import { Box } from '@mui/material';
import { globalTypes } from '@shared/duck';
import React from 'react';

const UITabPanel: React.FC<globalTypes.UITabPanel> = ({
  children,
  openTabId,
  tabPanelId,
  ...other
}) => {
  const isCurrent = tabPanelId === openTabId;
  return (
    <Box
      role='tabpanel'
      display={!isCurrent ? 'none !important' : 'flex'}
      id={`full-width-tabpanel-${tabPanelId}`}
      aria-labelledby={`full-width-tab-${tabPanelId}`}
      overflow='auto'
      {...other}
    >
      {children}
    </Box>
  );
};

export default UITabPanel;