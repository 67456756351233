import { globalEnums } from '@shared/duck';

export const getHttpMethodColor = (method: string): globalEnums.MuiBadgeColor | undefined => {
  switch (method?.toUpperCase()) {
    case globalEnums.HttpMethodEnum.GET: {
      return globalEnums.MuiBadgeColor.PRIMARY;
    }
    case globalEnums.HttpMethodEnum.POST: {
      return globalEnums.MuiBadgeColor.SUCCESS;
    }
    case globalEnums.HttpMethodEnum.PUT:
    case globalEnums.HttpMethodEnum.PATCH: {
      return globalEnums.MuiBadgeColor.WARNING;
    }
    case globalEnums.HttpMethodEnum.DELETE: {
      return globalEnums.MuiBadgeColor.ERROR;
    }
    case globalEnums.HttpMethodEnum.HEAD: {
      return globalEnums.MuiBadgeColor.SECONDARY;
    }
  }
};