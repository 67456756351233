import { OpenApiTarget } from '@api-client';
import { Box, Stack, Typography } from '@mui/material';
import { globalEnums, globalUtils } from '@shared/duck';
import React from 'react';

interface TargetCardProps {
  target: OpenApiTarget;
}

const TargetCard: React.FC<TargetCardProps> = ({
  target,
  ...props
}) => {
  const Icon = globalUtils.getTargetTypeIcon(
    target.type.toString()?.toUpperCase() as globalEnums.ETargetType);

  return (
    <Stack
      {...props}
      flexDirection='row'
      padding='0.5rem 1rem'
      gap={0.5}
    >
      {Icon && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          {Icon}
        </Box>

      )}

      <Stack flexDirection='column' overflow='hidden' gap='0'>
        {target.name && (
          <Typography
            variant='subtitle2'
            noWrap
          >
            {target.name}
          </Typography>
        )}

        {target.location && (
          <Typography
            variant='body2'
            sx={{
              opacity: 0.5,
            }}
            noWrap
          >
            {target.location}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default TargetCard;