import { useMemo } from 'react';
import { globalQueries, globalModels, globalUtils, allRoutesEnum } from '@shared/duck';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

interface AuthsTableApiResult {
  authentications: globalModels.AuthenticationsViewModel[],
  totalItemsCount: number,
  isAuthsListLoading: boolean,
  authsHasNextPage: boolean,
  fetchNextAuthsPage: () => void,
  onAuthRowClick?: (item: globalModels.AuthenticationsViewModel) => void,
  onDeleteAuthClick: (item: globalModels.AuthenticationsViewModel) => void;
  onAddAuthentication: () => void;
}

export const useAuthsTableApi = ({
  page,
  project,
  search,
  pageSize,
}: {
  page: number;
  project?: string;
  search?: string;
  pageSize?: number;
}): AuthsTableApiResult => {
  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();

  const {
    credentials,
    totalItemsCount,
    isCredentialsListLoading,
    credentialsHasNextPage = false,
    fetchNextCredentialsPage,
  } = globalQueries.useGetCredentialsList({
    page: page + 1,
    filter: search,
    project: project ? [project] : undefined,
    pageSize: pageSize,
  });
  const { deleteCredentials } = globalQueries.useDeleteCredentials();

  const authentications = useMemo(
    () => credentials?.map(item => globalUtils.mapAuthenticationsDtoToViewModel(item)) || [],
    [credentials],
  );

  const onAuthRowClick = (item: globalModels.AuthenticationsViewModel) =>
    navigate(allRoutesEnum.ProjectAuthenticationDetails.replace(':projectId', project || '').replace(':authenticationId', item.id));

  const onConfirmDelete = async (item: globalModels.AuthenticationsViewModel) => {
    try {
      await deleteCredentials({ id: item.id });
      enqueueSnackbar(<><b>{item.name}</b> has been deleted successfully</>, { variant: 'success' });
    }
    catch (error) {
      enqueueSnackbar(`Failed to delete ${item.name}`, { variant: 'error' });
    }
  };

  const onDeleteAuthClick = async (item: globalModels.AuthenticationsViewModel) => {
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete Authentication?',
        onConfirm: () => onConfirmDelete(item),
        name:item.name,
      },
    });
  };

  const onAddAuthentication = () => showModal({
    modalType: MODAL_TYPE.AuthenticationModal,
    modalProps: {},
  });

  return {
    authentications,
    totalItemsCount,
    isAuthsListLoading: isCredentialsListLoading,
    authsHasNextPage: credentialsHasNextPage,
    fetchNextAuthsPage: fetchNextCredentialsPage,
    onAddAuthentication,
    onAuthRowClick,
    onDeleteAuthClick,
  };
};