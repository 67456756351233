import { AuthQueryKeys } from '@shared/duck/queries/queriesKeys';
import { getNextPageParam, useFlatCountFromResponse, useFlatResults } from '@shared/duck/queries/utils';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import * as apiClient from 'api-client';
import { useApp } from '@shared/hooks';

const useCreateNightVisionToken = () => {
  const { authApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.AuthApiAuthCliTokenCreateRequest) =>
      authApi.authCliTokenCreate(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [AuthQueryKeys.tokensList] });
    }
  });

  return {
    createNightVisionToken: mutateAsync,
    isLoading,
  };
};

const useGetTokensList = (request: apiClient.AuthApiAuthCliTokenListRequest, refetchInterval?: number) => {
  const { authApi } = useApp();

  const { data, isLoading, isFetching } = useInfiniteQuery({
    queryKey: [AuthQueryKeys.tokensList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => authApi.authCliTokenList({ ...request, page: pageParam }),
    getNextPageParam: getNextPageParam,
    refetchInterval,
  });

  const tokens = useFlatResults(data);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    tokens,
    totalItemsCount: totalItemsCount,
    isTokensListLoading: isLoading,
    isTokensListFetching: isFetching,
  };
};

const useDeleteToken = () => {
  const { authApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.AuthApiAuthCliTokenDestroyRequest) => authApi.authCliTokenDestroy(request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [AuthQueryKeys.tokensList] }),
  });

  return {
    deleteToken: mutateAsync,
    isDeleteInProgress: isLoading,
  };
};

export {
  useCreateNightVisionToken,
  useGetTokensList,
  useDeleteToken,
};
