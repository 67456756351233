import { FalsePositive } from '@api-client';
import { globalModels, globalQueries } from '@shared/duck';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

const mapFalsePositivesDtoToViewModel = (dto: FalsePositive): globalModels.FalsePositivesModel => {
  return {
    id: dto.id,
    target: dto.target,
    kind: dto.kind,
    kindName: dto.kind_name,
    path: dto.url_path,
    httpMethod: dto.http_method,
    parameterName: dto.parameter_name,
    createdFromIssue: dto.created_from_issue,
    deletedAt: dto.deleted_at,
    isDeleted: dto.is_deleted,
    createdAt: dto.created_at,
    lastUpdatedAt: dto.last_updated_at,
  };
};

interface FpTableApiResult {
  falsePositives: globalModels.FalsePositivesModel[];
  totalItemsCount: number,
  isFpListLoading: boolean,
  onDeleteFalsePositives: (targetId: string, fpIds: string[], isMultipleDelete?: boolean) => void,
}

export const useFpTableApi = ({
  page,
  targetId,
  pageSize,
}: {
  page: number;
  targetId: string;
  pageSize?: number;
}): FpTableApiResult => {
  const {
    falsePositives: dtoFalsePositives,
    totalItemsCount,
    isFpListLoading,
  } = globalQueries.useGetFalsePositives(
    {
      page: page + 1,
      id: targetId,
      pageSize: pageSize,
    },
  );

  const falsePositives = React.useMemo(
    () =>
      dtoFalsePositives?.map(
        (item) => mapFalsePositivesDtoToViewModel(item),
      ), [dtoFalsePositives],
  );

  const { deleteFalsePositives } = globalQueries.useDeleteFalsePositives();
  const { showModal } = useGlobalModalContext();

  const onDeleteFalsePositives = async (targetId: string, fpIds: string[], isMultipleDelete: boolean = false) => {
    const onConfirm = async () => {
      try {
        await deleteFalsePositives({ id: targetId, falsePositiveDestroyRequest: { ids: fpIds } });
        enqueueSnackbar('False Positives has been deleted successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Failed to delete False Positives', { variant: 'error' });
      }
    };
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: `${isMultipleDelete ? 'Delete selected False Positives?' : 'Delete False Positive?'}`,
        onConfirm,
        deleteText: `Are you sure you want to delete ${isMultipleDelete ? 'selected False Positives' : 'this False Positive'}`,
        textAfterName: '',
      },
    });
  };

  return {
    falsePositives,
    totalItemsCount,
    isFpListLoading,
    onDeleteFalsePositives,
  };
};