import React from 'react';
import { IssueResolutionEnum } from 'models/Enums';
import { ResolutionFalsePositiveIcon, ResolutionOpenIcon, ResolutionResolvedIcon } from 'views/components/v1/Icons';
import { StyledResolutionSelect } from './issue-resolution-select-old.styled';
import { selectOldTypes } from '@shared/components';
import { ResolutionEnum } from 'api-client';
import { IssueResolutions } from './duck';
import { Typography, Stack } from '@mui/material';

export interface IssueResolutionSelectProps extends Omit<selectOldTypes.SelectProps, 'options'> {
  resolution?: ResolutionEnum;
  withBorders?: boolean;
  withPaddings?: boolean;
  bgColor?: string;
}

const UIIssueResolutionSelectOld: React.FC<IssueResolutionSelectProps> = ({
  resolution,
  withBorders = true,
  withPaddings = true,
  bgColor = 'white',
  ...other
}: IssueResolutionSelectProps) => {
  const Icons = new Map<IssueResolutionEnum, React.ReactNode>([
    [IssueResolutionEnum.open, <ResolutionOpenIcon key={IssueResolutionEnum.open} />],
    [IssueResolutionEnum.resolved, <ResolutionResolvedIcon key={IssueResolutionEnum.resolved} />],
    [IssueResolutionEnum.falsePositive, <ResolutionFalsePositiveIcon key={IssueResolutionEnum.falsePositive} />],
  ]);

  return (
    <StyledResolutionSelect
      {...other}
      options={IssueResolutions}
      initialValue={IssueResolutions.find(i => i.value === resolution)}
      renderer={item => (
        <Stack direction='row' gap='1rem' alignItems='center'>
          {Icons.get(item.value as IssueResolutionEnum)}
          <Typography variant='default'>{item.label}</Typography>
        </Stack>
      )}
      withEmptyItem={false}
      placeholder='Status'
      enableReinitialize={true}
      withSearch={false}
      resolution={resolution}
      withBorders={withBorders}
      withPaddings={withPaddings}
      bgColor={bgColor}
    />
  );
};

export default UIIssueResolutionSelectOld;
