import { IconProps } from './BaseIcon';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="163"
    height="40"
    viewBox="0 0 163 40"
    fill="none"
    {...(props)}>
    <g>
      <path d="M36.7251 25.0211V13.6113H38.9739L44.647 20.9788V13.6113H46.8633V25.0211H44.7771L38.9576 17.3918V25.0211H36.7251Z" fill="white" />
      <path d="M50.5723 25.0211V13.6113H52.9365V25.0211H50.5723Z" fill="white" />
      <path d="M62.2539 25.1998C61.4067 25.1998 60.6181 25.0502 59.8896 24.751C59.1612 24.4518 58.5205 24.0339 57.9661 23.4957C57.4116 22.9575 56.9791 22.3331 56.6701 21.6209C56.3612 20.9087 56.2051 20.1412 56.2051 19.3152C56.2051 18.4892 56.3595 17.7201 56.6701 17.0095C56.9791 16.2973 57.4067 15.6729 57.9498 15.1347C58.4929 14.5965 59.1319 14.1786 59.8652 13.8794C60.5986 13.5803 61.3839 13.4307 62.2213 13.4307C63.166 13.4307 64.0571 13.6177 64.8945 13.9933C65.7319 14.3689 66.4213 14.8925 66.9644 15.5656L65.3189 17.0811C64.927 16.5916 64.4652 16.2225 63.9335 15.9721C63.4002 15.7217 62.8311 15.5965 62.2213 15.5965C61.7108 15.5965 61.2376 15.6925 60.8034 15.8827C60.3693 16.0729 59.9888 16.3331 59.662 16.6648C59.3352 16.9965 59.0831 17.3884 58.9043 17.8388C58.7254 18.2892 58.636 18.7819 58.636 19.3136C58.636 19.8453 58.7287 20.3412 58.9124 20.7965C59.0978 21.2534 59.3547 21.6469 59.6864 21.9786C60.0181 22.3103 60.4035 22.5705 60.8441 22.7607C61.2847 22.951 61.7644 23.0453 62.2864 23.0453C62.8083 23.0453 63.3189 22.9445 63.753 22.7445C64.1872 22.5429 64.5547 22.2616 64.8538 21.8973C65.153 21.5331 65.3514 21.1233 65.449 20.6664H62.075V18.6128H67.9758V19.3299C67.9758 20.177 67.8311 20.9575 67.5433 21.6681C67.2555 22.3803 66.8555 22.9998 66.3449 23.5266C65.8343 24.0534 65.2311 24.4632 64.5352 24.7575C63.8392 25.0518 63.0782 25.1981 62.2539 25.1981V25.1998Z" fill="white" />
      <path d="M71.2275 25.0211V13.6113H73.5918V18.2081H78.8405V13.6113H81.2048V25.0211H78.8405V20.343H73.5918V25.0211H71.2275Z" fill="white" />
      <path d="M88.312 25.0211V15.6975H84.4648V13.6113H94.5055V15.6975H90.6746V25.0211H88.3104H88.312Z" fill="white" />
      <path d="M101.634 25.0211L97.0049 13.6113H99.5642L103.052 22.5934L106.621 13.6113H109.067L104.372 25.0211H101.634Z" fill="white" />
      <path d="M111.728 25.0211V13.6113H114.092V25.0211H111.728Z" fill="white" />
      <path d="M122.286 25.1835C121.363 25.1835 120.46 25.0128 119.58 24.6697C118.701 24.3266 117.917 23.8404 117.232 23.2112L118.585 21.5152C119.249 22.1022 119.886 22.5266 120.501 22.7868C121.115 23.0469 121.764 23.177 122.449 23.177C122.927 23.177 123.34 23.1201 123.688 23.0063C124.036 22.8925 124.304 22.7266 124.494 22.5087C124.684 22.2908 124.779 22.0356 124.779 21.7429C124.779 21.351 124.637 21.0502 124.354 20.8388C124.071 20.6274 123.589 20.4616 122.904 20.3412L120.639 19.951C119.671 19.7884 118.935 19.4453 118.431 18.9234C117.925 18.4014 117.673 17.7282 117.673 16.9022C117.673 16.1965 117.855 15.5819 118.219 15.0599C118.584 14.538 119.099 14.1396 119.767 13.8616C120.436 13.5851 121.231 13.4453 122.156 13.4453C122.993 13.4453 123.828 13.5868 124.658 13.8697C125.489 14.1526 126.226 14.5429 126.867 15.0437L125.595 16.8047C124.4 15.8925 123.198 15.4356 121.992 15.4356C121.567 15.4356 121.198 15.4892 120.883 15.5982C120.567 15.7071 120.325 15.8567 120.158 16.0469C119.989 16.2372 119.906 16.4632 119.906 16.7234C119.906 17.0713 120.031 17.3396 120.281 17.5299C120.532 17.7201 120.949 17.8632 121.536 17.9624L123.688 18.3217C124.829 18.5071 125.681 18.8681 126.247 19.4063C126.811 19.9445 127.094 20.6534 127.094 21.5331C127.094 22.273 126.899 22.9169 126.507 23.4648C126.115 24.0128 125.559 24.4372 124.836 24.7364C124.112 25.0356 123.263 25.1852 122.284 25.1852L122.286 25.1835Z" fill="white" />
      <path d="M130.137 25.0211V13.6113H132.501V25.0211H130.137Z" fill="white" />
      <path d="M141.689 25.1998C140.842 25.1998 140.053 25.0502 139.325 24.751C138.597 24.4518 137.959 24.0339 137.41 23.4957C136.86 22.9575 136.431 22.3331 136.122 21.6209C135.813 20.9087 135.657 20.1412 135.657 19.3152C135.657 18.4892 135.811 17.7201 136.122 17.0095C136.431 16.2973 136.86 15.6729 137.41 15.1347C137.958 14.5965 138.597 14.1786 139.325 13.8794C140.053 13.5803 140.84 13.4307 141.689 13.4307C142.538 13.4307 143.327 13.5803 144.062 13.8794C144.795 14.1786 145.436 14.5965 145.985 15.1347C146.533 15.6729 146.961 16.2973 147.265 17.0095C147.569 17.7217 147.722 18.4908 147.722 19.3152C147.722 20.1396 147.569 20.9103 147.265 21.6209C146.961 22.3331 146.533 22.9575 145.985 23.4957C145.436 24.0339 144.795 24.4518 144.062 24.751C143.328 25.0502 142.538 25.1998 141.689 25.1998ZM141.689 23.0323C142.211 23.0323 142.689 22.938 143.123 22.7477C143.558 22.5575 143.938 22.2973 144.265 21.9656C144.592 21.6339 144.845 21.2404 145.031 20.7835C145.216 20.3266 145.307 19.8388 145.307 19.3168C145.307 18.7949 145.214 18.2925 145.031 17.842C144.845 17.3916 144.59 16.9998 144.265 16.6681C143.938 16.3364 143.558 16.0762 143.123 15.8859C142.689 15.6957 142.211 15.5998 141.689 15.5998C141.167 15.5998 140.706 15.6957 140.271 15.8859C139.837 16.0762 139.457 16.3364 139.13 16.6681C138.805 16.9998 138.548 17.3916 138.364 17.842C138.179 18.2925 138.088 18.7786 138.088 19.3006C138.088 19.8225 138.18 20.3282 138.364 20.7835C138.549 21.2404 138.805 21.6339 139.13 21.9656C139.457 22.2973 139.836 22.5575 140.271 22.7477C140.706 22.938 141.179 23.0323 141.689 23.0323Z" fill="white" />
      <path d="M150.442 25.0211V13.6113H152.691L158.364 20.9788V13.6113H160.58V25.0211H158.494L152.674 17.3918V25.0211H150.442Z" fill="white" />
      <path d="M6.77901 29.8098V18.6065C6.77901 18.2407 7.26194 18.1073 7.44893 18.4228L19.0278 38.0081C19.0928 38.1171 19.2099 38.1854 19.3384 38.1854H23.6879C23.9676 38.1854 24.14 37.8813 23.9969 37.6423L7.33023 9.52522C7.26519 9.41628 7.14811 9.34961 7.02128 9.34961H2.6782C2.47982 9.34961 2.31885 9.51059 2.31885 9.70896V29.8098C2.31885 30.0081 2.47982 30.1691 2.6782 30.1691H6.41966C6.61803 30.1691 6.77901 30.0081 6.77901 29.8098Z" fill="white" />
      <path d="M5.16911 1.88586L21.8358 30.0029C21.9008 30.1119 22.0179 30.1785 22.1447 30.1785H26.4878C26.6862 30.1785 26.8471 30.0176 26.8471 29.8192V9.71838C26.8471 9.52001 26.6862 9.35903 26.4878 9.35903H22.7463C22.548 9.35903 22.387 9.52001 22.387 9.71838V20.9216C22.387 21.2875 21.9041 21.4208 21.7171 21.1054L10.1382 1.52001C10.0732 1.41107 9.9561 1.34277 9.82764 1.34277H5.47805C5.19838 1.34277 5.02602 1.64684 5.16911 1.88586Z" fill="white" />
    </g>
  </svg>
);

const NightvisionFullIcon = (props: IconProps) => {
  return <CustomSVGIcon {...props} />;
};

export default NightvisionFullIcon;
