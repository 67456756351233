import React from 'react';
import { Link } from 'react-router-dom';

const ToLinkOrNotToLink: React.FC<React.PropsWithChildren & { href: string }> = ({ href, children }) => {
  return (
    href ? (
      <Link
        to={href}
        onClick={e => (
          e.stopPropagation()
        )}
      >
        {children}
      </Link>
    ) : (
      <>
        {children}
      </>
    )
  );
};

export default ToLinkOrNotToLink;