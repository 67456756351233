import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const StyledFlex = styled(Stack)`
  border: 1px solid ${muiPalette.grey?.[300]};
  border-radius: 0.375rem;
  gap: 0;
`;

export { StyledFlex };