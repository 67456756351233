import { Note, TextLink } from '@contentful/f36-components';
import { Box, Stack, Typography } from '@mui/material';
import { UICodeSnippet } from '@shared/components';
import { globalEnums } from '@shared/duck';

interface TestAuthPageProps {
  setOpenDownloadPage: () => void;
}

const TestAuthPage: React.FC<TestAuthPageProps> = ({
  setOpenDownloadPage
}: TestAuthPageProps) => {
  return (
    <Stack gap='1rem'>
      <Typography variant='default' fontWeight='500'>
        Open NightVision CLI & log into NightVision:
      </Typography>
      <UICodeSnippet>NightVision login</UICodeSnippet>
      <Typography variant='default' fontWeight='500'>
        Enter the following command to test authentication:
      </Typography>
      <UICodeSnippet>NightVision test-auth</UICodeSnippet>
      <Box>
        <Note variant={globalEnums.ENoteVariants.PRIMARY}>
          You must have NightVision CLI to record a Playwright script.
          If you don’t already have it click <TextLink onClick={setOpenDownloadPage}>here</TextLink> to download it.
        </Note>
      </Box>
    </Stack>
  );
};

export default TestAuthPage;