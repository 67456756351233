import React from 'react';
import { FieldInputProps, useField } from 'formik';
import { FormControlF36, TooltipError } from './components';
import { FormControlF36Props } from './duck';

export interface FormikControlF36ChildProps extends FieldInputProps<any> {
  isInvalid: boolean;
  isChecked?: boolean;
  error?: string;
  endIcon?: JSX.Element;
  noBorders?: boolean;
}

export interface FormikControlF36Props extends Omit<FormControlF36Props, 'validationMessage'> {
  name: string;
  showError?: boolean;
  tooltipError?: boolean;
}

const FormikControlF36: React.FC<FormikControlF36Props> = ({
  name,
  showError = false,
  tooltipError = false,
  children,
  ...other
}: FormikControlF36Props) => {
  const [field, meta] = useField(name);
  const error = showError ? meta.error : meta.touched ? meta.error : undefined;
  const isInvalid = !!error;
  const isChecked = field.checked;

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const childProps: FormikControlF36ChildProps = { ...field, isInvalid };
      if (isChecked != undefined) {
        childProps.isChecked = isChecked;
      }
      if (error != undefined) {
        childProps.error = error;
        childProps.endIcon = tooltipError ? <TooltipError content={error} {...other} /> : undefined;
        childProps.noBorders = false;
      }
      return React.cloneElement(child, childProps);
    }
    return child;
  });

  return (
    <FormControlF36 error={tooltipError ? undefined : error} isInvalid={isInvalid} {...other}>
      {childrenWithProps}
    </FormControlF36>
  );
};

export default FormikControlF36;
