import { Box, BoxProps, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { withSafeProps } from 'views/utils/common';

interface StyledToolCardProps extends BoxProps {
  isActive: boolean;
}

const StyledToolCard = styled(withSafeProps<StyledToolCardProps>(Box, ['isActive']))`
    & .icon {
        padding: 0.5rem 0;
    }

    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0.375rem;
    border: 1px solid ${muiPalette.grey?.[300]};

    border-color: ${({ isActive }) => isActive && muiPalette.blue?.[600]};
    background-color: ${({ isActive }) => isActive ? muiPalette.blue?.[100] : 'white'};

    :hover {
        cursor: pointer;
        border-color: ${muiPalette.blue?.[600]};
    }
    
    :active {
        border-color: ${muiPalette.blue?.[600]};
        box-shadow: 0 0 0 3px ${muiPalette.blue?.[300]};
    }

    word-break: break-word;
`;

export { StyledToolCard };