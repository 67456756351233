import * as Yup from 'yup';
import { Project } from 'api-client';
import { allRoutesEnum, globalConstants, globalModels, globalQueries, globalUtils } from '@shared/duck';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

export interface CreateProjectFormValues {
  name: string;
  exists: boolean;
  isNameTested: boolean;
}

export const CreateProjectValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required').max(100, 'Name must be at most 100 characters').when('exists', {
      is: true,
      then: schema => schema.test('exists', 'Project name already exists', () => false),
      otherwise: schema => schema
        .test('isValid', globalConstants.INVALID_NAME_FORMAT, (value?: string) => {
          return globalUtils.validateName(value || '');
        }),
    }),
  exists: Yup.boolean(),
  isNameTested: Yup.boolean(),
});

const useCreateOrEditProjectHandler = () => {
  const { showModal } = useGlobalModalContext();
  const createOrEditProjectHandler = ({ project, initialName }: { project?: globalModels.ProjectViewModel, initialName?: string }) => {
    const result = new Promise<Project | undefined>((resolve) => {
      showModal({
        modalType: MODAL_TYPE.ProjectModal,
        modalProps: {
          initialName,
          project,
          onResult: resolve,
        },
      });
    });
    return result;
  };
  return { createOrEditProjectHandler };
};

const useDeleteProjectHandler = () => {
  const { deleteProject } = globalQueries.useDeleteProject();
  const onDeleteProject = async (id: string, name?: string) => {
    try {
      await deleteProject({ id });
      enqueueSnackbar(<><b>{name}</b> Project has been deleted successfully</>, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to delete ${name} Project`, { variant: 'error' });
    }
  };

  const { showModal } = useGlobalModalContext();

  const deleteProjectHandler = async ({ id, name, onProjectDeleted }: { id: string, name: string, onProjectDeleted?: () => void }) => {
    const onConfirm = async () => {
      await onDeleteProject(id, name);
      if (onProjectDeleted) {
        onProjectDeleted();
      }
    };
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete Project?',
        onConfirm,
        name,
        textAfterName: '',
        warningText:
          '\n\nThis action is irreversible and will remove all shared access to this project.\n\n' +
          'This will also delete all the targets, applications and credentials associated with this project.',
      },
    });
  };

  return { deleteProjectHandler };
};

const useLeaveProjectHandler = () => {
  const navigate = useNavigate();
  const { unshareProject } = globalQueries.useUnshareProject();
  const { showModal } = useGlobalModalContext();

  const onLeaveProjectHandler = async (
    {
      userId,
      projectName,
      projectId,
    }: {
      userId: string,
      projectName: string
      projectId: string
    }) => {
    const project = `${projectName} project`;
    const onConfirm = async () => {
      try {
        await unshareProject({
          id: projectId,
          unshareProjectRequest: {
            users: [userId],
          },
        });

        enqueueSnackbar(`You have successfully left the ${project}`, { variant: 'success' });
        navigate(allRoutesEnum.Projects);
      } catch (error) {
        enqueueSnackbar(`Failed to leave the ${project}`, { variant: 'error' });
      }
    };
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Leave Project?',
        onConfirm,
        deleteText: 'Are you sure you want to leave',
        name: project,
        textAfterName: '',
        warningText: '\n\nThis action is irreversible. You will no longer have access to associated targets, scans etc.',
        confirmButtonText: 'Leave',
      },
    });
  };

  return { onLeaveProjectHandler };
};

const useShareProjectHandlerNew = () => {
  const { shareProject } = globalQueries.useShareProject();
  const shareProjectSubmit = async (
    usernames: string[],
    projectId: string,
    onClose: () => void,
  ) => {
    if (usernames.length) {
      let anyError = false;
      try {
        await shareProject({
          id: projectId,
          userSharedProjectRequest: { usernames: usernames },
          force: 1,
        });
      } catch {
        anyError = true;
      }

      anyError ? enqueueSnackbar('Failed to share the project', { variant: 'error' })
        : enqueueSnackbar('The project has been successfully shared', { variant: 'success' });

      onClose();
    } else {
      enqueueSnackbar('Failed to share the project. Please select Users', { variant: 'error' });
    }
  };

  return { shareProjectSubmit };
};

const useShareProjectModalHandler = () => {
  const { showModal } = useGlobalModalContext();
  const shareProjectHandler = (project: globalModels.ProjectViewModel) => {
    showModal({
      modalType: MODAL_TYPE.ShareProjectModal,
      modalProps: {
        projectId: project.id,
        collaborators: project.collaborators,
      },
    });
  };
  return { shareProjectHandler };
};

const getShareProjectTooltipText = (
  isProjectOwner: boolean,
  isFreeProject: boolean | undefined,
): string => {
  if (!isProjectOwner) {
    return globalConstants.SHARE_PROJECT_NOT_ALLOWED;
  } else if (isFreeProject) {
    return globalConstants.SHARE_FREE_PROJECT_NOT_ALLOWED;
  } else return '';
};

const getRenameTooltipText = (
  isOwned: boolean,
  isFree: boolean,
) => {
  if (!isOwned) {
    return globalConstants.RENAME_PROJECT_NOT_ALLOWED;
  } else if (isFree) {
    return globalConstants.CANT_RENAME_FREE_PROJECT;
  } else {
    return '';
  }
};

const getDeleteProjectTooltipText = (
  isOwned: boolean,
  isFree: boolean,
  isDefault: boolean,
) => {
  if (!isOwned) {
    return globalConstants.DELETE_PROJECT_NOT_ALLOWED;
  } else if (isFree) {
    return globalConstants.CANT_DELETE_FREE_PROJECT;
  } else if (isDefault) {
    return globalConstants.CANT_DELETE_DEFAULT_PROJECT;
  } else {
    return '';
  }
};

const getUnShareProjectTooltipText = (
  isOwned: boolean,
): string => {
  if (!isOwned) {
    return globalConstants.REMOVE_USER_FROM_PROJECT_NOT_ALLOWED;
  } else {
    return globalConstants.REMOVE_OWNER_FROM_PROJECT_NOT_ALLOWED;
  }
};

export {
  useShareProjectHandlerNew,
  useCreateOrEditProjectHandler,
  useDeleteProjectHandler,
  getShareProjectTooltipText,
  getRenameTooltipText,
  getDeleteProjectTooltipText,
  getUnShareProjectTooltipText,
  useShareProjectModalHandler,
  useLeaveProjectHandler,
};
