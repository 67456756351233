import {
  FlaskIconC,
  GithubIconC,
  JSIconC,
  LaravelIconC,
  PhpIconC,
  PythonIconC,
  SwaggerIconC,
  VueIconC,
  WorldIconC,
} from '@assets';
import { IconProps } from '@mui/material/Icon/Icon';
import { globalEnums } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';

const SupportedTechnologies = [
  'github',
  'world',
  'swagger',
  'vue',
  'python',
  'flask',
  'laravel',
  'php',
  'javascript',
  'url',
  'openapi',
] as const;

export type TechnologyName = typeof SupportedTechnologies[number]

const getTechnologyDescription = () => {
  const description = new Map<
    TechnologyName,
    {
      icon: React.FC<IconProps>
      name: string
      primaryColor?: string
      secondaryColor?: string
    }
  >();

  description.set('github', {
    icon: GithubIconC,
    name: 'Github',
    primaryColor: muiPalette.grey?.[400],
    secondaryColor: muiPalette.grey?.[300],
  });

  description.set('world', {
    icon: WorldIconC,
    name: 'Web',
    primaryColor: muiPalette.blue?.[400],
    secondaryColor: muiPalette.blue?.[300],
  });

  description.set('url', {
    icon: WorldIconC,
    name: 'Web',
    primaryColor: muiPalette.blue?.[400],
    secondaryColor: muiPalette.blue?.[300],
  });

  description.set('openapi', {
    icon: SwaggerIconC,
    name: 'OpenAPI',
    primaryColor: muiPalette.green?.[400],
    secondaryColor: muiPalette.green?.[200],
  });

  description.set('vue', {
    icon: VueIconC,
    name: 'Vue',
  });

  description.set('python', {
    icon: PythonIconC,
    name: 'Python',
  });

  description.set('flask', {
    icon: FlaskIconC,
    name: 'Flask',
  });

  description.set('laravel', {
    icon: LaravelIconC,
    name: 'Laravel',
  });

  description.set('php', {
    icon: PhpIconC,
    name: 'php',
  });

  description.set('javascript', {
    icon: JSIconC,
    name: 'Javascript',
  });

  return description;
};

const useTechnologies = () => {
  const technologies = React.useMemo(getTechnologyDescription, []);
  return {
    get: (type?: string) => {
      return technologies.get(type?.toLowerCase() as TechnologyName);
    },
  };
};

const getTargetTypes = () =>
  new Map<
    globalEnums.ETargetType,
    {
      icon: React.FC<IconProps>
      name: string
      primaryColor?: string
      secondaryColor?: string
    }
  >([
    [
      globalEnums.ETargetType.GitHub,
      {
        icon: GithubIconC,
        name: 'Github',
        primaryColor: muiPalette.grey?.[400],
        secondaryColor: muiPalette.grey?.[300],
      },
    ],
    [
      globalEnums.ETargetType.OpenAPI,
      {
        icon: SwaggerIconC,
        name: 'OpenAPI',
        primaryColor: muiPalette.green?.[400],
        secondaryColor: muiPalette.green?.[200],
      },
    ],
    [
      globalEnums.ETargetType.URL,
      {
        icon: WorldIconC,
        name: 'URL',
        primaryColor: muiPalette.blue?.[400],
        secondaryColor: muiPalette.blue?.[300],
      },
    ],
  ]);

const useTargetTypes = () => {
  return React.useMemo(getTargetTypes, []);
};

export { getTechnologyDescription, SupportedTechnologies, useTechnologies, useTargetTypes, getTargetTypes };
