import { AxiosError } from 'axios';

export interface IFormControlEvent<T> {
  target: { id?: string; name?: string; value: T };
}

export class FormControlEvent<T> implements IFormControlEvent<T> {
  target: { id?: string; name?: string; value: T };

  constructor({ id, name, value }: { id?: string; name?: string; value: T }) {
    this.target = {
      id,
      name,
      value,
    };
  }
}

interface SubmitError {
  attr?: string;
  code?: string;
  detail?: string;
}

export interface ServerError {
  errors?: SubmitError[];
  type?: string;
}

export const getFormikErrorsFromSubmit = (error: AxiosError<any>) => {
  const innerError = error.response?.data as ServerError;
  if (innerError?.type === 'validation_error') {
    const errors =
      innerError?.errors?.reduce((prev: any, curr) => {
        const result = {
          ...prev,
        };
        if (curr.attr) {
          result[curr.attr] = curr.detail || '';
        }
        return result;
      }, {}) || {};
    return errors;
  }

  return {
    non_field_errors: (
      innerError?.errors?.map(error => error.detail) || []
    ).join('\n'),
  };
};
