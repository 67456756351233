import { TextFieldProps } from '@mui/material';
import { MuiTextInput } from '@shared/components';
import { globalTypes } from '@shared/duck';
import { useFormikContext } from 'formik';
import React from 'react';

const FirstName: React.FC<TextFieldProps> = ({
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext<globalTypes.ProfileFormValues>();
  const [value, setValue] = React.useState<string>(values.firstName || '');

  React.useEffect(() => {
    if (values.firstName !== value) {
      setValue(values.firstName);
    }
  }, [values.firstName]);

  React.useEffect(() => {
    if (values.firstName !== value) {
      setFieldValue('firstName', value);
    }
  }, [value]);

  return (
    <MuiTextInput
      id='firstName'
      placeholder='Enter First Name'
      value={value}
      onBlur={props.onBlur}
      name={props.name}
      error={props.error}
      onChange={(e) => {
        setValue(e.target.value);
        props.onChange?.(e);
      }}
    />
  );
};

export default FirstName;