import React from 'react';
import { ArrowDownIcon, ArrowUpIcon, DoubleArrowIcon } from '../Icons';
import { globalEnums, globalTypes } from '@shared/duck';
import { Button } from '@mui/material';

interface SortIconProps {
  sorting?: globalTypes.SortingDirections;
  onClick: () => void;
  children: React.ReactNode;
}

function TableColumnSortButton(props: SortIconProps): JSX.Element {
  const { sorting, onClick, children } = props;
  let SortIcon;

  if (!sorting) {
    SortIcon = DoubleArrowIcon;
  } else {
    SortIcon = sorting === globalTypes.SortingDirections.asc ? ArrowUpIcon : ArrowDownIcon;
  }

  return (
    <Button
      endIcon={<SortIcon variant={globalEnums.EIconVariants.MUTED} />}
      onClick={onClick}
      size='small'
      sx={{
        border: 'none !important',
      }}
    >
      {children}
    </Button>
  );
}

export default TableColumnSortButton;
