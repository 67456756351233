import React from 'react';
import { ProjectApplicationsTable } from './components';
import { useDebounce } from '@shared/hooks';
import { MuiSearchInput } from '@shared/components';
import { Box, Stack } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const ProjectApplications: React.FC = () => {
  const { debouncedValue: debouncedSearch, onChange: onSearchChange } = useDebounce<string>();

  return (
    <Stack gap={0} sx={{ overflow: 'hidden' }}>
      <Box p='1rem' borderBottom={`1px solid ${muiPalette.grey?.[200]}`}>
        <MuiSearchInput
          width='25rem'
          onChange={e => onSearchChange(e.target.value)}
        />
      </Box>

      <ProjectApplicationsTable filter={debouncedSearch} />
    </Stack>
  );
};

export default ProjectApplications;