import * as api from 'api-client';
import { CredentialsType as CredentialsTypeEnum } from 'api-client';

export enum IssueResolutionEnum {
  open = api.ResolutionEnum.NUMBER_0,
  resolved = api.ResolutionEnum.NUMBER_1,
  falsePositive = api.ResolutionEnum.NUMBER_2,
}

export const IssueResolutionName = new Map<IssueResolutionEnum, string>([
  [IssueResolutionEnum.open, 'Open'],
  [IssueResolutionEnum.resolved, 'Resolved'],
  [IssueResolutionEnum.falsePositive, 'False Positive'],
]);

export const CredentialTypeName = new Map<CredentialsTypeEnum, string>([
  [CredentialsTypeEnum.Header, 'Header Authentication'],
  [CredentialsTypeEnum.Cookie, 'Cookies Authentication'],
  [CredentialsTypeEnum.UserPass, 'Username / Password'],
  [CredentialsTypeEnum.Script, 'Playwright Script'],
]);

export enum ScanStatusEnum {
  SUCCEEDED = 1,
  IN_PROGRESS = 2,
  ABORTED = 3,
  FAILED = 4,
  TIMED_OUT = 5,
}
