import React from 'react';
import { UIInfoCard } from '@shared/components';
import { useNavigate } from 'react-router';
import { RecentScansTable } from './components';
import { allRoutesEnum, globalTypes } from '@shared/duck';
import { Button } from '@mui/material';

interface ScansTableProps {
  selectedProjects: globalTypes.UIOptionItem<string>[];
  limit?: number;
}

const RecentScans: React.FC<ScansTableProps> = ({
  limit,
  selectedProjects,
}) => {
  const navigate = useNavigate();

  const navigateToNewScan = React.useCallback(() => navigate(allRoutesEnum.NewScan), []);

  return (
    <UIInfoCard
      title='Recent Scans'
      action={
        <Button variant='contained' onClick={navigateToNewScan}>
          + New Scan
        </Button>
      }
      customStyles={{
        noBodyPadding: true,
      }}
    >
      <RecentScansTable limit={limit} selectedProjects={selectedProjects} />
    </UIInfoCard>
  );
};

export default RecentScans;
