import { AutocompleteRenderInputParams, CircularProgress, TextField } from '@mui/material';
import React from 'react';

interface CustomInputProps extends AutocompleteRenderInputParams {
  isError?: boolean;
  isLoading: boolean;
  onChange?: any;
  onBlur?: any;
  name?: string;
  placeholder?: string;
}

const CustomInput: React.FC<CustomInputProps> = ({
  isError,
  isLoading,
  onBlur,
  name,
  placeholder = '',
  ...props
}: CustomInputProps) => {
  return (
    <TextField
      {...props}
      placeholder={placeholder}
      onBlur={onBlur}
      error={isError}
      name={name}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <>
            {isLoading && <CircularProgress color='inherit' size={16} />}
            {props.InputProps.endAdornment}
          </>
        ),
      }} />
  );
};

export default CustomInput;