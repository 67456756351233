import { globalQueries } from '@shared/duck';
import React from 'react';
import { BillingPlanCards } from './components';
import { MuiContentLoader } from '@shared/components';

const BillingPlanPage: React.FC = () => {
  const { paymentPlans, isPaymentPlansListLoading } = globalQueries.useGetPaymentPlansList();
  const [waitForPlanStatusChange, setWaitForPlanStatusChange] = React.useState(false);

  return (
    <MuiContentLoader isLoading={isPaymentPlansListLoading || waitForPlanStatusChange} variant='dots'>
      <BillingPlanCards cards={paymentPlans ?? []} setWaitForPlanStatusChange={setWaitForPlanStatusChange}/>
    </MuiContentLoader>);
};

export default BillingPlanPage;
