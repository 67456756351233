import { Table as BaseTable } from '@contentful/f36-components';
import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';
import Table from './Table';
import { FiltersPanel as BaseFiltersPanel } from './Table.styled';

const TableHead = styled(BaseTable.Head)`
  & th {
    background: ${baseTokens.colorWhite};
  }
`;
const TableBody = BaseTable.Body;
const TableRow = BaseTable.Row;
const TableCell = BaseTable.Cell;

export const FiltersPanel = BaseFiltersPanel;
export { Table, TableHead, TableBody, TableRow, TableCell };
export * from './Table.interfaces';
