import { useFormikContext } from 'formik';
import { WebTargetsContentValues } from '../../duck';
import { Tooltip, IconButton } from '@mui/material';
import { X } from '@phosphor-icons/react';

interface DeleteColumnCellRenderProps {
  valuesLength: number;
  index: number;
}

export const DeleteCellRender: React.FC<DeleteColumnCellRenderProps> = ({
  valuesLength,
  index,
}: DeleteColumnCellRenderProps) => {
  const { values, setFieldValue, validateForm } = useFormikContext<WebTargetsContentValues>();

  const anyCheckIsInProgress = values.targets.some(target => {
    return !target.isAccessTested || !target.isNameTested;
  });

  const onDeleteUrl = () => {
    if (values.targets.length > 1) {
      const newRows = values.targets.filter((_, ind) => valuesLength !== 1 ? (ind !== index) : true);
      setFieldValue('targets', newRows, false);
      const newStringUrls = newRows.map(target => target && target.url).join('\n');
      setFieldValue('stringUrl', newStringUrls, false);
      validateForm({ ...values, targets: newRows, stringUrl: newStringUrls });
    }
  };

  return (
    <Tooltip
      title={valuesLength === 1 ? 'At least one target is required' : 'Cannot remove while checks are in progress'}
      disableHoverListener={!anyCheckIsInProgress}
    >
      <IconButton
        size='large'
        aria-label='remove'
        onClick={onDeleteUrl}
        disabled={valuesLength === 1 || anyCheckIsInProgress}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent !important',
          }
        }}
      >
        <X size={18} />
      </IconButton>
    </Tooltip>
  );
};