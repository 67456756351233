import React from 'react';
import { useApp } from '@shared/hooks';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from '@contentful/f36-components';
import { Box } from '@mui/material';

const DocumentationRedirect: React.FC = () => {
  const app = useApp();

  const [searchParams] = useSearchParams();

  React.useLayoutEffect(() => {
    app.redirectToReadme(searchParams);
  }, [searchParams]);

  return (
    <Box p='2rem'>
      <Spinner size='large' />
    </Box>);
};

export default DocumentationRedirect;
