import { omit } from 'lodash';
import React from 'react';

const withSafeProps = <T extends {[_:string]: any}>(Component: React.ElementType, keys: Array<keyof T>) => {
  const element = React.forwardRef((props: T, ref) => {
    const safeProps = omit(props, keys);
    return React.createElement(Component, { ...safeProps, ref });
  });
  element.displayName = 'component';

  return element;
};

export { withSafeProps };
