import { Divider, Grid, Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { useMedia } from '@shared/hooks';
import React from 'react';

const ProfilePassword: React.FC = () => {
  const { isLargeScreen } = useMedia();

  return (
    <Stack
      width='100%'
      paddingTop={isLargeScreen ? '2rem' : 'initial'}
    >
      <Divider />

      <Stack flexDirection={'row'}>
        <Grid
          item
          xs={4}
          padding='1.5rem 0'
        >
          <Typography
            fontSize='0.875rem'
            fontWeight={500}
            lineHeight='1.25rem'
            letterSpacing='-0.00963rem'
            color={muiPalette.grey?.[800]}
          >
            {'Password'}
          </Typography>
        </Grid>

        <Grid
          item
          xs={8}
          padding='1.5rem 0'
        >
          <Typography
            fontSize='0.875rem'
            fontWeight={400}
            lineHeight='1.25rem'
            letterSpacing='-0.00963rem'
            color={muiPalette.grey?.[500]}
          >
            {'Your account was authorized through Google'}
          </Typography>
        </Grid>
      </Stack>

      <Divider />
    </Stack>
  );
};

export default ProfilePassword;