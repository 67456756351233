import styled from '@emotion/styled';
import { OutlinedInput, OutlinedInputProps } from '@mui/material';
import { withSafeProps } from 'views/utils/common';
import baseTokens from '@contentful/f36-tokens';

interface BorderedMuiInputProps extends OutlinedInputProps {
    showBorders?: boolean;
}

const BorderedMuiInput = styled(withSafeProps<BorderedMuiInputProps>(OutlinedInput, ['showBorders']))`
    &.MuiOutlinedInput-root {
        background: transparent;
        padding: 0.25rem 0.5rem;
        font-size: 1.75rem;
        line-height: 2.25rem;
        font-weight: 600;
        letter-spacing: 0.02275rem;
        color: ${baseTokens.gray900};
        height: 2.85rem;
    }

    & .MuiOutlinedInput-notchedOutline {
        border: ${({ showBorders }) => showBorders ? '' : 'none'};
    }

    & .MuiInputAdornment-positionEnd {
        padding-right: 1rem;
    }

    & .MuiIconButton-root {
        &:hover {
            background-color: transparent
        }
    }
`;

export { BorderedMuiInput };