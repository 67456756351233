import React from 'react';
import AuthenticationModal, { AuthenticationFormValues, AuthenticationModalProps } from './authentication-modal';
import { onSubmitHandler } from './duck';
import { useGlobalModalContext } from '@shared/duck/contexts';
import { Credentials, CredentialsUpdate } from '@api-client';

export interface AuthModalContainerProps extends Omit<AuthenticationModalProps, 'onSubmit' | 'onClose'> {
  enableProjectSelect?: boolean;
  authenticationId?: string;
  onResult?: (data: Credentials | CredentialsUpdate | null) => void;
}

const AuthModalContainer: React.FC<AuthModalContainerProps> = ({
  authenticationId,
  enableProjectSelect = true,
  onResult,
  ...props
}: AuthModalContainerProps
) => {
  const { hideModal } = useGlobalModalContext();

  const { onSubmit } = onSubmitHandler(authenticationId);

  const onSubmitForm = async (values: AuthenticationFormValues) => {
    const response = await onSubmit(values);
    response && onResult?.(response?.data);
    return !!response;
  };

  return (
    <AuthenticationModal
      {...props}
      onClose={hideModal}
      enableProjectSelect={authenticationId ? false : enableProjectSelect}
      authenticationId={authenticationId}
      onSubmit={onSubmitForm}
    />
  );
};

export default AuthModalContainer;
