import { InfiniteQueryObserverOptions, QueryObserverOptions, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  PaginatedProjectList,
  Project,
  ProjectsApiProjectsCreateRequest,
  ProjectsApiProjectsCredentialsRetrieveRequest,
  ProjectsApiProjectsDestroyRequest,
  ProjectsApiProjectsListRequest,
  ProjectsApiProjectsNameListRequest,
  ProjectsApiProjectsRetrieveRequest,
  ProjectsApiProjectsShareCreateRequest,
  ProjectsApiProjectsTargetRetrieveRequest,
  ProjectsApiProjectsUnshareCreateRequest,
  ProjectsApiProjectsUpdateRequest,
  Target,
} from 'api-client';
import { AxiosError, AxiosResponse } from 'axios';
import { useApp } from '@shared/hooks';
import { ProjectsQueryKeys, UsersQueryKeys } from './queriesKeys';
import { getNextPageParam, useFlatCountFromResponse, useFlatResults } from './utils';

const useGetProjectsList = (
  request: ProjectsApiProjectsListRequest,
  config?: Partial<InfiniteQueryObserverOptions<AxiosResponse<PaginatedProjectList>, AxiosError>>,
) => {
  const { projectsApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery({
    queryKey: [ProjectsQueryKeys.projectsList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => projectsApi.projectsList({ ...request, page: pageParam }),
    getNextPageParam: getNextPageParam,
    ...config,
  });

  const projects = useFlatResults(data);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    projects,
    totalItemsCount: totalItemsCount,
    isProjectsListLoading: isLoading,
    isProjectsListFetching: isFetching,
    projectsHasNextPage: hasNextPage,
    fetchNextProjectsPage: fetchNextPage,
  };
};

const useGetDefaultProject = () => {
  const { projects = [], isProjectsListLoading } = useGetProjectsList({
    isDefault: true,
    ownProjects: true,
  }, {
    // Do not invalidate Default Project query when List Projects query is invalidated.
    queryKey: [ProjectsQueryKeys.projectsDefault],
  });
  const [defaultProject = null] = projects;
  return {
    defaultProject,
    isDefaultProjectLoading: isProjectsListLoading,
  };
};

const useGetProject = (
  request: ProjectsApiProjectsRetrieveRequest,
  config?: Partial<QueryObserverOptions<AxiosResponse<Project>, AxiosError>>,
) => {
  const { projectsApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [ProjectsQueryKeys.projectById, ...Object.values(request)],
    queryFn: () => projectsApi.projectsRetrieve(request),
    enabled: Boolean(request.id),
    ...config,
  });

  const project = data?.data;

  return {
    project,
    isProjectLoading: isLoading,
  };
};

const useCreateProject = () => {
  const { projectsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: ProjectsApiProjectsCreateRequest) => projectsApi.projectsCreate(request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [ProjectsQueryKeys.projectsList] }),
  });

  return {
    createProject: mutateAsync,
  };
};

const useUpdateProject = () => {
  const { projectsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: ProjectsApiProjectsUpdateRequest) => projectsApi.projectsUpdate(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [ProjectsQueryKeys.projectsList] });
      queryClient.resetQueries({ queryKey: [ProjectsQueryKeys.projectById] });
      queryClient.resetQueries({ queryKey: [ProjectsQueryKeys.projectByName] });
      queryClient.resetQueries({ queryKey: [ProjectsQueryKeys.targetByName] });
    },
  });

  return {
    updateProject: mutateAsync,
  };
};

const useShareProject = () => {
  const { projectsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: ProjectsApiProjectsShareCreateRequest) => projectsApi.projectsShareCreate(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [ProjectsQueryKeys.projectsList] });
      queryClient.resetQueries({ queryKey: [UsersQueryKeys.usersList] });
      queryClient.resetQueries({ queryKey: [ProjectsQueryKeys.projectById] });
    },
  });

  return {
    shareProject: mutateAsync,
  };
};

const useUnshareProject = () => {
  const { projectsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: ProjectsApiProjectsUnshareCreateRequest) => projectsApi.projectsUnshareCreate(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [ProjectsQueryKeys.projectsList] });
      queryClient.resetQueries({ queryKey: [UsersQueryKeys.usersList] });
      queryClient.resetQueries({ queryKey: [ProjectsQueryKeys.projectById] });
    },
  });

  return {
    unshareProject: mutateAsync,
  };
};

const useDeleteProject = () => {
  const queryClient = useQueryClient();
  const { projectsApi } = useApp();

  const { mutateAsync } = useMutation({
    mutationFn: (request: ProjectsApiProjectsDestroyRequest) => projectsApi.projectsDestroy(request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [ProjectsQueryKeys.projectsList] }),
  });

  return {
    deleteProject: mutateAsync,
  };
};

const useGetTargetByName = (signal?: AbortSignal) => {
  const { projectsApi } = useApp();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: ProjectsApiProjectsTargetRetrieveRequest) => projectsApi.projectsTargetRetrieve(request, { signal: signal }),
  });

  return {
    getTarget: mutateAsync,
    isLoading: isLoading,
  };
};

const useGetTargetByNameWithOptions = (
  request: ProjectsApiProjectsTargetRetrieveRequest,
  config?: Partial<QueryObserverOptions<AxiosResponse<Target>, AxiosError>>,
) => {
  const { projectsApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [ProjectsQueryKeys.targetByName, ...Object.values(request)],
    queryFn: () => projectsApi.projectsTargetRetrieve(request),
    enabled: Boolean(request.targetName),
    ...config,
  });

  const target = data?.data;

  return {
    target,
    isTargetLoading: isLoading,
  };
};

const useGetProjectByName = () => {
  const { projectsApi } = useApp();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: ProjectsApiProjectsNameListRequest) => projectsApi.projectsNameList(request),
  });

  return {
    getProjectByName: mutateAsync,
    isLoading: isLoading,
  };
};

const useGetAuthByName = (signal?: AbortSignal) => {
  const { projectsApi } = useApp();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: ProjectsApiProjectsCredentialsRetrieveRequest) =>
      projectsApi.projectsCredentialsRetrieve(request, { signal: signal }),
  });

  return {
    getAuthByName: mutateAsync,
    isLoading: isLoading,
  };
};

export {
  useGetProjectsList,
  useCreateProject,
  useUpdateProject,
  useGetProject,
  useShareProject,
  useDeleteProject,
  useGetDefaultProject,
  useUnshareProject,
  useGetTargetByName,
  useGetTargetByNameWithOptions,
  useGetProjectByName,
  useGetAuthByName,
};
