import { globalModels, globalQueries, globalUtils } from '@shared/duck';
import React from 'react';

interface ScansTableApiResult {
  scans: globalModels.ScansViewModelNew[],
  totalItemsCount: number,
  isScansListLoading: boolean,
  scansHasNextPage: boolean,
  fetchNextScansPage: () => void,
}

interface useScansTableApiProps {
  credentialsId: string;
  page: number;
  pageSize?: number;
  startDate?: Date | undefined;
}

export const useScansTableApi = ({
  credentialsId,
  startDate,
  page,
  pageSize,
}: useScansTableApiProps): ScansTableApiResult => {
  const REFETCH_INTERVAL = 20 * 1000;

  const {
    scans: dtoScans,
    totalItemsCount,
    isScansListLoading,
    scansHasNextPage = false,
    fetchNextScansPage,
  } = globalQueries.useGetScansList(
    {
      createdSince: startDate?.toISOString(),
      credentials: [credentialsId],
      page: page + 1,
      pageSize: pageSize,
    },
    {
      refetchInterval: REFETCH_INTERVAL,
      enabled: !!credentialsId
    },
  );

  const scans = React.useMemo(
    () =>
      dtoScans?.map(
        (item) => globalUtils.mapScansDtoToViewModelNew(item)) || [],
    [dtoScans],
  );

  return {
    scans,
    totalItemsCount,
    isScansListLoading,
    scansHasNextPage,
    fetchNextScansPage,
  };
};