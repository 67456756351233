import { Box, styled } from '@mui/material';

const ChartBox = styled(Box)`
  width: 100%;
  height: 100%;
  /** Note: 'position: relative;' is required for proper diagram size calculation */
  position: relative;
  
  /** Set specific height to disable "vertical stretching" animation on render */
  & canvas {
    height: 500px;
  }
`;

export { ChartBox };
