import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { allRoutesEnum } from '@shared/duck';

interface DocsLinkProps {
  to: string;
  style?: React.CSSProperties;
}

export const DocsLink: React.FunctionComponent<React.PropsWithChildren<DocsLinkProps>> = ({ children, style, to }) => {
  return (
    <Link style={style} component={RouterLink} target='_blank' to={allRoutesEnum.Documentation + `?redirect=${encodeURIComponent(to)}`}>
      {children}
    </Link>
  );
};