import { SeverityEnum } from '@api-client';
import { globalEnums } from '@shared/duck';

export const getVariantFromSeverity = (severityName: SeverityEnum): globalEnums.EBadgeVariants => {
  switch (severityName) {
    case SeverityEnum.Critical:
      return globalEnums.EBadgeVariants.NEGATIVE;
    case SeverityEnum.High:
      return globalEnums.EBadgeVariants.WARNING;
    case SeverityEnum.Medium:
      return globalEnums.EBadgeVariants.YELLOW;
    case SeverityEnum.Low:
      return globalEnums.EBadgeVariants.POSITIVE;
    case SeverityEnum.Info:
      return globalEnums.EBadgeVariants.PRIMARY;
    case SeverityEnum.Unknown:
      return globalEnums.EBadgeVariants.SECONDARY;
    case SeverityEnum.Unspecified:
      return globalEnums.EBadgeVariants.PRIMARY;
    default:
      return globalEnums.EBadgeVariants.PRIMARY;
  }
};