import { allRoutesEnum, globalEnums } from '@shared/duck';
import { NotificationType } from './enums';
import { billingNotificationEnums } from '.';
import { App } from '@app/components';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledPaymentLink } from '../billing-notification.styled';

export const getNotificationType = (status?: globalEnums.SubscriptionStatus): NotificationType => {
  switch (status) {
    case globalEnums.SubscriptionStatus.TRIALING:
      return NotificationType.FREE_TRIAL;
    case globalEnums.SubscriptionStatus.TRIAL_WILL_END:
      return NotificationType.FREE_TRIAL_ENDING;
    case globalEnums.SubscriptionStatus.INCOMPLETE:
      return NotificationType.INCOMPLETE;
    case globalEnums.SubscriptionStatus.ENDING:
      return NotificationType.ENDING;
    case globalEnums.SubscriptionStatus.PAST_DUE:
      return NotificationType.DUE;
    case globalEnums.SubscriptionStatus.OVERDUE:
      return NotificationType.OVERDUE;
    case globalEnums.SubscriptionStatus.PAUSED:
      return NotificationType.PAUSED;
    // Active subscription = no notification:
    case globalEnums.SubscriptionStatus.ACTIVE:
      return NotificationType.NONE;
    // No subscription = no notification:
    case globalEnums.SubscriptionStatus.NONE:
    case globalEnums.SubscriptionStatus.CANCELED:
    case undefined:
      return NotificationType.NONE;
  }
};

export const getNotificationContent = (type: billingNotificationEnums.NotificationType, app: App) => {
  let result: React.ReactElement | null;
  const endingDate = app.users.endingDate;
  const pastDueDate = app.users.pastDueDate;
  const latestInvoiceDate = app.users.latestInvoiceDate;
  const scansPerformed = app.users.scansPerformed;
  const freeScansLimit = app.users.freeScansLimit;
  const scansPerformedNotification = (!Number.isNaN(scansPerformed) && !Number.isNaN(freeScansLimit)) ? <><b>{scansPerformed}/{freeScansLimit}</b> scans. </> : '';

  switch (type) {
    case billingNotificationEnums.NotificationType.FREE_TRIAL:
      result = (
        <Typography variant='default' flexGrow='1' textAlign='center' color='white' key='free'>
          You’re on a Free Trial. {scansPerformedNotification}To access more of NightVision <Link style={{ fontWeight: 'bold' }} to={allRoutesEnum.SelectBillingPlan}>upgrade your account</Link>.
        </Typography>
      );
      break;
    case billingNotificationEnums.NotificationType.FREE_TRIAL_ENDING:
      result = (
        <Typography variant='default' flexGrow='1' textAlign='center' color='white' key='free'>
          Your Free Trial is ending on {endingDate}. {scansPerformedNotification}To continue using Professional Plan please provide a payment method <StyledPaymentLink onClick={() => app.openStripeCustomerPortal()} text='here' />.
        </Typography>
      );
      break;
    case billingNotificationEnums.NotificationType.INCOMPLETE:
      // TODO: remove, block access to application until paid / canceled
      result = (
        <Typography variant='default' flexGrow='1' textAlign='center' color='white' key='incomplete'>
          Please wait while we are validating your payment status. You can check the status <StyledPaymentLink onClick={() => app.openStripeCustomerPortal()} text='here' />.
        </Typography>
      );
      break;
    case billingNotificationEnums.NotificationType.ENDING:
      result = (
        <Typography variant='default' flexGrow='1' textAlign='center' color='white' key='ending'>
          Your subscription is ending on {endingDate}. To avoid interruption of service <StyledPaymentLink onClick={() => app.openStripeCustomerPortal()} text='Renew subscription' />
        </Typography>
      );
      break;
    case billingNotificationEnums.NotificationType.DUE:
      result = (
        <Typography variant='default' flexGrow='1' textAlign='center' color='white' key='due'>
          We remain unable to charge your credit/debit card. Please process your payment before {pastDueDate}. <StyledPaymentLink onClick={() => app.openStripeCustomerPortal()} text='Pay now' />
        </Typography>
      );
      break;
    case billingNotificationEnums.NotificationType.OVERDUE:
      result = (
        <Typography variant='default' flexGrow='1' textAlign='center' color='white' key='overdue'>
          Your {latestInvoiceDate} Invoice is overdue. <StyledPaymentLink onClick={() => app.openStripeCustomerPortal()} text='Pay now' />
        </Typography>
      );
      break;
    case billingNotificationEnums.NotificationType.PAUSED:
      result = (
        <Typography variant='default' flexGrow='1' textAlign='center' color='white' key='overdue'>
          Your subscription is paused. You can renew it <StyledPaymentLink onClick={() => app.openStripeCustomerPortal()} text='here' />.
        </Typography>
      );
      break;
    case billingNotificationEnums.NotificationType.NONE:
      result = null;
  }
  return result;
};
