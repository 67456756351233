import React from 'react';
import {
  DurationPeriod,
  getUTCIntervalForNow,
  useDurationSelect,
} from './components/duration-select/duck/utils';
import { Spinner } from '@contentful/f36-components';
import { globalQueries, globalTypes } from '@shared/duck';
import { DurationSelect, IssuesTimeChart } from './components';
import { SelectOld, UIInfoCard } from '@shared/components';
import { Stack } from '@mui/material';

const IssueIncidents = 1;
const TotalOpenIssues = 2;

const IssueOptions = [
  {
    label: 'Issue Incidents',
    value: IssueIncidents,
  },
  {
    label: 'Total Open Issues',
    value: TotalOpenIssues,
  },
];

const InitialPeriod = DurationPeriod.Week;
const InitialIssueOption = TotalOpenIssues;

interface IssueTimeProps {
  selectedProjects: globalTypes.UIOptionItem<string>[]
  statsProp: globalQueries.StatisticsProp
}

const IssuesTime: React.FC<IssueTimeProps> = ({
  selectedProjects,
  statsProp,
}) => {
  const { period, onPeriodChange } = useDurationSelect(InitialPeriod);
  const perMonth = period == DurationPeriod.Year;

  const [issueOption, setIssueOption] = React.useState<number>(InitialIssueOption);
  const isRunningTotal = issueOption == TotalOpenIssues;

  const onIssueOptionChange = (value: number) => {
    setIssueOption(value);
  };

  const interval = React.useMemo(() => getUTCIntervalForNow({ period, generateDates: true }), [period]);

  const {
    isUniqueIssuesCountBySeverityAndDateLoading: isIssuesCountBySeverityAndDateLoading,
    uniqueIssuesCountBySeverityAndDate,
  } =
    globalQueries.useGetIssuesCountBySeverityInPeriodList(
      {
        end: interval.before,
        start: interval.since,
        project: selectedProjects.map(proj => proj.value),
      },
      // options
      {
        dates: interval.datesToDisplay,
        statsProp,
        perMonth,
        runningTotal: isRunningTotal,
      },
    );

  return (
    <UIInfoCard
      title='Issues'
      subtitle='(UTC)'
      action={
        <Stack flexDirection='row' gap='1rem'>
          {isIssuesCountBySeverityAndDateLoading && <Spinner size='medium' />}
          <SelectOld
            name='issues-view'
            options={IssueOptions}
            initialValue={IssueOptions.find(x => x.value === InitialIssueOption)}
            withEmptyItem={false}
            withSearch={false}
            onChange={e => onIssueOptionChange(Number(e.target.value))}
          />
          <DurationSelect name='issues-period' initialPeriod={InitialPeriod} onChange={onPeriodChange} />
        </Stack>
      }
    >
      <IssuesTimeChart data={uniqueIssuesCountBySeverityAndDate} perMonth={perMonth} />
    </UIInfoCard>
  );
};

export default IssuesTime;
