import { IconProps } from './BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from './icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='14'
    height='13'
    viewBox='0 0 14 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <path
      d='M5.68671 1.43953C6.25738 0.407527 7.74072 0.407527 8.31138 1.43953L13.4754 10.7735C14.0287 11.7735 13.3054 13.0002 12.1627 13.0002H1.83605C0.692714 13.0002 -0.0306194 11.7735 0.522714 10.7735L5.68671 1.44019V1.43953ZM7.66471 10.3335C7.66754 10.2443 7.65241 10.1555 7.62023 10.0723C7.58804 9.98903 7.53946 9.91312 7.47737 9.84903C7.41528 9.78495 7.34094 9.73399 7.25877 9.69919C7.17661 9.66439 7.08828 9.64646 6.99905 9.64646C6.90981 9.64646 6.82149 9.66439 6.73932 9.69919C6.65715 9.73399 6.58282 9.78495 6.52072 9.84903C6.45863 9.91312 6.41005 9.98903 6.37787 10.0723C6.34569 10.1555 6.33056 10.2443 6.33338 10.3335C6.33885 10.5064 6.41139 10.6704 6.53562 10.7908C6.65986 10.9112 6.82606 10.9785 6.99905 10.9785C7.17203 10.9785 7.33823 10.9112 7.46247 10.7908C7.58671 10.6704 7.65924 10.5064 7.66471 10.3335ZM7.49205 5.09819C7.47477 4.97264 7.41046 4.85832 7.31212 4.77838C7.21378 4.69843 7.08874 4.65881 6.9623 4.66753C6.83586 4.67625 6.71745 4.73267 6.63102 4.82536C6.54459 4.91806 6.49658 5.04012 6.49672 5.16686L6.49938 8.16753L6.50405 8.23553C6.52132 8.36108 6.58564 8.4754 6.68398 8.55535C6.78232 8.63529 6.90735 8.67491 7.03379 8.66619C7.16023 8.65747 7.27864 8.60105 7.36508 8.50836C7.45151 8.41566 7.49951 8.2936 7.49938 8.16686L7.49672 5.16553L7.49205 5.09819Z'
      fill='#DA294A'
    />
  </svg>
);

function DisruptedIcon(props: IconProps) {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default DisruptedIcon;
