import React from 'react';
import { IconButton, InputAdornment, OutlinedInputProps } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { BorderedMuiInput } from './edit-project-name.styled';
import { useFormikContext } from 'formik';
import { globalEnums, globalQueries } from '@shared/duck';
import { FormControl, Spinner } from '@contentful/f36-components';
import { RenameProjectFormValues } from '../project-details-info';

const EditProjectName: React.FC<OutlinedInputProps> = ({
  ...innerTextInputProps
}: OutlinedInputProps) => {
  const { values, setFieldValue, isSubmitting, handleSubmit, isValid, resetForm } = useFormikContext<RenameProjectFormValues>();

  const [initialName, setInitialName] = React.useState('');
  React.useEffect(() => {
    setInitialName(values.name || '');
  }, []);

  const inputRef = React.useRef<HTMLDivElement>();
  const reinitialize = () => {
    resetForm();
    inputRef.current?.focus();
  };

  const { getProjectByName, isLoading } = globalQueries.useGetProjectByName();
  const onKeyDown = React.useCallback(async (e: any, blur?: boolean) => {
    const testName = async () => {
      if (values.name) {
        if (values.name === initialName) {
          setFieldValue('exists', false);
          setFieldValue('isNameTested', true);
          isValid && handleSubmit();
          return;
        }

        const res = await getProjectByName({ name: values.name, ownProjects: true });
        setFieldValue('isNameTested', true);
        if (res.data.count) {
          setFieldValue('exists', true);
        }
        else {
          setFieldValue('exists', false);
          isValid && handleSubmit();
        }
      }
    };

    if ((e.key === globalEnums.EKeyboardKeys.ENTER || blur && e.relatedTarget === null) && !isSubmitting) {
      await testName();
    }
  }, [values.name, initialName, isValid]);

  const onInputChange = (e: any) => {
    setFieldValue('exists', false);
    setFieldValue('isNameTested', false);
    innerTextInputProps.onChange?.(e);
  };

  return (
    <>
      <BorderedMuiInput
        {...innerTextInputProps}
        placeholder='Project name'
        fullWidth
        inputRef={inputRef}
        autoFocus
        error={values.exists ? true : !isValid}
        showBorders={values.exists ? true : !isValid}
        endAdornment={
          <>
            <InputAdornment position='start'>
              {isLoading || isSubmitting ? (
                <Spinner size='medium' />
              ) : (
                <IconButton edge='end' onClick={reinitialize}>
                  <X size={16} />
                </IconButton>
              )}
            </InputAdornment>
            {innerTextInputProps.endAdornment}
          </>
        }
        onKeyDown={onKeyDown}
        onBlur={(e) => onKeyDown(e, true)}
        onChange={onInputChange}
      />

      {/* Error has been added locally because Formik doesn't handle the condition itself */}
      {values.exists && (
        <FormControl.ValidationMessage style={{ position: 'absolute' }}>
          {'Project name already exists'}
        </FormControl.ValidationMessage>
      )}
    </>
  );
};

export default EditProjectName;