import { Scan } from '@api-client';
import dayjs from 'dayjs';
import { ApplicDetailsTargetViewModel } from './types';
import { globalConstants, globalEnums } from '@shared/duck';

export const mapApplicDetailsTableDtoToViewModel = (dto: Scan): ApplicDetailsTargetViewModel => {
  return {
    id: dto.target.id,
    lastScanId: dto.id,
    name: dto.target.name || 'N/A',
    hasApiDetails: dto.target.type?.toString() === globalEnums.ETargetType.OpenAPI,
    description: '', /** TODO: remove after moving to a new table */
    location: dto.location || '',
    type: dto.target.type?.toString() as globalEnums.ETargetType,
    createdBy: dto.target.created_by,
    projectName: dto.project_name || '',
    projectId: dto.target.project,
    createdAt: dayjs(dto.created_at).format(globalConstants.FULL_MONTH_DATE_TIME),
    updatedAt: dayjs(dto.last_updated_at).format(globalConstants.FULL_MONTH_DATE_TIME),
    credentials: dto.credentials,
    internetAccessible: dto.target.internet_accessible,
    vulnerable_paths_statistics: [
      { severityIndex: 1, count: dto.vulnerable_paths_statistics?.Critical || 0, severity: 'Critical' },
      { severityIndex: 2, count: dto.vulnerable_paths_statistics?.High || 0, severity: 'High' },
      { severityIndex: 3, count: dto.vulnerable_paths_statistics?.Medium || 0, severity: 'Medium' },
      { severityIndex: 4, count: dto.vulnerable_paths_statistics?.Low || 0, severity: 'Low' },
      { severityIndex: 5, count: dto.vulnerable_paths_statistics?.Informational || 0, severity: 'Info' },
    ],
  };
};