import { Table } from '@contentful/f36-components';
import { tableTypes } from '@shared/components';
import { StyledTableCell } from '@shared/components/table/components/cell';
import { StyledHeaderCell, StyledHeaderRow } from './table-header.styled';
import { Box, Tooltip, Stack } from '@mui/material';

interface UiTableHeaderProps<DataItem> extends Partial<tableTypes.BasicTableProps<DataItem>> {
  columns: tableTypes.ColumnProps<DataItem>[];
  isStickyHeader?: boolean;
  withItemActions: boolean;
  bordered?: boolean;
  withExternalBorders: boolean;
}

// TODO: icons

const UITableHeader = <DataItem,>({
  columns,
  isStickyHeader,
  withItemActions,
  bordered = false,
  itemSelection,
  withExternalBorders,
}: UiTableHeaderProps<DataItem>) => {

  const selectable = Boolean(itemSelection);

  return (
    <Table.Head isSticky={isStickyHeader}>
      <StyledHeaderRow bordered={bordered} withExternalBorders={withExternalBorders}>
        {selectable ? (

          <StyledTableCell small={true} >
            {itemSelection?.customHeadCheckboxRenderer?.()}
          </StyledTableCell>
        ) : null}

        {columns?.map((col) => {
          const headContent = col.customHeadRenderer ? col.customHeadRenderer(col) : col.title;

          return (
            <StyledHeaderCell
              key={col.columnKey.toString()}
              width={col.width}
              verticalAlign={col.headerInfo?.verticalAlign}
            >
              <Tooltip title={col.headerInfo?.cellTooltipText || ''} placement={col.headerInfo?.tooltipPlace || 'top'}>
                <Stack
                  direction='row'
                  alignItems='center'
                >
                  {headContent}

                  {col.headerInfo?.icon &&
                    <Tooltip title={col.headerInfo.iconTooltipText || ''} placement={col.headerInfo?.tooltipPlace || 'bottom'}>
                      <Box>
                        {col.headerInfo.icon}
                      </Box>
                    </Tooltip>
                  }
                </Stack>
              </Tooltip>
            </StyledHeaderCell>
          );
        })}
        {withItemActions ? <StyledHeaderCell width='1.7rem' /> : null}
      </StyledHeaderRow>
    </Table.Head>
  );
};

export default UITableHeader;