import { AddCredentialsPage, CustomCredentialsType } from '../../duck';
import React from 'react';
import { useFormikContext } from 'formik';
import { AuthenticationFormValues } from '../../authentication-modal';
import { Button, CircularProgress } from '@mui/material';

interface AuthModalControlsProps {
  page: AddCredentialsPage,
  authenticationId?: string,
  returnToGeneralPage: () => void,
  openReRecordPage: () => void,
  onClose: (_: { invalidateQueries: boolean }) => void,
  onGenerateCommandClick: () => void,
  isGenerateLoading: boolean;
}

const AuthModalControls: React.FC<AuthModalControlsProps> = ({
  page,
  authenticationId,
  returnToGeneralPage,
  openReRecordPage,
  onClose,
  onGenerateCommandClick,
  isGenerateLoading,
}) => {
  const { values, isValid: isValidWithoutName, isSubmitting, handleSubmit, dirty, initialValues } = useFormikContext<AuthenticationFormValues>();
  const isValid = isValidWithoutName && (!!values.name || authenticationId) && values.nameExists === false;
  const isScript = values.type === CustomCredentialsType.Script;
  const isUpdateScript = isScript && authenticationId;
  const isUpdateUrl = values.playwrightUrl !== initialValues.playwrightUrl;

  const isGeneralPage = page === AddCredentialsPage.GENERAL;
  const isDownloadPage = page === AddCredentialsPage.DOWNLOAD;
  const isGenerateCommandPage = page === AddCredentialsPage.GENERATE_COMMAND;
  const isRerecordCommandPage = page === AddCredentialsPage.RERECORD;

  const disabled = !(isGenerateCommandPage || isRerecordCommandPage) && (!isValid || isSubmitting || !dirty || isGenerateLoading);
  const endIcon = (isGenerateCommandPage ? false : isGenerateLoading || isSubmitting)
    ? (<CircularProgress color='inherit' size='1.25rem' />)
    : (<></>);

  const onLeftButtonClick = () => {
    if (isUpdateScript && isGeneralPage) openReRecordPage();
    else isDownloadPage ? returnToGeneralPage() : onClose({ invalidateQueries: false });
  };

  const leftButtonText = isUpdateScript && isGeneralPage
    ? 'Re-record Auth Script only'
    : 'Cancel';

  const onRightButtonClick = () => {
    if (isScript && (isUpdateUrl || !isUpdateScript)) {
      isGenerateCommandPage || isRerecordCommandPage ? onClose({ invalidateQueries: true }) : onGenerateCommandClick();
    }
    else {
      isGeneralPage ? handleSubmit() : onClose({ invalidateQueries: true });
    }
  };

  const rightButtonText = isScript && (isUpdateUrl || !isUpdateScript)
    ? isGenerateCommandPage ? 'Done'
      : isRerecordCommandPage ? 'I have finished recording Auth' : 'Generate Command'
    : isGeneralPage
      ? authenticationId ? 'Update' : 'Create'
      : 'Done';

  return (
    <>
      <Button
        onClick={onLeftButtonClick}
      >
        {leftButtonText}
      </Button>

      {page !== AddCredentialsPage.DOWNLOAD && (
        <Button
          variant='contained'
          disabled={disabled}
          endIcon={endIcon}
          onClick={onRightButtonClick}
        >
          {rightButtonText}
        </Button>
      )}
    </>
  );
};

export default AuthModalControls;