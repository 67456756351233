import React from 'react';
import { Grid } from '@mui/material';
import { globalConstants } from '@shared/duck';
import { Description, Header } from './cli-commands-layout.styled';
import { DocsLink } from '@shared/components';

interface CliCommandsLayoutProps extends React.PropsWithChildren {
  multipleCommands?: boolean;
}

const CliCommandsLayout: React.FC<CliCommandsLayoutProps> = ({
  multipleCommands = true,
  children
}) => {
  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item sm={4} xs={12}>
        <Header>{multipleCommands ? 'CLI commands' : 'CLI command'}</Header>
        <Description>
          {`Copy and paste ${multipleCommands ? 'these commands' : 'this command'} in the NightVision CLI to start a Scan.\n\n`}
          {'If you have not already, you can install the CLI from '}
          <DocsLink to={globalConstants.DOCS_PATHS.INSTALLING_CLI}>
            here
          </DocsLink>
          {'.\n\nFor further guidance, read our '}
          <DocsLink to={globalConstants.DOCS_PATHS.CLI_CHEAT_SHEET_SCANNING}>
            CLI cheatsheet
          </DocsLink>{'.'}
        </Description>
      </Grid>
      <Grid item sm={8} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default CliCommandsLayout;