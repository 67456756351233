import React from 'react';
import { allSubroutesEnum } from 'pages/all-subroutes-enum';
import { Route, Routes } from 'react-router-dom';
import TargetsPage from './targets-page';
import { TargetDetailsPage } from '@shared/components';
import { NotFoundErrorWrapper } from '@pages';

const TargetsRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={allSubroutesEnum.Root} element={<TargetsPage />} />
      <Route path={allSubroutesEnum.TargetDetails} element={<TargetDetailsPage />} />
      <Route path='*' element={<NotFoundErrorWrapper />} />
    </Routes>
  );
};

export default TargetsRouter;
