import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';
import { Tabs } from '@mui/material';

const BorderedTabs = styled(Tabs)`
  overflow: auto;
  border-bottom: 1px solid ${baseTokens.gray200};

  button {
    text-transform: none;
  }
`;

export { BorderedTabs };
