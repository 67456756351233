import { CSSObject, IconButton, IconButtonProps, Theme, styled } from '@mui/material';
import MuiDrawer, { SwipeableDrawerProps as MuiDrawerProps } from '@mui/material/SwipeableDrawer';
import MuiListItemButton, { ListItemButtonProps as MuiListItemButtonProps } from '@mui/material/ListItemButton';
import { muiPalette } from '@shared/general-mui-theme';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, isSmallScreen: boolean): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: !isSmallScreen ? `calc(${theme.spacing(4)} + 1px)` : 0,
});

interface DrawerProps extends MuiDrawerProps {
  isSmallScreen: boolean;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isSmallScreen'
})<DrawerProps>(
  ({ theme, open, isSmallScreen }) => ({
    display: 'block !important',
    visibility: 'visible',

    '& .MuiPaper-root': {
      pointerEvents: 'unset !important',
      backgroundColor: muiPalette.grey?.[800],
      visibility: 'visible !important',
      transform: 'unset !important',
      zIndex: 99,
    },

    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      '& .MuiBackdrop-root': {
        opacity: isSmallScreen ? '1 !important' : 0,
        visibility: isSmallScreen ? 'visible !important' : 'hidden',
      }
    }),

    ...(!open && {
      ...closedMixin(theme, isSmallScreen),
      '& .MuiDrawer-paper': closedMixin(theme, isSmallScreen),
    }),
  }),
);

const DrawerHeader = styled('div')(
  () => ({
    display: 'flex',
    height: '4.5rem',
    minHeight: '4.5rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }),
);

interface ListItemButtonProps extends MuiListItemButtonProps {
  isActive?: boolean;
}
const ListItemButtonStyled = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<ListItemButtonProps>(
  ({ isActive }) => ({
    padding: '0.5rem 1.2rem',

    '& .MuiListItemText-root': {
      color: muiPalette.grey?.[400],
      'span': {
        fontSize: '0.875rem',
      }
    },

    '& .MuiListItemIcon-root': {
      padding: '0.25rem',
      borderRadius: '0.25rem',

      'svg': {
        'path': {
          stroke: muiPalette.grey?.[400],
        }
      }
    },

    '&:hover': {
      '& .MuiListItemText-root': {
        color: 'white',
      },
      '& .MuiListItemIcon-root': {
        'svg': {
          'path': {
            stroke: 'white',
          }
        }
      }
    },

    ...(isActive && {
      '& .MuiListItemText-root': {
        color: 'white',
        'span': {
          fontSize: '0.875rem',
        }
      },

      '& .MuiListItemIcon-root': {
        backgroundColor: muiPalette.grey?.[700],
        padding: '0.25rem',
        borderRadius: '0.25rem',

        'svg': {
          'path': {
            stroke: 'white',
          }
        },
      }
    }),
  }),
);

interface CollapsibleButtonProps extends IconButtonProps {
  open?: boolean;
  isSmallScreen?: boolean;
}

const CollapsibleButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isSmallScreen',
})<CollapsibleButtonProps>(({ theme, open, isSmallScreen }) => ({
  borderRadius: '50%',
  zIndex: isSmallScreen ? 2000 : 100,
  position: 'absolute',
  bottom: isSmallScreen ? 'unset' : '1.5rem',
  top: isSmallScreen ? '1.2rem' : 'unset',

  transition: theme.transitions.create(['left'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    left: '14rem',
  }),

  ...(!open && {
    left: isSmallScreen ? '-3rem' : '3rem',
    transform: 'rotate(180deg)',
  }),
}));

export { Drawer, DrawerHeader, ListItemButtonStyled, CollapsibleButton };