import { Box, MenuItem, SelectProps, Stack, SxProps, Theme, Typography } from '@mui/material';
import { MuiSelectStyled } from './mui-select.styled';
import { globalTypes } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';

type MuiSelectProps = {
  label?: string;
  labelSx?: SxProps<Theme>;
  hidePopupIndicator?: boolean;
  required?: boolean;
  error?: boolean;
  options: globalTypes.MuiSelectOptions[];
  placeholder?: string;
} & SelectProps;

const MuiSelect: React.FC<MuiSelectProps> = ({
  label,
  labelSx,
  hidePopupIndicator = false,
  required = false,
  error = false,
  options,
  placeholder,
  ...props
}) => {
  return (
    <Box
      flexDirection='column'
    >
      {label && (
        <Stack
          gap={0.25}
          sx={{ paddingBottom: '0.5rem', flexDirection: 'row' }}
        >
          <Typography
            variant={'subtitle2'}
            sx={{ ...labelSx }}
          >
            {label}
          </Typography>

          {required && (
            <Typography
              variant={'subtitle2'}
              color={`${error ? 'error' : muiPalette.grey?.['500']}`}
              sx={{ opacity: `${error ? '1' : '0.5'}` }}
            >
              {'(required)'}
            </Typography>
          )}
        </Stack>
      )}

      <MuiSelectStyled
        {...props}
        hidePopupIndicator={hidePopupIndicator}
        displayEmpty={!!placeholder}
        renderValue={(value) => {
          if (placeholder && !value) {
            return (
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  color: muiPalette.grey?.[500],
                  opacity: 0.5,
                }}
              >
                {placeholder}
              </Typography>
            );
          }

          return <>{value}</>;
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.key} value={option.key} sx={{ fontSize: '0.875rem' }}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelectStyled>
    </Box>
  );
};

export default MuiSelect;