import styled from '@emotion/styled';
import { UICodeFragment } from '@shared/components';
import baseTokens from '@contentful/f36-tokens';
import { BreakpointX } from 'views/utils/design';
import { globalTypes } from '@shared/duck';
import { Grid } from '@contentful/f36-components';

const ContentGrid = styled(Grid)`
  margin-top: ${baseTokens.spacingL};
  border: 1px solid ${baseTokens.gray300};
  border-radius: ${baseTokens.borderRadiusMedium};
  background: ${baseTokens.colorWhite};
  height: 85%;

  .grid-item-issues,
  .grid-item-info {
    min-width: 0;
    min-height: 0;
    overflow: auto;
  }

  .grid-item-issues {
    border-right: 1px solid ${baseTokens.gray300};
  }

  @media (max-width: ${BreakpointX.Laptop.max}px) {
    height: auto;

    .grid-item-issues {
      grid-area: auto / span 2;
      border-right: none;
    }

    .grid-item-info {
      grid-area: auto / span 2;
      border-top: 1px solid ${baseTokens.gray300};
    }
  }
`;

const ContentContainer = styled.div<{ hasTarget?: globalTypes.EmotionBooleanType }>`
  flex-grow: 1;
  overflow: auto;

  & > .vulnerable-paths-table {
    .targetName {
      width: 6.25rem;
    }

    .httpMethod {
      width: 5rem;
    }

    @media (max-width: ${BreakpointX.Laptop.max}px) {
      min-width: ${BreakpointX.Mobile.max}px;
    }

    @media (min-width: ${BreakpointX.DesktopHD.min}px) {
      .targetName {
        width: 10rem;
      }
    }

    @media (min-width: ${BreakpointX.DesktopFHD.min}px) {
      .targetName {
        width: 12.5rem;
      }
    }
  }
`;

const StyledCodeFragment = styled(UICodeFragment)`
  & pre[class*="language-"] {
    height: 25rem;
    margin: 0;

    > code {
      white-space: break-spaces;
    }
  }
`;

export { ContentGrid, StyledCodeFragment, ContentContainer };
