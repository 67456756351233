import { createRef, useCallback, useEffect } from 'react';

const useInfiniteScroll = <Item, HTMLContainerElement extends HTMLElement>({
  onScrollEnd,
  itemSelector,
  items,
}: {
  onScrollEnd?: () => void
  itemSelector: string
  items: Item[]
}) => {
  const containerRef = createRef<HTMLContainerElement>();

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (onScrollEnd && entry.isIntersecting) {
        onScrollEnd();
      }
    },
    [onScrollEnd],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    });

    const list = containerRef.current?.querySelectorAll(itemSelector);

    const lastChild = list && list[list.length - 1];

    if (lastChild) {
      observer.observe(lastChild);
    }

    return () => {
      if (lastChild) {
        observer.unobserve(lastChild);
      }
    };
  }, [containerRef, observerCallback, items]);

  return {
    containerRef,
  };
};

export default useInfiniteScroll;
