import { useCallback, useRef, useState } from 'react';

export interface ModalApi<T> {
  isModalShown: boolean;
  closeModal: (value?: T) => void;
  openModal: () => Promise<T | undefined>;
}

const useModal = <T>(): ModalApi<T> => {
  const [isShown, setIsShown] = useState(false);
  const responsePromise = useRef<Promise<any>>();
  const resolveWith = useRef<(value?: any) => void>();

  const openModal = useCallback(() => {
    setIsShown(true);
    responsePromise.current = new Promise<any>(resolve => {
      resolveWith.current = resolve;
    });
    return responsePromise.current;
  }, []);

  const closeModal = useCallback((value?: T) => {
    setIsShown(false);

    if (resolveWith.current) {
      resolveWith.current(value);
    }
  }, []);

  return {
    isModalShown: isShown,
    closeModal,
    openModal,
  };
};

export default useModal;
