import { AutocompleteChangeReason } from '@mui/material';
import { UIOptionItem } from '@shared/duck/types';
import { ProjectFilter } from './project-filter';

import React from 'react';
import { useSearchParams } from 'react-router-dom';

interface ProjectFilterWrapperProps<T> {
  selectedProjects?: UIOptionItem<T>[];
  setSelectedProjects?: (newValues: UIOptionItem<T>[], options?: UIOptionItem<T>[], reason?: AutocompleteChangeReason) => void;
  setToSearchParams?: boolean;
}

export const ProjectFilterWrapper = <T,>({
  selectedProjects,
  setSelectedProjects,
  setToSearchParams = true,
}: ProjectFilterWrapperProps<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onProjectChange = React.useCallback((newValues: UIOptionItem<T>[], options?: UIOptionItem<T>[], reason?: AutocompleteChangeReason) => {
    setSelectedProjects?.(newValues, options, reason);
    setToSearchParams && setSearchParams((urlParams) => {
      if (newValues.length) {
        const newSearchValues = newValues.map(filter =>
          `${filter.label}/${filter.value}`).join(',') || '';
        urlParams.set('project', newSearchValues);
      } else {
        urlParams.delete('project');
      }
      return urlParams;
    });
  }, [setSearchParams]);

  React.useEffect(() => {
    if (setToSearchParams) {
      const options = searchParams.get('project');
      if (options) {
        const newValuesString = options?.split(',');

        const newValues = newValuesString?.map(filter => {
          const label = filter.split('/')[0];
          const value = filter.split('/')[1];
          return ({
            value: value, label: label, key: value,
          } as UIOptionItem<T>);
        });

        if (newValues) {
          setSelectedProjects?.(newValues);
        }
      }
    }
  }, [searchParams]);

  return (
    <ProjectFilter
      selectedProjects={selectedProjects}
      setSelectedProjects={onProjectChange}
    />
  );
};