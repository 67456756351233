import React from 'react';
import { Project } from '@api-client';
import { AutocompleteChangeReason } from '@mui/material';
import { MuiFilterAutocomplete } from '@shared/components';
import { globalQueries } from '@shared/duck';
import { UIOptionItem } from '@shared/duck/types';
import { useDebounce } from '@shared/hooks';

interface ProjectFilterProps<T> {
  selectedProjects?: UIOptionItem<T>[];
  setSelectedProjects?: (newValues: UIOptionItem<T>[], options?: UIOptionItem<T>[], reason?: AutocompleteChangeReason) => void;
}

export const ProjectFilter = <T,>({
  selectedProjects,
  setSelectedProjects,
}: ProjectFilterProps<T>) => {
  const { debouncedValue, onChange: onInputChange, value = '' } = useDebounce<string>();

  const {
    projects = [],
    fetchNextProjectsPage,
    projectsHasNextPage,
    isProjectsListLoading,
    isProjectsListFetching,
  } = globalQueries.useGetProjectsList({
    order: ['name'],
    pageSize: 25,
    filter: debouncedValue,
  });

  const createNewValues = (project: Project): UIOptionItem<T> => (
    { key: project.id, label: project.name, value: project.id as T }
  );

  const { getProjectByName } = globalQueries.useGetProjectByName();
  const [isProjectsChecked, setIsProjectsChecked] = React.useState(false);
  React.useEffect(() => {
    const checkProjects = async () => {
      if (selectedProjects?.length && !isProjectsChecked) {
        setIsProjectsChecked(true);
        const optionsToDelete: UIOptionItem<T>[] = [];
        const newOptions: UIOptionItem<T>[] = [];
        await Promise.all(
          selectedProjects.map(async project => {
            const { data } = await getProjectByName({
              name: project.label
            });

            if (data.count) {
              if (data.results.some(res => res.id === project.value && res.name === project.label)) {
                newOptions.push(project);
              }
              else {
                optionsToDelete.push(project);
              }
            }
            else {
              optionsToDelete.push(project);
            }
          })
        );
        if (optionsToDelete.length) {
          setSelectedProjects?.(newOptions, optionsToDelete, 'removeOption');
        }
      }
    };

    checkProjects();
  }, [selectedProjects]);

  return (
    <MuiFilterAutocomplete<UIOptionItem<T>>
      filterName='Project'
      options={projects.map(proj => createNewValues(proj))}
      loading={isProjectsListFetching || isProjectsListLoading}
      inputValue={value}
      onInputChange={onInputChange}
      selectedValues={selectedProjects}
      setSelectedValues={setSelectedProjects}
      getOptionKey={option => option.key || (option.value as string)}
      getOptionLabel={option => option.label}
      fetchNextPage={() => projectsHasNextPage && fetchNextProjectsPage()}
    />
  );
};