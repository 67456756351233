import { BoxArrowDown, BoxArrowUp, ClockClockwise } from '@phosphor-icons/react';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { useApp, useLaunchDarklyFlags } from '@shared/hooks';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { scansTableConsts, scansTableHooks, scansTableUtils } from './duck';
import { tableTypes, MuiTable } from '@shared/components';
import { allRoutesEnum, globalConstants, globalModels, globalQueries } from '@shared/duck';
import { ScansGray } from '@assets';
import { ItemSelectionApi } from '@shared/hooks/use-item-selection';
import { ScanFiltersContext } from '../../filters-context';
import * as apiClient from 'api-client';
import { UIOptionItemNew } from '../../scans-page-wrapper';
import { FilterName } from '../../duck';
import { enqueueSnackbar } from 'notistack';

interface ScansTableProps {
  itemSelectionApi: ItemSelectionApi<globalModels.ScansViewModelNew>;
  setOpenPlanner: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPlannerScans: React.Dispatch<React.SetStateAction<globalModels.ScansViewModelNew[] | undefined>>;
}

const ScansTable: React.FC<ScansTableProps> = ({
  itemSelectionApi,
  setOpenPlanner,
  setSelectedPlannerScans,
}) => {
  const { scanPlanner, archivedScans } = useLaunchDarklyFlags();
  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();
  const { deleteScanHandler } = scansTableUtils.useDeleteScanHandler();
  const { updateScan } = globalQueries.useUpdateScan();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const app = useApp();
  const isScanLimitReached = app.checkIfScanLimitReached();

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const { filterDictionary } = React.useContext(ScanFiltersContext);

  const createdSince = filterDictionary[FilterName.STARTDATE]?.selected.length
    ? filterDictionary[FilterName.STARTDATE].selected[0].value
    : undefined;
  let createdBefore = filterDictionary[FilterName.ENDDATE]?.selected.length
    ? filterDictionary[FilterName.ENDDATE].selected[0].value
    : undefined;
  createdBefore = createdBefore && dayjs(createdBefore).add(1, 'day').toISOString(); // add 1 day to show all scans up to the end date
  const project = filterDictionary[FilterName.PROJECT]?.selected.map((item: UIOptionItemNew<string>) => item.value);
  const application = filterDictionary[FilterName.APPLICATION]?.selected.map((item: UIOptionItemNew<string>) => item.value);
  const authentication = filterDictionary[FilterName.AUTHENTICATION]?.selected.map((item: UIOptionItemNew<string>) => item.value);
  const target = filterDictionary[FilterName.TARGET]?.selected.map((item: UIOptionItemNew<string>) => item.value);
  const accessibility = filterDictionary[FilterName.ACCESSIBILITY]?.selected.map((item: UIOptionItemNew<number>) => item.value);
  const scanStatuses = filterDictionary[FilterName.STATUS]?.selected.filter((item: UIOptionItemNew<apiClient.ScanStatus | string>) =>
    item.value !== 'archived').map(item => item.value.toString().split('+')).flat();
  const createdBy = filterDictionary[FilterName.INITIATEDBY]?.selected.map((item: UIOptionItemNew<string>) => item.value);
  const archived = !!filterDictionary[FilterName.STATUS]?.selected.find(el => el.value === 'archived');

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [
    createdSince, createdBefore, JSON.stringify(project),
    JSON.stringify(application), JSON.stringify(target), JSON.stringify(scanStatuses),
    JSON.stringify(accessibility), JSON.stringify(createdBy), archived,
  ]);

  const {
    scans,
    totalItemsCount,
    isScansListLoading,
  } = scansTableHooks.useScansTableApi(
    {
      createdSince: createdSince,
      createdBefore: createdBefore,
      project: project,
      application: application,
      authentication: authentication,
      target: target,
      accessibility: accessibility,
      statuses: scanStatuses,
      archived: archived,
      createdBy: createdBy,
      page: currentPage,
      pageSize: rowsPerPage,
    },
  );

  React.useEffect(() => {
    itemSelectionApi.reinitializeData(scans);

    /** Note: use this to control pagination */
    const selectedItems = new Map(itemSelectionApi.selectedItems);
    itemSelectionApi.selectedItems.forEach((item) => {
      if (selectedItems.has(item.id) && !scans.find((scan) => scan.id === item.id)) {
        selectedItems.delete(item.id);
      }
    });
    itemSelectionApi.reinitialize(selectedItems);
  }, [JSON.stringify(scans)]);

  const scansConfig: tableTypes.MuiTableProps<globalModels.ScansViewModelNew> = React.useMemo(() => {
    return {
      data: scans,
      columns: scansTableConsts.scansColumns,
      loading: isScansListLoading,
      onRowClick: (item) => (
        navigate(allRoutesEnum.ScanDetails.replace(':scanId', item.id))
      ),
      itemActions: item => {
        const archived = item.archived;
        const tooltipText = item.credentials?.is_deleted
          ? globalConstants.RESCAN_IS_NOT_AVAILABLE_AUTH
          : !item.applicationId
            ? globalConstants.RESCAN_IS_NOT_AVAILABLE_APP
            : undefined;
        return (
          {
            itemList: [
              {
                title: 'Re-scan',
                itemAction: (e?: any) => {
                  showModal({ modalType: MODAL_TYPE.RescanPopupModal, modalProps: { scanId: item.id } });
                  e?.stopPropagation();
                },
                disabled: Boolean(tooltipText),
                tooltipText: tooltipText,
                isHidden: isScanLimitReached,
              },
              {
                title: 'Scan Planner',
                titleIcon: <ClockClockwise size={16} />,
                titleIconPosition: 'right',
                itemAction: () => {
                  setOpenPlanner(true);
                  setSelectedPlannerScans([item]);
                },
                isHidden: !scanPlanner,
              },
              {
                title: archived ? 'Unarchive' : 'Archive',
                titleIcon: archived ? <BoxArrowUp size={16} /> : <BoxArrowDown size={16} />,
                titleIconPosition: 'right',
                itemAction: async () => {
                  try {
                    await updateScan({ id: item.id, scanUpdateRequest: { archived: !archived } });
                  } catch {
                    enqueueSnackbar(`Failed to ${archived ? 'unarchive' : 'archive'} Scan`);
                  }
                },
                isHidden: !archivedScans,
              },
              {
                title: 'Delete',
                itemAction: () => deleteScanHandler(item),
                isRedText: true,
              },
            ],
          }
        );
      },
      itemSelection: {
        itemSelectionApi,
        disabled: !scans.length,
      },
      noContentMessage: 'No Scans found',
      noContentIcon: ScansGray,
      groupRowsBy: (item: globalModels.ScansViewModelNew) => dayjs(item.createdAt)
        .format(globalConstants.FULL_MONTH_DATE),
      pagination: {
        count: totalItemsCount,
        page: currentPage,
        rowsPerPage: rowsPerPage,
        onPageChange: (e, newPage) => handleChangePage(newPage),
        onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
      },
    };
  }, [
    scans,
    isScansListLoading,
    itemSelectionApi,
    isScanLimitReached,
    scanPlanner,
    archivedScans,
    totalItemsCount,
    currentPage,
    rowsPerPage,
  ]);

  return (
    <MuiTable
      {...scansConfig}
    />
  );
};

export default ScansTable;