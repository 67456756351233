import { Check } from '@api-client';
import React, { useMemo } from 'react';
import { ModalHeader, Table, TableCell } from '@contentful/f36-components';
import { AttentionGray, CopyIconC } from '@assets';
import { ModalContent, MuiContentLoader, MuiSearchInput, NoContentPlaceholder } from '@shared/components';
import { StyledFlex, StyledPanel, TableStyled } from './json-modal.styled';
import { useDebounce } from '@shared/hooks';
import saveAs from 'file-saver';
import { globalConstants, globalEnums, globalQueries } from '@shared/duck';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext } from '@shared/duck/contexts';
import { Alert, Button, IconButton, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { DownloadSimple } from '@phosphor-icons/react';

interface ColumnTypeProps {
  item?: Check;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  // Workaround for old OWASP Logs in text form, change current modal on-the-fly
  onUseTextModal?: () => void;
  isCheckLoading?: boolean;
  title?: string;
}

type ZapLogs = {
  timestamp: string;
  zap_component: string;
  level: string;
  subcomponent: string;
  message: string;
  flow_control: string;
  flow_control_comment: string;
  flow_error: string;
}

const VisibleZapLogsKeys = [
  'timestamp',
  'zap_component',
  'level',
  'subcomponent',
  'message'
];

const JsonModal: React.FC<ColumnTypeProps> = ({
  item,
  setModal,
  onUseTextModal,
  isCheckLoading = false,
  title = '',
}) => {
  const widths = ['17rem', '17rem', '3.5rem', '17rem', 'auto'];
  const [text, setText] = React.useState<Partial<ZapLogs>[]>();
  const [isLoading, setIsLoading] = React.useState(true);
  const enableGetLogs = !!item;

  const onDownload = () => {
    const fileName = 'zaplog.json';
    const data = new Blob([JSON.stringify(text, undefined, 2)], { type: 'text/json' });
    saveAs(data, fileName);
  };

  const onCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(text, undefined, 2)).then(() => {
      enqueueSnackbar('Copied to clipboard!', { variant: 'info', autoHideDuration: 1000, closeBtn: false });
    });
  };

  const { setError } = React.useContext(ErrorContext);
  const { logsUrl, islogsUrlLoading } = globalQueries.useGetOwaspLogs(
    { id: item?.id || '' }, {
      onError: err => errorResponseHandler(err, 'scan', setError),
      enabled: enableGetLogs,
    }
  );

  const getDataFromUrl = async () => {
    try {
      if (logsUrl?.url) {
        const res = await fetch(logsUrl.url);
        const s = await res.text();
        try {
          const parsedResult = JSON.parse(s) as ZapLogs[];

          const result = parsedResult.map(item =>
            Object.keys(item).filter(key => VisibleZapLogsKeys.includes(key as keyof ZapLogs))
              .reduce((acc, key) => {
                const typedKey = key as keyof ZapLogs;
                acc[typedKey] = item[typedKey];
                return acc;
              }, {} as Partial<ZapLogs>)
          );
          setText(result);
        }
        catch {
          // Workaround for old OWASP Logs in text form, change current modal on-the-fly
          onUseTextModal?.();
        }
      }
      else {
        setIsLoading(false);
      }
    }
    catch {
      enqueueSnackbar(globalConstants.FAILED_TO_READ_FILE, { variant: 'error' });
    }
  };

  React.useEffect(() => {
    if (!isCheckLoading && !islogsUrlLoading) {
      getDataFromUrl();
    }
  }, [logsUrl, islogsUrlLoading, isCheckLoading]);

  React.useEffect(() => {
    if (text && text.length !== 0) {
      setIsLoading(false);
    }
  }, [text]);

  const { debouncedValue: debouncedSearch, onChange: onSearchChange } = useDebounce<string>();
  const searchString = debouncedSearch?.trim().toLowerCase();

  const filteredLogs = useMemo(() => {
    if (text) {
      const filtered = searchString
        ? text.filter(l => Object.values(l).filter(i => i?.toLowerCase().includes(searchString)).length ? l : null)
        : text;

      return [...filtered].reverse();
    }
  }, [text, debouncedSearch]);

  return <>
    <ModalHeader onClose={() => setModal(false)} title={title || 'ZAP Scanner\'s Logs: ' + item?.name} />

    <ModalContent
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <MuiContentLoader isLoading={isLoading || isCheckLoading}>
        {text?.length ? (
          <StyledFlex flexDirection='column' height='100%'>
            <StyledPanel p='0.75rem' justifyContent='space-between'>
              <MuiSearchInput
                width='25rem'
                onChange={(e) => onSearchChange(e.target.value)}
              />
              <Stack flexDirection='row' gap='1rem'>
                <Button
                  aria-label='Download JSON'
                  startIcon={<DownloadSimple size={20} />}
                  onClick={onDownload}
                  sx={{
                    height: '2.5rem'
                  }}
                >
                  Download JSON
                </Button>
                <IconButton
                  aria-label='Copy'
                  color='outlined'
                  sx={{
                    width: '2.5rem',
                    height: '2.5rem'
                  }}
                  onClick={onCopy}
                >
                  <CopyIconC
                    variant={globalEnums.CopyIconVariantsEnum.BLACK}
                  />
                </IconButton>
              </Stack>
            </StyledPanel>
            {filteredLogs && filteredLogs.length > 0 ? <TableStyled>
              <Table.Head isSticky>
                <Table.Row>
                  {Object.keys(filteredLogs[0]).map((value, index) => (
                    <TableCell style={{ minWidth: widths[index], width: value === 'message' ? '100%' : '' }} key={value}>{value}</TableCell>
                  ))}
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {filteredLogs.map((item, number) => {
                  return <Table.Row key={'item' + number.toString()}>
                    {Object.keys(item || {}).map((value, number2) => {
                      return <TableCell
                        key={'value' + number.toString() + number2.toString()} > {item[value as keyof ZapLogs]}
                      </TableCell>;
                    })}
                  </Table.Row>;
                })}
              </Table.Body>
            </TableStyled> : <NoContentPlaceholder message='No logs found.' height='18.75rem' iconSrc={AttentionGray} />}
          </StyledFlex>
        ) : (
          <Alert severity='info'>
            {'No logs found.'}
          </Alert>
        )}
      </MuiContentLoader>
    </ModalContent >
  </>;
};

export default JsonModal;
