import { User } from '@datadog/browser-core';
import { datadogRum } from '@datadog/browser-rum';
import { getCookie, setCookie } from '@shared/duck/utils';

const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_RUM_APP_ID || '';
const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN || '';

const DATADOG_USER_TRACKING_CONSENT_COOKIE = 'DatadogTrackingConsent';

const API_URL = (process.env.REACT_APP_API_URL ?? '/').replace(/\/?$/, '/');

export function configureDatadogRum() {
  if (DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'nightvision-dashboard',
      env: process.env.REACT_APP_DEPLOYMENT_ENV || 'local',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0, // disable Session Replay
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        (url) => url.startsWith(API_URL),
      ],
      // TODO for GDPR and CCPA compliance
      // trackingConsent: 'not-granted',
    });
  }
}

export function setUserForDatadogRum(user: User): void {
  datadogRum.setUser(user);
}

export function clearUserForDatadogRum(): void {
  datadogRum.clearUser();
}

export function isUserTrackingAllowed(): boolean {
  const consentCookieValue = getCookie(DATADOG_USER_TRACKING_CONSENT_COOKIE);
  return consentCookieValue == 'granted';
}

export function allowUserTracking() {
  datadogRum.setTrackingConsent('granted');
  setCookie(DATADOG_USER_TRACKING_CONSENT_COOKIE, 'granted');
}
