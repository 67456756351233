import { globalEnums, globalTypes } from '@shared/duck';
import React from 'react';
import { BasicMenu } from './basic';
import { ComplexMenu } from './complex';
import { SxProps, Theme } from '@mui/material';

export interface UIMenuProps {
  itemList: globalTypes.UIMenuItemsList[];
  type?: globalEnums.EMenuType;
  disabled?: boolean;
  iconVariant?: globalEnums.EMuiIconButtonVariants;
  size?: 'small' | 'medium' | 'large';
  headerText?: string;
  headerSubText?: string;
  footerItems?: globalTypes.UIMenuItemsList[];
  menuIconC?: JSX.Element;
  sx?: SxProps<Theme>;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UIMenu: React.FC<UIMenuProps> = ({
  type = globalEnums.EMenuType.BASIC,
  itemList,
  disabled,
  headerText,
  headerSubText,
  footerItems,
  iconVariant,
  size,
  menuIconC,
  sx,
  setIsOpen,
}) => {
  return (
    <>
      {(
        () => {
          switch (type) {
            case globalEnums.EMenuType.BASIC:
              return (
                <BasicMenu
                  itemList={itemList}
                  disabled={disabled}
                  iconVariant={iconVariant}
                  iconSize={size}
                  menuIconC={menuIconC}
                  sx={sx}
                  setIsOpen={setIsOpen}
                />
              );
            case globalEnums.EMenuType.COMPLEX:
              return (
                <ComplexMenu
                  itemList={itemList}
                  disabled={disabled}
                  headerText={headerText}
                  headerSubText={headerSubText}
                  footerItems={footerItems}
                  iconVariant={iconVariant}
                  size={size}
                  menuIconC={menuIconC}
                  sx={sx}
                  setIsOpen={setIsOpen}
                />
              );
          }
        }
      )()}
    </>
  );
};

export default UIMenu;