import { Power } from '@phosphor-icons/react';
import { useApp, useLaunchDarklyFlags } from '@shared/hooks';
import { GenerateTokenModal } from 'pages/workspace-router/settings';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { allRoutesEnum, globalEnums } from '@shared/duck';
import { UIMenu, UIUserAvatar } from '@shared/components';

const PageTopBarAvatar = () => {
  const app = useApp();
  const navigate = useNavigate();
  const { billingFlag, settingsFlag } = useLaunchDarklyFlags();

  const [openGenerateModal, setOpenGenerateModal] = React.useState(false);

  const user = app.users.me?.dto;
  const fullName = user && `${user?.first_name} ${user?.last_name}`;
  const avatarSrc = user?.avatar_url;

  const isEnterpriseSubscription = app.checkHasEnterpriseSubscription();

  const doSubscribe = React.useCallback(() => {
    // TODO: update
    navigate(allRoutesEnum.SelectBillingPlan);
  }, []);

  const doBillingPortal = React.useCallback(() => {
    // TODO: update
    app.openStripeCustomerPortal();
  }, []);

  const openGenerateTokenModal = React.useCallback(() => {
    setOpenGenerateModal(true);
  }, []);

  const closeGenerateTokenModal = React.useCallback(() => {
    setOpenGenerateModal(false);
  }, []);

  return (
    <>
      <UIMenu
        type={globalEnums.EMenuType.COMPLEX}
        itemList={[
          {
            title: 'Profile ',
            itemAction: () => navigate(allRoutesEnum.Profile),
            isHidden: settingsFlag, // Note: if Settings are enable, Profile is moved into them
          },
          {
            title: 'Generate API token',
            itemAction: () => openGenerateTokenModal(),
            isHidden: settingsFlag, // Note: if Settings are enable, Generate API token is moved into them
          },
          {
            title: 'Change Subscription',
            itemAction: () => doSubscribe(),
            isHidden: !billingFlag || isEnterpriseSubscription,
          },
          {
            title: 'Billing Portal',
            itemAction: () => doBillingPortal(),
            isHidden: !billingFlag || isEnterpriseSubscription,
          },
          {
            title: 'Settings',
            itemAction: () => navigate(allRoutesEnum.Settings),
            isHidden: !settingsFlag,
          },
        ]}
        headerText={fullName}
        headerSubText={user?.username}
        footerItems={[
          {
            title: 'Log Out',
            itemAction: () => app.logout(),
            titleIcon: <Power size={16} color='#d92a4a' weight='bold' />,
            isRedText: true,
          },
        ]}
        menuIconC={
          <UIUserAvatar
            src={avatarSrc}
            cursorPointer
          />
        }
        sx={{ maxWidth: '50%' }}
      />
      {!settingsFlag && (
        <GenerateTokenModal
          open={openGenerateModal}
          onClose={closeGenerateTokenModal}
        />
      )}
    </>
  );
};

export default PageTopBarAvatar;
