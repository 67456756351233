import { Modal } from '@contentful/f36-components';
import styled from '@emotion/styled';

const StyledModal = styled(Modal)`
  top: -50vh !important;
  position: absolute;
  right: -50vw !important;
  max-height: 100vh !important;
  width: 50vw !important;
`;

export { StyledModal };