import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { globalEnums, globalConstants, globalQueries } from '@shared/duck';
import { ErrorContext } from '@shared/duck/contexts';
import { ContentGrid, FilteredStackPanel, StyledCodeContainer } from './authentication-details.styled';
import dayjs from 'dayjs';
import { getCodeFragmentByType } from './duck';
import { GridItem, Tabs } from '@contentful/f36-components';
import { BasicMenu, CommonAuthHeader, Description, ScansTable, TargetsTable } from './components';
import { DayRangeEnum } from './components/day-menu/duck';
import { UICodeFragment } from '@shared/components';
import { CredentialsType } from '@api-client';

const AuthenticationDetails: React.FC = () => {
  const { setError } = React.useContext(ErrorContext);

  const { authenticationId: id = '' } = useParams();
  const { credentials, isCredentialsLoading } = globalQueries.useGetCredentials(
    { id },
    {
      onError: (er) => errorResponseHandler(er, 'authentication', setError),
    },
  );

  const createdAt = credentials?.created_at
    ? dayjs(credentials.created_at).format(globalConstants.FULL_MONTH_DATE_TIME)
    : 'N/A';
  const credentialsType = credentials?.type ? globalEnums.authenticationTypeNameMap.get(credentials.type) ||
    credentials.type : 'N/A';
  const [startDate, setStartDate] = React.useState<Date | undefined>();

  React.useEffect(() => {
    const now = dayjs().toDate();
    const dayOfWeek = now.getDay();
    now.setDate(now.getDate() - (
      dayOfWeek === 0 ? 7 : dayOfWeek
    ));
    setStartDate(dayjs(now).endOf('day').toDate());
    setMenuText(DayRangeEnum.WEEK);
  }, []);

  const [currentTab, setCurrentTab] = React.useState('scans');
  const [menuText, setMenuText] = React.useState(DayRangeEnum.WEEK);

  const code = React.useMemo(() => getCodeFragmentByType(credentials), [credentials]);
  const isScript = credentials?.type === CredentialsType.Script;

  return (
    <Stack gap={0}>
      <CommonAuthHeader credentials={credentials} isCredentialsLoading={isCredentialsLoading} />
      <ContentGrid columns='3fr 4fr' columnGap='spacingXl'>
        <GridItem className='grid-item-info'>
          <Stack
            alignItems='flex-start'
            gap={1.5}
            marginBottom='1.5rem'
            className='cred-info'
          >
            <Description title='Project Name:' value={credentials?.project_name || ''} />
            <Description title='Authentication type:' value={credentialsType} />
            <Description title='Created at:' value={createdAt} />
            {isScript && (
              <Description title='Target URL:' value={credentials?.script_first_url || 'N/A'} />
            )}
            <Description title='Description:' value={credentials?.description || 'N/A'} />
          </Stack>
          <StyledCodeContainer>
            <Typography
              variant='subtitle1' fontSize='1rem' fontWeight='600'
              p='1rem'
            >
              Authentication Script
            </Typography>
            <UICodeFragment
              code={code}
              language={globalEnums.SupportedLangs.python}
              plugins={[globalEnums.SupportedPlugins.lineNumbers, globalEnums.SupportedPlugins.lineHighlight]}
            />
          </StyledCodeContainer>
        </GridItem>
        <GridItem className='grid-item-info'>
          <Tabs defaultTab='scans' onTabChange={setCurrentTab}>
            <FilteredStackPanel>
              <Stack flexDirection='row' justifyContent='space-between' alignItems='center' style={{ width: '100%' }}>
                <Tabs.List>
                  <Tabs.Tab panelId='scans'>Scans</Tabs.Tab>
                  <Tabs.Tab panelId='targets'>Targets</Tabs.Tab>
                </Tabs.List>
                {currentTab === 'scans' && <Box>
                  <BasicMenu setStartDate={setStartDate} text={menuText} setText={setMenuText} size='small'></BasicMenu>
                </Box>}
              </Stack>
            </FilteredStackPanel>
            <Tabs.Panel id='scans'>
              <ScansTable credentialsId={credentials?.id || ''} startDate={startDate} />
            </Tabs.Panel>
            <Tabs.Panel id='targets'>
              <TargetsTable credentialsId={credentials?.id || ''} />
            </Tabs.Panel>
          </Tabs>
        </GridItem>
      </ContentGrid>
    </Stack>
  );
};

export default AuthenticationDetails;