import { Grid, GridItem } from '@contentful/f36-components';
import { Box, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { isString } from 'lodash';
import { Fragment } from 'react';

export interface DataItem {
  title: string;
  value: string | React.ReactNode;
}

interface CredentialsBasicInfoProps {
  items: DataItem[];
}

const CredentialsBasicInfo: React.FC<CredentialsBasicInfoProps> = ({
  items
}: CredentialsBasicInfoProps) => {
  return (
    <Box p='1rem'>
      <Grid columns='12.5rem 1fr' rowGap='spacingS'>
        {items.map(({ title, value }) => (
          <Fragment key={title}>
            <GridItem>
              <Typography variant='default' fontWeight='500'>
                {title}
              </Typography>
            </GridItem>
            <GridItem>{isString(value) ? (
              <Typography variant='default' color={muiPalette.grey?.[500]}>
                {value}
              </Typography>
            ) : value}
            </GridItem>
          </Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default CredentialsBasicInfo;
