import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Popover, Spinner, TextInput } from '@contentful/f36-components';
import useInfiniteScroll from 'views/utils/useInfiniteScroll';
import { SearchGlassIcon } from 'views/components/v1/Icons';
import { FormControlEvent, IFormControlEvent } from 'views/utils/form';
import { StyledOption } from './select-old.styled';
import { globalConstants, globalTypes } from '@shared/duck';
import { UITextInput } from '@shared/components';
import { Typography, IconButton, Box } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { CaretDown, CaretUp } from '@phosphor-icons/react';

export interface SelectProps {
  options: globalTypes.UIOptionItem[];
  onScrollEnd?: () => void;
  noItemsMessage?: React.ReactNode;
  isLoading?: boolean;
  onSearchChange?: (value: string) => void;
  initialValue?: globalTypes.UIOptionItem | null;
  onChange?: (e: IFormControlEvent<string | number | undefined>) => void;
  onInitialChange?: (e: IFormControlEvent<string | number | undefined>) => void;
  // Extra onChange action. Needed inside FormikControl, as Formik overrides default `onChange` with its own `handleChange` handler.
  onAfterChange?: (e: IFormControlEvent<string | number | undefined>) => void;
  placeholder?: string;
  name?: string;
  id?: string;
  enableReinitialize?: boolean;
  withSearch?: boolean;
  validationMessage?: string;
  isInvalid?: boolean;
  withEmptyItem?: boolean;
  renderer?: (item: globalTypes.UIOptionItem) => React.ReactNode;
  selectedLabel?: (value: string) => string;
  className?: string;
  footer?: React.ReactNode;
  withoutBorders?: boolean;
}

/* TODO: Out of date, switch to MuiSelect */

function Select(props: SelectProps) {
  const {
    options,
    onScrollEnd,
    onSearchChange,
    isLoading = false,
    onChange,
    onAfterChange,
    name,
    id,
    enableReinitialize,
    noItemsMessage = (
      <Box p='1rem'>
        <Typography variant='default' color={muiPalette.grey?.[500]}>
          No items found
        </Typography>
      </Box>
    ),
    placeholder = 'Select...',
    withSearch = true,
    isInvalid = false,
    initialValue: initialValueProp,
    withEmptyItem = true,
    renderer,
    selectedLabel: selectedLabelRender,
    className,
    onInitialChange,
    footer,
    withoutBorders = false,
  } = props;
  const initialValue = useMemo(() => {
    return initialValueProp || { label: placeholder, value: Select.EmptyValue };
  }, [initialValueProp]);
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);
  const { containerRef } = useInfiniteScroll<globalTypes.UIOptionItem, HTMLDivElement>({
    onScrollEnd,
    itemSelector: 'label',
    items: options,
  });

  const loadingMessage = isLoading ? (
    <Box p='0.5rem'>
      <Spinner size='medium' />
    </Box>
  ) : null;

  const openPopover = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closePopover = useCallback(() => {
    if (onSearchChange) {
      onSearchChange('');
    }

    setIsOpen(false);
  }, [onSearchChange]);

  const togglePopover = () => (
    isOpen ? closePopover() : openPopover()
  );

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onSearchChange) {
        onSearchChange(e.target.value);
      }
    },
    [onSearchChange],
  );

  const onSelectOption = (item: globalTypes.UIOptionItem) => {
    setCurrentValue(item);

    if (onChange) {
      onChange(new FormControlEvent({ id, name, value: item ? item.value : undefined }));
    }
    if (onAfterChange) {
      onAfterChange(new FormControlEvent({ id, name, value: item ? item.value : undefined }));
    }

    closePopover();
  };

  useEffect(() => {
    if (enableReinitialize) {
      setCurrentValue(initialValue);

      if (onInitialChange) {
        onInitialChange(new FormControlEvent({ id, name, value: initialValue ? initialValue.value : undefined }));
      }
    }
  }, [enableReinitialize, initialValue, onInitialChange]);

  return (
    <Popover isOpen={isOpen} isFullWidth={true} onClose={closePopover}>
      <Popover.Trigger>
        <TextInput.Group className={className}>
          <UITextInput
            onClick={e => {
              togglePopover();
              e.stopPropagation();
            }}
            id={id}
            name={name}
            value={
              currentValue
                ? selectedLabelRender
                  ? selectedLabelRender(currentValue.label)
                  : currentValue.label
                : placeholder
            }
            isReadOnly={true}
            isInvalid={isInvalid}
            style={{ borderRight: withoutBorders ? 'none' : '' }}
          />
          <IconButton
            aria-label='Select'
            color='outlined'
            onClick={togglePopover}
            sx={{
              height: '2.5rem !important',
              width: '2.5rem !important',
              borderLeft: withoutBorders ? 'none' : '',
              borderColor: isInvalid ? globalConstants.INPUT_BORDER.ERROR : '',

              '&:hover': {
                borderColor: isInvalid ? globalConstants.INPUT_BORDER.ERROR : '',
              }
            }}
          >
            {isOpen ? <CaretUp size={18} /> : <CaretDown size={18} />}
          </IconButton>
        </TextInput.Group>
      </Popover.Trigger>
      <Popover.Content>
        {withSearch ? (
          <Box p='0.75rem'>
            <UITextInput icon={<SearchGlassIcon />} placeholder='Search' onChange={onSearch} isInvalid={false} />
          </Box>
        ) : null}
        <Box
          ref={containerRef}
          sx={{
            overflowY: 'auto',
            maxHeight: '15.625rem',
          }}
        >
          {options.length > 0 ? (
            <>
              {withEmptyItem ? (
                <StyledOption
                  onClick={() => onSelectOption({ label: placeholder, value: Select.EmptyValue })}
                  isSelected={currentValue?.value === Select.EmptyValue}
                >
                  <Typography variant='default'>
                    {placeholder}
                  </Typography>
                </StyledOption>
              ) : null}
              {options.map(item => (
                <StyledOption
                  key={item.value}
                  onClick={() => onSelectOption(item)}
                  isSelected={currentValue?.value === item.value}
                >
                  {renderer ? renderer(item) : <Typography variant='default'>{item.label}</Typography>}
                </StyledOption>
              ))}
              {footer}
            </>
          ) : (
            noItemsMessage
          )}
        </Box>
        {loadingMessage}
      </Popover.Content>
    </Popover >
  );
}

Select.EmptyValue = '';

export default Select;
