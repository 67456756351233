import { IconProps } from './BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from './icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='16'
    height='18'
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <path
      d='M1.25 4.5H2.75M2.75 4.5H14.75M2.75 4.5V15C2.75 15.3978 2.90804 15.7794 3.18934 16.0607C3.47064 16.342 3.85218 16.5 4.25 16.5H11.75C12.1478 16.5 12.5294 16.342 12.8107 16.0607C13.092 15.7794 13.25 15.3978 13.25 15V4.5H2.75ZM5 4.5V3C5 2.60218 5.15804 2.22064 5.43934 1.93934C5.72064 1.65804 6.10218 1.5 6.5 1.5H9.5C9.89782 1.5 10.2794 1.65804 10.5607 1.93934C10.842 2.22064 11 2.60218 11 3V4.5'
      stroke={props.color || '#111B2B'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

function DeleteEmptyIcon(props: IconProps & { color?: string }) {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default DeleteEmptyIcon;
