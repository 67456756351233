import { Popover, Spinner, TextInput } from '@contentful/f36-components';
import React from 'react';
import { SearchGlassIcon } from 'views/components/v1/Icons';
import { FormControlEvent } from 'views/utils/form';
import useInfiniteScroll from 'views/utils/useInfiniteScroll';
import { StyledOption, StyledTextInput } from '@shared/components/selectors/f36-select/f36-select.styled';
import { globalConstants, globalEnums, globalTypes } from '@shared/duck';
import { HorizontalDivider, UITextInput } from '@shared/components';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { CaretDown, CaretUp } from '@phosphor-icons/react';


/* TODO: Out of date, switch to MuiSelect */

const F36Select = <T,>({
  options,
  onScrollToEnd,
  onSearchChange,
  isLoading = false,
  onChange,
  onAfterChange,
  noItemsMessage = (
    <Box p='1rem'>
      <Typography variant='default' color={muiPalette.grey?.[500]}>
        No items found
      </Typography>
    </Box>
  ),
  placeholder = 'Select',
  withSearch = true,
  isInvalid = false,
  initialValue: initValue,
  withEmptyItem = true,
  itemRenderer,
  className,
  footer,
  isFullWidth = true,
  name,
  id,
  bordered = true,
  disabled = false,
}: globalTypes.UISelectProps<T>) => {

  const initialValue = initValue || { label: placeholder, value: undefined };
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const { containerRef } = useInfiniteScroll<globalTypes.UIOptionItem<T>, HTMLDivElement>({
    onScrollEnd: onScrollToEnd,
    itemSelector: 'label',
    items: options,
  });

  const loadingMessage = isLoading ? (
    <Box p='0.5rem'>
      <Spinner size='medium' />
    </Box>
  ) : null;

  const openPopover = React.useCallback(() => {
    setIsPopoverOpen(true);
  }, []);

  const closePopover = React.useCallback(() => {
    if (onSearchChange) {
      onSearchChange('');
    }

    setIsPopoverOpen(false);
  }, [onSearchChange]);

  const togglePopover = () => (
    isPopoverOpen ? closePopover() : openPopover()
  );

  const onSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onSearchChange) {
        onSearchChange(e.target.value);
      }
    }, [onSearchChange]);

  const onSelectOption = (item: globalTypes.UIOptionItem<T | undefined>) => {

    if (onChange) {
      onChange(new FormControlEvent({ name, id, value: item ? item.value : undefined }));
    }
    if (onAfterChange) {
      onAfterChange(new FormControlEvent({ name, id, value: item ? item.value : undefined }));
    }

    closePopover();
  };
  const isPlaceholder = initialValue.label === placeholder;

  const inputRef = React.useRef<HTMLDivElement>();

  const setRef = (inputRef: React.MutableRefObject<HTMLDivElement | undefined>) => (el: any) =>
    (
      inputRef.current = el ? el : undefined
    );

  React.useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [isPopoverOpen]);

  return (
    <Popover isOpen={isPopoverOpen} isFullWidth={isFullWidth} onClose={closePopover}>
      <Popover.Trigger>
        <TextInput.Group className={className}>
          <StyledTextInput
            onClick={togglePopover}
            value={initialValue ? initialValue.label : placeholder}
            isReadOnly={true}
            placeholder={placeholder}
            id={id}
            name={name}
            isInvalid={isInvalid}
            isDisabled={disabled}
            bordered={bordered}
            isPlaceholder={isPlaceholder}
          />
          <IconButton
            aria-label='Select'
            color='outlined'
            onClick={!disabled ? togglePopover : undefined}
            disabled={disabled}
            sx={{
              height: '2.5rem !important',
              width: '2.5rem !important',
              border: bordered ? '' : 'transparent',
              borderColor: isInvalid ? globalConstants.INPUT_BORDER.ERROR : '',
              '&:hover': {
                borderColor: isInvalid ? globalConstants.INPUT_BORDER.ERROR : '',
              }
            }}
          >
            {isPopoverOpen ? <CaretUp size={18} /> : (isLoading ? <CircularProgress size={18} /> : <CaretDown size={18} />)}
          </IconButton>
        </TextInput.Group>
      </Popover.Trigger>
      <Popover.Content style={{ minWidth: !isFullWidth ? '20rem' : '' }}>
        {withSearch ? (
          <>
            <UITextInput
              icon={<SearchGlassIcon size='large' />}
              placeholder='Search...'
              onChange={onSearch}
              isInvalid={false}
              ref={setRef(inputRef)}
              noBorders={globalEnums.EmotionBoolean.True} />
            <HorizontalDivider />
          </>
        ) : null}

        <Box
          ref={containerRef}
          sx={{
            overflowY: 'auto',
            maxHeight: '10rem',
            wordWrap: 'break-word',
          }}>
          {options.length > 0 ? (
            <>
              {withEmptyItem ? (
                <StyledOption
                  onClick={() => onSelectOption({ label: placeholder, value: undefined })}
                  isSelected={initialValue?.value === undefined}
                >
                  <Typography variant='default'>
                    {placeholder}
                  </Typography>
                </StyledOption>
              ) : null}
              {options.map((item, index) => (
                <StyledOption
                  key={index}
                  onClick={() => onSelectOption(item)}
                  isSelected={initialValue?.value === item.value}
                >
                  {itemRenderer ? itemRenderer(item) : <Typography variant='default'>{item.label}</Typography>}
                </StyledOption>
              ))}
            </>
          ) : (
            loadingMessage ? loadingMessage : noItemsMessage
          )}
          {options.length > 0 && loadingMessage}
        </Box>
        {footer && <HorizontalDivider />}
        {footer}
      </Popover.Content>
    </Popover >
  );
};

export default F36Select;
