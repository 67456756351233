import { useApp } from '@shared/hooks';
import { BillingNotificationStyled, CloseButton } from './billing-notification.styled';
import { globalEnums } from '@shared/duck';
import { observer } from 'mobx-react';
import React from 'react';
import { CloseIcon } from 'views/components/v1/Icons';
import { billingNotificationEnums, billingNotificationUtils } from './duck';

const BillingNotification = observer(({ className }: { className?: string }) => {
  const app = useApp();
  const plan = app.users.plan;
  const status = app.users.planStatus;

  const [isClosed, setIsClosed] = React.useState(false);

  // Show notification if billing plan or subscription changed
  React.useEffect(() => {
    setIsClosed(false);
  }, [plan, status]);

  const type = billingNotificationUtils.getNotificationType(status);
  const content = billingNotificationUtils.getNotificationContent(type, app);

  const onClose = () => { setIsClosed(true); };

  const showCloseButton = type == billingNotificationEnums.NotificationType.FREE_TRIAL;

  if (isClosed || !content) {
    return null;
  }

  return (
    <BillingNotificationStyled className={className} type={type}>
      {content}
      {showCloseButton && (
        <CloseButton onClick={() => onClose()} >
          <CloseIcon variant={globalEnums.EIconVariants.WHITE} />
        </CloseButton>
      )}
    </BillingNotificationStyled>
  );
});

export default BillingNotification;
