/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
import { globalEnums } from '@shared/duck';
import Prism from 'prismjs';

Prism.languages[globalEnums.SupportedLangs.evidence_markup] = {};

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\&]/g, '\\$&');
};

const getYamlTokens = () => {
  // https://yaml.org/spec/1.2/spec.html#c-ns-anchor-property
  // https://yaml.org/spec/1.2/spec.html#c-ns-alias-node
  const anchorOrAlias = /[*&][^\s[\]{},]+/;
  // https://yaml.org/spec/1.2/spec.html#c-ns-tag-property
  const tag = /!(?:<[\w\-%#;/?:@&=+$,.!~*'()[\]]+>|(?:[a-zA-Z\d-]*!)?[\w\-%#;/?:@&=+$.~*'()]+)?/;
  // https://yaml.org/spec/1.2/spec.html#c-ns-properties(n,c)
  const properties =
    '(?:' +
    tag.source +
    '(?:[ \t]+' +
    anchorOrAlias.source +
    ')?|' +
    anchorOrAlias.source +
    '(?:[ \t]+' +
    tag.source +
    ')?)';
  // https://yaml.org/spec/1.2/spec.html#ns-plain(n,c)
  // This is a simplified version that doesn't support "#" and multiline keys
  // All these long scarry character classes are simplified versions of YAML's characters
  const plainKey =
    /(?:[^\s\x00-\x08\x0e-\x1f!"#%&'*,\-:>?@[\]`{|}\x7f-\x84\x86-\x9f\ud800-\udfff\ufffe\uffff]|[?:-]<PLAIN>)(?:[ \t]*(?:(?![#:])<PLAIN>|:<PLAIN>))*/.source.replace(
      /<PLAIN>/g,
      function () {
        return /[^\s\x00-\x08\x0e-\x1f,[\]{}\x7f-\x84\x86-\x9f\ud800-\udfff\ufffe\uffff]/.source;
      },
    );
  const string = /"(?:[^"\\\r\n]|\\.)*"|'(?:[^'\\\r\n]|\\.)*'/.source;

  return {
    key: {
      pattern: RegExp(
        /((?:^|[:\-,[{\r\n?])[ \t]*(?:<<prop>>[ \t]+)?)<<key>>(?=\s*:\s)/.source
          .replace(/<<prop>>/g, function () {
            return properties;
          })
          .replace(/<<key>>/g, function () {
            return '(?:' + plainKey + '|' + string + ')';
          }),
      ),
      lookbehind: true,
      greedy: false,
      alias: 'atrule',
    },
  };
};

const createRegExp = (strings: string[]): RegExp | null => {
  try {
    const escapedStrings = strings.map(str => escapeRegExp(str));
    const joinedStrings = escapedStrings.join('|');
    const regexp = new RegExp(joinedStrings, 'g');
    regexp.test('');
    return regexp;
  } catch (error) {
    console.error('Error creating RegExp from evidence and payload', error);
    return null;
  }
};

const initHighlightTextPlugin = (textFragments: string[]) => {
  const fragments = textFragments.filter(item => item.trim() !== '');
  const regex = createRegExp(fragments);

  const customTokens =
    fragments.length > 0 && regex
      ? {
        danger: {
          pattern: regex,
          greedy: true,
        },
      }
      : {};

  Prism.languages[globalEnums.SupportedLangs.evidence_markup] = {
    ...customTokens,
    /** HTML */
    comment: {
      pattern: /<!--(?:(?!<!--)[\s\S])*?-->|#.*/,
      greedy: false,
    },
    tag: {
      pattern:
        /<\/?(?!\d)[^\s>\/=$<%]+(?:\s(?:\s*[^\s>\/=]+(?:\s*=\s*(?:"[^"]*"|'[^']*'|[^\s'">=]+(?=[\s>]))|(?=[\s/>])))+)?\s*\/?>/,
      greedy: false,
      inside: {
        ...customTokens,
        tag: {
          pattern: /^<\/?[^\s>\/]+/,
          inside: {
            punctuation: /^<\/?/,
            namespace: /^[^\s>\/:]+:/,
          },
          greedy: false,
        },
        'special-attr': [],
        'attr-value': {
          pattern: /=\s*(?:"[^"]*"|'[^']*'|[^\s'">=]+)/,
          inside: {
            ...customTokens,
            punctuation: [
              {
                pattern: /^=/,
                alias: 'attr-equals',
                greedy: false,
              },
              {
                pattern: /^(\s*)["']|["']$/,
                lookbehind: true,
                greedy: false,
              },
            ],
          },
          greedy: false,
        },
        punctuation: /\/?>/,
        'attr-name': {
          pattern: /[^\s>\/]+/,
          inside: {
            namespace: /^[^\s>\/:]+:/,
          },
          greedy: false,
        },
      },
    },

    /** YAML */
    ...getYamlTokens(),
  };
};

export { initHighlightTextPlugin };
