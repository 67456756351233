import { User } from '@api-client';
import { tableTypes } from '@shared/components';
import { StyledMuiTableCell } from './table-cell.styled';
import { globalEnums } from '@shared/duck';
import { get } from 'lodash';
import { CellText, MuiCellLabels, MuiCustomCell, ToLinkOrNotToLink } from './components';
import { Stack, Tooltip } from '@mui/material';

interface ItemCommonProps {
  name?: string;
  labels?: Array<string>;
  createdBy?: User;
  isInProgress?: globalEnums.EmotionBoolean;
}

type alignProps = 'center' | 'left' | 'right';

const MuiTableCell = <DataItem, >({
  dataItem,
  column,
  index,
}: {
  dataItem: DataItem
  column: tableTypes.ColumnProps<DataItem>
  index: number
}) => {
  const cellValue = get(dataItem, column.columnKey);
  const dataItemProps = dataItem as ItemCommonProps;
  const Icon = column.cellInfo?.getIcon?.(dataItem) || column.cellInfo?.icon;

  if (column.customCellRenderer && cellValue !== null) {
    return (
      <MuiCustomCell
        dataItem={dataItem}
        column={column}
        Icon={Icon}
        index={index}
        cellValue={cellValue}
        description={column.cellInfo?.description?.(dataItem) || ''}
        withoutPadding={column.cellInfo?.withoutPadding}
      />
    );
  } else if (dataItemProps?.labels?.length && cellValue === dataItemProps.labels) {
    return (
      <MuiCellLabels
        labels={dataItemProps.labels}
        column={column}
      />
    );
  }

  return (
    <StyledMuiTableCell
      width={column.width}
      minWidth={column.cellInfo?.hasUnbreakableTextData ? '15rem' : column.minWidth}
      align={column.cellInfo?.alignItems as alignProps || 'left'}
      sx={{
        wordBreak: column.cellInfo?.hasUnbreakableTextData ? 'break-word' : '',
      }}
    >
      <ToLinkOrNotToLink href={column.cellInfo?.href?.(dataItem) || ''}>
        <Tooltip
          arrow
          title={column.cellInfo?.cellTooltipText || ''}
          placement={column.cellInfo?.cellTooltipPlace || 'bottom'}
        >
          <Stack
            alignItems='center'
            flexDirection='row'
            justifyContent={column.cellInfo?.alignItems || 'left'}
          >

            {!!Icon && (
              !column.cellInfo?.iconPosition ||
              column.cellInfo?.iconPosition === 'left'
            ) && (
              Icon
            )}

            {cellValue && (
              <CellText
                title={cellValue}
                description={column.cellInfo?.description?.(dataItem) || ''}
                titleColor={column.cellInfo?.cellTextColor}
                titleWeight={column.cellInfo?.cellTextWeight}
              />
            )}

            {!!Icon && column.cellInfo?.iconPosition === 'right' && (
              Icon
            )}
          </Stack>
        </Tooltip>
      </ToLinkOrNotToLink>
    </StyledMuiTableCell>
  );
};

export default MuiTableCell;