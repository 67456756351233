import { Avatar, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Theme } from '@emotion/react';
import { userAvatarUtils } from './duck';
import { globalEnums, globalTypes } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';

interface UserAvatarProps {
  fullName?: string;
  withFullNameText?: boolean;
  src?: string | null;
  titleColor?: string;
  variant?: globalEnums.EAvatarVariants;
  avatarSize?: number;
  tooltipId?: string;
  tooltipText?: string;
  tooltipPosition?: globalTypes.MuiTooltipPlacement;
  titleFontSize?: string | number,
  titleLineHeight?: string | number,
  titleFontWeight?: string | number,
  description?: string,
  cursorPointer?: boolean;
  sx?: SxProps<Theme>;
}

const UIUserAvatar: React.FC<UserAvatarProps> = ({
  fullName = '',
  withFullNameText = true,
  src = '',
  titleColor = muiPalette.grey?.[900],
  variant,
  avatarSize = 2,
  tooltipId = '0',
  tooltipText,
  tooltipPosition = 'bottom',
  titleFontSize = '0.875rem',
  titleLineHeight = '1.25rem',
  titleFontWeight = '400',
  description = '',
  cursorPointer = false,
  ...props
}) => {
  return (
    <Stack
      gap='0.5rem'
      flexDirection='row'
      alignItems='center'
      sx={{ cursor: cursorPointer ? 'pointer' : 'default', ...props.sx }}
      {...props}
    >
      <Tooltip
        key={'avatar-tooltip' + tooltipId}
        arrow
        placement={tooltipPosition}
        title={tooltipText}
      >
        <Avatar
          {...userAvatarUtils.setAvatarStyle(avatarSize, fullName || tooltipText)}
          src={src || ''}
          alt={fullName || tooltipText}
          variant={variant}
        />
      </Tooltip>

      {fullName && withFullNameText && (
        <Stack alignItems='start' gap='0.125rem'>

          <Typography
            fontSize={titleFontSize} fontWeight={titleFontWeight} lineHeight={titleLineHeight}
            color={titleColor}
          >
            {fullName}
          </Typography>

          {description && (
            <Typography
              variant={'body2'}
              sx={{
                opacity: 0.5,
              }}
            >
              {description}
            </Typography>
          )}
        </Stack>

      )}
    </Stack>
  );
};

export default UIUserAvatar;
