import { LinearProgress, Paper, Stack } from '@mui/material';
import React from 'react';

interface CustomPaperProps extends React.HTMLAttributes<HTMLElement> {
  loading?: boolean;
  footer?: React.ReactElement;
}

const CustomLinearPaper: React.FC<CustomPaperProps> = ({
  loading,
  children,
  footer,
  ...paperProps
}) => {
  return (
    <Paper {...paperProps} sx={{ overflow: 'hidden !important' }}>
      {children}
      <Stack>
        {loading && (
          <LinearProgress />
        )}
      </Stack>
      {footer}
    </Paper>
  );
};

export default React.memo(CustomLinearPaper);