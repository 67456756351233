import { CheckTypeEnum } from '@api-client';
import { globalModels } from '@shared/duck';
import { UIAsset, UISeverityBadge, tableTypes } from '@shared/components';
import { Nuclei, Zap } from '@assets';
import { useLaunchDarklyFlags } from '@shared/hooks';
import { Box } from '@mui/material';

export const getFindingsColumns = (href?: string): tableTypes.ColumnProps<globalModels.IssueKindStatisticsViewModel>[] => {
  const { showEngineIcon } = useLaunchDarklyFlags();

  const findingsColumns: tableTypes.ColumnProps<globalModels.IssueKindStatisticsViewModel>[] =
    [
      {
        title: '',
        columnKey: 'customField',
        minWidth: '1rem',
        cellInfo: {
          getIcon(item) {
            return getEngineIcon(item.type[0]);
          },
          alignItems: 'center',
        },
      },
      {
        title: 'Issue',
        columnKey: 'name',
        cellInfo: {
          href(item) {
            return href?.replace(':kindId', item.kindId.toString() || '') || '';
          },
        },
      },
      {
        title: 'URL Paths',
        columnKey: 'vulnPaths',
      },
      {
        title: 'Open Issues',
        columnKey: 'openIssues',
      },
      {
        title: 'Resolved',
        columnKey: 'resolved',
      },
      {
        title: 'False Positives',
        columnKey: 'falsePositives',
        headerInfo: {
          cellTooltipText: 'These are all of end-points that \n' +
            'carry an exploitable vulnerability ',
        },
      },
      {
        title: 'Severity',
        columnKey: 'labels',
        customCellRenderer(_, item) {
          return (
            <Box p='0.75rem'>
              <UISeverityBadge title={item?.labels?.[0] || ''} />
            </Box>
          );
        },
      },
    ];

  return showEngineIcon ? findingsColumns : findingsColumns.slice(1);
};

const getEngineIcon = (type: CheckTypeEnum) => {
  switch (type) {
    case CheckTypeEnum.NucleiBuiltIn:
    case CheckTypeEnum.NucleiCustom:
    default:
      return <UIAsset src={Nuclei}></UIAsset>;
    case CheckTypeEnum.OwaspActive:
    case CheckTypeEnum.OwaspPassive:
      return <UIAsset src={Zap}></UIAsset>;
  }
};