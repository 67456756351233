import { Issue, ResolutionEnum } from '@api-client';
import { UIMenu } from '@shared/components';
import { globalEnums, globalQueries, globalTypes } from '@shared/duck';
import { ItemSelectionApi } from '@shared/hooks/use-item-selection';
import { IssueResolutionEnum } from 'models/Enums';
import React from 'react';
import { occurencesTableUtils } from '../occurences-table/duck';

interface TableActionsMenuProps {
  itemSelectionApi?: ItemSelectionApi<Issue>;
}

const TableActionsMenu: React.FC<TableActionsMenuProps> = ({
  itemSelectionApi,
}) => {
  const { updateIssue } = globalQueries.useUpdateIssue();

  const onResolutionChange = React.useCallback(
    (value?: IssueResolutionEnum) => {

      itemSelectionApi?.selectedItems.forEach((item) => {
        occurencesTableUtils.onResolutionChange(
          updateIssue,
          item,
          value as ResolutionEnum,
        );
      });
    },
    [itemSelectionApi?.selectedItems],
  );

  const itemList: globalTypes.UIMenuItemsList[] = [
    {
      title: 'Open',
      itemAction: () => onResolutionChange(IssueResolutionEnum.open),
    },
    {
      title: 'Resolved',
      itemAction: () => onResolutionChange(IssueResolutionEnum.resolved),
    },
    {
      title: 'False Positive',
      itemAction: () => onResolutionChange(IssueResolutionEnum.falsePositive),
    },
  ];

  return (
    <UIMenu
      type={globalEnums.EMenuType.COMPLEX}
      headerText={'Mark selected as...'}
      itemList={itemList}
      iconVariant={globalEnums.EMuiIconButtonVariants.DEFAULT}
    />
  );
};

export default TableActionsMenu;