import { Datepicker } from '@contentful/f36-components';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { MuiAutocomplete } from '@shared/components';
import { globalTypes } from '@shared/duck';
import { FULL_WEEK_MONTH_DATE } from '@shared/duck/constants';
import { muiPalette } from '@shared/general-mui-theme';
import { Dayjs } from 'dayjs';
import React from 'react';
import { StyledStack } from './expiration-select.styled';
import { CUSTOM_EXPIRATION, EXPIRATION_SELECT_OPTIONS, ExpirationValue, getNewDate, transformToDayjs } from '../../duck';

interface ExpirationSelectContentProps extends globalTypes.MUISelectProps<ExpirationValue> {
  expireDate: Dayjs | null;
  setExpireDate: (date: Dayjs | null) => void;
}

const ExpirationSelectContent: React.FC<ExpirationSelectContentProps> = ({
  expireDate,
  setExpireDate
}) => {
  const [expiration, setExpiration] = React.useState<ExpirationValue>(EXPIRATION_SELECT_OPTIONS[1]);

  const today = new Date();

  React.useEffect(() => {
    setExpireDate(getNewDate(expiration?.value));
  }, [expiration]);

  return (
    <Stack>
      <Typography
        variant='subtitle2'
        fontWeight='400'
      >
        {'Create a NightVision API Token.'}
      </Typography>
      <Typography variant={'subtitle2'}>
        {'Expiration'}
      </Typography>
      <StyledStack flexDirection='row'>
        <Box flexGrow={1} maxWidth='40%' width='100%' flexDirection={'column'}>
          <MuiAutocomplete
            options={EXPIRATION_SELECT_OPTIONS}
            autoComplete
            autoHighlight
            value={expiration}
            onChange={(_, newValue: ExpirationValue) => setExpiration(newValue || null)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select expire date"
              />
            )}
            disableClearable
            getOptionKey={(option: ExpirationValue) => option.label}
            getOptionLabel={(option: ExpirationValue) => option.label}
          />
        </Box>
        {expiration.value !== CUSTOM_EXPIRATION.value ? (
          <Typography
            variant='subtitle2'
            color={muiPalette.grey?.['500']}
            fontSize={'0.875rem'}
            fontWeight={400}
          >
            {expiration.value ? `This token will expire on ${expireDate?.format(FULL_WEEK_MONTH_DATE)}` : 'This token will never expire!'}
          </Typography>
        ) : (
          <Datepicker
            className='datepicker'
            inputProps={{
              placeholder: 'Expiration date',
              name: 'expiration-date',
            }}
            onSelect={(date) => { setExpireDate(transformToDayjs(date || today)); }}
            selected={expireDate?.toDate()}
            fromDate={new Date(new Date().setDate(today.getDate() + 1))}
            dateFormat={'E, LLL d uuuu'}
          />
        )}
      </StyledStack>
    </Stack>
  );
};

export default ExpirationSelectContent;