import { Stack, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const StyledPanel = styled(Stack)`
  border-bottom: 1px solid ${muiPalette.grey?.[300]};
  flex-direction: row;
`;

const StyledFlex = styled(Stack)`
  border: 1px solid ${muiPalette.grey?.[300]};
  border-radius: 0.375rem;
  gap: 0;
`;

export { StyledPanel, StyledFlex };
