import React from 'react';
import { useFormikContext } from 'formik';
import { WebTargetsContentValues } from '../../duck';
import { globalEnums, globalQueries, globalTypes, globalUtils } from '@shared/duck';
import { Spinner } from '@contentful/f36-components';
import { useDebounce } from '@shared/hooks';
import { UITextInput } from '@shared/components';

export interface NameCellRenderProps extends globalTypes.UITextInputProps {
  index: number;
  project?: string;
  isInEdit: boolean;
}

const NameColumnRender: React.FC<NameCellRenderProps> = ({
  index,
  project,
  isInEdit,
  ...innerTextInputProps
}: NameCellRenderProps) => {
  const { values, setFieldValue, validateForm } = useFormikContext<WebTargetsContentValues>();
  const { debouncedValue = values.targets[index].name, onChange: onDebounceChange } = useDebounce<string>();
  const [changes] = React.useState({ count: 0 });
  const [abortController] = React.useState(new AbortController());

  const componentWillUnmount = React.useRef(false);

  const cleanup = React.useCallback(() => {
    abortController.abort();
    componentWillUnmount.current = true;
  }, [abortController]);

  globalUtils.useComponentWillUnmount(cleanup);

  const [initialName, setInitialName] = React.useState('');
  React.useEffect(() => {
    setInitialName(values.targets[index].name);
    setFieldValue(`targets[${index}].exists`, false);
    setFieldValue(`targets[${index}].isNameTested`, values.targets[index].isNameTested);
    isInEdit && setEnabled(true);
  }, []);

  const { getTarget, isLoading } = globalQueries.useGetTargetByName(abortController.signal);
  const [enabled, setEnabled] = React.useState<boolean>(!isInEdit);

  const testName = async () => {
    setEnabled(true);
    if (values.targets[index].name) {
      const currCounter = changes.count;
      try {
        if (isInEdit && values.targets[index].name.trim() === initialName) {
          throw new Error();
        }
        await getTarget({ id: project || '', targetName: values.targets[index].name.trim() });
        if (changes.count === currCounter && !componentWillUnmount.current) {
          setFieldValue(`targets[${index}].exists`, true);
          setFieldValue(`targets[${index}].isNameTested`, true);
        }
      } catch {
        if (changes.count === currCounter && !componentWillUnmount.current) {
          setFieldValue(`targets[${index}].exists`, false);
          setFieldValue(`targets[${index}].isNameTested`, true);
        }
      }
    }
  };

  React.useEffect(() => {
    if (enabled) {
      testName();
    }
  }, [project]);

  React.useEffect(() => {
    if (((isInEdit && initialName) || !isInEdit) && !values.targets[index].isNameTested && !enabled)
      testName();
  }, [debouncedValue, initialName]);

  return (
    <>
      <UITextInput
        {...innerTextInputProps}
        isInvalid={enabled ? innerTextInputProps.isInvalid : false}
        onChange={(e) => {
          setEnabled(false);
          innerTextInputProps.onChange?.(e);
          setFieldValue(`targets[${index}].name`, e.target.value);
          setFieldValue(`targets[${index}].exists`, false);
          setFieldValue(`targets[${index}].isNameTested`, false);
          changes.count++;
          onDebounceChange(e.target.value);

          const newTargets = values.targets;
          newTargets[index] = {
            ...newTargets[index],
            name: e.target.value,
            exists: false,
            isNameTested: false
          };
          // All setters work asynchronously, but we need to run validation with correct data
          validateForm({ ...values, targets: newTargets });
        }}
        endIcon={enabled
          ? (
            !isLoading
              ? (
                innerTextInputProps.endIcon
                  ? innerTextInputProps.endIcon
                  : <></>
              )
              : <Spinner size='small' />
          )
          : <></>}
        noBorders={innerTextInputProps.isInvalid && enabled
          ? globalEnums.EmotionBoolean.False
          : globalEnums.EmotionBoolean.True}
      />
    </>
  );
};

export default NameColumnRender;