import { MuiTable, tableTypes } from '@shared/components';
import { allRoutesEnum, globalModels, globalTypes } from '@shared/duck';
import React from 'react';
import { targetsTableConsts, targetsTableHooks } from './duck';
import { useCreateOrEditTargetsHandler, useDeleteTargetHandler } from '@shared/hooks';
import { useNavigate } from 'react-router-dom';
import { TargetGray } from '@assets';

interface TargetsTableProps {
  search?: string;
  selectedProjects: globalTypes.UIOptionItem<string>[];
}

const TargetsTable: React.FC<TargetsTableProps> = ({
  search,
  selectedProjects,
}: TargetsTableProps) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [search, JSON.stringify(selectedProjects)]);

  const {
    targets,
    totalItemsCount,
    isTargetsListLoading,
    targetsHasNextPage,
    fetchNextTargetsPage,
  } = targetsTableHooks.useTargetsTableApi({
    projects: selectedProjects.map(proj => proj.value),
    search,
    page: currentPage,
    pageSize: rowsPerPage,
  });

  const { deleteTargetHandler } = useDeleteTargetHandler();
  const navigate = useNavigate();
  const { createOrEditTargetHandler } = useCreateOrEditTargetsHandler();

  const tableConfig: tableTypes.MuiTableProps<globalModels.TargetViewModel> = {
    data: targets,
    columns: targetsTableConsts.targetsColumns,
    loading: isTargetsListLoading,
    isLoadMoreAvailable: targetsHasNextPage,
    onLoadMoreClick: fetchNextTargetsPage,
    itemActions: item => (
      {
        itemList: [
          {
            title: 'Edit',
            itemAction: () => {
              createOrEditTargetHandler({ target: item, enableProjectSelect: false });
            },
          },
          {
            title: 'Delete',
            itemAction: () => {
              deleteTargetHandler(item);
            },
            isRedText: true,
          },
        ],
      }
    ),
    onRowClick: (item) => (
      navigate(allRoutesEnum.TargetDetails.replace(':targetId', item.id))
    ),
    noContentMessage: 'No Targets found',
    noContentIcon: TargetGray,
    pagination: {
      count: totalItemsCount,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: (e, newPage) => handleChangePage(newPage),
      onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
    },
  };

  return (
    <MuiTable
      {...tableConfig}
    />
  );
};

export default TargetsTable;