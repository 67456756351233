import { globalModels } from '@shared/duck';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

interface UseCreateOrEditTargetsHandlerProps {
  onAfterSubmit?: (item: any) => void
  initialProject?: string | undefined,
  initialTab?: 'general' | 'advanced',
  applicationId?: string;
  target?: globalModels.TargetViewModel;
  enableProjectSelect?: boolean;
}

export const useCreateOrEditTargetsHandler = () => {

  const { showModal } = useGlobalModalContext();

  const createOrEditTargetHandler = ({
    enableProjectSelect,
    target
  }: UseCreateOrEditTargetsHandlerProps) => {
    showModal({
      modalType: MODAL_TYPE.TargetModal,
      modalProps: {
        enableProjectSelect,
        target,
      },
    });
  };

  return { createOrEditTargetHandler };
};