import { globalModels, globalQueries, globalUtils } from '@shared/duck';
import React from 'react';

interface TargetTableApiResult {
  targets: globalModels.TargetViewModel[],
  totalItemsCount: number,
  isTargetsListLoading: boolean,
  targetsHasNextPage: boolean,
  fetchNextTargetsPage: () => void,
  onDeleteTargetClick?: (item: globalModels.TargetViewModel) => void,
}

// todo: delete

export const useTargetsTableApi = ({
  page,
  projects,
  search,
  pageSize,
}: {
  page: number;
  projects?: string[];
  search?: string;
  pageSize?: number;
}): TargetTableApiResult => {
  const {
    targets: dtoTargets,
    totalItemsCount,
    isTargetsListLoading,
    targetsHasNextPage = false,
    fetchNextTargetsPage,
  } = globalQueries.useGetTargetsList({
    page: page + 1,
    filter: search,
    project: projects,
    pageSize: pageSize,
  });

  const targets = React.useMemo(
    () =>
      dtoTargets?.map(
        (target) => globalUtils.mapTargetsDtoToViewModel(target)) || [],
    [dtoTargets],
  );

  return {
    targets,
    totalItemsCount,
    isTargetsListLoading,
    targetsHasNextPage,
    fetchNextTargetsPage,
  };
};