import React from 'react';
import { globalEnums, globalTypes, globalUtils } from '@shared/duck';
import { CodeFragment } from './code-fragment';
import UITabs from '../tabs/tabs';
import { Box } from '@mui/material';


const UICodeFragment: React.FC<globalTypes.CodeFragmentProps> = ({
  code,
  windowsCode = code,
  useTabsForMultiline = false,
  ...props
}) => {
  const { os } = globalUtils.getOSInfo();

  const includeNewLines = code.includes('\\\n');
  const replacedWindowsCode = windowsCode.replaceAll('\\\n', '`\n');

  try {
    code = decodeURIComponent(code);
  }
  catch {
    // skip URIError in case of malformed text
  }

  const defaultTab = os !== globalEnums.OS.Windows ? globalEnums.OS.Mac : globalEnums.OS.Windows;
  const [tabsValue, setTabsValue] = React.useState<globalEnums.OS>(defaultTab);

  const handleTabsChange = (value: globalEnums.OS) => {
    setTabsValue(value);
  };

  const tabsConfig: globalTypes.UITabsProps = React.useMemo(() => {
    return {
      tabsProps: {
        value: tabsValue,
        onChange: (e, value) => handleTabsChange(value),
        'aria-label': 'code_fragment_tabs',
      },
      tabs: [
        {
          label: 'Shell (Mac/Linux)',
          value: globalEnums.OS.Mac,
        },
        {
          label: 'PowerShell (Windows)',
          value: globalEnums.OS.Windows,
        },
      ],
      tabsPanels: [
        {
          tabPanelId: globalEnums.OS.Mac,
          openTabId: tabsValue,
          children: <CodeFragment {...props} code={code} />,
        },
        {
          tabPanelId: globalEnums.OS.Windows,
          openTabId: tabsValue,
          children: <CodeFragment {...props} code={replacedWindowsCode} />,
        },
      ],
    };
  }, [tabsValue, code, windowsCode]);

  return (
    // relative for absolute positioning of Copy button
    <Box sx={{ position: 'relative' }}>
      {useTabsForMultiline && includeNewLines ? (
        <UITabs {...tabsConfig}/>
      ) : (
        <CodeFragment {...props} code={code} />
      )}
    </Box>
  );
};

export default UICodeFragment;
