import { MuiApplicationSelect } from '@shared/components';
import { useFormikContext } from 'formik';
import React from 'react';
import { formDataTypes } from '../../duck';

const ApplicSelect: React.FC = ({
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext<formDataTypes.FormDataFormValues>();

  return (
    <MuiApplicationSelect
      {...props}
      label=''
      projectId={values.project?.id}
      defaultApplication={values.application}
      onFormChange={(e) => setFieldValue('application', e.target.value)}
    />
  );
};

export default ApplicSelect;