import { Application, ApplicationConfigurationExcludedIssuesInner, ApplicationRequest, ApplicationUpdate, ApplicationUpdateRequest } from '@api-client';
import { globalQueries } from '@shared/duck';
import { AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { ApplicationFormValues } from '../components/application-modal/application-modal';

const CreateApplicationRequest = (values: ApplicationFormValues): ApplicationRequest | ApplicationUpdateRequest => {
  values.criteria = values.criteria.filter((el) => Object.values(el).filter(e => e && e.toString().length > 0).length);
  const config: ApplicationConfigurationExcludedIssuesInner[] | undefined = values.criteria.length ?
    values.criteria.map(({
      issue_kind, target, parameter, path, payload
    }) => (
      {
        issue_kind: issue_kind,
        target: target,
        path: path,
        parameter: parameter,
        payload: payload,
      }
    )) : undefined;

  return {
    project: values.project?.id,
    name: values.name,
    labels: values.labels,
    configuration: config || values.url_patterns || values.xpath_patterns ? {
      excluded_url_patterns: values.url_patterns,
      excluded_x_paths: values.xpath_patterns,
      excluded_issues: config,
    } : null,
  };
};

const getErrorMessage = (error: any): string | undefined => {
  const response = error.response?.data['errors'][0];
  if (response.code === 'already_exists')
    return response.detail.toString();
};

export const onSubmitHandler = (applicationId?: string) => {
  const { createApplication } = globalQueries.useCreateApplication();
  const onCreate = async (values: ApplicationFormValues): Promise<AxiosResponse<Application, any> | null> => {
    const request = CreateApplicationRequest(values) as ApplicationRequest;
    let response: AxiosResponse<Application, any> | null = null;
    try {
      response = await createApplication({ applicationRequest: request });
    }
    catch (error) {
      enqueueSnackbar(getErrorMessage(error) ?? 'Failed to create Application', { variant: 'error' });
    }
    finally {
      return response;
    }
  };

  const { updateApplication } = globalQueries.useUpdateApplication();
  const onUpdate = async (values: ApplicationFormValues): Promise<AxiosResponse<ApplicationUpdate, any> | null> => {
    const request = CreateApplicationRequest(values) as ApplicationUpdateRequest;
    let response: AxiosResponse<ApplicationUpdate, any> | null = null;
    try {
      response = await updateApplication({ id: applicationId || '', applicationUpdateRequest: request });
    }
    catch (error) {
      enqueueSnackbar(getErrorMessage(error) ?? 'Failed to update Application', { variant: 'error' });
    }
    finally {
      return response;
    }
  };
  const onSubmit = applicationId ? onUpdate : onCreate;

  return { onSubmit };
};