import React from 'react';
import { default as ApplicationModal, CreateApplicationModalProps, ApplicationFormValues } from './application-modal';
import { onSubmitHandler } from 'pages/workspace-router/applications/duck';
import { useGlobalModalContext } from '@shared/duck/contexts';
import { Application, ApplicationUpdate } from '@api-client';

export interface ApplicationModalContainerProps extends Omit<CreateApplicationModalProps, 'onSubmit' | 'onClose'> {
  enableProjectSelect?: boolean;
  applicationId?: string;
  onResult?: (data: Application | ApplicationUpdate | undefined) => void;
}

const ApplicationModalContainer: React.FC<ApplicationModalContainerProps> = ({
  applicationId,
  enableProjectSelect = true,
  onResult,
  ...props
}: ApplicationModalContainerProps
) => {
  const { hideModal } = useGlobalModalContext();

  const { onSubmit } = onSubmitHandler(applicationId);

  const onSubmitForm = async (values: ApplicationFormValues) => {
    const response = await onSubmit(values);
    response && onResult?.(response?.data);
    return !!response;
  };

  return (
    <ApplicationModal
      {...props}
      onClose={hideModal}
      enableProjectSelect={applicationId ? false : enableProjectSelect}
      applicationId={applicationId}
      onSubmit={onSubmitForm}
    />
  );
};

export default ApplicationModalContainer;
