import { styled, Stack } from '@mui/material';

export const StyledStack = styled(Stack)`
  flex-direction: row;
  align-items: center;

  .datepicker {
    input {
      height: 2.8rem;
      max-height: unset;
    }

    button {
      height: 2.8rem;
      padding: 0.75rem;
    }
  }
`;