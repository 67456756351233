import { IconProps } from '../BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from '../icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='16'
    height='18'
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <path
      d='M1.4525 5.21993L8 9.00743L14.5475 5.21993M8 16.5599V8.99993M14.75 11.9999V5.99993C14.7497 5.73688 14.6803 5.47853 14.5487 5.2508C14.417 5.02306 14.2278 4.83395 14 4.70243L8.75 1.70243C8.52197 1.57077 8.2633 1.50146 8 1.50146C7.7367 1.50146 7.47803 1.57077 7.25 1.70243L2 4.70243C1.7722 4.83395 1.58299 5.02306 1.45135 5.2508C1.31971 5.47853 1.25027 5.73688 1.25 5.99993V11.9999C1.25027 12.263 1.31971 12.5213 1.45135 12.7491C1.58299 12.9768 1.7722 13.1659 2 13.2974L7.25 16.2974C7.47803 16.4291 7.7367 16.4984 8 16.4984C8.2633 16.4984 8.52197 16.4291 8.75 16.2974L14 13.2974C14.2278 13.1659 14.417 12.9768 14.5487 12.7491C14.6803 12.5213 14.7497 12.263 14.75 11.9999Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

function NavTargetsIcon(props: IconProps): JSX.Element {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default NavTargetsIcon;
