import { AutocompleteChangeReason, AutocompleteInputChangeReason, AutocompleteRenderInputParams, Chip, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { useMedia } from '@shared/hooks';
import React from 'react';

interface CustomTextFieldProps<T> extends AutocompleteRenderInputParams {
  value: string;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
  selectedValues?: T[];
  setSelectedValues?: (newValues: T[], options?: T[], reason?: AutocompleteChangeReason) => void;
  onInputChange: (value: string, reason: AutocompleteInputChangeReason) => void;
  filterName: string;
}

export const CustomTextField = <T,>({
  value,
  setActive,
  loading,
  selectedValues,
  setSelectedValues,
  onInputChange,
  filterName,
  ...params
}: CustomTextFieldProps<T>) => {
  const { isSmallScreen } = useMedia();
  return (
    <TextField
      {...params}
      inputProps={{
        ...params.inputProps,
        value: value,
        onKeyDown: (e) => { // prevent values from being deleted by clicking backspace
          if (e.key === 'Backspace') {
            e.stopPropagation();
          }
        },
      }
      }
      onFocus={() => setActive(true)}
      onBlur={() => setActive(false)}
      sx={{
        '& .MuiAutocomplete-input': {
          minWidth: '0 !important',
        },
      }}
      InputProps={{
        ...params.InputProps,
        startAdornment: null,
        endAdornment: (
          <InputAdornment position='end' sx={{ gap: '0.2rem', ml: 0, width: selectedValues?.length ? '50%' : '0', justifyContent: 'flex-end' }}>
            {params.InputProps.endAdornment}
            {loading && <CircularProgress color='inherit' size={16} sx={{ flexShrink: 0 }} />}
            {!!selectedValues?.length && (
              <IconButton
                onClick={(e) => {
                  setSelectedValues?.([], selectedValues, 'removeOption');
                  onInputChange('', 'clear');
                  e.stopPropagation();
                }}
                size='small'
              >
                <X size={16} />
              </IconButton>
            )}
            {!!selectedValues?.length && (
              <Chip
                sx={{ overflow: 'hidden' }}
                label={isSmallScreen ? selectedValues.length : `${selectedValues.length} selected`} />
            )}
          </InputAdornment>
        )
      }}
      placeholder={filterName}
    />
  );
};