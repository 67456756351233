import {
  DialogActionsClasses,
  DialogClasses,
  DialogContentClasses,
  DialogProps,
  Interpolation,
  Theme,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const DialogStyles: Partial<OverridesStyleRules<keyof DialogClasses, 'MuiDialog', Omit<Theme, 'components'>>> | undefined = {
  root: {
    zIndex: '100',
    borderRadius: '0.375rem',
    boxShadow: '0 13px 27px -5px rgba(25, 37, 50, 0.15), 0 8px 16px -8px rgba(25, 37, 50, 0.20), 0 -6px 16px -6px' +
      ' rgba(25, 37, 50, 0.03), 0 0 0 1px rgba(25, 37, 50, 0.10)',
  },
};

export const DialogVariants: {
  props: Partial<DialogProps>,
  style: Interpolation<{ theme: Theme }>
}[] | undefined = [
  {
    props: {
      fullScreen: true,
    },
    style: {
      '.MuiDialog-paper': {
        width: '100%'
      }
    },
  },
];

export const DialogTitleStyles: Partial<OverridesStyleRules<'root', 'MuiDialogTitle', Omit<Theme, 'components'>>> | undefined = {
  root: {
    padding: 'initial',
    color: '#111B2B', // gray900 in figma
    fontSize: '1rem !important',
    lineHeight: '1.5rem',
    fontWeight: '600',
  },
};

export const DialogContentStyles: Partial<OverridesStyleRules<keyof DialogContentClasses, 'MuiDialogContent'>> | undefined = {
  root: { padding: '1rem 1.5rem' },
  dividers: { padding: '1rem 1.5rem' },
};

export const DialogActionsStyles: Partial<OverridesStyleRules<keyof DialogActionsClasses, 'MuiDialogActions'>> | undefined = {
  root: {
    padding: '0.75rem 1.5rem',
  },
};