import { Theme, TooltipClasses, TooltipProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

declare module '@mui/material/Tooltip' {
  interface TooltipComponentsPropsOverrides {
    disable: true,
  }
}

export const TooltipDefaultProps: Partial<TooltipProps> | undefined =
{
  arrow: true,
  disableInteractive: true,
  placement: 'top',
};

export const TooltipStyles: Partial<OverridesStyleRules<keyof TooltipClasses, 'MuiTooltip', Omit<Theme, 'components'>>> | undefined =
{
  tooltip: {
    backgroundColor: '#111B2B',
    color: 'white',
    padding: '0.5rem',
    borderRadius: '0.375rem',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1rem',
  },
  arrow: {
    color: '#111B2B',
  },
};