import { FormControl, FormControlProps } from '@contentful/f36-components';
import styled from '@emotion/styled';
import { withSafeProps } from 'views/utils/common';

interface StyledBaseFormControlProps extends FormControlProps {
  fullWidth?: boolean;
}

const StyledBaseFormControl = styled(withSafeProps<StyledBaseFormControlProps>(FormControl, ['fullWidth']))`
  width: ${({ fullWidth }) => fullWidth ? '100%' : ''};
`;

export { StyledBaseFormControl };