import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';
import { TextLink } from '@contentful/f36-components';

const Label = styled.label`
  margin-bottom: 0.5rem;
  color: ${baseTokens.gray900};
  font-weight: 500;
`;

const StyledTextLink = styled(TextLink)`
  & > span {
    align-items: center;
  }
`;

export { Label, StyledTextLink };