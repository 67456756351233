import { AppLayout } from '@shared/components';
import { allRoutesEnum, globalUtils } from '@shared/duck';
import { useLaunchDarklyFlags } from '@shared/hooks';
import { Settings } from './settings';
import { Showcase } from './showcase';
import { ProfileOld } from './profile-old';
import { SignUpPage } from './sign-up';
import { AuthenticationsRouter } from './authentications';
import { Navigate, Route } from 'react-router-dom';
import { NotFoundPage } from '@pages';
import { ApplicationsRouter } from './applications';
import { ProjectsRouter } from './projects';
import { ScansRouter } from './scans';
import { TargetsRouter } from './targets';
import { DocumentationRedirect } from './documentation-redirect';
import { NewScanPageRouter } from './new-scan-page';
import { HomePage } from './home';

const useWorkspaceRouter = () => {
  const { vulnerableShowcase, settingsFlag } = useLaunchDarklyFlags();

  return (
    <>
      <Route element={<AppLayout />}>
        <Route path={allRoutesEnum.Root} element={<Navigate to={allRoutesEnum.Home} replace />} />
        <Route path={allRoutesEnum.Home} element={<HomePage />} />
        {/** Note: if Settings are enable, Profile is moved into them */}
        {!settingsFlag && <Route path={allRoutesEnum.Profile} element={<ProfileOld />} />}
        {settingsFlag && <Route path={allRoutesEnum.Settings} element={<Settings />} />}
        <Route path={globalUtils.withSubRoutes(allRoutesEnum.Scans)} element={<ScansRouter />} />
        <Route path={globalUtils.withSubRoutes(allRoutesEnum.NewScan)} element={<NewScanPageRouter />} />
        <Route path={globalUtils.withSubRoutes(allRoutesEnum.Applications)} element={<ApplicationsRouter />} />
        <Route path={globalUtils.withSubRoutes(allRoutesEnum.Targets)} element={<TargetsRouter />} />
        <Route path={globalUtils.withSubRoutes(allRoutesEnum.Authentications)} element={<AuthenticationsRouter />} />
        <Route path={globalUtils.withSubRoutes(allRoutesEnum.Projects)} element={<ProjectsRouter />} />
        {/* <Route path={globalUtils.withSubRoutes(allRoutesEnum.NucleiTemplates)} element={<NucleiTemplatesRouter />} /> */}
      </Route>
      <Route>
        <Route path={allRoutesEnum.GetStarted} element={<SignUpPage />} />
        <Route path={allRoutesEnum.Documentation} element={<DocumentationRedirect />} />
        {vulnerableShowcase && (
          <Route
            path={allRoutesEnum.ScanDetailsFindingsShowcase}
            element={<Showcase />}
          />
        )}
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </>
  );
};

export default useWorkspaceRouter;
