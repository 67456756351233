import React from 'react';
import { Header, TargetsTable } from './components';
import { useSearchParams } from 'react-router-dom';
import { useCreateOrEditTargetsHandler, useDebounce } from '@shared/hooks';
import { globalTypes } from '@shared/duck';
import { Stack } from '@mui/material';

const UITargetsPage: React.FC = () => {
  const [debounceSearchEnabled, setDebounceSearchEnabled] = React.useState(false);
  const [searchFilter, setSearchFilter] = React.useState<string>();
  const { debouncedValue: debouncedSearch, onChange: onSearchChange, value = '' } = useDebounce<string>();
  const { createOrEditTargetHandler } = useCreateOrEditTargetsHandler();
  const [selectedProjects, setSelectedProjects] = React.useState<globalTypes.UIOptionItem<string>[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  // Handle initial search param value
  React.useLayoutEffect(() => {
    onSearchChange(searchParams.get('search') || undefined);
  }, []);

  // Handle search params
  React.useEffect(() => {
    setSearchFilter(searchParams.get('search') || undefined);
  }, [searchParams]);

  React.useEffect(() => {
    // onDebounceSearchChange
    // apply debounceSearch change only after search filter was touched
    if (debounceSearchEnabled && debouncedSearch != undefined) {
      setSearchParams((urlParams) => {
        const newSearch = debouncedSearch;
        if (newSearch) {
          urlParams.set('search', newSearch);
        } else {
          urlParams.delete('search');
        }
        return urlParams;
      });
    }
  }, [debouncedSearch, debounceSearchEnabled, setSearchParams]);

  return (
    <Stack gap={1.5} sx={{ overflow: 'hidden' }}>
      <Header
        search={value}
        onSearchChange={e => {
          setDebounceSearchEnabled(true); // allow to debounce after first change
          onSearchChange(e.target.value);
        }}
        onNewTargetClick={() => createOrEditTargetHandler({})}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
      />

      <TargetsTable
        search={searchFilter}
        selectedProjects={selectedProjects}
      />
    </Stack>
  );
};

export default UITargetsPage;