import { tableTypes, UISeverityDots } from '@shared/components';
import { allRoutesEnum, globalConstants, globalEnums, globalModels } from '@shared/duck';
import dayjs from 'dayjs';
import { InfoCircleIcon } from 'views/components/v1/Icons';

export const applicationColumns: tableTypes.ColumnProps<globalModels.ApplicationsViewModel>[] = [
  {
    title: 'Application Name',
    columnKey: 'name',
    cellInfo: {
      description(item) {
        return item.lastScanEndedAt ?
          `Last Scan: ${dayjs(item.lastScanEndedAt).format(globalConstants.FULL_MONTH_DATE_TIME)}` :
          '';
      },
      href(item) {
        return allRoutesEnum.ApplicationDetails.replace(':applicationId', item.id);
      },
    },
    minWidth: '20.5rem',
  },
  {
    title: 'Label(s)',
    columnKey: 'labels',
    minWidth: '16.3rem',
  },
  {
    title: 'Targets',
    columnKey: 'noOfTargets',
    minWidth: '16.3rem',
  },
  {
    title: 'Unique URL Paths',
    columnKey: 'vulnerablePathsStat',
    headerInfo: {
      icon:
        <InfoCircleIcon
          variant={globalEnums.EIconVariants.MUTED}
          size='small'
        />,
      iconTooltipText: 'Based on the most recent scan performed',
      tooltipPlace: 'top',
    },
    minWidth: '13.3rem',
    customCellRenderer(value, item) {
      return (
        <UISeverityDots values={item.vulnerablePathsStat} popupComments='paths' />
      );
    },
  },
];