import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Tooltip, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { sidebarConsts } from './duck';
import { CollapsibleButton, Drawer, DrawerHeader, ListItemButtonStyled } from './sidebar.styled';
import { NewScanButton, NightvisionLogo } from './components';
import { useMedia } from '@shared/hooks';
import { CaretDoubleLeft } from '@phosphor-icons/react';

interface CollapsibleSideBarProps {
  className?: string;
  toggleDrawer: () => void;
  open: boolean;
}

const CollapsibleSideBar: React.FC<CollapsibleSideBarProps> = ({
  className,
  toggleDrawer,
  open,
}) => {
  const { isSmallScreen } = useMedia();
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  React.useEffect(() => {
    isSmallScreen && open && toggleDrawer();
  }, [location.pathname, isSmallScreen]);

  return (
    <Box className={className}>
      <CollapsibleButton
        onClick={toggleDrawer}
        open={open}
        color='outlined'
        isSmallScreen={isSmallScreen}
      >
        <CaretDoubleLeft size={16} weight='bold' />
      </CollapsibleButton>
      <Drawer
        variant={isSmallScreen ? 'temporary' : 'persistent'}
        open={open}
        ModalProps={{
          keepMounted: true,
        }}
        isSmallScreen={isSmallScreen}
        onClose={() => toggleDrawer()}
        onOpen={() => toggleDrawer()}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <DrawerHeader sx={{ px: open ? 1.2 : 0.87 }}>
          <NightvisionLogo showText={open} />
        </DrawerHeader>
        <NewScanButton isSideBarCollapsed={!open} />
        <List>
          {sidebarConsts.MENU.map((item, index) => {
            const Icon = item.icon;
            const to = item.to?.replace('/*', '');
            const isActive = location.pathname.startsWith(to);
            return (
              <ListItem key={item.title} disablePadding sx={{ display: 'block' }} >
                <Link to={to} target={index === sidebarConsts.MENU.length - 1 ? '_blank' : ''}>
                  <Tooltip
                    title={open ? '' : item.title}
                    placement='right'
                    arrow
                    disableHoverListener={open}
                  >
                    <span>
                      <ListItemButtonStyled
                        disableTouchRipple
                        sx={{
                          justifyContent: open ? 'initial' : 'center',
                        }}
                        isActive={isActive}
                      >
                        <ListItemIcon
                          sx={{
                            mr: open ? 0.5 : 'auto',
                          }}
                        >
                          {Icon && <Icon size='small' />}
                        </ListItemIcon>
                        <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButtonStyled>
                    </span>
                  </Tooltip>
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </Box>
  );
};

export default CollapsibleSideBar;
