import { User, UserMeUpdateUpdateErrorResponse400 } from '@api-client';
import { Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { ProfileForm, UIUserAvatar } from '@shared/components';
import { allRoutesEnum, globalTypes, globalUtils } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import { useApp, useMedia } from '@shared/hooks';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Greetings } from './components';
import { signUpUtils } from './duck';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { TextLink } from '@contentful/f36-components';

const SignUpPage: React.FC = () => {
  const { isSmallScreen, isLargeScreen, isMediumScreen } = useMedia();
  const navigate = useNavigate();
  const app = useApp();
  const currentUser: User | undefined = app.users.me?.dto;

  const initialValues = React.useMemo(() => {
    return signUpUtils.getInitialValues(currentUser);
  }, [currentUser]);

  const onSubmit = async (values: globalTypes.ProfileFormValues, formikHelpers: FormikHelpers<globalTypes.ProfileFormValues>) => {
    const userUpdateRequest = globalUtils.getUserUpdateRequest(values);
    try {
      await app.users.updateMe(userUpdateRequest);
      enqueueSnackbar('Personal info updated', { variant: 'success' });
      navigate(allRoutesEnum.Home);
    }
    catch (err) {
      const error = err as AxiosError<UserMeUpdateUpdateErrorResponse400, any>;
      const errors = error?.response?.data?.errors || [];
      for (const e of errors) {
        if (e.attr === 'username' && e.code === 'unique') {
          formikHelpers.setFieldError('username', e.detail);
        }
      }
      enqueueSnackbar('Could not update user info. Please try again.', { variant: 'error' });
    }
  };

  return (
    <Grid
      height='100%'
      container
    >
      {isMediumScreen && (
        <Greetings />
      )}

      <Grid item lg={6.5} xs={12}
        display='flex' flexDirection='column' justifyContent='center'
        padding={isSmallScreen ? '2rem' : (isMediumScreen ? '3rem' : '7rem')}
      >
        <Formik<globalTypes.ProfileFormValues>
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnChange
          validateOnBlur
          validationSchema={signUpUtils.profileValidationSchema}
        >
          {({ handleSubmit, isSubmitting, isValid, values, initialValues, setFieldValue }) => {
            React.useEffect(() => {
              if (initialValues.username === values.username) {
                setFieldValue('usernameExists', false);
              }
            }, [values.username]);

            const isUsernameValid = values.usernameExists !== undefined && !values.usernameExists;

            return (
              <>
                <Stack flexDirection='row' gap='1rem' paddingBottom='3rem' justifyContent='space-between'>
                  <Typography
                    variant='h4'
                    fontWeight='600'
                    color={muiPalette.grey?.[900]}
                    style={{ wordBreak: 'keep-all' }}
                  >
                    Let’s begin! 🚀
                  </Typography>

                  <Stack flexDirection='row' alignItems='center'>
                    <Stack alignItems='flex-end' gap={0}>
                      <Typography
                        fontSize='0.875rem'
                        fontWeight={400}
                        lineHeight='1.25rem'
                        letterSpacing='-0.00963rem'
                        color={muiPalette.grey?.[500]}
                        style={{ wordBreak: 'break-word', textAlign: 'right' }}
                      >
                        {values.email}
                      </Typography>
                      <TextLink onClick={() => app.logout()}>Log out</TextLink>
                    </Stack>
                    <UIUserAvatar
                      src={values?.avatarUrl}
                      avatarSize={2}
                    />
                  </Stack>
                </Stack>

                <Stack gap={4}>
                  <ProfileForm />

                  <Button
                    variant='contained'
                    sx={{ width: 'fit-content' }}
                    disabled={isSubmitting || !isValid || !isUsernameValid}
                    onClick={() => handleSubmit()}
                    endIcon={isSubmitting && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                  >
                    {'Let\'s Go'}
                  </Button>
                </Stack>
              </>
            );
          }}
        </Formik>
      </Grid>

      {isLargeScreen && (
        <Greetings />
      )}
    </Grid>
  );
};

export default SignUpPage;