import { Stack, Typography } from '@mui/material';
import { DocsLink, UICodeFragment } from '@shared/components';
import { globalConstants, globalEnums } from '@shared/duck';
import { NucleiInstallModal } from './components';
import React from 'react';

interface NucleiTabProps {
  validateWithNuclei: string;
}

export const NucleiTab: React.FC<NucleiTabProps> = ({
  validateWithNuclei,
}) => {
  return (
    <Stack width='100%'>
      <Typography variant='body2' fontSize='0.875rem' paddingTop='1rem'>
        {'Copy and Paste the following in your CLI. Make sure you have Nuclei installed on your device.'}
      </Typography>
      <UICodeFragment
        code={validateWithNuclei}
        language={globalEnums.SupportedLangs.evidence_markup}
        useTabsForMultiline
      />

      <NucleiInstallModal />

      <Stack gap={0} flexDirection='row' alignItems='center'>
        <Typography variant='body2' fontSize='0.875rem'>
          {'Learn more about CLI Web Scans in our'}&nbsp;
        </Typography>
        <DocsLink
          to={globalConstants.DOCS_PATHS.CLI_CHEAT_SHEET_SCANNING}
        >
          {'documentation'}
        </DocsLink>
      </Stack>
    </Stack>
  );
};
