import { SelectChangeEvent, SelectProps } from '@mui/material';
import { MuiSelect } from '@shared/components';
import { useFormikContext } from 'formik';
import React from 'react';
import { fpModalTypes } from '../../duck';
import { globalEnums, globalTypes } from '@shared/duck';

const MethodSelect: React.FC<SelectProps> = ({
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext<fpModalTypes.FpModalFormValues>();
  const [value, setValue] = React.useState<string>(values.method.toUpperCase() || '');

  const options: globalTypes.MuiSelectOptions[] = React.useMemo(() => {
    return [
      {
        key: globalEnums.HttpMethodEnum.GET,
        label: globalEnums.HttpMethodEnum.GET,
      }, {
        key: globalEnums.HttpMethodEnum.POST,
        label: globalEnums.HttpMethodEnum.POST,
      }, {
        key: globalEnums.HttpMethodEnum.DELETE,
        label: globalEnums.HttpMethodEnum.DELETE,
      }, {
        key: globalEnums.HttpMethodEnum.PUT,
        label: globalEnums.HttpMethodEnum.PUT,
      }, {
        key: globalEnums.HttpMethodEnum.PATCH,
        label: globalEnums.HttpMethodEnum.PATCH,
      },
    ];
  }, []);

  React.useEffect(() => {
    if (values.method.toUpperCase() !== value) {
      setFieldValue('method', value);
    }
  }, [value]);

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    setValue(options.find((option) => option.key === value.toUpperCase())?.key as string || '');
  };

  return (
    <MuiSelect
      {...props}
      id='method-select'
      label=''
      value={value}
      options={options}
      onChange={(e) => handleOnChange(e)}
      placeholder='Select'
    />
  );
};

export default MethodSelect;
