import { Card, CardActionArea, CardContent, CardHeader, Typography } from '@mui/material';
import { UIAsset } from '@shared/components';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';

interface ScanTypeCardProps {
  asset: string;
  title: string;
  description: string;
  action: () => void;
  disabled?: boolean;
}

const ScanTypeCard: React.FC<ScanTypeCardProps> = ({
  asset,
  title,
  description,
  action,
  disabled,
}: ScanTypeCardProps) => {

  return (
    <Card sx={disabled ? {
      background: muiPalette.grey?.[100],
      ':hover': {
        border: '1px solid #CFD9E0',
        cursor: 'not-allowed',

      },
      ':active': {
        border: '1px solid #CFD9E0',
        cursor: 'not-allowed',
        boxShadow: 'none',
      },
    } : {}}>
      <CardActionArea
        onClick={action}
        sx={{
          padding: '1.5rem'
        }}
        disableRipple
        disabled={disabled}
      >
        <CardHeader
          avatar={<UIAsset src={asset} />}
          sx={{
            marginBottom: '3rem'
          }}
        />
        <CardContent>
          <Typography
            variant='h6'
            marginBottom='0.5rem'
            lineHeight='2rem'
          >
            {title}
          </Typography>

          <Typography
            variant='subtitle2'
            sx={{
              fontSize: '0.875rem',
              fontWeight: '400',
              lineHeight: '1.25rem',
              letterSpacing: '-0.00963rem',
              color: `${muiPalette.grey?.[500]}`,
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ScanTypeCard;