import { FullScreenFooter } from './components';
import { FullScreenContainer, FullScreenContent, FullScreenLogoutButton, FullScreenPageLayout, FullScreenTopBar } from './full-screen-page.styled';
import { LogoFull } from '@assets';
import { TextLink } from '@contentful/f36-components';
import { Link, Stack, Typography } from '@mui/material';
import {  UIAsset, UIUserAvatar } from '@shared/components';
import { globalConstants, globalEnums } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import { useApp } from '@shared/hooks';
import { useNavigate } from 'react-router-dom';

interface FullScreenPageProps {
  backButton?: boolean;
  name: string;
  stretchContent?: boolean;
}
export const FullScreenPage = ({ backButton, stretchContent, name, children }: React.PropsWithChildren<FullScreenPageProps>) => {
  const app = useApp();
  const navigate = useNavigate();
  const isEnvySubscription = app.users.plan === globalEnums.SubscriptionPlan.API_ENVY;

  return (
    <FullScreenPageLayout>
      <FullScreenTopBar>
        <Stack flexDirection='row' alignItems='center'>
          <FullScreenLogoutButton>
            <Typography variant='default' color={muiPalette.grey?.[500]}>
              {app.users.me?.dto.email}
            </Typography>
            <br />
            <TextLink onClick={() => app.logout()}>Log out</TextLink>
          </FullScreenLogoutButton>
          <UIUserAvatar
            src={app.users.me?.dto.avatar_url}
            avatarSize={2}
          />
        </Stack>
        {backButton && (
          <TextLink onClick={() => navigate(-1)}>Back</TextLink>
        )}
      </FullScreenTopBar>
      <FullScreenContainer>
        <UIAsset src={LogoFull} />
        <FullScreenContent stretch={stretchContent ? 1 : 0}>
          <Typography
            fontWeight='600' fontSize='1.25rem' lineHeight='2rem'
          >
            {name}
          </Typography>

          {isEnvySubscription && (
            <Typography variant='subtitle1'  textAlign='center'>
              Your subscription includes only Unlimited&nbsp;
              <Link
                href={globalConstants.MAIN_DOCS_URL + globalConstants.DOCS_PATHS.API_EXTRACTION_OVERVIEW}
                target='_blank'
              >
                API Discovery
              </Link>
              &nbsp;feature.<br />
              To access more of NightVision upgrade your account:
            </Typography>
          )}
          {children}
        </FullScreenContent>
      </FullScreenContainer>
      <FullScreenFooter />
    </FullScreenPageLayout>
  );
};
