import { IconProps } from './BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from './icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <g clipPath='url(#clip0_814_51339)'>
      <path
        d='M3.70419 2.31055C3.60989 2.31055 3.51945 2.34801 3.45277 2.41469C3.38609 2.48137 3.34863 2.5718 3.34863 2.6661V13.3328C3.34863 13.4271 3.38609 13.5175 3.45277 13.5842C3.51945 13.6509 3.60989 13.6883 3.70419 13.6883C3.79849 13.6883 3.88892 13.6509 3.9556 13.5842C4.02228 13.5175 4.05974 13.4271 4.05974 13.3328V2.6661C4.05974 2.5718 4.02228 2.48137 3.9556 2.41469C3.88892 2.34801 3.79849 2.31055 3.70419 2.31055Z'
        fill='#0059C8'
      />
      <path
        d='M12.4442 2.95766C12.3902 2.92645 12.3289 2.91002 12.2665 2.91002C12.2041 2.91002 12.1427 2.92645 12.0887 2.95766C11.3994 3.2474 10.6566 3.38797 9.90914 3.3701C9.26515 3.32298 8.63797 3.14258 8.06736 2.84032C7.51858 2.54383 6.91464 2.36349 6.29314 2.31055C5.78023 2.31111 5.27257 2.41385 4.7998 2.61277V8.71055C5.26304 8.45709 5.78288 8.32499 6.31092 8.32655C6.83381 8.37842 7.3413 8.53309 7.80425 8.78166C8.46563 9.12702 9.19274 9.32819 9.93758 9.37188C10.7925 9.38964 11.6413 9.22352 12.4265 8.88477C12.4851 8.85522 12.5344 8.81002 12.5689 8.75416C12.6035 8.69831 12.6218 8.63398 12.622 8.56832V3.25988C12.621 3.19844 12.6041 3.1383 12.573 3.08533C12.5418 3.03236 12.4975 2.98838 12.4442 2.95766Z'
        fill='#0059C8'
      />
    </g>
    <defs>
      <clipPath id='clip0_814_51339'>
        <rect width='12.8' height='12.8' fill='white' transform='translate(1.59961 1.59961)' />
      </clipPath>
    </defs>
  </svg>
);

function ResolutionFalsePositiveIcon(props: IconProps) {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default ResolutionFalsePositiveIcon;
