import {
  MuiContentLoader,
  NoContentPlaceholder,
  UILink,
  UISeverityBadge,
} from '@shared/components';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import IssueWriteupModal from 'views/components/modals/IssueWriteupModal';
import { GridItem } from '@contentful/f36-components';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ContentGrid } from './issue-details.styled';
import { DetailsVulPathsTable, IssueInformation } from './components';
import { ErrorContext } from '@shared/duck/contexts';
import { globalQueries } from '@shared/duck';
import { AttentionGray } from '@assets';
import { issueDetailsConstants } from './duck';
import { Typography, Button, Stack, Box } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

interface IssueDetailsPageProps {
  scanId: string;
}

const IssueDetailsPage: React.FC<IssueDetailsPageProps> = ({ scanId }) => {
  const { setError } = React.useContext(ErrorContext);
  const { kindId: kindIdParam = '' } = useParams<{
    kindId: string;
  }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const kindId = Number(kindIdParam);
  const { issueKind, isIssueKindLoading } = globalQueries.useGetIssueKind(
    { id: kindId },
    {
      onError: (er) => errorResponseHandler(er, 'issue', setError),
    },
  );
  const { scan, isScanLoading } = globalQueries.useGetScan({ id: scanId }, {
    onError: err => errorResponseHandler(err, 'scan', setError),
  });
  const [currentIssueId, setCurrentIssueId] = React.useState<string>();
  const [noContent, setNoContent] = React.useState<boolean>();
  const [firstRender, setFirstRender] = React.useState(true);

  React.useEffect(() => {
    setSearchParams((urlParams) => {
      if (currentIssueId) {
        urlParams.set('issueId', currentIssueId);
        try {
          const requestTab = urlParams.get(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB);
          const responseTab = urlParams.get(issueDetailsConstants.SEARCH_TAB_NAME.RESPONSE_TAB);

          if (!(requestTab || responseTab)) {
            throw new Error();
          }

          if (requestTab) {
            // to avoid resetting the initial values for page first rendering
            if (firstRender) {
              const isValid = Object.values(issueDetailsConstants.REQUEST_TAB).find(el => el === requestTab);
              if (!isValid) throw new Error();
              // for backward compability
              if (requestTab === issueDetailsConstants.REQUEST_TAB.POSTDATA) {
                urlParams.set(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB, issueDetailsConstants.REQUEST_TAB.BODY);
              }
              urlParams.delete(issueDetailsConstants.SEARCH_TAB_NAME.RESPONSE_TAB);
            }
            else {
              urlParams.set(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB, issueDetailsConstants.REQUEST_TAB.RAW);
            }
          }
          else if (responseTab) {
            // to avoid resetting the initial values for page first rendering
            if (firstRender) {
              const isValid = Object.values(issueDetailsConstants.RESPONSE_TAB).find(el => el === responseTab);
              if (!isValid) throw new Error();
              urlParams.delete(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB);
            }
            else {
              urlParams.set(issueDetailsConstants.SEARCH_TAB_NAME.RESPONSE_TAB, issueDetailsConstants.RESPONSE_TAB.RAW);
            }
          }
        }
        catch { // set default values
          urlParams.set(issueDetailsConstants.SEARCH_TAB_NAME.RESPONSE_TAB, issueDetailsConstants.RESPONSE_TAB.RAW);
          urlParams.delete(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB);
        }
        setFirstRender(false);
      }
      return urlParams;
    }, {
      replace: true
    });
  }, [currentIssueId]);

  React.useEffect(() => {
    setCurrentIssueId(searchParams.get('issueId') || '');
  }, [searchParams]);

  const { issue: currentIssue } = globalQueries.useGetIssueById({ id: currentIssueId || '' }, {
    enabled: !!currentIssueId,
    onError: err => errorResponseHandler(err, 'issue', setError),
  });
  const issueName = issueKind?.name || '';

  const [isWriteupShown, setIsWriteupShown] = React.useState(false);
  const onViewIssueWriteup = () => setIsWriteupShown(true);
  const onHideIssueWriteup = () => setIsWriteupShown(false);

  return (
    <>
      <Stack direction='row' gap='1rem' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
        <Stack direction='row' gap='1rem' flexWrap='wrap'>
          <MuiContentLoader
            isLoading={isIssueKindLoading || (
              isScanLoading && !!scanId
            )}
            padding='initial'
          >
            <Box>
              <Stack direction='row' gap='1rem' alignItems='center'>
                <Typography variant='h5'
                  lineHeight='2.25rem'
                >
                  {issueName}
                </Typography>
                <UISeverityBadge title={issueKind?.severity || ''} />
              </Stack>

              <Stack direction='row' gap='1rem' alignItems='center'>
                <Typography variant='default' color={muiPalette.grey?.[500]}>
                  {'Host '}
                  {scan?.target_name ? (
                    <UILink
                      href={scan.location || ''}
                      text={scan.location || ''}
                    />
                  ) : (
                    <> {'N/A'}</>
                  )}
                </Typography>
              </Stack>
            </Box>
          </MuiContentLoader>
        </Stack>
        <Box>
          <Button onClick={() => onViewIssueWriteup()}>
            View Issue Details
          </Button>
        </Box>
      </Stack>
      <ContentGrid columns='1fr 1fr' columnGap='none'>
        <GridItem className='grid-item-issues'>
          <DetailsVulPathsTable
            kindId={kindId}
            scanId={scanId}
            currentIssue={currentIssue}
            currentIssueId={currentIssueId}
            setCurrentIssueId={setCurrentIssueId}
            setNoContent={setNoContent}
          // unselectCurrentIssueOnResolutionFilterChange
          />
        </GridItem>
        <GridItem className='grid-item-info'>
          {noContent && !currentIssue ? (
            <NoContentPlaceholder message='Please select an Issue' iconSrc={AttentionGray} />
          ) : (
            <IssueInformation currentIssue={currentIssue} setCurrentIssueId={setCurrentIssueId} />
          )}
        </GridItem>
      </ContentGrid>
      <IssueWriteupModal isShown={isWriteupShown} onClose={onHideIssueWriteup} issueName={issueName} />
    </>
  );
}
  ;

export default IssueDetailsPage;
