import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';
import { withSafeProps } from 'views/utils/common';
import { Box, BoxProps, Typography } from '@mui/material';
import { globalConstants } from '@shared/duck';

interface FileInputContainerProps {
  isDraggable?: boolean;
  isInvalid?: boolean;
}

const FileInputContainer = styled(withSafeProps<FileInputContainerProps & BoxProps>(Box, ['isDraggable', 'isInvalid']))`
  position: relative;
  border: ${({ isDraggable, isInvalid }) =>
    isDraggable ? (`1px dashed ${baseTokens.blue300}`) : (
      isInvalid ? `1px dashed ${globalConstants.INPUT_BORDER.ERROR}` : `1px dashed ${baseTokens.gray300}`
    )};
  background-color: ${({ isDraggable }) => isDraggable && baseTokens.blue100};
  border-radius: 0.375rem;
  padding: 1rem;

  & button, a {
    padding: 0;
  }

  & .MuiTypography-root {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  & .MuiTextField-root {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    font-size: 0.875rem;
    
    & > div {
      height: 100%;
      
      & > input {
        cursor: pointer;
        height: 100%;
      }
    }
  }
`;

const FileInputText = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;

  & > a {
    position: relative;
    z-index: 10;
    color: #0059C8;
    cursor: pointer;
  }
`;

export { FileInputContainer, FileInputText };