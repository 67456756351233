import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormikControl, UIProjectSelect } from '@shared/components';
import { globalEnums } from '@shared/duck';
import { useDebounce } from '@shared/hooks';

export interface CommonContentHeaderProps {
  project?: string;
  enableProjectSelect?: boolean;
  onProjectChange: (newProject?: string) => void;
  showToggleButtons: boolean;
  currentTab: globalEnums.ETargetType;
  onTabChange: (_: any, newTab: globalEnums.ETargetType) => void;
}

export const CommonContentHeader: React.FC<CommonContentHeaderProps> = ({
  project,
  enableProjectSelect,
  onProjectChange,
  showToggleButtons,
  currentTab,
  onTabChange,
}) => {
  const { debouncedValue: debouncedSearch, onChange: onSearchChange } = useDebounce<string>();

  return (
    <>
      <FormikControl name='project' label='Project' showError style={{ marginBottom: '2rem' }}>
        <UIProjectSelect
          currentValue={project}
          onAfterChange={e => onProjectChange(e.target.value as string)}
          placeholder='All Projects'
          search={debouncedSearch}
          onSearchChange={onSearchChange}
          enableProjectSelect={enableProjectSelect}
        />
      </FormikControl>

      {/* <FormikControlMui name='project' label='Project' showError sx={{ mb: '1.5rem' }}>
        <MuiProjectSelect
          disabled={!enableProjectSelect}
          value={project}
          onFormChange={e => onProjectChange(e.target.value)}
          placeholder='All Projects'
        />
      </FormikControlMui> */}

      {showToggleButtons && (
        <ToggleButtonGroup
          color='primary'
          value={currentTab}
          exclusive
          onChange={onTabChange}
        >
          <ToggleButton value={globalEnums.ETargetType.URL}> Web Targets</ToggleButton>
          <ToggleButton value={globalEnums.ETargetType.OpenAPI}>API Target</ToggleButton>
        </ToggleButtonGroup>
      )}
    </>
  );
};