import { globalQueries } from '@shared/duck';
import { ErrorContext } from '@shared/duck/contexts';
import React from 'react';
import { errorResponseHandler } from 'views/utils/errorHandlers';

export const getApplication = (applicationId: string) => {
  const { setError } = React.useContext(ErrorContext);
  return globalQueries.useGetApplication(
    { id: applicationId },
    {
      onError: (er) => errorResponseHandler(er, 'application', setError),
    },
  );
};