import React from 'react';
import { Description, Title } from '../../cli-commands.styled';
import { UICodeFragment } from '@shared/components';
import { SupportedLangs } from '@shared/duck/enums/global-enums';
import { useFormikContext } from 'formik';
import { formDataTypes } from '../../../../../components';
import { CliCommandsProps } from '../../cli-commands';
import { globalEnums } from '@shared/duck';

const UploadSpec: React.FC<CliCommandsProps> = ({
  fileAttached
}) => {
  const { values } = useFormikContext<formDataTypes.FormDataFormValues>();
  const initTargetName = values.target?.name;
  const initTargetUrl = values.target?.location;
  const newTargetName = values.newTargetName;
  const newTargetUrl = values.baseUrl;
  const projectId = values.project?.id;

  const isTargetNameChanged = !values.createNewTargetCheckbox && newTargetName && (initTargetName !== newTargetName);
  const isTargetLocationChanged = !values.createNewTargetCheckbox && (initTargetUrl !== newTargetUrl);
  const isNewTarget = values.createNewTargetCheckbox;
  let step = 1;

  return (
    <Description variant='caption'>
      {isNewTarget && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'Let’s create a new target first.'}
          <UICodeFragment code={`# Create target\nnightvision target create ${newTargetName} ${newTargetUrl} -P ${projectId} --type ${globalEnums.ECLITargetType.API}`} language={SupportedLangs.bash} />
        </>
      )}

      {(isTargetNameChanged || isTargetLocationChanged) && !fileAttached ? (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'We have noticed that you made changes to the target; let\'s apply those first:'}
          <UICodeFragment code={`# Update target with swagger file\nnightvision target update ${initTargetName} ${isTargetNameChanged ? `-n ${newTargetName}` : ''}${isTargetLocationChanged ? `-u ${newTargetUrl}` : ''} -f $PATH_TO_SWAGGER_FILE -P ${projectId}`} language={SupportedLangs.bash} />
        </>
      ) : (
        <>
          <Title> {`Step ${step++}`}</Title>
          Upload Swagger file. Replace <i>$PATH_TO_SWAGGER_FILE</i> with the path to the Swagger file on your machine.
          <UICodeFragment code={`# Update target with swagger file\nnightvision target update ${isNewTarget ? newTargetName : initTargetName} -f $PATH_TO_SWAGGER_FILE -P ${projectId}`} language={SupportedLangs.bash} />
        </>
      )}

      <Title>{`Step ${step++}`}</Title>
      {'Run scan:'}
      <UICodeFragment code={`# Run scan\nnightvision scan ${isTargetNameChanged || isNewTarget ? newTargetName : initTargetName} -a ${values.application?.name} -P ${projectId} ${values.authentication ? `--auth ${values.authentication.name}` : ''}`} language={SupportedLangs.bash} />
    </Description >
  );
};

export default UploadSpec;