import { FalsePositive } from '@api-client';
import { AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';
import * as Yup from 'yup';
import { globalModels, globalQueries } from '@shared/duck';
import { fpModalTypes } from '.';

export const getInitialValues = (falsePositive: globalModels.FalsePositivesModel) => {
  return {
    kindId: falsePositive?.kind || 0,
    path: falsePositive?.path || '',
    method: falsePositive?.httpMethod || '',
    paramName: falsePositive?.parameterName || '',
  };
};

export const modalValidationSchema = Yup.object().shape({
  kindId: Yup.number().required(),
  path: Yup.string(),
  method: Yup.string(),
  paramName: Yup.string(),
});

export const onSubmitHandler = (targetId: string, falsePositiveId?: string) => {
  const { createFalsePositives } = globalQueries.useCreateFalsePositives();
  const { updateFalsePositives } = globalQueries.useUpdateFalsePositives();

  const onCreate = async (data: fpModalTypes.FpModalFormValues): Promise<AxiosResponse<void, any> | null> => {
    let response: AxiosResponse<void, any> | null = null;

    try {
      response = await createFalsePositives(
        {
          id: targetId,
          falsePositiveRequest: [
            {
              kind: data.kindId,
              url_path: data.path,
              http_method: data.method,
              parameter_name: data.paramName,
            },
          ],
        },
      );
    } catch (error) {
      enqueueSnackbar('Failed to create new False Positive', { variant: 'error' });
    } finally {
      return response;
    }
  };

  const onUpdate = async (data: fpModalTypes.FpModalFormValues): Promise<AxiosResponse<FalsePositive, any> | null> => {
    let response: AxiosResponse<FalsePositive, any> | null = null;

    try {
      response = await updateFalsePositives(
        {
          falsePositiveId: falsePositiveId || '',
          id: targetId,
          falsePositiveUpdateRequest:
          {
            kind: data.kindId,
            url_path: data.path,
            http_method: data.method,
            parameter_name: data.paramName,
          },
        },
      );
    } catch (error) {
      enqueueSnackbar('Failed to edit False Positive', { variant: 'error' });
    } finally {
      return response;
    }
  };

  const onSubmit = falsePositiveId ? onUpdate : onCreate;

  return { onSubmit };
};