import { BasicTableProps, ColumnProps } from '@shared/components/table/duck/types';
import { groupBy } from 'lodash';
import React from 'react';
import { TableSpinner } from 'views/components/v1/Table/Table.styled';
import { BasicBodyRow, StyledLastRow, GroupedRow } from './components';
import { globalTypes } from '@shared/duck';
import { StyledBasicTableBody } from './basic-table-body.styled';

interface BasicTableBodyProps<DataItem> extends BasicTableProps<DataItem> {
  data: DataItem[];
  columns: ColumnProps<DataItem>[];
}

const BasicTableBody = <DataItem extends globalTypes.UIDItem>({
  data,
  columns,
  lastRowProps,
  withRowSelection = true,
  loading,
  groupRowsBy = () => '',
  ...props
}: BasicTableBodyProps<DataItem>) => {
  const groupedItems = React.useMemo(() => {
    return groupBy(data, groupRowsBy);
  }, [data, groupRowsBy]);

  return (
    <StyledBasicTableBody withRowSelection={withRowSelection} withSelectableRows={!!props.onRowClick}>
      {loading && <TableSpinner colSpan={columns.length} />}
      {data.length ? (
        <>
          {Object.entries(groupedItems)
            .map((
              ([group, items]) => (
                <React.Fragment key={group}>
                  {group ? (
                    <GroupedRow
                      columns={columns}
                      group={group}
                      itemSelection={props.itemSelection}
                      itemActions={props.itemActions}
                    />
                  ) : null}

                  {items.map((dataItem, index) => (
                    <BasicBodyRow<DataItem>
                      key={dataItem?.id ? (
                        dataItem.toString.length == 0 ? `index-${index}` : dataItem.id
                      ) : `item-${index}`}
                      rowKey={dataItem.id}
                      dataItem={dataItem}
                      columns={columns}
                      index={index}
                      {...props}
                    />
                  ))}

                  {lastRowProps && (
                    <StyledLastRow
                      colLength={columns.length}
                      bordered={props.bordered}
                      {...lastRowProps}
                    />
                  )}
                </React.Fragment>
              )
            ))}
        </>
      ) : <></>}
    </StyledBasicTableBody>
  );
};

export default BasicTableBody;