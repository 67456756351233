import { OpenApiTarget, OpenApiTargetRequest } from '@api-client';
import { globalConstants, globalModels, globalQueries, globalUtils } from '@shared/duck';
import * as Yup from 'yup';
import { ApiTargetsContentWrapperValues, NewOpenApiTarget, ScanTargetOpenApi } from './types';
import { TargetExclusionsValuesSchema } from '../../target-exclusions/duck';
import { enqueueSnackbar } from 'notistack';

export const importTargetsValidationSchema = Yup.object().shape({
  newTarget: Yup.object().when('isFileUrl', {
    is: true,
    then: schema =>
      schema.shape({
        name: Yup.string().when('exists', {
          is: true,
          then: schema => schema.test('exists', 'This name is already taken in this Project', () => false),
          otherwise: schema => schema.required('Please enter target name').max(100, 'Name must be at most 100 characters')
            .test('isValid', globalConstants.INVALID_NAME_FORMAT, (value?: string) => {
              return globalUtils.validateName(value || '');
            }),
        }),
        isNameTested: Yup.boolean().oneOf([true], 'Name must be tested').required('Please test the name'),
        isAccessTested: Yup.boolean().oneOf([true], 'Url must be tested').required('Please test the url'),
        location: Yup.string().required('Base target URL is required')
          .test('isValid', 'Invalid format', (value?: string) => {
            const newUrls = value?.split('\n').filter(url => url.length);
            return globalUtils.validateUrls(newUrls || []);
          }),
        fileLocation: Yup.string().required('Please enter the OpenAPI file location or attach an existing OpenAPI file')
          .test('isValid', 'Invalid format', (value?: string) => {
            const newUrls = value?.trim();
            return globalUtils.validateUrls([newUrls || '']);
          })
          .when('isFileUrlValid', {
            is: false,
            then: schema => schema.test('fileUrlInvalid', globalConstants.INVALID_YAML_DEFINITION, () => false)
          })
          .when('isFileUrlValid2', {
            is: false,
            then: schema => schema.test('fileUrlInvalid2', globalConstants.INACCESSIBLE_URL, () => false)
          }),
        isFileUrlValid: Yup.boolean().oneOf([true], 'Must be valid Yaml data').required('Must be valid Yaml data'),
        isFileUrlValid2: Yup.boolean().oneOf([true], 'Must be valid Yaml data').required('Must be valid Yaml data'),
      }),
    otherwise: schema => schema.shape({
      name: Yup.string().when('exists', {
        is: true,
        then: schema => schema.test('exists', 'This name is already taken in this Project', () => false),
        otherwise: schema => schema.required('Please enter target name').max(100, 'Name must be at most 100 characters')
          .test('isValid', globalConstants.INVALID_NAME_FORMAT, (value?: string) => {
            return globalUtils.validateName(value || '');
          }),
      }),
      isAccessTested: Yup.boolean().oneOf([true], 'Url must be tested').required('Please test the url'),
      isNameTested: Yup.boolean().oneOf([true], 'Name must be tested').required('Please test the name'),
      location: Yup.string().required('Base target URL is required')
        .test('isValid', 'Invalid format', (value?: string) => {
          const newUrls = value?.split('\n').filter(url => url.length);
          return globalUtils.validateUrls(newUrls || []);
        }),
      file: Yup.mixed().nullable().required('Please attach an existing Swagger file'),
      isFileUrlValid: Yup.boolean().notRequired(),
      isFileUrlValid2: Yup.boolean().notRequired(),
    }),
  }),
  exclusions: TargetExclusionsValuesSchema,
});


export const onCreateNewTarget = () => {
  const { uploadOpenApiSpec } = globalQueries.useUploadOpenApiSpec();
  const { createOpenApiTargets } = globalQueries.useCreateTargetsOpenApi();

  const onCreate = async (targetToCreate: NewOpenApiTarget, isFileUrl: boolean) => {
    const target: ScanTargetOpenApi = {
      ...targetToCreate,
      credentials: new Map(),
      isAccessTested: false,
    };

    const request: OpenApiTargetRequest = {
      name: target.name || '',
      credentials_id: null,
      project: target.project,
      location: target.location,
      swaggerfile_url: isFileUrl ? target.fileLocation : undefined,
      internet_accessible: target.isAccessible,
    };

    const {
      data: [newTarget],
    } = await createOpenApiTargets({
      openApiTargetRequest: [request],
    });

    if (!isFileUrl && target.file) {
      await uploadOpenApiSpec({
        id: newTarget.id,
        file: target.file,
      });
    }

    return newTarget;
  };

  return { onCreate };
};

export const onUpdateNewTarget = () => {
  const { uploadOpenApiSpec } = globalQueries.useUploadOpenApiSpec();
  const { updateOpenApiTargets } = globalQueries.useUpdateTargetsOpenApi();

  const onUpdate = async (targetToCreate: NewOpenApiTarget, id: string, isFileUrl: boolean, isFileEdited?: boolean, isSwaggerFileChanged?: boolean) => {
    const target: ScanTargetOpenApi = {
      ...targetToCreate,
      credentials: new Map(),
      isAccessTested: false,
    };

    const request: OpenApiTargetRequest = {
      name: target.name || '',
      credentials_id: null,
      project: target.project,
      location: target.location,
      swaggerfile_url: isFileUrl ?
        isSwaggerFileChanged ? target.fileLocation : undefined
        : undefined,
    };

    const {
      data: newTarget,
    } = await updateOpenApiTargets({
      id: id,
      openApiTargetUpdateRequest: request,
    });

    if (!isFileUrl && target.file && isFileEdited) {
      await uploadOpenApiSpec({
        id: newTarget.id,
        file: target.file,
      });
    }
  };

  return { onUpdate };
};

const getErrorMessage = (error: any): string | undefined => {
  const response = error.response?.data['errors'][0];
  if (response.code === 'invalid') {
    return response.detail.toString();
  }
};

interface GetUtilsProps {
  openApiTarget?: OpenApiTarget;
  project?: string;
  onAfterUpdate?: (values: ApiTargetsContentWrapperValues) => void;
  onAfterCreate?: (values: ApiTargetsContentWrapperValues) => void;
  relatedApplication?: globalModels.ApplicationsViewModel;
  isFileEdited: boolean;
  selectedFile?: File;
  appConfigToTarget: boolean;
}

interface GetUtilsResultProps {
  onUpdateApiTarget: (values: ApiTargetsContentWrapperValues) => void;
  onCreateApiTarget: (values: ApiTargetsContentWrapperValues) => void;
  getInitialValues: () => ApiTargetsContentWrapperValues;
}

export const useGetApiUtils = ({
  openApiTarget,
  project,
  onAfterUpdate,
  onAfterCreate,
  relatedApplication,
  isFileEdited,
  selectedFile,
  appConfigToTarget,
}: GetUtilsProps): GetUtilsResultProps => {
  const { onCreate } = onCreateNewTarget();
  const { onUpdate } = onUpdateNewTarget();
  const { createApplication } = globalQueries.useCreateApplication();
  const { updateApplication } = globalQueries.useUpdateApplication();

  const swaggerFileUrl = openApiTarget?.swaggerfile_url;

  const onCreateApiTarget = async (values: ApiTargetsContentWrapperValues) => {
    try {
      const response = await onCreate({
        ...values.newTarget,
        project: project,
        file: values.isFileUrl ? undefined : values.newTarget.file,
        fileLocation: values.isFileUrl ? values.newTarget.fileLocation : undefined
      }, values.isFileUrl);

      if (appConfigToTarget) {
        await createApplication({
          applicationRequest: {
            name: `${response.id}-Application`,
            project: project,
            configuration: {
              excluded_url_patterns: values.exclusions.url_patterns,
              excluded_x_paths: values.exclusions.xpath_patterns,
            }
          }
        });
      }
      onAfterCreate?.({ ...values, newTarget: { ...values.newTarget, targetId: response.id } });
      enqueueSnackbar('OpenAPI Target has been created successfully', { variant: 'success' });
    }
    catch (error) {
      enqueueSnackbar(getErrorMessage(error) ?? 'Failed to create OpenAPI Target', { variant: 'error' });
    }
  };

  const onUpdateApiTarget = async (values: ApiTargetsContentWrapperValues) => {
    try {
      const isSwaggerUrlChanged = swaggerFileUrl?.trim() !== values.newTarget.fileLocation?.trim();
      await onUpdate({ ...values.newTarget, project: project }, openApiTarget?.id || '', values.isFileUrl, isFileEdited, isSwaggerUrlChanged);

      if (appConfigToTarget) {
        if (relatedApplication) {
          const isExclusionsChanged = (relatedApplication.configuration?.excluded_url_patterns !== values.exclusions.url_patterns)
            || (relatedApplication.configuration?.excluded_x_paths !== values.exclusions.xpath_patterns);

          if (isExclusionsChanged) {
            await updateApplication({
              id: relatedApplication?.id || '', applicationUpdateRequest: {
                configuration: {
                  excluded_url_patterns: values.exclusions.url_patterns,
                  excluded_x_paths: values.exclusions.xpath_patterns,
                }
              }
            });
          }
        }
        else {
          await createApplication({
            applicationRequest: {
              name: `${openApiTarget?.id}-Application`,
              project: project,
              configuration: {
                excluded_url_patterns: values.exclusions.url_patterns,
                excluded_x_paths: values.exclusions.xpath_patterns,
              }
            }
          });
        }
      }

      onAfterUpdate?.({ ...values, newTarget: { ...values.newTarget, targetId: openApiTarget?.id || '' } });
      enqueueSnackbar('OpenAPI Target has been updated successfully', { variant: 'success' });
    }
    catch (error) {
      enqueueSnackbar(getErrorMessage(error) ?? 'Failed to update OpenAPI Target', { variant: 'error' });
    }
  };

  const getInitialValues = () => {
    return {
      newTarget: {
        location: openApiTarget?.location || '',
        name: openApiTarget?.name || '',
        fileLocation: swaggerFileUrl || '',
        file: openApiTarget ? swaggerFileUrl ? undefined : selectedFile : undefined,
        project: openApiTarget?.project || project,
        isNameTested: !!openApiTarget,
        isAccessible: undefined,
        isAccessTested: false,
        exists: false,
        targetId: '',
      },
      isFileUrl: openApiTarget ? !!swaggerFileUrl : true,
      exclusions: {
        url_patterns: relatedApplication?.configuration?.excluded_url_patterns || [],
        xpath_patterns: relatedApplication?.configuration?.excluded_x_paths || [],
      },
    };
  };

  return {
    onCreateApiTarget,
    onUpdateApiTarget,
    getInitialValues,
  };
};