import { Interpolation, ListItemIconClasses, MenuClasses, MenuItemClasses, MenuItemProps, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const MenuStyles: Partial<OverridesStyleRules<keyof MenuClasses, 'MuiMenu', Omit<Theme, 'components'>>> | undefined =
  {
    list: {
      padding: 'initial',
    },
  };

export const MenuItemStyles: Partial<OverridesStyleRules<keyof MenuItemClasses, 'MuiMenuItem', Omit<Theme, 'components'>>> | undefined =
  {
    root: {
      minHeight: '2rem',
    },
  };

export const MenuItemVariants:
  {
    props: Partial<MenuItemProps>;
    style: Interpolation<{
      theme: Omit<Theme, 'components'>;
    }>;
  }[] | undefined = [
    {
      props: { color: 'error' },
      style: {
        color: '#BD002A',
      },
    },
  ];

export const ListItemIconStyles: Partial<OverridesStyleRules<keyof ListItemIconClasses, 'MuiListItemIcon'>> | undefined =
  {
    root: {
      minWidth: 'initial !important',
    },
  };