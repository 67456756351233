import { Check } from '@api-client';
import React from 'react';
import { Copy, DownloadGray, Link, LinkIconC } from '@assets';
import { UIAsset, ModalContent, StyledExtLink, MuiContentLoader, UICloseBtn } from '@shared/components';
import { StyledContainer, StyledHeaderContainer } from './text-modal.styled';
import { globalConstants, globalQueries } from '@shared/duck';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext } from '@shared/duck/contexts';
import { Alert, Box, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

interface ColumnTypeProps {
  item?: Check;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  isCheckLoading?: boolean;
  title?: string;
}

const TextModal: React.FC<ColumnTypeProps> = ({
  item,
  setModal,
  isCheckLoading = false,
  title,
}) => {

  const [text, setText] = React.useState('');
  const [num, setNum] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const targetRef = React.useRef<HTMLDivElement>(null);
  let numStr = '';
  const enableGetLogs = !!item;

  const onNewWindow = () => {
    // window.location.href = logsUrl!.url!.toString();
    const newWindow = window.open('INFO', 'Info');
    if (newWindow) {
      newWindow.document.write(`<div style='white-space: pre-line;'>${text}</div>`);

      newWindow.document.title = 'Logs';
      newWindow.document.close();
    }
  };

  const onDownload = () => {
    if (logsUrl?.url)
      window.location.href = logsUrl.url.toString();
  };

  const onCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar('Copied to clipboard!', { variant: 'info', autoHideDuration: 1000, closeBtn: false });
    });
  };

  const { setError } = React.useContext(ErrorContext);
  const { logsUrl, islogsUrlLoading } = globalQueries.useGetOwaspLogs(
    { id: item?.id || '' }, {
      onError: err => errorResponseHandler(err, 'scan', setError),
      enabled: enableGetLogs,
    }
  );

  const getDataFromUrl = async () => {
    try {
      if (logsUrl?.url) {
        const res = await fetch(logsUrl.url);
        const s = await res.text();
        setText(s);
      }
      else {
        setIsLoading(false);
      }
    }
    catch {
      enqueueSnackbar(globalConstants.FAILED_TO_READ_FILE, { variant: 'error' });
    }
  };

  React.useLayoutEffect(() => {
    const el = targetRef.current;
    if (el && !isLoading) {
      const divHeight = el.clientHeight;
      const lineHeight = parseInt(el.style.lineHeight);
      const lines = divHeight / lineHeight;
      setNum(lines);
    }
  });

  React.useEffect(() => {
    if (!isCheckLoading && !islogsUrlLoading) {
      getDataFromUrl();
    }
  }, [logsUrl, islogsUrlLoading, isCheckLoading]);

  React.useEffect(() => {
    if (text)
      setIsLoading(false);
  }, [text]);

  for (let i = 0; i < num; i++) numStr += (i + 1).toString() + '\n';
  numStr = numStr.substring(0, numStr.length - 1);

  return <>
    <StyledHeaderContainer>
      <Stack direction='row' className='header-container' gap='0'>
        <Box>{title || 'ZAPScanner\'s Logs: ' + item?.name}</Box>
        <StyledExtLink
          icon={<LinkIconC />}
          alignIcon='end'
          href={item?.link}
          target={'_blank'}
          rel='noopener noreferrer'
        />
      </Stack>
      <UICloseBtn onClose={() => setModal(false)} />
    </StyledHeaderContainer>

    <ModalContent
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <MuiContentLoader isLoading={isLoading || isCheckLoading}>
        {text?.length ? (
          <StyledContainer>
            <Box className='number' >
              {num > 0 && <div>{numStr}</div>}
            </Box>
            <Stack direction='row' className='modal-content'>
              <Box className='logs'>
                <div ref={targetRef} style={{ lineHeight: '16px' }} >{text}</div>
              </Box>
              <Stack p='0.5rem' gap='0' direction='row'>
                <Box className='icon' onClick={onCopy}>
                  <UIAsset type='image' src={Copy} />
                </Box>
                <Box className='icon' onClick={onNewWindow}>
                  <UIAsset type='image' src={Link} />
                </Box>
                <Box className='icon' onClick={onDownload}>
                  <UIAsset type='image' src={DownloadGray} />
                </Box>
              </Stack>
            </Stack>
          </StyledContainer>
        ) : (
          <Alert severity='info'>
            {'No logs found.'}
          </Alert>
        )}
      </MuiContentLoader>
    </ModalContent >
  </>;
};

export default TextModal;
