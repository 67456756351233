import { User } from '@api-client';
import { Typography } from '@mui/material';
import { Container } from './access-list.styled';
import { UserCard } from '../user-card';
import {
  AvatarContainer,
  ContentContainer,
} from '../../share-project-modal.styled';
import React from 'react';

interface AccessListProps {
  sharedUsers: User[] | undefined;
}

const AccessList: React.FC<AccessListProps> = ({
  sharedUsers,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const avatarContainerRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (containerRef.current && avatarContainerRef.current) {
      /** Note: calculate the maximum height of the parent container, which is equal to the height of 3 children */

      // get height in rem
      const childHeight = avatarContainerRef.current.getBoundingClientRect().height / 16;

      // (height + margin-top) * max 3 child
      containerRef.current.style.maxHeight = `${(childHeight + 1) * 3}rem`;
    }
  }, [containerRef.current, avatarContainerRef.current]);

  return (
    <ContentContainer>
      <Typography
        variant={'subtitle2'}
        sx={{ marginBottom: '0.25rem' }}
      >
        {'Users with access:'}
      </Typography>

      <Container ref={containerRef}>
        {sharedUsers?.map((user) => {
          return (
            <AvatarContainer
              ref={avatarContainerRef}
              key={user.id}
            >
              <UserCard key={user.id} user={user} />
            </AvatarContainer>
          );
        })}
      </Container>
    </ContentContainer>
  );
};

export default AccessList;