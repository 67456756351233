import React from 'react';
import { useNavigate } from 'react-router-dom';

import { App, AppModelContext } from './app';
import { allRoutesEnum } from '@shared/duck';

interface Props {
  children: React.ReactNode;
}

export const AppModelProvider: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [app] = React.useState(() => new App());
  // Workaround for React StrictMode to avoid double rendering
  const [isAfterFirstRender, setIsAfterFirstRender] = React.useState(false);
  React.useEffect(() => setIsAfterFirstRender(true), []);

  React.useEffect(() => {
    if (isAfterFirstRender) {
      // FIXME: handle public pages properly
      if (window.location.pathname.startsWith(allRoutesEnum.Error)) {
        // skip authentication for error page
      } else if (window.location.pathname.startsWith(allRoutesEnum.SSO)) {
        // skip authentication for SSO page
      } else {
        void app.authenticate(navigate);
      }
    }
  }, [isAfterFirstRender]);

  return <AppModelContext.Provider value={app}>{props.children}</AppModelContext.Provider>;
};
