import { Table } from '@contentful/f36-components';
import { ColumnProps, ItemSelectionApiProps } from '@shared/components/table/duck/types';
import {
  StyledGroupRow,
} from './grouped-row.styped';
import { bodyRowUtils } from '../duck';
import { menuTypes } from '@shared/components';

interface GroupedRowProps<DataItem> {
  columns: ColumnProps<DataItem>[];
  group: string;
  itemSelection?: ItemSelectionApiProps<DataItem>;
  itemActions?: (item: DataItem) => menuTypes.UIMenuProps;
}

const GroupedRow = <DataItem,>({
  columns,
  group,
  itemSelection,
  itemActions,
}: GroupedRowProps<DataItem>) => {
  return (
    <StyledGroupRow>
      <Table.Cell
        colSpan={
          bodyRowUtils.calculateTotalColSpan({
            columns,
            itemSelection,
            itemActions,
          },
          )}
      >
        {group}
      </Table.Cell>
    </StyledGroupRow>
  );
};

export default GroupedRow;