import { User } from '@api-client';
import { Chip, Stack, Tooltip } from '@mui/material';
import { tableTypes, UIUserAvatar } from '@shared/components';
import { allRoutesEnum, globalEnums, globalModels, globalUtils } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';

const getIconWrapper = (
  Children: React.ReactNode,
): React.ReactNode => {

  // eslint-disable-next-line react/display-name
  return (
    <Stack>
      {Children}
    </Stack>
  );
};

export const targetsColumns: tableTypes.ColumnProps<globalModels.TargetViewModel>[] = [
  {
    title: 'Target',
    columnKey: 'name',
    minWidth: '19rem',
    cellInfo: {
      cellTextWeight: 600,
      getIcon(item) {
        return getIconWrapper(globalUtils.getTargetTypeIcon(
          item.type.toString()?.toUpperCase() as globalEnums.ETargetType,
        ));
      },
      description(item) {
        return item.location || '';
      },
      href(item) {
        return allRoutesEnum.TargetDetails.replace(':targetId', item.id);
      }
    }
  },
  {
    title: 'Project',
    columnKey: 'projectName',
    minWidth: '18.65625rem',
    cellInfo: {
      cellTextColor: muiPalette.text?.secondary,
    },
  },
  {
    title: 'Accessibility',
    columnKey: 'internetAccessible',
    width: '18.65625rem',
    customCellRenderer(value) {
      const color = value === false ? 'primary' : 'secondary';
      const label = value === false ? 'Private' : 'Public';
      return (
        <Chip color={color} label={label} />
      );
    },
  },
  {
    title: 'Created By',
    columnKey: 'createdBy',
    minWidth: '11.5625rem',
    customCellRenderer(createdBy) {
      const user = createdBy as Partial<User> | undefined;
      return (
        <Tooltip title={`${user?.first_name} ${user?.last_name}`} placement='left'>
          <span>
            <UIUserAvatar src={user?.avatar_url} fullName={`${user?.first_name} ${user?.last_name}`} />
          </span>
        </Tooltip>
      );
    },
  },
];