import { Issue, ResolutionEnum } from '@api-client';
import useItemSelection from '@shared/hooks/use-item-selection';
import { first } from 'lodash';
import { IssueResolutionEnum } from 'models/Enums';
import React from 'react';
import { Table, TableProps } from 'views/components/v1/Table';
import { useDebounce } from '@shared/hooks';
import { ContentContainer } from '../../issue-details.styled';
import { IssueOccurrencesTable, TableActionsMenu } from './components';
import { vulnPathsTableUtils, vulnPathsTableConsts } from './duck';
import { NoContentPlaceholder, SelectOld, UIBadge, MuiSearchInput } from '@shared/components';
import { globalEnums, globalQueries, globalUtils } from '@shared/duck';
import { AttentionGray } from '@assets';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext, ErrorContextTypes } from '@shared/duck/contexts';
import { useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, Box, CircularProgress, Stack } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

interface IssuesOccurrencesTableProps {
  scanId?: string;
  kindId: number;
  currentIssue?: Issue;
  currentIssueId?: string;
  setCurrentIssueId: (issueId?: string) => void;
  setNoContent: (value: boolean) => void;
  projectId?: string;
  unselectCurrentIssueOnResolutionFilterChange?: boolean;
}

const DetailsVulPathsTable: React.FC<IssuesOccurrencesTableProps> = ({
  kindId,
  scanId,
  currentIssue,
  currentIssueId,
  setCurrentIssueId,
  setNoContent,
  projectId,
  unselectCurrentIssueOnResolutionFilterChange,
}) => {
  const { setError } = React.useContext(ErrorContext);
  const { debouncedValue: debouncedSearch, onChange: onSearchChange } = useDebounce<string>();
  const [firstRequest, setFirstRequest] = React.useState(true);
  const [firstScrollToIssue, setFirstScrollToIssue] = React.useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [resolution, setFilterResolution] = React.useState<ResolutionEnum[]>();

  const {
    vulnerablePaths = [],
    fetchVulnerablePathsListNextPage,
    isVulnerablePathsLoading,
    vulnerablePathsListHasNextPage,

  } = globalQueries.useGetIssuesVulnerablePathsList({
    kind: [kindId],
    scan: scanId,
    filter: debouncedSearch,
    project: projectId ? [projectId] : undefined,
    resolution: resolution,
  }, {
    onError: err => errorResponseHandler(err, 'scan', setError),
    onSettled(data) {
      firstRequest && !data?.pages[0].data.count && setError?.({
        type: ErrorContextTypes.ErrorType.NOT_FOUND,
        objectName: 'issue'
      });
      firstRequest && setFirstRequest(false);
    },
  });

  const noContent = !isVulnerablePathsLoading && !vulnerablePaths.length;

  React.useEffect(() => {
    setNoContent(noContent);
  }, [noContent]);

  React.useEffect(() => {
    // go back when last issue deleted
    if (noContent && !firstRequest && !debouncedSearch?.length && !resolution?.length) {
      navigate(globalUtils.extractBackLink(pathname), { replace: true });
    }
  }, [vulnerablePaths, firstRequest]);

  const vulnerablePathsViewModel = vulnerablePaths.map(vulnPathsTableUtils.mapDtoToViewModel);

  const parameterSelectionApi = useItemSelection<Issue>({
    data: [],
    keyBy: item => item.id,
    labelBy: item => item.id,
  });

  React.useEffect(() => {
    if (currentIssue) {
      const isPathAvailable = vulnerablePathsViewModel.find(path => vulnPathsTableUtils.getVulnerablePathId(currentIssue) === path.id);
      if (!isPathAvailable && vulnerablePathsListHasNextPage) {
        fetchVulnerablePathsListNextPage();
      }
    }
  }, [fetchVulnerablePathsListNextPage, vulnerablePathsListHasNextPage, currentIssue, vulnerablePathsViewModel]);

  const tableProps: TableProps<vulnPathsTableConsts.IVulnerablePaths> = {
    data: vulnerablePathsViewModel,
    columns: [
      {
        title: 'Path',
        columnKey: 'urlPath',
        renderer(value) {
          try {
            const decodedPath = decodeURIComponent(value as string);
            return decodedPath;
          }
          catch {
            // skip URIError in case of malformed text
            return value;
          }
        },
      },
      {
        title: 'Method',
        columnKey: 'httpMethod',
        renderer(value) {
          {
            const variant = vulnPathsTableUtils.getVariantFromMethod(value as string);
            return (
              <UIBadge
                variant={variant}
                text={value as string}
                sx={{
                  textTransform: 'uppercase',
                }}
              />
            );
          }
        },
      },
    ],
    loading: isVulnerablePathsLoading,
    isLoadMoreAvailable: vulnerablePathsListHasNextPage,
    onLoadMoreClick: fetchVulnerablePathsListNextPage,
    rowExpandRenderer(item: vulnPathsTableConsts.IVulnerablePaths) {
      return (
        <IssueOccurrencesTable
          kindId={kindId}
          scanId={scanId}
          resolution={resolution}
          currentIssue={currentIssue}
          setCurrentIssueId={setCurrentIssueId}
          parameterSelectionApi={parameterSelectionApi}
          firstScrollToIssue={firstScrollToIssue}
          setFirstScrollToIssue={setFirstScrollToIssue}
          {...item}
        />
      );
    },
    expandOnRowClick: true,
    className: 'vulnerable-paths-table',
    afterDelete: !currentIssue,
    withSelectAllCheckbox: false,
  };

  const expandedRowId = currentIssueId ? (
    currentIssue ? vulnPathsTableUtils.getVulnerablePathId(currentIssue) : undefined
  ) : first(vulnerablePathsViewModel)?.id;

  return (
    <Stack gap='1rem'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
        open={firstScrollToIssue}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack gap='0' width='100%'>
        <Stack
          direction='row' justifyContent='space-between'
          sx={{
            borderBottom: `1px solid ${muiPalette.grey?.[300]}`,
            p: '0.75rem',
          }}
        >
          <Stack direction='row' gap='0.25rem' alignItems='center'>
            {/* <Stack alignItems='center' className='checkbox-cell'>
           <IconButton
           size={'small'}
           variant={globalEnums.EButtonVariants.TRANSPARENT}
           icon={<DeleteOutlineIconC />}
           aria-label='Delete Action'
           />
           </Stack> */}
            <TableActionsMenu itemSelectionApi={parameterSelectionApi} />
          </Stack>

          <Stack direction='row' alignItems='center'>
            <MuiSearchInput
              width='12.8125rem'
              onChange={(e) => onSearchChange(e.target.value)}
            />
            <Box width='10.4375rem'>
              <SelectOld
                name='issue-resolution'
                options={[
                  { label: 'Open', value: IssueResolutionEnum.open },
                  { label: 'False Positive', value: IssueResolutionEnum.falsePositive },
                  { label: 'Resolved', value: IssueResolutionEnum.resolved },
                ]}
                placeholder='All'
                withEmptyItem
                withSearch={false}
                onChange={e => {
                  const value = e.target.value as ResolutionEnum | undefined | '';
                  if (value !== undefined && value !== null && value !== '') {
                    setFilterResolution([value]);
                    if (unselectCurrentIssueOnResolutionFilterChange) {
                      if (currentIssue?.resolution !== value) {
                        setCurrentIssueId(undefined);
                      }
                    }

                    parameterSelectionApi.selectedItems.forEach((item) => {
                      if (item.resolution !== value) {
                        parameterSelectionApi.onToggleItem(item);
                      }
                    });
                  } else {
                    setFilterResolution(undefined);
                  }
                }}
              />
            </Box>
          </Stack>
        </Stack>
        <ContentContainer hasTarget={!scanId ? globalEnums.EmotionBoolean.True : globalEnums.EmotionBoolean.False}>
          {noContent ? (
            <NoContentPlaceholder message='No URL Paths found' iconSrc={AttentionGray} />
          )
            : (
              <Table<vulnPathsTableConsts.IVulnerablePaths> {...tableProps} expandedRowId={expandedRowId} style={{ maxHeight: '100%' }} />
            )}
        </ContentContainer>
      </Stack>
    </Stack>
  );
};

export default DetailsVulPathsTable;
