import { IconProps } from '../BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from '../icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='16'
    height='18'
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <path
      d='M8.75 1.5L1.25 10.5H8L7.25 16.5L14.75 7.5H8L8.75 1.5Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

function NavAutomationsIcon(props: IconProps): JSX.Element {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default NavAutomationsIcon;
