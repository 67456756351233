import React from 'react';
import { TextLink } from '@contentful/f36-components';
import { globalEnums } from '@shared/duck';
import { CustomIconLink } from './custom-icon-link';
import { ExternalLink } from './external-link';

interface UILinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  type?: globalEnums.ELinksType;
  icon?: React.ReactElement;
  text: string;
}

const UILink: React.FC<UILinkProps> = ({
  href,
  type = globalEnums.ELinksType.TEXT,
  text,
  icon,
  ...props
}) => {
  return (
    <>
      {(
        () => {
          switch (type) {
            case globalEnums.ELinksType.TEXT:
              return (
                <TextLink
                  href={href}
                  target={props.target || '_blank'}
                  rel='noopener noreferrer'
                  onClick={(e: any) => e.stopPropagation()}
                  {...props}
                >
                  {text}
                </TextLink>
              );
            case globalEnums.ELinksType.EXT_ICON:
              return (
                <ExternalLink href={href} text={text} />
              );
            case globalEnums.ELinksType.CUSTOM_ICON:
              return (
                icon && <CustomIconLink href={href} text={text} icon={icon} />
              );
          }
        }
      )()}
    </>
  );
};

export default UILink;