import { globalUtils } from '@shared/duck';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { allSubroutesEnum } from 'pages/all-subroutes-enum';
import ProjectsPage from './projects-page';
import { ProjectDetailsRoutes } from './components';

const ProjectsRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={allSubroutesEnum.Root} element={<ProjectsPage />} />
      <Route path={globalUtils.withSubRoutes(allSubroutesEnum.ProjectDetails)} element={<ProjectDetailsRoutes />} />
    </Routes>
  );
};

export default ProjectsRouter;