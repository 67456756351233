import { AlertClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const AlertStyles: Partial<OverridesStyleRules<keyof AlertClasses, 'MuiAlert', Omit<Theme, 'components'>>> | undefined = {
  root: {
    borderRadius: '0.375rem',
    border: '1px solid',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem',
  },
  standardInfo: {
    borderColor: '#98CBFF', // palette.blue300
    backgroundColor: '#E8F5FF', // palette.blue100
  },
};