import { MuiIssueKindsSelect } from '@shared/components';
import { globalQueries } from '@shared/duck';
import { useFormikContext } from 'formik';
import React from 'react';
import { fpModalTypes } from '../../duck';

const IssueKindsSelect: React.FC = ({
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext<fpModalTypes.FpModalFormValues>();

  /** Note: we need stable defaultKindId to avoid bugs during "Edit" mode */

  const defaultKindId = React.useMemo(() => {
    return values.kindId;
  }, []);

  /** Note: should act only once and only in "Edit" mode */
  const { issueKind } = globalQueries.useGetIssueKind(
    { id: defaultKindId },
    { enabled: defaultKindId !== 0 },
  );

  const handleFormChange = (kindId?: string) => {
    setFieldValue('kindId', Number(kindId));
  };

  return (
    <MuiIssueKindsSelect
      {...props}
      label=''
      defaultIssueKind={issueKind}
      isDefaultIssueKindLoading={!!defaultKindId && !issueKind}
      onFormChange={(e) => handleFormChange(e.target.id)}
    />
  );
};

export default IssueKindsSelect;
