import { Button, Dialog, DialogActions, DialogContent, Grid, Stack } from '@mui/material';
import { FormikControlMui, UIDialogTitle } from '@shared/components';
import { useParams } from 'react-router-dom';
import { FpPath, IssueKindsSelect, MethodSelect, ParamName } from './components';
import { globalModels } from '@shared/duck';
import { Form, Formik } from 'formik';
import React from 'react';
import { fpModalTypes, fpModalUtils } from './duck';

interface FPEditModalProps {
  onClose: () => void;
  selectedFP: globalModels.FalsePositivesModel;
}

const FPEditModal: React.FC<FPEditModalProps> = ({
  onClose,
  selectedFP,
}) => {
  const { targetId = '' } = useParams();
  const [openModal, setOpenModal] = React.useState(true);

  const handleClose = () => {
    setOpenModal(false);
  };

  const { onSubmit } = fpModalUtils.onSubmitHandler(selectedFP?.target || targetId, selectedFP?.id);

  const onSubmitForm = async (values: fpModalTypes.FpModalFormValues) => {
    await onSubmit(values);
    handleClose();
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      onTransitionExited={onClose}
      fullWidth
      maxWidth='md'
    >
      <UIDialogTitle
        title={'Edit False Positive'}
        onCloseBtnAction={handleClose}
      />
      <Formik<fpModalTypes.FpModalFormValues>
        initialValues={fpModalUtils.getInitialValues(selectedFP)}
        onSubmit={onSubmitForm}
        validateOnChange
        validateOnBlur
        validationSchema={fpModalUtils.modalValidationSchema}
      >
        {({ handleSubmit, isSubmitting, dirty, isValid }) => {
          return (
            <>
              <DialogContent
                dividers
                sx={{ padding: '2rem 1.5rem' }}
              >
                <Form>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item sm={6} xs={12}>
                      <FormikControlMui name='kindId' label='Kind' required>
                        <IssueKindsSelect />
                      </FormikControlMui>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikControlMui name='path' label='Path'>
                        <FpPath />
                      </FormikControlMui>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikControlMui name='method' label='HTTP Method'>
                        <MethodSelect />
                      </FormikControlMui>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikControlMui name='paramName' label='Parameter Name'>
                        <ParamName />
                      </FormikControlMui>
                    </Grid>
                  </Grid>
                </Form>
              </DialogContent>

              <DialogActions>
                <Stack
                  width='100%'
                  flexDirection='row'
                >
                  <Button
                    variant='outlined'
                    color='error'
                    onClick={() => handleClose()}
                  >
                    {'Cancel'}
                  </Button>

                  <Button
                    variant='contained'
                    onClick={() => handleSubmit()}
                    sx={{ marginLeft: 'auto' }}
                    disabled={isSubmitting || !dirty || !isValid}
                  >
                    {'Save'}
                  </Button>
                </Stack>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default FPEditModal;