import { ModalProps } from '@contentful/f36-components';
import { StyledModal } from './right-side-modal.styled';

interface RightSideModalProps extends Omit<ModalProps, 'size' | 'position'> {
  isShown: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const RightSideModal: React.FC<RightSideModalProps> = ({
  isShown,
  onClose,
  children,
  ...otherProps
}: RightSideModalProps) => {
  return (
    <StyledModal onClose={onClose} isShown={isShown} size='fullscreen' {...otherProps}>
      {() => (
        children
      )}
    </StyledModal>
  );
};

export default RightSideModal;