import { AutocompleteProps, Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { MuiAutocompleteStyled } from './mui-autocomplete.styled';
import React from 'react';

interface MuiAutocompleteProps
  extends AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined> {
  label?: string;
  labelSx?: SxProps<Theme>;
  hidePopupIndicator?: boolean;
  required?: boolean;
  error?: boolean;
}

const MuiAutocomplete: React.FC<MuiAutocompleteProps> = ({
  label,
  labelSx,
  hidePopupIndicator = false,
  required = false,
  error = false,
  ...props
}) => {
  return (
    <Box
      flexDirection={'column'}
    >
      {label && (
        <Stack
          gap={0.25}
          sx={{ paddingBottom: '0.5rem', flexDirection: 'row' }}
        >
          <Typography
            variant={'subtitle2'}
            sx={{ ...labelSx }}
          >
            {label}
          </Typography>

          {required && (
            <Typography
              variant={'subtitle2'}
              color={`${error ? 'error' : muiPalette.grey?.['500']}`}
              sx={{ opacity: `${error ? '1' : '0.5'}` }}
            >
              {'(required)'}
            </Typography>
          )}
        </Stack>
      )}

      <MuiAutocompleteStyled
        hidePopupIndicator={hidePopupIndicator}
        {...props}
      />
    </Box>
  );
};

export default MuiAutocomplete;