import { CopyIconC } from '@assets';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { StyledSnippet } from './code-snippet.styled';
import { globalEnums, globalUtils } from '@shared/duck';

interface CodeSnippetProps {
  children: string;
  inline?: boolean;
  className?: string;
}

const UICodeSnippet: React.FC<CodeSnippetProps> = ({
  children, inline, className,
}: CodeSnippetProps) => {

  const onCopyClick = () => {
    globalUtils.copyTextToClipboard(children, 'Copied to clipboard!');
  };

  return (
    <StyledSnippet
      inline={inline ? globalEnums.EmotionBoolean.True : globalEnums.EmotionBoolean.False}
      className={className}
    >
      <Box mr='0.5rem' className={className}>{children}</Box>
      <IconButton
        aria-label='Copy'
        onClick={onCopyClick}
      >
        <CopyIconC />
      </IconButton>
    </StyledSnippet>
  );
};

export default UICodeSnippet;
