import { Issue } from '@api-client';
import { CopyIconC } from '@assets';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Badge, Spinner } from '@contentful/f36-components';
import { Sparkles } from 'views/utils/emoji';
import LocalStorageManager from 'views/utils/LocalStorageManager';
import {
  ExplainWithAIBox,
} from './explain-ai-wall.styled';
import { globalEnums, globalTypes, globalUtils } from '@shared/duck';
import { UILink } from '@shared/components';
import { muiPalette } from '@shared/general-mui-theme';
import Prism from 'prismjs';

const STORAGE_NAME = 'ExplainWithAI';
const storageInstance = new LocalStorageManager(STORAGE_NAME);
const GENERATION_ANIMATION_DURATION = 2000;

interface Props extends globalTypes.CommonProps {
  issue: Issue;
}

const ExplainWithAIWall: React.FC<Props> = ({ issue, ...props }) => {
  const [isExplanationShown, setIsExplanationShown] = React.useState(Boolean(storageInstance.get(issue.id)));
  const [isGenerationInProgress, setIsGenerationInProgress] = React.useState(false);

  const onGenerateClick = () => {
    setIsGenerationInProgress(true);
    setTimeout(() => {
      storageInstance.set(issue.id, 'true');
      setIsExplanationShown(true);
      setIsGenerationInProgress(false);
    }, GENERATION_ANIMATION_DURATION);
  };

  React.useEffect(() => {
    setIsExplanationShown(Boolean(storageInstance.get(issue.id)));
  }, [issue]);

  const onCopyClick = (content: string) => {
    globalUtils.copyTextToClipboard(content, 'Copied to clipboard!');
  };

  // This useEffect is needed because Prism.js applies syntax highlighting after the DOM is rendered.
  // Without it, <code> blocks (and maybe some others) won't be highlighted on the first render.
  // This ensures that Prism.js runs after the Markdown content is fully loaded.
  React.useEffect(() => {
    if (isExplanationShown && !isGenerationInProgress)
      Prism.highlightAll();
  }, [isExplanationShown, isGenerationInProgress]);

  return (
    <Box mb='1.5rem'>
      <Stack direction='row' gap='0.75rem' alignItems='center' mb='0.75rem'>
        <Typography variant='subtitle1' fontWeight='600'>Explain with AI {Sparkles}</Typography>
        <Badge variant={globalEnums.EBadgeVariants.FEATURED}>Beta</Badge>
      </Stack>
      <ExplainWithAIBox {...props}>
        {isGenerationInProgress ? (
          <Stack direction='row' gap='1rem' alignItems='center' justifyContent='space-between'>
            <Typography variant='default' color={muiPalette.grey?.[500]}>Generating...</Typography>
            <Spinner />
          </Stack>
        ) : (
          <>
            {isExplanationShown ? (
              <>
                <ReactMarkdown>{issue.ai_explanation || ''}</ReactMarkdown>
                <Tooltip
                  arrow
                  title={'Copy to clipboard'}
                  placement='top'
                  sx={{
                    maxWidth: '10rem',
                  }}
                >
                  <IconButton
                    className='copy'
                    aria-label='Copy'
                    color='outlined'
                    sx={{
                      width: '2rem',
                      height: '2rem',
                    }}
                    onClick={() => issue.ai_explanation && onCopyClick(issue.ai_explanation)}
                  >
                    <CopyIconC
                      variant={globalEnums.CopyIconVariantsEnum.BLACK}
                      sx={{ cursor: 'pointer' }}
                      style={{ cursor: 'pointer' }}
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <UILink onClick={onGenerateClick} text='Generate' />
                {' '}
                <Typography variant='default' color={muiPalette.grey?.[500]}>explanation with AI</Typography>
              </>
            )}
          </>
        )}
      </ExplainWithAIBox>
    </Box>
  );
};

export default ExplainWithAIWall;
