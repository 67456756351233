import { tableTypes } from '@shared/components';
import ToLinkOrNotToLink from '../link-or-not';
import { StyledMuiTableCell } from '../../table-cell.styled';
import React from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { globalUtils } from '@shared/duck';

interface CustomCellProp<DataItem> {
  dataItem: DataItem;
  column: tableTypes.ColumnProps<DataItem>;
  index: number;
  cellValue: any;
  description?: string;
  Icon?: React.ReactNode;
  withoutPadding?: boolean;
}

type alignProps = 'center' | 'left' | 'right';

const MuiCustomCell = <DataItem,>({
  dataItem,
  column,
  index,
  cellValue,
  description,
  Icon,
  withoutPadding = false,
}: CustomCellProp<DataItem>) => {
  return (
    <StyledMuiTableCell
      width={column.width}
      withoutPadding={withoutPadding}
      minWidth={column.minWidth}
      align={column.cellInfo?.alignItems as alignProps || 'left'}
    >
      <ToLinkOrNotToLink href={column.cellInfo?.href?.(dataItem) || ''}>
        <Tooltip
          arrow
          title={column.cellInfo?.cellTooltipText || ''}
          placement={column.cellInfo?.cellTooltipPlace || 'bottom'}
        >
          <Stack
            alignItems='center'
            justifyContent={column.cellInfo?.alignItems || 'left'}
            flexDirection='row'
          >
            {Icon}

            <Stack gap='0.13rem' width={column.cellInfo?.fullWidth ? '100%' : 'inherit'}>
              {column.customCellRenderer && column.customCellRenderer(cellValue, dataItem, index)}

              {description && (
                <Typography variant='default' noWrap sx={{ opacity: 0.5 }}>
                  {globalUtils.sliceTextByLimit(description, true)}
                </Typography>
              )}
            </Stack>

          </Stack>
        </Tooltip>
      </ToLinkOrNotToLink>
    </StyledMuiTableCell>
  );
};

export default MuiCustomCell;