import { Chip, Stack, TextFieldProps } from '@mui/material';
import { MuiTextInput } from '@shared/components/text-input';
import { globalEnums } from '@shared/duck';
import React from 'react';

type InputWithChipProps = {
  onAfterChange: (newValues: string[]) => void;
  tooltip: string;
  placeholder: string;
  label: string;
} & TextFieldProps;

export const InputWithChip: React.FC<InputWithChipProps> = ({
  onAfterChange,
  placeholder,
  label,
  value: val,
}: InputWithChipProps) => {
  const [state, setState] = React.useState<string>('');
  const value = val as string[];

  const updateLabels = () => {
    if (state.length > 0) {
      onAfterChange([...value, state]);
      setState('');
    }
  };

  const onKeyDown = React.useCallback((e: any) => {
    if (e.key === globalEnums.EKeyboardKeys.ENTER) {
      updateLabels();
    }
  }, [state]);

  React.useEffect(() => {
    () => {
      updateLabels();
    };
  }, []);

  const onDelete = (indexToDelete: number) => {
    const newValue = value.filter((_, index) => index !== indexToDelete);
    onAfterChange(newValue);
  };

  return (
    <Stack>
      <MuiTextInput
        value={state}
        label={label}
        placeholder={placeholder}
        onChange={(e) => setState(e.target.value)}
        onBlur={updateLabels}
        onKeyDown={onKeyDown}
      />
      <Stack>
        {value?.map((label, index) => {
          return (
            <Chip
              key={`${label}-${index}`}
              label={label}
              onDelete={() => onDelete(index)}
              sx={{
                width: 'fit-content',
              }}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
