import { SeverityEnum } from '@api-client';
import { UIBadge } from '@shared/components';
import { capitalize } from 'lodash';
import { severityBadgeUtils } from './duck';

interface SeverityBadgeProps {
  title: string;
}

const SeverityBadge = ({
  title,
}: SeverityBadgeProps) => {
  return (
    <UIBadge
      variant={severityBadgeUtils.getVariantFromSeverity(title as SeverityEnum)}
      text={capitalize(title)}
      sx={{
        fontWeight: 600
      }}
    />
  );
};

export default SeverityBadge;
