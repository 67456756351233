import styled from '@emotion/styled';
import { Tabs } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const BorderedTabs = styled(Tabs)`
  overflow: auto;
  border-bottom: 1px solid ${muiPalette.grey?.[200]};

  button {
    text-transform: none;
  }
`;

export { BorderedTabs };
