import { pick } from 'lodash';
import { IconProps } from './BaseIcon';
import { IconSize } from './icons.interfaces';
import { IconVariant } from '@contentful/f36-components';

const IconSizeStyles = new Map<IconSize, { width: number; height: number }>();

IconSizeStyles.set('tiny', { width: 16, height: 16 });
IconSizeStyles.set('small', { width: 18, height: 18 });
IconSizeStyles.set('medium', { width: 24, height: 24 });
IconSizeStyles.set('large', { width: 32, height: 32 });
IconSizeStyles.set('xlarge', { width: 48, height: 48 });

const pickCustomSvgProps = (props: IconProps) => pick<IconProps>(props, ['width', 'height']);

const IconColorStyles = new Map<IconVariant, string>();

IconColorStyles.set('primary', '#0059C8');
IconColorStyles.set('positive', '#006D23');
IconColorStyles.set('negative', '#BD002A');
IconColorStyles.set('warning', '#F07F23');
IconColorStyles.set('secondary', '#111B2B');


export { IconSizeStyles, pickCustomSvgProps, IconColorStyles };
