import { OpenApiTarget } from '@api-client';
import { CircularProgress, TextField } from '@mui/material';
import { MuiAutocomplete } from '@shared/components';
import { globalQueries, globalTypes } from '@shared/duck';
import { useDebounce } from '@shared/hooks';
import React from 'react';
import { selectorsUtils } from '../duck';
import { TargetCard } from './components';

interface MuiMuiOpenApiTargetSelectProps extends globalTypes.MUISelectProps<OpenApiTarget> {
  label?: string;
  defaultTarget?: OpenApiTarget;
  projectId?: string;
  setSelectedTarget?: React.Dispatch<React.SetStateAction<OpenApiTarget | undefined>>;
  setNewTargetName?: (value: string, e?: any) => void;
  defaultNewTargetName?: string;
  isFreeSolo?: boolean;
  onAfterTestName?: ({ exists }: { exists?: boolean }) => void;
  forceCheck?: boolean;
}

const MuiOpenApiTargetSelect: React.FC<MuiMuiOpenApiTargetSelectProps> = ({
  label = 'Target Name',
  defaultTarget,
  projectId,
  onFormChange,
  setNewTargetName,
  defaultNewTargetName,
  isFreeSolo,
  onAfterTestName,
  forceCheck,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState(defaultNewTargetName || '');
  const [value, setValue] = React.useState<OpenApiTarget | null>(defaultTarget ?? null);
  const { debouncedValue, onChange: onDebounceChange } = useDebounce<string>(defaultNewTargetName || '');
  const [changes] = React.useState({ count: 0 });
  const [enableCheck, setEnableCheck] = React.useState<boolean>(false);
  const [lastForceValue, setLastForceValue] = React.useState(forceCheck);
  const [isNoOptions, setNoOptions] = React.useState(false);

  const { getTarget, isLoading } = globalQueries.useGetTargetByName();

  React.useEffect(() => {
    setValue(defaultTarget ?? null);
    setInputValue(defaultNewTargetName || '');
  }, [defaultTarget]);

  const {
    openApiTargets = [],
    fetchNextOpenApiTargetsPage,
    openApiTargetsHasNextPage,
    isOpenApiTargetsListLoading,
  } = globalQueries.useGetOpenApiTargetsList({
    project: projectId ? [projectId] : undefined,
    order: ['name'],
  });

  const isTargetsLoading = isOpenApiTargetsListLoading || isLoading;

  React.useEffect(() => {
    if (openApiTargetsHasNextPage && !isOpenApiTargetsListLoading) {
      fetchNextOpenApiTargetsPage();
    }
  }, [openApiTargetsHasNextPage, fetchNextOpenApiTargetsPage, isOpenApiTargetsListLoading]);

  const handleInputChange = (value: string) => {
    if (value !== inputValue) {
      changes.count++;
      setInputValue(value);
      onDebounceChange(value);
      setEnableCheck(true);
      value && onAfterTestName?.({ exists: undefined });
    }
  };

  const testName = async (force?: boolean) => {
    setNewTargetName?.(inputValue);
    if (debouncedValue && isFreeSolo && (
      enableCheck || force
    )) {
      const currCounter = changes.count;
      try {
        onAfterTestName?.({ exists: undefined });
        if (!forceCheck && value && debouncedValue === value?.name) {
          throw new Error();
        }

        await getTarget({ id: projectId || '', targetName: debouncedValue });
        if (changes.count === currCounter) {
          onAfterTestName?.({ exists: true });
        }
      } catch {
        if (changes.count === currCounter) {
          onAfterTestName?.({ exists: false });
        }
      }
    }
  };

  React.useEffect(() => {
    testName();
  }, [debouncedValue, enableCheck]);

  React.useEffect(() => {
    if (lastForceValue !== forceCheck) {
      onAfterTestName?.({ exists: undefined });
      testName(true);
    }
    else testName(false);
    setLastForceValue(forceCheck);
  }, [forceCheck]);

  const handleOnChange = (e: any, newValue: OpenApiTarget | string) => {
    if (typeof newValue === 'string') {
      setNewTargetName?.(newValue, e);
      onAfterTestName?.({ exists: undefined });
      setEnableCheck(true);
    } else {
      onAfterTestName?.({ exists: false });
      selectorsUtils.handleOnFormChange(newValue, onFormChange);
      setValue(newValue);
      setEnableCheck(forceCheck || false);
    }
    changes.count++;
  };

  return (
    <MuiAutocomplete
      id='openapi-target-autocomplete'
      required={props.required}
      error={props.error}
      label={label}
      value={value}
      loading={isOpenApiTargetsListLoading}
      loadingText='Loading...'
      freeSolo={isFreeSolo}
      autoComplete
      autoHighlight
      filterSelectedOptions
      hidePopupIndicator
      options={openApiTargets}
      noOptionsText='Target not found'
      slotProps={{
        paper: {
          elevation: isNoOptions && isFreeSolo && !isTargetsLoading ? 0 : 12,
        }
      }}
      onChange={(e: any, newValue: OpenApiTarget | string) => {
        handleOnChange(e, newValue);
        // props.onChange?.(e);
      }}
      getOptionLabel={(option: OpenApiTarget | string) => {
        if (typeof option === 'string') {
          return option;
        }
        return defaultNewTargetName || option.name || '';
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      onBlur={(e: any) => {
        setNewTargetName?.(inputValue, e);
      }}
      onInputChange={(_, value) => handleInputChange(value)}
      filterOptions={(options) => {
        const result = options.filter((option) =>
          option.name
            .toLowerCase()
            .trim()
            .includes(inputValue.toLowerCase().trim()));

        !result.length ? setNoOptions(true) : setNoOptions(false);
        return result;
      }}
      renderOption={(props, option) => (
        <TargetCard target={option as OpenApiTarget} {...props} key={option.id} />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          onBlur={props.onBlur}
          name={props.name}
          error={props.error}
          inputProps={{ ...params.inputProps, value: !value ? inputValue || '' : params.inputProps.value || '' }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isTargetsLoading && <CircularProgress color='inherit' size={16} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          placeholder='Enter Target name...'
        />
      )}
      getOptionKey={(option: OpenApiTarget) => option.id}
    />
  );
};

export default MuiOpenApiTargetSelect;