import { Stack } from '@mui/material';
import { MuiTable, tableTypes } from '@shared/components';
import { FPEditModal } from './components';
import { FalsePositivesModel } from '@shared/duck/models';
import useItemSelection from '@shared/hooks/use-item-selection';
import React from 'react';
import { fpConsts, fpUtils } from './duck';
import { globalModels } from '@shared/duck';

interface FalsePositivesProps {
  targetId: string;
}

const FalsePositivesTab: React.FC<FalsePositivesProps> = ({
  targetId,
}) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedFP, setSelectedFP] = React.useState<globalModels.FalsePositivesModel>();

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [targetId]);

  const {
    falsePositives,
    totalItemsCount,
    isFpListLoading,
    onDeleteFalsePositives,
  } = fpUtils.useFpTableApi(
    {
      page: currentPage,
      targetId: targetId,
      pageSize: rowsPerPage,
    },
  );

  const itemSelectionApi = useItemSelection<globalModels.FalsePositivesModel>({
    data: [],
    keyBy: item => item.id,
    labelBy: item => item.kindName,
  });

  React.useEffect(() => {
    itemSelectionApi.reinitializeData(falsePositives);

    /** Note: use this to control pagination */
    const selectedItems = new Map(itemSelectionApi.selectedItems);
    itemSelectionApi.selectedItems.forEach((item) => {
      if (selectedItems.has(item.id) && !falsePositives.find((fp) => fp.id === item.id)) {
        selectedItems.delete(item.id);
      }
    });
    itemSelectionApi.reinitialize(selectedItems);
  }, [JSON.stringify(falsePositives)]);

  const tableConfig: tableTypes.MuiTableProps<FalsePositivesModel> = React.useMemo(() => {
    return {
      data: falsePositives,
      columns: fpConsts.fpColumns,
      loading: isFpListLoading,
      noContentMessage: 'No False Positives found',
      headerItemActions: (
        {
          itemList: [
            {
              title: 'Delete Selected',
              itemAction: () => {
                const fpIds: string[] = [];
                itemSelectionApi.selectedItems.forEach((item) => fpIds.push(item.id));
                onDeleteFalsePositives(targetId, fpIds, true);
              },
              isRedText: true,
              disabled: itemSelectionApi.selectedItems.size === 0,
            },
          ],
        }
      ),
      itemActions: item => (
        {
          itemList: [
            {
              title: 'Edit',
              itemAction: () => {
                setOpenModal(true);
                setSelectedFP(item);
              },
            },
            {
              title: 'Delete',
              itemAction: () => {
                onDeleteFalsePositives(item.target, [item.id]);
              },
              isRedText: true,
            },
          ],
        }
      ),
      itemSelection: {
        itemSelectionApi,
        disabled: !falsePositives.length,
      },
      pagination: {
        count: totalItemsCount,
        page: currentPage,
        rowsPerPage: rowsPerPage,
        onPageChange: (e, newPage) => handleChangePage(newPage),
        onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
      },
    };
  }, [
    falsePositives,
    isFpListLoading,
    itemSelectionApi,
    totalItemsCount,
    currentPage,
    rowsPerPage,
  ]);

  return (
    <>
      <Stack
        marginTop='1.5rem'
        width='100%'
      >
        <MuiTable
          {...tableConfig}
        />
      </Stack>

      {openModal && !!selectedFP && (
        <FPEditModal
          onClose={() => setOpenModal(false)}
          selectedFP={selectedFP}
        />
      )}
    </>
  );
};

export default FalsePositivesTab;