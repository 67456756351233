import { css } from '@emotion/react';
import { Select, SelectProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withSafeProps } from 'views/utils/common';

const popupIndicatorStyles = ({ hidePopupIndicator }: { hidePopupIndicator?: boolean }) =>
  hidePopupIndicator
    ? css`
              .MuiSelect-icon {
                  display: none;
              }
    ` : css``;

export const MuiSelectStyled = styled(
  withSafeProps<{
    hidePopupIndicator?: boolean
  } & SelectProps>(
    Select,
    ['hidePopupIndicator'],
  ),
)`
    ${popupIndicatorStyles}
`;