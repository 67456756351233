import { getHttpMethodColor } from './duck';
import { Chip, ChipProps } from '@mui/material';

interface HttpMethodChipProps extends Omit<ChipProps, 'variant' | 'children' | 'color'> {
  method: string;
}

const HttpMethodChip = ({ method, ...props }: HttpMethodChipProps) => {
  return (
    <Chip
      label={method?.toUpperCase()}
      color={getHttpMethodColor(method)}
      sx={{
        'span': {
          fontSize: '0.75rem',
          fontWeight: 500,
        }
      }}
      {...props}
    />
  );
};

export default HttpMethodChip;
