import React from 'react';
import { globalEnums, globalModels } from '@shared/duck';
import { CreateOrEditTargetsModalProps } from '../..';
import { Project } from '@api-client';
import { ApiTargetsContentWrapper, WebTargetsContent } from './components';

export interface TargetModalContentProps extends CreateOrEditTargetsModalProps {
  isDefaultProjectLoading: boolean;
  defaultProject?: Project;
  relatedApplication?: globalModels.ApplicationsViewModel;
  isRelatedApplicationLoading: boolean;
  closeModal: () => void;
}

export const TargetModalContent: React.FC<TargetModalContentProps> = ({
  target,
  enableProjectSelect,
  isDefaultProjectLoading,
  defaultProject,
  relatedApplication,
  isRelatedApplicationLoading,
  closeModal,
}) => {
  const [project, setProject] = React.useState<string | undefined>();

  React.useEffect(() => {
    setProject(defaultProject?.id || undefined);
  }, [defaultProject]);

  const [targetTypeTab, setTargetTypeTab] = React.useState(target?.type || globalEnums.ETargetType.URL);
  const handleChange = (_: any, newTab: globalEnums.ETargetType) => {
    newTab && setTargetTypeTab(newTab);
  };

  return (
    targetTypeTab === globalEnums.ETargetType.URL ? (
      <WebTargetsContent
        closeModal={closeModal}
        onAfterCreate={closeModal}
        onAfterUpdate={closeModal}
        project={project}
        onProjectChange={(newProject?: string) => setProject(newProject)}
        enableProjectSelect={enableProjectSelect}
        isDefaultProjectLoading={isDefaultProjectLoading}
        target={target}
        currentTab={targetTypeTab}
        onTabChange={handleChange}
        relatedApplication={relatedApplication}
        isRelatedApplicationLoading={isRelatedApplicationLoading}
      />
    ) : (
      <ApiTargetsContentWrapper
        closeModal={closeModal}
        onAfterCreate={closeModal}
        onAfterUpdate={closeModal}
        project={project}
        onProjectChange={(newProject?: string) => setProject(newProject)}
        enableProjectSelect={enableProjectSelect}
        isDefaultProjectLoading={isDefaultProjectLoading}
        target={target}
        currentTab={targetTypeTab}
        onTabChange={handleChange}
        relatedApplication={relatedApplication}
        isRelatedApplicationLoading={isRelatedApplicationLoading}
      />
    )
  );
};