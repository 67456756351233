import React from 'react';
import { ErrorContext, MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { NoContentPlaceholder } from '@shared/components';
import { AttentionGray, CopyGray } from '@assets';
import { ScanStatusEnum } from 'models/Enums';
import { ScanLogsTable } from './components';
import { SCAN_REFETCH_INTERVAL } from '../duck';
import { globalConstants, globalQueries } from '@shared/duck';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { useApp } from '@shared/hooks';
import { Stack } from '@mui/material';

// const LOGS_EXPIRATION = { value: 14, unit: 'days' as dayjs.ManipulateType };
const LOGS_REFETCH_INTERVAL = 30 * 1000; // 30 sec

interface ScanDetailsLogsProps {
  scanId: string;
}

const ScanDetailsLogs: React.FC<ScanDetailsLogsProps> = ({
  scanId,
}) => {
  const { setError } = React.useContext(ErrorContext);
  const [scanEnded, setScanEnded] = React.useState<boolean>(false);
  const [scanFailed, setScanFailed] = React.useState<boolean>(false);
  const [scanWasInProgress, setScanWasInProgress] = React.useState<boolean>(false);

  const app = useApp();
  const isScanLimitReached = app.checkIfScanLimitReached();

  const { scan, isScanLoading } = globalQueries.useGetScan({
    id: scanId
  }, {
    refetchInterval: scanEnded ? undefined : SCAN_REFETCH_INTERVAL,
    onError: err => errorResponseHandler(err, 'scan', setError),
  });

  React.useEffect(() => {
    if (scan) {
      const scanInProgress = scan.status == ScanStatusEnum.IN_PROGRESS;
      setScanEnded(!scanInProgress);
      setScanFailed(!scanInProgress && scan.status !== ScanStatusEnum.SUCCEEDED);
      if (!scanWasInProgress && scanInProgress) {
        setScanWasInProgress(true);
      }
    }
  }, [scan, scan?.status]);

  // const expiryDate = dayjs(scan?.started_at).startOf('day').add(LOGS_EXPIRATION.value, LOGS_EXPIRATION.unit);
  // const expiryDateDisplay = isScanLoading ? '...' : expiryDate.format(globalConstants.FULL_MONTH_DATE);
  // const isLogsExpired = !expiryDate.isValid() || expiryDate.isBefore(Date.now());
  const isLogsExpired = false;

  const {
    logs = [],
    isLogsLoading,
    refetchLogs,
  } = globalQueries.useGetScanLogsList({
    id: scanId
  }, {
    enabled: !isScanLoading && !isLogsExpired,
    refetchInterval: scanEnded ? undefined : LOGS_REFETCH_INTERVAL,
    onError: err => errorResponseHandler(err, 'scan', setError),
  });

  // Refetch all logs on Scan end
  React.useEffect(() => {
    if (scanEnded && scanWasInProgress) {
      refetchLogs();
    }
  }, [scanEnded, scanWasInProgress]);

  const { showModal } = useGlobalModalContext();

  const tooltipText = scan?.credentials?.is_deleted
    ? globalConstants.RESCAN_IS_NOT_AVAILABLE_AUTH
    : !scan?.application_id
      ? globalConstants.RESCAN_IS_NOT_AVAILABLE_APP
      : undefined;

  return (
    <>
      <Stack alignItems='stretch' p='0.5rem' overflow='hidden'>
        {isLogsExpired ? (
          // <Note variant={globalEnums.ENoteVariants.WARNING}>
          //   These scan logs expired on <Text fontWeight='fontWeightMedium'>{expiryDateDisplay}</Text>. Please run a new
          //   scan to see a valid scan log
          // </Note>
          <></>
        ) : scanEnded ? (
          // <Note variant={globalEnums.ENoteVariants.PRIMARY}>
          //   {globalConstants.SCAN_LOGS_EXPIRATION_TEXT + ' '}
          //   <Text fontWeight='fontWeightMedium'>{expiryDateDisplay}</Text>
          // </Note>
          <></>
        ) : (
          <></>
        )}
        {isScanLoading ? (
          <NoContentPlaceholder height='18.75rem' message='Loading...' iconSrc={AttentionGray} />
        ) : isLogsExpired ? (
          <NoContentPlaceholder
            height='18.75rem'
            iconSrc={CopyGray}
            message={'Logs expired.'}
            link={isScanLimitReached ? '' : 'Re-Run another scan'}
            onLinkClick={() => !isScanLimitReached ? showModal({ modalType: MODAL_TYPE.RescanPopupModal, modalProps: { scanId } }) : null}
            isLinkDisabled={Boolean(tooltipText)}
            linkTooltipText={tooltipText}
          />
        ) : (
          <ScanLogsTable scanId={scanId} scanFailed={scanFailed} logs={logs} isLoading={!scanEnded || isLogsLoading} />
        )}
      </Stack>
    </>
  );
};

export default ScanDetailsLogs;
