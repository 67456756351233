import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { plannerTypes } from '../../utils';
import { globalTypes } from '@shared/duck';

const PlannerPeriodSelect: React.FC = () => {
  const { setFieldValue } = useFormikContext<plannerTypes.PlannerFormValues>();

  const options: globalTypes.MuiSelectOptions[] = [
    {
      key: '1',
      label: 'Once a day',
    },
    {
      key: '2',
      label: 'Once a week',
    },
    {
      key: '3',
      label: 'Once a month',
    },
  ];

  const [value, setValue] = React.useState(options[0]);

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setValue(options.find((option) => option.key === value) || options[0]);
  };

  React.useEffect(() => {
    setFieldValue('period', value);
  }, [value]);

  return (
    <Select
      id='planner-period-select'
      value={value.key.toString()}
      onChange={handleChange}
    >
      {options.map((option) => (
        <MenuItem key={option.key} value={option.key}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PlannerPeriodSelect;