import { debounce } from 'lodash';
import React from 'react';

export const useDebounce = <ValueType>(initialValue?: ValueType, timeout?: number) => {
  const [value, setValue] = React.useState<ValueType | undefined>(initialValue);
  const [debouncedValue, setDebouncedValue] = React.useState<ValueType | undefined>(initialValue);

  const onDebouncedChange = React.useCallback(
    debounce((value?: ValueType) => setDebouncedValue(value), timeout || 500),
    [],
  );

  const onChange = React.useCallback((val?: ValueType) => setValue(val), []);

  React.useEffect(() => {
    onDebouncedChange(value);
  }, [value]);

  return {
    debouncedValue,
    value,
    onChange,
  };
};
