import { Stack, Tooltip, Typography } from '@mui/material';
import { tableTypes, UISeverityDots } from '@shared/components';
import { allRoutesEnum, globalEnums, globalModels, globalUtils } from '@shared/duck';
import { DisruptedIcon } from 'views/components/v1/Icons';
import { formatTimeSpan } from 'views/utils/dates';
import useTimer from 'views/utils/useTimer';

export const scansColumns: tableTypes.ColumnProps<globalModels.ScansViewModelNew>[] = [
  {
    title: 'Target',
    columnKey: 'targetName',
    width: '17.9375rem',
    cellInfo: {
      getIcon(item) {
        return (
          <Stack position='relative'>
            {globalUtils.getTargetTypeIcon(
              item.targetType.toString()?.toUpperCase() as globalEnums.ETargetType,
              item.isInProgress,
            )}
            {item.isDisrupted && (
              <Tooltip
                arrow
                title='This scan was disrupted'
                placement='top'
              >
                <Stack
                  sx={{ position: 'absolute', top: '-5px', left: '-5px' }}
                >
                  <DisruptedIcon size='tiny' />
                </Stack>
              </Tooltip>
            )}
          </Stack>
        );
      },
      description(item) {
        const { time: currentTime } = useTimer({
          fromDate: item.startedAt,
          formatter: formatTimeSpan,
          isActive: item.isInProgress,
        });

        return (
          item.isDisrupted && item.completedIn
            ?
            'Disrupted' + ' | ' + `Completed in: ${formatTimeSpan(Math.round(item.completedIn))}`
            :
            item.completedIn && !item.isInProgress
              ? `Completed in: ${formatTimeSpan(Math.round(item.completedIn))}`
              :
              item.isInProgress ? 'In Progress' + ' | ' + `${item.percentage}%` + ' | ' + `${currentTime}` : ''
        );
      },
      href(item) {
        return allRoutesEnum.ScanDetails.replace(':scanId', item.id);
      }
    },
    customCellRenderer(value) {
      return (
        <Typography variant='subtitle2' fontWeight='600'>
          {value as string}
        </Typography>
      );
    },
  },
  {
    title: 'Application',
    columnKey: 'applicationName',
    width: '10.625rem',
    cellInfo: {
      cellTextWeight: 600,
      description(item) {
        return item.projectName || 'N/A';
      },
    },
  },
  {
    title: 'URL Paths',
    columnKey: 'vulnPathsStat',
    minWidth: '13.4375rem',
    customCellRenderer(value, item) {
      return (
        <UISeverityDots values={item.vulnPathsStat} popupComments='paths' />
      );
    },
  },
];