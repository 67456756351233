import { LoginStatusEnum } from '@api-client';
import { startCase } from 'lodash';
import { globalEnums } from '@shared/duck';
import { Badge, BadgeProps } from '@contentful/f36-components';

interface CredentialsStatusBadgeProps {
  status?: LoginStatusEnum;
}

const CredentialsStatusBadge: React.FC<CredentialsStatusBadgeProps> = ({
  status
}: CredentialsStatusBadgeProps) => {
  if (!status) {
    return null;
  }

  let badgeProps: BadgeProps;

  switch (status) {
    case LoginStatusEnum.Success:
      badgeProps = {
        children: 'Success',
        variant: globalEnums.EBadgeVariants.POSITIVE,
      };
      break;
    case LoginStatusEnum.Failure:
      badgeProps = {
        children: 'Failure',
        variant: globalEnums.EBadgeVariants.NEGATIVE,
      };
      break;
    default:
      badgeProps = {
        children: startCase(status),
        variant: globalEnums.EBadgeVariants.WARNING,
      };
  }

  return <Badge {...badgeProps} />;
};

export default CredentialsStatusBadge;
