import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectDetailsInfo } from './components';
import { allRoutesEnum } from '@shared/duck';
import { BorderedTabs } from './project-details.styled';
import { Paper, Tab } from '@mui/material';

type ProjectDetailsTabName = 'targets' | 'authentications' | 'collaborators' | 'applications';

interface ProjectDetailsTabProps {
  tabId: ProjectDetailsTabName;
  children: React.ReactNode;
}

const ProjectDetailsTab: React.FC<ProjectDetailsTabProps> = ({ tabId, children }: ProjectDetailsTabProps) => {
  const { projectId = '' } = useParams();
  const navigate = useNavigate();
  const onTabChange = (tabId: ProjectDetailsTabName) => {
    navigate(`${allRoutesEnum.ProjectDetails.replace(':projectId', projectId)}/${tabId}`, { replace: true });
  };

  return (
    <>
      <ProjectDetailsInfo projectId={projectId} />
      <Paper
        variant='outlined'
        sx={{
          borderRadius: '0.375rem',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <BorderedTabs
          value={tabId}
          onChange={(_, tab) => onTabChange(tab as ProjectDetailsTabName)}
          variant='scrollable'
          allowScrollButtonsMobile
        >
          <Tab value='targets' label='Targets' />
          <Tab value='authentications' label='Authentications' />
          <Tab value='collaborators' label='Collaborators' />
          <Tab value='applications' label='Applications' />
        </BorderedTabs>
        {children}
      </Paper>
    </>
  );
};

export default ProjectDetailsTab;
