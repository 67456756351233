import { Heading, SubHeading, Image, Content } from './no-access-page.styled';
import { useApp } from '@shared/hooks';
import { CONTACT_EMAIL } from '@shared/duck/constants';
import { Box, Link, Stack } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
import { allRoutesEnum } from '@shared/duck';

const NoAccessPage = ({ name }: { name?: string }) => {
  const app = useApp();
  const currentUser: string | undefined = app.users.me?.dto.email;
  const params = new URLSearchParams(document.location.search);
  name = name || params.get('name') || undefined;
  // window.history.replaceState({}, document.title, window.location.pathname)

  return (
    <Content>
      <Stack alignItems='flex-start' gap='3rem' flex='1 0 0'>
        <Box ml='5rem' mr='5rem'>
          <Heading>You don&apos;t have access to this {name || 'target'}</Heading>
          <SubHeading>
            {'Make sure you\'re logged in from the right account. You are signed in as '}
            <u style={{ textUnderlineOffset: '0.3rem' }}>{currentUser}</u>{'.\n'}
            <Link onClick={() => app.logout()}>
              {'Logout'}
            </Link>
            {' to switch account, or return to the NightVision '}
            <Link component={ReactLink} to={allRoutesEnum.Home}>
              {'Home Page'}
            </Link>
            {'.\n\n'}
            {'Otherwise, contact us at '}
            <Link href={`mailto:${CONTACT_EMAIL}`}>
              {CONTACT_EMAIL}
            </Link>
            {' to solve this issue.'}
          </SubHeading>
        </Box>
      </Stack>
      <Image />
    </Content>
  );
};

export default NoAccessPage;
