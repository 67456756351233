import { AttentionGray } from '@assets';
import { NoContentPlaceholder } from '@shared/components';
import { BasicTable } from './basic';
import { globalEnums, globalTypes } from '@shared/duck';
import { tableTypes } from './duck';
import { LoadMoreButton } from './components';

interface UITableProps<DataItem> extends tableTypes.UITableCommonProps<DataItem> {
  data: DataItem[],
  columns: tableTypes.ColumnProps<DataItem>[];
  type?: globalEnums.ETableType;
}

const UITable = <DataItem extends globalTypes.UIDItem>({
  type = globalEnums.ETableType.BASIC,
  data,
  columns,
  isLoadMoreAvailable = false,
  onLoadMoreClick,
  loading,
  noContentMessage,
  noContentHeight = '18.75rem',
  noContentIcon = AttentionGray,
  ...props
}: UITableProps<DataItem>) => {
  return (
    <>
      {(
        () => {
          switch (type) {
            case globalEnums.ETableType.BASIC:
              return (
                <BasicTable<DataItem>
                  data={data}
                  columns={columns}
                  loading={loading}
                  {...props}
                />
              );
          }
        }
      )()}

      {!loading && !data.length && (
        <NoContentPlaceholder
          height={noContentHeight}
          iconSrc={noContentIcon}
          message={noContentMessage || 'No items found'}
        />
      )}

      {!loading && isLoadMoreAvailable && onLoadMoreClick && !!data.length && (
        <LoadMoreButton
          onLoadMoreClick={onLoadMoreClick}
          colorTheme={props.colorTheme}
        />
      )}
    </>
  );
};

export default UITable;