import React from 'react';
import { User } from '@api-client';
import { allowUserTracking, clearUserForDatadogRum, isUserTrackingAllowed, setUserForDatadogRum } from 'datadogRum';
import { Button, SnackbarContent, Stack } from '@mui/material';

function TrackingConsentNotification() {
  const [isHidden, setIsHidden] = React.useState(false);
  const consentGranted = isUserTrackingAllowed();
  if (isHidden || consentGranted) {
    return undefined;
  }
  return (
    <div style={{ position: 'absolute', bottom: '1rem', left: 0, right: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <SnackbarContent
        message="We want to track your actions on our website"
        action={
          <Stack direction='row'>
            <Button
              color='secondary'
              size='small'
              onClick={() => {
                allowUserTracking();
                setIsHidden(true);
              }}
            >
              Allow
            </Button>
            <Button
              color='secondary'
              size='small'
              onClick={() => setIsHidden(true)}
            >
              Hide
            </Button>
          </Stack>
        }
      />
    </div>
  );
}

function setUser(currentUser?: User) {
  const [isUserSet, setIsUserSet] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (currentUser && !isUserSet) {
      setUserForDatadogRum({
        id: currentUser.id,
        email: currentUser.email,
        name: currentUser.username,
      });
      setIsUserSet(true);
    }
    if (!currentUser && isUserSet) {
      clearUserForDatadogRum();
      setIsUserSet(false);
    }
  }, [currentUser, isUserSet]);
}

export const useSetDatadogRumUser =() => {
  return {
    setUser,
    TrackingConsentNotification,
  };
};
