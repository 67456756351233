import { CredentialsRef } from '@api-client';
import { globalModels, globalUtils } from '@shared/duck';

const mapCredentialsRefDtoToViewModel = (dto: CredentialsRef): globalModels.AuthenticationsViewModel => {
  return {
    id: dto.id,
    name: dto.name,
    description: dto.description || '',
    lastSynced: '',
    authenticationType: globalUtils.getAuthenticationTypeName(dto.type) || '',
    projectName: dto.project_name,
    noOfTargets: 0,
  };
};

export { mapCredentialsRefDtoToViewModel };
