import { CopyIconC } from '@assets';
import { F36Select, MuiSearchInput } from '@shared/components';
import { useDebounce } from '@shared/hooks';
import React from 'react';
import { StyledFlex, StyledPanel } from './scan-logs-table.styled';
import { ScanLogsTableUtils } from './duck';
import { saveAs } from 'file-saver';
import { globalEnums, globalTypes } from '@shared/duck';
import { TableVirtuosoHandle } from 'react-virtuoso';
import { Box, Button, Button as MuiButton, Stack } from '@mui/material';
import { ComponentEnumValues, ComponentEnumNames, ComponentEnum } from './components/table/column-component/duck/enums';
import { getAssociatedEnumValue } from './components/table/column-component/duck/utils';
import { enqueueSnackbar } from 'notistack';
import { LogsModal, LogsTable } from './components';
import { DownloadSimple } from '@phosphor-icons/react';

let fileSaveSupported: boolean;
try {
  fileSaveSupported = !!new Blob();
} catch (e) {
  fileSaveSupported = false;
}

interface ScanLogsTableProps {
  scanId: string;
  scanFailed: boolean;
  logs: globalTypes.ScanLogItem[];
  isLoading: boolean;
}

const ComponentOptions = ComponentEnumValues.map(v => ({
  label: ComponentEnumNames.get(v) ?? '',
  value: v,
}));

const ScanLogsTable: React.FC<ScanLogsTableProps> = ({
  scanId,
  scanFailed,
  logs,
  isLoading
}: ScanLogsTableProps) => {
  const { debouncedValue: debouncedSearch, onChange: onSearchChange } = useDebounce<string>();
  const searchString = debouncedSearch?.trim().toLowerCase();

  const [component, setComponent] = React.useState<ComponentEnum | undefined>(undefined);

  const selectedComponent = React.useMemo(() => ComponentOptions.find(c => c.value == component), [component]);

  const filteredLogs = React.useMemo(() => {
    let result = logs;
    if (searchString) {
      result = result.filter(l => l.msg?.toLowerCase().includes(searchString));
    }
    if (selectedComponent) {
      result = result.filter(l => getAssociatedEnumValue(l.component) === selectedComponent.value);
    }
    return result;
  }, [logs, debouncedSearch, component]);

  const tableRef = React.useRef<TableVirtuosoHandle>(null);

  const onCopySelectedClick = React.useCallback(() => {
    const value = ScanLogsTableUtils.logsToCsv(filteredLogs);
    navigator.clipboard.writeText(value).then(async () => {
      enqueueSnackbar('Copied to clipboard!', { variant: 'info', autoHideDuration: 1000, closeBtn: false });
    });
  }, [filteredLogs]);

  const onExportClick = React.useCallback(() => {
    const text = ScanLogsTableUtils.logsToCsv(logs);
    const value = new Blob([text], { type: 'text/plain;charset=utf-8' });
    saveAs(value, `nv-scan-logs-${scanId}.csv`);
  }, [logs]);

  const firstCriticalError = React.useMemo(() => logs.findIndex(v => v.level?.toLocaleLowerCase() === 'critical'), [logs.length]);
  const initialTopMostItemIndex = (scanFailed && firstCriticalError > -1) ? firstCriticalError : filteredLogs.length - 1;

  React.useEffect(() => {
    // Overcoming https://github.com/petyosi/react-virtuoso/issues/656
    if (scanFailed && firstCriticalError > -1) {
      setTimeout(() => {
        tableRef.current?.scrollToIndex(firstCriticalError);
      }, 100);
    }
  }, [scanFailed, firstCriticalError]);

  const [checkName, setCheckName] = React.useState('');

  return (
    <StyledFlex flexDirection='column' height='100%'>
      <StyledPanel padding='0.75rem' justifyContent='space-between'>
        <Stack direction='row' gap='1rem' alignItems='center' sx={{ width: 500 }}>
          <MuiSearchInput
            placeholder='Search for logs'
            onChange={e => onSearchChange(e.target.value)}
            disabled={!logs.length}
          />
          <Box width={150} flexShrink='0'>
            <F36Select
              options={ComponentOptions}
              initialValue={selectedComponent}
              onChange={(value) => setComponent(value.target.value)}
              withSearch={false}
              placeholder='All'
            />
          </Box>
        </Stack>
        <Stack direction='row' gap='1rem' alignItems='center' flexShrink='0' >
          <MuiButton
            aria-label='Copy'
            sx={{ height: '2.5rem', }}
            onClick={onCopySelectedClick}
            disabled={!filteredLogs.length}
            startIcon={
              <CopyIconC
                variant={globalEnums.CopyIconVariantsEnum.BLACK}
              />
            }
          >
            Copy {(searchString || selectedComponent) ? `(${filteredLogs.length})` : ''}
          </MuiButton>

          {fileSaveSupported && (
            <Button
              aria-label='Export'
              startIcon={<DownloadSimple size={20} />}
              disabled={!logs.length || isLoading}
              onClick={onExportClick}
              sx={{
                height: '2.5rem'
              }}
            >
              Export All
            </Button>
          )}
        </Stack>
      </StyledPanel>

      <LogsTable
        filteredLogs={filteredLogs}
        isLoading={isLoading}
        tableRef={tableRef}
        initialTopMostItemIndex={initialTopMostItemIndex}
        setCheckName={setCheckName}
      />

      <LogsModal
        scanId={scanId}
        checkName={checkName}
        setCheckName={setCheckName}
      />
    </StyledFlex>
  );
};

export default ScanLogsTable;
