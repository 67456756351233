import { Table } from '@mui/material';
import { tableTypes } from '@shared/components';
import { BasicTableBody } from './components';
import { MuiTableHeader } from '@shared/components/table/components';
import { globalTypes } from '@shared/duck';

const BasicMuiTable = <DataItem extends globalTypes.UIDItem>({
  columns,
  data,
  isStickyHeader = true,
  itemActions,
  loading,
  headerItemActions,
  ...props
}: tableTypes.BasicMuiTableProps<DataItem>) => {
  return (
    <>
      <Table
        stickyHeader={isStickyHeader}
        aria-label='mui-table'
      >
        <MuiTableHeader
          columns={columns}
          withEmptyItemActions={!headerItemActions && !!itemActions}
          headerItemActions={headerItemActions}
          bordered={props.bordered}
          itemSelection={props.itemSelection}
          {...props}
        />

        <BasicTableBody<DataItem>
          data={data}
          columns={columns}
          itemActions={itemActions}
          loading={loading}
          {...props}
        />
      </Table>
    </>
  );
};

export default BasicMuiTable;