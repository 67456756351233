import React from 'react';
import dayjs from 'dayjs';
import * as dateFns from 'date-fns';

export enum DurationPeriod {
  Week,
  Month,
  Year,
}

export interface DurationSelectApi {
  period: DurationPeriod;
  onPeriodChange: (period: DurationPeriod) => void;
}

export const useDurationSelect = (initialPeriod: DurationPeriod): DurationSelectApi => {
  const [period, setPeriod] = React.useState(() => initialPeriod);

  const onPeriodChange = React.useCallback((new_period: DurationPeriod) => {
    setPeriod(new_period);
  }, []);

  return {
    period: period,
    onPeriodChange,
  };
};

export interface DatesInterval {
  /**
   * Upper boundary (exclusive).
   */
  before: Date;
  /**
   * Lower boundary (inclusive)
   */
  since: Date;
  /**
   * Dates inside interval (in current TimeZone for appropriate display).
   */
  datesToDisplay: Date[];
}

export function getUTCIntervalForNow({ period, generateDates = false }: { period: DurationPeriod; generateDates?: boolean; }): DatesInterval {
  const lastDayOfInterval = dayjs.utc().startOf('date');
  const exclusiveBefore = lastDayOfInterval.add(1, 'day');
  let since: dayjs.Dayjs;
  switch (period) {
    case DurationPeriod.Week:
      since = exclusiveBefore.subtract(1, 'week');
      break;
    case DurationPeriod.Month:
      since = exclusiveBefore.subtract(1, 'month');
      break;
    case DurationPeriod.Year:
      since = exclusiveBefore.subtract(1, 'year');
      break;
  }
  const start = new Date(since.year(), since.month(), since.date());
  const end = new Date(lastDayOfInterval.year(), lastDayOfInterval.month(), lastDayOfInterval.date());
  const dates = generateDates
    ? period === DurationPeriod.Year
      ? dateFns.eachMonthOfInterval({ start, end })
      : dateFns.eachDayOfInterval({ start, end })
    : [];
  return {
    before: exclusiveBefore.toDate(),
    since: since.toDate(),
    datesToDisplay: dates,
  };
}
