import { Box, CircularProgress, CircularProgressProps, LinearProgress } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';
import { SyncLoader } from 'react-spinners';

interface MuiContentLoaderProps extends Pick<CircularProgressProps, 'color' | 'size'> {
  isLoading?: boolean;
  variant?: 'linear' | 'circular' | 'dots';
  children: React.ReactNode;
  tableSpanCols?: number;
  padding?: string;
}

const MuiContentLoader: React.FC<MuiContentLoaderProps> = ({
  children,
  variant = 'circular',
  color,
  size,
  isLoading,
  tableSpanCols,
  padding = '2rem',
}: MuiContentLoaderProps) => {
  const Progress = () => {
    switch (variant) {
      case 'circular':
        return (
          <Box p={padding}>
            <CircularProgress color={color} size={size} />
          </Box>
        );
      case 'linear':
        return (
          <LinearProgress color={color} />
        );
      case 'dots':
        return (
          <Box p={padding}>
            <SyncLoader color={color || muiPalette.grey?.[800]} size={size || 10} />
          </Box>
        );
      default:
        return (
          <Box p={padding}>
            <CircularProgress color={color} size={size} />
          </Box>
        );
    }
  };

  const RenderedSpinner = tableSpanCols !== undefined ? <tr>
    <td colSpan={tableSpanCols}>{Progress()}</td>
  </tr> : Progress();

  return <>{isLoading ? RenderedSpinner : children}</>;
};

export default MuiContentLoader;
