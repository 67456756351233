import { IconButton, Tooltip as MuiTooltip, Tooltip } from '@mui/material';
import { globalEnums } from '@shared/duck';
import { InfoCircleIcon } from 'views/components/v1/Icons';

const TooltipError: React.FC<{ content: string }> = ({ content }: { content: string }) => {
  return (
    <Tooltip title={content}>
      <span style={{ height: '1.15rem' }}>
        <InfoCircleIcon variant={globalEnums.EIconVariants.NEGATIVE} />
      </span>
    </Tooltip>
  );
};

export const MuiTooltipError: React.FC<{ content: string }> = ({ content }: { content: string }) => {
  return (
    <MuiTooltip title={content} arrow placement='top'>
      <IconButton>
        <InfoCircleIcon variant={globalEnums.EIconVariants.NEGATIVE} />
      </IconButton>
    </MuiTooltip>
  );
};

export default TooltipError;