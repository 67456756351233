import React from 'react';
import { Label } from './general-tab.styled';
import { globalEnums } from '@shared/duck';
import { useField, useFormikContext } from 'formik';
import { BadgeLabel } from '../badge';
import { Project } from '@api-client';
import { useComponentWillUnmount } from '.';
import { UITextInput, FormikControl, MuiProjectSelect } from '@shared/components';
import { ApplicationFormValues } from '../../application-modal';
import { Stack } from '@mui/material';

interface GeneralTabProps {
  initialProject?: Project;
  enableProjectSelect: boolean;
}

const GeneralTab: React.FC<GeneralTabProps> = ({
  initialProject,
  enableProjectSelect,
}: GeneralTabProps) => {
  const { setFieldTouched, setFieldValue } = useFormikContext<ApplicationFormValues>();
  const [field, , helpers] = useField<string[]>('labels');
  const [label, setLabel] = React.useState<string>('');

  const updateLabels = () => {
    setFieldTouched('name', true);
    if (label.length > 0) {
      const updatedValue = [...field.value, label];
      helpers.setValue(updatedValue);
      setLabel('');
    }
  };

  useComponentWillUnmount(updateLabels);

  const onKeyDown = React.useCallback((e: any) => {
    if (e.key === globalEnums.EKeyboardKeys.ENTER) {
      updateLabels();
    }
  }, [label]);

  const onDeleteLabel = (indexToDelete: number) => {
    const newLabels = field.value.filter((_, index) => index !== indexToDelete);
    helpers.setValue(newLabels);
  };
  return (
    <Stack width='50%' gap='0' mt='1.5rem'>
      <FormikControl name='name' label='Application name'>
        <UITextInput placeholder='Enter name' />
      </FormikControl>
      <FormikControl name='project' showError>
        <MuiProjectSelect
          disabled={!enableProjectSelect}
          defaultProject={initialProject}
          onFormChange={(e: any) => setFieldValue('project', e.target.value)}
        />
      </FormikControl>

      <Stack>
        <Stack gap='0'>
          <Label>Environment Label(s)</Label>
          <UITextInput
            value={label}
            placeholder='e.g. QA, Production, etc...'
            onChange={(e) => setLabel(e.target.value)}
            onBlur={updateLabels}
            onKeyDown={onKeyDown}
          />
        </Stack>
        <FormikControl name='labels' showError>
          <Stack direction='row' flexWrap='wrap' gap='0'>
            {field.value.map((label, index) => {
              return (
                <BadgeLabel
                  onDeleteLabel={() => onDeleteLabel(index)}
                  label={label}
                  key={index}
                />
              );
            })}
          </Stack>
        </FormikControl>
      </Stack>
    </Stack>
  );
};

export default GeneralTab;