import { globalEnums } from 'shared/duck';

export type CheckCountType = globalEnums.CheckStatusEnum | 'total'

export const textForCheckCountType = new Map<CheckCountType, string>(
  [
    ...Array.from(globalEnums.CheckStatusName),
    ['total', 'Total'],
  ]
);
