import { Box, Tooltip, Typography } from '@mui/material';
import { allRoutesEnum } from '@shared/duck';
import { useNavigate } from 'react-router-dom';
import { Plus } from '@phosphor-icons/react';
import { IconButton } from './new-scan-button.styled';

interface NewScanButtonProps {
  isSideBarCollapsed: boolean;
}

const NewScanButton: React.FC<NewScanButtonProps> = ({
  isSideBarCollapsed
}: NewScanButtonProps) => {
  const navigate = useNavigate();

  return (
    <Tooltip
      arrow
      disableHoverListener={!isSideBarCollapsed}
      placement='right'
      title='New Scan'
    >
      <Box p='0 1.2rem'>
        <IconButton
          size='medium'
          color='outlined'
          onClick={() => navigate(allRoutesEnum.NewScan)}
          isSideBarCollapsed={isSideBarCollapsed}
        >
          <Plus color='white' weight="bold" />
          <Typography variant='subtitle2'>
            New Scan
          </Typography>
        </IconButton>
      </Box >
    </Tooltip>
  );
};

export default NewScanButton;