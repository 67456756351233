import {
  PlusIcon as BasePlusIcon,
  ChevronDownTrimmedIcon as BaseChevronDownTrimmedIcon,
  EditIcon as BaseEditIcon,
  ArrowUpIcon as BaseArrowUp,
  ArrowDownIcon as BaseArrowDown,
  DoubleArrowIcon as BaseDoubleArrowIcon,
  CloseIcon as BaseCloseIcon,
  MoreVerticalIcon as BaseMoreVerticalIcon,
  ChevronLeftIcon as BaseChevronLeftIcon,
  ChevronRightIcon as BaseChevronRightIcon,
  InfoCircleIcon as BaseInfoCircleIcon,
  CopyIcon as BaseCopyIcon,
  ChevronUpTrimmedIcon as BaseChevronUpTrimmedIcon,
  ChevronDownIcon as BaseChevronDownIcon,
  ChevronUpIcon as BaseChevronUp,
  LinkAlternateIcon as BaseLinkAlternateIcon,
} from '@contentful/f36-icons';

const PlusIcon = BasePlusIcon;
const ChevronDownTrimmedIcon = BaseChevronDownTrimmedIcon;
const ChevronUpTrimmedIcon = BaseChevronUpTrimmedIcon;
const EditIcon = BaseEditIcon;
const ArrowUpIcon = BaseArrowUp;
const ArrowDownIcon = BaseArrowDown;
const DoubleArrowIcon = BaseDoubleArrowIcon;
const CloseIcon = BaseCloseIcon;
const MoreVerticalIcon = BaseMoreVerticalIcon;
const ChevronLeftIcon = BaseChevronLeftIcon;
const InfoCircleIcon = BaseInfoCircleIcon;
const CopyIcon = BaseCopyIcon;
const ChevronRightIcon = BaseChevronRightIcon;
const ChevronDownIcon = BaseChevronDownIcon;
const ChevronUpIcon = BaseChevronUp;
const LinkAlternateIcon = BaseLinkAlternateIcon;

export {
  PlusIcon,
  ChevronDownTrimmedIcon,
  ChevronUpTrimmedIcon,
  EditIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  DoubleArrowIcon,
  CloseIcon,
  MoreVerticalIcon,
  ChevronLeftIcon,
  InfoCircleIcon,
  CopyIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  LinkAlternateIcon
};