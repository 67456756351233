import { Check } from '@api-client';
import React from 'react';
import { FileTextIconC } from '@assets';
import { Modal } from '@shared/components';
import { StyledDivContainer } from './type-name.styled';
import { columnTypeUtils } from '../duck';
import { JsonModal, TextModal } from './components';
import { CheckStatusEnum } from '@shared/duck/enums/global-enums';

interface ColumnTypeProps {
  item: Check;
  label: string;
}

const TypeName: React.FC<ColumnTypeProps> = ({
  item,
  label,
}) => {
  const isCheckInProgress = item.status === CheckStatusEnum.IN_PROGRESS;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isOwaspType, setOwaspType] = React.useState<boolean>(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  React.useEffect(() => {
    setOwaspType(columnTypeUtils.isOwaspType(item.type));
  }, []);

  // Workaround for old OWASP Logs in text form, change current modal on-the-fly:
  const [useTextModal, setUseTextModal] = React.useState(false);

  React.useEffect(() => {
    setUseTextModal(false);
  }, [item]);

  const onClickHandler = isCheckInProgress ? undefined : handleOpenModal;

  if (isOwaspType) {
    return <>
      <StyledDivContainer onClick={onClickHandler} sx={{ cursor: isCheckInProgress ? 'default' : 'pointer' }}>
        <div>{label}</div>
        {!isCheckInProgress && <FileTextIconC />}
      </StyledDivContainer>
      <Modal
        onClose={handleCloseModal}
        style={{
          height: '34.75rem',
        }}
        isShown={isModalOpen}
        size='fullWidth'
      >
        {() => (
          !useTextModal ? (
            <JsonModal
              item={item}
              setModal={setIsModalOpen}
              onUseTextModal={() => setUseTextModal(true)}
            />
          ) : (
            <TextModal item={item} setModal={setIsModalOpen} />
          )
        )}
      </Modal>
    </>;
  } else {
    return <>{item.name}</>;
  }
};

export default TypeName;
