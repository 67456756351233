import { Issue } from '@api-client';

export const getIssueUrl = (issue: Issue | undefined): string => {
  if (issue?.url_path) {
    try {
      const decodedPath = decodeURIComponent(issue.url_path);
      return decodedPath;
    }
    catch {
      // skip URIError in case of malformed text
      return issue.url_path;
    }
  } else {
    return '';
  }
};

export const getIssueCopyUrl = (issue: Issue | undefined): string => {
  const url = getIssueUrl(issue);
  const hostLocation = issue?.scan.location ? new URL(issue.scan.location).origin.toString() : '';

  return hostLocation + (
    hostLocation.endsWith('/') && url.startsWith('/') ? url.slice(1) : url
  );
};