import { Box, IconButton, ListItemIcon, Menu, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { DotsThreeVertical } from '@phosphor-icons/react';
import { StyledDivider, StyledFooter, StyledHeader } from './complex-menu.styled';
import { StyledMenuItem, StyledMenuItemText } from '@shared/components/menu/menu.styled';
import { globalEnums, globalTypes } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';
import { isUndefined } from 'lodash';

interface ComplexMenuProps {
  itemList: globalTypes.UIMenuItemsList[];
  disabled?: boolean;
  headerText?: string;
  headerSubText?: string;
  footerItems?: globalTypes.UIMenuItemsList[];
  iconVariant?: globalEnums.EMuiIconButtonVariants;
  size?: 'small' | 'medium' | 'large';
  iconColor?: string;
  menuIconC?: JSX.Element;
  sx?: SxProps<Theme>;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ComplexMenu: React.FC<ComplexMenuProps> = ({
  itemList,
  disabled,
  headerText,
  headerSubText,
  footerItems,
  iconVariant = globalEnums.EMuiIconButtonVariants.OUTLINED,
  size = 'medium',
  iconColor = muiPalette.grey?.[900],
  menuIconC,
  sx,
  setIsOpen,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    setIsOpen && setIsOpen(open);
  }, [open]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (disabled) {
      event.preventDefault();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {menuIconC ? (
        <Box
          display='flex'
          justifyContent='center'
          aria-label='toggle menu'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          {menuIconC}
        </Box>
      ) : (
        <IconButton
          color={iconVariant}
          aria-label='toggle menu'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          size={size}
        >
          <DotsThreeVertical color={iconColor} weight='bold' size={22} />
        </IconButton>
      )}

      <Menu
        id='complex-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          disablePadding: true,
        }}
        sx={sx}
      >
        {headerText && (
          <StyledHeader
            disabled
          >
            <Typography
              variant={'body1'}
              fontWeight={'bold'}
              color={muiPalette.grey?.[800]}
              width='100%'
              noWrap
            >
              {headerText}
            </Typography>

            {headerSubText && (
              <StyledMenuItemText>
                {headerSubText}
              </StyledMenuItemText>
            )}
          </StyledHeader>
        )}
        {itemList.map((item, index) => {
          const iconPosition = item.titleIconPosition || 'left';

          return item.isHidden ? null : (
            <Tooltip
              key={`complex-menu-tooltip-${item.title}-${index}`}
              arrow
              placement={item.tooltipPlacement || 'top'}
              title={item.disabled ? item.tooltipText || '' : ''}
            >
              <span>
                <StyledMenuItem
                  onClick={() => {
                    item.itemAction();
                    (
                      isUndefined(item.closeAfterAction) || item.closeAfterAction
                    ) && handleClose();
                  }}
                  disabled={item.disabled}
                >
                  <Stack flexDirection={'row'} gap={item.titleStackGap || 0.25}>
                    {item.titleIcon && iconPosition === 'left' && (
                      <ListItemIcon sx={{ alignItems: 'center' }}>
                        {item.titleIcon}
                      </ListItemIcon>
                    )}

                    <StyledMenuItemText
                      isRedText={item.isRedText ? globalEnums.EmotionBoolean.True : globalEnums.EmotionBoolean.False}
                    >
                      {item.title}
                    </StyledMenuItemText>

                    {item.titleIcon && iconPosition === 'right' && (
                      <ListItemIcon sx={{ alignItems: 'center' }}>
                        {item.titleIcon}
                      </ListItemIcon>
                    )}
                  </Stack>
                </StyledMenuItem>
              </span>
            </Tooltip>
          );
        })}

        {!!itemList.length && !!footerItems?.length && <StyledDivider />}

        {footerItems?.map((footerItem, index) => {
          return (
            <Tooltip
              key={`menu-tooltip-${footerItem.title}-${index}`}
              arrow
              placement={footerItem.tooltipPlacement || 'top'}
              title={footerItem.disabled ? footerItem.tooltipText || '' : ''}
            >
              <span>
                <StyledFooter
                  key={index}
                  onClick={() => {
                    footerItem.itemAction();
                    handleClose();
                  }}
                  disabled={footerItem.disabled}
                  sx={{
                    cursor: `${footerItem.disabled ? 'default' : 'pointer'}`,
                  }}
                >

                  {footerItem.titleIcon && (
                    <ListItemIcon
                      sx={{ marginRight: '0.25rem' }}
                    >
                      {footerItem.titleIcon}
                    </ListItemIcon>
                  )}

                  <StyledMenuItemText
                    isRedText={footerItem.isRedText
                      ? globalEnums.EmotionBoolean.True
                      : globalEnums.EmotionBoolean.False}
                  >
                    {footerItem.title}
                  </StyledMenuItemText>
                </StyledFooter>
              </span>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
};

export default ComplexMenu;