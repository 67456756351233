import { Stack, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const StyledBadge = styled(Stack)`
  & > div svg {
    &:hover {cursor: pointer;}
  }
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  width: fit-content;
  
  border-radius: 0.25rem;
  background-color: ${muiPalette.grey?.[200]};
  padding: 0rem 0.5rem;

  .text {
    color: ${muiPalette.grey?.[600]};
    font-weight: 600;
    font-size: 0.75rem;
    word-break: break-all;
    line-height: 1.25rem;
  }
`;

export { StyledBadge };