import styled from '@emotion/styled';

const StyledTextInput = styled.div`
  position: relative;
  width: 100%;

  .styled-input {
    box-shadow: none;
    border: none;

    &:focus {
      box-shadow: none;
      border: none;
    }
  }

  & > input {
    padding-right: 2.625rem;
  }

  & .icon-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0.62rem;

    &:hover {
      cursor: pointer;
    }

    .tooltip {
      z-index: 10;
    }
  }
`;

export { StyledTextInput };