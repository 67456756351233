import { Modal, ModalContent, ModalControls, ModalHeader } from '@contentful/f36-components';
import { Formik, FormikErrors } from 'formik';
import { globalEnums } from '@shared/duck';
import { FormikControl, UITextInput } from '@shared/components';
import { Typography, Button } from '@mui/material';

interface DowngradePlanFormValues {
  cancel?: string;
}

interface CancelSubscriptionModalProps {
  subscriptionEndingDate?: string;
  isOpenModal: boolean;
  closeModal: () => void;
  onConfirm: () => void;
}

const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({
  subscriptionEndingDate,
  isOpenModal,
  closeModal,
  onConfirm,
}: CancelSubscriptionModalProps) => {
  return (
    <Modal onClose={closeModal} isShown={isOpenModal} style={{ opacity: '0.5rem' }}>
      {() => (
        <Formik<DowngradePlanFormValues>
          initialValues={{ cancel: '' }}
          validate={(values) => {
            const errors: FormikErrors<DowngradePlanFormValues> = {};
            if (values.cancel !== 'Cancel Subscription') errors.cancel = '';
            return errors;
          }}
          isInitialValid={false}
          onSubmit={() => { closeModal; onConfirm(); }}
          validateOnMount>
          {({ handleSubmit, isValid }) => (
            <>
              <ModalHeader onClose={closeModal} title='Cancel Subscription?' />
              <ModalContent>
                <Typography variant='default' pb='1rem' display='block'>
                  {subscriptionEndingDate && (
                    <>
                      Your subscription will be cancelled at {subscriptionEndingDate}.
                      {/* <br /><br /> */}
                    </>
                  )}
                  {/* By clicking <b>Confirm</b>, you agree to deactivate your Free Trial Plan and proceed to the
                  payment page for the Professional Plan. // TODO: change text */}
                </Typography>
                <FormikControl style={{ margin: '0' }} name='cancel' label="Type 'Cancel Subscription' to proceed">
                  <UITextInput placeholder='Cancel Subscription' />
                </FormikControl>
              </ModalContent>
              <ModalControls style={{ padding: '0 1.5rem 1rem 1.5rem' }}>
                <Button
                  size='small'
                  color={globalEnums.EMuiButtonColors.SECONDARY}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  size='small'
                  color={globalEnums.EMuiButtonColors.PRIMARY}
                  variant={globalEnums.EMuiButtonVariants.CONTAINED}
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                >
                  Confirm
                </Button>
              </ModalControls>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default CancelSubscriptionModal;
