import { Table } from '@contentful/f36-components';
import { BasicTableBody } from './components';
import { UITableHeader } from '../../components';
import { tableTypes } from '../../duck';
import { globalTypes } from '@shared/duck';

const BasicTable = <DataItem extends globalTypes.UIDItem>({
  columns,
  data,
  isStickyHeader = false,
  itemActions,
  minWidth,
  loading,
  withExternalBorders = true,
  ...props
}: tableTypes.BasicTableProps<DataItem>) => {
  return (
    <Table style={{
      minWidth: minWidth ? minWidth : '',
      border: withExternalBorders ? '1px solid #E7EBEE' : '',
      borderTop: 'unset',
      boxSizing: 'border-box',
      boxShadow: 'unset',
    }}>
      <UITableHeader
        isStickyHeader={isStickyHeader}
        columns={columns}
        withItemActions={!!itemActions}
        bordered={props.bordered}
        itemSelection={props.itemSelection}
        withExternalBorders={withExternalBorders}
        {...props}
      />

      <BasicTableBody<DataItem>
        data={data}
        columns={columns}
        itemActions={itemActions}
        loading={loading}
        {...props}
      />
    </Table>
  );
};

export default BasicTable;