import { CredentialsType, InitiatedWithEnum, UserUpdateRequest } from '@api-client';
import { WorldIconC } from '@assets';
import { Icon } from '@mui/material';
import { IconProps } from '@mui/material/Icon/Icon';
import { UIIconFrame } from '@shared/components';
import { globalConstants, globalEnums, globalTypes } from '@shared/duck';
import dayjs from 'dayjs';
import React from 'react';
import { useTargetTypes } from '@shared/duck/utils/technologies';
import * as yaml from 'js-yaml';
import { Ajv } from 'ajv';
import SwaggerParser from '@apidevtools/swagger-parser';
import { enqueueSnackbar } from 'notistack';

export const copyTextToClipboard = (
  text: string,
  message: string,
  duration: number = 4000,
  closeBtn: boolean = true,
) => {
  navigator.clipboard.writeText(text).then(async () => {
    enqueueSnackbar(message, { variant: 'info', autoHideDuration: duration, closeBtn: closeBtn });
  });
};

export const withSubRoutes = (route: string) => route.endsWith('/') ? `${route}*` : `${route}/*`;

export const extractBackLink = (pathname: string, level: number | undefined = -1) =>
  pathname.split('/').slice(0, level).join('/');

/** ATTENTION!!!!!
 * If you want to change the size of TargetTypeIcon use
 * globalUtils.getTargetTypeIcon(
 * type,
 * loading,
 * {
 *   width: 1rem,
 *   height: 1rem
 * }) */

export const getTargetTypeIcon = (
  type: globalEnums.ETargetType,
  loading?: boolean,
  imgStyle?: React.CSSProperties,
): React.ReactNode => {
  const typesMap = useTargetTypes();
  const tech = typesMap.get(type.toString()?.toUpperCase() as globalEnums.ETargetType);
  const Icon = tech?.icon || WorldIconC;

  if (loading !== undefined) {
    return (
      <UIIconFrame
        Icon={tech?.icon || WorldIconC}
        loading={loading ? 'true' : undefined}
        primaryColor={tech?.primaryColor}
        secondaryColor={tech?.secondaryColor}
      />
    );
  } else {
    return <Icon style={imgStyle} />;
  }
};

export const validateUrls = (urls: string[]): boolean => {
  const invalidUrls = urls?.map(url => url.trim()).filter(url => !url
    .match(/^(https?:\/\/)?(?![^#?/]*\.\.)[a-zA-Z0-9][-a-zA-Z0-9.]{1,255}(:\d+)?($|(#|\?|\/)[-a-zA-Z0-9@:%_+.~#?&/=]*)$/));

  if (invalidUrls?.length) {
    const result = invalidUrls.filter(url => !(
      url.match(/^((https?:\/\/)?((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}(:\d+)?(\/.*)?)$/)
      || (
        url.trim().includes('localhost')
      ) && url.match(/^((https?:\/\/)?\w+(\.\w+)*(:\d+)?(\/.*)?)$/)
    ));
    return !result?.length;
  }
  return true;
};

export const validateName = (name: string): boolean => {
  return !!name.match(/^[\w\-_]+$/);
};

export const validateEmails = (emails: string[]): boolean => {
  const invalidEmails = emails?.filter(email => !email.trim()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
  return !invalidEmails?.length;
};

export const getAuthenticationTypeName = (type: CredentialsType) => {
  return globalEnums.authenticationTypeNameMap.get(type) || 'N/A';
};

export const sliceTextByLimit = (text: string, withEllipsis = false, limit = 100) => {
  /** Note: remove spaces at the beginning and at the end */
  const trimmedText = text.trim();

  if (trimmedText.length <= limit) {
    return trimmedText;
  } else {
    /** Note: We use (limit - 3) because the ellipsis takes up 3 characters */

    return withEllipsis ? trimmedText.slice(0, limit - 3) + '...' : trimmedText.slice(0, limit);
  }
};

/**
 * Boolean filter with type inference
 */
export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

/**
 * Wrap a CSV record value in double quotes if there are `"`, `,` or `\n` characters inside.
 * Escapes all double quotes inside the item accordingly.
 *
 * @param item initial CSV record
 * @returns escaped and wrapped CSV record (if needed)
 */
export function wrapCsvInDoubleQuotesIfNeeded(item: string): string {
  return item.indexOf('"') + item.indexOf(',') + item.indexOf('\n') > -3 ? `"${item.replaceAll('"', '""')}"` : item;
}

export const getInitiatedWithName = (type?: InitiatedWithEnum) => {
  return globalEnums.scanInitiatedWithMap.get(type || InitiatedWithEnum.Web) || 'N/A';
};

export const getUserUpdateRequest = (values: globalTypes.ProfileFormValues) => {
  const result: UserUpdateRequest = {
    first_name: values.firstName,
    last_name: values.lastName,
    username: values.username,
    avatar_url: values.avatarUrl,
  };
  return result;
};


const VALIDATION_TIMEOUT_MS = 10_000;

interface ValidSpecResponse {
  isValidOpenApiSpec: true;
}

interface InvalidSpecResponse {
  isValidOpenApiSpec: false;
  error: {
    message: string
    code: string
  };
}

type UrlResponseData = (ValidSpecResponse | InvalidSpecResponse) & {
  requestedUrl: string;
}

export const validateOpenApiSpec = async ({ api, isFile, abortController, strictCheck }: {
  api: File | string;
  isFile: boolean;
  abortController?: AbortController;
  strictCheck?: boolean
}) => {
  try {
    let text = '';
    if (isFile) {
      text = await (
        api as File
      )?.text();
    } else {
      if (!abortController) {
        abortController = new AbortController();
      }
      const timeoutId = setTimeout(() => abortController?.abort(), VALIDATION_TIMEOUT_MS);

      const url = api as string;

      const body = { url: url, strict: strictCheck };
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await fetch('/api/test-api-spec', {
        method: 'POST',
        body: JSON.stringify(body),
        headers,
        signal: abortController?.signal,
      });
      if (!response.ok) {
        return { isValid: false };
      }

      clearTimeout(timeoutId);

      const data: UrlResponseData = await response.json();
      return {
        isValid: data.isValidOpenApiSpec,
        errorCode: !data.isValidOpenApiSpec ? data.error.code : undefined,
      };
    }

    const obj = yaml.load(text);  // supports JSON
    if (strictCheck) {
      await SwaggerParser.validate(obj);
    }
    else if (!isValidOpenApiSpecObject(obj)) {
      throw new Error();  // caught later
    }
    return {
      isValid: true,
    };
  } catch (err: any) {
    if (err.name === 'AbortError') {
      return { isValid: undefined };
    }
    return { isValid: false };
  }
};

const OPENAPI_VALIDATION_SCHEMA = {
  type: 'object',
  properties: {
    'paths': {
      type: 'object',
      minProperties: 1, // at least one path is required
      patternProperties: {
        '^x-': {},
        '^\\/': {
          type: 'object',
          // at least one method is required
          oneOf: [
            { required: ['get'] },
            { required: ['put'] },
            { required: ['post'] },
            { required: ['delete'] },
            { required: ['options'] },
            { required: ['head'] },
            { required: ['patch'] },
            { required: ['trace'] },
          ]
        },
      },
      additionalProperties: false,
    },
  },
  required: ['paths'],
  additionalProperties: true,
};

function isValidOpenApiSpecObject(obj: any): boolean {
  const ajv = new Ajv();

  const valid = ajv.validate(OPENAPI_VALIDATION_SCHEMA, obj);
  if (!valid) {
    console.error(ajv.errors);
  }
  return valid;
}

export const isSystem12HourFormat = () => {
  const time = new Intl.DateTimeFormat(
    'default',
    {
      hour: 'numeric',
    },
  ).format(new Date());

  return /AM|PM/i.test(time);
};

export const formatLocalTime = (value: dayjs.Dayjs) => {
  return value.toDate().toLocaleTimeString(
    [],
    { hour: '2-digit', minute: '2-digit', hour12: isSystem12HourFormat() },
  );
};

export const createCustomIcon = (icon: string, props: IconProps,) => {
  const [cursorStyle, setCursorStyle] = React.useState<'pointer' | 'default'>('pointer');

  const enableCursorPointer = () => {
    setCursorStyle('pointer');
  };

  const enableCursorDefault = () => {
    setCursorStyle('default');
  };

  /** Note: use 'sx' for <Icon> & 'style' for <img> */

  /** ATTENTION!!!!!
   * If you want to change the size of your icon use
   * style={{
   *   width: 1rem,
   *   height: 1rem
   * }} */

  return (
    <Icon
      sx={{
        cursor: cursorStyle,
        width: 'fit-content',
        height: 'fit-content',
        fontSize: 'inherit',
        ...props.sx,
      }}
      style={{ cursor: cursorStyle }}
      className={props.className}
      onMouseEnter={enableCursorPointer}
      onMouseLeave={enableCursorPointer}
      onClick={enableCursorDefault}
    >
      <img
        alt='customIcon'
        src={icon}
        style={{
          verticalAlign: 'middle',
          ...props.style,
        }}
        onClick={props.onClick}
      />
    </Icon>
  );
};

export const useComponentWillUnmount = (cleanupCallback: () => void) => {
  const initialized = React.useRef(!globalConstants.IS_DEVELOPMENT);

  // Workaround for React.StrictMode (double re-rendering)
  React.useEffect(() => {
    if (!initialized.current) {
      return () => {
        initialized.current = true;
      };
    }
    else {
      return () => {
        cleanupCallback();
      };
    }
  }, []);
};

interface OSInfoProps {
  os: globalEnums.OS;
  processor: globalEnums.Processor;
}

export const getOSInfo = (): OSInfoProps => {
  let os = globalEnums.OS.Unknown;
  let processor = globalEnums.Processor.Unknown;

  const userAgent = navigator.userAgent;

  // Check for Mac
  if (userAgent.includes('Mac OS X')) {
    os = globalEnums.OS.Mac;
    if (userAgent.includes('Intel')) {
      processor = globalEnums.Processor.Intel;
    } else if (userAgent.includes('ARM')) {
      processor = globalEnums.Processor.ARM;
    }
  } else if (userAgent.includes('Windows')) {
    os = globalEnums.OS.Windows;
    if (userAgent.includes('Win64')) {
      processor = globalEnums.Processor.x64;
    } else {
      processor = globalEnums.Processor.x86;
    }
  } else if (userAgent.includes('Linux')) {
    os = globalEnums.OS.Linux;
    if (userAgent.includes('x86_64')) {
      processor = globalEnums.Processor.x64;
    } else if (userAgent.includes('armv')) {
      processor = globalEnums.Processor.ARM;
    }
  } else if (userAgent.includes('Android')) {
    os = globalEnums.OS.Android;
    if (userAgent.includes('aarch64')) {
      processor = globalEnums.Processor.ARM64;
    } else if (userAgent.includes('arm')) {
      processor = globalEnums.Processor.ARM;
    } else if (userAgent.includes('x86_64')) {
      processor = globalEnums.Processor.x64;
    } else if (userAgent.includes('x86')) {
      processor = globalEnums.Processor.x86;
    }
  }

  return { os, processor };
};