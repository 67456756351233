import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Content,
  // ErrorText,
} from './error-page.styled';
import { Box, Button, Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const ErrorPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error') || 'unknown_error';
  const errorDescription = searchParams.get('error_description') || 'An error occurred. Please try again.';
  const errorDetails = searchParams.get('error_details') || '';
  const signup = searchParams.get('to_signup') || '';

  const showHomeButton = (error === 'access_denied');

  // clean up query params
  window.history.replaceState({}, document.title, window.location.pathname);

  return (
    <Content>
      <Box className='content-box'>
        {/* <ErrorText marginBottom='spacingM'>{error}</ErrorText> */}
        <Typography
          fontWeight='600' fontSize='3rem' lineHeight='3.5rem'
          letterSpacing='0.02275rem'
        >
          {errorDescription}
        </Typography>
        {errorDetails && (
          <Typography
            fontWeight='600' fontSize='1.25rem' lineHeight='2rem'
            color={muiPalette.grey?.[500]} mt='1rem'
          >
            {errorDetails}
          </Typography>
        )}
        <Stack direction='row'>
          {signup && (
            <Button
              variant='contained'
              className='back-button'
              href='https://www.nightvision.net/get-started'
            >
              Apply for Access
            </Button>
          )}
          {showHomeButton && (
            <Button
              variant='contained'
              className='back-button'
              href='/home'
            >
              Try again
            </Button>
          )}
          <Button
            variant={(signup || showHomeButton) ? 'outlined' : 'contained'}
            className='back-button'
            href='/?socialSignOut=1'
          >
            Back to Login
          </Button>
        </Stack>
      </Box>
    </Content>
  );
};

export default ErrorPage;
