import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Stack, Alert } from '@mui/material';
import { FormikControlMui } from '@shared/components/formik';
import { useFormikContext } from 'formik';
import { InputWithChip } from './components';
import { muiPalette } from '@shared/general-mui-theme';

export interface TargetExclusionsProps {
  targetsCount?: number;
}

export const TargetExclusions: React.FC<TargetExclusionsProps> = ({
  targetsCount = 1,
}) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Stack>
      <Accordion>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography fontWeight='500'>Exclusions</Typography>
          <Typography sx={{ opacity: '0.5' }} color={muiPalette.grey?.[500]}>&nbsp;(optional)</Typography>
        </AccordionSummary>
        {targetsCount === 1 ? (
          <AccordionDetails>
            <Stack gap='1.5rem' flexDirection='row'>
              <FormikControlMui name={'exclusions.url_patterns'} fullWidth>
                <InputWithChip
                  onAfterChange={newValue => setFieldValue('exclusions.url_patterns', newValue)}
                  tooltip='Provide URL patterns you want to exclude from the scan'
                  placeholder='Enter regex or the URLs directly'
                  label='Exclude URL patterns'
                />
              </FormikControlMui>

              <FormikControlMui name={'exclusions.xpath_patterns'} fullWidth>
                <InputWithChip
                  onAfterChange={newValue => setFieldValue('exclusions.xpath_patterns', newValue)}
                  tooltip='Provide clicks based on XPath you want to exclude from the scan'
                  placeholder='Enter XPath'
                  label='Exclude clicks based on XPath' />
              </FormikControlMui>
            </Stack>
          </AccordionDetails>
        ) : (
          <AccordionDetails>
            <Alert severity='info'>
              {'You have multiple targets. Configure each one after creation.'}
            </Alert>
          </AccordionDetails>
        )}
      </Accordion>
    </Stack>
  );
};