import { Spinner } from '@contentful/f36-components';
import { IconButton } from '@mui/material';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { globalEnums } from '@shared/duck';
import { isUndefined } from 'lodash';
import React from 'react';
import { Tooltip as LinkTooltip } from 'react-tooltip';
import { InfoCircleIcon } from 'views/components/v1/Icons';

interface EndIconProps {
  isAccessible?: boolean;
  location?: string;
  id: string;
  setOpenModal: (value: boolean) => void;
  smallIcon?: boolean;
  targetExists?: boolean;
}

const EndIcon: React.FC<EndIconProps> = ({
  isAccessible,
  location,
  id,
  setOpenModal,
  targetExists = true,
}) => {
  const tooltipId = React.useRef('cli-tooltip-' + id.replace(/[/,.: ]/g, '-'));

  return (
    <>
      {
        isUndefined(isAccessible) ? (<Spinner size='small' />) : (isAccessible ? (
          <IconButton
            size='small'
            onClick={() => window.open(location, '_blank')}
            aria-label='external site'
            sx={{
              p: 0,
              '&:hover': {
                background: 'transparent !important',
              }
            }}
          >
            <ArrowSquareOut size={20} />
          </IconButton>
        ) : (

          targetExists ? (
            <>
              <InfoCircleIcon
                id={tooltipId.current}
                variant={globalEnums.EIconVariants.NEGATIVE}
              />

              <LinkTooltip
                anchorSelect={`#${tooltipId.current}`}
                clickable
                place='bottom'
                className='tooltip'
              >
                Target URL may be Private
                <br />
                Try CLI to run the scan.&nbsp;

                <b
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpenModal(true)}
                >
                  Click here
                </b>
              </LinkTooltip>
            </>
          ) : (
            <>
              <InfoCircleIcon
                id={tooltipId.current}
                variant={globalEnums.EIconVariants.NEGATIVE}
              />
              <LinkTooltip
                anchorSelect={`#${tooltipId.current}`}
                place='bottom'
                className='tooltip'
              >
                URL may be Invalid or Private
              </LinkTooltip>
            </>)
        )
        )
      }
    </>
  );
};

export default EndIcon;