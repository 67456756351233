import React from 'react';
import { Application, ApplicationUpdate } from '@api-client';
import { MuiAutocomplete } from '@shared/components';
import { globalQueries, globalTypes } from '@shared/duck';
import { selectorsUtils } from '../duck';
import { CustomInput, CustomOption, CustomPaperComponent } from '../components';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

interface MuiApplicationSelectProps extends globalTypes.MUISelectProps<Application> {
  label?: string;
  defaultApplication?: Application;
  withoutBorders?: boolean;
  projectId?: string;
  withCreate?: boolean;
}

const MuiApplicationSelect: React.FC<MuiApplicationSelectProps> = ({
  label = 'Application',
  defaultApplication,
  projectId = '',
  withCreate = true,
  onFormChange,
  ...props
}) => {
  const [value, setValue] = React.useState<Application | ApplicationUpdate | null>(defaultApplication || null);

  React.useEffect(() => {
    setValue(defaultApplication ?? null);
  }, [defaultApplication]);

  const {
    applications = [],
    fetchNextApplicationsPage,
    applicationsHasNextPage,
    isApplicationsListLoading,
    isApplicationsFetching,
  } = globalQueries.useGetApplicationsList({
    project: projectId ? [projectId] : [],
    order: ['name'],
  });

  const isApplicationsLoading = isApplicationsListLoading || isApplicationsFetching;

  const { showModal } = useGlobalModalContext();

  const onAddNewApplication = () => {
    showModal({
      modalType: MODAL_TYPE.ApplicationModal,
      modalProps: {
        initialProject: projectId,
        enableProjectSelect: false,
        onResult(data) {
          data && setValue(data);
        },
      },
    });
  };

  React.useEffect(() => {
    if (applicationsHasNextPage && !isApplicationsFetching) {
      fetchNextApplicationsPage();
    }
  }, [applicationsHasNextPage, fetchNextApplicationsPage, isApplicationsFetching]);

  React.useEffect(() => {
    selectorsUtils.handleOnFormChange(value, onFormChange);
  }, [value]);

  return (
    <MuiAutocomplete
      id='application-autocomplete'
      options={applications}
      label={label}
      required={props.required}
      error={props.error}
      autoComplete
      autoHighlight
      loadingText='Loading...'
      loading={isApplicationsLoading}
      value={value}
      // filterSelectedOptions
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      onChange={(_, newValue: Application) => setValue(newValue)}
      renderOption={(props, option) => {
        return (
          <CustomOption {...props} option={option} />
        );
      }}
      renderInput={(params) => {
        return (
          <CustomInput
            {...params}
            onBlur={props.onBlur}
            isError={props.error}
            name={props.name}
            isLoading={isApplicationsLoading}
            placeholder='Select'
          />
        );
      }}
      PaperComponent={(props) => {
        return (
          <CustomPaperComponent
            {...props}
            onCreateClick={onAddNewApplication}
            withCreate={withCreate}
            content='+ Create new Application'
          />
        );
      }}
      getOptionKey={(option: Application) => option.id}
      getOptionLabel={(option: Application) => option.name}
      noOptionsText='No Applications found'
    />
  );
};

export default MuiApplicationSelect;