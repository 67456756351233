import { InfiniteData } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';

const getNextPageParam = (lastPage?: AxiosResponse) =>
  lastPage?.data?.next && new URL(lastPage?.data?.next).searchParams.get('page');

type PaginatedResult<D> = {
  count?: number
  results?: D[]
}

type UnPaginate<T> = T extends PaginatedResult<infer D> ? D : any;

export const getFlatResults = <T extends PaginatedResult<any>>(
  data?: InfiniteData<AxiosResponse<T>>,
  fieldName: keyof T = 'results'
): UnPaginate<T>[] => {
  return data?.pages?.map(res => {
    const x = res.data?.[fieldName];
    return x;
  })?.flat() || new Array<any>();
};

const useFlatResults = <T extends PaginatedResult<D>, D>(
  data?: InfiniteData<AxiosResponse<T>>,
  fieldName: keyof T = 'results'
) => {
  return useMemo(() => getFlatResults<T>(data, fieldName), [data]);
};

const useFlatCountFromResponse = <T extends PaginatedResult<D>, D>(
  data?: InfiniteData<AxiosResponse<T>>,
  fieldName: keyof T = 'count'
) => {
  return useMemo(() => getFlatResults<T>(data, fieldName), [data]);
};

export { getNextPageParam, useFlatResults, useFlatCountFromResponse };
