import React from 'react';
import { useDebounce } from '@shared/hooks';
import { MuiSearchInput } from '@shared/components';
import { useSearchParams } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import { ProjectsTable } from './components';
import { globalUtils } from '@shared/duck';

const ProjectsPage: React.FC = () => {
  const [debounceSearchEnabled, setDebounceSearchEnabled] = React.useState(false);
  const { debouncedValue: debouncedSearch, onChange: onSearchChange, value = '' } = useDebounce<string>();
  const [searchFilter, setSearchFilter] = React.useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { createOrEditProjectHandler: createProjectHandler } = globalUtils.useCreateOrEditProjectHandler();

  // Handle initial search param value
  React.useLayoutEffect(() => {
    onSearchChange(searchParams.get('search') || undefined);
  }, []);

  // Handle search params
  React.useEffect(() => {
    setSearchFilter(searchParams.get('search') || undefined);
  }, [searchParams]);

  React.useEffect(() => {
    // onDebounceSearchChange
    // apply debounceSearch change only after search filter was touched
    if (debounceSearchEnabled && debouncedSearch != undefined) {
      setSearchParams((urlParams) => {
        const newSearch = debouncedSearch;
        if (newSearch) {
          urlParams.set('search', newSearch);
        } else {
          urlParams.delete('search');
        }
        return urlParams;
      });
    }
  }, [debouncedSearch, debounceSearchEnabled, setSearchParams]);

  return (
    <Stack sx={{ overflow: 'hidden' }}>
      <Stack justifyContent='space-between' flexDirection='row' mb='1rem'>
        <MuiSearchInput
          controllable
          value={value}
          onChange={e => {
            setDebounceSearchEnabled(true); // allow debounce after first change
            onSearchChange(e.target.value);
          }}
        />

        <Button
          variant='contained'
          color='primary'
          sx={{
            flexShrink: 0,
          }}
          onClick={() => createProjectHandler({})}
        >
          New Project
        </Button>
      </Stack>

      <ProjectsTable filter={searchFilter} />
    </Stack>
  );
};

export default ProjectsPage;