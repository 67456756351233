import { IconProps } from '../BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from '../icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='14'
    height='18'
    viewBox='0 0 14 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <path
      d='M7 16.5C7 16.5 13 13.5 13 9V3.75L7 1.5L1 3.75V9C1 13.5 7 16.5 7 16.5Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

function NavAuthenticationIcon(props: IconProps): JSX.Element {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default NavAuthenticationIcon;
