import {
  FormControl as BaseFormControl,
} from '@contentful/f36-components';
import React from 'react';
import { StyledBaseFormControl } from './form-control-f36.styled';
import { FormControlF36Props } from '../../duck';
import { muiPalette } from '@shared/general-mui-theme';
import { Typography } from '@mui/material';

const FormControlF36: React.FC<FormControlF36Props> = (props: FormControlF36Props) => {
  const { label, subLabel, helpText, error, children, absoluteError, additionalError, ...otherProps } = props;
  return (
    <StyledBaseFormControl {...otherProps}>
      {label && (
        <BaseFormControl.Label>
          {label}
          {subLabel && (
            <Typography variant='default' color={muiPalette.grey?.[500]}>
              &nbsp;{subLabel}
            </Typography>
          )}
        </BaseFormControl.Label>
      )}
      {children}
      {helpText && <BaseFormControl.HelpText>{helpText}</BaseFormControl.HelpText>}
      {error && <BaseFormControl.ValidationMessage style={{ position: absoluteError ? 'absolute' : 'initial' }}>
        {error}
        {additionalError}
      </BaseFormControl.ValidationMessage>}
    </StyledBaseFormControl>
  );
};

export default FormControlF36;
