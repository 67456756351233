import { tableTypes, UIHttpMethodBadge } from '@shared/components';
import { globalModels } from '@shared/duck';

export const fpColumns: tableTypes.ColumnProps<globalModels.FalsePositivesModel>[] = [
  {
    title: 'Id',
    columnKey: 'kind',
  },
  {
    title: 'Kind',
    columnKey: 'kindName',
  },
  {
    title: 'Path',
    columnKey: 'path',
  },
  {
    title: 'HTTP Method',
    columnKey: 'httpMethod',
    customCellRenderer(_, item) {
      return (
        item.httpMethod && <UIHttpMethodBadge method={item.httpMethod as string} />
      );
    },
  },
  {
    title: 'Parameter Name',
    columnKey: 'parameterName',
    cellInfo: {
      hasUnbreakableTextData: true,
    },
  },
];