import React from 'react';
import { Collapse, Grid, IconButton, Stack, Typography } from '@mui/material';
import { MuiAccessibilityFilter, MuiApplicationFilter, MuiAuthenticationFilter, MuiCreatedByFilter } from './components';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { ScanFiltersContext } from '../../filters-context';
import { FilterName } from '../../duck';

export const OtherFilters: React.FC = () => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const toggleFilters = () => setExpanded(old => !old);

  const {
    filterDictionary,
  } = React.useContext(ScanFiltersContext);

  // React.useEffect(() => {
  //   if (selectedProjects.length) {
  //     setSelectedApplications?.(
  //       (oldApplications) => oldApplications.filter(app => selectedProjects.some(p => p.id === app.project)));
  //     setSelectedAuthentications?.(
  //       (oldAuthentications) => oldAuthentications.filter(auth => selectedProjects.some(p => p.id === auth.project)));
  //     setSelectedUsers?.(
  //       (oldUsers) => oldUsers.filter(user => selectedProjects.some(p => p.shared_with_users.some(u => u === user.id))));
  //   }
  // }, [selectedProjects]);

  return (
    <Stack gap={0}>
      <Stack direction='row' alignItems='center' onClick={toggleFilters}
        sx={{ cursor: 'pointer', width: 'fit-content' }}
      >
        <Typography variant='subtitle2'>
          {expanded ? 'Hide filters' : 'More filters'}
        </Typography>
        <IconButton size='small'>
          {expanded ? <CaretUp /> : <CaretDown />}
        </IconButton>
      </Stack>

      <Collapse in={expanded}
        sx={{
          '& .MuiCollapse-wrapperInner': {
            mt: '0.5rem',
          }
        }}>
        <Grid container spacing={1}>
          <Grid item lg={3} md={4} xs={6}>
            <MuiAuthenticationFilter
              // projectIds={selectedProjects.map(proj => proj.id)}
              selectedAuthentications={filterDictionary[FilterName.AUTHENTICATION]?.selected}
              setSelectedAuthentication={filterDictionary[FilterName.AUTHENTICATION]?.setSelected}
            />
          </Grid>

          <Grid item lg={3} md={4} xs={6}>
            <MuiApplicationFilter
              // projectIds={selectedProjects.map(proj => proj.id)}
              selectedApplications={filterDictionary[FilterName.APPLICATION]?.selected}
              setSelectedApplications={filterDictionary[FilterName.APPLICATION]?.setSelected}
            />
          </Grid>

          <Grid item lg={3} md={4} xs={6}>
            <MuiAccessibilityFilter
              selectedAccessibility={filterDictionary[FilterName.ACCESSIBILITY]?.selected}
              setSelectedAccessibility={filterDictionary[FilterName.ACCESSIBILITY]?.setSelected}
            />
          </Grid>

          <Grid item lg={3} md={4} xs={6}>
            <MuiCreatedByFilter
              // projectIds={selectedProjects.map(proj => proj.id)}
              selectedUsers={filterDictionary[FilterName.INITIATEDBY]?.selected}
              setSelectedUsers={filterDictionary[FilterName.INITIATEDBY]?.setSelected}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Stack >
  );
};