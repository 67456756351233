import { Theme, CardProps, CardActionAreaClasses, CardActionAreaProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const CardStyles: Partial<OverridesStyleRules<'root', 'MuiCard', Omit<Theme, 'components'>>> | undefined =
  {
    root: {
      widht: '100%',
      height: '100%',
      borderRadius: '0.375rem',
      border: '1px solid #CFD9E0', // gray.300
      '& .MuiCardHeader-root': {
        padding: '0',
      },
      '& .MuiCardContent-root': {
        padding: '0',
      },
      ':hover': {
        borderColor: '#0059C8', // blue.600
      },
      ':active': {
        borderColor: '#0059C8', // blue.600
        boxShadow: '0 0 0 3px #98CBFF',
      },
    },
  };

export const CardDefaultProps: Partial<CardProps> | undefined =
  {
    variant: 'outlined',
  };

export const CardActionAreaStyles: Partial<OverridesStyleRules<keyof CardActionAreaClasses, 'MuiCardActionArea', Omit<Theme, 'components'>>> | undefined =
  {
    root: {
      height: '100%',
      flexDirection: 'column',
      ':hover': {
        '.MuiCardActionArea-focusHighlight': {
          opacity: 0,
        },
      },
    },
  };

export const CardActionAreaDefaultProps: Partial<CardActionAreaProps> | undefined =
  {
    disableTouchRipple: true,
  };
