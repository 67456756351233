import React from 'react';
import { Scan } from '@api-client';
import { globalQueries } from '@shared/duck';
import { ErrorContext } from '@shared/duck/contexts';
import { errorResponseHandler } from 'views/utils/errorHandlers';

interface ScanDetailsApiResult {
  scan?: Scan,
  isScanLoading: boolean
}

export const useScanDetailsApi = ({
  applicationId,
  targetId,
  enabled,
}: {
  applicationId: string;
  targetId: string;
  enabled: boolean;
}): ScanDetailsApiResult => {
  const { setError } = React.useContext(ErrorContext);
  const {
    scan,
    isScanLoading,
  } = globalQueries.useGetScanByApplication(
    {
      id: applicationId,
      targetId: targetId,
    },
    {
      enabled: enabled,
      onError: err => errorResponseHandler(err, 'target', setError),
    },
  );

  return {
    scan,
    isScanLoading,
  };
};