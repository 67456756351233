import { muiPalette } from '@shared/general-mui-theme';
import _ from 'lodash';
import React from 'react';
import { FileInputContainer, FileInputText } from './file-input.styled';
import { FileIcon, UploadCloud } from '@assets';
import { globalEnums } from '@shared/duck';
import { UIAsset, MuiTextInput } from '@shared/components';
import { Box, IconButton, Link, Stack, TextFieldProps, Typography } from '@mui/material';
import { CloudArrowDown, X } from '@phosphor-icons/react';
import { enqueueSnackbar } from 'notistack';

type UIFileInputProps = {
  onAfterChange?: (file: File | null) => void;
  accept: globalEnums.EFileExtension[];
  helpText?: string;
  initFile?: File | null;
  downloadUrl?: string;
  deleteIcon?: boolean;
  fileValidation?: (file: File) => Promise<{ success: boolean, error?: string }>;
  customEndIcon?: React.ReactElement | null;
} & Omit<TextFieldProps, 'accept'>

const UIFileInput: React.FC<UIFileInputProps> = ({
  onAfterChange,
  initFile,
  accept,
  helpText,
  downloadUrl = '',
  deleteIcon = true,
  fileValidation,
  customEndIcon,
  ...props
}) => {
  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(initFile ?? null);
  const [drag, setDrag] = React.useState(false);

  React.useEffect(() => {
    setSelectedFile((oldValue) => {
      if (oldValue) {
        return !_.isEqual(oldValue, initFile) ? (
          initFile || null
        ) : oldValue;
      } else {
        return initFile || null;
      }
    });
  }, [initFile]);

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const file: File | null = (event.currentTarget as any)?.files[0] || null;
    if (!file) {
      return;
    }
    const mime = file.type;
    const ext = file.name.split('.').pop() || '';

    if (accept.filter(a => a.mime.includes(mime) || a.exts?.includes(ext)).length > 0) {
      const validationRes = await fileValidation?.(file);
      if (validationRes === undefined || validationRes.success) {
        setSelectedFile(file);
        onAfterChange?.(file);
      }
      else {
        validationRes?.error && enqueueSnackbar(validationRes?.error, { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Unsupported file type', { variant: 'error' });
    }
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };

  const acceptProp = React.useMemo(() => accept.map(a => [
    ...a.mime,
    (
      a.exts?.map(x => '.' + x) || []
    ).join(','),
  ].join(',')).join(','), [accept]);

  const onDeleteFile = () => {
    setSelectedFile(null);
    onAfterChange?.(null);
  };

  const onTextInputClick = () => fileRef.current?.click();
  return (
    selectedFile?.name ? (
      <FileInputContainer isInvalid={props.error}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' gap={{ sm: 1.5, xs: 1 }}>
          <Stack direction='row' spacing={0.5} alignItems='center'>
            <Box style={{ minWidth: 'fit-content' }}>
              <UIAsset src={FileIcon}></UIAsset>
            </Box>
            <Typography color='#5A657C'
              style={{ hyphens: 'auto' }}
            >{selectedFile?.name}</Typography>
          </Stack>
          <Stack direction='row' spacing={{ sm: 1.5, xs: 1 }}>
            {!!downloadUrl && (
              <IconButton href={downloadUrl || ''}>
                <CloudArrowDown size={20} />
              </IconButton>
            )}
            {customEndIcon}
            {deleteIcon && (
              <IconButton onClick={onDeleteFile}>
                <X size={16} />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </FileInputContainer>
    ) : (
      <FileInputContainer
        isDraggable={drag}
        isInvalid={props.error}
        onDragEnter={() => setDrag(true)}
        onDragStart={() => setDrag(true)}
        onDragOver={() => setDrag(true)}
        onDragEnd={() => setDrag(false)}
        onDrop={() => setDrag(false)}
        onDragLeave={() => setDrag(false)}
      >
        <Stack alignItems='center' gap={0.25} textAlign='center'>
          <UIAsset src={UploadCloud} />
          <FileInputText color={muiPalette.grey?.['500']}>
            <Link underline='hover' onClick={onTextInputClick}>
              Click to upload
            </Link>
            {' or drag and drop'}
          </FileInputText>
          {helpText && (
            <FileInputText
              color={muiPalette.grey?.['500']}
            >
              {helpText}
            </FileInputText>
          )}
        </Stack>
        <MuiTextInput
          type='file'
          inputRef={fileRef}
          onChange={(e) => {
            onFileChange(e);
          }}
          inputProps={{ accept: acceptProp }}
        />
      </FileInputContainer>
    )
  );
};

export default UIFileInput;