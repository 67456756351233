import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { allRoutesEnum } from '@shared/duck';
import { BorderedTabs } from './scan-details.styled';
import { ScanDetailsInfo } from './components';
import { scanDetailsHooks } from './duck';
import { Paper, Tab } from '@mui/material';

type ScanDetailsTabName = 'findings' | 'checks' | 'paths' | 'login-status' | 'logs' | 'scan-parameters' | 'reports';

interface ScanDetailsProps {
  tabId: ScanDetailsTabName;
  children: React.ReactNode;
}

const ScanDetails: React.FC<ScanDetailsProps> = ({ tabId, children }: ScanDetailsProps) => {
  const { applicationId = '', scanId: scanIdParam = '', targetId = '' } = useParams();
  const navigate = useNavigate();
  const { scan } = scanDetailsHooks.useScanDetailsApi({
    applicationId,
    targetId,
    enabled: !scanIdParam,
  });

  const [scanId, setScanId] = React.useState<string>('');

  React.useEffect(() => {
    scanIdParam && setScanId(scanIdParam);
  }, [scanIdParam]);

  React.useEffect(() => {
    scan && setScanId(scan.id);
  }, [scan]);

  const onTabChange = (tabId: ScanDetailsTabName) => {
    if (scanIdParam) {
      navigate(`${allRoutesEnum.ScanDetails.replace(':scanId', scanIdParam)}/${tabId}`, { replace: true });
    } else if (targetId) {
      navigate(`${allRoutesEnum.ApplicationScanDetails
        .replace(':applicationId', applicationId)
        .replace(':targetId', targetId)}/${tabId}`, { replace: true });
    }
  };

  return (
    <>
      {scanId && (
        <ScanDetailsInfo scanId={scanId} targetId={targetId} applicationId={applicationId} />
      )}
      <Paper variant='outlined' sx={{ display: 'flex', flexDirection: 'column', borderRadius: '0.375rem', overflow: 'hidden' }}>
        <BorderedTabs value={tabId} onChange={(_, tab) => onTabChange(tab as ScanDetailsTabName)} variant='scrollable' allowScrollButtonsMobile>
          <Tab value='findings' label='Findings' />
          <Tab value='checks' label='Security Checks' />
          <Tab value='paths' label='Paths Scanned' />
          <Tab value='login-status' label='Login Status' />
          <Tab value='logs' label='Logs' />
          <Tab value='scan-parameters' label='Scan Parameters' />
          <Tab value='reports' label='Reports' />
        </BorderedTabs>
        {children}
      </Paper>
    </>
  );
};

export default ScanDetails;
