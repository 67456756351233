import { tableTypes } from '@shared/components';
import { allRoutesEnum, globalConstants, globalModels } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import dayjs from 'dayjs';
import { getAuthWarningAlert } from '../components';
import { Typography } from '@mui/material';

export const authenticationsColumns: tableTypes.ColumnProps<globalModels.AuthenticationsViewModel>[] = [
  {
    title: 'Name',
    columnKey: 'name',
    minWidth: '21rem',
    cellInfo: {
      cellTextWeight: 600,
      description: item => item.description,
      getIcon(item) {
        return getAuthWarningAlert({ id: item.id, name: item.name });
      },
      iconPosition: 'right',
      alignItems: 'space-between',
      href(item) {
        return allRoutesEnum.AuthenticationDetails.replace(':authenticationId', item.id);
      }
    },
  },
  {
    title: 'Project',
    columnKey: 'projectName',
    minWidth: '9.0625rem',
  },
  {
    title: 'Last Used',
    columnKey: 'lastSynced',
    cellInfo: {
      cellTextColor: muiPalette.grey?.[600],
    },
    minWidth: '10.75rem',
    customCellRenderer(value) {
      return (
        <Typography variant='default' color={muiPalette.grey?.[500]}>
          {dayjs(value).format(globalConstants.FULL_MONTH_DATE_TIME)}
        </Typography>
      );
    },
  },
  {
    title: 'Targets',
    columnKey: 'noOfTargets',
    minWidth: '7.8125rem',
  },
  {
    title: 'Authentication Type',
    columnKey: 'authenticationType',
    minWidth: '9.065rem',
  },
];