import { Stack } from '@mui/material';
import React from 'react';
import { tableTypes, MuiTable } from '@shared/components';
import { allRoutesEnum, globalModels } from '@shared/duck';
import { useAuthTableApi, authColumns } from './duck';
import { AuthenticationGray } from '@assets';

interface AuthTableProps {
  targetId: string;
  closeModal: (url?: string) => void;
}

const AuthTable: React.FC<AuthTableProps> = ({
  targetId,
  closeModal,
}) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [targetId]);

  const {
    authentications,
    totalItemsCount,
    isAuthListLoading,
    fetchNextAuthPage,
    authHasNextPage,
    onEditCredentials,
  } = useAuthTableApi(
    {
      target: targetId,
      page: currentPage,
      pageSize: rowsPerPage,
    },
  );

  const authConfig: tableTypes.MuiTableProps<globalModels.AuthenticationsViewModel> = {
    data: authentications,
    columns: authColumns,
    loading: isAuthListLoading,
    isLoadMoreAvailable: authHasNextPage,
    onLoadMoreClick: fetchNextAuthPage,
    onRowClick: (item) => {
      closeModal(allRoutesEnum.AuthenticationDetails.replace(':authenticationId', item.id));
    },
    withExternalBorders: false,
    itemActions: item => (
      {
        itemList: [
          {
            title: 'Edit',
            itemAction: () => onEditCredentials(item.id),
          },
        ],
      }
    ),
    noContentMessage: 'No Authentications found',
    noContentIcon: AuthenticationGray,
    pagination: {
      count: totalItemsCount,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: (e, newPage) => handleChangePage(newPage),
      onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
    },
  };

  return (
    <Stack overflow={'auto'}>
      <MuiTable
        {...authConfig}
      />
    </Stack>
  );
};

export default AuthTable;