const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 6) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const setAvatarStyle = (avatarSize: number, fullName?: string,) => {
  return {
    sx: {
      bgcolor: fullName ? stringToColor(fullName || '') : '',
      width: avatarSize ? `${avatarSize}rem` : '',
      height: avatarSize ? `${avatarSize}rem` : '',
      fontSize: `${avatarSize * 0.5}rem`
    },
    children: fullName ? `${fullName?.split(' ')?.[0]?.[0]}${fullName?.split(' ')?.[1]?.[0]}` : undefined,
  };
};