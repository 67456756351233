import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';
import { billingNotificationEnums } from './duck';
import UILink from '@shared/components/links/link';

const getBillingNotificationColor = ({ type }: { type: billingNotificationEnums.NotificationType }) => {
  switch (type) {
    case billingNotificationEnums.NotificationType.FREE_TRIAL:
    case billingNotificationEnums.NotificationType.INCOMPLETE:
      return baseTokens.blue600;
    case billingNotificationEnums.NotificationType.FREE_TRIAL_ENDING:
    case billingNotificationEnums.NotificationType.ENDING:
      return baseTokens.orange500;
    case billingNotificationEnums.NotificationType.DUE:
      return baseTokens.red700;
    case billingNotificationEnums.NotificationType.OVERDUE:
      return baseTokens.red700;
    case billingNotificationEnums.NotificationType.PAUSED:
      return baseTokens.yellow800;
    // fallback
    case billingNotificationEnums.NotificationType.NONE:
      return baseTokens.blue600;
  }
};

const BillingNotificationStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  padding: ${baseTokens.spacingS};
  background-color: ${getBillingNotificationColor}
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;


const StyledPaymentLink = styled(UILink)`
  color: ${baseTokens.colorWhite} !important;
  font-weight: bold;
  text-decoration: unset;

  &:hover, &:focus {
    color: ${baseTokens.gray200} !important;
    text-decoration: unset;
  }
`;


export { BillingNotificationStyled, CloseButton, StyledPaymentLink };
