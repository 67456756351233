import React from 'react';
import { DarkGrayInfoIcon } from 'views/components/v1/Icons';
import { BadgeLabel } from '../../../badge';
import { Label } from '../../advanced-tab.styled';
import { useField } from 'formik';
import { globalEnums } from '@shared/duck';
import { useComponentWillUnmount } from '../../../general-tab';
import { UITextInput, FormikControl } from '@shared/components';
import { Stack, Tooltip } from '@mui/material';

interface InputWithBadgeProps {
  name: string;
  tooltip: string;
  placeholder: string;
  label: string;
}

const InputWithBadge: React.FC<InputWithBadgeProps> = ({
  name,
  tooltip,
  placeholder,
  label,
}: InputWithBadgeProps) => {
  const [field, , helpers] = useField<string[]>(name);
  const [state, setState] = React.useState<string>('');

  const updateLabels = () => {
    if (state.length > 0) {
      const updatedValue = [...field.value, state];
      helpers.setValue(updatedValue);
      setState('');
    }
  };

  const onKeyDown = React.useCallback((e: any) => {
    if (e.key === globalEnums.EKeyboardKeys.ENTER) {
      updateLabels();
    }
  }, [state]);

  useComponentWillUnmount(updateLabels);

  const onDelete = (indexToDelete: number) => {
    const value = field.value.filter((_, index) => index !== indexToDelete);
    helpers.setValue(value);
  };
  return (
    <Stack width='50%'>
      <Stack gap='0'>
        <Stack direction='row' alignItems='center'>
          <Label>{label}</Label>
          <Tooltip
            arrow
            placement='top'
            title={tooltip}
          >
            <span>
              <DarkGrayInfoIcon size='tiny' />
            </span>
          </Tooltip>
        </Stack>
        <UITextInput
          value={state}
          placeholder={placeholder}
          onChange={(e) => setState(e.target.value)}
          onBlur={updateLabels}
          onKeyDown={onKeyDown}
        />
      </Stack>
      <FormikControl name={name} style={{ marginBottom: '0.5rem' }}>
        <Stack gap='0'>
          {field.value.map((label, index) => {
            return (
              <BadgeLabel
                onDeleteLabel={() => onDelete(index)}
                label={label}
                key={`${name}-${index}`}
              />
            );
          })}
        </Stack>
      </FormikControl>
    </Stack>
  );
};

export default InputWithBadge;