import { Table, TableRowProps } from '@contentful/f36-components';
import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';
import { withSafeProps } from 'views/utils/common';

interface StyledBodyRowProps extends TableRowProps {
  bordered: boolean;
}

const StyledBodyRow = styled(withSafeProps<StyledBodyRowProps>(Table.Row, ['bordered']))`
  & td {
    border-right: ${({ bordered }) => bordered && `1px solid ${baseTokens.gray200}`};
  }

  & td:nth-last-of-type(-n + 1) {
    border-right: ${({ bordered }) => bordered && 'none'}
  }
`;

export { StyledBodyRow };