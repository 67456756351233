import { globalTypes } from '@shared/duck';
import { AuthenticationsTable, Header } from './components';
import React from 'react';
import { useDebounce } from '@shared/hooks';
import { useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

const AuthenticationsPage: React.FC = () => {
  const [debounceSearchEnabled, setDebounceSearchEnabled] = React.useState(false);
  const { debouncedValue: debouncedSearch, onChange: onSearchChange, value = '' } = useDebounce<string>();
  const [searchFilter, setSearchFilter] = React.useState<string>();
  const [selectedProjects, setSelectedProjects] = React.useState<globalTypes.UIOptionItem<string>[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const { showModal } = useGlobalModalContext();

  // Handle initial search param value
  React.useLayoutEffect(() => {
    onSearchChange(searchParams.get('search') || undefined);
  }, []);

  // Handle search params
  React.useEffect(() => {
    setSearchFilter(searchParams.get('search') || undefined);
  }, [searchParams]);

  React.useEffect(() => {
    // onDebounceSearchChange
    // apply debounceSearch change only after search filter was touched
    if (debounceSearchEnabled && debouncedSearch != undefined) {
      setSearchParams((urlParams) => {
        const newSearch = debouncedSearch;
        if (newSearch) {
          urlParams.set('search', newSearch);
        } else {
          urlParams.delete('search');
        }
        return urlParams;
      });
    }
  }, [debouncedSearch, debounceSearchEnabled, setSearchParams]);

  const onAddCredentials = () => showModal({
    modalType: MODAL_TYPE.AuthenticationModal,
    modalProps: {},
  });

  return (
    <Stack gap={1.5} sx={{ overflow: 'hidden' }}>
      <Header
        search={value}
        onSearchChange={e => {
          setDebounceSearchEnabled(true); // allow to debounce after first change
          onSearchChange(e.target.value);
        }}
        onNewAuthClick={() => onAddCredentials()}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
      />

      <AuthenticationsTable
        filter={searchFilter}
        selectedProjects={selectedProjects}
      />
    </Stack>
  );
};

export default AuthenticationsPage;