class LocalStorageManager {
  private readonly storageName: string;

  constructor(storageName: string) {
    this.storageName = storageName;
  }

  set(key: string, value: string) {
    localStorage.setItem(`${this.storageName}_${key}`, value);
  }

  get(key: string) {
    return localStorage.getItem(`${this.storageName}_${key}`);
  }
}

export default LocalStorageManager;
