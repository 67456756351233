import React from 'react';
import { CardWrapper, CustomStylesProps } from './info-card.styled';
import { globalTypes } from '@shared/duck';
import { Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

export interface InfoCardProps extends globalTypes.CommonProps {
  title?: string;
  subtitle?: string;
  action?: React.ReactNode;
  customStyles?: CustomStylesProps;
}

// TODO: Add card placeholder component

const UIInfoCard: React.FC<InfoCardProps> = ({
  title,
  subtitle,
  action,
  children,
  className,
  customStyles
}: InfoCardProps) => {
  return (
    <CardWrapper className={className} {...customStyles}>
      {title && (
        <Stack direction='row' gap='1rem' justifyContent='space-between' alignItems='center' className='card-header'>
          <div>
            <Typography variant='subtitle1' fontWeight='600'>{title}</Typography>
            {subtitle && (
              <>
                <Typography variant='default' color={muiPalette.grey?.[500]}>
                  {subtitle}
                </Typography>
              </>
            )}
          </div>
          {action}
        </Stack>
      )}
      <div className='card-body' style={{ overflowX: 'auto' }}>{children}</div>
    </CardWrapper>
  );
};

export default UIInfoCard;
