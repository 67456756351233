import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NightvisionSignupBg } from '@assets';

export const StyledGridBg = styled(Grid)`
    display: flex;
    background-image: url(${NightvisionSignupBg});
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
`;