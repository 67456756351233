import * as api from 'api-client';
import axios from 'axios';

export const ApiUrl = process.env.REACT_APP_API_URL ?? '/';

export const SsoCliCallbackUrl = ApiUrl + '/api/v1/auth/cli/social';

export const Configuration = new api.Configuration({
  basePath: ApiUrl,
  baseOptions: { withCredentials: true },
});

export const AxiosClient = axios.create();

export const CsrfHeaderName = 'X-CSRFTOKEN';

export const ReturnToCookie = 'returnTo';

export const AuthStateCookie = 'authState';
