
import React from 'react';
import { Button, Stack } from '@mui/material';
import { UIMenu } from '@shared/components';
import { allRoutesEnum, globalEnums, globalTypes } from '@shared/duck';
import { useNavigate } from 'react-router-dom';

interface NewScanButtonWrapperProps {
  itemList: globalTypes.UIMenuItemsList[];
  footerItems: globalTypes.UIMenuItemsList[];
  fullWidth?: boolean;
}

export const NewScanButtonWrapper: React.FC<NewScanButtonWrapperProps> = ({
  itemList,
  footerItems,
  fullWidth = false,
}: NewScanButtonWrapperProps) => {
  const navigate = useNavigate();
  const onNavigateToScan = React.useCallback(() => {
    navigate(allRoutesEnum.NewScan);
  }, []);

  return (
    <Stack flexDirection='row' gap={1}>
      <Button
        variant='contained'
        onClick={onNavigateToScan}
        sx={{
          lineHeight: '1.25rem',
          padding: '0.75rem 1rem',
          textWrap: 'nowrap',
        }}
        fullWidth={fullWidth}
      >
        {'New Scan'}
      </Button>

      <UIMenu
        type={globalEnums.EMenuType.COMPLEX}
        itemList={itemList}
        footerItems={footerItems}
        sx={{
          '.MuiMenu-list': {
            pt: '0.5rem',

            '& .MuiMenuItem-root': {
              p: '0.5rem 1rem',
            },

            '& .MuiDivider-root': {
              pt: '0.5rem',
            },
          },
        }}
      />
    </Stack>
  );
};