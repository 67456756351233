import { TextLink } from '@contentful/f36-components';
import { Stack, Tooltip, Typography } from '@mui/material';
import { IconProps } from '@mui/material/Icon/Icon';
import { ExtraInfoPopup } from './componets';
import React from 'react';
import { AttentionGray } from '@assets';
import { NoItemsContainer } from './no-content-placeholder.styled';
import { globalUtils } from '@shared/duck';

interface NoContentPlaceholderProps {
  iconSrc?: string;
  message?: string | React.ReactNode;
  link?: string;
  isLinkDisabled?: boolean;
  linkTooltipText?: string;
  onLinkClick?: () => void;
  width?: string;
  height?: string;
  padded?: boolean;
  customLinkText?: string;
  infoPopupProps?: {
    title: string,
    content: React.ReactNode
  };
}

const NoContentPlaceholder: React.FC<NoContentPlaceholderProps> = ({
  iconSrc = AttentionGray,
  message = '',
  link = '',
  isLinkDisabled = false,
  linkTooltipText = '',
  onLinkClick,
  width,
  height,
  padded,
  customLinkText,
  infoPopupProps,
}) => {
  const [isOpenModal, setOpenModal] = React.useState<boolean>(false);
  const IconC: React.FC<IconProps> = ({
    ...props
  }) => {
    return globalUtils.createCustomIcon(iconSrc, props);
  };

  return (
    <NoItemsContainer width={width} height={height} padded={padded}>
      <Stack className='inner-content'>
        <IconC />
        <Typography className='message'>
          {message}
          {link &&
           <>
             &nbsp;
             <Tooltip
               arrow
               placement='bottom'
               title={isLinkDisabled ? linkTooltipText || '' : ''}
             >
               <TextLink
                 className='colorMessage' onClick={onLinkClick} isDisabled={isLinkDisabled}
               >
                 {link}
               </TextLink>
             </Tooltip>
           </>
          }

          {infoPopupProps && (
            <TextLink
              style={{ marginLeft: '0.25rem' }}
              onClick={() => setOpenModal(true)}
            >
              {customLinkText || 'Learn more'}
            </TextLink>
          )}
        </Typography>
      </Stack>

      {infoPopupProps && (
        <ExtraInfoPopup
          openModal={isOpenModal}
          closeModal={() => setOpenModal(false)}
          {...infoPopupProps}
        />
      )}
    </NoItemsContainer>
  );
};

export default NoContentPlaceholder;
