import React from 'react';
import { useFormikContext } from 'formik';
import { globalQueries } from '@shared/duck';
import { useDebounce } from '@shared/hooks';
import { Spinner } from '@contentful/f36-components';
import { CreateProjectFormValues } from '@shared/duck/utils';
import { MuiTextInput } from '@shared/components';
import { InputAdornment, InputBaseComponentProps } from '@mui/material';

export interface NameInputProps extends InputBaseComponentProps {
  isInEdit?: boolean;
}

const NameInput: React.FC<NameInputProps> = ({
  isInEdit = false,
  ...innerTextInputProps
}: NameInputProps) => {
  const { values, setFieldValue } = useFormikContext<CreateProjectFormValues>();
  const { debouncedValue = '', onChange: onDebounceChange } = useDebounce<string>(values.name);

  const [initialName, setInitialName] = React.useState('');
  React.useEffect(() => {
    setInitialName(values.name || '');
  }, []);

  const { getProjectByName, isLoading } = globalQueries.useGetProjectByName();

  const testName = async () => {
    if (values.name) {
      if (isInEdit && values.name.trim() === initialName) {
        setFieldValue('exists', false);
        setFieldValue('isNameTested', true);
        return;
      }

      const res = await getProjectByName({ name: debouncedValue });
      if (res.data.count)
        setFieldValue('exists', true);
      else setFieldValue('exists', false);
      setFieldValue('isNameTested', true);
    }
  };

  React.useLayoutEffect(() => {
    if ((isInEdit && initialName) || !isInEdit)
      testName();
  }, [debouncedValue, initialName]);

  return (
    <MuiTextInput
      inputProps={innerTextInputProps}
      error={innerTextInputProps.error}
      placeholder='Enter name'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {isLoading
              ? <Spinner size='small' />
              : <></>}
          </InputAdornment>
        )
      }}

      onChange={(e) => {
        setFieldValue('isNameTested', false);
        setFieldValue('exists', false);
        onDebounceChange(e.target.value);
        innerTextInputProps.onChange?.(e);
      }} />
  );
};

export default NameInput;