import { tableTypes } from '@shared/components';
import { allRoutesEnum, globalModels } from '@shared/duck';

export const authColumns: tableTypes.ColumnProps<globalModels.AuthenticationsViewModel>[] = [
  {
    title: 'Name',
    columnKey: 'name',
    cellInfo: {
      cellTextWeight: 600,
      description(item) {
        return item.description || '';
      },
      href(item) {
        return allRoutesEnum.AuthenticationDetails.replace(':authenticationId', item.id);
      }
    },
    minWidth: '30rem',
  },
  {
    title: 'Authentication Type',
    columnKey: 'authenticationType',
    minWidth: '11rem',
  },
  {
    title: 'Targets',
    columnKey: 'noOfTargets',
    minWidth: '7rem',
  },
];