export const getCookie = (cname: string): string | undefined => {
  const cookies = `; ${document.cookie}`;
  const parts = cookies.split(`; ${cname}=`);
  const value = parts.pop();
  if (value) {
    return value.split(';').shift();
  }
  return undefined;
};

export const setCookie = (name: string, value: string, expireMinutes?: number) => {
  if (expireMinutes !== undefined) {
    const maxAge = expireMinutes * 60 * 1000;
    document.cookie = name + '=' + value + ';Max-Age=' + maxAge + ';path=/';
  }
  else {
    document.cookie = name + '=' + value + ';path=/';
  }
};

export const removeCookie = (name: string) => {
  setCookie(name, '', 0);
};
