import { Stack, Typography } from '@mui/material';
import { globalUtils } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';

interface CellTextProps {
  title: string,
  description?: string;
  titleColor?: string
  titleWeight?: string | number;
}

const CellText: React.FC<CellTextProps> = ({
  title,
  description,
  titleColor = muiPalette.grey?.[800],
  titleWeight = 400,
}) => {
  return (
    <Stack gap='0.13rem'>
      <Typography
        sx={{
          fontWeight: titleWeight,
          color: titleColor,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
        }}
      >
        {title}
      </Typography>

      {description && (
        <Typography variant='default' noWrap sx={{ opacity: 0.5 }}>
          {globalUtils.sliceTextByLimit(description, true)}
        </Typography>
      )}
    </Stack>
  );
};

export default CellText;