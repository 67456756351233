import { NightvisionFullIconC } from '@assets';
import { AppBar, Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ScanOpenapi, ScanWeb } from './components';
import React from 'react';
import { newScanPageEnums } from '../../duck';
import { useMedia } from '@shared/hooks';

interface ScanTargetDialogProps {
  open: boolean,
  onClose: () => void;
  scanType: newScanPageEnums.EScanType;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='left' ref={ref} {...props} />;
});

const ScanTargetDialog: React.FC<ScanTargetDialogProps> = ({
  open,
  onClose,
  scanType,
}) => {
  const { isSmallScreen } = useMedia();
  const [isShown, setIsShown] = React.useState(open);
  const hideDialog = () => setIsShown(false);

  return (
    <Dialog
      fullScreen
      open={isShown}
      onClose={hideDialog}
      onTransitionExited={onClose}
      TransitionComponent={Transition}
      sx={{
        '& .MuiPaper-root': {
          overflow: 'unset'
        }
      }}
    >
      <AppBar
        position='static'
        style={{ padding: isSmallScreen ? '1rem' : '1rem 5rem' }}
      >
        <NightvisionFullIconC
          style={{
            justifyContent: 'initial',
            width: '10rem',
            height: '2.5rem',
          }}
        />
      </AppBar>

      {(
        () => {
          switch (scanType) {
            case newScanPageEnums.EScanType.WEB:
              return (
                <ScanWeb
                  onCancel={hideDialog}
                />
              );
            case newScanPageEnums.EScanType.OPEN_API:
              return (
                <ScanOpenapi
                  onCancel={hideDialog}
                />
              );
          }
        }
      )()}
    </Dialog>
  );
};

export default ScanTargetDialog;