import { globalUtils } from '@shared/duck';
import React from 'react';
import { ScansPageWrapper } from './scans-page-wrapper';
import { Route, Routes } from 'react-router-dom';
import { ScanDetailsRoutes } from './scan-details';
import { allSubroutesEnum } from 'pages/all-subroutes-enum';

const ScansRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        path={allSubroutesEnum.Root}
        element={<ScansPageWrapper />}
      />
      <Route
        path={globalUtils.withSubRoutes(allSubroutesEnum.ScanDetails)}
        element={<ScanDetailsRoutes />}
      />
    </Routes>
  );
};

export default ScansRouter;