import { globalEnums } from '@shared/duck';
import { CheckCountType } from './constants';

export const getCheckChipColor = (type: CheckCountType): globalEnums.MuiBadgeColor => {
  switch (type) {
    case globalEnums.CheckStatusEnum.FAIL: {
      return globalEnums.MuiBadgeColor.ERROR;
    }
    case globalEnums.CheckStatusEnum.PASS: {
      return globalEnums.MuiBadgeColor.SUCCESS;
    }
    case globalEnums.CheckStatusEnum.DISRUPTED: {
      return globalEnums.MuiBadgeColor.WARNING;
    }
    case globalEnums.CheckStatusEnum.IN_PROGRESS: {
      return globalEnums.MuiBadgeColor.DEFAULT;
    }
    case 'total': {
      return globalEnums.MuiBadgeColor.PRIMARY;
    }
  }
};