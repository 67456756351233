import { globalTypes, globalUtils } from '@shared/duck';

export const logsToCsv = (logs: globalTypes.ScanLogItem[], useFormattedTimestamp = true): string => {
  const result: string[][] = logs.map(item => [
    useFormattedTimestamp ? item.timestampFormatted : item.timestamp.toString(),
    item.level || '',
    item.msg || '',
    item.component || '',
    item.check_id || '',
  ]);
  const str =
    'timestamp,level,log,component,check\n' +
    result
      .map(line =>
        line
          .map(globalUtils.wrapCsvInDoubleQuotesIfNeeded)
          .join(','),
      )
      .join('\n');
  return str;
};