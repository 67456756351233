import { globalQueries } from '@shared/duck';
import { mapApplicDetailsTableDtoToViewModel } from './utils';
import React from 'react';
import { ApplicDetailsTargetViewModel } from './types';
import { ErrorContext } from '@shared/duck/contexts';
import { errorResponseHandler } from 'views/utils/errorHandlers';

interface ApplicDetailsTableApiResult {
  targets: ApplicDetailsTargetViewModel[],
  totalItemsCount: number,
  isTargetsListLoading: boolean,
  targetsHasNextPage: boolean,
  fetchNextTargetsPage: () => void,
  onDeleteTargetClick?: (item: ApplicDetailsTargetViewModel) => void,
}

// todo: delete

export const useApplicDetailsTableApi = ({
  id,
  filter,
  page,
  pageSize,
}: {
  page: number;
  id: string,
  filter?: string,
  pageSize?: number;
}): ApplicDetailsTableApiResult => {
  const { setError } = React.useContext(ErrorContext);
  const {
    applicationTargetScans,
    totalItemsCount,
    isApplicationTargetScansListLoading,
    applicationTargetScansHasNextPage = false,
    fetchNextApplicationTargetScansPage,
  } = globalQueries.useGetApplicationTargetScansList({
    page: page + 1,
    id: id,
    filter: filter,
    pageSize: pageSize,
  }, {
    onError: err => errorResponseHandler(err, '', setError),
  });

  const targets = React.useMemo(
    () =>
      applicationTargetScans?.map(
        (target) => mapApplicDetailsTableDtoToViewModel(target)) || [],
    [applicationTargetScans],
  );

  // const { deleteTarget } = globalQueries.useDeleteTarget();

  /*  const onDeleteTargetSubmit = async (id: string) => {
   try {
   await deleteTarget({ id });
   } catch (error) {
   await UINotification.error('Failed to delete the target');
   }
   }; */

  return {
    targets,
    totalItemsCount,
    isTargetsListLoading: isApplicationTargetScansListLoading,
    targetsHasNextPage: applicationTargetScansHasNextPage,
    fetchNextTargetsPage: fetchNextApplicationTargetScansPage,
  };
};