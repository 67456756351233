import { allRoutesEnum, globalModels, globalQueries } from '@shared/duck';
import React from 'react';
import { mapCredentialsRefDtoToViewModel } from './utils';
import { useNavigate } from 'react-router-dom';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext, MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { enqueueSnackbar } from 'notistack';
interface AuthTableApiResult {
  authentications: globalModels.AuthenticationsViewModel[],
  totalItemsCount: number,
  isAuthListLoading: boolean,
  authHasNextPage: boolean,
  fetchNextAuthPage: () => void,
  onCredentialsRowClick: (item: globalModels.AuthenticationsViewModel) => void,
  onDeleteCredentials: (id: string, name?: string) => void,
  onEditCredentials: (authenticationId?: string) => void;
}

export const useAuthTableApi = ({
  page,
  target,
  pageSize,
}: {
  page: number;
  target: string;
  pageSize?: number;
}): AuthTableApiResult => {
  const { setError } = React.useContext(ErrorContext);
  const {
    authentications: dtoAuthentications,
    totalItemsCount,
    isAuthListLoading,
    authHasNextPage = false,
    fetchNextAuthPage,
  } = globalQueries.useGetTargetsAuthList(
    {
      page: page + 1,
      id: target,
      pageSize: pageSize,
    },
    {
      onError: err => errorResponseHandler(err, 'target', setError),
    },
  );

  const authentications = React.useMemo(
    () =>
      dtoAuthentications?.map(
        (item) => mapCredentialsRefDtoToViewModel(item)) || [],
    [dtoAuthentications],
  );

  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();

  const onCredentialsRowClick = (item: globalModels.AuthenticationsViewModel) =>
    navigate(allRoutesEnum.AuthenticationDetails.replace(':authenticationId', item.id));

  const { deleteCredentials } = globalQueries.useDeleteCredentials();
  const onDeleteCredentials = async (id: string, name?: string) => {
    const onConfirm = async () => {
      try {
        await deleteCredentials({ id });
        enqueueSnackbar(<><b>{name}</b> has been deleted successfully</>, { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(`Failed to delete ${name}`, { variant: 'error' });
      }
    };
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete Authentication?',
        onConfirm,
        name: name,
      },
    });
  };

  const onEditCredentials = (authenticationId?: string) => showModal({
    modalType: MODAL_TYPE.AuthenticationModal,
    modalProps: { authenticationId },
  });

  return {
    authentications,
    totalItemsCount,
    isAuthListLoading,
    authHasNextPage,
    fetchNextAuthPage,
    onCredentialsRowClick,
    onDeleteCredentials,
    onEditCredentials,
  };
};