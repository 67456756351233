import { globalModels, globalQueries, globalUtils } from '@shared/duck';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { enqueueSnackbar } from 'notistack'; import React from 'react';

interface AuthTableApiResult {
  authentications: globalModels.AuthenticationsViewModel[],
  totalItemsCount: number,
  isAuthenticationsListLoading: boolean,
  authenticationsHasNextPage: boolean,
  fetchNextAuthenticationsPage: () => void,
}

export const useAuthenticationsTableApi = ({
  page,
  projects,
  search,
  pageSize,
}:
  {
    page: number;
    projects?: string[];
    search?: string;
    pageSize?: number;
  }): AuthTableApiResult => {
  const {
    credentials: dtoCredentials,
    totalItemsCount,
    isCredentialsListLoading,
    credentialsHasNextPage = false,
    fetchNextCredentialsPage,
  } = globalQueries.useGetCredentialsList({
    page: page + 1,
    filter: search,
    project: projects,
    pageSize: pageSize
  });

  const authentications = React.useMemo(
    () =>
      dtoCredentials?.map(
        (item) => globalUtils.mapAuthenticationsDtoToViewModel(item)) || [],
    [dtoCredentials],
  );

  return {
    authentications,
    totalItemsCount,
    isAuthenticationsListLoading: isCredentialsListLoading,
    authenticationsHasNextPage: credentialsHasNextPage,
    fetchNextAuthenticationsPage: fetchNextCredentialsPage,
  };
};

export const useDeleteAuthenticationsHandler = () => {
  const { deleteCredentials } = globalQueries.useDeleteCredentials();
  const onDeleteAuthentication = async (id: string, name?: string) => {
    try {
      await deleteCredentials({ id });
      enqueueSnackbar(<><b>{name}</b> has been deleted successfully</>, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to delete ${name}`, { variant: 'error' });
    }
  };

  const { showModal } = useGlobalModalContext();

  const deleteAuthenticationHandler = async (authentication: globalModels.AuthenticationsViewModel) => {
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete Authentication?',
        name: authentication.name,
        onConfirm: () => onDeleteAuthentication(authentication.id, authentication.name),
      },
    });
  };

  return { deleteAuthenticationHandler };
};