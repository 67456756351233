import React from 'react';
import { PathInfo } from '@shared/duck/types';
import { SCAN_REFETCH_INTERVAL } from '../duck';
import { globalQueries } from '@shared/duck';
import { tableTypes, MuiTable, MuiSearchInput } from '@shared/components';
import { ScanStatusEnum } from 'models/Enums';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext } from '@shared/duck/contexts';
import { useDebounce, useLaunchDarklyFlags } from '@shared/hooks';
import { AttentionGray } from '@assets';
import { ColumnsProps, getColumns } from './duck';
import { Box, Stack } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

interface ScanDetailsPathsProps {
  scanId: string;
}

const ScanDetailsPaths: React.FC<ScanDetailsPathsProps> = ({
  scanId,
}) => {
  const [scanEnded, setScanEnded] = React.useState<boolean>(false);
  const { debouncedValue: debouncedSearch, onChange: onSearchChange, value = '' } = useDebounce<string>();
  const { setError } = React.useContext(ErrorContext);
  const { newPathsTableWithCodes } = useLaunchDarklyFlags();

  const { scan } = globalQueries.useGetScan({
    id: scanId,
  }, {
    onError: err => errorResponseHandler(err, 'scan', setError),
  });

  React.useEffect(() => {
    if (scan) {
      const scanInProgress = scan.status == ScanStatusEnum.IN_PROGRESS;
      setScanEnded(!scanInProgress);
    }
  }, [scan, scan?.status]);

  const refetchInterval = !scan?.ended_at ? SCAN_REFETCH_INTERVAL : undefined;

  const {
    paths = [],
    isPathsLoading,
    fetchNextPathsPage,
    pathsHasNextPage,
    pathsTotal,
  } = globalQueries.useGetScanCheckedPathsList({
    id: scanId,
    filter: debouncedSearch,
  }, {
    refetchInterval,
    onError: err => errorResponseHandler(err, 'scan', setError),
  });

  const hasMethodColumn = paths.length ? !!paths[0].http_method : true;
  const columnProps: ColumnsProps = {
    pathsTotal,
    scanEnded,
    debouncedSearch,
    newPathsTableWithCodes: newPathsTableWithCodes && hasMethodColumn,
  };

  const tableConfig: tableTypes.MuiTableProps<PathInfo> = {
    data: paths,
    columns: getColumns(columnProps),
    loading: isPathsLoading || !scanEnded,
    isLoadMoreAvailable: pathsHasNextPage,
    onLoadMoreClick: fetchNextPathsPage,
    noContentMessage: 'No Paths found',
    noContentIcon: AttentionGray,
    withExternalBorders: false,
  };

  return (
    <Stack p='0.75rem' overflow='hidden' gap='0'>
      <Stack
        sx={{
          border: `1px solid ${muiPalette.grey?.[300]}`,
          borderRadius: '0.375rem',
          height: '100%',
          gap: '0',
        }}
      >
        <Box
          sx={{
            p: '0.75rem',
            borderBottom: `1px solid ${muiPalette.grey?.[300]}`,
          }}
        >
          <MuiSearchInput
            controllable
            value={value}
            onChange={e => {
              onSearchChange(e.target.value);
            }}
          />
        </Box>

        <MuiTable {...tableConfig} />
      </Stack>
    </Stack>
  );
};

export default ScanDetailsPaths;
