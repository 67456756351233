import React from 'react';
import { Button, Link, Paper, Stack } from '@mui/material';
import { HorizontalDivider } from '@shared/components';

interface CustomPaperProps extends React.HTMLAttributes<HTMLElement> {
  onCreateClick?: () => void;
  withCreate?: boolean;
  content?: string;
}

const CustomPaper: React.FC<CustomPaperProps> = ({
  onCreateClick,
  children,
  withCreate,
  content = '+ Create new',
  ...props
}) => {
  return (
    <Paper
      {...props}
      sx={{
        '& ul': {
          maxHeight: withCreate ? '30vh' : 'initial',
        }
      }}
    >
      {children}
      {withCreate && (
        <>
          {<HorizontalDivider />}
          <Stack p={1} alignItems='flex-start' onMouseDown={e => e.preventDefault()}>
            <Button
              variant='text'
              sx={{
                padding: '0',
                lineHeight: '1.5rem',
              }}
              onMouseDown={onCreateClick}
            >
              <Link>
                {content}
              </Link>
            </Button>
          </Stack>
        </>
      )}
    </Paper>
  );
};

export default React.memo(CustomPaper);