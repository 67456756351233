import { Spinner } from '@contentful/f36-components';
import React from 'react';
import { useFormikContext } from 'formik';
import { isUndefined } from 'lodash';
import { globalEnums, globalQueries, globalTypes } from '@shared/duck';
import { EndIcon, UITextInput, UnableToRunScanModal } from '@shared/components';
import baseTokens from '@contentful/f36-tokens';
import { WebTargetsContentValues } from '../../duck';
import { useDebounce } from '@shared/hooks';

export interface UrlCellRenderProps extends globalTypes.UITextInputProps {
  index: number;
}

const UrlCellRenderForEdit: React.FC<UrlCellRenderProps> = ({
  isInvalid: invalid,
  index,
  ...innerTextInputProps
}: UrlCellRenderProps) => {
  const { values, setFieldValue } = useFormikContext<WebTargetsContentValues>();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const { debouncedValue = values.targets[index].url, onChange: onDebounceChange } = useDebounce<string>();
  const [isInvalid, setInvalid] = React.useState<boolean>(false);
  const [enabled, setEnabled] = React.useState<boolean>(false);
  const [changes] = React.useState({ count: 0 });

  React.useEffect(() => {
    setFieldValue(`targets[${index}].isAccessTested`, false);
    setEnabled(true);
  }, []);

  React.useEffect(() => {
    if (enabled && !invalid)
      isUndefined(values.targets[index].isAccessible) ?
        setInvalid(false) :
        setInvalid(!values.targets[index].isAccessible);
  }, [values.targets[index].isAccessTested]);

  const { checkPublicUrl } = globalQueries.useCheckPublicUrl();
  const testUrl = async () => {
    const onTestNewWebTarget = async (
      url: string,
    ) => {
      const currCounter = changes.count;
      const { data } = await checkPublicUrl(url);

      if (changes.count === currCounter) {
        setFieldValue(`targets[${index}].url`, data.requested_url);
        setFieldValue(`targets[${index}].isAccessible`, data.is_accessible);
        setFieldValue(`targets[${index}].isAccessTested`, true);
      }
    };

    setEnabled(true);
    if (values.targets[index].url) {
      onTestNewWebTarget(values.targets[index].url);
    }
  };

  React.useEffect(() => {
    if (!values.targets[index].isAccessTested && enabled)
      testUrl();
  }, [values.targets[index].isAccessTested]);

  React.useEffect(() => {
    if (!values.targets[index].isAccessTested && !enabled)
      testUrl();
  }, [debouncedValue]);

  return (
    <>
      <UITextInput
        {...innerTextInputProps}
        isInvalid={enabled ? invalid || isInvalid : false}
        onChange={(e) => {
          setEnabled(false);
          setFieldValue(`targets[${index}].url`, e.target.value);
          setFieldValue(`targets[${index}].isAccessTested`, false);
          setFieldValue(`targets[${index}].isAccessible`, undefined);
          innerTextInputProps.onChange?.(e);
          onDebounceChange(e.target.value);
          changes.count++;
        }}
        endIcon={enabled
          ? (innerTextInputProps.endIcon ?
            innerTextInputProps.endIcon
            : (values.targets[index].isAccessTested
              ? (
                <EndIcon
                  isAccessible={values.targets[index].isAccessible}
                  location={values.targets[index].url}
                  id={values.targets[index].name || values.targets[index].url}
                  setOpenModal={() => setOpenModal(true)}
                  targetExists={false}
                  smallIcon
                />
              ) : (<Spinner size='small' />)))
          : <></>}
        noBorders={(invalid || isInvalid) && enabled ? globalEnums.EmotionBoolean.False : globalEnums.EmotionBoolean.True}
        style={{ color: baseTokens.gray500 }}
      />
      <UnableToRunScanModal
        type={globalEnums.ETargetTypes.WEB}
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        targetName={values.targets[index]?.name || values.targets[index]?.url}
      />
    </>
  );
};

export default UrlCellRenderForEdit;

