import { Modal, ModalHeader, ModalContent } from '@shared/components';
import React from 'react';

interface PopupProps {
  openModal: boolean;
  closeModal: () => void;
  title: string;
  content: React.ReactNode;
}

const ExtraInfoPopup: React.FC<PopupProps> = ({
  openModal,
  closeModal,
  title,
  content,
}) => {
  return (
    <Modal
      isShown={openModal}
      onClose={closeModal}
      size='medium'
    >
      {() => (
        <>
          <ModalHeader onClose={closeModal} title={title} />
          <ModalContent style={{ maxHeight: '41.8125rem' }}>
            {content}
          </ModalContent>
        </>
      )}
    </Modal>
  );
};

export default ExtraInfoPopup;