import { Box, Checkbox, Stack, TableCell, TableHead, Tooltip } from '@mui/material';
import { menuTypes, tableTypes, UIMenu } from '@shared/components';
import { globalEnums } from '@shared/duck';
import React from 'react';
import { StyledMuiHeaderRow } from './table-header.styled';

interface TableHeaderProps<DataItem> extends Partial<tableTypes.BasicMuiTableProps<DataItem>> {
  columns: tableTypes.ColumnProps<DataItem>[];
  withEmptyItemActions: boolean;
  bordered?: boolean;
  headerItemActions?: menuTypes.UIMenuProps;
}

const MuiTableHeader = <DataItem, >({
  columns,
  withEmptyItemActions,
  bordered = false,
  itemSelection,
  headerItemActions,
}: TableHeaderProps<DataItem>) => {
  const selectable = Boolean(itemSelection);

  const isComplexMenu = React.useMemo(() => {
    if (headerItemActions) {
      return headerItemActions.footerItems?.length ||
             headerItemActions.headerText?.length ||
             headerItemActions.headerSubText?.length ||
             headerItemActions.sx;
    } else {
      return false;
    }
  }, [headerItemActions]);

  return (
    <TableHead>
      {/** Note: bordered - add separators between cells */}
      <StyledMuiHeaderRow bordered={bordered}>
        {selectable && (
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={itemSelection?.itemSelectionApi?.isPartiallySelected}
              checked={itemSelection?.itemSelectionApi?.isAllSelected ||
                itemSelection?.itemSelectionApi?.isPartiallySelected}
              onChange={itemSelection?.itemSelectionApi?.onToggleAll}
              inputProps={{
                'aria-label': 'select_all',
              }}
              disabled={itemSelection?.disabled}
            />
          </TableCell>
        )}

        {columns?.map((col) => {
          const headContent = col.customHeadRenderer ? col.customHeadRenderer(col) : col.title;

          return (
            <TableCell
              key={col.columnKey.toString()}
              sx={{
                verticalAlign: col.headerInfo?.verticalAlign,
                textWrap: 'nowrap',
                width: col.width,
                fontWeight: '600',
                fontSize: '0.85rem',
              }}
            >
              <Tooltip
                arrow
                key={'header-cell-tooltip' + col.columnKey.toString()}
                title={col.headerInfo?.cellTooltipText || ''}
                placement={col.headerInfo?.tooltipPlace || 'top'}
              >
                <Stack
                  gap={0.25}
                  flexDirection={'row'}
                >
                  {headContent}

                  {col.headerInfo?.icon && (
                    <Tooltip
                      arrow
                      key={'header-icon-tooltip' + col.columnKey.toString()}
                      title={col.headerInfo?.iconTooltipText || ''}
                      placement={col.headerInfo?.tooltipPlace || 'bottom'}
                    >
                      <Box display='flex' alignItems='center'>
                        {col.headerInfo.icon}
                      </Box>
                    </Tooltip>
                  )}
                </Stack>
              </Tooltip>
            </TableCell>
          );
        })}

        {headerItemActions && (
          <TableCell
            onClick={(e: any) => e.stopPropagation()}
            sx={{ textAlign: 'right' }}
          >
            <UIMenu
              type={isComplexMenu
                ? globalEnums.EMenuType.COMPLEX
                : globalEnums.EMenuType.BASIC}
              itemList={headerItemActions.itemList}
              headerText={headerItemActions.headerText}
              headerSubText={headerItemActions.headerSubText}
              footerItems={headerItemActions.footerItems}
              size='small'
              iconVariant={globalEnums.EMuiIconButtonVariants.OUTLINED}
              menuIconC={headerItemActions.menuIconC}
              sx={headerItemActions.sx}
            />
          </TableCell>
        )}

        {/* Note: add an empty title for extra column with menu btn */}
        {withEmptyItemActions && (
          <TableCell padding='checkbox' />
        )}
      </StyledMuiHeaderRow>
    </TableHead>
  );
};

export default MuiTableHeader;