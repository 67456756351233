import { Check, Scan } from '@api-client';
import { AttentionGray } from '@assets';
import { ScanStatusEnum } from 'models/Enums';
import React from 'react';
import { NoContentPlaceholder, UISeverityBadge } from '@shared/components';
import { Table, TableProps } from 'views/components/v1/Table';
import { CheckCountBadge, CheckStatusBadge, ColumnType } from './components';
import { BreakpointX } from 'views/utils/design';
import { globalEnums } from 'shared/duck';
import { Stack } from '@mui/material';

interface ScanChecksTableProps {
  scan: Scan | undefined;
  isLoading: boolean;
  checks: Check[];
  checksHasNextPage: boolean;
  fetchNextChecksPage: () => void;
}

const ScanChecksTable: React.FC<ScanChecksTableProps> = ({
  scan,
  checks,
  checksHasNextPage,
  fetchNextChecksPage,
  isLoading,
}) => {
  const scanFinished = scan?.status && scan.status !== ScanStatusEnum.IN_PROGRESS;

  const tableProps: TableProps<Check> = {
    data: checks,
    columns: [
      {
        title: 'Name',
        columnKey: 'name',
        width: '50%',
        renderer(_, item) {
          return item.name;
        },
        headRenderer(column) {
          return (
            <Stack direction='row' alignItems='center'>
              <span>{column.title}</span>
              {scan && scanFinished && (
                <CheckCountBadge type='total' size='small' label={scan.checks_count} />
              )}
            </Stack>
          );
        },
      },
      {
        title: 'Status',
        columnKey: 'status',
        width: '15rem',
        renderer(_, item) {
          return <CheckStatusBadge type={item.status} />;
        },
        headRenderer(column) {
          return (
            <Stack direction='row' alignItems='center'>
              <span>{column.title}</span>
              {scan && scanFinished && (
                <>
                  <CheckCountBadge type={globalEnums.CheckStatusEnum.FAIL} size='small' label={scan.checks_statistics?.failed} />
                  <CheckCountBadge type={globalEnums.CheckStatusEnum.DISRUPTED} size='small' label={scan.checks_statistics?.disrupted} />
                  <CheckCountBadge type={globalEnums.CheckStatusEnum.PASS} size='small' label={scan.checks_statistics?.passed} />
                </>
              )
              }
            </Stack >
          );
        },
      },
      {
        title: 'Type',
        columnKey: 'type',
        width: '20rem',
        renderer(_, item) {
          return <ColumnType item={item} />;
        },
      },
      {
        title: 'Severity',
        columnKey: 'severity',
        renderer(_, item) {
          return <UISeverityBadge title={item.severity} />;
        },
      },
    ],
    loading: isLoading,
    isLoadMoreAvailable: checksHasNextPage,
    onLoadMoreClick: fetchNextChecksPage,
    minWidth: `${BreakpointX.Laptop.max}px`,
  };

  return (
    checks.length > 0 || isLoading ? (
      <Table {...tableProps} style={{ maxHeight: '100%' }} />
    ) : (
      <NoContentPlaceholder message='No Checks found' height='18.75rem' iconSrc={AttentionGray} />
    )
  );
};

export default ScanChecksTable;
