import { UITabs } from '@shared/components';
import { globalTypes } from '@shared/duck';
import { ProfileSettings, TokensSettings } from './components';
import React from 'react';
import { settingsConsts } from './duck';

const Settings: React.FC = () => {
  const [tabsValue, setTabsValue] = React.useState<string | number>(settingsConsts.PROFILE);

  const handleTabsChange = (value: string | number) => {
    setTabsValue(value);
  };

  const tabsConfig: globalTypes.UITabsProps = React.useMemo(() => {
    return {
      tabsProps: {
        value: tabsValue,
        onChange: (e, value) => handleTabsChange(value),
        'aria-label': 'settings_tabs',
        variant: 'fullWidth',
      },
      tabs: [
        {
          label: 'Profile',
          value: settingsConsts.PROFILE,
        },
        {
          label: 'Tokens',
          value: settingsConsts.TOKENS,
        },
      ],
      tabsPanels: [
        {
          tabPanelId: settingsConsts.PROFILE,
          openTabId: tabsValue,
          children: <ProfileSettings />,
        },
        {
          tabPanelId: settingsConsts.TOKENS,
          openTabId: tabsValue,
          children: <TokensSettings />,
        },
      ],
    };
  }, [tabsValue]);

  return (
    <UITabs {...tabsConfig} />
  );
};

export default Settings;