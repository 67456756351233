import { User } from '@api-client';
import { tableTypes, UIUserAvatar } from '@shared/components';
import { globalEnums, globalUtils } from '@shared/duck';
import { InfoCircleIcon } from 'views/components/v1/Icons';
import { ApplicDetailsTargetViewModel } from './types';
import SeverityDots from '@shared/components/severity-dots';
import { Chip } from '@mui/material';

export const targetsColumns = (href: string): tableTypes.ColumnProps<ApplicDetailsTargetViewModel>[] => [
  {
    title: 'Target',
    columnKey: 'name',
    cellInfo: {
      cellTextWeight: 600,
      getIcon(item) {
        return globalUtils.getTargetTypeIcon(
          item.type.toString()?.toUpperCase() as globalEnums.ETargetType,
        );
      },
      description(item) {
        return item.location || '';
      },
      href(item) {
        return href
          .replace(':targetId', item.id);
      }
    },
  },
  {
    title: 'URL Paths',
    columnKey: 'vulnerable_paths_statistics',
    headerInfo: {
      icon:
        <InfoCircleIcon
          variant={globalEnums.EIconVariants.MUTED}
          size='small'
        />,
      iconTooltipText: 'Based on the most recent scan performed',
      tooltipPlace: 'right',
    },
    customCellRenderer(value, item) {
      return (
        <SeverityDots values={item.vulnerable_paths_statistics} popupComments='paths' />
      );
    },
  },
  {
    title: 'Accessibility',
    columnKey: 'internetAccessible',
    customCellRenderer(value) {
      const color = value === false ? 'primary' : 'secondary';
      const label = value === false ? 'Private' : 'Public';
      return (
        <Chip color={color} label={label} />
      );
    },
  },
  {
    title: 'Created By',
    columnKey: 'createdBy',
    width: '7.5625rem',
    customCellRenderer(createdBy, item, index) {
      const user = createdBy as Partial<User> | undefined;
      return (
        <UIUserAvatar
          src={user?.avatar_url}
          tooltipId={user?.id ? (
            user.id + index
          ) : index.toString()}
          tooltipText={user?.first_name + ' ' + user?.last_name}
        />
      );
    },
  },
];