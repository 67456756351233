import { IFormControlEvent } from 'views/utils/form';

export const handleOnFormChange = (
  value: any,
  onFormChange?: (e: IFormControlEvent<any | undefined>) => void,
) => {
  if (onFormChange) {
    value ?
      onFormChange({
        target: {
          id: value.id,
          name: value.name,
          value: value,
        },
      }) : onFormChange(
        {
          target: {
            id: undefined,
            name: undefined,
            value: undefined,
          },
        });
  }
};