import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';
import { Box, Stack, SxProps, TextField, TextFieldProps, Theme, Typography } from '@mui/material';


type MuiTextInputProps = {
  label?: string;
  labelSx?: SxProps<Theme>;
  ref?: React.ForwardedRef<HTMLInputElement>;
} & TextFieldProps;

const MuiTextInput: React.FC<MuiTextInputProps> = ({
  label,
  labelSx,
  ref,
  variant = 'outlined',
  ...props
}) => {
  return (
    <Box
      flexDirection='column'
    >
      {label && (
        <Stack
          sx={{ paddingBottom: '0.5rem', flexDirection: 'row' }}
        >
          <Typography
            variant={'subtitle2'}
            sx={{ ...labelSx }}
          >
            {label}
          </Typography>
          &nbsp;
          {props.required && (
            <Typography
              variant={'subtitle2'}
              color={`${props.error ? 'error' : muiPalette.grey?.['500']}`}
              sx={{ opacity: `${props.error ? '1' : '0.5'}` }}
            >
              {'(required)'}
            </Typography>
          )}
        </Stack>
      )}

      <TextField
        {...props}
        variant={variant}
        ref={ref}
      />
    </Box>
  );
};

export default MuiTextInput;
