import { MuiTable, tableTypes } from '@shared/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  authenticationTableConsts,
  authenticationTableHooks,
} from './duck';
import { allRoutesEnum, globalModels, globalTypes } from '@shared/duck';
import { AuthenticationGray } from '@assets';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

interface AuthenticationsTableProps {
  filter?: string;
  selectedProjects: globalTypes.UIOptionItem<string>[]
}

const AuthenticationsTable: React.FC<AuthenticationsTableProps> = ({
  filter,
  selectedProjects
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [filter, JSON.stringify(selectedProjects)]);

  const {
    authentications,
    totalItemsCount,
    isAuthenticationsListLoading,
    authenticationsHasNextPage,
    fetchNextAuthenticationsPage,
  } = authenticationTableHooks.useAuthenticationsTableApi({
    page: currentPage,
    search: filter,
    projects: selectedProjects.map(proj => proj.value),
    pageSize: rowsPerPage
  });

  const { showModal } = useGlobalModalContext();

  const onEditCredentials = (authenticationId?: string) => showModal({
    modalType: MODAL_TYPE.AuthenticationModal,
    modalProps: { authenticationId },
  });

  const { deleteAuthenticationHandler } = authenticationTableHooks.useDeleteAuthenticationsHandler();

  const tableConfig: tableTypes.MuiTableProps<globalModels.AuthenticationsViewModel> = {
    data: authentications,
    columns: authenticationTableConsts.authenticationsColumns,
    loading: isAuthenticationsListLoading,
    isLoadMoreAvailable: authenticationsHasNextPage,
    onLoadMoreClick: fetchNextAuthenticationsPage,
    onRowClick: (item) => (
      navigate(allRoutesEnum.AuthenticationDetails.replace(':authenticationId', item.id))
    ),
    itemActions: item => (
      {
        itemList: [
          {
            title: 'Edit',
            itemAction: () => onEditCredentials(item.id),
          },
          {
            title: 'Delete',
            itemAction: () => deleteAuthenticationHandler(item),
            isRedText: true,
          },
        ],
      }
    ),
    noContentMessage: 'No Authentications found',
    noContentIcon: AuthenticationGray,
    pagination: {
      count: totalItemsCount,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: (e, newPage) => handleChangePage(newPage),
      onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
    },
  };

  return (
    <MuiTable
      {...tableConfig}
    />
  );
};

export default AuthenticationsTable;