import React from 'react';
import { useField, useFormikContext } from 'formik';
import { globalEnums, globalQueries, globalTypes } from '@shared/duck';
import { ApiTargetsContentValues, NewOpenApiTarget } from '../../duck';
import { useDebounce } from '@shared/hooks';
import { Spinner } from '@contentful/f36-components';
import { UITextInput } from '@shared/components';

export interface NameCellRenderProps extends globalTypes.UITextInputProps {
  fieldName: string;
  project?: string;
  isInEdit: boolean;
}

const NameColumnRender: React.FC<NameCellRenderProps> = ({
  fieldName,
  project,
  isInEdit,
  ...innerTextInputProps
}: NameCellRenderProps) => {
  const [field] = useField<NewOpenApiTarget>(fieldName ?? '');
  const { setFieldValue } = useFormikContext<ApiTargetsContentValues>();
  const { debouncedValue, onChange: onDebounceChange } = useDebounce<string>();
  const [changes] = React.useState({ count: 0 });

  const [initialName, setInitialName] = React.useState('');
  React.useEffect(() => {
    setInitialName(field.value.name || '');
    field.value.name && setEnabled(true);
  }, []);

  const { getTarget, isLoading } = globalQueries.useGetTargetByName();
  const [enabled, setEnabled] = React.useState<boolean>(!isInEdit);
  const testName = async () => {
    setEnabled(true);
    if (field.value.name) {
      const currCounter = changes.count;
      try {
        if (isInEdit && field.value.name.trim() === initialName) throw new Error();

        await getTarget({ id: project || '', targetName: field.value.name.trim() });
        if (changes.count === currCounter) {
          setFieldValue(`${fieldName}.exists`, true);
          setFieldValue(`${fieldName}.isNameTested`, true);
        }
      }
      catch {
        if (changes.count === currCounter) {
          setFieldValue(`${fieldName}.exists`, false);
          setFieldValue(`${fieldName}.isNameTested`, true);
        }
      }
    }
  };

  React.useLayoutEffect(() => {
    if (!field.value.isNameTested && enabled)
      testName();
  }, []);

  React.useLayoutEffect(() => {
    if ((isInEdit && initialName) || !isInEdit)
      testName();
  }, [debouncedValue, initialName]);

  React.useLayoutEffect(() => {
    if (enabled)
      testName();
  }, [project]);

  return (
    <>
      <UITextInput
        {...innerTextInputProps}
        endIcon={enabled
          ? (!isLoading
            ? (innerTextInputProps.endIcon
              ? innerTextInputProps.endIcon
              : <></>)
            : <Spinner size='small' />)
          : <></>}
        noBorders={globalEnums.EmotionBoolean.False}
        onChange={(e) => {
          setEnabled(false);
          innerTextInputProps.onChange?.(e);
          setFieldValue(`${fieldName}.name`, e.target.value);
          setFieldValue(`${fieldName}.exists`, false);
          setFieldValue(`${fieldName}.isNameTested`, false);
          changes.count++;
          onDebounceChange(e.target.value);
        }}
      />
    </>
  );
};

export default NameColumnRender;