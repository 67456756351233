import { css } from '@emotion/react';
import { Textarea as BaseTextarea } from '@contentful/f36-components';
import styled from '@emotion/styled';
import { withSafeProps } from 'views/utils/common';
import { globalTypes } from '@shared/duck';

const noBordersStyles = ({ noBorders }: globalTypes.UITextareaProps) =>
  noBorders
    ? css`
        box-shadow: none;
        border: none;

        &:focus {
          box-shadow: none;
          border: none;
        }
      `
    : css``;

const withIconInputStyles = ({ endIcon }: globalTypes.UITextareaProps) =>
  endIcon
    ? css`
        padding-right: 3.375rem;
      `
    : css``;

const StyledTextarea = styled(withSafeProps<globalTypes.UITextareaProps>(BaseTextarea, ['noBorders']))`
  ${noBordersStyles}
`;

const TextareaContainer = styled.div`
  position: relative;
  width: 100%;

  & > textarea {
    ${withIconInputStyles}
  }

  & .icon-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 3.375rem;

    .tooltip {
      z-index: 10;
    }
  }
`;

export { StyledTextarea, TextareaContainer };
