import { Alert, Button, Dialog, DialogActions, DialogContent, Grid, Stack, Typography } from '@mui/material';
import { LocalizationProvider, renderTimeViewClock, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormikControlMui, UIDialogTitle } from '@shared/components';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { PlannerPeriodSelect } from './components';
import React from 'react';
import { plannerTypes } from './utils';
import { globalModels, globalUtils } from '@shared/duck';

interface PlannerModalProps {
  isOpen: boolean;
  onClose: () => void;
  scans: globalModels.ScansViewModelNew[];
}

const PlannerModal: React.FC<PlannerModalProps> = ({
  isOpen,
  onClose,
  scans,
}) => {
  const [time, setTime] = React.useState<string>(globalUtils.formatLocalTime(dayjs()));
  const [periodDate, setPeriodDate] = React.useState<string>();

  const initialValues = {
    period: {
      id: '1',
      label: 'Once a day',
    },
    time: globalUtils.formatLocalTime(dayjs()),
  };

  const handleClose = () => {
    onClose();
  };

  const onSubmitForm = async () => {
    handleClose();
  };

  React.useEffect(() => {
    // console.log('scans', scans);
    // set initial time & period
  }, [scans]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
    >
      <UIDialogTitle
        title='Scan Planner'
        onCloseBtnAction={handleClose}
      />

      <DialogContent
        dividers
        sx={{ paddingBottom: '2rem' }}
      >
        <Stack gap={1}>

          <Alert severity='info' sx={{ marginBottom: '1rem' }}>
            {scans.length > 1 ? (
              <>
                {'Attention! Some of the selected scans already have schedules.'}
                <br />
                {'Saving this schedule will overwrite the old ones.'}
              </>
            ) : (
              <>
                {'Attention! The selected scan already has a schedule.'}
                <br />
                {'You can either cancel the old schedule or overwrite it with a new one.'}
              </>
            )}
          </Alert>


          <Formik<plannerTypes.PlannerFormValues>
            initialValues={initialValues}
            onSubmit={onSubmitForm}
          >
            {({ setFieldValue, values }) => {

              React.useEffect(() => {
                setTime(values.time);
              }, [values.time]);

              React.useEffect(() => {
                const today = dayjs().startOf('day');

                switch (values.period.id) {
                  case '1':
                    return setPeriodDate(today.add(1, 'day').toDate().toLocaleDateString());
                  case '2':
                    return setPeriodDate(today.add(1, 'week').toDate().toLocaleDateString());
                  case '3':
                    return setPeriodDate(today.add(1, 'month').toDate().toLocaleDateString());
                }
              }, [values.period]);

              return (
                <Form>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item sm={6} xs={12}>
                      <FormikControlMui name='period' label='Period'>
                        <PlannerPeriodSelect />
                      </FormikControlMui>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <FormikControlMui name='time' label='Time'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            ampm={globalUtils.isSystem12HourFormat()}
                            defaultValue={dayjs()}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                            }}
                            closeOnSelect={false}
                            onAccept={(value) =>
                              setFieldValue('time', value ? globalUtils.formatLocalTime(value) : '')}
                          />
                        </LocalizationProvider>
                      </FormikControlMui>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>

          <Stack flexDirection='row' gap={0.25} flexWrap={'wrap'}>
            <Typography variant='body2'>
              {scans.length > 1 ?
                `The next ${scans.length} scan's will be scheduled for:`
                : 'The next scan will be scheduled for:'
              }
            </Typography>

            <Typography variant='body2' fontWeight={600}>
              {`${periodDate} at ${time}`}
            </Typography>
          </Stack>

        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack
          width='100%'
          flexDirection='row'
        >
          <Button
            variant='outlined'
            color='error'
            onClick={() => handleClose()}
          >
            {'Cancel Schedule'}
          </Button>

          <Button
            variant='contained'
            onClick={() => null} // TODO
            sx={{ marginLeft: 'auto' }}
          >
            {'Save schedule'}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default PlannerModal;