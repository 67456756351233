import baseTokens from '@contentful/f36-tokens';
import { Card, CardContent, Typography, Stack, CardActions, Button as MuiButton, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { muiPalette } from '@shared/general-mui-theme';
import {
  CardHeaderStyled,
} from './report-card.styled';
import React from 'react';

interface ReportCardPros {
  reportIcon: any;
  title: string;
  description: string;
  generateAction: () => void;
  tooltip: string;
}

const ReportCard: React.FC<ReportCardPros> = ({
  reportIcon,
  title,
  description,
  generateAction,
  tooltip,
}) => {
  return (
    <Card
      sx={{
        height: '27.5rem',
        width: '21.75rem',
        boxSizing: 'border-box',
      }}
      variant='outlined'
    >
      <CardContent
        component='div'
        sx={{
          height: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          padding: '5.5rem 2.5rem !important',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >

        <CardHeaderStyled
          avatar={
            <Avatar
              variant={'square'}
              sx={{
                bgcolor: `${baseTokens.blue100}`,
                borderRadius: '0.375rem',
                boxShadow: '0px 1px 0.5px 0px rgba(255, 255, 255, 0.50) inset, 0px -1px 0.5px 0px rgba(3, 111, 227, 0.20) inset, 0px 2px 4px 0px rgba(3, 111, 227, 0.25)',
              }}
              aria-label={'report-icon'}
            >
              {reportIcon}
            </Avatar>
          }
        >
        </CardHeaderStyled>

        <Stack
          direction='column'
          gap={1}
          alignItems='center'
        >
          <Typography
            alignItems={'center'}
            sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              lineHeight: '2rem',
              letterSpacing: '0.02375rem',
              color: `${baseTokens.colorBlack}`,
            }}
          >
            {title}
          </Typography>

          <Typography
            alignItems={'center'}
            sx={{
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.25rem',
              letterSpacing: '-0.00963rem',
              color: `${muiPalette.grey?.['500']}`,
              textAlign: 'center',
            }}
          >
            {description}
          </Typography>
        </Stack>

        <CardActions sx={{ padding: 'initial' }}>
          <Tooltip
            title={tooltip}
            key={'generate-report-tooltip'}
            placement={'top'}
            arrow
          >
            <span>
              <MuiButton
                size='medium'
                sx={{
                  padding: '0.875rem 1rem',
                  lineHeight: '1.25',
                }}
                onClick={() => generateAction()}
                disabled={!!tooltip}
              >
                Generate
              </MuiButton>
            </span>
          </Tooltip>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default ReportCard;