import { Tabs as F36Tabs } from '@contentful/f36-components';
import { Issue } from '@api-client';
import { muiPalette } from '@shared/general-mui-theme';
import { useLaunchDarklyFlags } from '@shared/hooks';
import { ShowcaseLink } from './components';
import React from 'react';
import { StyledCodeFragment } from '../../../../issue-details.styled';
import { createResponseString, formatResponseContent } from 'views/utils/formatting';
import { issueDetailsConstants, issueDetailsUtils } from '../../../../duck';
import { globalEnums } from '@shared/duck';
import { useSearchParams } from 'react-router-dom';

interface RequestItem {
  name: string;
  value: string;
}

interface ResponseProps {
  parentRef?: React.RefObject<HTMLDivElement>,
  isRaw: boolean,
  issue?: Issue,
  isFirstIssueOpen?: boolean;
}

const ResponseTab: React.FC<ResponseProps> = ({
  parentRef,
  issue,
  isRaw,
  isFirstIssueOpen = true,
}) => {
  const { vulnerableShowcase } = useLaunchDarklyFlags();

  const response = issue?.extra_info?.http_responses?.[0] || {};
  const headers = response?.headers?.map((item: RequestItem) => `${item.name}: ${item.value}`)?.join(' \n');
  const evidence = issueDetailsUtils.parseEvidence(issue?.evidence);
  const danger = issueDetailsUtils.findExtractedResult(issue?.evidence);
  const [searchParams, setSearchParams] = useSearchParams();

  const { content: body, contentType } = React.useMemo(() => {
    return formatResponseContent(
      response?.content?.text || '',
      response?.content?.mimeType || '',
      response?.content?.encoding || '',
    );
  }, [response?.content?.text, response?.content?.mimeType, response?.content?.encoding]);

  const raw = createResponseString(response, body);
  const cookies = response?.cookies?.map((s: RequestItem) => `${s.name}: ${s.value}`)?.join(' \n');

  const [currentTab, setCurrentTab] = React.useState<string>();
  const [isIssueChange, setIsIssueChange] = React.useState(true);
  const [scroll, setScroll] = React.useState(isFirstIssueOpen);
  const [oldIssue, setOldIssue] = React.useState<Issue | undefined>();

  React.useEffect(() => {
    if (oldIssue?.id !== issue?.id) {
      setOldIssue(issue);
      setIsIssueChange(true);
      isFirstIssueOpen && setScroll(true);
    }
  }, [issue]);

  const scrollProps = isIssueChange && parentRef ? {
    parentRef: parentRef,
    scrollToTop: scroll,
  } : undefined;

  const onTabChange = (e: string) => {
    if (e !== issueDetailsConstants.RESPONSE_TAB.RAW && e !== issueDetailsConstants.RESPONSE_TAB.EVIDENCE) {
      setIsIssueChange(false);
    } else {
      setIsIssueChange(true);
    }
    setSearchParams((urlParams) => {
      urlParams.set(issueDetailsConstants.SEARCH_TAB_NAME.RESPONSE_TAB, e);
      return urlParams;
    });
    setScroll(false);
  };

  React.useEffect(() => {
    setScroll(isFirstIssueOpen);
  }, [isFirstIssueOpen]);


  // RFC 3986 - compliant URI encode https://stackoverflow.com/a/64317676
  const encodeUri = (uriComponent: string) => {
    return encodeURIComponent(uriComponent || '')
      .replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
  };

  React.useEffect(() => {
    setCurrentTab(searchParams.get(issueDetailsConstants.SEARCH_TAB_NAME.RESPONSE_TAB) || issueDetailsConstants.RESPONSE_TAB.RAW);
  }, [searchParams]);

  return (
    <>
      {isRaw ? (
        <StyledCodeFragment
          code={JSON.stringify(response || {}, null, 2)}
          language={globalEnums.SupportedLangs.javascript}
        />
      ) : (
        <>
          <F36Tabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            style={{
              border: `1px solid ${muiPalette.grey?.[200]}`,
              borderRadius: '0.375rem',
            }}
          >
            <F36Tabs.List
              style={{
                alignItems: 'center',
              }}
            >
              <F36Tabs.Tab panelId={issueDetailsConstants.RESPONSE_TAB.BODY}>Body</F36Tabs.Tab>
              <F36Tabs.Tab panelId={issueDetailsConstants.RESPONSE_TAB.EVIDENCE}>Evidence</F36Tabs.Tab>
              <F36Tabs.Tab panelId={issueDetailsConstants.RESPONSE_TAB.HEADERS}>Headers</F36Tabs.Tab>
              <F36Tabs.Tab panelId={issueDetailsConstants.RESPONSE_TAB.COOKIES}>Cookies</F36Tabs.Tab>
              <F36Tabs.Tab panelId={issueDetailsConstants.RESPONSE_TAB.RAW}>Raw</F36Tabs.Tab>

              {vulnerableShowcase && <ShowcaseLink body={body} />}
            </F36Tabs.List>
            <F36Tabs.Panel id={issueDetailsConstants.RESPONSE_TAB.BODY}>
              <StyledCodeFragment code={body} language={contentType} />
            </F36Tabs.Panel>
            <F36Tabs.Panel id={issueDetailsConstants.RESPONSE_TAB.EVIDENCE}>
              <StyledCodeFragment
                {...evidence}
                scrollProps={scrollProps}
                dangerText={[issue?.payload || '']}
              />
            </F36Tabs.Panel>
            <F36Tabs.Panel id={issueDetailsConstants.RESPONSE_TAB.HEADERS}>
              <StyledCodeFragment code={headers} language={globalEnums.SupportedLangs.yaml} />
            </F36Tabs.Panel>
            <F36Tabs.Panel id={issueDetailsConstants.RESPONSE_TAB.COOKIES}>
              <StyledCodeFragment code={cookies} language={globalEnums.SupportedLangs.yaml} />
            </F36Tabs.Panel>
            <F36Tabs.Panel id={issueDetailsConstants.RESPONSE_TAB.RAW}>
              <StyledCodeFragment
                scrollProps={scrollProps}
                code={raw}
                language={globalEnums.SupportedLangs.evidence_markup}
                dangerText={[
                  issue?.payload || '',
                  encodeUri(issue?.payload || ''),
                  issue?.evidence || '',
                  encodeUri(issue?.evidence || ''),
                  ...danger || '',
                ]}
              />
            </F36Tabs.Panel>
          </F36Tabs>
        </>
      )}
    </>
  );
};

export default ResponseTab;
