import { Divider, MenuItem, styled } from '@mui/material';

export const StyledHeader = styled(MenuItem)`
  padding: 0.75rem 1rem 1rem;
  cursor: default;
  flex-direction: column;
  align-items: flex-start;
    
    &.Mui-disabled {
        opacity: 1;
    }
`;

export const StyledFooter = styled(MenuItem)`
  padding: 0.75rem 1rem;
  align-items: center;
    
    &.Mui-disabled {
        opacity: 0.5;
    }
`;

export const StyledDivider = styled(Divider)`
    &.MuiDivider-root {
        margin: initial !important;
    }
`;
