import { Button } from '@mui/material';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { UIMenu } from '@shared/components';
import { globalTypes } from '@shared/duck';
import React from 'react';

interface BasicMenuProps {
  text: string,
  itemList: globalTypes.UIMenuItemsList[];
  isDisabled?: boolean;
}

const ExportMenu: React.FC<BasicMenuProps> = ({
  text,
  itemList,
  isDisabled,
}) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <UIMenu
        itemList={itemList}
        setIsOpen={setOpen}
        disabled={isDisabled}
        menuIconC={
          <Button
            endIcon={isOpen ? <CaretUp /> : <CaretDown />}
            disabled={isDisabled}
          >
            {text}
          </Button>
        }
        sx={{
          '& > .MuiMenu-paper': {
            width: '7.5rem',
          },
        }}
      />
    </>
  );
};

export default ExportMenu;