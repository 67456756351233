import { Target } from '@api-client';
import { globalConstants, globalEnums, globalQueries, globalUtils } from '@shared/duck';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import React from 'react';
import { DownloadIconC, CopyIconC, CloudIconC, FileIconC } from '@assets';
import { StyledExtLink } from '@shared/components/links';
import { ExternalGrayLinkIcon } from 'views/components/v1/Icons';
import { MuiContentLoader } from '@shared/components';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext } from '@shared/duck/contexts';
import { Box, Chip, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

interface GeneralTabProps {
  target?: Target;
}

const GeneralTab: React.FC<GeneralTabProps> = ({
  target,
}) => {
  const { setError } = React.useContext(ErrorContext);
  const isWebScan = target?.type?.toString()?.toUpperCase() === globalEnums.ETargetType.URL;
  const enableOpenApiRequests = !isWebScan && !!target;
  const TargetIcon = globalUtils.getTargetTypeIcon(
    target?.type.toString()?.toUpperCase() as globalEnums.ETargetType,
    undefined,
    {
      height: '1.125rem',
      width: '1.125rem',
    },
  );

  const { openApiTarget, isOpenApiTargetLoading } = globalQueries.useGetOpenApiTarget({ id: target?.id || '' }, {
    enabled: enableOpenApiRequests,
    onError: err => errorResponseHandler(err, 'target', setError),
  });

  const { applications: appList, isAppListLoading } = globalQueries.useGetTargetsApplicationsList({
    id: target?.id || '',
  }, {
    onError: err => errorResponseHandler(err, 'target', setError),
  });

  const applications = appList?.map(item => item.name).join(', ');

  const { getOpenApiSpecUrl } = globalQueries.useGetOpenApiSpecUrlFromTargetMutation();

  const [isFileLoading, setIsFileLoading] = React.useState(false);

  const loadOpenApiSpecContent = async (targetId: string) => {
    let content: Blob | undefined = undefined;
    setIsFileLoading(true);
    try {
      const response = await getOpenApiSpecUrl({ id: targetId });
      if (response.status === 200 && response.data.url) {
        const res = await fetch(response.data.url);
        content = await res.blob();
      }
    }
    catch {
      enqueueSnackbar(globalConstants.FAILED_TO_READ_FILE, { variant: 'error' });
    }
    finally {
      setIsFileLoading(false);
    }
    return content;
  };


  const isUrl = Boolean(openApiTarget?.swaggerfile_url);
  const isSpecUploaded = openApiTarget?.swaggerfile_url || openApiTarget?.swaggerfile_name;

  const swaggerFileUrlName = openApiTarget?.swaggerfile_url?.substring(openApiTarget?.swaggerfile_url.lastIndexOf('/') +
                                                                       1);
  const swaggerFileName = openApiTarget?.swaggerfile_name || swaggerFileUrlName || 'openapi-swagger.yaml';
  const pureLastUpdate = openApiTarget?.last_updated_at || openApiTarget?.created_at;
  const lastUpdate = dayjs(pureLastUpdate).format(globalConstants.FULL_MONTH_DATE_TIME);

  const lastScanned = target?.last_scanned_at ? dayjs(target.last_scanned_at)
    .format(globalConstants.FULL_MONTH_DATE_TIME) : 'N/A';
  const dateCreated = dayjs(target?.created_at).format(globalConstants.FULL_MONTH_DATE_TIME);

  const chipColor = target?.internet_accessible === false ? 'primary' : 'secondary';
  const chipLabel = target?.internet_accessible === false ? 'Private' : 'Public';

  const [cachedFileContent, setCachedFileContent] = React.useState<Blob | undefined>(undefined);

  const onDownload = async () => {
    if (!openApiTarget || isWebScan) {
      return;
    }
    if (openApiTarget.swaggerfile_url) {
      // Open spec URL in new tab
      window.open(openApiTarget.swaggerfile_url, '_blank');
    } else {
      let content = cachedFileContent;
      if (!content) {
        // Download spec file
        content = await loadOpenApiSpecContent(openApiTarget.id);
      }
      if (!content) {
        enqueueSnackbar(globalConstants.FAILED_TO_READ_FILE, { variant: 'error' });
      } else {
        setCachedFileContent(content);
        saveAs(content, swaggerFileName);
      }
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(target?.id || '').then(() => {
      enqueueSnackbar('Copied to clipboard!', { variant: 'info', autoHideDuration: 1000, closeBtn: false });
    });
  };

  return (
    <Box
      width='100%'
      paddingTop='1.5rem'
    >
      <MuiContentLoader
        isLoading={isAppListLoading || (isOpenApiTargetLoading && !isWebScan)}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={0.25}
        >
          <Grid item xs={5}>
            <Typography variant='subtitle2' fontWeight={600}>
              {'Project'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='subtitle2' color='textSecondary'>
              {target?.project_name}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography variant='subtitle2' fontWeight={600}>
              {'Application(s)'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='subtitle2' color='textSecondary'>
              {applications ? applications : 'N/A'}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography variant='subtitle2' fontWeight={600}>
              {'Target Type'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Stack flexDirection='row'>
              {TargetIcon && (
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  {TargetIcon}
                </Box>
              )}
              <Typography variant='subtitle2' color='textSecondary'>
                {isWebScan ? 'Web' : 'Open API'}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={5}>
            <Typography variant='subtitle2' fontWeight={600}>
              {'Target Accessibility'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Chip color={chipColor} label={chipLabel} />
          </Grid>

          <Grid item xs={5}>
            <Typography variant='subtitle2' fontWeight={600}>
              {'Target ID'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Stack flexDirection='row' alignItems='center'>
              <Typography
                variant='subtitle2'
                color='textSecondary'
                onClick={onCopy}
                sx={{ cursor: 'pointer' }}
              >
                {target?.id}
              </Typography>
              <CopyIconC
                onClick={onCopy}
              />
            </Stack>
          </Grid>

          <Grid item xs={5}>
            <Typography variant='subtitle2' fontWeight={600}>
              {'Last Scanned'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='subtitle2' color='textSecondary'>
              {lastScanned || 'N/A'}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography variant='subtitle2' fontWeight={600}>
              {'Date Created'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='subtitle2' color='textSecondary'>
              {dateCreated}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography variant='subtitle2' fontWeight={600}>
              {'Base URL'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='subtitle2' color='textSecondary'>
              {target?.location}
            </Typography>
          </Grid>

          {!isWebScan && (
            <>
              <Grid item xs={5}>
                <Typography variant='subtitle2' fontWeight={600}>
                  {'API Specs'}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                {isSpecUploaded ? (
                  <Stack flexDirection='row'>
                    {isUrl ? <CloudIconC /> : <FileIconC />}
                    <Stack flexDirection='row'>
                      <Typography variant='subtitle2' color='textSecondary'>
                        {swaggerFileName}
                      </Typography>
                      {isFileLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <StyledExtLink
                          className='external-link'
                          icon={isUrl ? <ExternalGrayLinkIcon /> : <DownloadIconC />}
                          onClick={onDownload}
                          size='small'
                          style={{ marginLeft: 'initial' }}
                        />
                      )}
                    </Stack>
                  </Stack>
                ) : (
                  'N/A'
                )}
              </Grid>

              {isSpecUploaded && (
                <>
                  <Grid item xs={5}>
                    <Typography variant='subtitle2' fontWeight={600}>
                      {'Latest update of API Specs'}
                    </Typography>
                  </Grid>

                  <Grid item xs={7}>
                    <Typography variant='subtitle2' color='textSecondary'>
                      {lastUpdate}
                    </Typography>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </MuiContentLoader>
    </Box>
  );
};

export default GeneralTab;