import { Theme, AccordionProps, AccordionClasses, AccordionSummaryClasses, AccordionSummaryProps, AccordionDetailsClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { CaretRight } from '@phosphor-icons/react';
import { globalConstants } from '@shared/duck';

export const AccordionDefaultProps: Partial<AccordionProps> | undefined =
{
  disableGutters: true,
  elevation: 0,
  square: true,

};

export const AccordionStyles: Partial<OverridesStyleRules<keyof AccordionClasses, 'MuiAccordion', Omit<Theme, 'components'>>> | undefined =
{
  root: {
    borderTop: `1px solid ${globalConstants.INPUT_BORDER.BASE}`,
    borderBottom: `1px solid ${globalConstants.INPUT_BORDER.BASE}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  },
};

export const SummaryDefaultProps: Partial<AccordionSummaryProps> | undefined =
{
  expandIcon: <CaretRight size={16} />,
};

export const SummaryStyles: Partial<OverridesStyleRules<keyof AccordionSummaryClasses, 'MuiAccordionSummary', Omit<Theme, 'components'>>> | undefined =
{
  root: {
    flexDirection: 'row-reverse',
    padding: 0,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: '1rem',
    },
  },
};

export const DetailsStyles: Partial<OverridesStyleRules<keyof AccordionDetailsClasses, 'MuiAccordionDetails', Omit<Theme, 'components'>>> | undefined =
{
  root: {
    padding: '0.75rem 0',
    borderTop: `1px solid ${globalConstants.INPUT_BORDER.BASE}`,
  },
};