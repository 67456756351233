import { Stack, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const StyledContainer = styled(Stack)`
    flex-direction: row;
    height: 17.0625rem;
    overflow: auto;
    align-items: flex-start;
    border-radius: 0.5rem;
    border: 1px solid ${muiPalette.grey?.[300]};
    font-family: monospace;
    font-size: 0.8rem;
    line-height: 1rem;
    white-space: pre-line;

    .number {
        padding: 1rem 0.5rem;
        align-items: flex-start;
        background: ${muiPalette.grey?.[200]};
        color: ${muiPalette.grey?.[500]};
        text-align: right;
    }

    .modal-content {
        padding: 1rem;
        width: 100%;
    }

    .logs {
        color: var(--code-block-light-meta, #68747A);
        width: 100%;
    }

    .icon {
        width: 2rem;
        align-items: flex-start;
        &:hover {
            cursor: pointer;
        }
    }
`;

const StyledHeaderContainer = styled(Stack)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 1rem 0.7rem 1.5rem;
    border-radius: 0.375rem 0.375rem 0 0;
    border-bottom: 1px solid ${muiPalette.grey?.[300]};

    .header-container {
        font-weight: 600;
        font-size: 1rem;
        align-items: flex-start;
        gap: 0.5rem;
    }
`;

export { StyledContainer, StyledHeaderContainer };
