import React from 'react';
import { ErrorContext, ErrorContextTypes } from '@shared/duck/contexts';

export const NotFoundErrorWrapper: React.FC = () => {
  const { setError } = React.useContext(ErrorContext);

  setError?.({
    type: ErrorContextTypes.ErrorType.NOT_FOUND,
    objectName: 'Page not found',
  });

  return <></>;
};