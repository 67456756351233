import { useApp } from '@shared/hooks';
import { PaymentQueryKeys } from './queriesKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PaymentPlanButtonProps } from '../types';
import { globalEnums } from '@shared/duck';
import { StripePlan, StripePrice } from '@api-client';

const useGetPaymentPlansList = () => {
  const app = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [PaymentQueryKeys.paymentPlansList],
    queryFn: () => app.paymentsApi.paymentsPlansRetrieve(),
  });
  const currentPlan = app.users.plan;
  // const currentPlanStatus = app.users.plan_status;
  // const endingDate = app.users.endingDate;
  const plan: StripePlan | undefined = data?.data;
  const free_trial_period_days = plan?.free_trial_period_days;
  const paymentPlans: PaymentPlanButtonProps[] | undefined = plan?.prices.map((price: StripePrice) => {
    const isCurrent = app.checkHasSubscription() && app.users.planPrice === price.id && currentPlan !== globalEnums.SubscriptionPlan.TRIAL;
    return ({
      id: price.id,
      plan: price.plan,
      priceId: price.id,
      name: price.name,
      monthly: price.unit_amount_monthly,
      monthlySuffix: 'monthly',
      yearly: price.unit_amount_yearly + ' yearly',
      customButtonText: undefined,
      includes: price.features,
      order: price.order,
      isCurrent: isCurrent,
      isBest: price.recurring_interval === 'year',
      disabled: false,
    } as PaymentPlanButtonProps);
  });

  paymentPlans?.sort((a, b) => a.order < b.order ? -1 : 1);

  // if (monthlySubscription && currentPlan === globalEnums.SubscriptionPlan.TRIAL) {
  //   monthlySubscription.customButtonText = 'Starts ' + endingDate;
  // }

  // Add Free Trial card
  if (currentPlan === globalEnums.SubscriptionPlan.NONE || currentPlan === globalEnums.SubscriptionPlan.TRIAL) {
    paymentPlans?.unshift({
      id: 'free',
      plan: globalEnums.SubscriptionPlan.TRIAL,
      name: 'Trial',
      priceId: undefined,
      monthly: 'Free',
      monthlySuffix: undefined,
      yearly: `${free_trial_period_days} days, no commitment`,
      customButtonText: 'Free Trial',
      includes: [
        'No Card Required',
        'All Included',
      ],
      order: '00',
      isCurrent: currentPlan === globalEnums.SubscriptionPlan.TRIAL,
      disabled: currentPlan !== globalEnums.SubscriptionPlan.NONE,
    });
  }

  // // Add Contact Sales card
  // paymentPlans?.push({
  //   id: 'custom',
  //   name: 'Enterprise',
  //   description: 'support@nightvision.net',
  //   cost: 'Custom',
  //   includes: [
  //     'Custom Web Targets',
  //     'Custom API Targets',
  //     'Unlimited Projects',
  //     'Project Sharing',
  //     'Custom Offer',
  //   ],
  //   order: '9999', // last card
  //   disabled: false,
  //   type: globalEnums.SubscriptionPlan.ENTERPRISE,
  //   customButtonText: 'Contact Sales',
  // });

  return {
    paymentPlans,
    isPaymentPlansListLoading: isLoading,
  };
};

const useCancelSubscription = () => {
  const { paymentsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () => paymentsApi.paymentsCancelSubscriptionCreate(),
    onSuccess: () => queryClient.resetQueries({ queryKey: [PaymentQueryKeys.paymentPlansList] }),
  });

  return {
    cancelSubscription: mutateAsync,
    isLoading: isLoading,
  };
};

export { useGetPaymentPlansList, useCancelSubscription };
