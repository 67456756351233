import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { StyledBadge } from './badge.styled';
import { globalTypes } from '@shared/duck';

const UIBadge: React.FC<globalTypes.UIBadgeProps> = ({
  variant,
  text,
  sx,
}) => {
  const textElementRef = React.useRef<HTMLSpanElement>(null);
  const [isOverflowed, setIsOverflow] = React.useState(false);
  React.useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
  }, [textElementRef.current]);

  return (
    <Tooltip
      arrow
      placement='top'
      disableInteractive
      title={isOverflowed ? text : ''}
    >
      <StyledBadge variant={variant}>
        <Typography
          ref={textElementRef}
          noWrap
          maxWidth={'6rem'}
          sx={sx}
        >
          {text}
        </Typography>
      </StyledBadge>
    </Tooltip>

  );
};

export default UIBadge;