import React from 'react';
import { InputAdornment, Stack, IconButton, AutocompleteChangeReason } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { X, CalendarDots } from '@phosphor-icons/react';
import { MuiTextInput } from '@shared/components';
import { muiPalette } from '@shared/general-mui-theme';
import { Dayjs } from 'dayjs';

interface DateFilterProps {
  name: string;
  date: Dayjs | null;
  setDate?: (newValues: Dayjs | null, options?: Dayjs | null | undefined, action?: AutocompleteChangeReason | undefined) => void;
  maxDate?: Dayjs | undefined;
  minDate?: Dayjs | undefined;
}

export const DateFilter: React.FC<DateFilterProps> = ({
  name,
  date,
  setDate,
  maxDate,
  minDate,
}: DateFilterProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <Stack
      sx={{
        '& > .MuiFormControl-root': {
          position: 'absolute',
        },
      }}
      width='100%'
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format={'DD MMM YYYY'}
          value={date}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          onChange={(value) => setDate?.(value, value, value ? 'selectOption' : 'removeOption')}
          disableFuture
          maxDate={maxDate}
          minDate={minDate}
          slotProps={{
            textField: {
              sx: {
                maxWidth: 0,
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                }
              },
              InputProps: { endAdornment: null },
            },
          }}
        />
      </LocalizationProvider>
      <MuiTextInput
        value={date ? date.format('DD MMM YYYY') : name}
        onClick={() => {
          setOpen(true);
        }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position='end' sx={{ gap: '0.2rem' }}>
              {date && <IconButton
                onClick={(e) => { setDate?.(null, date, 'removeOption'); e.stopPropagation(); }}
                size='small'
              >
                <X size={16} />
              </IconButton>}
              <IconButton size='small' sx={{ mr: '-0.5rem' }}>
                <CalendarDots size='20' />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiInputBase-root': {
            color: muiPalette.grey?.[500],
          }
        }}
      />
    </Stack>
  );
};