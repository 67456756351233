import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppModelProvider } from './provider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

export const API_QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 0, // default: 3
    },
  },
});

const AppContext = ({ children }: { children: React.ReactNode }) => (
  <BrowserRouter>
    <AppModelProvider>
      <QueryClientProvider client={API_QUERY_CLIENT}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AppModelProvider>
  </BrowserRouter>
);

export default AppContext;
