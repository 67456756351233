import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';
import { globalEnums } from '@shared/duck';
import { css } from '@emotion/react';

const StyledOption = styled.div<{ isSelected?: boolean }>`
  padding: ${baseTokens.spacingS} ${baseTokens.spacingM};
  ${({ isSelected = globalEnums.EmotionBoolean.False }) =>
    isSelected
      ? css`
          & label {
            font-weight: ${baseTokens.fontWeightDemiBold};
          }

          background: ${baseTokens.gray100};
        `
      : css``};

  &:hover {
    cursor: pointer;
    background: ${baseTokens.gray100};
  }
`;

export { StyledOption };
