import { styled } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';
import { GridItem } from '@contentful/f36-components';

const StyledGridItem = styled(GridItem)`
  padding-top: 0.75rem; 

  & .remove-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.125rem 0.25rem;
  }

  & .add-button > span {
    color: ${baseTokens.blue600};
  }
`;

export { StyledGridItem };
