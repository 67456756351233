import { css } from '@emotion/react';
import { TextInput as BaseTextInput } from '@contentful/f36-components';
import styled from '@emotion/styled';
import { withSafeProps } from 'views/utils/common';
import { globalTypes } from '@shared/duck';

const noBordersStyles = ({ noBorders }: globalTypes.UITextInputProps) =>
  noBorders
    ? css`
        box-shadow: none;
        border: none;

        &:focus {
          box-shadow: none;
          border: none;
        }
      `
    : css``;

const withEndIconInputStyles = ({ endIcon }: globalTypes.UITextInputProps) =>
  endIcon
    ? css`
        padding-right: 2.3rem;
      `
    : css``;

const withStartIconInputStyles = ({ startIcon }: globalTypes.UITextInputProps) =>
  startIcon
    ? css`
        padding-left: 2.5rem;
      `
    : css``;

const StyledTextInput = styled(withSafeProps<globalTypes.UITextInputProps>(BaseTextInput, ['noBorders', 'startIcon', 'endIcon']))`
  ${noBordersStyles}
`;

const TextInputContainer = styled.div`
  position: relative;
  width: 100%;

  & > input {
    ${withStartIconInputStyles}
    ${withEndIconInputStyles}
  }

  & .end-icon-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0.75rem;

    .tooltip {
      z-index: 10;
    }

    :hover{
      cursor: pointer;
    }
  }

  & .start-icon-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0.75rem;

    .tooltip {
      z-index: 10;
    }
  }
`;

export { StyledTextInput, TextInputContainer };
