import { styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';

interface IconButtonProps extends MuiIconButtonProps {
  isSideBarCollapsed?: boolean;
}

const IconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) => prop !== 'isSideBarCollapsed'
}
)<IconButtonProps>(
  ({ isSideBarCollapsed }) => ({
    fontSize: '0.875rem',
    width: '100%',
    overflow: 'hidden',
    height: '2rem',
    justifyContent: 'center',

    backgroundColor: muiPalette.blue?.[700],
    borderColor: muiPalette.blue?.[700],

    '&:hover': {
      backgroundColor: muiPalette.blue?.[800],
      borderColor: muiPalette.blue?.[800],
    },

    '& svg': {
      flexShrink: 0,
      marginRight: isSideBarCollapsed ? '0' : '0.25rem'
    },

    '& .MuiTypography-root': {
      opacity: isSideBarCollapsed ? '0' : '1',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: 'white',
    },
  }));

export { IconButton };