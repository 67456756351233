import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TableRowProps } from '@mui/material/TableRow/TableRow';
import { muiPalette } from '@shared/general-mui-theme';
import { withSafeProps } from 'views/utils/common';

interface StyledMuiBodyRowProps extends TableRowProps {
  bordered: boolean;
  disabled: boolean
}

export const StyledMuiBodyRow = styled(withSafeProps<StyledMuiBodyRowProps>(TableRow, ['bordered']))`
    & td {
        border-right: ${({ bordered }) => bordered && `1px solid ${muiPalette.grey?.[200]}`};
        opacity: ${({ disabled }) => disabled && 0.5};
    }

    & td:nth-last-of-type(-n + 1) {
        border-right: ${({ bordered }) => bordered && 'none'};
        opacity: ${({ disabled }) => disabled && 1};
    }
`;