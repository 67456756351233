import React from 'react';
import { MuiSearchInput, ProjectFilterWrapper } from '@shared/components';
import { useMedia } from '@shared/hooks';
import { Box, Button, Stack } from '@mui/material';
import { globalTypes } from '@shared/duck';

interface HeaderProps {
  search: string;
  onSearchChange: (e: any) => void;
  onNewTargetClick: () => void;
  selectedProjects: globalTypes.UIOptionItem<string>[];
  setSelectedProjects: React.Dispatch<React.SetStateAction<globalTypes.UIOptionItem<string>[]>>;
}

export const Header: React.FC<HeaderProps> = ({
  search,
  onSearchChange,
  onNewTargetClick,
  selectedProjects,
  setSelectedProjects,
}) => {
  const { isSxScreen } = useMedia();

  const searchInput = (
    <MuiSearchInput
      controllable
      value={search}
      width='20rem'
      onChange={onSearchChange}
    />
  );

  const newTargetButton = (
    <Button
      variant='contained'
      onClick={onNewTargetClick}
      sx={{
        textWrap: 'nowrap',
        lineHeight: '1.25rem',
        padding: '0.75rem 1rem',
        flexShrink: '0',
      }}
      fullWidth={isSxScreen}
    >
      New Target
    </Button>
  );

  const projectFilter = (
    <ProjectFilterWrapper
      selectedProjects={selectedProjects}
      setSelectedProjects={setSelectedProjects}
    />
  );

  return (
    <Stack gap={1.5}>
      {!isSxScreen ? (
        <Stack justifyContent='space-between' flexDirection={'row'}>
          {searchInput}
          <Stack gap={1.5} flexDirection={'row'} justifyContent='flex-end' width='100%'>
            <Box sx={{ minWidth: '15rem', maxWidth: '20rem', width: '100%' }}>
              {projectFilter}
            </Box>
            {newTargetButton}
          </Stack>
        </Stack>
      ) : (
        <Stack gap={1.5}>
          {newTargetButton}
          <Stack direction='row'>
            <Box sx={{ width: '50%' }}>
              {searchInput}
            </Box>
            <Box sx={{ width: '50%' }}>
              {projectFilter}
            </Box>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};