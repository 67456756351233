import { ButtonProps, Interpolation, Theme, ButtonClasses, ToggleButtonProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

declare module '@mui/material/Button' {

  interface ButtonPropsColorOverrides {
    transparent: true,
  }
}

export const ButtonDefaultProps: Partial<ButtonProps> | undefined =
{
  disableTouchRipple: true,
  variant: 'outlined',
};

export const ButtonStyles: Partial<OverridesStyleRules<keyof ButtonClasses, 'MuiButton', Omit<Theme, 'components'>>> | undefined =
{
  root: {
    borderRadius: '0.4rem',
    textTransform: 'none',
    whiteSpace: 'nowrap',

    '&.Mui-disabled': {
      borderColor: '#CFD9E0',
    },
  },
  text: {
    ':hover': {
      background: 'transparent',
    },
  },
};

export const ButtonVariants: {
  props: Partial<ButtonProps>;
  style: Interpolation<{
    theme: Omit<Theme, 'components'>;
  }>;
}[] | undefined = [
  {
    props: { variant: 'contained', color: 'primary' },
    style: {
      background: '#036FE3',
      color: '#FFFFFF',

      '&:hover': {
        backgroundColor: '#0059C8',
      },
    },
  },
  {
    props: { variant: 'outlined' },
    style: {
      background: '#FFFFFF',
      border: '1px solid #CFD9E0',
      color: '#111B2B',

      '&:hover': {
        backgroundColor: '#F7F9FA',
        borderColor: '#CFD9E0',
      },
    },
  },
  {
    props: { variant: 'contained', color: 'success' },
    style: {
      background: '#008539',

      ':hover': {
        background: '#0c720c',
      },
    },
  },
  {
    props: { variant: 'outlined', color: 'success' },
    style: {
      background: '#FFFFFF',
      border: '1px solid #008539',
      color: '#008539',

      ':hover': {
        background: '#FFFFFF',
        border: '1px solid #0c720c',
        color: '#125812',
      },
    },
  },
  {
    props: { variant: 'contained', color: 'error' },
    style: {
      background: '#BD002A',
      boxShadow: 'inset 0 0 0 1px #BD002A',
      color: '#FFFFFF',

      ':hover': {
        background: '#990017',
        boxShadow: 'inset 0 0 0 1px #BD002A',
      },
    },
  },
  {
    props: { variant: 'outlined', color: 'error' },
    style: {
      background: '#FFFFFF',
      border: '1px solid #BD002A',
      color: '#BD002A',

      ':hover': {
        background: '#FFFFFF',
        border: '1px solid #DE1D1D',
        color: '#DE1D1D',
      },
    },
  }
];

export const ToggleButtonDefaultProps: Partial<ToggleButtonProps> | undefined =
{
  disableTouchRipple: true,
  color: 'primary',
};

export const ToggleButtonStyles: Partial<OverridesStyleRules<keyof ButtonClasses, 'MuiToggleButton', Omit<Theme, 'components'>>> | undefined =
{
  root: {
    borderRadius: '0.375rem',
    textTransform: 'none',
    lineHeight: '1.25rem',
    color: grey[900],
    padding: '0.7rem 1rem',
  },
};

export const ToggleButtonVariants:
  {
    props: Partial<ToggleButtonProps>;
    style: Interpolation<{
      theme: Omit<Theme, 'components'>;
    }>;
  }[] | undefined = [
    {
      props: { color: 'primary' },
      style: {

        '&.Mui-selected': {
          border: '1px solid #0059C8 !important',
          color: '#0041AB',
        },
      }
    }
  ];