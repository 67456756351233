import { Tabs } from '@contentful/f36-components';
import { Issue } from '@api-client';
import { isEmpty } from 'lodash';
import React from 'react';
import { createRequestString, formatPostDataParams } from 'views/utils/formatting';
import { StyledCodeFragment } from '../../../../issue-details.styled';
import { issueDetailsConstants, issueDetailsUtils } from '../../../../duck';
import baseTokens from '@contentful/f36-tokens';
import { globalEnums } from '@shared/duck';
import { useSearchParams } from 'react-router-dom';

interface RequestItem {
  name: string;
  value: string;
}

interface RequestProps {
  isRaw: boolean,
  issue?: Issue,
}

const RequestTab: React.FC<RequestProps> = ({ issue, isRaw }) => {
  const request = React.useMemo(() => issue?.extra_info?.http_requests?.[0] || {}, [issue?.extra_info?.http_requests]);
  const headers = React.useMemo(() => {
    return request?.headers?.map((item: RequestItem) => {
      return item.value ? `${item.name}: ${item.value}` : '';
    })?.filter((el: string) => !isEmpty(el)).join(' \n');
  }, [request?.headers]);
  const evidence = React.useMemo(() => issueDetailsUtils.parseEvidence(issue?.evidence), [issue?.evidence]);
  const queryString = React.useMemo(() => {
    return request?.queryString?.map((s: RequestItem) => `${s.name}: ${s.value}`)?.join(' \n');
  }, [request?.queryString]);
  const cookies = React.useMemo(
    () => request?.cookies?.map((s: RequestItem) => `${s.name}: ${s.value}`)?.join(' \n'),
    [request?.cookies],
  );
  const rawRequest = React.useMemo(() => createRequestString(request), [request]);
  const isGetRequest = issue?.http_method === globalEnums.HttpMethodEnum.GET;

  const methodsWithBody = [
    globalEnums.HttpMethodEnum.POST,
    globalEnums.HttpMethodEnum.PUT,
    globalEnums.HttpMethodEnum.DELETE,
    globalEnums.HttpMethodEnum.PATCH,
  ];
  const isRequestHasBody = methodsWithBody.includes(issue?.http_method as globalEnums.HttpMethodEnum);

  const { content: postDataParams, contentType } = React.useMemo(() => {
    return formatPostDataParams(
      request?.postData?.text || '',
      request?.postData?.params || [],
      request?.postData?.mimeType || '',
    );
  }, [request?.postData?.params, request?.postData?.mimeType]);

  const [currentTab, setCurrentTab] = React.useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();

  const onTabChange = (nextState: string) => {
    setSearchParams((urlParams) => {
      urlParams.set(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB, nextState);
      return urlParams;
    });
  };

  React.useEffect(() => {
    setCurrentTab(searchParams.get(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB) || issueDetailsConstants.REQUEST_TAB.RAW);
  }, [searchParams]);

  return (
    <>
      {isRaw ? (
        <StyledCodeFragment
          code={JSON.stringify(request || {}, null, 2)}
          language={globalEnums.SupportedLangs.javascript}
        />
      ) : (
        <>
          <Tabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            style={{ border: `1px solid ${baseTokens.gray200}`, borderRadius: '0.375rem' }}>
            <Tabs.List>
              <Tabs.Tab panelId={issueDetailsConstants.REQUEST_TAB.HEADERS}>Headers</Tabs.Tab>
              <Tabs.Tab panelId={issueDetailsConstants.REQUEST_TAB.EVIDENCE}>Evidence</Tabs.Tab>
              {isGetRequest ? <Tabs.Tab panelId={issueDetailsConstants.REQUEST_TAB.QUERYSTRING}>Query string</Tabs.Tab> : null}
              {isRequestHasBody ? <Tabs.Tab panelId={issueDetailsConstants.REQUEST_TAB.BODY}>Request Body</Tabs.Tab> : null}
              <Tabs.Tab panelId={issueDetailsConstants.REQUEST_TAB.COOKIES}>Cookies</Tabs.Tab>
              <Tabs.Tab panelId={issueDetailsConstants.REQUEST_TAB.RAW}>Raw</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel id={issueDetailsConstants.REQUEST_TAB.HEADERS}>
              <StyledCodeFragment code={headers} language={globalEnums.SupportedLangs.evidence_markup} />
            </Tabs.Panel>
            <Tabs.Panel id={issueDetailsConstants.REQUEST_TAB.EVIDENCE}>
              <StyledCodeFragment {...evidence} />
            </Tabs.Panel>
            <Tabs.Panel id={issueDetailsConstants.REQUEST_TAB.QUERYSTRING}>
              <StyledCodeFragment code={queryString} language={globalEnums.SupportedLangs.evidence_markup} />
            </Tabs.Panel>
            <Tabs.Panel id={issueDetailsConstants.REQUEST_TAB.BODY}>
              <StyledCodeFragment code={postDataParams} language={contentType} />
            </Tabs.Panel>
            <Tabs.Panel id={issueDetailsConstants.REQUEST_TAB.COOKIES}>
              <StyledCodeFragment code={cookies} language={globalEnums.SupportedLangs.evidence_markup} />
            </Tabs.Panel>
            <Tabs.Panel id={issueDetailsConstants.REQUEST_TAB.RAW}>
              <StyledCodeFragment code={rawRequest} language={globalEnums.SupportedLangs.evidence_markup} />
            </Tabs.Panel>
          </Tabs>
        </>
      )}
    </>
  );
};

export default RequestTab;
