import styled from '@emotion/styled';

const LayoutContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 4.5rem 1fr;
  grid-template-areas:
  "sidebar notification"
  "sidebar navbar"
  "sidebar content";

  .sidebar {
    grid-area: sidebar;
  }

  .navbar {
    grid-area: navbar;
  }

  .notification {
    grid-area: notification;
  }

  .content {
    grid-area: content;
    overflow: auto;
  }
`;

export { LayoutContainer };