import { User } from '@api-client';
import { tableTypes } from '@shared/components';
import { globalEnums } from '@shared/duck';
import { StyledTableCell } from './table-cell.styled';
import { CellLabels, CellText, CustomCell } from './components';
import { get } from 'lodash';
import { ToLinkOrNotToLink } from './components/custom-cell/custom-cell';
import { Tooltip, Stack } from '@mui/material';

interface ItemCommonProps {
  name?: string;
  labels?: Array<string>;
  createdBy?: User;
  isInProgress?: globalEnums.EmotionBoolean;
}

type alignProps = 'center' | 'left' | 'right';

const UITableCell = <DataItem,>({
  dataItem,
  column,
  index,
}: {
  dataItem: DataItem
  column: tableTypes.ColumnProps<DataItem>
  index: number
}) => {
  const cellValue = get(dataItem, column.columnKey);
  const dataItemProps = dataItem as ItemCommonProps;
  const Icon = column.cellInfo?.getIcon?.(dataItem) || column.cellInfo?.icon;

  if (column.customCellRenderer && cellValue !== null) {
    return (
      <CustomCell
        dataItem={dataItem}
        column={column}
        Icon={Icon}
        index={index}
        cellValue={cellValue}
        description={column.cellInfo?.description?.(dataItem) || ''}
        withoutPadding={column.cellInfo?.withoutPadding}
      />
    );
  } else if (dataItemProps?.labels?.length && cellValue === dataItemProps.labels) {
    return (
      <CellLabels
        column={column}
        labels={dataItemProps.labels}
      />
    );
  }

  return (
    <StyledTableCell
      width={column.width}
      minWidth={column.minWidth}
      align={column.cellInfo?.alignItems as alignProps || 'left'}
    >
      <ToLinkOrNotToLink href={column.cellInfo?.href?.(dataItem) || ''}>
        <Tooltip title={column.cellInfo?.cellTooltipText || ''} placement={column.cellInfo?.cellTooltipPlace || 'bottom'}>
          <Stack
            direction='row'
            gap='1rem'
            alignItems='center'
            justifyContent={column.cellInfo?.alignItems || 'left'}
          >
            {!!Icon && (
              !column.cellInfo?.iconPosition ||
              column.cellInfo?.iconPosition === 'left'
            ) && (
              Icon
            )}

            {cellValue && (
              <CellText
                title={cellValue}
                description={column.cellInfo?.description?.(dataItem) || ''}
                titleColor={column.cellInfo?.cellTextColor}
                titleWeight={column.cellInfo?.cellTextWeight}
              />
            )}

            {!!Icon && column.cellInfo?.iconPosition === 'right' && (
              Icon
            )}
          </Stack>
        </Tooltip>
      </ToLinkOrNotToLink>
    </StyledTableCell>
  );
};

export default UITableCell;