import { Button, Grid, Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { useMedia } from '@shared/hooks';
import { GenerateTokenModal, TokensTable } from './components';
import React from 'react';

const TokensSettings: React.FC = () => {
  const { isSxScreen } = useMedia();
  const [openGenerateModal, setOpenGenerateModal] = React.useState(false);

  const openGenerateTokenModal = React.useCallback(() => {
    setOpenGenerateModal(true);
  }, []);

  const closeGenerateTokenModal = React.useCallback(() => {
    setOpenGenerateModal(false);
  }, []);

  return (
    <Stack gap={2} padding={isSxScreen ? 1 : 2} flexGrow={1}>
      <Grid
        height='fit-content'
        container
        gap={isSxScreen ? 2 : 0}
      >
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography
            fontSize='1.75rem'
            fontWeight={600}
            lineHeight='2.25rem'
            letterSpacing='0.02275rem'
            color={muiPalette.grey?.[900]}
          >
            {'Tokens Settings'}
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          display='flex'
          justifyContent={isSxScreen ? 'center' : 'flex-end'}
        >
          <Button
            variant='contained'
            sx={{
              width: `${isSxScreen ? '100%' : 'fit-content'}`,
            }}
            onClick={() => openGenerateTokenModal()}
          >
            {'Generate API Token'}
          </Button>
        </Grid>
      </Grid>

      <TokensTable />

      <GenerateTokenModal
        open={openGenerateModal}
        onClose={closeGenerateTokenModal}
      />
    </Stack>
  );
};

export default TokensSettings;