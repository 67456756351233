import { TextLink } from '@contentful/f36-components';
import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';

export const StyledExtLink = styled(TextLink)`
  color: ${baseTokens.gray800};
  text-decoration: unset;

  & #externalLinkIcon {
    visibility: hidden;
  }

  &:hover, &:focus {
    color: ${baseTokens.gray800};
    text-decoration: unset;
  }
  
  &:hover #externalLinkIcon {
    visibility: visible;
  }
`;
