import { InfiniteData } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import * as apiClient from 'api-client';
import dayjs from 'dayjs';

function getNextLogsPage(lastPage?: AxiosResponse) {
  const hasNext = !!lastPage?.data?.next_token && !!lastPage?.data?.messages?.length;
  return hasNext ? lastPage.data.next_token : undefined;
}

function getFlatLogs(data?: InfiniteData<AxiosResponse<apiClient.LogsResponse>>) {
  return data?.pages?.map(res => res.data?.messages ?? [])?.flat() || [];
}

function useFlatLogs(data?: InfiniteData<AxiosResponse<apiClient.LogsResponse>>) {
  return useMemo(() => getFlatLogs(data), [data]);
}

function formatTimestamp(timestamp: string | number | Date | null | undefined): string {
  const ts = dayjs(timestamp);
  const result = ts.format('YYYY-MM-DD HH:mm:ss.') + (
    '000' + ts.millisecond()
  ).slice(-3);
  return result;
}

export { getNextLogsPage, useFlatLogs, formatTimestamp };
