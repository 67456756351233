import { MuiSearchInput, MuiTable, tableTypes } from '@shared/components';
import React from 'react';
import { globalModels } from '@shared/duck';
import { useParams } from 'react-router-dom';
import { targetColumns, useTargetsTableApi } from './duck';
import { useDebounce } from '@shared/hooks';
import { TargetGray } from '@assets';
import { Stack } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const ProjectTargetsList: React.FC = () => {
  const { projectId = '' } = useParams();
  const { debouncedValue: debouncedSearch, onChange: onSearchChange } = useDebounce<string>();

  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [debouncedSearch, projectId]);

  const {
    targets,
    totalItemsCount,
    isTargetsListLoading,
    targetsHasNextPage,
    fetchNextTargetsPage,
    onDeleteTargetClick,
    onTargetRowClick,
  } = useTargetsTableApi({
    page: currentPage,
    project: projectId,
    search: debouncedSearch,
    pageSize: rowsPerPage,
  });

  const tableConfig: tableTypes.MuiTableProps<globalModels.TargetViewModel> = {
    data: targets,
    columns: targetColumns,
    loading: isTargetsListLoading,
    isLoadMoreAvailable: targetsHasNextPage,
    onLoadMoreClick: fetchNextTargetsPage,
    onRowClick: onTargetRowClick,
    itemActions: item => (
      {
        itemList: [
          {
            title: 'Delete',
            itemAction: () => onDeleteTargetClick(item),
            isRedText: true,
          },
        ],
      }
    ),
    noContentMessage: 'No Targets found',
    noContentIcon: TargetGray,
    withExternalBorders: false,
    pagination: {
      count: totalItemsCount,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: (e, newPage) => handleChangePage(newPage),
      onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
    },
  };

  return (
    <>
      <Stack p='1rem' borderBottom={`1px solid ${muiPalette.grey?.[200]}`}>
        <MuiSearchInput
          width='25rem'
          onChange={e => onSearchChange(e.target.value)}
        />
      </Stack>

      <MuiTable
        {...tableConfig}
      />
    </>
  );
};

export default ProjectTargetsList;
