import { Stack, Typography } from '@mui/material';
import { DocsLink } from '@shared/components';
import { globalConstants } from '@shared/duck';
import React from 'react';

const InfoPopupContent: React.FC = () => {
  return (
    <Stack gap='1rem'>
      <Typography variant='default'>
        {'NightVision enables you to replay the login sequence using ' +
          'the provided authentication credentials. ' +
          'This functionality allows you to meticulously ' +
          'analyse the steps involved in simulating an attack and ' +
          'effectively investigate any potential authentication ' +
          'failures within the login sequence.'}
      </Typography>

      <div style={{ position: 'relative', height: '16rem', width: '29rem' }}>
        <iframe
          src={globalConstants.VIDEOS_URL.LOGIN_STATUS}
          allowFullScreen
          style={{ width: '100%', height: '100%', borderRadius: '0.5rem' }}
        />
      </div>

      <h4 style={{ marginBottom: 'initial' }}>
        {'What is an Authentication Credential?'}
      </h4>

      <Typography variant='default'>
        {'By utilising authentication credentials, ' +
          'the scanner gets the ability to  gain access to the core ' +
          'of the application, including third-party APIs. ' +
          'This access provides a comprehensive view of the system, ' +
          'allowing for a more thorough assessment and identification ' +
          'of potential vulnerabilities.'}
      </Typography>


      <Typography variant='default'>
        {'Learn more about '}

        <DocsLink
          to={globalConstants.DOCS_PATHS.SCANNING_REST_API_W_AUTH}
        >
          {'Header Authentication'}
        </DocsLink>

        &nbsp;{'&'}&nbsp;

        <DocsLink
          to={globalConstants.DOCS_PATHS.RECORD_AUTH}
        >
          {'Record Authentication'}
        </DocsLink>

        &nbsp;{'using Playwright'}
      </Typography>
    </Stack>
  );
};

export default InfoPopupContent;