import { globalEnums } from '@shared/duck';
import { Chip } from '@mui/material';
import { getCheckChipColor } from './duck';

export type CheckStatusProps = {
  type: globalEnums.CheckStatusEnum
}

const CheckStatusBadge: React.FC<CheckStatusProps> = ({
  type,
  ...props
}: CheckStatusProps) => {
  return (
    <Chip
      label={globalEnums.CheckStatusName.get(type)}
      color={getCheckChipColor(type)}
      sx={{
        'span': {
          fontSize: '0.75rem',
          fontWeight: 500,
        }
      }}
      {...props}
    />
  );
};

export default CheckStatusBadge;
