import { IssueKind } from '@api-client';
import { Stack, Typography } from '@mui/material';
import React from 'react';

interface IssueKindRenderProps {
  kind: IssueKind;
}

const KindRenderOption: React.FC<IssueKindRenderProps> = ({
  kind,
  ...props
}) => {
  return (
    <Stack
      {...props}
      flexDirection='row'
      padding='0.5rem 1rem'
      gap={0.5}
    >
      <Stack flexDirection='column' overflow='hidden' gap='0'>
        <Typography
          variant='body2'
          sx={{
            opacity: 0.5,
          }}
          noWrap
        >
          {'ID:' + kind.id}
        </Typography>

        <Typography
          variant='subtitle2'
          noWrap
        >
          {kind.name}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default KindRenderOption;