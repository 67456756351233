import { Theme, LinkProps, LinkClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const LinkDefaultProps: Partial<LinkProps> | undefined =
{
  underline: 'hover',
};

export const LinkStyles: Partial<OverridesStyleRules<keyof LinkClasses, 'MuiLink', Omit<Theme, 'components'>>> | undefined =
{
  root: {
    '&.MuiLink-root': {
      color: '#0059C8',
      zIndex: '10',

      '&:hover': {
        cursor: 'pointer',
      }
    },
  },
};