import {
  Modal as BaseModal,
  ModalProps as BaseModalProps,
  ModalHeader as BaseModalHeader,
  ModalHeaderProps as BaseModalHeaderProps,
  ModalContentProps as BaseModalContentProps,
  ModalControls as BaseModalControls,
  ModalControlsProps as BaseModalPropsControls,
  ModalLauncher as BaseModalLauncher,
  ModalLauncherComponentRendererProps as BaseModalLauncherComponentRendererProps,
  ModalConfirm as BaseModalConfirm,
  ModalConfirmProps as BaseModalConfirmProps,
} from '@contentful/f36-components';

import { StyledModalContent } from './Modal.styled';

export const Modal = BaseModal;
export const ModalHeader = BaseModalHeader;
export const ModalContent = StyledModalContent;
export const ModalControls = BaseModalControls;
export const ModalLauncher = BaseModalLauncher;
export const ModalConfirm = BaseModalConfirm;

export type ModalProps = BaseModalProps
export type ModalHeaderProps = BaseModalHeaderProps
export type ModalContentProps = BaseModalContentProps
export type ModalControlsProps = BaseModalPropsControls
export type ModalLauncherComponentRendererProps = BaseModalLauncherComponentRendererProps
export type ModalConfirmProps = BaseModalConfirmProps
