import { globalModels, globalQueries, globalUtils } from '@shared/duck';
import React from 'react';

interface ApplicTableApiResult {
  applications: globalModels.ApplicationsViewModel[],
  totalItemsCount: number,
  isApplicationsListLoading: boolean,
  applicationsHasNextPage: boolean,
  fetchNextApplicationsPage: () => void,
  onApplicationsRowClick?: (item: globalModels.ApplicationsViewModel) => void,
}

export const useApplicationsTableApi = ({
  page,
  projects,
  search,
  pageSize,
}:
  {
    page: number;
    projects?: string[];
    search?: string;
    pageSize?: number;
  }): ApplicTableApiResult => {
  const {
    applications: dtoApplications,
    totalItemsCount,
    isApplicationsListLoading,
    applicationsHasNextPage = false,
    fetchNextApplicationsPage,
  } = globalQueries.useGetApplicationsList({
    page: page + 1,
    filter: search,
    project: projects,
    pageSize: pageSize,
  });

  const applications = React.useMemo(
    () =>
      dtoApplications?.map(
        (item) => globalUtils.mapApplicationsDtoToViewModel(item)) || [],
    [dtoApplications],
  );

  return {
    applications,
    totalItemsCount,
    isApplicationsListLoading,
    applicationsHasNextPage,
    fetchNextApplicationsPage
  };
};