import * as Yup from 'yup';

export const TargetExclusionsValuesSchema = Yup.object().shape({
  url_patterns: Yup.array().of(Yup.string()),
  xpath_patterns: Yup.array().of(Yup.string()),
});

export interface ExclusionsValues {
  url_patterns: string[];
  xpath_patterns: string[];
}