import { InfiniteQueryObserverOptions, UseQueryOptions, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Issue,
  IssuesApiIssuesKindListRequest,
  IssuesApiIssuesUpdateRequest,
  IssuesApiIssuesVulnerablePathsListRequest,
  IssuesApiIssuesOccurrencesCreateRequest,
  IssuesApiIssuesRetrieveRequest,
  IssuesApiIssuesDropDestroyRequest,
  IssuesApiIssuesDestroyRequest,
  PaginatedIssueKindStatisticsList,
  PaginatedIssueVulnerablePathResponseList,
  PaginatedIssuesOccurrencesResponseList,
} from 'api-client';
import { useApp } from '@shared/hooks';
import { IssuesQueryKeys } from './queriesKeys';
import { getNextPageParam, useFlatResults } from './utils';
import { AxiosError, AxiosResponse } from 'axios';

const useUpdateIssue = () => {
  const { issuesApi } = useApp();
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: (request: IssuesApiIssuesUpdateRequest) => issuesApi.issuesUpdate(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [IssuesQueryKeys.issuesList] });
      queryClient.resetQueries({
        queryKey: [IssuesQueryKeys.issueKindsList],
      });
      queryClient.resetQueries({ queryKey: [IssuesQueryKeys.issuesVulnerablePathsList] });
      queryClient.resetQueries({ queryKey: [IssuesQueryKeys.issueOccurrencesList] });
      queryClient.resetQueries({ queryKey: [IssuesQueryKeys.issueById] });
    },
  });

  return {
    updateIssue: mutateAsync,
  };
};

const useDeleteIssues = () => {
  const { issuesApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: IssuesApiIssuesDropDestroyRequest) => issuesApi.issuesDropDestroy(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [IssuesQueryKeys.issuesList] });
      queryClient.resetQueries({
        queryKey: [IssuesQueryKeys.issueKindsList],
      });
      queryClient.resetQueries({ queryKey: [IssuesQueryKeys.issuesVulnerablePathsList] });
      queryClient.resetQueries({ queryKey: [IssuesQueryKeys.issueOccurrencesList] });
    },
  });

  return {
    deleteIssues: mutateAsync,
    isDeleteInProgress: isLoading
  };
};

const useDeleteIssueById = () => {
  const { issuesApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: IssuesApiIssuesDestroyRequest) => issuesApi.issuesDestroy(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [IssuesQueryKeys.issuesList] });
      queryClient.resetQueries({
        queryKey: [IssuesQueryKeys.issueKindsList],
      });
      queryClient.resetQueries({ queryKey: [IssuesQueryKeys.issuesVulnerablePathsList] });
      queryClient.resetQueries({ queryKey: [IssuesQueryKeys.issueOccurrencesList] });
    },
  });

  return {
    deleteIssue: mutateAsync,
    isDeleteInProgress: isLoading
  };
};

const useGetIssueKindList = (
  request: IssuesApiIssuesKindListRequest,
  config: Partial<InfiniteQueryObserverOptions<AxiosResponse<PaginatedIssueKindStatisticsList>, AxiosError>> = {},
) => {
  const { issuesApi } = useApp();

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [IssuesQueryKeys.issueKindsList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => issuesApi.issuesKindList({ ...request, page: pageParam }),

    getNextPageParam: getNextPageParam,
    ...config,
  });

  const issueKinds = useFlatResults(data);

  return {
    issueKinds,
    isIssueKindsLoading: isLoading,
    issueKindListHasNextPage: hasNextPage,
    fetchIssueKindListNextPage: fetchNextPage,
  };
};

const useGetIssuesVulnerablePathsList = (
  request: IssuesApiIssuesVulnerablePathsListRequest,
  config: Partial<InfiniteQueryObserverOptions<AxiosResponse<PaginatedIssueVulnerablePathResponseList>, AxiosError>> = {},
) => {
  const { issuesApi } = useApp();

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [IssuesQueryKeys.issuesVulnerablePathsList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => issuesApi.issuesVulnerablePathsList({ ...request, page: pageParam }),
    getNextPageParam: getNextPageParam,
    ...config,
  });

  const vulnerablePaths = useFlatResults(data);

  return {
    vulnerablePaths,
    isVulnerablePathsLoading: isLoading,
    vulnerablePathsListHasNextPage: hasNextPage,
    fetchVulnerablePathsListNextPage: fetchNextPage,
  };
};

const useGetIssueOccurrencesList = (
  request: IssuesApiIssuesOccurrencesCreateRequest,
  config: Partial<InfiniteQueryObserverOptions<AxiosResponse<PaginatedIssuesOccurrencesResponseList>, AxiosError>> = {},
) => {
  const { issuesApi } = useApp();

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchedAfterMount, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [IssuesQueryKeys.issueOccurrencesList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => issuesApi.issuesOccurrencesCreate({ ...request, page: pageParam }),

    getNextPageParam: getNextPageParam,
    ...config,
  });

  const issues = useFlatResults(data);

  return {
    issues,
    isIssuesLoading: isLoading,
    issuesListHasNextPage: hasNextPage,
    fetchIssuesListNextPage: fetchNextPage,
    isIssuesFetchedAfterMount: isFetchedAfterMount,
    isFetchingNextPage: isFetchingNextPage,
  };
};

const useGetIssueById = (request: IssuesApiIssuesRetrieveRequest,
  config?: Partial<UseQueryOptions<AxiosResponse<Issue>, AxiosError>>,
) => {
  const { issuesApi } = useApp();

  const { data, isLoading } = useQuery({
    queryKey: [IssuesQueryKeys.issueById, ...Object.values(request)],
    queryFn: () => issuesApi.issuesRetrieve(request),
    ...config
  });

  return {
    issue: data?.data,
    isIssueLoading: request.id ? isLoading : false,
  };
};

export {
  useUpdateIssue,
  useDeleteIssues,
  useDeleteIssueById,
  useGetIssueKindList,
  useGetIssuesVulnerablePathsList,
  useGetIssueOccurrencesList,
  useGetIssueById,
};
