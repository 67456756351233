import { OpenApiTargetRequest } from '@api-client';
import { ScanTargetOpenApi } from '@shared/components/create-targets/components/target-modal-content/components/api-content/duck';
import { globalQueries } from '..';
import { NewOpenApiTarget } from '../types';

export const onCreateNewTarget = () => {
  const { uploadOpenApiSpec } = globalQueries.useUploadOpenApiSpec();
  const { createOpenApiTargets } = globalQueries.useCreateTargetsOpenApi();

  const onCreate = async (targetToCreate: NewOpenApiTarget, isFileUrl?: boolean) => {
    const target: ScanTargetOpenApi = {
      ...targetToCreate,
      credentials: new Map(),
      isAccessTested: false,
    };

    const request: OpenApiTargetRequest = {
      name: target.name,
      credentials_id: null,
      project: target.project,
      location: target.location,
      swaggerfile_url: isFileUrl ? target.fileLocation : undefined,
      internet_accessible: target.isAccessible,
    };

    const {
      data: [newTarget],
    } = await createOpenApiTargets({
      openApiTargetRequest: [request],
    });

    if (!isFileUrl && target.file) {
      await uploadOpenApiSpec({
        id: newTarget.id,
        file: target.file,
      });
      newTarget.swaggerfile_name = target.file.name;
    }

    return newTarget;
  };

  return { onCreate };
};

export const onUpdateNewTarget = () => {
  const { uploadOpenApiSpec } = globalQueries.useUploadOpenApiSpec();
  const { updateOpenApiTargets } = globalQueries.useUpdateTargetsOpenApi();

  const onUpdate = async (targetToUpdate: NewOpenApiTarget, id: string, isFileUrl: boolean, isFileEdited?: boolean, isSwaggerFileChanged?: boolean) => {
    const request: OpenApiTargetRequest = {
      name: targetToUpdate.name,
      project: targetToUpdate.project,
      location: targetToUpdate.location,
      swaggerfile_url: isFileUrl ?
        isSwaggerFileChanged ? targetToUpdate.fileLocation : undefined
        : undefined,
    };

    const {
      data: newTarget,
    } = await updateOpenApiTargets({
      id: id,
      openApiTargetUpdateRequest: request,
    });

    if (!isFileUrl && targetToUpdate.file && isFileEdited) {
      await uploadOpenApiSpec({
        id: newTarget.id,
        file: targetToUpdate.file,
      });
    }

    return newTarget;
  };

  return { onUpdate };
};