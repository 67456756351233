import { ApplicationsGray } from '@assets';
import { MuiTable, tableTypes } from '@shared/components';
import { allRoutesEnum, globalModels } from '@shared/duck';
import { useApplicationsTableApi, useDeleteApplicationHandler } from '@shared/hooks';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { applicationTableConsts } from './duck';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

interface ProjectApplicationsTableProps {
  filter?: string;
}

const ProjectApplicationsTable: React.FC<ProjectApplicationsTableProps> = ({
  filter,
}) => {
  const navigate = useNavigate();
  const { projectId = '' } = useParams();
  const { deleteApplicationHandler } = useDeleteApplicationHandler();

  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [filter, projectId]);

  const {
    applications,
    totalItemsCount,
    isApplicationsListLoading,
    applicationsHasNextPage,
    fetchNextApplicationsPage,
  } = useApplicationsTableApi({
    page: currentPage,
    search: filter,
    projects: [projectId],
    pageSize: rowsPerPage,
  });

  const { showModal } = useGlobalModalContext();

  const tableConfig: tableTypes.MuiTableProps<globalModels.ApplicationsViewModel> = {
    data: applications,
    columns: applicationTableConsts.applicationColumns,
    loading: isApplicationsListLoading,
    isLoadMoreAvailable: applicationsHasNextPage,
    onLoadMoreClick: fetchNextApplicationsPage,
    onRowClick: (item) => (
      navigate(allRoutesEnum.ApplicationDetails.replace(':applicationId', item.id))
    ),
    itemActions: item => (
      {
        itemList: [
          {
            title: 'Edit',
            itemAction: () => showModal({
              modalType: MODAL_TYPE.ApplicationModal,
              modalProps: {
                applicationId: item.id,
                enableProjectSelect: false,
              },
            }),
          },
          {
            title: 'Delete',
            itemAction: () => deleteApplicationHandler(item),
            isRedText: true,
          },
        ],
      }
    ),
    noContentMessage: 'No Applications found',
    noContentIcon: ApplicationsGray,
    withExternalBorders: false,
    pagination: {
      count: totalItemsCount,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: (e, newPage) => handleChangePage(newPage),
      onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
    },
  };

  return (
    <MuiTable
      {...tableConfig}
    />
  );
};

export default ProjectApplicationsTable;