import { Modal, ModalContent, ModalControls, ModalHeader } from '@contentful/f36-components';
import { Formik, FormikErrors } from 'formik';
import { globalEnums } from '@shared/duck';
import { FormikControl, UITextInput } from '@shared/components';
import { Typography, Button } from '@mui/material';

interface UpgradePlanFormValues {
  upgrade?: string;
}

interface UpgradePlanModalProps {
  downgradeDate?: string;
  isOpenModal: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  upgradeToApiEnvy?: boolean;
}

const UpgradePlanModal: React.FC<UpgradePlanModalProps> = ({
  isOpenModal,
  closeModal,
  onConfirm,
  upgradeToApiEnvy = false,
}: UpgradePlanModalProps) => {
  return (
    <Modal onClose={closeModal} isShown={isOpenModal} style={{ opacity: '0.5rem' }}>
      {() => (
        <Formik<UpgradePlanFormValues>
          initialValues={{ upgrade: '' }}
          validate={(values) => {
            const errors: FormikErrors<UpgradePlanFormValues> = {};
            if (values.upgrade !== 'Cancel Trial') errors.upgrade = '';
            return errors;
          }}
          isInitialValid={false}
          onSubmit={() => { closeModal; onConfirm(); }}
          validateOnMount>
          {({ handleSubmit, isValid }) => (
            <>
              <ModalHeader onClose={closeModal} title={`Upgrade account to ${upgradeToApiEnvy ? 'API ENVY' : 'Professional'}?`} />
              <ModalContent>
                <Typography variant='default' pb='1rem' display='block'>
                  By clicking <b>Confirm</b>, you agree to deactivate your Free Trial Plan <b>now</b> and proceed to the
                  payment page for the {`${upgradeToApiEnvy ? 'API ENVY' : 'Professional'} Plan.`}
                </Typography>
                <FormikControl style={{ margin: '0' }} name='upgrade' label="Type 'Cancel Trial' to proceed">
                  <UITextInput placeholder='Cancel Trial' />
                </FormikControl>
              </ModalContent>
              <ModalControls style={{ padding: '0 1.5rem 1rem 1.5rem' }}>
                <Button
                  size='small'
                  color={globalEnums.EMuiButtonColors.SECONDARY}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  size='small'
                  color={globalEnums.EMuiButtonColors.PRIMARY}
                  variant={globalEnums.EMuiButtonVariants.CONTAINED}
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                >
                  Confirm
                </Button>
              </ModalControls>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default UpgradePlanModal;
