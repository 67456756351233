import { Theme, IconButtonProps, IconButtonClasses, Interpolation } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    outlined: true,
  }
}

export const IconButtonDefaultProps: Partial<IconButtonProps> | undefined =
  {
    disableTouchRipple: true,
  };

export const IconButtonStyles: Partial<OverridesStyleRules<keyof IconButtonClasses, 'MuiIconButton', Omit<Theme, 'components'>>> | undefined =
  {
    root: {
      borderRadius: '0.4rem',

      '&:hover': {
        backgroundColor: '#F7F9FA',
      },
    },
  };

export const IconButtonVariants: {
  props: Partial<IconButtonProps>,
  style: Interpolation<{ theme: Theme }>
}[] | undefined = [
  {
    props: {
      color: 'default',
    },
    style: {
      background: 'transparent',
      border: 'initial',
      color: '#67728A',
    },
  },
  {
    props: {
      color: 'outlined',
    },
    style: {
      background: '#FFFFFF',
      border: '1px solid #CFD9E0',
      color: '#111B2B',

      '&:hover': {
        backgroundColor: '#F7F9FA',
        borderColor: '#CFD9E0',
      },
    },
  },
  {
    props: { color: 'success' },
    style: {
      background: '#008539',

      ':hover': {
        background: '#0c720c',
      },
    },
  },
  {
    props: { color: 'error' },
    style: {
      background: '#FFFFFF',
      border: '1px solid #BD002A',
      color: '#BD002A',

      ':hover': {
        background: '#FFFFFF',
        border: '1px solid #DE1D1D',
        color: '#DE1D1D',
      },
    },
  },
];