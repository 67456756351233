import styled from '@emotion/styled';
import { Stack } from '@mui/material';

const StyledHeaderContainer = styled(Stack)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0.7rem 1rem 0.7rem 1.5rem;

  .header-container {
    font-weight: 600;
    font-size: 1rem;
    align-items: center;
    gap: 0.5rem;
  }
`;

export { StyledHeaderContainer };