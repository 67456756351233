import { SelectClasses, SelectProps, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { CaretDown } from '@phosphor-icons/react';

export const SelectStyles: Partial<OverridesStyleRules<keyof SelectClasses, 'MuiSelect', Omit<Theme, 'components'>>> | undefined =
{
  root: {
    padding: '0.75rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: '#67728A',
  },
};

export const SelectDefaultProps: Partial<SelectProps> | undefined = {
  IconComponent: (props) => <CaretDown size={16} weight='bold' cursor='pointer' {...props} style={{ padding: '0.25rem', top: 'unset' }} />,
};