import { Badge } from '@contentful/f36-components';
import { Chip, Stack } from '@mui/material';
import {  tableTypes } from '@shared/components';
import { globalEnums, globalTypes } from '@shared/duck';

const extendedColumns: tableTypes.ColumnProps<globalTypes.PathInfo>[] = [
  {
    title: 'Method',
    columnKey: 'http_method',
    minWidth: '3rem',
  },
  {
    title: 'Codes',
    columnKey: 'http_codes',
    minWidth: '3rem',
    customCellRenderer(_, item) {
      return (
        <Stack direction='row' flexWrap='wrap'>
          {item.http_codes?.map(code => (
            <Chip key={code} label={code} color={getCodeColor(code)}
              sx={{
              // Special yellow color for redirection codes
                ...(getHttpCodeGroup(code) === 3 && {
                  color: '#B78300',
                  background: '#FFF2CC',
                })
              }} />
          ))}
        </Stack>
      );
    },
  }
];

export interface ColumnsProps {
  pathsTotal?: number;
  scanEnded: boolean;
  debouncedSearch?: string;
  newPathsTableWithCodes: boolean;
}

export const getColumns = ({
  pathsTotal,
  scanEnded,
  debouncedSearch,
  newPathsTableWithCodes,
}: ColumnsProps): tableTypes.ColumnProps<globalTypes.PathInfo>[] => {
  let columns: tableTypes.ColumnProps<globalTypes.PathInfo>[] = [
    {
      title: 'Paths',
      columnKey: 'path',
      minWidth: '61.25rem',
      customHeadRenderer: () => (
        <Stack flexDirection='row' alignItems='center'>
          <span>Paths</span>
          {pathsTotal !== undefined && scanEnded && !debouncedSearch && (
            <Badge variant={globalEnums.EBadgeVariants.PRIMARY} size='small'>
              {pathsTotal}
            </Badge>
          )}
        </Stack>
      ),
      cellInfo: {
        hasUnbreakableTextData: true
      },
    }
  ];

  if (newPathsTableWithCodes) {
    columns = columns.concat(extendedColumns);
  }

  return columns;
};

const getHttpCodeGroup = (code: number) => Math.trunc(code / 100);

const getCodeColor = (code: number): globalEnums.MuiBadgeColor => {
  code = getHttpCodeGroup(code);
  switch (code) {
    case 1: {
      return globalEnums.MuiBadgeColor.PRIMARY;
    }
    case 2: {
      return globalEnums.MuiBadgeColor.SUCCESS;
    }
    case 4: {
      return globalEnums.MuiBadgeColor.WARNING;
    }
    case 5: {
      return globalEnums.MuiBadgeColor.ERROR;
    }
    default: {
      return globalEnums.MuiBadgeColor.DEFAULT;
    }
  }
};