export enum NotificationType {
  FREE_TRIAL,
  FREE_TRIAL_ENDING,
  INCOMPLETE,
  ENDING,
  DUE,
  OVERDUE,
  PAUSED,
  NONE,
}
