import React from 'react';
import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material';
import { MuiAutocomplete } from '@shared/components';
import { CustomPaper } from './components/paper';
import { CustomOption, CustomTextField } from './components';

interface MuiFilterAutocompleteProps<T> {
  options: readonly T[];
  loading?: boolean;
  selectedValues?: T[];
  setSelectedValues?: (newValues: T[], options?: T[], reason?: AutocompleteChangeReason) => void;
  filterName: string;
  getOptionKey: ((option: T) => string | number);
  getOptionLabel: ((option: T) => string);
  open?: boolean;
  onOpen?: (e?: any) => void;
  onClose?: (e?: any) => void;
  optionStartIcon?: (option: T) => React.ReactNode;
  inputValue?: string;
  onInputChange?: (value: string, reason: AutocompleteInputChangeReason) => void;
  fetchNextPage?: () => void;
  multiple?: boolean;
  disableFilterOptions?: boolean;
  getOptionDisabled?: (option: T) => boolean;
  footer?: React.ReactElement;
}

export const MuiFilterAutocomplete = <T,>({
  options,
  loading,
  selectedValues,
  setSelectedValues,
  filterName,
  getOptionKey,
  getOptionLabel,
  open,
  onClose,
  onOpen,
  optionStartIcon,
  inputValue: inputValueProps,
  onInputChange: onInputChangeProps,
  fetchNextPage,
  multiple = true,
  disableFilterOptions = true,
  getOptionDisabled,
  footer,
}: MuiFilterAutocompleteProps<T>) => {

  const [active, setActive] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const input = inputValueProps || inputValue;
  const onInputChange = onInputChangeProps || setInputValue;

  return (
    <MuiAutocomplete
      multiple={multiple}
      open={open}
      onOpen={onOpen}
      onClose={() => {
        onClose?.();
        onInputChange('', 'clear');
      }}
      options={options}
      loading={loading}
      autoComplete
      autoHighlight
      disableClearable
      disableCloseOnSelect
      disablePortal
      value={selectedValues}
      onKeyDown={() => null}
      onChange={(_, newValue: T[], reason, details) => setSelectedValues?.(newValue, [details?.option], reason)}
      inputValue={input}
      onInputChange={(_, value, reason) => {
        if ((multiple && reason !== 'reset') || !multiple)
          onInputChange(value, reason);
      }}
      renderInput={(params) => (
        <CustomTextField<T>
          {...params}
          value={active ? input : filterName}
          filterName={filterName}
          setActive={setActive}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          onInputChange={onInputChange}
          loading={loading}
        />
      )}
      filterOptions={(options: T[]) => {
        if (disableFilterOptions) return options;
        const result = options.filter((option) =>
          getOptionLabel(option)
            .toLowerCase()
            .trim()
            .includes(input.toLowerCase().trim()));

        return result;
      }}
      renderOption={(props, option) => (
        <CustomOption<T>
          props={props}
          option={option}
          selected={selectedValues?.some(selected => getOptionKey(selected) === getOptionKey(option)) || false}
          optionStartIcon={optionStartIcon}
          getOptionLabel={getOptionLabel}
          getOptionKey={getOptionKey}
        />
      )}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      isOptionEqualToValue={(option, value) => getOptionKey(option) === getOptionKey(value)}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 5) {
            !loading && fetchNextPage?.();
          }
        },
      }}
      PaperComponent={CustomPaper}
      slotProps={{
        paper: {
          loading: !!options.length && loading,
          footer: footer,
        } as any,
      }}
    />
  );
};