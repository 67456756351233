export enum DayRangeEnum {
  ALL = 'All',
  DAY = 'This Day',
  WEEK = 'This Week',
  MONTH = 'This Month',
}

export const DAY_RANGE_LIST: DayRangeEnum[] = [
  DayRangeEnum.ALL,
  DayRangeEnum.MONTH,
  DayRangeEnum.WEEK,
  DayRangeEnum.DAY,
];