import React from 'react';
import { globalTypes } from '@shared/duck';
import ReactMarkdown from 'react-markdown';
import { ColumnData } from './types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { TableSpinner } from 'views/components/v1/Table/Table.styled';
import { TableComponents } from 'react-virtuoso';
import { StyledMarkdownContainer } from '../logs-table.styled';
import { default as ColumnComponent } from '../column-component';

export const getTableColumns = (
  setCheckName?: React.Dispatch<React.SetStateAction<string>>
): ColumnData[] => [
  {
    title: 'Time Stamp',
    columnKey: 'timestampFormatted',
    width: '12rem',
    renderer(_, item) {
      return item.timestampFormatted;
    },
  },
  {
    title: 'Level',
    columnKey: 'level',
    width: '5rem',
    // paddingRight: "0.5rem",
    paddingRight: '0',
    paddingLeft: '0',
  },
  {
    title: 'Log',
    columnKey: 'msg',
    width: '26rem',
    renderer(_, item) {
      return (
        <StyledMarkdownContainer>
          <ReactMarkdown>{item.msg ?? ''}</ReactMarkdown>
        </StyledMarkdownContainer>
      );
    },
  },
  {
    title: 'Component',
    columnKey: 'component',
    width: '12rem',
    paddingRight: '0.5rem',
    paddingLeft: '0',
    renderer(_, item) {
      return (
        <ColumnComponent item={item} setCheckName={setCheckName} />
      );
    },
  },
  {
    title: 'Check',
    columnKey: 'check_id',
    width: '12rem',
    // width: "10.9375rem",
    paddingRight: '0.5rem',
    paddingLeft: '0',
  },
];

export function fixedHeaderContent(isLoading: boolean) {
  return (
    <>
      <TableRow>
        {getTableColumns().map((column) => (
          <TableCell
            key={column.columnKey}
            variant="head"
            align={column.numeric || false ? 'right' : 'left'}
            style={{ width: column.width, paddingRight: column.paddingRight, paddingLeft: column.paddingLeft }}
            sx={{
              backgroundColor: 'background.paper',
              padding: '0.75rem',
            }}
          >
            {column.title}
          </TableCell>
        ))}
      </TableRow>
      {isLoading ? <TableSpinner colSpan={getTableColumns().length} /> : <></>}
    </>
  );
}

export const rowContent = (
  _index: number, row: globalTypes.ScanLogItem,
  setCheckName?: React.Dispatch<React.SetStateAction<string>>
) => {
  return (
    <>
      {getTableColumns(setCheckName).map((column) => (
        <TableCell
          key={column.columnKey}
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width, paddingRight: column.paddingRight, paddingLeft: column.paddingLeft }}
        >
          {
            column.renderer
              ? column.renderer(column.columnKey, row)
              : row[column.columnKey]
          }
        </TableCell>
      ))}
    </>
  );
};

export const getTableComponents = (

): TableComponents<globalTypes.ScanLogItem> => ({
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow,
  // eslint-disable-next-line react/display-name
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
});
