import { IconProps } from '../BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from '../icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <path
      d='M9 6V9M9 12H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

function NavIssuesIcon(props: IconProps): JSX.Element {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default NavIssuesIcon;
