import { globalConstants } from '@shared/duck';
import { useMedia } from '@shared/hooks';
import React from 'react';
import { useParams } from 'react-router-dom';

const Showcase: React.FC = () => {
  const { isSmallScreen } = useMedia();
  const { id } = useParams();

  const body = localStorage.getItem(globalConstants.VULNERABLE_RESPONSE_KEY + id) || '';
  const contentPadding = isSmallScreen ? '1rem' : '2rem';

  return (
    <>
      <div
        style={{ padding: contentPadding }}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </>
  );
};

export default Showcase;