import { globalUtils } from '@shared/duck';

export const endpointsToCsv = (endpoints: { [key: string]: any }[]): string => {
  const result: string[][] = endpoints.map(item => [
    item.http_method || '',
    item.url_path || '',
  ]);
  const str =
    'method,path\n' +
    result
      .map(line =>
        line
          .map(globalUtils.wrapCsvInDoubleQuotesIfNeeded)
          .join(','),
      )
      .join('\n');
  return str;
};