import { Issue } from '@api-client';
import { CopyIconC } from '@assets';
import { globalUtils } from '@shared/duck';
import React from 'react';
import { ExternalGrayLinkIcon } from 'views/components/v1/Icons';
import { ExplainWithAIWall, ScanLink } from './components';
import { StyledGridItem } from './common-details.styled';
import { StyledExtLink, UICodeSnippet } from '@shared/components';
import { Grid, GridItem } from '@contentful/f36-components';
import { Link, Stack, Tooltip, Typography } from '@mui/material';
import { commonDetailsUtils } from './duck';
import { muiPalette } from '@shared/general-mui-theme';

interface CommonDetailsProps {
  issue?: Issue;
  withScanInfo?: boolean;
}

const IssueCommonDetails: React.FC<CommonDetailsProps> = ({
  issue,
  withScanInfo = false,
}) => {
  const url = commonDetailsUtils.getIssueUrl(issue || undefined);
  const copyUrl = commonDetailsUtils.getIssueCopyUrl(issue || undefined);
  const [isFullDescription, setFullDescription] = React.useState(false);
  const textRef = React.useRef<HTMLSpanElement | null>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const openAllNucleiDescription = () => {
    setFullDescription(true);
  };
  React.useEffect(() => {
    setFullDescription(false);
  }, [issue?.id]);

  React.useEffect(() => {
    if (textRef.current && containerRef.current) {
      const textWidth = textRef.current?.clientWidth;
      const containerWidth = containerRef.current?.clientWidth;
      if (textWidth < containerWidth) {
        setFullDescription(true);
      } else {
        setFullDescription(false);
      }
    }
  }, [textRef.current, containerRef.current]);

  const onCopyPathUrlClick = (copyUrl: string) => {
    globalUtils.copyTextToClipboard(copyUrl, 'Copied to clipboard!');
  };

  return (
    <>
      <Grid columns='1fr 4fr' columnGap='spacingM' rowGap='spacingM' marginBottom='spacingL'>
        {withScanInfo && (
          <>
            <GridItem>
              <Typography variant='default' fontWeight='600'>
                Scan
              </Typography>
            </GridItem>
            <GridItem style={{ maxWidth: 'fit-content' }}>
              {issue && <ScanLink issue={issue} showDetectionTime />}
            </GridItem>
          </>
        )}
        <GridItem style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='default' fontWeight='600'>
            Path
          </Typography>
        </GridItem>
        <GridItem style={{ minWidth: 0, maxWidth: 'fit-content' }}>
          <Stack
            gap={1.5}
            flexDirection='row'
          >
            <Tooltip
              arrow
              title={copyUrl || ''}
              placement='top'
            >
              <Stack
                flexDirection='row'
                onClick={() => onCopyPathUrlClick(copyUrl)}
                sx={{
                  cursor: 'pointer',
                }}
                overflow='auto'
              >
                <Typography variant='default' fontWeight='500' color={muiPalette.grey?.[500]} noWrap>
                  {url}
                </Typography>
                <CopyIconC />
              </Stack>
            </Tooltip>

            <StyledExtLink
              className='external-link'
              icon={<ExternalGrayLinkIcon />}
              alignIcon='end'
              href={copyUrl}
              target={'_blank'}
              rel='noopener noreferrer'
            />
          </Stack>
        </GridItem>
        <GridItem>
          <Typography variant='default' fontWeight='600'>
            Parameter
          </Typography>
        </GridItem>
        <GridItem>
          <Typography variant='default' color={muiPalette.grey?.[500]}>
            {issue?.parameter_name || ''}
          </Typography>
        </GridItem>
        <GridItem>
          <Typography variant='default' fontWeight='600'>
            Method
          </Typography>
        </GridItem>
        <GridItem>
          <Typography variant='default' color={muiPalette.grey?.[500]}>
            {issue?.http_method}
          </Typography>
        </GridItem>
        <GridItem>
          <Typography variant='default' fontWeight='600'>
            Payload
          </Typography>
        </GridItem>
        <GridItem>{issue?.payload ? <UICodeSnippet>{issue.payload}</UICodeSnippet> :
          <Typography variant='default' color={muiPalette.grey?.[500]}>
            N/A
          </Typography>}
        </GridItem>
        {issue?.extra_info?.info?.description && (
          <>
            <GridItem>
              <Typography variant='default' fontWeight='600'>
                Description
              </Typography>
            </GridItem>
            <StyledGridItem ref={containerRef}>
              <Typography variant='subtitle2' noWrap={!isFullDescription} ref={textRef}>
                {issue?.extra_info?.info.description}
              </Typography>
              {!isFullDescription && <Link onClick={openAllNucleiDescription}>Read More</Link>}
            </StyledGridItem>
          </>
        )}
      </Grid>
      {issue?.ai_explanation ? <ExplainWithAIWall issue={issue} style={{ padding: '0.375rem 0.5rem' }} /> : null}
    </>
  );
};

export default IssueCommonDetails;
