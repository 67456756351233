import { IconProps } from '../BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from '../icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='18'
    height='14'
    viewBox='0 0 18 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <path
      d='M12.1804 3.82001C12.5987 4.23794 12.9307 4.73424 13.1571 5.28053C13.3836 5.82682 13.5001 6.41239 13.5001 7.00376C13.5001 7.59513 13.3836 8.1807 13.1571 8.72699C12.9307 9.27328 12.5987 9.76958 12.1804 10.1875M5.82035 10.18C5.40196 9.76208 5.07004 9.26578 4.84358 8.71949C4.61712 8.1732 4.50056 7.58763 4.50056 6.99626C4.50056 6.40489 4.61712 5.81932 4.84358 5.27303C5.07004 4.72674 5.40196 4.23044 5.82035 3.81251M14.3029 1.69751C15.7089 3.10397 16.4987 5.01128 16.4987 7.00001C16.4987 8.98874 15.7089 10.8961 14.3029 12.3025M3.69785 12.3025C2.29182 10.8961 1.50195 8.98874 1.50195 7.00001C1.50195 5.01128 2.29182 3.10397 3.69785 1.69751M10.5004 7.00001C10.5004 7.82844 9.82878 8.50001 9.00035 8.50001C8.17192 8.50001 7.50035 7.82844 7.50035 7.00001C7.50035 6.17158 8.17192 5.50001 9.00035 5.50001C9.82878 5.50001 10.5004 6.17158 10.5004 7.00001Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

function NavScansIcon(props: IconProps): JSX.Element {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default NavScansIcon;
