import { ComponentsOverrides, TableClasses, TablePaginationClasses } from '@mui/material';
import { createTheme, Theme } from '@mui/material/styles';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

const defaultTheme = createTheme();

export const TableStyles: Partial<OverridesStyleRules<keyof TableClasses, 'MuiTable', Omit<Theme, 'components'>>> | undefined =
  {
    root: {
      borderColor: '#E7EBEE', // grey200
    },
  };

export const CellStyles: ComponentsOverrides<Theme>['MuiTableCell'] =
  {
    root: {
      color: '#1B273A', // grey800, text.primary
      cursor: 'default',
      border: 'none'
    },
  };

export const RowStyles: ComponentsOverrides<Theme>['MuiTableRow'] =
  {
    root: {
      boxShadow: '0 1px 0 0 #E7EBEE'
    },
    hover: {
      backgroundColor: '#F7F9FA', // grey100
      '& :hover': {
        backgroundColor: '#F7F9FA', // grey100
      }
    }
  };

export const PaginationStyles: Partial<OverridesStyleRules<keyof TablePaginationClasses, 'MuiTablePagination', Omit<Theme, 'components'>>> | undefined =
  {
    root: {
      borderTop: '1px solid #E7EBEE', // grey200
      cursor: 'default',
    },
    input: {
      marginLeft: 'initial',
      cursor: 'default',
      [defaultTheme.breakpoints.down('md')]: {
        marginRight: '0.5rem !important',
      },
    },
    select: {
      paddingRight: '2rem !important',
      paddingLeft: '0.5rem !important',
    },
    selectIcon: {
      right: '1rem',
    },
    actions: {
      marginLeft: '0.5rem',
    },
    toolbar: {
      paddingLeft: '2rem',
      paddingRight: '0.125rem',
      [defaultTheme.breakpoints.down('md')]: {
        padding: 'initial',
      },
    },
  };