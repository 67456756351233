import {
  MuiSearchInput,
  tableTypes,
  UIMenu,
  UITable,
} from '@shared/components';
import { allRoutesEnum, globalEnums, globalModels, globalQueries } from '@shared/duck';
import useItemSelection from '@shared/hooks/use-item-selection';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from '@shared/hooks';
import { findingsTableHooks, findingsTableUtils } from './duck';
import { IssuesTriangleGray } from '@assets';
import { enqueueSnackbar } from 'notistack';
import { Checkbox, Stack } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

interface FindingsTableProps {
  scanId: string;
  applicationId?: string | null;
  refetchInterval?: number;
  projectId?: string;
  scanEnded: boolean;
}

const FindingsTable: React.FC<FindingsTableProps> = ({
  scanId,
  refetchInterval,
  projectId,
  scanEnded,
}) => {
  const { targetId = '', applicationId: applicationIdParam = '', scanId: scanIdParam = '' } = useParams();
  const navigate = useNavigate();
  const { deleteIssues } = globalQueries.useDeleteIssues();
  const { debouncedValue: debouncedSearch, onChange: onSearchChange } = useDebounce<string>();

  const {
    issueKinds = [],
    fetchIssueKindListNextPage,
    isIssueKindsLoading,
    issueKindListHasNextPage,
  } = findingsTableHooks.useFindingsTableApi({
    scanId,
    projectId,
    refetchInterval,
    filter: debouncedSearch,
  });

  const itemSelectionApi = useItemSelection<globalModels.IssueKindStatisticsViewModel>({
    data: issueKinds,
    keyBy: item => item.id,
    labelBy: item => item.name,
  });

  let url: string = scanIdParam
    ? allRoutesEnum.ScanDetailsIssueKind
    : allRoutesEnum.ApplicationScanDetailsIssueKind;
  url = url
    .replace(':applicationId', applicationIdParam || '')
    .replace(':targetId', targetId || '')
    .replace(':scanId', scanId);

  const tableConfig: tableTypes.UITableCommonProps<globalModels.IssueKindStatisticsViewModel> = {
    data: issueKinds,
    columns: findingsTableUtils.getFindingsColumns(url),
    loading: !scanEnded || isIssueKindsLoading,
    isLoadMoreAvailable: issueKindListHasNextPage,
    onLoadMoreClick: fetchIssueKindListNextPage,
    onRowClick: (item: globalModels.IssueKindStatisticsViewModel) => {
      url = url
        .replace(':kindId', item.kindId.toString() || '');
      navigate(url);
    },
    noContentMessage: 'No Issues found',
    noContentIcon: IssuesTriangleGray,
    itemSelection: { itemSelectionApi },
    withExternalBorders: false,
  };

  const { showModal } = useGlobalModalContext();

  const onDeleteIssues = React.useCallback(async () => {
    const onConfirm = async () => {
      if (itemSelectionApi.selectedItems.size > 0) {
        try {
          const kindIds = Array.from(itemSelectionApi.selectedItems).map((item) => {
            return item[1].kindId;
          });

          await deleteIssues({
            kind: kindIds,
            scan: [scanId],
          });
          enqueueSnackbar('Selected Issues have been deleted successfully', { variant: 'success' });
          itemSelectionApi.reinitialize(new Map());
        } catch (error) {
          enqueueSnackbar('Failed to delete selected Issues', { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Please select items', { variant: 'warning' });
      }
    };
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete Issues?',
        deleteText: 'Are you sure you want to delete the selected items',
        textAfterName: 'from this Scan',
        onConfirm,
      },
    });

  }, [itemSelectionApi.selectedItems]);

  React.useEffect(() => {
    const selectedItems = new Map(itemSelectionApi.selectedItems);
    itemSelectionApi.reinitializeData(issueKinds);

    itemSelectionApi.selectedItems.forEach((item) => {
      if (selectedItems.has(item.id) && !issueKinds.find((issue) => issue.id === item.id)) {
        selectedItems.delete(item.id);
      }
    });
    itemSelectionApi.reinitialize(selectedItems);
  }, [issueKinds]);

  return (
    <>
      <Stack
        borderBottom={`1px solid ${muiPalette.grey?.[200]}`}
        p='0.5rem 1rem'
        pl='0.75rem'
        direction='row'
        justifyContent='space-between'
      >
        <Stack direction='row' alignItems='center'>
          <Checkbox
            checked={itemSelectionApi?.isAllSelected || itemSelectionApi?.isPartiallySelected}
            indeterminate={itemSelectionApi?.isPartiallySelected}
            onChange={itemSelectionApi?.onToggleAll}
            disableRipple
            disabled={!issueKinds.length}
            size='small'
            sx={{
              padding: '0',
            }}
          />

          <UIMenu
            itemList={[{
              title: 'Delete',
              itemAction: () => onDeleteIssues(),
              isRedText: true,
              disabled: !issueKinds.length || !itemSelectionApi.selectedItems.size,
              tooltipText: 'Please select Issues',
            }]}
            size='small'
            iconVariant={globalEnums.EMuiIconButtonVariants.DEFAULT}
          />
        </Stack>

        <MuiSearchInput
          placeholder='Search'
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </Stack>

      <div style={{ overflowY: 'auto' }}>
        <UITable
          {...tableConfig}
        />
      </div>
    </>
  );
};

export default FindingsTable;