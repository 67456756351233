/* tslint:disable */
/* eslint-disable */
/**
 * NightVision REST API
 * This is the NightVision REST API, which powers [NightVision Security](https://app.nightvision.net).  ## Available Endpoints  * [Authentication](#auth): exchange user credentials for API access token.  ### Enums  * [Enums](#enums): get all enums.  ### Projects  * [Projects](#projects): manage projects.  ### Credentials  * [Credentials](#credentials): manage credentials used during scans. This includes Headers and Playwright Scripts.  ### Nuclei Templates  * [Nuclei Repositories](#nuclei-repositories): manage Nuclei Repositories used in targets.  ### Applications  * [Applications](#applications): manage Applications configuration used during scans.  ### Targets  * [URL Targets](#targets/targets_url_list): manage URL Targets scanned by NightVision. * [API Specification Targets](#targets/targets_openapi_list): manage API Specification Targets scanned by NightVision. * [GitHub Targets](#targets/targets_github_list): manage GitHub Repository Targets scanned by NightVision. *  * [Targets](#targets): manage Targets by types scanned by NightVision.  Target Type values: - 1 - GitHub - 2 - URL - 3 - OpenAPI  GitHub Target Visibility values: - 1 - Public - 2 - Private - 3 - Internal  ### Scanning  * [Scans](#scans): manage scans.  Scan Status values: - 1 - Succeeded - 2 - Running - 3 - Aborted - 4 - Failed - 5 - Timed Out  Issue Resolution values: - 0 - Open - 1 - Resolved - 2 - False Positive  Check Status values: - 0 - Fail - 1 - Pass - 2 - Disrupted - 3 - In Progress  ### Issues  * [Issues](#issues): manage issues. * [Kinds of Issues](#issue-kinds): manage issue kinds. * [Writeups of Issues](#issue-writeups): manage issue writeups.  ### Checked path info  * [Checked path info](#checked-path-info): Info about all checked paths. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConfigurationParameters {
    apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>);
    username?: string;
    password?: string;
    accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string) | ((name?: string, scopes?: string[]) => Promise<string>);
    basePath?: string;
    baseOptions?: any;
    formDataCtor?: new () => any;
}

export class Configuration {
    /**
     * parameter for apiKey security
     * @param name security name
     * @memberof Configuration
     */
    apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>);
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    username?: string;
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    password?: string;
    /**
     * parameter for oauth2 security
     * @param name security name
     * @param scopes oauth2 scope
     * @memberof Configuration
     */
    accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string) | ((name?: string, scopes?: string[]) => Promise<string>);
    /**
     * override base path
     *
     * @type {string}
     * @memberof Configuration
     */
    basePath?: string;
    /**
     * base options for axios calls
     *
     * @type {any}
     * @memberof Configuration
     */
    baseOptions?: any;
    /**
     * The FormData constructor that will be used to create multipart form data
     * requests. You can inject this here so that execution environments that
     * do not support the FormData class can still run the generated client.
     *
     * @type {new () => FormData}
     */
    formDataCtor?: new () => any;

    constructor(param: ConfigurationParameters = {}) {
        this.apiKey = param.apiKey;
        this.username = param.username;
        this.password = param.password;
        this.accessToken = param.accessToken;
        this.basePath = param.basePath;
        this.baseOptions = param.baseOptions;
        this.formDataCtor = param.formDataCtor;
    }

    /**
     * Check if the given MIME is a JSON MIME.
     * JSON MIME examples:
     *   application/json
     *   application/json; charset=UTF8
     *   APPLICATION/JSON
     *   application/vnd.company+json
     * @param mime - MIME (Multipurpose Internet Mail Extensions)
     * @return True if the given MIME is JSON, false otherwise.
     */
    public isJsonMime(mime: string): boolean {
        const jsonMime: RegExp = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
        return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
    }
}
