import { Button, CircularProgress, Stack } from '@mui/material';
import { UICloseBtn } from '@shared/components';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';
import { DialogTitle, Footer, Header } from './scan-dialog-layout.styled';
import { useMedia } from '@shared/hooks';
import { globalUtils } from '@shared/duck';

interface ScanDialogLayoutProps {
  title: string;
  titleIcon?: string;
  children: React.ReactNode;
  onClose: () => void;
  isOpenCli: boolean;
  onSubmit: (e?: any) => void;
  disabled?: boolean;
  isOpenCommandsPage: boolean;
  onBackClick: () => void;
  isSubmitting?: boolean;
}

const ScanDialogLayout: React.FC<ScanDialogLayoutProps> = ({
  title,
  titleIcon,
  children,
  onClose,
  isOpenCli,
  onSubmit,
  disabled = false,
  isOpenCommandsPage,
  onBackClick,
  isSubmitting,
}) => {
  const { isSmallScreen } = useMedia();
  return (
    <Stack gap={0} flexGrow={1} overflow='auto'>
      <Header
        direction='row'
        justifyContent='space-between'
        padding={isSmallScreen ? '1rem' : '1rem 5rem'}
      >
        <Stack direction='row' gap={1} alignItems='center'>
          {titleIcon && globalUtils.createCustomIcon(titleIcon, {})}
          <DialogTitle noWrap>{title}</DialogTitle>
        </Stack>
        <UICloseBtn onClose={onClose} />
      </Header>

      <Stack
        gap={0}
        padding={isSmallScreen ? '1rem' : '3rem 5rem'}
        flexGrow={1}
        direction='row'
        sx={{ backgroundColor: muiPalette.grey?.['100'] }}
        overflow='auto'
      >
        {children}
      </Stack>

      <Footer padding={isSmallScreen ? '1rem' : '1.5rem 9rem'}>
        <Button onClick={isOpenCommandsPage ? onBackClick : onClose}>
          {isOpenCommandsPage ? 'Back' : 'Cancel'}
        </Button>
        <Button
          variant='contained'
          disabled={isOpenCommandsPage ? false : disabled || isSubmitting}
          onClick={onSubmit}
          endIcon={isSubmitting && (
            <CircularProgress color='inherit' size={20} />
          )}
        >
          {isOpenCli ? isOpenCommandsPage ? 'I started the scan' : 'Generate Command' : 'Start Scan'}
        </Button>
      </Footer>
    </Stack>
  );
};

export default ScanDialogLayout;