import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';

const Header = styled(Typography)`
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 0.5rem;
  color: ${baseTokens.gray900};
`;

const Description = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: ${baseTokens.gray500};
  white-space: pre-line;
`;

export { Header, Description };