import { allRoutesEnum } from '@shared/duck';
import { BREADCRUMBS_NAME_MAP } from './constants';

export const findMatchedBreadcrumbName = (url: string) => {
  const matchedKey = Object.keys(BREADCRUMBS_NAME_MAP).find((key) => {
    const pathTemplate = key.replace(/:[^/]+/g, '[^/]+');
    const regex = new RegExp(`^${pathTemplate}$`);

    return regex.test(url);
  }) || '';

  return matchedKey && BREADCRUMBS_NAME_MAP[matchedKey];
};

export const getPageTitleByEntityName = (entityName: string) => {
  return BREADCRUMBS_NAME_MAP[`/${entityName}`];
};

export const isHomePage = (path: string) => {
  const pathNames = path.split('/').filter(Boolean);
  return pathNames[0] === allRoutesEnum.Home.substring(1) && pathNames.length === 1;
};