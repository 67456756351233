import { LinearProgress, TableBody, TableCell, TableRow } from '@mui/material';
import { BasicBodyRow, GroupedRow } from './components';
import { tableTypes } from '@shared/components/table/duck';
import { globalTypes } from '@shared/duck';
import { groupBy } from 'lodash';
import React from 'react';

interface BasicTableBodyProps<DataItem> extends tableTypes.BasicMuiTableProps<DataItem> {
  data: DataItem[];
  columns: tableTypes.ColumnProps<DataItem>[];
}

const BasicTableBody = <DataItem extends globalTypes.UIDItem>({
  data,
  columns,
  loading,
  groupRowsBy = () => '',
  itemActions,
  disableRow,
  ...props
}: BasicTableBodyProps<DataItem>) => {
  const groupedItems = React.useMemo(() => {
    return groupBy(data, groupRowsBy);
  }, [data, groupRowsBy]);

  return (
    <TableBody>
      {loading && (
        <TableRow>
          <TableCell
            padding='none'
            colSpan={columns.length + (
              itemActions?.length ? 1 : 0
            ) + (
              props.itemSelection ? 1 : 0
            )}
          >
            <LinearProgress />
          </TableCell>
        </TableRow>
      )}

      {data.length ? (
        <>
          {Object.entries(groupedItems)
            .map((
              ([group, items]) => (
                <React.Fragment key={group}>
                  {group ? (
                    <GroupedRow
                      columns={columns}
                      group={group}
                      itemSelection={props.itemSelection}
                      itemActions={itemActions}
                    />
                  ) : null}

                  {items.map((dataItem, index) => (
                    <BasicBodyRow<DataItem>
                      key={dataItem?.id ? (
                        dataItem.toString.length == 0 ? `index-${index}` : dataItem.id
                      ) : `item-${index}`}
                      rowKey={dataItem.id}
                      dataItem={dataItem}
                      columns={columns}
                      index={index}
                      itemActions={itemActions}
                      disableRow={disableRow}
                      {...props}
                    />
                  ))}
                </React.Fragment>
              )
            ))}
        </>
      ) : null}
    </TableBody>
  );
};

export default BasicTableBody;