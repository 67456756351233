import { Typography, styled, Box, Grid } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { LinkRouter } from './components';

const StyledTopBar = styled(Grid)`
    display: flex;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid ${muiPalette.grey?.[200]};
    align-items: center;
    z-index: 10;
    position: sticky;
`;

const StyledNotificationContainer = styled(Box)`
    display: flex;
    justify-content: center;

    .ellipse {
        position: absolute;
        padding-left: 0.5rem;
        top: 1.75rem;
    }
`;

const ActivePage = styled(Typography)`
  font-size: 0.875rem; 
  line-height: 1.25rem; 
  letter-spacing: -0.00963rem;
  color: #111B2B !important; 
  font-weight: 500;
`;

const NotActivePageLink = styled(LinkRouter)`
  display: flex;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.00963rem;
  color: ${muiPalette.grey?.[500]} !important;
`;

export { StyledTopBar, StyledNotificationContainer, ActivePage, NotActivePageLink };
