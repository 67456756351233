import { Tabs } from '@contentful/f36-components';
import React from 'react';
import { UICodeFragment } from '@shared/components';
import { globalEnums } from '@shared/duck';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';

const NucleiInstallModal: React.FC = () => {
  return (
    <Box p='0.5rem 0'>
      <Accordion>
        <AccordionSummary>
          <Typography fontWeight='500'>Install Nuclei</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Tabs defaultTab='Go'>
            <Tabs.List variant='horizontal-divider' loop>
              <Tabs.Tab panelId='Go'>Go</Tabs.Tab>
              <Tabs.Tab panelId='Brew'>Brew</Tabs.Tab>
              <Tabs.Tab panelId='Docker'>Docker</Tabs.Tab>
              <Tabs.Tab panelId='Binary'>Binary</Tabs.Tab>
            </Tabs.List>

            <Box>
              <Tabs.Panel id='Go'>
                <Stack pt='0.75rem'>
                  <Typography variant='default'>
                    Nuclei require latest GO version to install successfully.
                  </Typography>
                  <UICodeFragment
                    code={'go install -v github.com/projectdiscovery/nuclei/v2/cmd/nuclei@latest'}
                    language={globalEnums.SupportedLangs.evidence_markup}
                  />
                </Stack>
              </Tabs.Panel>
              <Tabs.Panel id='Brew'>
                <Stack pt='0.75rem'>
                  <Typography variant='default'>
                    Supported in macOS (or Linux).
                  </Typography>
                  <UICodeFragment
                    code={'brew install nuclei'}
                    language={globalEnums.SupportedLangs.evidence_markup}
                  />
                </Stack>
              </Tabs.Panel>
              <Tabs.Panel id='Docker'>
                <UICodeFragment
                  code={'docker pull projectdiscovery/nuclei:latest'}
                  language={globalEnums.SupportedLangs.evidence_markup}
                />
              </Tabs.Panel>
              <Tabs.Panel id='Binary'>
                <Stack pt='0.75rem'>
                  <Typography variant='default'>
                    Download the latest binary for your OS. Unzip the ready to run binary.
                  </Typography>
                  <UICodeFragment
                    code={'https://github.com/projectdiscovery/nuclei/releases'}
                    language={globalEnums.SupportedLangs.evidence_markup}
                  />
                </ Stack>
              </Tabs.Panel>
            </Box>
          </Tabs>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default NucleiInstallModal;