export const getBorderColor = (variant: string): string | undefined => {
  switch (variant) {
    case 'success': {
      return '#4CAF50';
    }
    case 'error': {
      return '#EF5350';
    }
    case 'info': {
      return '#03A9F4';
    }
    case 'warning': {
      return '#FF9800';
    }
  }
  return undefined;
};