import { IconProps } from '../BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from '../icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='18'
    height='16'
    viewBox='0 0 18 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <path
      d='M16.5 13.25C16.5 13.6478 16.342 14.0294 16.0607 14.3107C15.7794 14.592 15.3978 14.75 15 14.75H3C2.60218 14.75 2.22064 14.592 1.93934 14.3107C1.65804 14.0294 1.5 13.6478 1.5 13.25V2.75C1.5 2.35218 1.65804 1.97064 1.93934 1.68934C2.22064 1.40804 2.60218 1.25 3 1.25H6.75L8.25 3.5H15C15.3978 3.5 15.7794 3.65804 16.0607 3.93934C16.342 4.22064 16.5 4.60218 16.5 5V13.25Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

function NavProjectsIcon(props: IconProps): JSX.Element {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default NavProjectsIcon;
