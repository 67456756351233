import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { allSubroutesEnum } from 'pages/all-subroutes-enum';
import NewScanPage from './new-scan-page';
import { NotFoundErrorWrapper } from '@pages';

const NewScanPageRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={allSubroutesEnum.Root} element={<NewScanPage />} />
      <Route path='*' element={<NotFoundErrorWrapper />} />
    </Routes>
  );
};

export default NewScanPageRouter;