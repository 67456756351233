import { Project } from '@api-client';
import { Typography } from '@mui/material';
import React from 'react';

interface CustomOptionProps {
  option: Project;
}

const CustomOption: React.FC<CustomOptionProps> = ({
  option,
  ...props
}: CustomOptionProps) => {
  return (
    <Typography
      {...props}
      noWrap
      lineHeight='1.25rem'
      fontSize='0.875rem'
      style={{ display: 'block' }}
    >
      {option.name}
    </Typography>
  );
};

export default CustomOption;