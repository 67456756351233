import { globalModels, globalQueries, globalUtils } from '@shared/duck';
import React from 'react';

interface TargetsTableApiResult {
  targets: globalModels.TargetViewModel[],
  totalItemsCount: number,
  isTargetsListLoading: boolean,
  targetsHasNextPage: boolean,
  fetchNextTargetsPage: () => void,
}

interface useTargetsTableApiProps {
  credentialsId: string;
  page: number;
  pageSize?: number;
}

export const useTargetsTableApi = ({
  page,
  pageSize,
  credentialsId,
}: useTargetsTableApiProps): TargetsTableApiResult => {
  const {
    scans: dtoScans,
    totalItemsCount,
    isScansListLoading,
    scansHasNextPage = false,
    fetchNextScansPage,
  } = globalQueries.useGetUniqueScansByCredentialsId({
    id: credentialsId,
    page: page + 1,
    pageSize: pageSize,
  });

  const targets = React.useMemo(
    () =>
      dtoScans?.map(
        (scan) => globalUtils.mapScanDtoToTargetViewModel(scan)) || [],
    [dtoScans],
  );

  return {
    targets,
    totalItemsCount,
    isTargetsListLoading: isScansListLoading,
    targetsHasNextPage: scansHasNextPage,
    fetchNextTargetsPage: fetchNextScansPage,
  };
};