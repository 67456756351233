import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { MuiTextInput } from '../text-input';
import { MagnifyingGlass } from '@phosphor-icons/react';


type MuiSearchInputProps = {
  width?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  inputSx?: SxProps<Theme>;
};

type Controllable = {
  controllable: true;
  value: string;
}

type Uncontrollable = {
  controllable?: false;
  value?: string;
}

const MuiSearchInput: React.FC<MuiSearchInputProps & (Controllable | Uncontrollable)> = ({
  width = '18.75rem',
  onChange,
  disabled = false,
  placeholder = 'Search...',
  value = undefined,
  ...props
}) => {
  return (
    <Box sx={{ maxWidth: width, width: '100%' }}>
      <MuiTextInput
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <MagnifyingGlass
              size={20}
              color='#67728A'
              style={{ overflow: 'unset' }}
            />
          ),
        }}
        sx={{
          '& .MuiInputBase-input': {
            ml: '0.5rem',
          },
          '& .MuiInputBase-root': {
            // padding: '0.625rem 0.75rem',
          },
          ...props.inputSx
        }}
        onChange={onChange}
      />
    </Box>
  );
};

export default MuiSearchInput;
