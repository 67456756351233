import { Issue } from '@api-client';
import React from 'react';
import dayjs from 'dayjs';
import { useTechnologies } from '@shared/duck/utils/technologies';
import { Link } from 'react-router-dom';
import { allRoutesEnum, globalConstants, globalUtils } from '@shared/duck';
import { ExternalLinkIconC } from '@assets';
import { Box, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

interface ScanLinkProps {
  issue: Issue;
  showDetectionTime?: boolean;
}

const IssueScanLink: React.FC<ScanLinkProps> = ({ issue, showDetectionTime: showDate }) => {
  const technologies = useTechnologies();
  const scanName = issue.scan.target_name || '';
  const technology = globalUtils.getTargetTypeFromDto(issue.scan);
  const tech = issue && technologies.get(technology);
  const TechIcon = tech?.icon;
  const detectedAt = dayjs(issue.created_at).format(globalConstants.FULL_MONTH_DATE);
  return (
    <>
      <Link
        to={allRoutesEnum.ScanDetails.replace(':scanId', issue.scan_id)}
        target='_blank'
        style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}
      >
        {TechIcon &&
          <TechIcon
            style={{
              height: '1.125rem',
              width: '1.125rem',
            }}
          />}
        <Typography variant='default' color={muiPalette.grey?.[500]}>
          {scanName}
        </Typography>
        <Box width='0.875rem'>
          <ExternalLinkIconC />
        </Box>
      </Link>
      {showDate && (
        <>
          <Typography variant='default' fontSize='0.75rem' color={muiPalette.grey?.[500]}>
            {detectedAt}
          </Typography>
        </>
      )}
    </>
  );
};

export default IssueScanLink;
