import { NotFoundErrorWrapper, ScanDetailsRoutes } from '@pages';
import { allSubroutesEnum } from 'pages/all-subroutes-enum';
import { ApplicationDetails } from './components';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ApplicationsPage from './applications-page';
import { globalUtils } from '@shared/duck';

const ApplicationsRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={allSubroutesEnum.Root} element={<ApplicationsPage />} />
      <Route path={allSubroutesEnum.ApplicationDetails} element={<ApplicationDetails />} />
      <Route path={globalUtils.withSubRoutes(allSubroutesEnum.ApplicationScanDetails)} element={<ScanDetailsRoutes />} />
      <Route path='*' element={<NotFoundErrorWrapper />} />
    </Routes>
  );
};

export default ApplicationsRouter;
