import { tableTypes } from '@shared/components';
import { allRoutesEnum, globalModels } from '@shared/duck';

export const authColumns: tableTypes.ColumnProps<globalModels.AuthenticationsViewModel>[] = [
  {
    title: 'Name',
    columnKey: 'name',
    minWidth: '30.375rem',
    cellInfo: {
      cellTextWeight: 600,
      description(item) {
        return (
          item.description
        );
      },
      href(item) {
        return allRoutesEnum.AuthenticationDetails.replace(':authenticationId', item.id);
      }
    },
  },
  {
    title: 'Authentication Type',
    columnKey: 'authenticationType',
    minWidth: '9.0625rem',
  },
];