import React from 'react';
import { useFormikContext } from 'formik';
import { ApiTargetsContentValues } from '../../duck';
import { useDebounce } from '@shared/hooks';
import { CircularProgress, InputAdornment, InputBaseComponentProps } from '@mui/material';
import { MuiTextInput } from 'shared/components';
import { globalUtils } from 'shared/duck';
import { Check } from '@phosphor-icons/react';

interface SwaggerFileInputProps extends InputBaseComponentProps {
  strictSwaggerSpecValidation?: boolean;
}

const SwaggerFileInput: React.FC<SwaggerFileInputProps> = ({
  strictSwaggerSpecValidation,
  ...props
}: SwaggerFileInputProps) => {
  const { values, initialValues, setFieldValue } = useFormikContext<ApiTargetsContentValues>();
  const { debouncedValue, onChange: onDebounceChange } = useDebounce<string>(values.newTarget.fileLocation);
  const [changes] = React.useState({ count: 0 });
  const [loading, setLoading] = React.useState(false);
  const [abortController, setAbortController] = React.useState(new AbortController());

  const fileUrlValidation = async (abortController: AbortController) => {
    if (debouncedValue) {
      if (debouncedValue === initialValues.newTarget.fileLocation) {
        setFieldValue('newTarget.isFileUrlValid', true);
        setFieldValue('newTarget.isFileUrlValid2', true);
        setLoading(false);
        return;
      }
      setFieldValue('newTarget.isFileUrlValid', undefined);
      const currCounter = changes.count;
      setLoading(true);
      const res = await globalUtils.validateOpenApiSpec({ api: debouncedValue, isFile: false, abortController, strictCheck: strictSwaggerSpecValidation });
      if (res.isValid !== undefined) {
        // not aborted
        setLoading(false);
        if (changes.count === currCounter) {
          if (res.errorCode === 'access_error') {
            setFieldValue('newTarget.isFileUrlValid2', false);
          }
          else {
            setFieldValue('newTarget.isFileUrlValid2', true);
            setFieldValue('newTarget.isFileUrlValid', res.isValid);
          }
        }
      }
    }
  };

  React.useLayoutEffect(() => {
    if (!values.newTarget.isFileUrlValid)
      fileUrlValidation(abortController);
  }, [debouncedValue]);

  return (
    <>
      <MuiTextInput
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        name={props.name}
        error={props.error}
        inputProps={props}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {loading
                ? <CircularProgress size={20} color='inherit' />
                : values.newTarget.isFileUrlValid
                  ? <Check size={20} color="green" />
                  : <></>}
            </InputAdornment>
          )
        }}
        onChange={(e) => {
          setFieldValue('newTarget.isFileUrlValid', undefined);
          abortController.abort();
          const newAbortController = new AbortController();
          setLoading(false);
          setAbortController(newAbortController);
          changes.count++;
          onDebounceChange(e.target.value);
          props.onChange?.(e);
        }}
      />
    </>

  );
};

export default SwaggerFileInput;