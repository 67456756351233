import { IssueKind } from '@api-client';
import { MuiAutocomplete } from '@shared/components';
import { globalQueries, globalTypes } from '@shared/duck';
import { useDebounce } from '@shared/hooks';
import React from 'react';
import { CustomInput } from '../components';
import { selectorsUtils } from '../duck';
import { CustomLinearPaper, KindRenderOption } from './components';

interface IssueKindsSelectProps extends globalTypes.MUISelectProps<IssueKind> {
  label?: string;
  defaultIssueKind?: IssueKind,
  withoutBorders?: boolean;
  isDefaultIssueKindLoading?: boolean;
}

const MuiIssueKindsSelect: React.FC<IssueKindsSelectProps> = ({
  label = 'Issue Kind',
  defaultIssueKind,
  onFormChange,
  isDefaultIssueKindLoading = false,
  ...props
}) => {
  const { debouncedValue, onChange: onInputChange, value: searchInput = '' } = useDebounce<string>();

  const [value, setValue] = React.useState<IssueKind | null>(null);

  React.useEffect(() => {
    defaultIssueKind && setValue(defaultIssueKind);
  }, [defaultIssueKind]);

  const {
    issueKinds = [],
    isIssueKindsListLoading,
    issueKindsHasNextPage,
    fetchNextIssueKindsPage,
  } = globalQueries.useGetIssueKindsList({
    ordering: 'name',
    pageSize: 25,
    filter: debouncedValue,
  });

  React.useEffect(() => {
    /** Note: in the "Edit" mode, must wait until the default value is loaded */
    if (!isDefaultIssueKindLoading) {
      selectorsUtils.handleOnFormChange(value, onFormChange);
      onInputChange('');
    }
  }, [value]);

  const handleInputChange = (value: string) => {
    if (value !== searchInput) {
      onInputChange(value);
    }
  };

  return (
    <MuiAutocomplete
      id='issue-kind-autocomplete'
      options={issueKinds}
      label={label}
      value={value}
      required={props.required}
      error={props.error}
      autoComplete
      autoHighlight
      loadingText='Loading...'
      loading={isDefaultIssueKindLoading || isIssueKindsListLoading}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      onChange={(_, newValue: IssueKind) => setValue(newValue)}
      onInputChange={(_, value) => {
        handleInputChange(value);
      }}
      renderOption={(props, option) => (
        <KindRenderOption kind={option} {...props} />
      )}
      renderInput={(params) => {
        return (
          <CustomInput
            {...params}
            onBlur={props.onBlur}
            isError={props.error}
            name={props.name}
            isLoading={isDefaultIssueKindLoading || isIssueKindsListLoading}
            placeholder='Select'
          />
        );
      }}
      filterOptions={(options) => {
        return options.filter((option) =>
          option.name
            .toLowerCase()
            .trim()
            .includes(searchInput.toLowerCase().trim()));
      }}
      getOptionKey={(option: IssueKind) => option.id}
      getOptionLabel={(option: IssueKind) => option.name}
      noOptionsText='No Issue Kind found'
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 5) {
            !isIssueKindsListLoading && issueKindsHasNextPage && fetchNextIssueKindsPage?.();
          }
        },
      }}
      PaperComponent={CustomLinearPaper}
      slotProps={{
        paper: {
          loading: !!issueKinds.length && isIssueKindsListLoading,
        } as any,
      }}
    />
  );
};

export default MuiIssueKindsSelect;
