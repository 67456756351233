import React from 'react';
import { StyledHeaderContainer } from './modal-header.styled';
import { AddCredentialsPage } from '../../duck';
import { Stack, IconButton, Typography } from '@mui/material';
import { ArrowLeft, X } from '@phosphor-icons/react';

interface ModalHeaderProps {
  page: AddCredentialsPage;
  closeModal: () => void;
  setNewPage: (page: AddCredentialsPage) => void;
  authenticationId?: string;
}

const EDIT = '_edit';

const AuthTitleMap = new Map<string, string>([
  // Create
  [AddCredentialsPage.GENERAL, 'Create new Authentication'],
  [AddCredentialsPage.DOWNLOAD, 'Download NightVision CLI'],
  [AddCredentialsPage.GENERATE_COMMAND, 'Create Auth commands'],

  // Edit
  [AddCredentialsPage.GENERAL + EDIT, 'Edit Authentication'],
  [AddCredentialsPage.DOWNLOAD + EDIT, 'Download NightVision CLI'],
  [AddCredentialsPage.GENERATE_COMMAND + EDIT, 'Update Auth commands'],
  [AddCredentialsPage.RERECORD + EDIT, 'Re-record Auth Script'],
]);

const ModalHeader: React.FC<ModalHeaderProps> = ({
  page,
  closeModal,
  setNewPage,
  authenticationId,
}: ModalHeaderProps) => {
  const setPage = () => setNewPage(
    page === AddCredentialsPage.DOWNLOAD
      ? AddCredentialsPage.GENERATE_COMMAND
      : AddCredentialsPage.GENERAL);

  return (
    <StyledHeaderContainer>
      <Stack flexDirection='row' className='header-container'>
        {page !== AddCredentialsPage.GENERAL && (
          <IconButton
            onClick={setPage}
            size='small'
          >
            <ArrowLeft color='black' size={16} />
          </IconButton>
        )}
        <Typography
          variant='subtitle1'
          fontWeight='600'
          fontSize='1rem'
        >
          {AuthTitleMap.get(`${page}${authenticationId ? EDIT : ''}`)}
        </Typography>
      </Stack>
      <IconButton
        aria-label='Back'
        onClick={closeModal}
        size='small'
      >
        <X color='black' size={16} />
      </IconButton>
    </StyledHeaderContainer>
  );
};

export default ModalHeader;