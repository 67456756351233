import React from 'react';
import { Check } from '@api-client';
import { FlashIconC } from '@assets';
import { globalEnums } from '@shared/duck';
import { UILink } from '@shared/components';
import { columnTypeUtils } from './duck';
import { TypeName } from './components';
import { Stack, Typography } from '@mui/material';

interface ColumnTypeProps {
  item: Check;
}

const ColumnType: React.FC<ColumnTypeProps> = ({
  item,
}) => {
  const [label, setLabel] = React.useState<string>('');
  const [isOwaspType, setOwaspType] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLabel(columnTypeUtils.getCheckTypeName(item.type, item.link));
    setOwaspType(columnTypeUtils.isOwaspType(item.type));
  }, [item.type]);

  if (item.link) {
    return (
      <>
        {isOwaspType ? (
          <Stack direction='row'>
            <FlashIconC />
            <TypeName item={item} label={label}></TypeName>
          </Stack>
        ) : (
          <UILink
            type={globalEnums.ELinksType.EXT_ICON}
            href={item.link}
            text={label}
          />
        )}
      </>
    );
  } else {
    return (
      <Typography variant='default' fontWeight='500'>{label}</Typography>
    );
  }
};

export default ColumnType;