import { CopyIconC } from '@assets';
import { Box, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { Eye, EyeClosed } from '@phosphor-icons/react';
import { MuiTextInput } from '@shared/components';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';
import { TokenResult, transformToDayjs } from '../../duck';
import { FULL_WEEK_MONTH_DATE } from '@shared/duck/constants';

interface GeneratedTokentProps {
  tokenResult: TokenResult | undefined;
  onCopyTokenClick: () => void;
}

const GeneratedTokenContent: React.FC<GeneratedTokentProps> = ({
  tokenResult,
  onCopyTokenClick,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const tokenExpireDate = tokenResult?.expireDate ? transformToDayjs(tokenResult.expireDate).format(FULL_WEEK_MONTH_DATE) : undefined;

  return (
    <Stack>
      <Typography
        variant='subtitle2'
        fontWeight='400'
      >
        {'Make sure to copy your API key now as you will not be able to see it again.'}
      </Typography>
      <Stack flexDirection='row' alignItems='center'>
        <Box flexGrow={1}>
          <MuiTextInput
            value={tokenResult?.token}
            InputProps={{
              readOnly: true,
              endAdornment: (<InputAdornment position="end" >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <EyeClosed size={20} /> : <Eye size={20} />}
                </IconButton>
              </InputAdornment >
              )
            }}
            type={showPassword ? 'text' : 'password'}
          />
        </Box>
        <CopyIconC onClick={onCopyTokenClick} />
      </Stack>
      <Typography
        variant='subtitle2'
        color={muiPalette.grey?.['500']}
        fontSize={'0.875rem'}
        fontWeight={400}
      >
        {tokenExpireDate ? `Expires on ${tokenExpireDate}` : 'Token will never expire'}
      </Typography>
    </Stack>
  );
};

export default GeneratedTokenContent;