import { QueryObserverOptions, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  NucleiRepository,
  NucleiRepositoriesApiNucleiRepositoriesCreateRequest,
  NucleiRepositoriesApiNucleiRepositoriesDestroyRequest,
  NucleiRepositoriesApiNucleiRepositoriesListRequest,
  NucleiRepositoriesApiNucleiRepositoriesRetrieveRequest,
} from 'api-client';
import { AxiosError, AxiosResponse } from 'axios';
import { useApp } from '@shared/hooks';
import { NucleiReposQueryKeys } from './queriesKeys';
import { getNextPageParam, useFlatResults } from './utils';

const useListNucleiRepositories = (request: NucleiRepositoriesApiNucleiRepositoriesListRequest) => {
  const { nucleiReposApi: nucleiApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [NucleiReposQueryKeys.nucleiRepositoriesList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => nucleiApi.nucleiRepositoriesList({ ...request, page: pageParam }),

    getNextPageParam: getNextPageParam,
  });

  const nucleiRepositories = useFlatResults(data);

  return {
    nucleiRepositories: nucleiRepositories,
    isNucleiListLoading: isLoading,
    nucleiListHasNextPage: hasNextPage,
    fetchNextNucleiPage: fetchNextPage,
  };
};

const useGetNucleiRepository = (
  request: NucleiRepositoriesApiNucleiRepositoriesRetrieveRequest,
  config?: Partial<QueryObserverOptions<AxiosResponse<NucleiRepository>, AxiosError>>,
) => {
  const { nucleiReposApi: nucleiApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [NucleiReposQueryKeys.nucleiRepository, ...Object.values(request)],
    queryFn: () => nucleiApi.nucleiRepositoriesRetrieve(request),
    ...config,
  });

  return {
    nucleiRepository: data?.data,
    isNucleiRepositoryLoading: isLoading,
  };
};

const useCreateNucleiRepository = () => {
  const { nucleiReposApi: nucleiApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: NucleiRepositoriesApiNucleiRepositoriesCreateRequest) =>
      nucleiApi.nucleiRepositoriesCreate(request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [NucleiReposQueryKeys.nucleiRepositoriesList] }),
  });

  return {
    createNucleiRepository: mutateAsync,
  };
};

const useDeleteNucleiRepository = () => {
  const { nucleiReposApi: nucleiApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: NucleiRepositoriesApiNucleiRepositoriesDestroyRequest) =>
      nucleiApi.nucleiRepositoriesDestroy(request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [NucleiReposQueryKeys.nucleiRepositoriesList] }),
  });

  return {
    deleteNucleiRepository: mutateAsync,
  };
};

export { useListNucleiRepositories, useCreateNucleiRepository, useDeleteNucleiRepository, useGetNucleiRepository };
