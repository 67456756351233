import styled from '@emotion/styled';
import { PermissionDenied } from '@assets';
import { Stack, Typography } from '@mui/material';
import { generalMuiTheme } from '@shared/general-mui-theme';

const Content = styled(Stack)`
  gap: 2rem;
  flex-direction: row;
  justify-content: center; 
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  background-color: white;
`;

const Image = styled(Stack)`
  background: url(${PermissionDenied}) no-repeat; 
  background-position: left;
  height: 100%;
  flex: 1 0 0;
`;

const Heading = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.5rem; 
  letter-spacing: 0.024rem;
  color: ${generalMuiTheme.palette.grey[900]};
`;

const SubHeading = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem; 
  letter-spacing: 0.02375rem;
  color: ${generalMuiTheme.palette.grey[500]};
  
  margin-top: 1rem;
  white-space: pre-line;
`;

export { Content, Heading, SubHeading, Image };
