import * as apiClient from 'api-client';
import { globalQueries, globalUtils } from '@shared/duck';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

export const useDeletePaths = () => {
  const { deletePathsTargetUrl } = globalQueries.useDeletePathsTargetUrl();

  const { showModal } = useGlobalModalContext();

  const deletePathsHandler = async (targetId: string, paths: apiClient.UrlsAdditionalPathsItem[]) => {
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: paths.length > 1 ? 'Delete selected Paths?' : 'Delete Path?',
        deleteText: 'Are you sure you want to delete ',
        name: paths.length > 1 ? `${paths.length} selected paths` : paths[0].path,
        textAfterName: '',
        warningText: '',
        onConfirm: () => deletePathsTargetUrl({ id: targetId, urlsAdditionalPathsDestroyRequest: { ids: paths.map(path => path.id) } }),
      },
    });
  };

  return { deletePathsHandler };
};

export const endpointsToCsv = (paths: { [key: string]: any }[]): string => {
  const result: string[][] = paths.map(item => [
    item.path || '',
  ]);
  const str =
    'path\n' +
    result
      .map(line =>
        line
          .map(globalUtils.wrapCsvInDoubleQuotesIfNeeded)
          .join(','),
      )
      .join('\n');
  return str;
};