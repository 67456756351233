import React from 'react';
import { ExternalLinkIconC } from '@assets';
import { TextLinkProps } from '@contentful/f36-components';
import { StyledExtLink } from './external-link.styled';

interface BaseLinkProps extends TextLinkProps {
  text: string;
}

const ExternalLink: React.FC<BaseLinkProps> = ({
  text,
  ...props
}) => {
  return (
    <StyledExtLink
      icon={<ExternalLinkIconC id='externalLinkIcon' />}
      alignIcon='end'
      href={props.href}
      target={props.target || '_blank'}
      rel='noopener noreferrer'
      onClick={(e: any) => e.stopPropagation()}
      {...props}
    >
      {text}
    </StyledExtLink>
  );
};

export default ExternalLink;