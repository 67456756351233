import { Typography } from '@mui/material';
import { useMedia } from '@shared/hooks';
import { StyledGridBg } from './greetings.styled';
import React from 'react';

const Greetings: React.FC = () => {
  const { isSmallScreen, isMediumScreen } = useMedia();

  return (
    <StyledGridBg
      item
      xs={12}
      lg={5.5}
      padding={isSmallScreen ? '2rem' : (isMediumScreen ? '3rem' : '7rem')}
    >
      <Typography
        variant='h3'
        fontWeight='600'
        color='white'
        textAlign='center'
      >
        {'The next best thing for app security is here!'}
      </Typography>
    </StyledGridBg>
  );
};

export default Greetings;