import { CheckCountType, getCheckChipColor, textForCheckCountType } from './duck';
import { Chip, ChipProps, Tooltip } from '@mui/material';

export interface CheckStatusProps extends ChipProps {
  type: CheckCountType;
}

const CheckCountBadge = ({ label, type, ...other }: CheckStatusProps) => {
  return (
    <Tooltip
      title={label + ' ' + textForCheckCountType.get(type)}
    >
      <Chip
        label={label}
        color={getCheckChipColor(type)}
        sx={{
          'span': {
            fontSize: '0.75rem',
            fontWeight: 500,
          }
        }}
        {...other}
      />
    </Tooltip>
  );
};

export default CheckCountBadge;
