import { Box, BoxProps, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { withSafeProps } from 'views/utils/common';
import { css } from '@emotion/react';
import { globalEnums } from '@shared/duck';

const getBadgeColors = ({ variant }: { variant: globalEnums.EBadgeVariants }) => {
  let bgColor, color;

  switch (variant) {
    case globalEnums.EBadgeVariants.FEATURED:
      bgColor = muiPalette.purple?.[200];
      color = muiPalette.purple?.[600];
      break;
    case globalEnums.EBadgeVariants.NEGATIVE:
      bgColor = muiPalette.red?.[200];
      color = muiPalette.red?.[600];
      break;
    case globalEnums.EBadgeVariants.POSITIVE:
      bgColor = muiPalette.green?.[200];
      color = muiPalette.green?.[600];
      break;
    case globalEnums.EBadgeVariants.PRIMARY:
      bgColor = muiPalette.blue?.[200];
      color = muiPalette.blue?.[600];
      break;
    case globalEnums.EBadgeVariants.SECONDARY:
      bgColor = muiPalette.grey?.[200];
      color = muiPalette.grey?.[600];
      break;
    case globalEnums.EBadgeVariants.WARNING:
      bgColor = muiPalette.orange?.[200];
      color = muiPalette.orange?.[600];
      break;
    case globalEnums.EBadgeVariants.YELLOW:
      bgColor = muiPalette.yellow?.[200];
      color = muiPalette.yellow?.[800];
      break;
    case globalEnums.EBadgeVariants.UNSPECIFIED:
      bgColor = muiPalette.grey?.[100];
      color = muiPalette.grey?.[400];
      break;
  }

  return css`
      background-color: ${bgColor};

      & .MuiTypography-root {
          color: ${color};
      }
  `;
};

const StyledBadge = styled(
  withSafeProps<
    {
      variant: globalEnums.EBadgeVariants
    } & BoxProps
  >(Box, ['variant']),
)`
    display: flex;
    height: 1.25rem;
    width: fit-content;
    padding: 0 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;

    ${getBadgeColors};

    & .MuiTypography-root {
        text-align: center;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
    }
`;

export { StyledBadge };