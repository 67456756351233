import { startCase } from 'lodash';
import { globalEnums } from '@shared/duck';
import { Badge, BadgeProps } from '@contentful/f36-components';

interface CookieStatusBadgeProps {
  status?: string | boolean;
}

const CookieStatusBadge: React.FC<CookieStatusBadgeProps> = ({
  status
}: CookieStatusBadgeProps) => {
  if (status === undefined) {
    return null;
  }

  let badgeProps: BadgeProps;
  if (typeof status === 'boolean') {
    switch (status) {
      case true:
        badgeProps = {
          children: 'True',
          variant: globalEnums.EBadgeVariants.POSITIVE,
        };
        break;
      case false:
        badgeProps = {
          children: 'False',
          variant: globalEnums.EBadgeVariants.NEGATIVE,
        };
        break;
    }
  }
  else {
    badgeProps = {
      children: startCase(status),
      variant: globalEnums.EBadgeVariants.WARNING,
    };
  }

  return <Badge {...badgeProps} />;
};

export default CookieStatusBadge;
