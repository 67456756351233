import { Stack, Tab, Tabs } from '@mui/material';
import React from 'react';
import { UITabPanel } from './components';
import { globalTypes } from '@shared/duck';

const UITabs: React.FC<globalTypes.UITabsProps> = ({
  tabsProps,
  tabs,
  tabsPanels,
}) => {
  const a11yProps = (tabValue: string | number) => {
    return {
      id: `${tabsProps['aria-label']}-${tabValue}`,
      value: tabValue,
      'aria-controls': `${tabsProps['aria-label']}-tabpanel-${tabValue}`,
    };
  };

  return (
    <Stack
      gap={0}
      flexDirection={tabsProps.orientation === 'vertical' ? 'row' : 'column'}
      sx={{
        backgroundColor: 'background.paper',
        flexGrow: 1,
        overflow: 'hidden',
      }}
    >
      <Tabs
        {...tabsProps}
        value={tabsProps.value}
        onChange={(e, value) => tabsProps.onChange?.(e, value)}
        aria-label={`ui_tabs-${tabsProps['aria-label']}`}
        sx={{
          borderBottom: tabsProps.orientation === 'vertical' ? 0 : 1,
          borderRight: tabsProps.orientation === 'vertical' ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'white',
          ...tabsProps.sx,
        }}
      >
        {tabs.map((tab: globalTypes.UITab, index) => {
          const isTabExist = tab.isExist === undefined ? true : tab.isExist;
          return (
            /** Note: use 'OR null' to calculate tab indicator properly */
            isTabExist ?
              <Tab
                key={`${index}-${tab.value}`}
                {...tab}
                {...a11yProps(tab.value)}
              /> : null
          );
        })}
      </Tabs>

      {tabsPanels.map((tabPanel, index) => {
        const isTabExist = tabs.find((tab) =>
          tab.value === tabPanel.tabPanelId)?.isExist;
        const isPanelExist = isTabExist === undefined ? true : isTabExist;

        return (
          isPanelExist ?
            <UITabPanel
              key={`${index}-${tabPanel.tabPanelId}`}
              {...tabPanel}
            /> : null
        );
      })}
    </Stack>
  );
};

export default UITabs;