import { orderBy } from 'lodash';
import { IssuesContainer } from './severity-dots.styled';
import baseTokens from '@contentful/f36-tokens';
import { Dot } from './components';
import { Tooltip, Typography } from '@mui/material';

export type Severity = 'Critical' | 'High' | 'Medium' | 'Low' | 'Info'
export type SeverityIndex = 1 | 2 | 3 | 4 | 5

export interface SeverityCount {
  severityIndex: SeverityIndex;
  severity: Severity;
  count: number;
}

export interface SeverityDotsProps {
  values: SeverityCount[];
  inProgress?: boolean;
  popupComments?: string;
}

const SeverityColors = (
  () => {
    const colors = new Map<Severity, string>();
    colors.set('Critical', baseTokens.red500);
    colors.set('High', baseTokens.orange400);
    colors.set('Medium', baseTokens.yellow600);
    colors.set('Low', baseTokens.green400);
    colors.set('Info', baseTokens.blue400);
    return colors;
  }
)();

function SeverityDots({ values: issues, inProgress, popupComments = 'issues' }: SeverityDotsProps) {
  return (
    <IssuesContainer>
      {orderBy<SeverityCount>(issues, 'severityIndex').map(issue => (
        <Tooltip
          key={issue.severity}
          title={`${inProgress ? '-' : issue.count} ${issue.severity} ${popupComments}`}
        >
          <div className='severity-item'>
            <Dot color={SeverityColors.get(issue.severity)} size='small' />
            <div className='severity-count'>
              <Typography variant='default' fontWeight='500' noWrap>
                {inProgress ? '-' : issue.count}
              </Typography>
            </div>
          </div>
        </Tooltip>
      ))}
    </IssuesContainer>
  );
}

export default SeverityDots;
