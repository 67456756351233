import React from 'react';
import { muiPalette } from '@shared/general-mui-theme';
import { FormHelperText as MuiFormHelperText } from '@mui/material';

export interface FormHelperTextProps {
  helpText: string;
}

const FormHelperText: React.FC<FormHelperTextProps> = ({
  helpText,
}) => {
  return (
    <MuiFormHelperText
      sx={{
        ml: 0,
        mt: '0.5rem',
        color: muiPalette.grey?.['500'],
        fontSize: '0.875rem',
        lineHeight: '1.25rem'
      }}
    >
      {helpText}
    </MuiFormHelperText>
  );
};

export default FormHelperText;
