import styled from '@emotion/styled';
import { Note } from '@contentful/f36-components';

const StyledNote = styled(Note)`
  .markdown {
    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
`;

export { StyledNote };
