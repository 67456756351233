import { SeverityEnum } from '@api-client';
import { capitalize } from 'lodash';
import React from 'react';
import baseTokens from '@contentful/f36-tokens';

import * as dateFns from 'date-fns';
import { globalQueries } from '@shared/duck';
import { TimeChart } from './time-chart';

function renderDailyXLabels(val: number | string, index: number, ticks: any[]) {
  // Show only first and last label
  if (index === 0 || index === ticks.length - 1) {
    return dateFns.format(new Date(val), 'MMM dd');
  } else {
    return '';
  }
}

function renderMonthlyXLabels(val: number | string) {
  return dateFns.format(new Date(val), 'MMM');
}

const yMin = 0;

const BorderColorBySeverity: { [key in SeverityEnum]: string } = {
  [SeverityEnum.Critical]: baseTokens.red500,
  [SeverityEnum.High]: baseTokens.orange400,
  [SeverityEnum.Medium]: baseTokens.yellow400,
  [SeverityEnum.Low]: baseTokens.green300,
  [SeverityEnum.Info]: baseTokens.blue300,
  [SeverityEnum.Unknown]: baseTokens.gray400,
  [SeverityEnum.Unspecified]: baseTokens.gray100,
};

export interface IssuesTimeChartProps {
  data: globalQueries.IssuesCountBySeverityAndDate[];
  perMonth?: boolean;
  labels?: string[];
  xMin?: string | number;
  xMax?: string | number;
}

const IssuesTimeChart: React.FC<IssuesTimeChartProps> = ({
  data,
  perMonth,
  labels,
  xMax,
  xMin,
}) => {
  const datasets = data.map(x => (
    {
      label: capitalize(x.severity),
      data: x.values.map(v => (
        {
          x: v.date,
          y: v.value,
        }
      )),
      borderColor: BorderColorBySeverity[x.severity],
      backgroundColor: baseTokens.colorWhite,
      hidden: x.hidden,
    }
  ));

  return (
    <TimeChart
      verticalLineOnHover
      datasets={datasets}
      perMonth={perMonth}
      labels={labels}
      axisX={{
        renderTickLabel: perMonth ? renderMonthlyXLabels : renderDailyXLabels,
        min: xMin,
        max: xMax,
      }}
      axisY={{
        min: yMin,
      }}
    />
  );
};

// const styleSkippedSegments = {
//   borderWidth: (ctx: any) => skipped(ctx, 2),
//   borderDash: (ctx: any) => skipped(ctx, [6, 6]),
// }

// const skipped = (ctx: any, value: any) => {
//   return Number.isNaN(ctx.p0.raw.y) || Number.isNaN(ctx.p1.raw.y) ? value : undefined
// }

export default IssuesTimeChart;
