import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { muiPalette } from '@shared/general-mui-theme';

interface AppliedFilterChipProps {
  filter: string;
  name: string;
  icon?: React.ReactNode;
  id: string;
  onDelete: () => void;
  indicator?: boolean;
  count: number;
  onDeleteAllFromIndex?: () => void;
  tooltip?: string;
}

export const AplliedFilterChip: React.FC<AppliedFilterChipProps> = ({
  filter,
  name,
  icon,
  id,
  onDelete,
  indicator,
  count,
  onDeleteAllFromIndex,
  tooltip,
}: AppliedFilterChipProps) => {
  return (
    <Stack direction='row' sx={{ cursor: indicator ? 'pointer' : 'default' }}>
      <Stack direction='row' key={`application-${id}`} alignItems='center'
        sx={{
          backgroundColor: muiPalette.grey?.[200],
          p: '0 0.5rem',
          borderRadius: '0.25rem',
          color: muiPalette.grey?.[600],
        }}
        overflow='hidden'
      >
        <Typography
          lineHeight='1.25rem'
          fontSize='0.875rem'
        >
          {filter}
        </Typography>
        <Stack gap='0.25rem' alignItems='center' direction='row' overflow='hidden'>
          {icon}
          <Typography
            noWrap
            lineHeight='1.25rem'
            fontSize='0.875rem'
            fontWeight='600'
          >
            {name}
          </Typography>
        </Stack>
        <IconButton
          size='small'
          sx={{
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
            p: 0,
          }}
          onClick={() => onDelete()}
        >
          <X size={14} />
        </IconButton>
      </Stack>
      {indicator && count > 0 && (
        <Tooltip
          arrow
          title={tooltip}
          placement='top'
        >
          <Stack direction='row' key={`application-${id}`} alignItems='center'
            sx={{
              backgroundColor: muiPalette.grey?.[200],
              p: '0 0.5rem',
              borderRadius: '0.25rem',
              color: muiPalette.grey?.[600],
            }}
            overflow='hidden'
          >
            <Typography
              lineHeight='1.25rem'
              fontSize='0.875rem'
            >
              +{count}
            </Typography>
            <IconButton
              size='small'
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent !important',
                },
                p: 0,
              }}
              onClick={() => {
                onDeleteAllFromIndex?.();
              }}
            >
              <X size={14} />
            </IconButton>
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
};