import { IconButton, Tooltip } from '@mui/material';
import { MonitorPlay } from '@phosphor-icons/react';
import { allRoutesEnum, globalConstants } from '@shared/duck';
import React from 'react';
import { Link } from 'react-router-dom';

interface ShowcaseLinkProps {
  body: string;
}

const ShowcaseLink: React.FC<ShowcaseLinkProps> = ({
  body,
}) => {
  const id = React.useMemo(() => {
    return Math.floor(Math.random() * 100).toString();
  }, []);

  return (
    <Link
      to={allRoutesEnum.ScanDetailsFindingsShowcase.replace(':id', id)}
      target='_blank'
      style={{
        marginLeft: 'auto',
        marginRight: '0.5rem',
        cursor: 'pointer',
      }}
      onClick={e => !body && e.preventDefault()}
    >
      <Tooltip
        arrow
        title={'View Response in New Tab'}
        placement='top'
        sx={{
          maxWidth: '10rem',
        }}
      >
        <span>
          <IconButton
            aria-label='Demo'
            color='outlined'
            size='small'
            disabled={!body}
            onClick={() => localStorage.setItem(globalConstants.VULNERABLE_RESPONSE_KEY + id, body)}
          >
            <MonitorPlay />
          </IconButton>
        </span>
      </Tooltip>
    </Link>

  );
};

export default ShowcaseLink;