import { BreakpointX } from 'views/utils/design';
import baseTokens from '@contentful/f36-tokens';
import { Box, Stack, styled } from '@mui/material';
import { UIInfoCard } from '@shared/components';
import { muiPalette } from '@shared/general-mui-theme';

interface BillingPlanCardProps {
  isBest: boolean;
  isCurrent: boolean;
}

const BillingPlanCard = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isBest' && prop !== 'isCurrent',
})<BillingPlanCardProps>(({ isBest, isCurrent }) => ({
  gap: 0,
  width: '100%',
  maxWidth: '22rem',
  minWidth: '16rem',
  boxShadow: '0rem 0.8125rem 1.6875rem -0.3125rem rgba(25, 37, 50, 0.15), 0rem 0.5rem 1rem -0.5rem rgba(25, 37, 50, 0.2), 0rem -0.375rem 1rem -0.375rem rgba(25, 37, 50, 0.03)',
  borderRadius: '0.375rem',
  border: isCurrent ? `1px solid ${baseTokens.gray900}` : `1px solid ${baseTokens.gray200}`,

  ...(isBest && {
    borderTop: 'none',
    '&.card:': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  }),

  ...(!isBest && {
    height: 'calc(100% - 2.5rem)',
  }),
}));

interface BillingPlanCardBestMarkProps {
  isCurrent: boolean;
}

const BillingPlanCardBestMark = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCurrent',
})<BillingPlanCardBestMarkProps>(({ isCurrent }) => `
  background: ${muiPalette.blue?.[600]};
  border-top: 1px solid ${isCurrent ? baseTokens.gray900 : baseTokens.gray200};
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
`);


const CardsList = styled(Stack)`
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-position: right;
  gap: 1rem;
  padding-top: 2.5rem;

  @media (max-width: ${BreakpointX.Desktop.max}px) {
    padding-top: 0;
    flex-direction: column;
    align-items: center;
  }
`;

const BillingPlanInfoCard = styled(UIInfoCard)`
  &.card {
    border: none !important;
  }
`;

const BillingPlanItems = styled(Stack)`
  border-top: 1px solid ${baseTokens.gray200};
  flex-direction: column;
`;

export { CardsList, BillingPlanInfoCard, BillingPlanCardBestMark, BillingPlanItems, BillingPlanCard };
