import { menuTypes } from '@shared/components';
import { ColumnProps, ItemSelectionApiProps } from '@shared/components/table/duck/types';

interface CalculateTotalColSpanProps<DataItem> {
  columns: ColumnProps<DataItem>[];
  itemSelection?: ItemSelectionApiProps<DataItem>;
  itemActions?: (item: DataItem) => menuTypes.UIMenuProps;
}

export const calculateTotalColSpan = <DataItem,>({
  columns = [],
  itemSelection,
  itemActions,
}: CalculateTotalColSpanProps<DataItem>) => {
  const selectable = Boolean(itemSelection);
  const withActions = Boolean(itemActions);

  return columns.length + (
    selectable ? 1 : 0
  ) + (
    withActions ? 1 : 0
  );
};