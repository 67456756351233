import { Stack, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const StyledInlineCode = styled(Stack)`
  width: fit-content;
  padding: 0.125rem 0.25rem;
  align-items: flex-start;
  margin: 0 0.5rem;
  flex-direction: row;

  border-radius: 0.25rem;
  border: 1px solid ${muiPalette.grey?.[300]};
  background: ${muiPalette.grey?.[100]};
  
  & .text {
    color: ${muiPalette.blue?.[700]};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }
`;

export { StyledInlineCode };