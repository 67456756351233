import { IconProps } from './BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from './icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...pickCustomSvgProps(props)}>
    <path
      d='M7.00065 0.333332C5.68211 0.333332 4.39318 0.724325 3.29685 1.45687C2.20052 2.18941 1.34604 3.2306 0.841457 4.44878C0.336873 5.66695 0.204851 7.00739 0.462086 8.3006C0.71932 9.59381 1.35426 10.7817 2.28661 11.714C3.21896 12.6464 4.40685 13.2813 5.70005 13.5386C6.99326 13.7958 8.3337 13.6638 9.55188 13.1592C10.7701 12.6546 11.8112 11.8001 12.5438 10.7038C13.2763 9.60747 13.6673 8.31854 13.6673 7C13.6673 6.12452 13.4949 5.25761 13.1599 4.44878C12.8248 3.63994 12.3338 2.90501 11.7147 2.28595C11.0956 1.6669 10.3607 1.17583 9.55188 0.840802C8.74304 0.505771 7.87613 0.333332 7.00065 0.333332ZM7.66732 9.66667C7.66732 9.84348 7.59708 10.013 7.47206 10.1381C7.34703 10.2631 7.17747 10.3333 7.00065 10.3333C6.82384 10.3333 6.65427 10.2631 6.52925 10.1381C6.40423 10.013 6.33399 9.84348 6.33399 9.66667V6.33333C6.33399 6.15652 6.40423 5.98695 6.52925 5.86193C6.65427 5.7369 6.82384 5.66667 7.00065 5.66667C7.17747 5.66667 7.34703 5.7369 7.47206 5.86193C7.59708 5.98695 7.66732 6.15652 7.66732 6.33333V9.66667ZM7.00065 5C6.8688 5 6.73991 4.9609 6.63027 4.88765C6.52064 4.81439 6.43519 4.71027 6.38473 4.58845C6.33428 4.46664 6.32107 4.33259 6.3468 4.20327C6.37252 4.07395 6.43602 3.95516 6.52925 3.86193C6.62249 3.76869 6.74127 3.7052 6.87059 3.67948C6.99992 3.65375 7.13396 3.66695 7.25578 3.71741C7.37759 3.76787 7.48171 3.85332 7.55497 3.96295C7.62822 4.07258 7.66732 4.20148 7.66732 4.33333C7.66732 4.51014 7.59708 4.67971 7.47206 4.80474C7.34703 4.92976 7.17747 5 7.00065 5Z'
      fill="#5A657C"
    />
  </svg>
);

const DarkGrayInfoIcon = (props: IconProps) => {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
};

export default DarkGrayInfoIcon;
