import { Stack, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const TableStyled = styled('table')`
  display: block;
  overflow: auto;
  border-spacing: 0;
  height: 20rem;
  border-radius: unset;

  tr th {
    color: ${muiPalette.grey?.[800]};
    font-weight: 500;
    line-height: 1.4rem;
  };

  tr:hover td {
    background-color: transparent;
  }

  tr td {
    font-weight: 400;
    vertical-align: middle;

    color: ${muiPalette.grey?.[800]};
    font-size: 0.75rem;
  };

`;

const StyledPanel = styled(Stack)`
  flex-direction: row;
  border-bottom: 1px solid ${muiPalette.grey?.[300]};
  align-items: center;
`;

const StyledFlex = styled(Stack)`
  border: 1px solid ${muiPalette.grey?.[300]};
  border-radius: '0.375rem';
  gap: 0;
`;

export { StyledPanel, StyledFlex, TableStyled };
