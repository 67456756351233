import { MuiSearchInput } from '@shared/components';
import { globalTypes } from '@shared/duck';
import { ApplicationDetailsTable } from './components';
import React from 'react';
import { useParams } from 'react-router-dom';
import { applicDetailsUtils } from './duck';
import { useDebounce } from '@shared/hooks';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

const ApplicationDetails: React.FC<globalTypes.PageLayoutProps> = () => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { application, isApplicationLoading } = applicDetailsUtils.getApplication(applicationId);
  const [title, setTitle] = React.useState<string>('Loading...');
  const { debouncedValue: debouncedSearch, onChange: onSearchChange } = useDebounce<string>();

  const { showModal } = useGlobalModalContext();

  const onEditApplication = () => {
    showModal({
      modalType: MODAL_TYPE.ApplicationModal,
      modalProps: {
        applicationId,
        enableProjectSelect: false,
      },
    });
  };

  React.useEffect(() => {
    setTitle(isApplicationLoading ? 'Loading...' : (
      application?.name || 'N/A'
    ));
  }, [isApplicationLoading, application]);

  return (
    </* data-testid='parent'*/>
      <Stack gap='1rem' sx={{ overflow: 'hidden' }}>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
        >
          <Tooltip arrow title={title} placement='top'>
            <Typography
              variant='h5'
              lineHeight='2.25rem'
              noWrap
            >
              {title}
            </Typography>
          </Tooltip>

          <Stack gap='1rem' flexDirection='row'>
            <MuiSearchInput
              onChange={(e) => onSearchChange(e.target.value)}
              inputSx={{ minWidth: '18.75rem' }}
            />

            <Button onClick={onEditApplication}>
              Edit Application
            </Button>
          </Stack>
        </Stack>

        <ApplicationDetailsTable
          applicationId={applicationId}
          filter={debouncedSearch}
        />
      </Stack>

      {/*        <ApplicationDetailsContext.Provider
       value={{ setEditModal }}
       >
       <ApplicationDetailsTable applicationId={applicationId} />
       </ApplicationDetailsContext.Provider>*/}
    </>
  );
};

export default ApplicationDetails;