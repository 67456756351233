import React from 'react';
import { User } from '@api-client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const EmptyProvider = ({ children }: { children: React.ReactNode }) => <>{children}</>;

const useLaunchDarklyProvider = ({ currentUser }: { currentUser?: User }) => {
  const LDProviderRef = React.useRef<React.ElementType>(EmptyProvider);
  const [isLDProviderInitialized, setLDProviderInitialized] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (currentUser && !isLDProviderInitialized) {
      asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '',
        context: {
          kind: 'user',
          key: currentUser.email,
          name: currentUser.first_name + ' ' + currentUser.last_name,
          email: currentUser.email,
        },
        reactOptions: {
          useCamelCaseFlagKeys: false,
        },
      })
        .then((LDProvider: React.ElementType) => {
          LDProviderRef.current = LDProvider;
        })
        .catch((err: Error) => {
          console.error(err);
        })
        .finally(() => {
          setLDProviderInitialized(true);
        });
    }
  }, [currentUser, isLDProviderInitialized]);

  return {
    LDProvider: LDProviderRef.current,
    isLDProviderInitialized,
  };
};

export default useLaunchDarklyProvider;
