import { IconProps } from './BaseIcon';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 32 32"
    fill="none"
    {...(props)}
  >
    <path d="M9.56813 23.7734V14.8108C9.56813 14.5181 9.95447 14.4115 10.1041 14.6638L19.3672 30.3321C19.4192 30.4193 19.5128 30.4739 19.6156 30.4739H23.0953C23.319 30.4739 23.4569 30.2306 23.3424 30.0394L10.0091 7.54576C9.95707 7.45861 9.86341 7.40527 9.76195 7.40527H6.28748C6.12878 7.40527 6 7.53405 6 7.69275V23.7734C6 23.9321 6.12878 24.0609 6.28748 24.0609H9.28065C9.43935 24.0609 9.56813 23.9321 9.56813 23.7734Z" fill="white" />
    <path d="M8.2804 1.43447L21.6137 23.9281C21.6658 24.0153 21.7594 24.0686 21.8609 24.0686H25.3354C25.4941 24.0686 25.6228 23.9398 25.6228 23.7811V7.70049C25.6228 7.54179 25.4941 7.41301 25.3354 7.41301H22.3422C22.1835 7.41301 22.0547 7.54179 22.0547 7.70049V16.6631C22.0547 16.9558 21.6684 17.0624 21.5188 16.8101L12.2557 1.14179C12.2037 1.05464 12.11 1 12.0072 1H8.52756C8.30382 1 8.16593 1.24325 8.2804 1.43447Z" fill="white" />
  </svg>
);

const NightvisionFullIcon = (props: IconProps) => {
  return <CustomSVGIcon {...props} />;
};

export default NightvisionFullIcon;
