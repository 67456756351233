import dayjs from 'dayjs';
import React from 'react';
import { MuiTable, tableTypes } from '@shared/components';
import { allRoutesEnum, globalConstants, globalModels } from '@shared/duck';
import { useScansTableApi, scansColumns } from './duck';
import { ScansGray } from '@assets';
import { Stack } from '@mui/material';

interface ScansTableProps {
  targetId: string;
  closeModal: (url?: string) => void;
}

const ScansTable: React.FC<ScansTableProps> = ({
  targetId,
  closeModal,
}) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, []);

  const {
    scans,
    totalItemsCount,
    isScansListLoading,
    scansHasNextPage,
  } = useScansTableApi(
    {
      target: targetId,
      page: currentPage,
      pageSize: rowsPerPage,
    },
  );

  const scansConfig: tableTypes.MuiTableProps<globalModels.ScansViewModelNew> = {
    data: scans,
    columns: scansColumns,
    loading: isScansListLoading,
    isLoadMoreAvailable: scansHasNextPage,
    onRowClick: (item) => {
      closeModal(allRoutesEnum.ScanDetails.replace(':scanId', item.id));
    },
    withExternalBorders: false,
    noContentMessage: 'No Scans found',
    noContentIcon: ScansGray,
    groupRowsBy: (item: globalModels.ScansViewModelNew) => dayjs(item.createdAt)
      .format(globalConstants.FULL_MONTH_DATE),
    pagination: {
      count: totalItemsCount,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: (e, newPage) => handleChangePage(newPage),
      onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
    },
  };

  return (
    <Stack overflow={'auto'}>
      <MuiTable
        {...scansConfig}
      />
    </Stack>
  );
};

export default ScansTable;