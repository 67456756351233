import { globalConstants, globalUtils } from '@shared/duck';
import * as Yup from 'yup';

export const CreateApplicationValuesSchema = Yup.object().shape({
  name: Yup.string().required('Application name is required').max(100, 'Name must be at most 100 characters')
    .test('isValid', globalConstants.INVALID_NAME_FORMAT, (value?: string) => {
      return globalUtils.validateName(value || '');
    }),
  project: Yup.mixed().test('projects-field', 'Please select a project', (projectId: string) => {
    return projectId ? true : false;
  }),
  labels: Yup.array().of(Yup.string()).test('is-unique', 'Labels must be unique', (values: any) => {
    if (!values || values.length === 0) {
      return true;
    }
    if (values instanceof Array) {
      const duplicates = values.filter((item, index) => values.indexOf(item) !== index);
      return duplicates.length === 0;
    }
    return false;
  }),
  url_patterns: Yup.array().of(Yup.string()),
  xpath_patterns: Yup.array().of(Yup.string()),
  criteria: Yup.array().of(Yup.object().shape({
    issue_kind: Yup.number(),
    target: Yup.string(),
    path: Yup.string(),
    parameter: Yup.string(),
    payload: Yup.string(),
  })),
});