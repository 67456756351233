import { tableTypes } from '@shared/components';
import { globalEnums, globalModels } from '@shared/duck';
import { InfoCircleIcon } from 'views/components/v1/Icons';

export const tokensColumns = (): tableTypes.ColumnProps<globalModels.TokenViewModel>[] => [
  {
    title: 'Token Key',
    columnKey: 'tokenKey',
    headerInfo: {
      icon:
        <InfoCircleIcon
          variant={globalEnums.EIconVariants.MUTED}
          size='small'
        />,
      iconTooltipText: 'First 8 characters of the token',
      tooltipPlace: 'top',
    },
  },
  {
    title: 'Created',
    columnKey: 'createdAt',
  },
  {
    title: 'Expiration',
    columnKey: 'expiresAt',
  },
  // {
  //   title: 'Digest',
  //   columnKey: 'digest',
  //   cellInfo: {
  //     hasUnbreakableTextData: true,
  //   }
  // },
];
