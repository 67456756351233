import { Form } from 'formik';
import { NameInput } from './components';
import { FormikControlMui, UIDialogTitle } from '@shared/components';
import { CircularProgress, Dialog, DialogActions, DialogContent, Button } from '@mui/material';

export interface CreateProjectModalProps {
  onClose: () => void;  // start hiding modal
  onExited: () => void; // hide modal
  isShown: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  isInEdit: boolean;
  isNameTested: boolean;
}

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
  onClose, onExited, isShown, isSubmitting, isValid, isInEdit = false, isNameTested
}: CreateProjectModalProps) => {
  const onCancel = () => onClose();

  return (
    <Dialog
      open={isShown}
      onClose={onCancel}
      onTransitionExited={onExited}
      maxWidth='sm'
      fullWidth
    >
      <Form>
        <UIDialogTitle
          title={isInEdit ? 'Rename project' : 'Create new project'}
          onCloseBtnAction={onCancel}
        />
        <DialogContent dividers>
          <FormikControlMui name='name' fullWidth label='New Project name' showError>
            <NameInput isInEdit={isInEdit} />
          </FormikControlMui>
        </DialogContent>
        <DialogActions>
          <Button
            size='small'
            sx={{
              border: 'none !important',
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            size='small'
            type='submit'
            variant='contained'
            endIcon={isSubmitting && (
              <CircularProgress color='inherit' size={20} />
            )}
            disabled={!isValid || !isNameTested || isSubmitting}
          >
            {isInEdit ? 'Rename' : 'Create Project'}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default CreateProjectModal;
