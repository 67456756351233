import { Box, Stack } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';
import { LoadMoreIcon } from 'views/components/v1/Icons';
import { globalEnums } from '@shared/duck';
import { TextLink } from '@contentful/f36-components';

interface LoadMoreButtonProps {
  onLoadMoreClick: () => void;
  colorTheme?: globalEnums.ETableColorTheme,
  isMui?: boolean,
  loading?: boolean,
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
  onLoadMoreClick,
  colorTheme = globalEnums.ETableColorTheme.LIGHT,
  isMui = false,
  loading,
}: LoadMoreButtonProps) => {
  return (
    <>
      {isMui ? (
        <Box
          sx={
            {
              width: '100%',
              overflow: 'auto',
              maxHeight: 'inherit',
              borderTop: `1px solid ${muiPalette.grey?.[200]}`,
            }
          }
        >
          <TextLink
            onClick={onLoadMoreClick}
            data-testid='load-more-icon'
            isDisabled={loading}
            style={{
              width: '100%',
              minHeight: '3.25rem',
              paddingLeft: '1rem',
              paddingRight: '3rem',
              justifyContent: 'left',
            }}
          >
            <Stack flexDirection='row' sx={{ cursor: loading ? 'not-allowed' : 'pointer' }}>
              <LoadMoreIcon width={14} height={14} style={{ cursor: loading ? 'not-allowed' : 'pointer' }} />
              {'Load more results'}
            </Stack>
          </TextLink>
        </Box>
      ) : (
        <Box
          sx={{
            p: '0.75rem',
            mt: '1rem',
            borderRadius: '0.375rem',
            border: `1px solid ${muiPalette.grey?.[200]}`,
            backgroundColor: colorTheme === globalEnums.ETableColorTheme.DARK
              ? muiPalette.grey?.[100]
              : '#FFFFFF',
          }}
        >
          <TextLink isDisabled={loading} onClick={onLoadMoreClick} data-testid='load-more-icon'>
            <Stack flexDirection='row' sx={{ cursor: loading ? 'not-allowed' : 'pointer' }}>
              <LoadMoreIcon width={14} height={14} style={{ cursor: loading ? 'not-allowed' : 'pointer' }} />
              Load more results
            </Stack>
          </TextLink>
        </Box>
      )}
    </>
  );
};

export default LoadMoreButton;