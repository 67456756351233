import { FieldArray, useField } from 'formik';
import { TextLink, Grid, GridItem } from '@contentful/f36-components';
import { PlusIcon } from 'views/components/v1/Icons';
import { StyledGridItem } from './cookies-based-form.styled';
import React from 'react';
import { FormikControlMui, MuiTextInput } from '@shared/components';
import { X } from '@phosphor-icons/react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

export interface Cookies {
  name: string;
  value: string;
}

export interface ICookiesBasedForm {
  cookie: Cookies[];
}

const EmptyCookies: Cookies = {
  name: '',
  value: '',
};

export const CookiesBasedFormValues: ICookiesBasedForm = {
  cookie: [
    {
      name: '',
      value: '',
    },
  ],
};

const CookiesBasedForm: React.FC = () => {
  const [field] = useField('cookiesBasedForm');
  const cookies = (field.value as ICookiesBasedForm)?.cookie || [];
  const cookieHeader = cookies.map(cookie =>
    cookie.name && cookie.value ? `${cookie.name}=${cookie.value}` : '' )
    .filter(Boolean)
    .join('; ');

  return (
    <>
      <Stack
        gap='0'
        sx={{
          background: muiPalette.grey?.[200],
          borderRadius: '0.375rem',
          p: '0.25rem',
        }}
      >
        {cookieHeader ? (
          <>
            <Typography variant='default' textAlign='center'>This Authentication will add the following header:</Typography>
            <Typography variant='default' fontWeight='600' textAlign='center'>
              Cookie:&nbsp;
              {cookieHeader}
            </Typography>
          </>
        ) : (
          <Typography variant='default' textAlign='center'>Please enter cookie values below</Typography>
        )}
      </Stack>
      <FieldArray
        name='cookiesBasedForm.cookie'
        render={arrayHelpers => (
          <StyledGridItem area='span 1 / span 2'>
            {cookies.map((_, index) => (
              <Box
                key={index}
                sx={{
                  position: 'relative',
                  border: `1px solid ${muiPalette.grey?.[200]}`,
                  borderRadius: '0.375rem',
                  p: '1rem',
                  mb: '1rem',
                }}
              >
                <Grid columns='1fr 1fr'>
                  <GridItem>
                    <FormikControlMui name={`cookiesBasedForm.cookie[${index}].name`} label='Cookie name' mb='0.75rem'>
                      <MuiTextInput
                        placeholder='Enter cookie name'
                        size='small'
                      />
                    </FormikControlMui>
                  </GridItem>
                  <GridItem>
                    <FormikControlMui name={`cookiesBasedForm.cookie[${index}].value`} label='Cookie value' mb='0.75rem'>
                      <MuiTextInput
                        placeholder='Enter cookie value'
                        size='small'
                      />
                    </FormikControlMui>
                  </GridItem>
                </Grid>
                {index > 0 ? (
                  <IconButton
                    className='remove-icon'
                    size='small'
                    aria-label='remove'
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    <X size={18} />
                  </IconButton>
                ) : null}
              </Box>
            ))}
            <TextLink
              as='button'
              variant='primary'
              icon={<PlusIcon />}
              alignIcon='start'
              style={{ alignItems: 'flex-start', alignContent: 'flex-start' }}
              onClick={() => arrayHelpers.push({ ...EmptyCookies })}
            >
            Add another
            </TextLink>
          </StyledGridItem>
        )}
      />
    </>
  );
};

export default CookiesBasedForm;
