import ColumnComponentAsset from './column-component-asset';
import { getAssociatedEnumValue } from './duck/utils';
import { FileText } from '@phosphor-icons/react';
import { globalTypes } from '@shared/duck';
import { ComponentEnum } from './duck/enums';
import { muiPalette } from '@shared/general-mui-theme';
import { Stack, Typography } from '@mui/material';

interface ColumnComponentProps {
  item: globalTypes.ScanLogItem;
  setCheckName?: React.Dispatch<React.SetStateAction<string>>;
}

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const ColumnComponent: React.FC<ColumnComponentProps> = ({
  item,
  setCheckName,
}) => {
  const component = getAssociatedEnumValue(item.component);
  const isClickable = component === ComponentEnum.Zap && !!item.check_id && item.check_id?.toLowerCase() !== 'spider';

  const handleClick = () => {
    if (isClickable) {
      setCheckName?.(item.check_id || '');
    }
  };

  return (
    <Stack direction='row' alignItems='center' sx={{ cursor: isClickable ? 'pointer' : 'default' }} onClick={handleClick}>
      <ColumnComponentAsset associatedEnumValue={component} />
      <Typography variant='default' fontWeight='500'>
        {item.component}
      </Typography>
      {isClickable && <FileText size={20} color={muiPalette.grey?.[500]} />}
    </Stack>
  );
};

export default ColumnComponent;