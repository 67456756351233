import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';

const IssuesContainer = styled.div`
  display: flex;
  align-items: center;

  .severity-item {
    display: flex;
    align-items: center;

    .severity-count {
      width: 1.875rem;
      margin-left: ${baseTokens.spacing2Xs};
    }
  }
`;
export { IssuesContainer };
