import { FieldArray, useField } from 'formik';
import { TextLink, Grid, GridItem } from '@contentful/f36-components';
import { PlusIcon } from 'views/components/v1/Icons';
import { StyledGridItem } from './header-based-form.styled';
import { FormikControlMui, MuiTextInput } from '@shared/components';
import { X } from '@phosphor-icons/react';
import { Box, IconButton } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

export interface Header {
  name: string;
  value: string;
}

export interface IHeaderBasedForm {
  headers: Header[];
}

const EmptyHeader: Header = {
  name: '',
  value: '',
};

export const HeaderBasedFormValues: IHeaderBasedForm = {
  headers: [
    {
      name: '',
      value: '',
    },
  ],
};

const HeaderBasedForm: React.FC = () => {
  const [field] = useField('headerBasedForm');
  const headers = (field.value as IHeaderBasedForm)?.headers || [];
  return (
    <FieldArray
      name='headerBasedForm.headers'
      render={arrayHelpers => (
        <StyledGridItem area='span 1 / span 2'>
          {headers.map((header, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                border: `1px solid ${muiPalette.grey?.[200]}`,
                borderRadius: '0.375rem',
                p: '1rem',
                mb: '1rem',
              }}
            >
              <Grid columns='1fr 1fr'>
                <GridItem>
                  <FormikControlMui name={`headerBasedForm.headers[${index}].name`} label='Header name' mb='0.75rem'>
                    <MuiTextInput
                      placeholder='Enter header name'
                      size='small'
                    />
                  </FormikControlMui>
                </GridItem>
                <GridItem>
                  <FormikControlMui name={`headerBasedForm.headers[${index}].value`} label='Header value' mb='0.75rem'>
                    <MuiTextInput
                      placeholder='Enter header value'
                      size='small'
                    />
                  </FormikControlMui>
                </GridItem>
              </Grid>
              {index > 0 ? (
                <IconButton
                  className='remove-icon'
                  size='small'
                  aria-label='remove'
                  onClick={() => arrayHelpers.remove(index)}
                >
                  <X size={18} />
                </IconButton>
              ) : null}
            </Box>
          ))}
          <TextLink
            as='button'
            variant='primary'
            icon={<PlusIcon />}
            alignIcon='start'
            style={{ alignItems: 'flex-start', alignContent: 'flex-start' }}
            onClick={() => arrayHelpers.push({ ...EmptyHeader })}
          >
            Add another
          </TextLink>
        </StyledGridItem>
      )}
    />
  );
};

export default HeaderBasedForm;