import {
  CredentialsApiCredentialsAssignToTargetsCreateRequest,
  CredentialsApiCredentialsRemoveFromTargetsCreateRequest,
} from '@api-client';
import * as apiClient from '@api-client';
import { InfiniteQueryObserverOptions, UseQueryOptions, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useApp } from '@shared/hooks';
import { CredentialQueryKeys, TargetsQueries } from './queriesKeys';
import { getNextPageParam, useFlatResults, useFlatCountFromResponse } from './utils';

const useCreateUsernamePasswordCredentials = () => {
  const { credentialsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.CredentialsApiCredentialsUsernamePasswordCreateRequest) =>
      credentialsApi.credentialsUsernamePasswordCreate(request),
    onSuccess: () =>
      queryClient.resetQueries({ queryKey: [CredentialQueryKeys.credentialsList] }, { cancelRefetch: true }),
  });

  return { createUsernamePasswordCredentials: mutateAsync };
};

const useCreateHeaderBasedCredentials = () => {
  const { credentialsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.CredentialsApiCredentialsHeaderCreateRequest) =>
      credentialsApi.credentialsHeaderCreate(request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [CredentialQueryKeys.credentialsList] }),
  });

  return { createHeaderBasedCredentials: mutateAsync };
};

const useCredentialsUpdate = () => {
  const { credentialsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.CredentialsApiCredentialsUpdateRequest) =>
      credentialsApi.credentialsUpdate(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [CredentialQueryKeys.credentialsList] });
      queryClient.resetQueries({ queryKey: [TargetsQueries.targetsAuthList] });
    }
  });

  return { updateAuthentication: mutateAsync };
};

const useCreateCookiesBasedCredentials = () => {
  const { credentialsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.CredentialsApiCredentialsCookieCreateRequest) =>
      credentialsApi.credentialsCookieCreate(request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [CredentialQueryKeys.credentialsList] }),
  });

  return { createCookiesBasedCredentials: mutateAsync };
};

const useGetCredentialsList = (request: apiClient.CredentialsApiCredentialsListRequest) => {
  const { credentialsApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [CredentialQueryKeys.credentialsList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => credentialsApi.credentialsList({ ...request, page: pageParam }),
    getNextPageParam: getNextPageParam,
  });
  const credentials = useFlatResults(data);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    credentials,
    totalItemsCount,
    isCredentialsListLoading: isLoading || isFetchingNextPage,
    credentialsHasNextPage: hasNextPage,
    fetchNextCredentialsPage: fetchNextPage,
  };
};


const useGetCredentialsLoginStatus = (
  request: apiClient.CredentialsApiCredentialsLoginStatusRetrieveRequest,
  config: Partial<UseQueryOptions<AxiosResponse<apiClient.Login>, AxiosError>> = {}
) => {
  const { credentialsApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [CredentialQueryKeys.credentialsStatus, ...Object.values(request)],
    queryFn: () => credentialsApi.credentialsLoginStatusRetrieve({ ...request }),
    ...config,
    enabled: !!request.id,
  });

  const statuses = data?.data.login_statuses;

  return {
    data: statuses,
    isLoginStatusLoading: isLoading,
  };
};

const useDeleteCredentials = () => {
  const { credentialsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading: isDeleteCredentialsInProgress } = useMutation({
    mutationFn: (request: apiClient.CredentialsApiCredentialsDestroyRequest) => credentialsApi.credentialsDestroy(
      request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [CredentialQueryKeys.credentialsList] });
      queryClient.resetQueries({ queryKey: [TargetsQueries.targetsAuthList] });
    }
  });

  return {
    deleteCredentials: mutateAsync,
    isDeleteCredentialsInProgress,
  };
};

const useGetCredentials = (
  request: apiClient.CredentialsApiCredentialsRetrieveRequest,
  config?: Partial<UseQueryOptions<AxiosResponse<apiClient.Credentials>, AxiosError>>,
) => {
  const { credentialsApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [CredentialQueryKeys.credentialsList, ...Object.values(request)],
    queryFn: () => credentialsApi.credentialsRetrieve(request),
    ...config,
  });

  return {
    credentials: data?.data,
    isCredentialsLoading: isLoading,
  };
};

const useGetCredentialsMutation = () => {
  const { credentialsApi } = useApp();
  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.CredentialsApiCredentialsRetrieveRequest) => credentialsApi.credentialsRetrieve(request),
  });

  return {
    getAuthentication: mutateAsync,
  };
};

const useAssignCredentialsToTargets = () => {
  const { credentialsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: CredentialsApiCredentialsAssignToTargetsCreateRequest) =>
      credentialsApi.credentialsAssignToTargetsCreate(request),
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: [CredentialQueryKeys.credentialsList],
      });
      queryClient.resetQueries({
        queryKey: [TargetsQueries.targetsList],
      });
      queryClient.resetQueries({
        queryKey: [TargetsQueries.urlTargetsList],
      });
    },
  });

  return {
    assignCredentialsToTargets: mutateAsync,
    isAssigningToTargetsLoading: isLoading,
  };
};

const useUnassignCredentialsFromTargets = () => {
  const { credentialsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: CredentialsApiCredentialsRemoveFromTargetsCreateRequest) =>
      credentialsApi.credentialsRemoveFromTargetsCreate(request),
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: [CredentialQueryKeys.credentialsList],
      });
      queryClient.resetQueries({
        queryKey: [TargetsQueries.targetsList],
      });
      queryClient.resetQueries({
        queryKey: [TargetsQueries.urlTargetsList],
      });
    },
  });

  return {
    unassignCredentialsFromTargets: mutateAsync,
    isUnassigningFromTargetsLoading: isLoading,
  };
};

const useGetUniqueScansByCredentialsId = (
  request: apiClient.CredentialsApiCredentialsAuthTargetListRequest,
  config?: Partial<InfiniteQueryObserverOptions<AxiosResponse<apiClient.PaginatedScanList>, AxiosError>>,
) => {
  const { credentialsApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [CredentialQueryKeys.scansListByCredId, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => credentialsApi.credentialsAuthTargetList({ ...request, page: pageParam }),
    getNextPageParam: getNextPageParam,
    enabled: !!request.id,
    ...config,
  });

  const scans = useFlatResults(data);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    scans: scans,
    totalItemsCount,
    isScansListLoading: isLoading,
    scansHasNextPage: hasNextPage,
    fetchNextScansPage: fetchNextPage,
  };
};

export {
  useCreateUsernamePasswordCredentials,
  useCreateHeaderBasedCredentials,
  useCreateCookiesBasedCredentials,
  useGetCredentialsList,
  useGetCredentialsLoginStatus,
  useDeleteCredentials,
  useGetCredentials,
  useGetCredentialsMutation,
  useAssignCredentialsToTargets,
  useUnassignCredentialsFromTargets,
  useCredentialsUpdate,
  useGetUniqueScansByCredentialsId
};
