import { Table, Grid } from '@contentful/f36-components';
import styled from '@emotion/styled';
import { UICodeFragment } from '@shared/components';
import baseTokens from '@contentful/f36-tokens';
import { BreakpointX } from 'views/utils/design';

const gradient = 'linear-gradient(0deg, rgba(253,187,45,0.2) 29%, rgba(253,187,45,0.4) 100%);';
const errorGradient = 'linear-gradient(0deg, rgba(242,3,3,0.2) 47%, rgba(242,3,3,0.4) 100%);';
const lightBlueish = '#337ab7';

const TableStyled = styled(Table)`
  padding: 0 1rem;
  border-radius: unset;
  line-height: 1rem;

  tr th {
    color: ${baseTokens.gray800};
    font-weight: 500;
    padding-left: 0;
    line-height: 1.25rem;
  };

  tr td {
    padding-left: 0;
    font-weight: 400;
    line-height: 1rem;
    vertical-align: middle;

    color: ${baseTokens.gray800};
    font-size: 0.75rem;
  };

  tr .value-col {
    color: ${baseTokens.gray500};
  }

`;

const ContentGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0 !important;

  .header {
    height: 3.75rem;
    display: flex;
    align-items: center;
    padding: 0 ${baseTokens.spacingM};
    border-top: 1px solid ${baseTokens.gray200};
    border-bottom: 1px solid ${baseTokens.gray200};

    &:first-of-type {
      border-right: 1px solid ${baseTokens.gray200};
    }
  }

  .auth-script {
    min-width: 0;

    & .Code {
      margin-top: -0.375rem;
    }
  }

  .thumbnails-galery {

    .thumbnails {
      border: 0.25rem solid transparent;

      &:hover {
        border: 0.25rem solid ${lightBlueish};
        outline: none;  
      }
    }
  
    .active {
      border: 0.25rem solid ${lightBlueish};
    }
  }

  .subheading-thumb {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem; 
    color: ${baseTokens.gray900};
  }

  .gallery {
    min-width: 0;

    .header .show-last-button {
      margin-left: auto;
    }

    .image-gallery {
      .image-gallery-slide-wrapper {
        min-height: 23.75rem;
      }

      .image-gallery-thumbnail-image {
        height: 3.75rem;
      }

      .image-gallery-thumbnails {
        overflow-x: auto;
      }

      .left-arrow {
        position: absolute;
        left: 0;
        top: 45%;
        z-index: 100;
      }

      .right-arrow {
        position: absolute;
        right: 0;
        top: 45%;
        z-index: 100;
      }
    }
  }

  @media (max-width: ${BreakpointX.Laptop.max}px) {
    grid-template-columns: 1fr !important;
  }

  @media (min-width: ${BreakpointX.Laptop.max}px) {
    grid-template-columns: 1fr 1fr !important;
    &.cookies-content {
      grid-template-columns: 1fr !important;
    }
  }
`;

const StyledCodeFragment = styled(UICodeFragment) <{ variant?: 'positive' | 'negative' }>`
  pre {
    pointer-events: auto;
  }

  pre > .line-highlight {
    box-sizing: border-box;
    padding: 0;
    background: ${({ variant = 'positive' }) => (
    variant === 'negative' ? errorGradient : gradient
  )};
  }
`;

export { ContentGrid, StyledCodeFragment, TableStyled };

