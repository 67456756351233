import { MenuItem, styled, Typography } from '@mui/material';
import { globalTypes } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import { withSafeProps } from 'views/utils/common';

interface StyledMenuItemTextProps {
  children: string,
  isRedText?: globalTypes.EmotionBooleanType
}

export const StyledMenuItemText = styled(withSafeProps<StyledMenuItemTextProps>(Typography, ['isRedText']))`
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: -0.00963rem;
    color: ${({ isRedText }) => (
    isRedText ? '#BD002A' : muiPalette.grey?.[600]
  )};
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: 0.75rem 1rem;
  align-items: center;
    
    &.Mui-disabled {
        opacity: 0.5;
    }
`;