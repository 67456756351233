import { globalEnums } from '@shared/duck';

export const getCheckChipColor = (type: globalEnums.CheckStatusEnum): globalEnums.MuiBadgeColor | undefined => {
  switch (type) {
    case globalEnums.CheckStatusEnum.FAIL: {
      return globalEnums.MuiBadgeColor.ERROR;
    }
    case globalEnums.CheckStatusEnum.PASS: {
      return globalEnums.MuiBadgeColor.SUCCESS;
    }
    case globalEnums.CheckStatusEnum.DISRUPTED: {
      return globalEnums.MuiBadgeColor.WARNING;
    }
    case globalEnums.CheckStatusEnum.IN_PROGRESS: {
      return globalEnums.MuiBadgeColor.DEFAULT;
    }
  }
};