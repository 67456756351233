import React from 'react';
import { SnackbarProvider as Snackbar } from 'notistack';
import { NotificationMui } from '@shared/components';

export const SnackbarProvider: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  return (
    <Snackbar
      Components={{
        error: NotificationMui,
        success: NotificationMui,
        info: NotificationMui,
        warning: NotificationMui,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={4000}
      maxSnack={4}
    >
      {children}
    </Snackbar>
  );
};