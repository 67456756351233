import { globalEnums, globalQueries } from '@shared/duck';
import React from 'react';
import { useParams } from 'react-router-dom';
import { InfoCircleIcon, MoreVerticalIcon } from 'views/components/v1/Icons';
import {
  Menu, MenuItem, MenuList, MenuTrigger,
  IconButton, Switch
} from '@contentful/f36-components';
import { enqueueSnackbar } from 'notistack';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { Tooltip, Typography, Stack } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
interface ActionsInfoMenuProps {
  isRaw: boolean;
  setIsRaw: React.Dispatch<React.SetStateAction<boolean>>;
  issueId?: string;
  isSinglePage?: boolean;
  setCurrentIssueId?: (issue?: string) => void;
}

const ActionsInfoMenu: React.FC<ActionsInfoMenuProps> = ({
  isRaw,
  setIsRaw,
  issueId,
  isSinglePage = false,
  setCurrentIssueId,
}) => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();

  const { showModal } = useGlobalModalContext();

  const onEditApplication = () => {
    showModal({
      modalType: MODAL_TYPE.ApplicationModal,
      modalProps: {
        initialTab: 'advanced',
        applicationId,
        enableProjectSelect: false,
      },
    });
  };

  const { deleteIssue } = globalQueries.useDeleteIssueById();
  const onDeleteIssue = React.useCallback(async () => {
    if (issueId) {
      const onConfirm = async () => {
        try {
          await deleteIssue({ id: issueId });
          setCurrentIssueId?.(undefined);
          enqueueSnackbar('Selected Issue has been deleted successfully', { variant: 'success' });
        } catch (error) {
          enqueueSnackbar('Failed to delete selected Issue', { variant: 'error' });
        }
      };
      showModal({
        modalType: MODAL_TYPE.DeleteModal,
        modalProps: {
          title: 'Delete Issue?',
          deleteText: 'Are you sure you want to delete this issue',
          onConfirm,
        },
      });
    }
  }, [issueId]);

  return (
    <>
      <Menu closeOnSelect={false}>
        <MenuTrigger>
          <IconButton
            variant={globalEnums.EButtonVariants.SECONDARY}
            icon={<MoreVerticalIcon />} aria-label='actions' />
        </MenuTrigger>
        <MenuList>
          {!isSinglePage && applicationId ? (
            <>
              <MenuItem onClick={onEditApplication}>
                Configure app to exclude...
              </MenuItem >
              <MenuItem onClick={onDeleteIssue}>
                <Typography variant='default' color={muiPalette.red?.[600]}>
                  Delete
                </Typography>
              </MenuItem>
            </>
          ) : (
            <MenuItem>
              <Switch name='view-har' isChecked={isRaw} onChange={() => setIsRaw(!isRaw)}>
                <Stack direction='row' gap='1rem' alignItems='center'>
                  View HAR
                  <Tooltip title='HTTP Archive Record (HAR)'>
                    <span>
                      <InfoCircleIcon size='small' variant={globalEnums.EIconVariants.SECONDARY} />
                    </span>
                  </Tooltip>
                </Stack>
              </Switch>
            </MenuItem>)}
        </MenuList>
      </Menu>
    </>
  );
};

export default ActionsInfoMenu;