import React from 'react';

export enum ErrorType {
  NOT_FOUND,
  NO_ACCESS,
}

export interface ErrorDetails {
  type: ErrorType,
  objectName?: string,
  // objectName?: 'project' | 'application' | 'authentication' | 'target' | 'scan',
}

export interface ErrorProps {
  error?: ErrorDetails
  setError?: React.Dispatch<ErrorDetails | undefined>
}
