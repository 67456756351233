import { User } from '@api-client';
import { AutocompleteChangeReason } from '@mui/material';
import { MuiFilterAutocomplete, UIUserAvatar } from '@shared/components';
import { globalQueries } from '@shared/duck';
import { useDebounce } from '@shared/hooks';
import { UIOptionItemNew } from 'pages/workspace-router/scans/scans-page-wrapper';
import React from 'react';

interface MuiCreatedByFilterProps<T> {
  // projectIds: string[];
  selectedUsers?: UIOptionItemNew<T>[];
  setSelectedUsers?: (newValues: UIOptionItemNew<T>[], options?: UIOptionItemNew<T>[], reason?: AutocompleteChangeReason) => void;
}

const MIN_INPUT_LENGTH = 3;

export const MuiCreatedByFilter = <T,>({
  // projectIds,
  selectedUsers,
  setSelectedUsers,
}: MuiCreatedByFilterProps<T>) => {

  const { debouncedValue: inputValue = '', onChange: onInputChange, value = '' } = useDebounce<string>();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<User[]>([]);

  const handleOpen = () => {
    if (inputValue.length >= MIN_INPUT_LENGTH) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    onInputChange('');
  };

  React.useEffect(() => {
    if (inputValue.length >= MIN_INPUT_LENGTH) {
      setOpen(true);
    }
    else {
      setOpen(false);
    }
  }, [inputValue.length]);

  const {
    users = [],
    isUsersListLoading = false,
    usersHasNextPage,
    fetchNextUsersPage,
  } = globalQueries.useGetUsersList({
    filter: inputValue,
    // project: projectIds,
    order: ['last_name'],
    isActive: true,
    pageSize: 25,
  }, {
    enabled: !!inputValue && inputValue?.length >= MIN_INPUT_LENGTH,
  });

  React.useEffect(() => {
    setOptions(users);
    if (!open) setOptions([]);
  }, [users, open]);

  const createNewValues = (user: User): UIOptionItemNew<T> => (
    { key: user.id, label: `${user.first_name} ${user.last_name}`, value: user.id as T, extraInfo: user.avatar_url || '' }
  );

  const { getUsersList } = globalQueries.useGetUsersListMutation();
  const [isUsersChecked, setIsUsersChecked] = React.useState(false);
  React.useEffect(() => {
    const checkProjects = async () => {
      if (selectedUsers?.length && !isUsersChecked) {
        setIsUsersChecked(true);
        const optionsToDelete: UIOptionItemNew<T>[] = [];
        const newOptions: UIOptionItemNew<T>[] = [];
        await Promise.all(
          selectedUsers.map(async user => {
            const { data } = await getUsersList({
              filter: user.label
            });

            if (data.count) {
              const foundUser = data.results.find(res => res.id === user.value && `${res.first_name} ${res.last_name}` === user.label);
              if (foundUser) {
                newOptions.push({ ...user, extraInfo: foundUser.avatar_url || '' });
              }
              else {
                optionsToDelete.push(user);
              }
            }
            else {
              optionsToDelete.push(user);
            }
          })
        );
        if (optionsToDelete.length) {
          setSelectedUsers?.(newOptions, optionsToDelete, 'removeOption');
        }
        else {
          setSelectedUsers?.(newOptions, optionsToDelete, 'selectOption');
        }
      }
    };

    checkProjects();
  }, [selectedUsers]);

  return (
    <MuiFilterAutocomplete<UIOptionItemNew<T>>
      filterName='Initiated by'
      options={options.map(user => createNewValues(user))}
      loading={open ? isUsersListLoading : false}
      selectedValues={selectedUsers}
      setSelectedValues={setSelectedUsers}
      getOptionKey={option => option.key || option.value as string}
      getOptionLabel={option => option.label}
      inputValue={value}
      onInputChange={onInputChange}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      optionStartIcon={(option: UIOptionItemNew<T>) =>
        <UIUserAvatar
          src={option.extraInfo}
          avatarSize={1.125}
          sx={{ mr: '0.25rem' }}
        />
      }
      fetchNextPage={() => usersHasNextPage && fetchNextUsersPage()}
    />
  );
};