import React from 'react';
import { AddCredentialsPage } from '@shared/components/authentication-modal/duck';
import { globalEnums } from '@shared/duck';
import { useFormikContext } from 'formik';
import { AuthenticationFormValues } from '../../authentication-modal';
import { StyledCodeFragment } from './rerecord-command-page.styled';
import { Alert, Box, Link, List, ListItem, Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

interface RerecordCommandPageProps {
  setNewPage: (page: AddCredentialsPage) => void;
}

const RerecordCommandPage: React.FC<RerecordCommandPageProps> = ({
  setNewPage,
}) => {
  const { values, initialValues } = useFormikContext<AuthenticationFormValues>();

  const updateScriptCommand = 'nightvision auth playwright update ' +
    `${initialValues.name} ` +
    `-P ${values.project} ` +
    '--script';

  return (
    <Stack gap={1}>
      <Typography
        variant='subtitle2'
        fontWeight='400'
      >
        {'To record the script again, open NightVision CLI and enter the following command:'}
      </Typography>

      <StyledCodeFragment
        code={updateScriptCommand}
        language={globalEnums.SupportedLangs.bash}
      />

      <Box>
        <Typography
          variant='subtitle2'
          fontWeight='400'
          mb={0.5}
        >
          {'This will bring up a Chrome window to record the authentication sequence. In the Chrome window:'}
        </Typography>

        <List
          sx={{
            listStyle: 'decimal',
            p: 0, pl: 1.25,
            color: muiPalette.grey?.[900],

            '.MuiListItem-root': {
              display: 'list-item',
              p: 0,
            }
          }}
        >
          <ListItem>{'Navigate to the URL of the web application you want to scan.'}</ListItem>
          <ListItem>{'Log in to the web application.'}</ListItem>
          <ListItem>{'Once you are logged in, close the Chrome window.'}</ListItem>
        </List>
      </Box>

      <Alert severity='info'>
        {'You must have NightVision CLI to record a Playwright script. If you don’t already have it click '}
        <Link
          onClick={() => setNewPage(AddCredentialsPage.DOWNLOAD)}
          fontWeight={500}
        >
          {'here'}
        </Link>
        {' to download it.'}
      </Alert>

    </Stack>
  );
};

export default RerecordCommandPage;