import { MuiTable, tableTypes } from '@shared/components';
import useItemSelection from '@shared/hooks/use-item-selection';
import React from 'react';
import { tokensTableConsts, tokensTableUtils } from './duck';
import { globalModels } from '@shared/duck';
import { Stack } from '@mui/material';

const TokensTable: React.FC = () => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { deleteTokenHandler } = tokensTableUtils.useDeleteTokenHandler();

  const {
    tokens,
    totalItemsCount,
    isTokensListLoading,
  } = tokensTableUtils.useTokensTableApi({
    page: currentPage,
    pageSize: rowsPerPage,
  });

  const itemSelectionApi = useItemSelection<globalModels.TokenViewModel>({
    data: [],
    keyBy: item => item.id,
    labelBy: item => item.tokenKey,
  });

  React.useEffect(() => {
    itemSelectionApi.reinitializeData(tokens);

    /** Note: use this to control pagination */
    const selectedItems = new Map(itemSelectionApi.selectedItems);
    itemSelectionApi.selectedItems.forEach((item) => {
      if (selectedItems.has(item.id) && !tokens.find((scan) => scan.id === item.id)) {
        selectedItems.delete(item.id);
      }
    });
    itemSelectionApi.reinitialize(selectedItems);
  }, [JSON.stringify(tokens)]);

  const onDeleteToken = async (digest: string, tokenKey: string) => {
    await deleteTokenHandler(digest, tokenKey);
  };

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const tableConfig: tableTypes.MuiTableProps<globalModels.TokenViewModel> = {
    data: tokens,
    columns: tokensTableConsts.tokensColumns(),
    loading: isTokensListLoading,
    noContentMessage: 'No Tokens found',
    disableRow: item => item.isTokenExpired,
    itemActions: item => (
      {
        itemList: [
          {
            title: 'Delete',
            itemAction: () => onDeleteToken(item.digest, item.tokenKey),
            isRedText: true,
          },
        ],
      }
    ),
    pagination: {
      count: totalItemsCount,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: (e, newPage) => handleChangePage(newPage),
      onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
    },
  };

  return (
    <Stack overflow={'auto'}>
      <MuiTable
        {...tableConfig}
      />
    </Stack>
  );
};

export default TokensTable;