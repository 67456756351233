import { globalTypes } from '@shared/duck';

export const enum AddCredentialsPage {
  GENERAL = 'general',
  TEST = 'test',
  GENERATE_COMMAND = 'generate_command',
  DOWNLOAD = 'download',
  RERECORD = 'rerecord',
}

export enum CustomCredentialsType {
  UsernamePassword = 'username_password',
  HeaderBased = 'header_based',
  Script = 'script',
  CookiesBased = 'cookies_based',
}

export const SupportedCredentials: globalTypes.UIOptionItem[] = [
  {
    label: 'Recorded Authentication (Playwright)',
    value: CustomCredentialsType.Script,
  },
  {
    label: 'Header Authentication',
    value: CustomCredentialsType.HeaderBased,
  },
  {
    label: 'Cookie Header Authentication',
    value: CustomCredentialsType.CookiesBased,
  },
];