import { globalModels, globalQueries, globalUtils } from '@shared/duck';
import React from 'react';

interface ScansTableApiResult {
  scans: globalModels.ScansViewModelNew[],
  totalItemsCount: number,
  isScansListLoading: boolean,
  onScansRowClick?: (item: globalModels.ScansViewModelNew) => void,
}

export const useRecentScansTableApi = ({
  project,
  filter,
  createdSince,
  createdBefore,
  application,
  limit = 5,
  page,
  pageSize,
}:
  {
    project?: string[];
    filter?: string;
    createdSince?: string;
    createdBefore?: string;
    application?: string[];
    limit?: number;
    page: number;
    pageSize?: number;
  }): ScansTableApiResult => {
  const REFETCH_INTERVAL = 20 * 1000;

  const {
    scans: dtoScans,
    totalItemsCount,
    isScansListLoading,
  } = globalQueries.useGetScansList(
    {
      filter: filter,
      createdSince: createdSince,
      createdBefore: createdBefore,
      project: project,
      application: application,
      limit: limit,
      page: page + 1,
      pageSize: pageSize,
    },
    { refetchInterval: REFETCH_INTERVAL },
  );

  const scans = React.useMemo(
    () =>
      dtoScans?.map(
        (item) => globalUtils.mapScansDtoToViewModelNew(item)) || [],
    [dtoScans],
  );

  return {
    scans,
    totalItemsCount,
    isScansListLoading,
  };
};
