import React from 'react';
import { AutocompleteChangeReason } from '@mui/material';
import { ClockClockwise } from '@phosphor-icons/react';
import { globalModels, globalTypes, globalUtils } from '@shared/duck';
import useItemSelection, { ItemSelectionApi } from '@shared/hooks/use-item-selection';
import { AppliedFiltersProps, ScanStatusSelectionApiProps, callbackProps } from './types';
import { FilterName, SCAN_STATUSES } from './constants';
import { UICheckbox, UIUserAvatar } from '@shared/components';
import { UIOptionItemNew } from '../scans-page-wrapper';
import { ScanStatus } from '@api-client';

interface ScansPageItemsReturnProps {
  scanSelectionApi: ItemSelectionApi<globalModels.ScansViewModelNew>;
  // itemList: globalTypes.UIMenuItemsList[];
  footerItems: globalTypes.UIMenuItemsList[];
}

interface useScansPageItemsProps {
  selectedStatuses: UIOptionItemNew<ScanStatus[] | string>[],
  setSelectedStatuses: React.Dispatch<React.SetStateAction<UIOptionItemNew<ScanStatus[] | string>[]>>,
  setAppliedFilters: React.Dispatch<React.SetStateAction<AppliedFiltersProps[]>>,
  scanSelectionApi: ItemSelectionApi<globalModels.ScansViewModelNew>;
  plannerData: {
    setOpenPlanner: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedPlannerScans: React.Dispatch<React.SetStateAction<globalModels.ScansViewModelNew[] | undefined>>;
    scanPlanner: boolean,
  }
}

export const useScansPageItems = (): ScansPageItemsReturnProps => {
  const { bulkDeleteScansHandler } = globalUtils.useBulkDeleteScansHandler();

  const scanSelectionApi = useItemSelection<globalModels.ScansViewModelNew>({
    data: [],
    keyBy: item => item.id,
    labelBy: item => item.targetName, // TODO: exclude from required params
  });

  const footerItems: globalTypes.UIMenuItemsList[] = [
    {
      title: 'Delete Selected',
      itemAction: () => bulkDeleteScansHandler(Array.from(scanSelectionApi.selectedItems.values()).map(scan => scan.id)),
      isRedText: true,
      disabled: !scanSelectionApi.selectedItems.size,
      tooltipText: 'Please select Scans',
    }
  ];

  return {
    scanSelectionApi,
    footerItems,
  };
};

export const getItemsList = ({
  selectedStatuses,
  setSelectedStatuses,
  setAppliedFilters,
  plannerData,
  scanSelectionApi,
}: useScansPageItemsProps): globalTypes.UIMenuItemsList[] => {

  const setPlannerData = () => {
    plannerData.setSelectedPlannerScans(Array.from(scanSelectionApi.selectedItems.values()));
    plannerData.setOpenPlanner(true);
  };

  return [
    ...SCAN_STATUSES.map(el => ({
      title: `Show ${el.name}`,
      titleIcon: (
        <UICheckbox
          isChecked={selectedStatuses.some(status => status.label === el.name)}
          style={{
            marginRight: '0.25rem'
          }}
        />),
      itemAction: () => {
        let newValues: UIOptionItemNew<ScanStatus[] | string>[] = [];
        const nameBy = (option: ScanStatusSelectionApiProps) => option.name;
        const keyBy = (option: ScanStatusSelectionApiProps) => option.id.toString().replaceAll(',', '+');
        const options = [el];
        const filterName = FilterName.STATUS;

        if (selectedStatuses.some(status => status.label === el.name)) {
          newValues = selectedStatuses.filter(status => status.label !== el.name);
          setAppliedFilters((old) => {
            const newFilters: AppliedFiltersProps[] = old;
            return [...newFilters.filter(oldFilter => !options?.some(o => {
              return `${filterName}-${keyBy(o)}` === oldFilter.id;
            }))];
          });
        }
        else {
          newValues = [...selectedStatuses, { value: keyBy(el), label: el.name, key: keyBy(el) }];
          const newFilters: AppliedFiltersProps[] = [];
          options?.forEach((option: ScanStatusSelectionApiProps) => {
            newFilters.push({ filterName: filterName, value: nameBy(option), id: `${filterName}-${keyBy(option)}` });
          });
          setAppliedFilters((old) => [...old, ...newFilters]);
        }
        setSelectedStatuses?.(newValues);
      },
      closeAfterAction: false,
    })),
    {
      title: 'Scan Planner',
      titleIcon: <ClockClockwise size={16} />,
      titleStackGap: 0.5,
      itemAction: () => setPlannerData(),
      disabled: !scanSelectionApi.selectedItems.size,
      tooltipText: 'Please select Scans',
      tooltipPlacement: 'left',
      isHidden: !plannerData.scanPlanner,
    },
  ];
};

export const getUserAvatar = (url?: string) => (
  <UIUserAvatar
    src={url}
    avatarSize={0.9}
  />
);

export const useCreateFilterOptionsFabric = (setAppliedFilters: React.Dispatch<React.SetStateAction<AppliedFiltersProps[]>>) => {

  const createFilterOptions = <T,>(
    selected: UIOptionItemNew<T>[],
    setSelectedValues: (value: React.SetStateAction<UIOptionItemNew<T>[]>) => void,
    filterName: FilterName,
    icon?: (option: UIOptionItemNew<T>) => React.ReactElement,
  ) => {

    const callback = ({ newValues, options, action, filterName, nameBy, keyBy, setSelectedValues, icon }: callbackProps<UIOptionItemNew<T>>) => {
      setSelectedValues?.(newValues);
      if (action === 'selectOption') {
        const newFilters: AppliedFiltersProps[] = [];
        newValues?.forEach((option: UIOptionItemNew<T>) => {
          newFilters.push({ filterName: filterName, value: nameBy(option), id: `${filterName}-${keyBy(option)}`, icon: icon?.(option), });
        });
        setAppliedFilters((old) => {
          // if (filterName === FilterName.STARTDATE || filterName === FilterName.ENDDATE) {
          old = old.filter(item => item.filterName !== filterName);
          // }
          return [...new Map([...old, ...newFilters]
            .map(item => [item.id, item])).values()];
        });
      }
      else if (action === 'removeOption') {
        setAppliedFilters((old) => {
          const newFilters: AppliedFiltersProps[] = old;
          return [...newFilters.filter(oldFilter => !options?.some(o => {
            return `${filterName}-${keyBy(o)}` === oldFilter.id;
          }))];
        });
      }
    };

    return ({
      selected,
      setSelected: (newValues: UIOptionItemNew<T>[], options?: UIOptionItemNew<T>[], action?: AutocompleteChangeReason) =>
        callback({
          newValues,
          options,
          action,
          filterName,
          nameBy: option => option.label,
          keyBy: option => option.key?.toString() || option.label,
          setSelectedValues,
          icon: icon,
        }),
      keyBy: (option: UIOptionItemNew<T>) => option.key?.toString() || option.label,
      nameBy: (option: UIOptionItemNew<T>) => option.label,
    });
  };

  return ({
    createFilterOptions
  });
};