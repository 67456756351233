import { NotFoundBackground } from '@assets';
import { Stack, styled } from '@mui/material';

const Content = styled(Stack)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  background: url(${NotFoundBackground}) no-repeat right;

  .content-box {
    max-width: 35rem;
    margin-left: 5rem;
    margin-right: 5rem;
  }
`;

export { Content };
