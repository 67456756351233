import { Tabs } from '@contentful/f36-components';
import { globalEnums } from '@shared/duck';
import React from 'react';
import { UICodeFragment } from '@shared/components';
import { Box, Stack, Typography } from '@mui/material';

const InstallCLITabs: React.FC = () => {
  return (
    <Stack gap='0.75rem'>
      <Tabs defaultTab='Homebrew'>
        <Tabs.List variant='horizontal-divider' loop style={{ overflow: 'auto' }}>
          <Tabs.Tab panelId='Homebrew'>Homebrew (Mac/Linux)</Tabs.Tab>
          <Tabs.Tab panelId='Mac(Intel)'>Mac (Intel)</Tabs.Tab>
          <Tabs.Tab panelId='Mac(ARM)'>Mac (ARM)</Tabs.Tab>
          <Tabs.Tab panelId='Linux(Intel)'>Linux (Intel)</Tabs.Tab>
          <Tabs.Tab panelId='Linux(ARM)'>Linux (ARM)</Tabs.Tab>
          <Tabs.Tab panelId='Windows(Intel)'>Windows (Intel)</Tabs.Tab>
          <Tabs.Tab panelId='Windows(ARM)'>Windows (ARM)</Tabs.Tab>
        </Tabs.List>

        <Box>
          <Tabs.Panel id='Homebrew'>
            <UICodeFragment
              code={'# Install: ' + '\n' +
                'brew install nvsecurity/taps/nightvision ' + '\n' +
                '\n' +
                '# Upgrade to the latest version:' + '\n' +
                'brew update && brew upgrade nightvision'
              }
              language={globalEnums.SupportedLangs.bash}
            />
          </Tabs.Panel>
          <Tabs.Panel id='Mac(Intel)'>
            <UICodeFragment
              code={
                'curl -L https://downloads.nightvision.net/binaries/latest/nightvision_latest_darwin_amd64.tar.gz | tar -xz; ' +
                'mv nightvision /usr/local/bin/'
              }
              language={globalEnums.SupportedLangs.bash}
            />
          </Tabs.Panel>
          <Tabs.Panel id='Mac(ARM)'>
            <UICodeFragment
              code={
                'curl -L https://downloads.nightvision.net/binaries/latest/nightvision_latest_darwin_arm64.tar.gz -q | tar -xz; ' +
                'mv nightvision /usr/local/bin/'
              }
              language={globalEnums.SupportedLangs.bash}
            />
          </Tabs.Panel>
          <Tabs.Panel id='Linux(Intel)'>
            <UICodeFragment
              code={
                'curl -L https://downloads.nightvision.net/binaries/latest/nightvision_latest_linux_amd64.tar.gz -q | tar -xz; ' +
                'sudo mv nightvision /usr/local/bin/'
              }
              language={globalEnums.SupportedLangs.bash}
            />
          </Tabs.Panel>
          <Tabs.Panel id='Linux(ARM)'>
            <UICodeFragment
              code={
                'curl -L https://downloads.nightvision.net/binaries/latest/nightvision_latest_linux_arm64.tar.gz -q | tar -xz; ' +
                'sudo mv nightvision /usr/local/bin/'
              }
              language={globalEnums.SupportedLangs.bash}
            />
          </Tabs.Panel>
          <Tabs.Panel id='Windows(Intel)'>
            <UICodeFragment
              code={'# Open a new Admin Powershell console' + '\n' + '\n' +

                '# Create the folder and add the new location into the PATH for the first time only' + '\n' +
                'New-Item -ItemType Directory -Path "C:\\Program Files\\Nightvision\\bin" -Force' + '\n' +
                '$env:Path = "$env:Path;C:\\Program Files\\Nightvision\\bin"' + '\n' +
                '[Environment]::SetEnvironmentVariable("Path", $env:Path, "Machine")' + '\n' + '\n' +

                '# Run this block to install or update the app' + '\n' +
                'Invoke-WebRequest -Uri' +
                ' https://downloads.nightvision.net/binaries/latest/nightvision_latest_windows_amd64.tar.gz ' +
                '-OutFile nightvision_latest_windows_amd64.tar.gz' + '\n' +
                'tar xf .\\nightvision_latest_windows_amd64.tar.gz -C "C:\\Program Files\\Nightvision\\bin"' + '\n' +
                'rm .\\nightvision_latest_windows_amd64.tar.gz'
              }
              language={globalEnums.SupportedLangs.bash}
            />
          </Tabs.Panel>
          <Tabs.Panel id='Windows(ARM)'>
            <UICodeFragment
              code={'# Open a new Admin Powershell console' + '\n' + '\n' +

                '# Create the folder and add the new location into the PATH for the first time only' + '\n' +
                'New-Item -ItemType Directory -Path "C:\\Program Files\\Nightvision\\bin" -Force' + '\n' +
                '$env:Path = "$env:Path;C:\\Program Files\\Nightvision\\bin"' + '\n' +
                '[Environment]::SetEnvironmentVariable("Path", $env:Path, "Machine")' + '\n' + '\n' +

                '# Run this block to install or update the app' + '\n' +
                'Invoke-WebRequest -Uri' +
                ' https://downloads.nightvision.net/binaries/latest/nightvision_latest_windows_arm64.tar.gz ' +
                '-OutFile nightvision_latest_windows_arm64.tar.gz' + '\n' +
                'tar xf .\\nightvision_latest_windows_arm64.tar.gz -C "C:\\Program Files\\Nightvision\\bin"' + '\n' +
                'rm .\\nightvision_latest_windows_arm64.tar.gz'
              }
              language={globalEnums.SupportedLangs.bash}
            />
          </Tabs.Panel>
        </Box>
      </Tabs>

      <Typography variant='default'>
        {'Now log in to the NightVision CLI:'}
      </Typography>

      <UICodeFragment
        code={'nightvision login'}
        language={globalEnums.SupportedLangs.bash}
      />

      <Typography variant='default'>
        {'This will open up your web browser and you will be prompted to log in or sign up. ' + '\n' +
          'Follow the login prompts and close the tab when you are done.'}
      </Typography>
    </Stack>
  );
};

export default InstallCLITabs;