import { Project } from '@api-client';
import { globalModels, globalQueries, globalUtils } from '@shared/duck';
import React from 'react';

interface GetInitialValuesProps {
  targetId?: string;
  appConfigToTarget: boolean;
}

interface GetInitialValuesResultsProps {
  defaultProject?: Project;
  isDefaultProjectLoading: boolean;
  relatedApplication?: globalModels.ApplicationsViewModel;
  isRelatedApplicationLoading: boolean;
}

export const useGetInitialValues = ({
  targetId,
  appConfigToTarget,
}: GetInitialValuesProps): GetInitialValuesResultsProps => {
  const { defaultProject = null, isDefaultProjectLoading } = globalQueries.useGetDefaultProject();

  const {
    applications,
    isApplicationsListLoading = false,
  } = globalQueries.useGetApplicationsList({
    page: 1,
    filter: `${targetId}-Application`,
    pageSize: 1,
  }, {
    enabled: !!targetId && appConfigToTarget,
  });

  const relatedApplication = React.useMemo(
    () =>
      applications[0] && globalUtils.mapApplicationsDtoToViewModel(applications[0]),
    [applications],
  );

  return {
    defaultProject: defaultProject || undefined,
    isDefaultProjectLoading,
    relatedApplication,
    isRelatedApplicationLoading: isApplicationsListLoading,
  };
};