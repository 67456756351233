import { pluralize } from './formatting';

const formatTimeSpan = (secondsDuration: number, isFull?: boolean) => {
  const hours = Math.floor(secondsDuration / 3600);
  secondsDuration -= hours * 3600;

  const minutes = Math.floor(secondsDuration / 60);
  secondsDuration -= minutes * 60;

  const seconds = Math.floor(secondsDuration % 60);

  if (isFull) {
    const hoursStr = hours > 0 ? `${hours} ${pluralize('hour', hours)}` : '';
    const minutesStr = minutes > 0 ? `${minutes} ${pluralize('minute', minutes)}` : '';
    const secondsStr = seconds > 0 ? `${seconds} ${pluralize('second', seconds)}` : '';
    return [hoursStr, minutesStr, secondsStr].filter(Boolean).join(', ');
  }

  return `${hours ? hours + ':' : ''}${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} min`;
};

export { formatTimeSpan };
