import { css } from '@emotion/react';
import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';
import { iconFrameTypes } from './duck';

const getSize = ({ iconProps }: iconFrameTypes.IconFrameProps) => {
  const iconSize = iconProps?.fontSize || 'medium';
  switch (iconSize) {
    case 'inherit':
      return css`
          width: inherit;
          height: inherit;
      `;
    case 'small':
      return css`
          width: 2.125rem;
          height: 2.125rem;
      `;
    case 'medium':
      return css`
          width: 2.5rem;
          height: 2.5rem;
      `;
    case 'large':
      return css`
            width: 3rem;
            height: 3rem;
        `;
    default:
      return css`
          width: 2.5rem;
          height: 2.5rem;
      `;
  }
};

const getBorder = ({ primaryColor = baseTokens.gray400 }: iconFrameTypes.IconFrameProps) => {
  return css`
      border: 4px solid ${primaryColor};
      box-shadow: 0 0 0.5rem ${primaryColor};
      box-sizing: border-box;
  `;
};

const getLoadingAnimation = (props: iconFrameTypes.IconFrameProps) => {
  const { loading, primaryColor = baseTokens.gray400, secondaryColor = baseTokens.gray200 } = props;

  if (loading) {
    return css`
        @keyframes rotate {
            100% {
                transform: rotate(1turn);
            }
        }

        position: relative;
        z-index: 0;

        &::before {
            content: "";
            position: absolute;
            z-index: -2;
            left: -50%;
            top: -50%;
            width: 200%;
            height: 200%;
            background-color: ${secondaryColor};
            background-repeat: no-repeat;
            background-position: 0 0;
            background-image: conic-gradient(transparent, ${primaryColor}, transparent 30%);
            animation: rotate 1s linear infinite;
        }

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0.25rem;
            top: 0.25rem;
            width: calc(100% - 0.5rem);
            height: calc(100% - 0.5rem);
            background: ${baseTokens.colorWhite};
            border-radius: 50%;
        }
    `;
  }

  return getBorder(props);
};

const StyledIconFrame = styled.div<iconFrameTypes.IconFrameProps>`
    ${getSize}
    ${getLoadingAnimation}
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export { StyledIconFrame };
