import { tableTypes, UISeverityDots } from '@shared/components';
import { allRoutesEnum, globalConstants, globalModels } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import dayjs from 'dayjs';

export const applicationColumns: tableTypes.ColumnProps<globalModels.ApplicationsViewModel>[] = [
  {
    title: 'Application Name',
    columnKey: 'name',
    cellInfo: {
      cellTextWeight: 600,
      description(item) {
        return item.lastScanEndedAt ?
          `Last Scan: ${dayjs(item.lastScanEndedAt).format(globalConstants.FULL_MONTH_DATE_TIME)}` :
          '';
      },
      href(item) {
        return allRoutesEnum.ApplicationDetails.replace(':applicationId', item.id);
      }
    },
  },
  {
    title: 'Project',
    columnKey: 'projectName',
    cellInfo: {
      cellTextColor: muiPalette.grey?.[600],
    },
  },
  {
    title: 'Label(s)',
    columnKey: 'labels',
  },
  {
    title: 'Targets',
    columnKey: 'noOfTargets',
  },
  {
    title: 'Unique URL Paths',
    columnKey: 'vulnerablePathsStat',
    width: '11.46rem',
    customCellRenderer(value, item) {
      return (
        <UISeverityDots values={item.vulnerablePathsStat} popupComments='paths' />
      );
    },
  },
];