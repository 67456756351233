import { Credentials, CredentialsType, CredentialsUpdate } from '@api-client';
import { globalConstants, globalQueries, globalUtils } from '@shared/duck';
import { AxiosResponse } from 'axios';
import * as Yup from 'yup';
import { CustomCredentialsType, SupportedCredentials } from '.';
import { AuthenticationFormValues } from '../authentication-modal';
import { enqueueSnackbar } from 'notistack';

export const HeaderBasedSchema = Yup.object()
  .nullable()
  .when('type', {
    is: CustomCredentialsType.HeaderBased,
    then: schema =>
      schema.shape({
        headers: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().nullable().required('Name is required'),
            value: Yup.string().nullable().required('Value is required'),
          }),
        ),
      }),
  });

export const CookiesBasedSchema = Yup.object()
  .nullable()
  .when('type', {
    is: CustomCredentialsType.CookiesBased,
    then: schema =>
      schema.shape({
        cookie: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().nullable().required('Name is required'),
            value: Yup.string().nullable().required('Value is required'),
          }),
        ),
      }),
  });

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, 'Name must be at most 100 characters')
    .when('nameExists', {
      is: true,
      then: schema => schema.test('exists', 'This name is already taken in this Project', () => false),
      otherwise: schema => schema
        .test('isValid', globalConstants.INVALID_NAME_FORMAT, (value?: string | null) => {
          return value ? globalUtils.validateName(value || '') : true;
        }),
    }),
  description: Yup.string().nullable(),
  project: Yup.mixed().test('projects-field', 'Please select a project', (projectId: string) => {
    return !!projectId;
  }),
  type: Yup.string()
    .oneOf(SupportedCredentials.map(({ value }) => value as string))
    .required('Authentication Type is required'),
  headerBasedForm: HeaderBasedSchema,
  cookiesBasedForm: CookiesBasedSchema,
  playwrightUrl: Yup.string().when('type', {
    is: CustomCredentialsType.Script,
    then:
      schema => schema.required('Target URL is required')
        .test('isValid', 'Invalid format', (value?: string) => {
          const newUrls = value?.split('\n').filter(url => url.length);
          return globalUtils.validateUrls(newUrls || []);
        })
  }),
  nameExists: Yup.boolean().required('Name must be tested'),
});

export const onSubmitHandler = (authenticationId?: string) => {
  const { createHeaderBasedCredentials } = globalQueries.useCreateHeaderBasedCredentials();
  const { createCookiesBasedCredentials } = globalQueries.useCreateCookiesBasedCredentials();

  const onCreate = async (data: AuthenticationFormValues): Promise<AxiosResponse<Credentials, any> | null> => {
    let response: AxiosResponse<Credentials, any> | null = null;

    try {
      if (data.type === CustomCredentialsType.HeaderBased) {
        response = await createHeaderBasedCredentials({
          headerCredentialsRequest: {
            name: data.name,
            description: data.description,
            project: data.project,
            ...data.headerBasedForm,
          },
        });
        enqueueSnackbar('Header authentication has been created successfully', { variant: 'success' });
      }

      if (data.type === CustomCredentialsType.CookiesBased) {
        response = await createCookiesBasedCredentials({
          cookieCredentialsRequest: {
            name: data.name,
            description: data.description,
            project: data.project,
            ...data.cookiesBasedForm,
          },
        });
        enqueueSnackbar('Cookies Authentication has been created successfully', { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar('Failed to create new Authentication', { variant: 'error' });
    } finally {
      return response;
    }
  };

  const { updateAuthentication } = globalQueries.useCredentialsUpdate();

  const onUpdate = async (data: AuthenticationFormValues): Promise<AxiosResponse<CredentialsUpdate, any> | null> => {
    let response: AxiosResponse<CredentialsUpdate, any> | null = null;

    try {
      response = await updateAuthentication({
        id: authenticationId || '',
        credentialsUpdateRequest: {
          name: data.name || '',
          description: data.description,
          ...data.headerBasedForm,
          ...data.cookiesBasedForm,
        },
      });
      enqueueSnackbar('Authentication has been updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to update authentication', { variant: 'error' });
    } finally {
      return response;
    }
  };

  const onSubmit = authenticationId ? onUpdate : onCreate;

  return { onSubmit };
};


export const convertTypeToCustom = (type: CredentialsType) => {
  switch (type) {
    case CredentialsType.Script:
      return CustomCredentialsType.Script;
    case CredentialsType.Cookie:
      return CustomCredentialsType.CookiesBased;
    case CredentialsType.Header:
      return CustomCredentialsType.HeaderBased;
    case CredentialsType.UserPass:
      return CustomCredentialsType.UsernamePassword;
  }
};