import { Issue, IssuesApiIssuesUpdateRequest, IssueUpdate, ResolutionEnum } from '@api-client';
import { globalEnums, globalTypes } from '@shared/duck';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { isNumber } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { objectToYaml } from 'views/utils/formatting';

const findExtractedResult = (evidence?: string | null): string[] => {
  try {
    const evidenceJSON = evidence ? JSON.parse(evidence) : {};
    return evidenceJSON['extracted_results'];
  } catch (error) {
    return [''];
  }
};

const parseEvidence = (evidence?: string | null): globalTypes.CodeFragmentProps => {
  try {
    const evidenceJSON = evidence ? JSON.parse(evidence) : {};
    const code = objectToYaml(evidenceJSON);
    return {
      code,
      language: globalEnums.SupportedLangs.evidence_markup,
    };
  } catch (error) {
    return {
      code: evidence || '',
      language: globalEnums.SupportedLangs.evidence_markup,
    };
  }
};

const changeIssueResolution = (
  issue: Issue | undefined,
  updateIssue: UseMutateAsyncFunction<AxiosResponse<IssueUpdate, any>, unknown, IssuesApiIssuesUpdateRequest, unknown>,
  value?: ResolutionEnum,
  setCurrentIssue?: (issue: Issue) => void,
) => {
  if (isNumber(value)) {
    updateIssue(
      {
        id: issue?.id || '',
        issueUpdateRequest: { resolution: value },
      },
      {
        onSuccess: response => {
          if (issue && setCurrentIssue) {
            setCurrentIssue({
              ...issue,
              resolution: response.data.resolution as ResolutionEnum,
            });
          }
        },
      },
    ).catch(() =>
      enqueueSnackbar('Failed to change issue resolution', { variant: 'error' })
    );
  }
};

export {
  parseEvidence,
  findExtractedResult,
  changeIssueResolution,
};
