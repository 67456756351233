import { CredentialsRef } from '@api-client';
import { allRoutesEnum } from '@shared/duck';
import React from 'react';
import { StyledExtLink } from '@shared/components';
import { ExternalGrayLinkIcon } from 'views/components/v1/Icons';
import { Box, Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

interface CredentialsLinkProps {
  credentials?: CredentialsRef | null;
  targetProjectId: string;
}

const CredentialsLink: React.FC<CredentialsLinkProps> = ({
  credentials,
  targetProjectId,
}: CredentialsLinkProps) => {
  const link = allRoutesEnum.AuthenticationDetails.replace(':authenticationId', credentials?.id || '');
  const isDeleted = credentials?.is_deleted;
  const isCredFromAnotherProject = credentials?.project !== targetProjectId;

  return (
    <Box>
      {isDeleted ? (
        <Stack direction='row' gap='0.25rem' alignItems='center'>
          <Typography variant='default' fontWeight='500' color={muiPalette.grey?.[600]}>
            {credentials?.name}
          </Typography>
          {isDeleted && (
            <Typography variant='default' color={muiPalette.red?.[600]}>
              (Deleted)
            </Typography>
          )}
        </Stack>
      ) : (
        <Stack direction='row' gap='0.75rem' alignItems='center'>
          <Typography variant='default' color={muiPalette.grey?.[500]}>
            {credentials?.name}
          </Typography>
          {!isCredFromAnotherProject && (
            <StyledExtLink
              className='external-link'
              icon={<ExternalGrayLinkIcon />}
              alignIcon='end'
              href={link}
              target={'_blank'}
              rel='noopener noreferrer'
            />)
          }
        </Stack>
      )}
      {credentials?.description ? (
        <Typography variant='default' fontSize='0.75rem' color={muiPalette.grey?.[500]}>
          {credentials?.description}
        </Typography>
      ) : null}
    </Box>
  );
};

export default CredentialsLink;
