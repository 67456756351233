import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { muiPalette } from '@shared/general-mui-theme';

const StyledGridItem = styled(Grid)`
  padding: 0.75rem;

  &:nth-of-type(2n +1) {
    padding-left: 0;
    padding-right: 1.25rem;

    & > div {
      padding: 0;
      width: 100%;
      text-align: center;
    }
    & span {
      width: 100%;
      text-transform: capitalize;
    }
  }
  border-top: 1px solid ${muiPalette.grey?.[300]};
`;

export { StyledGridItem };
