import React from 'react';
import { observer } from 'mobx-react';
import { useApp, useLaunchDarklyProvider, useSetDatadogRumUser } from '@shared/hooks';
import { User } from 'api-client';
import WorkspaceRoutes from './workspace-routes';
import { allRoutesEnum } from '@shared/duck';
import { GlobalModal } from '@shared/duck/contexts';
import { MuiContentLoader } from '@shared/components';

const Workspace: React.FC = () => {
  const app = useApp();
  const currentUser: User | undefined = app.users.me?.dto;
  const { LDProvider, isLDProviderInitialized } = useLaunchDarklyProvider({ currentUser });
  const { setUser: setDatadogRumUser } = useSetDatadogRumUser();
  setDatadogRumUser(currentUser);

  // FIXME: handle error page properly - skip loading
  const isPublicRoute = window.location.pathname.startsWith(allRoutesEnum.Error) || window.location.pathname.startsWith(allRoutesEnum.SSO);
  const isLoading = !isPublicRoute && (!isLDProviderInitialized || !currentUser);

  return (
    <MuiContentLoader
      isLoading={isLoading}
      variant='dots'
    >
      <LDProvider>
        <GlobalModal>
          <WorkspaceRoutes />
        </GlobalModal>
      </LDProvider>
    </MuiContentLoader>
  );
};

export default observer(Workspace);
