import { MuiTargetSelect } from '@shared/components';
import { useFormikContext } from 'formik';
import React from 'react';
import { formDataTypes } from '../../duck';
import { Target } from '@api-client';
import { globalEnums, globalQueries } from '@shared/duck';
import { IFormControlEvent } from 'views/utils/form';

interface TargetSelectProps {
  readOnly?: boolean;
}

const TargetSelect: React.FC<TargetSelectProps> = ({
  readOnly = false,
  ...props
}) => {
  const { setFieldValue, values } = useFormikContext<formDataTypes.FormDataFormValues>();

  const { getLastScan } = globalQueries.useGetLastScanMutation();

  const onFormChange = async (e: IFormControlEvent<Target | undefined>) => {
    setFieldValue('target', e.target.value);
    if (e.target.value && e.target.value?.id !== values.target?.id) {
      setFieldValue('baseUrl', e.target.value?.location || '');
    }
    setFieldValue('newTargetName', '');
    if (e.target.value) {
      const lastScanInfo = await getLastScan({ target: [e.target.value.id] }).then(res => res.data.results);

      if (lastScanInfo?.[0]) {
        const auth = lastScanInfo?.[0]?.credentials;
        setFieldValue('application', lastScanInfo?.[0]?.application);
        setFieldValue('authentication', auth && auth?.is_deleted ? null : auth);
      }
    }
  };

  const setNewTargetName = (newName: string, e?: any) => {
    if (e?.key === globalEnums.EKeyboardKeys.ENTER || e?.type === 'blur') {
      if (!values.target && !!newName) {
        setFieldValue('enableTestCheckbox', true);
        setFieldValue('createNewTargetCheckbox', true);
      }
    }

    if (newName !== values.newTargetName)
      setFieldValue('enableTestCheckbox', false);

    if (newName !== values.target?.name) {
      setFieldValue('newTargetName', newName);
    }
    else {
      setFieldValue('newTargetName', '');
    }
  };

  const onAfterTestName = ({ exists }: { exists?: boolean }) => {
    setFieldValue('nameExists', exists);
  };

  return (
    <MuiTargetSelect
      {...props}
      isFreeSolo={!readOnly}
      label=''
      defaultTarget={values.target as Target}
      projectId={values.project?.id}
      onFormChange={onFormChange}
      defaultNewTargetName={readOnly ? values.target?.name : values.newTargetName}
      setNewTargetName={readOnly ? undefined : setNewTargetName}
      onAfterTestName={readOnly ? undefined : onAfterTestName}
      forceCheck={values.createNewTargetCheckbox}
    />
  );
};

export default TargetSelect;