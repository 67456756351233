import { globalQueries } from '@shared/duck';
import { enqueueSnackbar } from 'notistack';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

export const useDeleteScanHandler = () => {
  const { deleteScan } = globalQueries.useDeleteScan();
  const onDeleteScan = async (id: string, name?: string) => {
    try {
      await deleteScan({ id });
      enqueueSnackbar(<><b>{name}</b> Scan has been deleted successfully</>, { variant: 'success' });
      return true;
    } catch (error) {
      enqueueSnackbar(`Failed to delete ${name} Scan`, { variant: 'error' });
      return false;
    }
    return false;
  };

  const { showModal } = useGlobalModalContext();

  const deleteScanHandler = async (id: string, targetName: string) => {
    const result = new Promise<boolean>((resolve) => {
      showModal({
        modalType: MODAL_TYPE.DeleteModal,
        modalProps: {
          title: 'Delete Scan?',
          deleteText: 'Are you sure you want to delete this Scan of',
          name: targetName,
          onConfirm: () => resolve(onDeleteScan(id, targetName)),
          onCancel: () => resolve(false),
        },
      });
    });
    return result;
  };

  return { deleteScanHandler };
};

export const useBulkDeleteScansHandler = () => {
  const { deleteScans } = globalQueries.useDeleteScans();
  const onDeleteScans = async (scans: string[]) => {
    try {
      await deleteScans({ scan: scans });
      enqueueSnackbar('Selected Scans have been deleted successfully', { variant: 'success' });
      return true;
    } catch (error) {
      enqueueSnackbar('Failed to delete selected Scan', { variant: 'error' });
      return false;
    }
    return false;
  };

  const { showModal } = useGlobalModalContext();

  const bulkDeleteScansHandler = async (scans: string[]) => {
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete selected Scans?',
        deleteText: `Are you sure you want to delete ${scans.length} selected scans`,
        confirmButtonText: 'Yes, delete',
        onConfirm: () => onDeleteScans(scans),
      },
    });
  };

  return { bulkDeleteScansHandler };
};