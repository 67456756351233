import { Scan } from '@api-client';
import { globalQueries } from '@shared/duck';
import { SCAN_REFETCH_INTERVAL } from '../../../components/duck';
import React from 'react';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext } from '@shared/duck/contexts';

export const getTarget = (targetId?: string) => {
  const { setError } = React.useContext(ErrorContext);
  return globalQueries.useGetTarget(
    { id: targetId ?? '' },
    {
      onError: (er) => errorResponseHandler(er, 'target', setError),
      enabled: !!targetId
    },
  );
};

export const getScans = (scanId: string, fetchedScan: React.MutableRefObject<Scan | undefined>) => {
  const { setError } = React.useContext(ErrorContext);
  return globalQueries.useGetScan(
    { id: scanId ?? '' },
    {
      onSettled: data => {
        fetchedScan.current = data?.data;
      },
      refetchInterval: !fetchedScan.current?.ended_at ? SCAN_REFETCH_INTERVAL : undefined,
      onError: (er) => errorResponseHandler(er, 'scan', setError),
      enabled: !!scanId,
    },
  );
};
