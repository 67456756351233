import { TypographyOptions } from '@mui/material/styles/createTypography';
declare module '@mui/material/styles' {
  interface TypographyVariants {
    default: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    default?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    default: true;
  }
}

export const muiTypography: TypographyOptions = {
  fontFamily: [
    '"SF Pro Text"',
    '-apple-system',
    'BlinkMacSystemFont',
    'sans-serif',
  ].join(','),
  h5: {
    /** Note: Header */
    color: '#111B2B', // grey900
    fontSize: '1.75rem',
    fontWeight: 600,
    // ellipsis activated by 'noWrap' prop, requires a container with minWidth
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle1: {
    lineHeight: '1.5rem', // size: 1rem
    color: '#111B2B', // grey900
  },
  subtitle2: {
    lineHeight: '1.25rem', // size: 0.875rem, weight: 500
    color: '#111B2B',
  },
  body2: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 400,
    color: '#1B273A', // gray 800
  },
  default: {
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    fontWeight: 400,
    color: '#1B273A',
  }
};