import { IconProps } from './BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from './icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <path
      d='M6.68671 2.43904C7.25738 1.40704 8.74072 1.40704 9.31138 2.43904L14.4754 11.773C15.0287 12.773 14.3054 13.9997 13.1627 13.9997H2.83605C1.69271 13.9997 0.969381 12.773 1.52271 11.773L6.68671 2.43971V2.43904ZM8.66471 11.333C8.66754 11.2438 8.65241 11.155 8.62023 11.0718C8.58804 10.9885 8.53946 10.9126 8.47737 10.8485C8.41528 10.7845 8.34094 10.7335 8.25877 10.6987C8.17661 10.6639 8.08828 10.646 7.99905 10.646C7.90981 10.646 7.82149 10.6639 7.73932 10.6987C7.65715 10.7335 7.58282 10.7845 7.52072 10.8485C7.45863 10.9126 7.41005 10.9885 7.37787 11.0718C7.34569 11.155 7.33056 11.2438 7.33338 11.333C7.33885 11.5059 7.41139 11.6699 7.53562 11.7903C7.65986 11.9107 7.82606 11.978 7.99905 11.978C8.17203 11.978 8.33823 11.9107 8.46247 11.7903C8.58671 11.6699 8.65924 11.5059 8.66471 11.333ZM8.49205 6.09771C8.47477 5.97215 8.41046 5.85784 8.31212 5.77789C8.21378 5.69794 8.08874 5.65832 7.9623 5.66704C7.83586 5.67577 7.71745 5.73218 7.63102 5.82488C7.54459 5.91757 7.49658 6.03963 7.49672 6.16637L7.49938 9.16704L7.50405 9.23504C7.52132 9.3606 7.58564 9.47491 7.68398 9.55486C7.78232 9.63481 7.90735 9.67443 8.03379 9.6657C8.16023 9.65698 8.27864 9.60057 8.36508 9.50787C8.45151 9.41517 8.49951 9.29311 8.49938 9.16637L8.49672 6.16504L8.49205 6.09771Z'
      fill='#F07F23'
    />
  </svg>
);

function ResolutionOpenIcon(props: IconProps) {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default ResolutionOpenIcon;
