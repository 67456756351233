import React from 'react';
import { GlobalModalInfo, MODAL_TYPE, MODAL_COMPONENT_BY_TYPE } from './modals';


const NO_MODAL_INFO: GlobalModalInfo = { modalType: MODAL_TYPE.None, modalProps: null };

interface GlobalModalContext {
  showModal: (modalInfo: GlobalModalInfo) => void;
  hideModal: () => void;
  currentModal: GlobalModalInfo;
}

const initialState: GlobalModalContext = {
  showModal: () => {},
  hideModal: () => {},
  currentModal: NO_MODAL_INFO,
};

const GlobalModalContext = React.createContext<GlobalModalContext>(initialState);

export const useGlobalModalContext = () => React.useContext(GlobalModalContext);

export const GlobalModal: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [currentModal, setCurrentModal] = React.useState<GlobalModalInfo>(NO_MODAL_INFO);
  const { modalType, modalProps } = currentModal;

  const showModal = React.useCallback((modalInfo: GlobalModalInfo) => {
    setCurrentModal(modalInfo);
  }, []);

  const hideModal = React.useCallback(() => {
    setCurrentModal(NO_MODAL_INFO);
  }, []);

  const renderModal = () => {
    const ModalComponent = MODAL_COMPONENT_BY_TYPE[modalType];
    if (modalType == MODAL_TYPE.None || !ModalComponent) {
      return null;
    }
    return <ModalComponent id="global-modal" {...modalProps} />;
  };

  return (
    <GlobalModalContext.Provider value={{ currentModal, showModal, hideModal }}>
      {renderModal()}
      {children}
    </GlobalModalContext.Provider>
  );
};
