import React from 'react';
import { MuiTable, tableTypes } from '@shared/components';
import {
  ProjectsTableProps,
  projectsColumns,
  useGetItemListHandler,
  useProjectsTableApi,
} from './duck';
import { globalModels } from '@shared/duck';
import { ProjectGray } from '@assets';
import { useApp } from '@shared/hooks';

const ProjectsTable: React.FC<ProjectsTableProps> = ({
  filter,
}) => {
  const app = useApp();

  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [filter]);

  const {
    projects,
    totalItemsCount,
    isProjectsListLoading,
    onProjectsRowClick,
  } = useProjectsTableApi({
    page: currentPage,
    filter: filter,
    pageSize: rowsPerPage,
  });

  const { getItemList } = useGetItemListHandler();

  const tableConfig: tableTypes.MuiTableProps<globalModels.ProjectViewModel> = {
    data: projects,
    columns: projectsColumns(app.users.me?.dto.id),
    loading: isProjectsListLoading,
    onRowClick: onProjectsRowClick,
    itemActions: getItemList,
    noContentMessage: 'No Projects found',
    noContentIcon: ProjectGray,
    pagination: {
      count: totalItemsCount,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: (e, newPage) => handleChangePage(newPage),
      onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
    },
  };

  return (
    <MuiTable
      {...tableConfig}
    />
  );
};

export default ProjectsTable;