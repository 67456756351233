import styled from '@emotion/styled';
import { UICodeFragment } from '@shared/components';

const StyledCodeFragment = styled(UICodeFragment)`
  pre {
    margin: 0;
  }
`;

export { StyledCodeFragment };

