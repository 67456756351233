import { Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';

export interface DescriptionProps {
  title: string;
  value: string;
}

export const Description: React.FC<DescriptionProps> = ({
  title,
  value,
}) => {
  return (
    <Stack gap='0'>
      <Typography variant='body2' fontWeight='600' lineHeight='1.25rem' color={`${muiPalette.grey?.[800]} !important`}>
        {title}
      </Typography>
      <Typography variant='default' color={muiPalette.grey?.[700]}>
        {value}
      </Typography>
    </Stack>
  );
};