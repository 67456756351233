import React, { useMemo } from 'react';
import { IFormControlEvent } from 'views/utils/form';
import { durationSelectUtils } from './duck';
import { SelectOld } from '@shared/components';
import { Box } from '@mui/material';

interface DurationSelectProps {
  initialPeriod?: durationSelectUtils.DurationPeriod;
  onChange?: (value: durationSelectUtils.DurationPeriod) => void;
  name?: string;
}

const DurationOptions = [
  { label: 'Last week', value: durationSelectUtils.DurationPeriod.Week },
  { label: 'Last month', value: durationSelectUtils.DurationPeriod.Month },
  { label: 'Last year', value: durationSelectUtils.DurationPeriod.Year },
];

const DurationSelect: React.FC<DurationSelectProps> = ({ initialPeriod, onChange, name }) => {
  const initialValue = useMemo(
    () => (
      initialPeriod !== undefined ? DurationOptions.find(x => x.value == initialPeriod) : undefined
    ),
    [initialPeriod],
  );

  function onValueChange(e: IFormControlEvent<string | number | undefined>): void {
    const delta = e.target.value as durationSelectUtils.DurationPeriod;
    if (onChange) {
      onChange(delta);
    }
  }

  return (
    <Box>
      <SelectOld
        name={name}
        options={DurationOptions}
        initialValue={initialValue}
        withEmptyItem={false}
        withSearch={false}
        onChange={onValueChange}
      />
    </Box>
  );
};

export default DurationSelect;
