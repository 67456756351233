import baseTokens from '@contentful/f36-tokens';
import { Stack } from '@mui/material';
import { LockKey } from '@phosphor-icons/react';
import { MuiContentLoader } from '@shared/components';
import { useApp } from '@shared/hooks';
import { ReportCard } from './components';
import React from 'react';
import { Scan } from '@api-client';
import { ScanStatusEnum } from 'models/Enums';
import { enqueueSnackbar } from 'notistack';

interface ScanDetailsReportsProps {
  scan?: Scan;
}

const ScanDetailsReports: React.FC<ScanDetailsReportsProps> = ({
  scan,
}) => {
  const { scansApi } = useApp();
  const isInProgress = scan?.status === ScanStatusEnum.IN_PROGRESS;

  const csvIcon = () => {
    return <LockKey size='2rem' weight='fill' color={baseTokens.blue400} />;
  };

  /* const sarifIcon = () => {
   return <Cloud size='2rem' weight='fill' color={baseTokens.blue400} />;
   }; */

  return (
    <Stack p='1rem' overflow='auto'>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={3}
        width='100%'
      >
        <MuiContentLoader isLoading={!scan}>
          <ReportCard
            reportIcon={csvIcon()}
            title={'CSV Report'}
            description={'Get all findings report in a CSV format'}
            generateAction={async () => {
              await scansApi.scansReportCsvRetrieve({ id: scan?.id || '' });
              enqueueSnackbar('We are processing your scan report. You will receive it via email soon.', { variant: 'info' });
            }}
            tooltip={isInProgress ? 'You can generate a report once the scan is finished' : ''}
          />

          {/*        <ReportCard
         reportIcon={sarifIcon()}
         title={'SARIF file'}
         description={'Get a standardised SARIF file to share code scanning results'}
         generateAction={() => ''}
         /> */}

        </MuiContentLoader>
      </Stack>
    </Stack>
  );
};

export default ScanDetailsReports;