import { useFormikContext } from 'formik';
import React from 'react';
import { formDataTypes } from '../../duck';
import {
  Checkbox,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  Stack,
  StandardTextFieldProps,
  Typography,
} from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { globalConstants } from '@shared/duck';

const CreateTargetCheckbox: React.FC<Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, 'variant'>> = ({ ...props }) => {
  const { values, setFieldValue } = useFormikContext<formDataTypes.FormDataFormValues>();
  const [checked, setChecked] = React.useState(!!values.createNewTargetCheckbox);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setFieldValue('createNewTargetCheckbox', event.target.checked);
    props.onChange?.(event);
  };

  React.useEffect(() => {
    setChecked(!!values.createNewTargetCheckbox);
  }, [values.createNewTargetCheckbox]);

  return (
    <Stack direction='row' alignItems='center' gap={0.25}>
      <Checkbox
        name={props.name}
        checked={checked} onChange={handleChange}
        disableRipple
        sx={{
          padding: '0',
          color: props.error ? globalConstants.INPUT_BORDER.ERROR : '',
        }}
      />
      <Typography
        variant='subtitle2'
        fontWeight={400}
        color={muiPalette.grey?.['600']}
      >
        {'Create new Target'}
      </Typography>
    </Stack>
  );
};

export default CreateTargetCheckbox;