import { Dalfox, GoSpider, Katana, Nuclei, Playwright, Zap } from '@assets';
import { ComponentEnum } from './duck/enums';
import { globalUtils } from '@shared/duck';

interface ColumnComponentAssetProps {
  associatedEnumValue: ComponentEnum;
}

const ColumnComponentAsset: React.FC<ColumnComponentAssetProps> = ({
  associatedEnumValue
}: ColumnComponentAssetProps) => {
  const imgSrc: string | undefined = (() => {
    switch (associatedEnumValue) {
      case ComponentEnum.Zap:
        return Zap;
      case ComponentEnum.Dalfox:
        return Dalfox;
      case ComponentEnum.Playwright:
        return Playwright;
      case ComponentEnum.Katana:
        return Katana;
      case ComponentEnum.Gospider:
        return GoSpider;
      case ComponentEnum.Nuclei:
        return Nuclei;
      case ComponentEnum.Unknown:
        return;
    }
  })();

  return (
    imgSrc && globalUtils.createCustomIcon(imgSrc, {})
  );
};

export default ColumnComponentAsset;