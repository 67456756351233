import React from 'react';
import { Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

export interface FormControlLabelProps {
  label: string;
  error: boolean;
  required?: boolean;
  optional?: boolean;
}

const FormControlLabel: React.FC<FormControlLabelProps> = ({
  label,
  error,
  required = false,
  optional = false,
}) => {
  const isAdditionalText = React.useMemo(() => {
    return required || optional;
  }, [required, optional]);

  return (
    <Stack
      gap='0.25rem'
      flexDirection='row'
      pb='0.5rem'
    >
      <Typography variant='subtitle2'>
        {label}
      </Typography>

      {isAdditionalText && (
        <Typography
          variant='subtitle2'
          color={`${error && required ? 'error' : muiPalette.grey?.['500']}`}
          sx={{ opacity: `${error && required ? '1' : '0.5'}` }}
        >
          {required ? '(required)' : '(optional)'}
        </Typography>
      )}
    </Stack>
  );
};

export default FormControlLabel;
