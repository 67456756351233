export const enum ComponentEnum {
  Zap = 0,
  Gospider = 1,
  Katana = 2,
  Nuclei = 3,
  Dalfox = 4,
  Playwright = 5,
  Unknown = 6,
}

export const ComponentEnumValues = [
  ComponentEnum.Zap,
  ComponentEnum.Gospider,
  ComponentEnum.Katana,
  ComponentEnum.Nuclei,
  ComponentEnum.Dalfox,
  ComponentEnum.Playwright,
  ComponentEnum.Unknown,
];

export const ComponentEnumNames = new Map<ComponentEnum, string>([
  [ComponentEnum.Dalfox, 'Dalfox'],
  [ComponentEnum.Gospider, 'Gospider'],
  [ComponentEnum.Katana, 'Katana'],
  [ComponentEnum.Nuclei, 'Nuclei'],
  [ComponentEnum.Playwright, 'Playwright'],
  [ComponentEnum.Unknown, 'Unknown'],
  [ComponentEnum.Zap, 'ZAP'],
]);
