import { allRoutesEnum } from '@shared/duck';
import { NightvisionLogoLink } from './nightvision-logo.styled';
import { NightvisionFullIcon, NightvisionSmallIcon } from 'views/components/v1/Icons';

interface NightvisionLogoProps {
  showText?: boolean;
}

const NightvisionLogo: React.FC<NightvisionLogoProps> = ({
  showText = false
}: NightvisionLogoProps) => {
  return (
    <NightvisionLogoLink to={allRoutesEnum.Home}>
      {showText ? <NightvisionFullIcon /> : <NightvisionSmallIcon />}
    </NightvisionLogoLink>
  );
};

export default NightvisionLogo;
