import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { scansTableConsts, scansTableHooks } from './duck';
import { tableTypes, MuiTable } from '@shared/components';
import { allRoutesEnum, globalConstants, globalModels } from '@shared/duck';
import { ScansGray } from '@assets';

interface ScansTableProps {
  credentialsId: string;
  startDate?: Date | undefined;
}

const ScansTable: React.FC<ScansTableProps> = ({
  credentialsId,
  startDate,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [startDate, credentialsId]);

  const {
    scans,
    totalItemsCount,
    isScansListLoading,
    scansHasNextPage,
    fetchNextScansPage,
  } = scansTableHooks.useScansTableApi(
    {
      credentialsId: credentialsId,
      startDate: startDate,
      page: currentPage,
      pageSize: rowsPerPage,
    },
  );

  const scansConfig: tableTypes.MuiTableProps<globalModels.ScansViewModelNew> = {
    data: scans,
    columns: scansTableConsts.scansColumns,
    loading: isScansListLoading,
    isLoadMoreAvailable: scansHasNextPage,
    onLoadMoreClick: fetchNextScansPage,
    onRowClick: (item) => (
      navigate(
        allRoutesEnum.ScanDetailsFindings.replace(':scanId', item.id),
        {
          state: {
            customBackUrl: allRoutesEnum.AuthenticationDetails.replace(':authenticationId', item.credentials?.id ?? ''),
          },
        },
      )
    ),
    noContentMessage: 'No Scans found',
    noContentIcon: ScansGray,
    groupRowsBy: (item: globalModels.ScansViewModelNew) => dayjs(item.createdAt)
      .format(globalConstants.FULL_MONTH_DATE),
    withExternalBorders: false,
    pagination: {
      count: totalItemsCount,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: (e, newPage) => handleChangePage(newPage),
      onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
    },
  };

  return (
    <MuiTable
      {...scansConfig}
    />
  );
};

export default ScansTable;