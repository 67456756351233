import { Stack, Typography } from '@mui/material';
import { DocsLink, UICodeFragment } from '@shared/components';
import { globalConstants, globalEnums } from '@shared/duck';
import React from 'react';

interface CurlTabProps {
  validateWithCurl: string;
  validateWithCurlWindows: string;
}

export const CurlTab: React.FC<CurlTabProps> = ({
  validateWithCurl,
  validateWithCurlWindows,
}) => {
  return (
    <Stack width='100%'>
      <Typography variant='body2' fontSize='0.875rem' paddingTop='1rem'>
        {'Copy and Paste the following in your CLI.'}
      </Typography>
      <UICodeFragment
        code={validateWithCurl}
        windowsCode={validateWithCurlWindows}
        language={globalEnums.SupportedLangs.evidence_markup}
        useTabsForMultiline
      />
      <Stack gap={0} flexDirection='row' alignItems='center'>
        <Typography variant='body2' fontSize='0.875rem'>
          {'Learn more about CLI Web Scans in our'}&nbsp;
        </Typography>
        <DocsLink
          to={globalConstants.DOCS_PATHS.CLI_CHEAT_SHEET_SCANNING}
        >
          {'documentation'}
        </DocsLink>
      </Stack>
    </Stack>
  );
};
