import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';

const StyledCodeWrapper = styled.div`
  /* position: relative; */
  margin: 0;

  & .copy {
    position: absolute;
    right: 0;
    z-index: 10;
  }

  & pre {
    min-height: 0.25rem;
    padding-right: 2.5rem;
  }

  & code.language-bash {
    color: ${baseTokens.green300};

    & .command-line-prompt {
      color: ${baseTokens.orange300};
    }

    & .variable {
      color: ${baseTokens.red400};
    }
  }

  & .danger {
    background-color: ${baseTokens.red600};
    color: ${baseTokens.colorWhite};
  }
`;

export { StyledCodeWrapper };
