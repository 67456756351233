import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { GenerateSpec, UploadSpec, AddUrl } from './components';
import { useLaunchDarklyFlags } from '@shared/hooks';

enum CLI_COMMANDS {
  GENERATE = 'generate',
  UPLOAD = 'upload',
  URL = 'url',
}

export interface CliCommandsProps {
  fileAttached: boolean;
}

const CliCommands: React.FC<CliCommandsProps> = ({
  fileAttached,
}) => {
  const { scanWizardNewFileSelect } = useLaunchDarklyFlags();

  const [alignment, setAlignment] = React.useState(CLI_COMMANDS.GENERATE);
  const handleChange = (_: any, newAlignment: CLI_COMMANDS) => {
    newAlignment && setAlignment(newAlignment);
  };

  return (
    <>
      {!fileAttached && (
        <ToggleButtonGroup
          color='primary'
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label='swagger-file'
          sx={{ marginBottom: '0.5rem' }}
        >
          <ToggleButton value={CLI_COMMANDS.GENERATE}>Generate Swagger file</ToggleButton>
          <ToggleButton value={CLI_COMMANDS.UPLOAD}>Upload Swagger file</ToggleButton>
          {scanWizardNewFileSelect && <ToggleButton value={CLI_COMMANDS.URL}>OpenAPI URL</ToggleButton>}
        </ToggleButtonGroup>
      )}
      {(
        () => {
          switch (alignment) {
            case CLI_COMMANDS.GENERATE:
              return <GenerateSpec fileAttached={fileAttached} />;
            case CLI_COMMANDS.UPLOAD:
              return <UploadSpec fileAttached={fileAttached} />;
            case CLI_COMMANDS.URL:
              return <AddUrl fileAttached={fileAttached} />;
          }
        }
      )()}
    </>
  );
};

export default CliCommands;