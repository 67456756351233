import { ScanStatus } from '@api-client';
import { ScanStatusSelectionApiProps } from './types';

export const SCAN_STATUSES: ScanStatusSelectionApiProps[] = [
  { id: [ScanStatus.NUMBER_3, ScanStatus.NUMBER_4, ScanStatus.NUMBER_5], name: 'Disrupted' },
  { id: [ScanStatus.NUMBER_2], name: 'In Progress' },
  { id: [ScanStatus.NUMBER_1], name: 'Completed' },
  { id: 'archived', name: 'Archived' },
];

export enum FilterName {
  STARTDATE = 'Start Date',
  ENDDATE = 'End Date',
  TARGET = 'Target',
  PROJECT = 'Project',
  APPLICATION = 'Application',
  AUTHENTICATION = 'Authentication',
  INITIATEDBY = 'Initiated By',
  ACCESSIBILITY = 'Accessibility',
  STATUS = 'Status',
}