import dayjs from 'dayjs';
import React from 'react';

function getTimeDeltaSecondsForNow(fromDate: string): number {
  const now = dayjs();
  let result = now.diff(fromDate || now, 'seconds', true);
  if (result < 0) {
    result = 0;
  }
  return result;
}

const useTimer = ({
  fromDate,
  formatter,
  isActive,
}: {
  fromDate: string
  formatter?: (t: number) => string
  isActive: boolean
}) => {
  const [time, setTime] = React.useState<number>(() => getTimeDeltaSecondsForNow(fromDate));
  const intervalRef = React.useRef<any>();

  React.useEffect(() => {
    if (fromDate) {
      const time = getTimeDeltaSecondsForNow(fromDate);
      setTime(time);
    }
  }, [fromDate]);

  React.useEffect(() => {
    if (isActive) {
      intervalRef.current = setInterval(() => {
        const time = getTimeDeltaSecondsForNow(fromDate);
        setTime(time);
      }, 1000);
    }

    return () => {
      if (!isActive) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isActive]);

  const formattedTime = formatter ? formatter(time) : time;

  return { time: formattedTime };
};

export default useTimer;
