import { CircularProgress } from '@mui/material';
import { FormikControlMui, UIFileInput } from '@shared/components';
import { globalEnums } from '@shared/duck';
import { useFormikContext } from 'formik';
import React from 'react';
import { ImportCsvFileFormikValues, parseCsv } from '../duck';

export const ImportCsvContent: React.FC = () => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext<ImportCsvFileFormikValues>();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const parse = async (file: File) => {
      return await parseCsv(file);
    };

    if (values.file) {
      setIsLoading(true);
      parse(values.file)
        .then(res => {
          setFieldValue('data', res?.data ? [...new Set(res.data)] : undefined);
          setIsLoading(false);
        });
    }
    else {
      setFieldValue('data', null);
    }
  }, [values.file]);

  return (
    <FormikControlMui
      name='file'
      label='Upload a CSV'
      helpText='The file should contain a column labeled "path" in which you specify the paths you wish to import.'
    >
      <UIFileInput
        accept={[globalEnums.EFileExtension.CSV]}
        helpText='.CSV'
        onAfterChange={file => {
          setFieldTouched('file', true);
          setFieldValue('file', file);
        }}
        customEndIcon={
          isLoading
            ? <CircularProgress size={20} />
            : null
        }
      />
    </FormikControlMui>
  );
};