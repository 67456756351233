import React from 'react';
import { AttentionGray } from '@assets';
import { Box, Button } from '@mui/material';
import { CaretDown } from '@phosphor-icons/react';
import { NoContentPlaceholder } from '@shared/components';
import { globalTypes } from '@shared/duck';
import { TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso';
import { fixedHeaderContent, getTableComponents, rowContent } from './duck';

interface LogsTableProps {
  filteredLogs: globalTypes.ScanLogItem[];
  isLoading: boolean;
  tableRef: React.RefObject<TableVirtuosoHandle>;
  initialTopMostItemIndex: number;
  setCheckName: React.Dispatch<React.SetStateAction<string>>;
}

export const LogsTable: React.FC<LogsTableProps> = ({
  filteredLogs,
  isLoading,
  tableRef,
  initialTopMostItemIndex,
  setCheckName,
}) => {
  const [atBottom, setAtBottom] = React.useState(() => false);
  const showButtonDownTimeoutRef = React.useRef<NodeJS.Timeout>();
  const [showButtonDown, setShowButtonDown] = React.useState(false);

  React.useEffect(() => {
    clearTimeout(showButtonDownTimeoutRef.current);
    if (!atBottom) {
      showButtonDownTimeoutRef.current = setTimeout(() => setShowButtonDown(true), 500);
    } else {
      setShowButtonDown(false);
    }
  }, [atBottom, setShowButtonDown]);

  return (
    filteredLogs.length > 0 || isLoading ? (
      <Box height='90vh'>
        <TableVirtuoso
          components={getTableComponents()}
          data={filteredLogs}
          fixedHeaderContent={() => fixedHeaderContent(isLoading)}
          itemContent={(index, row) => rowContent(index, row, setCheckName)}
          initialTopMostItemIndex={initialTopMostItemIndex}
          ref={tableRef}
          followOutput='smooth'
          atBottomStateChange={setAtBottom}
        // style={{ maxHeight: '90vh', height: '100%' }}
        />
        {showButtonDown && (
          <Button
            sx={{ minWidth: 'unset', padding: '0.5rem', float: 'right', transform: 'translate(-1rem, -3rem)', cursor: 'pointer', borderRadius: '50%' }}
            onClick={() => tableRef.current?.scrollToIndex({ index: filteredLogs.length ? filteredLogs.length - 1 : 0, behavior: 'smooth' })}
          >
            <CaretDown size='1.5rem' />
          </Button>
        )}
      </Box>
    ) : (
      <NoContentPlaceholder message='No logs found' height='18.75rem' iconSrc={AttentionGray} />
    )
  );
};


