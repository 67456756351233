import { AutocompleteClasses, AutocompleteProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { CaretDown } from '@phosphor-icons/react';

export const AutocompleteStyles: Partial<OverridesStyleRules<keyof AutocompleteClasses, 'MuiAutocomplete'>> | undefined =
{
  inputRoot: {
    padding: '0.75rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: '#67728A', // grey500
  },
  input: {
    padding: '0px !important',
  },
  noOptions: {
    lineHeight: '1.25rem',
    color: '#111B2B',
    fontSize: '0.875rem'
  },
  tag: {
    color: 'default',
    fontWeight: 600,
    maxHeight: '1.25rem',
  },
  popper: {
    color: '#5A657C'
  },
  listbox: {
    color: '#5A657C',
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    padding: 'initial',
  },
  option: {
    ':hover': {
      backgroundColor: '#F7F9FA',
    },
    '&.Mui-focused': {
      backgroundColor: '#F7F9FA',
    }
  },
};

export const AutocompleteDefaultProps: Partial<AutocompleteProps<any, any, any, any, 'div'>> | undefined = {
  popupIcon: <CaretDown size={16} style={{ padding: '0.25rem' }} weight='bold' cursor='pointer' />,
};