import { globalEnums } from '@shared/duck';
import { LoginStatus, Result } from './types';

export const getVariantOfLastLimitScans = (data: LoginStatus, limit: number) => {
  const status = { failed: 0, success: 0 };
  if (data !== undefined) {
    // status.success = Object.values(data).filter((item: string) => item === 'success').length;
    status.failed = Object.values(data).filter((item: string) => item === 'failure').length;
  }

  let result: Result | undefined;
  // if (status.success === limit) result = { variant: 'positive', status: 'success' };
  if (status.failed > 0 && status.failed <= limit) result = {
    variant: globalEnums.EIconVariants.WARNING,
    status: 'failed'
  };
  return result;
};