export enum MAIN_TAB {
  REQUEST = 'request',
  RESPONSE = 'response',
}

export enum REQUEST_TAB {
  HEADERS = 'headers',
  EVIDENCE = 'evidence',
  QUERYSTRING = 'queryString',
  BODY = 'body',
  POSTDATA = 'postData',
  COOKIES = 'cookies',
  RAW = 'raw'
}

export enum RESPONSE_TAB {
  BODY = 'body',
  EVIDENCE = 'evidence',
  HEADERS = 'headers',
  COOKIES = 'cookies',
  RAW = 'raw',
}

export enum SEARCH_TAB_NAME {
  REQUEST_TAB = 'request-tab',
  RESPONSE_TAB = 'response-tab',
}