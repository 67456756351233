import { tableTypes } from '@shared/components';
import { globalEnums, globalModels, globalUtils } from '@shared/duck';

export const targetsColumns: tableTypes.ColumnProps<globalModels.TargetViewModel>[] = [
  {
    title: 'Target',
    columnKey: 'name',
    minWidth: '35rem',
    cellInfo: {
      cellTextWeight: 600,
      getIcon(item) {
        return globalUtils.getTargetTypeIcon(
          item.type.toString()?.toUpperCase() as globalEnums.ETargetType,
        );
      },
      description(item) {
        return item.location || '';
      },
    }
  },
];