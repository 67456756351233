import { MuiAuthenticationSelect } from '@shared/components';
import { useFormikContext } from 'formik';
import React from 'react';
import { formDataTypes } from '../../duck';

const AuthSelect: React.FC = ({
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext<formDataTypes.FormDataFormValues>();

  return (
    <MuiAuthenticationSelect
      {...props}
      label=''
      projectId={values.project?.id}
      currentValue={values.authentication}
      onFormChange={(e) => setFieldValue('authentication', e.target.value)}
    />
  );
};

export default AuthSelect;