import { ExpirationValue } from './types';

export const NO_EXPIRATION: ExpirationValue = {
  label: 'No expiration',
  value: null,
};

export const CUSTOM_EXPIRATION: ExpirationValue = {
  label: 'Custom',
  value: -1,
};

export const EXPIRATION_SELECT_OPTIONS: ExpirationValue[] = [
  {
    label: '7 days',
    value: 7,
  },
  {
    label: '30 days',
    value: 30,
  },
  {
    label: '60 days',
    value: 60,
  },
  {
    label: '90 days',
    value: 90,
  },
  NO_EXPIRATION,
  CUSTOM_EXPIRATION,
];