import {
  Issue,
  IssuesApiIssuesUpdateRequest,
  IssuesOccurrencesResponse,
  IssueUpdate,
  ResolutionEnum,
} from '@api-client';
import { UID } from '@shared/duck/types';
import { ItemSelectionApi } from '@shared/hooks/use-item-selection';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { IssueResolutionEnum } from 'models/Enums';
import { enqueueSnackbar } from 'notistack'; import React from 'react';

interface FetchNextPagesProps {
  setLoading: (state: boolean) => void,
  issuesListHasNextPage: boolean | undefined,
  fetchIssuesListNextPage: () => void;
  isIssuesLoading: boolean;
}

export const fetchNextPages = ({
  setLoading,
  issuesListHasNextPage,
  fetchIssuesListNextPage,
  isIssuesLoading,
}: FetchNextPagesProps) => {
  React.useEffect(() => {
    if (issuesListHasNextPage || issuesListHasNextPage === undefined) {
      if (!isIssuesLoading) {
        fetchIssuesListNextPage();
      }
    } else {
      setLoading(false);
    }
  }, [issuesListHasNextPage, fetchIssuesListNextPage, isIssuesLoading]);
};

export const setParameterIssuesOpen = (
  parameterName: string,
  open: boolean,
  expandedIssues: Set<string>,
  setExpandedIssues: React.Dispatch<React.SetStateAction<Set<string>>>,
) => {
  const newExpandedIssues = new Set(expandedIssues);
  if (open) {
    newExpandedIssues.add(parameterName);
  } else {
    newExpandedIssues.delete(parameterName);
  }
  setExpandedIssues(newExpandedIssues);
};

export const onResolutionChange = (
  updateIssue: UseMutateAsyncFunction<AxiosResponse<IssueUpdate, any>, unknown, IssuesApiIssuesUpdateRequest, unknown>,
  issue: Issue,
  value: IssueResolutionEnum,
) => {
  updateIssue(
    {
      id: issue.id || '',
      issueUpdateRequest: { resolution: value as ResolutionEnum },
    },
  ).catch(() =>
    enqueueSnackbar('Failed to change issue resolution', { variant: 'error' })
  );
};

export const concatSelectedItems = (items1: Map<UID, Issue>, items2?: Map<UID, Issue>) => {
  const map1 = new Map(items1);
  const map2 = new Map(items2);
  return new Map([...map1].concat([...map2]));
};

export const onToggleAll = (
  localSelectionApi: ItemSelectionApi<Issue>,
  parameterSelectionApi?: ItemSelectionApi<Issue>,
) => {
  localSelectionApi?.onToggleAll();

  if (localSelectionApi.isAllSelected) {
    if (localSelectionApi.selectedItems.size === parameterSelectionApi?.selectedItems.size) {
      parameterSelectionApi?.onUnToggleAll();
    } else {
      const map = new Map(parameterSelectionApi?.selectedItems);
      localSelectionApi.selectedItems.forEach((item, key) => {
        map.delete(key);
      });

      parameterSelectionApi?.reinitialize(map);
    }
  }
};

export const convertResponseToIssues = (
  groupedIssues: _.Dictionary<IssuesOccurrencesResponse[]>,
): Issue[] => {
  const values: Issue[] = [];

  Object.keys(groupedIssues).map(key => {
    const issues = groupedIssues[key] as Issue[];

    values.push(...issues);
  });

  return values;
};

export const getAllGroupedIssues = (
  setAllGroupedIssues: React.Dispatch<React.SetStateAction<Issue[]>>,
  groupedIssues?: _.Dictionary<IssuesOccurrencesResponse[]>,
) => {
  if (groupedIssues) {
    const values: Issue[] = convertResponseToIssues(groupedIssues);

    if (values.length) {
      setAllGroupedIssues(values);
    }
  }
};

export const sortSelectedItemsByResolution = (
  localSelectionApi: ItemSelectionApi<Issue>,
  parameterSelectionApi?: ItemSelectionApi<Issue>,
  resolution?: ResolutionEnum[],
) => {
  if (resolution) {
    parameterSelectionApi?.selectedItems.forEach((item) => {
      if (item.resolution !== resolution[0]) {
        parameterSelectionApi.selectedItems.delete(item.id);
      }
    });

    localSelectionApi.selectedItems.forEach((item) => {
      if (item.resolution !== resolution[0]) {
        localSelectionApi.selectedItems.delete(item.id);
      }
    });
  }
};