import { globalQueries } from '@shared/duck';
import Papa from 'papaparse';
import * as Yup from 'yup';

export const parseCsv = async (file: File): Promise<{
  data: string[],
  headers: string[],
} | undefined> => {
  const text = await file.text();
  return new Promise((resolve) => {
    Papa.parse(text, {
      header: true,
      worker: true,
      skipEmptyLines: true,
      complete: (results) => {
        let data = (results.data || []);
        const headersCount = results.meta.fields?.length || 0;
        const headers: string[] = results.meta.fields || [];
        const pathHeader = headers.indexOf('path');

        if (!headersCount || pathHeader < 0) {
          resolve(undefined);
          return;
        }
        data = data.map(row => (row as Record<string, string>)['path']);

        const resData = data as string[];
        resolve({ data: resData, headers: headers });
      },
    });
  });
};

export const createPathTargetUrlHandler = () => {
  const { createPathTargetUrl } = globalQueries.useCreatePathTargetUrl();

  const createNewUserPaths = async (data: string[], webTargetId: string) => {
    if (!data.length) {
      return;
    }
    const urlsToAdd = data.map(url => ({ path: url }));

    try {
      await createPathTargetUrl({
        id: webTargetId,
        urlsAdditionalPathsItemRequest: urlsToAdd,
      });
    }
    catch (error: any) {
      const response = error?.response;
      if (response?.status === 400) {
        const errorDetail = response.data?.errors?.[0]?.detail;
        throw new Error(errorDetail || 'Error');
      }
    }
  };

  return {
    createNewUserPaths
  };
};

export const importCsvValidationSchema = Yup.object().shape({
  file: Yup.mixed().nullable().required('Please select a file')
    .when('data', {
      is: undefined,
      then: Yup.mixed().test('no column found', 'There is no "path" column in the file', () => false)
    })
    .when('data', {
      is: (data?: string[] | null) => data && data?.length === 0,
      then: Yup.mixed().test('no data found', 'There is no data for "path" column in the file', () => false)
    }),
  data: Yup.array().of(Yup.string()).nullable().required('Upload a file with "path" column'),
});