import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { AuthenticationsTable, ScansTable } from './components';
import { MuiContentLoader } from '@shared/components';

interface RightPanelProps {
  targetId: string;
  isTargetLoading: boolean;
  closeModal: (url?: string) => void;
}

const SCAN = 'Scans';
const AUTHENTICATION = 'Authentications';

const RightPanel: React.FC<RightPanelProps> = ({
  targetId,
  isTargetLoading,
  closeModal,
}: RightPanelProps) => {
  const [tab, setTab] = React.useState(SCAN);
  const handleChange = (_: any, newTab: string) => {
    newTab && setTab(newTab);
  };

  return (
    <MuiContentLoader
      isLoading={isTargetLoading}
    >
      <Stack width='100%'>
        <ToggleButtonGroup
          color='primary'
          value={tab}
          exclusive
          onChange={handleChange}
          aria-label='toggle-tab'
        >
          <ToggleButton value={SCAN}>{SCAN}</ToggleButton>
          <ToggleButton value={AUTHENTICATION}>{AUTHENTICATION}</ToggleButton>
        </ToggleButtonGroup>
        {(
          () => {
            switch (tab) {
              case SCAN:
                return <ScansTable targetId={targetId} closeModal={closeModal} />;
              case AUTHENTICATION:
                return <AuthenticationsTable targetId={targetId} closeModal={closeModal} />;
            }
          }
        )()}
      </Stack>
    </MuiContentLoader>
  );
};

export default RightPanel;