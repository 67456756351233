import { IconProps } from './BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from './icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='10'
    height='12'
    viewBox='0 0 10 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <path
      d='M1.25 6.75L5 10.5L8.75 6.75M1.25 1.5L5 5.25L8.75 1.5'
      stroke='#0059C8'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

function LoadMoreIcon(props: IconProps) {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default LoadMoreIcon;
