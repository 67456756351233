import { Checkbox, Typography } from '@mui/material';
import React from 'react';

interface CustomOptionProps<T> {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: T;
  selected: boolean;
  getOptionLabel: ((option: T) => string);
  getOptionKey: ((option: T) => string | number);
  optionStartIcon?: (option: T) => React.ReactNode;
}

export const CustomOption = <T,>({
  props,
  option,
  selected,
  getOptionLabel,
  getOptionKey,
  optionStartIcon,
}: CustomOptionProps<T>) => {
  return (
    <li {...props} style={{ padding: '0.25rem 0.5rem' }} key={getOptionKey?.(option)}>
      <Checkbox
        size='small'
        sx={{ p: '0.25rem', mr: '0.5rem' }}
        checked={selected}
      />
      {optionStartIcon?.(option)}
      <Typography
        noWrap
        lineHeight='1.25rem'
        fontSize='0.875rem'
        style={{ display: 'block' }}
      >
        {getOptionLabel?.(option)}
      </Typography>
    </li>
  );
};