import { Application } from '@api-client';
import { MuiFilterAutocomplete } from '@shared/components';
import { globalQueries } from '@shared/duck';
import { useDebounce } from '@shared/hooks';
import { AutocompleteChangeReason } from '@mui/material';
import { UIOptionItemNew } from 'pages/workspace-router/scans/scans-page-wrapper';
import React from 'react';

interface MuiApplicationFilterProps<T> {
  // projectIds: string[];
  selectedApplications?: UIOptionItemNew<T>[];
  setSelectedApplications?: (newValues: UIOptionItemNew<T>[], options?: UIOptionItemNew<T>[], reason?: AutocompleteChangeReason) => void;
}

export const MuiApplicationFilter = <T,>({
  // projectIds,
  selectedApplications,
  setSelectedApplications,
}: MuiApplicationFilterProps<T>) => {
  const { debouncedValue, onChange: onInputChange, value = '' } = useDebounce<string>();

  const {
    applications = [],
    fetchNextApplicationsPage,
    applicationsHasNextPage,
    isApplicationsListLoading,
    isApplicationsFetching,
  } = globalQueries.useGetApplicationsList({
    // project: projectIds,
    order: ['name'],
    pageSize: 25,
    filter: debouncedValue,
  });

  const createNewValues = (app: Application): UIOptionItemNew<T> => (
    { key: app.id, label: app.name, value: app.id as T }
  );

  const { getApplication } = globalQueries.useGetApplicationMutation();
  const [isApplicationsChecked, setIsApplicationsChecked] = React.useState(false);
  React.useEffect(() => {
    const checkProjects = async () => {
      if (selectedApplications?.length && !isApplicationsChecked) {
        setIsApplicationsChecked(true);
        const optionsToDelete: UIOptionItemNew<T>[] = [];
        const newOptions: UIOptionItemNew<T>[] = [];
        await Promise.all(
          selectedApplications.map(async app => {
            try {
              const { data } = await getApplication({
                id: app.value as string,
              });
              if (data.id === app.value && data.name === app.label) {
                newOptions.push(app);
              }
              else {
                optionsToDelete.push(app);
              }
            }
            catch {
              optionsToDelete.push(app);
            }
          })
        );
        if (optionsToDelete.length) {
          setSelectedApplications?.(newOptions, optionsToDelete, 'removeOption');
        }
      }
    };

    checkProjects();
  }, [selectedApplications]);

  return (
    <MuiFilterAutocomplete<UIOptionItemNew<T>>
      filterName='Application'
      options={applications.map(app => createNewValues(app))}
      loading={isApplicationsListLoading || isApplicationsFetching}
      inputValue={value}
      onInputChange={onInputChange}
      selectedValues={selectedApplications}
      setSelectedValues={setSelectedApplications}
      getOptionKey={option => option.key || option.value as string}
      getOptionLabel={option => option.label}
      fetchNextPage={() => applicationsHasNextPage && fetchNextApplicationsPage()}
    />
  );
};