import { IconButton } from '@mui/material';
import { X } from '@phosphor-icons/react';
import React from 'react';

interface UICloseBtnProps {
  onClose: () => void;
}

const UICloseBtn: React.FC<UICloseBtnProps> = ({
  onClose,
}) => {
  return (
    <IconButton
      aria-label='close'
      onClick={onClose}
    >
      <X size='1.125rem' />
    </IconButton>
  );
};

export default UICloseBtn;