import { AutocompleteChangeReason } from '@mui/material';
import { MuiFilterAutocomplete } from '@shared/components';
import { UIOptionItemNew } from 'pages/workspace-router/scans/scans-page-wrapper';
import React from 'react';

interface MuiAccessibilityFilterProps {
  selectedAccessibility?: UIOptionItemNew<number>[];
  setSelectedAccessibility?: (newValues: UIOptionItemNew<number>[], options?: UIOptionItemNew<number>[], reason?: AutocompleteChangeReason) => void;
}

const ACCESSIBILITY_OPTIONS: UIOptionItemNew<number>[] = [
  { label: 'Public', value: 1, key: '1' },
  { label: 'Private', value: 0, key: '0' },
];

export const MuiAccessibilityFilter: React.FC<MuiAccessibilityFilterProps> = ({
  selectedAccessibility,
  setSelectedAccessibility,
}: MuiAccessibilityFilterProps) => {

  const [isAccessibilityChecked, setIsAccessibilityChecked] = React.useState(false);
  React.useEffect(() => {
    if (selectedAccessibility?.length && !isAccessibilityChecked) {
      setIsAccessibilityChecked(true);
      const optionsToDelete: UIOptionItemNew<number>[] = [];
      const newOptions: UIOptionItemNew<number>[] = [];
      selectedAccessibility.forEach(access => {
        if (ACCESSIBILITY_OPTIONS.some(option => option.label === access.label && option.value.toString() === access.value.toString())) {
          newOptions.push(access);
        }
        else {
          optionsToDelete.push(access);
        }
      });

      if (optionsToDelete.length) {
        setSelectedAccessibility?.(newOptions, optionsToDelete, 'removeOption');
      }
    }
  }, [selectedAccessibility]);

  return (
    <MuiFilterAutocomplete<UIOptionItemNew<number>>
      filterName='Accessibility'
      options={ACCESSIBILITY_OPTIONS}
      selectedValues={selectedAccessibility}
      setSelectedValues={setSelectedAccessibility}
      getOptionKey={(option: UIOptionItemNew<number>) => option.label}
      getOptionLabel={(option: UIOptionItemNew<number>) => option.label}
      disableFilterOptions={false}
    />
  );
};