import { ComponentEnum } from './enums';

export const getAssociatedEnumValue = (component?: string | null) => {
  if (component?.toLowerCase().includes('zap')) return ComponentEnum.Zap;
  else if (component?.toLowerCase().includes('dalfox')) return ComponentEnum.Dalfox;
  else if (component?.toLowerCase().includes('playwright')) return ComponentEnum.Playwright;
  else if (component?.toLowerCase().includes('katana')) return ComponentEnum.Katana;
  else if (component?.toLowerCase().includes('gospider')) return ComponentEnum.Gospider;
  else if (component?.toLowerCase().includes('nuclei')) return ComponentEnum.Nuclei;
  else return ComponentEnum.Unknown;
};
