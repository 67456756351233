import { allRoutesEnum, globalModels, globalQueries, globalTypes, globalUtils } from '@shared/duck';
import React from 'react';
import { useApp } from '@shared/hooks';
import { useNavigate } from 'react-router-dom';

interface ProjectsTableApiResult {
  projects: globalModels.ProjectViewModel[],
  totalItemsCount: number,
  isProjectsListLoading: boolean,
  projectsHasNextPage: boolean,
  fetchNextProjectsPage: () => void,
  onProjectsRowClick?: (item: globalModels.ProjectViewModel) => void,
}


export const useProjectsTableApi = (
  {
    page,
    filter,
    pageSize,
  }: {
    page: number;
    filter?: string;
    pageSize?: number;
  }): ProjectsTableApiResult => {
  const app = useApp();
  const navigate = useNavigate();

  const {
    projects: dtoProjects,
    totalItemsCount,
    isProjectsListLoading,
    isProjectsListFetching,
    projectsHasNextPage = false,
    fetchNextProjectsPage,
  } = globalQueries.useGetProjectsList({
    page: page + 1,
    filter: filter,
    pageSize: pageSize,
  });

  const projects = React.useMemo(
    () =>
      dtoProjects?.map(
        (item) => globalUtils.mapProjectsDtoToViewModel(item, app.users.me?.dto)) || [],
    [dtoProjects],
  );

  const onProjectsRowClick = (item: globalModels.ProjectViewModel) => {
    navigate(allRoutesEnum.ProjectDetails.replace(':projectId', item.id));
  };

  return {
    projects,
    totalItemsCount,
    isProjectsListLoading: isProjectsListLoading || isProjectsListFetching,
    projectsHasNextPage,
    fetchNextProjectsPage,
    onProjectsRowClick,
  };
};

export const useGetItemListHandler = () => {
  const { createOrEditProjectHandler: onEditProjectClick } = globalUtils.useCreateOrEditProjectHandler();
  const { deleteProjectHandler: onDeleteProjectClick } = globalUtils.useDeleteProjectHandler();
  const { shareProjectHandler: onShareProjectHandler } = globalUtils.useShareProjectModalHandler();
  const { onLeaveProjectHandler: onLeaveProjectClick } = globalUtils.useLeaveProjectHandler();
  const app = useApp();

  const getItemList = (item: globalModels.ProjectViewModel) => {
    const canRename = item.isOwned && !item.isFree;
    const canShare = item.isOwned && !item.isFree;
    const canDelete = item.isOwned && !item.isMyDefault && !item.isFree;
    const canLeave = !item.isOwned;

    const renameItem = {
      title: 'Rename',
      itemAction: () => onEditProjectClick({ project: item, initialName: item.name }),
    };
    const shareItem = {
      title: 'Share',
      itemAction: () => onShareProjectHandler(item),
    };
    const deleteItem = {
      title: 'Delete',
      itemAction: () => onDeleteProjectClick(item),
      isRedText: true,
    };
    const leaveItem = {
      title: 'Leave',
      itemAction: () => onLeaveProjectClick({
        projectName: item.name,
        projectId: item.id,
        userId: app.users.me?.dto.id || '',
      }),
      isRedText: true,
    };

    const itemList: globalTypes.UIMenuItemsList[] = [];
    canRename && itemList.push(renameItem);
    canShare && itemList.push(shareItem);
    canDelete && itemList.push(deleteItem);
    canLeave && itemList.push(leaveItem);

    return {
      itemList: itemList,
    };
  };

  return { getItemList };
};