import { Credentials, CredentialsType as CredentialsTypeEnum } from '@api-client';

export const getCodeFragmentByType = (credentials?: Credentials): string => {
  if (credentials?.type === CredentialsTypeEnum.Header) {
    const headers = credentials.headers?.map(item => `${item.name}: ${item.value}`)?.join('\n') || '';
    return `Headers:\n\n${headers}`;
  }

  if (credentials?.type === CredentialsTypeEnum.Cookie) {
    const cookies = credentials.cookie?.map(item => `${item.name}: ${item.value}`)?.join('\n') || '';
    return `Cookies:\n\n${cookies}`;
  }

  if (credentials?.type === CredentialsTypeEnum.UserPass) {
    return `Username: ${credentials.username}\nPassword: ${credentials.password}`;
  }

  if (credentials?.type === CredentialsTypeEnum.Script) {
    return credentials.script_content || '';
  }

  return '';
};