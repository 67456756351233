import { CheckTypeEnum } from '@api-client';
import { scanDetailsChecksConst } from '../../../../../duck';

export const getCheckTypeName = (checkTypeEnum?: CheckTypeEnum, link?: string | null): string => {
  switch (checkTypeEnum) {
    case CheckTypeEnum.OwaspActive:
      return scanDetailsChecksConst.OWASP_ACTIVE_NAME;
    case CheckTypeEnum.OwaspPassive:
      return scanDetailsChecksConst.OWASP_PASSIVE_NAME;
    case CheckTypeEnum.XssScanner:
      return scanDetailsChecksConst.OWASP_CHECKS_NAME;
    case CheckTypeEnum.NucleiBuiltIn: {
      const words = link?.substring('https://github.com/'.length).split('/');
      if (words?.[0] === 'nvsecurity') return words?.[1];
      else if (words?.[0] === 'projectdiscovery') return words?.[0] + '/' + words?.[1];
      return scanDetailsChecksConst.DEFAULT_NUCLEI_NAME;
    }
    case CheckTypeEnum.NucleiCustom:
      return scanDetailsChecksConst.CUSTOM_NUCLEI_NAME;
    default:
      return '';
  }
};

export const isOwaspType = (checkTypeEnum?: CheckTypeEnum): boolean => {
  switch (checkTypeEnum) {
    case CheckTypeEnum.OwaspActive:
    case CheckTypeEnum.OwaspPassive:
      return true;
    default:
      return false;
  }
};