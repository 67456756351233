import { Table, TableCellProps as OldTableCellProps } from '@contentful/f36-components';
import styled from '@emotion/styled';
import { TableCell, TableCellProps } from '@mui/material';
import { withSafeProps } from 'views/utils/common';
import { css } from '@emotion/react';

interface StyledTableCellProps extends OldTableCellProps {
  withoutPadding?: boolean;
  small?: boolean;
  minWidth?: string;
}

interface StyledMuiTableCellProps extends TableCellProps {
  withoutPadding?: boolean;
  small?: boolean;
  minWidth?: string;
}

export const StyledTableCell = styled(withSafeProps<StyledTableCellProps>(
  Table.Cell,
  ['withoutPadding', 'small', 'minWidth'],
))`
  padding: ${({ withoutPadding }) => withoutPadding && '0 !important'};
  vertical-align: middle !important;
  ${({ small }) =>
    small &&
    css`
            width: 1.875rem;
            overflow: hidden;
          `}
  min-width: ${({ minWidth }) => minWidth};
`;

export const StyledMuiTableCell = styled(withSafeProps<StyledMuiTableCellProps>(
  TableCell,
  ['withoutPadding', 'small', 'minWidth'],
))`
  padding: ${({ withoutPadding }) => withoutPadding && '0'};
  ${({ small }) =>
    small &&
    css`
            width: 1.875rem;
            overflow: hidden;
          `}
  min-width: ${({ minWidth }) => minWidth};
`;
