import { GridItem } from '@contentful/f36-components';
import { Typography } from '@mui/material';
import { InstallCLITabs } from '@shared/components';
import React from 'react';

const DownloadPage: React.FC = () => {
  return (
    <GridItem area='span 1 / span 2'>
      <Typography variant='default' fontWeight='500' mb='1rem'>
        {'To start, install the NightVision CLI by running this command from your CLI'}
      </Typography>
      <InstallCLITabs />
    </GridItem>
  );
};

export default DownloadPage;