import React from 'react';
import F36Select from 'shared/components/selectors/f36-select';
import { globalQueries, globalTypes } from '@shared/duck';
import { UISelectProps } from '@shared/duck/types';

interface ProjectSelectProps extends Omit<UISelectProps<string | number>, 'options' | 'isLoading' | 'onScrollEnd' | 'initialValue'> {
  currentValue?: string | number;
  withoutBorders?: boolean;
  search?: string;
  enableProjectSelect?: boolean;
}

const UIProjectSelect: React.FC<ProjectSelectProps> = ({
  currentValue,
  withoutBorders,
  search = '',
  enableProjectSelect: showProject = true,
  ...props
}) => {
  const {
    projects = [],
    fetchNextProjectsPage,
    projectsHasNextPage,
    isProjectsListLoading,
    isProjectsListFetching,
  } = globalQueries.useGetProjectsList({ filter: search, order: ['name'] });

  const projectsOptions: globalTypes.UIOptionItem[] = React.useMemo(
    () =>
      projects.map(item => (
        {
          label: item.name,
          value: item.id,
        }
      )),

    [projects],
  );

  const currentProjectOptionFromList = projectsOptions.find(v => v.value === currentValue) ?? null;
  const isCurrentProjectLoadingEnabled = Boolean(currentValue) && !currentProjectOptionFromList;

  const { project: currentProject, isProjectLoading: isCurrentProjectLoading } = globalQueries.useGetProject({
    id: currentValue?.toString() || '',
  }, { enabled: isCurrentProjectLoadingEnabled });

  const currentProjectOption: globalTypes.UIOptionItem | null = React.useMemo(
    () => (
      currentProject ? { label: currentProject.name, value: currentProject.id }
        : currentValue && projectsOptions.length ? currentProjectOptionFromList
          : null
    ),
    [currentProject, currentProjectOptionFromList],
  );

  const onScrollToEnd = React.useCallback(() => {
    projectsHasNextPage && fetchNextProjectsPage();
  }, [projectsHasNextPage, fetchNextProjectsPage]);

  return (
    <F36Select
      options={projectsOptions}
      onScrollToEnd={onScrollToEnd}
      isLoading={isProjectsListLoading || isProjectsListFetching || (
        isCurrentProjectLoading && isCurrentProjectLoadingEnabled
      )}
      initialValue={currentProjectOption}
      bordered={withoutBorders}
      disabled={!showProject}
      {...props}
    />
  );
};

export default UIProjectSelect;
