import { CheckTypeEnum, SeverityEnum } from '@api-client';
import { capitalize } from 'lodash';
import { globalEnums, globalTypes } from 'shared/duck';

export const SeverityOptions: globalTypes.UIOptionItem<SeverityEnum>[] = [
  { label: capitalize(SeverityEnum.Critical), value: SeverityEnum.Critical },
  { label: capitalize(SeverityEnum.High), value: SeverityEnum.High },
  { label: capitalize(SeverityEnum.Medium), value: SeverityEnum.Medium },
  { label: capitalize(SeverityEnum.Low), value: SeverityEnum.Low },
  { label: capitalize(SeverityEnum.Info), value: SeverityEnum.Info },
  { label: capitalize(SeverityEnum.Unknown), value: SeverityEnum.Unknown },
  { label: capitalize(SeverityEnum.Unspecified), value: SeverityEnum.Unspecified },
];

export const CheckStatusOptions: globalTypes.UIOptionItem<globalEnums.CheckStatusEnum>[] = Array.from(globalEnums.CheckStatusName)
  .map(([value, label]) => (
    {
      label,
      value,
    }
  ));


export const OWASP_CHECKS_NAME = 'OWASP Checks';
export const OWASP_ACTIVE_NAME = 'ZAP Active Scan';
export const OWASP_PASSIVE_NAME = 'ZAP Passive Scan';
export const DEFAULT_NUCLEI_NAME = 'Built-in Nuclei Rules';
export const CUSTOM_NUCLEI_NAME = 'Custom Nuclei Rules';

export const CheckTypeOptions: globalTypes.UIOptionItem<CheckTypeEnum>[] = [
  {
    label: OWASP_ACTIVE_NAME,
    value: CheckTypeEnum.OwaspActive,
  },
  {
    label: OWASP_PASSIVE_NAME,
    value: CheckTypeEnum.OwaspPassive,
  },
  {
    label: DEFAULT_NUCLEI_NAME,
    value: CheckTypeEnum.NucleiBuiltIn,
  },
  {
    label: CUSTOM_NUCLEI_NAME,
    value: CheckTypeEnum.NucleiCustom,
  },
];

