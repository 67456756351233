import { Table } from '@contentful/f36-components';
import { tableTypes } from '@shared/components/table/duck';
import React from 'react';
import { StyledBodyRow } from '../body-rows/basic/basic-body-row.styled';

interface StyledLastRowProps extends tableTypes.LastRowProps {
  colLength: number;
  bordered?: boolean;
}

const StyledLastRow: React.FC<StyledLastRowProps> = ({
  content,
  colSpan,
  bordered = false,
}: StyledLastRowProps) => {
  return (
    <StyledBodyRow bordered={bordered}>
      <Table.Cell colSpan={colSpan}>{content}</Table.Cell>
    </StyledBodyRow>
  );
};

export default StyledLastRow;