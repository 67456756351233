import { CredentialsType } from 'api-client';
import React from 'react';
import { MuiContentLoader, NoContentPlaceholder } from '@shared/components';
import { AttentionGray } from '@assets';
import {
  CookiesBasedCredentials,
  HeaderBasedCredentials, InfoPopupContent,
  PlaywrightScriptCredentials,
} from './components';
import { globalQueries } from '@shared/duck';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext } from '@shared/duck/contexts';
import { Stack } from '@mui/material';

const infoPopupProps = {
  title: 'What’s Login Status',
  content: <InfoPopupContent />,
};

interface ScanDetailsLoginStatusProps {
  scanId: string;
}

const ScanDetailsLoginStatus: React.FC<ScanDetailsLoginStatusProps> = ({
  scanId,
}) => {
  const { setError } = React.useContext(ErrorContext);
  const { scan, isScanLoading } = globalQueries.useGetScan({
    id: scanId
  }, {
    onError: err => errorResponseHandler(err, 'scan', setError),
  });

  return (
    <MuiContentLoader isLoading={isScanLoading}>
      {scan?.credentials ? (
        <Stack overflow='auto'>
          {scan?.credentials?.type === CredentialsType.Script && <PlaywrightScriptCredentials scan={scan} />}
          {scan?.credentials?.type === CredentialsType.Header && <HeaderBasedCredentials scan={scan} />}
          {scan?.credentials?.type === CredentialsType.Cookie && <CookiesBasedCredentials scan={scan} />}
        </Stack>
      ) : (
        <NoContentPlaceholder
          infoPopupProps={infoPopupProps}
          height='18.75rem'
          message='No Authentication credential was added'
          iconSrc={AttentionGray}
          padded
        />
      )}
    </MuiContentLoader>
  );
};

export default ScanDetailsLoginStatus;
