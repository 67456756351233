import { styled } from '@mui/material/styles';
import MuiAutocomplete from '@shared/components/selectors/autocomplete';

export const AutocompleteStyled = styled(MuiAutocomplete)`
    .MuiAutocomplete-popupIndicator {
        display: none;
    }
    .MuiAutocomplete-tag {
      margin: 0;
    }
    .MuiAutocomplete-inputRoot {
      gap: 3px;

      .MuiAutocomplete-input {
        width: auto;
      }
    }
`;