import React from 'react';
import { CloseIcon } from 'views/components/v1/Icons';
import { StyledBadge } from './badge.styled';
import { globalEnums } from '@shared/duck';
import { Stack, Typography } from '@mui/material';

interface BadgeLabelsProps {
  isInvalid?: boolean;
  onDeleteLabel: () => void;
  label: string;
}

const BadgeLabel: React.FC<BadgeLabelsProps> = ({
  onDeleteLabel,
  label,
}: BadgeLabelsProps) => {
  return (
    <StyledBadge>
      <Stack direction='row' alignItems='center' gap='0.25rem'>
        <Typography className='text'>{label}</Typography>
        <CloseIcon onClick={onDeleteLabel} variant={globalEnums.EIconVariants.SECONDARY} size='tiny' style={{ flexShrink: 0 }} />
      </Stack>
    </StyledBadge>
  );
};

export default BadgeLabel;