import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';
import { ScanChecksTable } from './components';
import {
  StyledFlex,
} from './scan-details-checks.styled';
import { scanDetailsChecksConst } from './duck';
import { CheckTypeEnum, SeverityEnum } from 'api-client';
import { useDebounce } from '@shared/hooks';
import { globalEnums, globalQueries, globalTypes } from 'shared/duck';
import { SCAN_REFETCH_INTERVAL } from '../duck';
import { MuiSearchInput, MuiAutocomplete } from '@shared/components';
import { ScanStatusEnum } from 'models/Enums';
import { ErrorContext } from '@shared/duck/contexts';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { TextField, Typography, Stack, Box } from '@mui/material';

interface ScanDetailsChecksProps {
  scanId: string;
}

const ScanDetailsChecks: React.FC<ScanDetailsChecksProps> = ({
  scanId,
}) => {
  const { setError } = React.useContext(ErrorContext);
  const { scan, isScanLoading } = globalQueries.useGetScan({ id: scanId }, {
    refetchInterval: !(
      scanId
    ) ? SCAN_REFETCH_INTERVAL : undefined,
    onError: err => errorResponseHandler(err, 'scan', setError),
  });

  const { debouncedValue: debouncedSearch, onChange: onSearchChange } = useDebounce<string>();
  const searchString = debouncedSearch?.trim().toLowerCase();
  const [severity, setSeverity] = React.useState<globalTypes.UIOptionItem<SeverityEnum> | null>(null);
  const [checkType, setCheckType] = React.useState<globalTypes.UIOptionItem<CheckTypeEnum> | null>(null);
  const [checkStatus, setCheckStatus] = React.useState<globalTypes.UIOptionItem<globalEnums.CheckStatusEnum> | null>(
    null);

  const checkTypeFilter: CheckTypeEnum[] | undefined = React.useMemo(() => {
    return checkType ? [checkType.value] : undefined;
  }, [checkType]);

  const checkStatusFilter: globalEnums.CheckStatusEnum[] | undefined = React.useMemo(() => {
    return checkStatus != undefined ? [checkStatus.value] : undefined;
  }, [checkStatus]);

  const severityFilter: SeverityEnum[] | undefined = React.useMemo(() => {
    return severity != undefined ? [severity.value] : undefined;
  }, [severity]);

  const [scanEnded, setScanEnded] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (scan) {
      const scanInProgress = scan.status == ScanStatusEnum.IN_PROGRESS;
      setScanEnded(!scanInProgress);
    }
  }, [scan, scan?.status]);

  const {
    checks = [],
    isChecksLoading,
    checksHasNextPage = false,
    fetchNextChecksPage,
  } = globalQueries.useGetScanChecksList(
    {
      id: scanId,
      name: searchString,
      severity: severityFilter,
      type: checkTypeFilter,
      status: checkStatusFilter,
    },
    {
      enabled: !isScanLoading,
      refetchInterval: !scan?.ended_at ? SCAN_REFETCH_INTERVAL : undefined,

      onError: err => errorResponseHandler(err, 'scan', setError),
    },
  );

  return (
    <Stack alignItems='stretch' p={0.75} overflow='hidden'>
      <StyledFlex overflow={'hidden'}>
        <Stack
          p={0.75}
          justifyContent='space-between'
          flexDirection='row'
          borderBottom={`1px solid ${muiPalette.grey?.[300]}`}
        >
          <Stack alignItems='flex-start' sx={{ width: '100%' }}>
            <Typography
              variant={'subtitle2'}
            >
              {'Search'}
            </Typography>
            <MuiSearchInput
              width='20rem'
              placeholder='Search for checks'
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </Stack>
          <Stack gap={0.5} flexDirection='row' justifyContent='flex-end' width='100%'>
            <Box sx={{ maxWidth: '20rem', width: '100%' }}>
              <MuiAutocomplete
                id='check-status'
                options={scanDetailsChecksConst.CheckStatusOptions}
                label={'Status'}
                autoComplete
                autoHighlight
                value={checkStatus}
                onChange={(_, newValue: globalTypes.UIOptionItem<globalEnums.CheckStatusEnum>) => setCheckStatus(
                  newValue || null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='All statuses'
                  />
                )}
                getOptionKey={(option: globalTypes.UIOptionItem<globalEnums.CheckStatusEnum>) => option.label}
                getOptionLabel={(option: globalTypes.UIOptionItem<globalEnums.CheckStatusEnum>) => option.label}
              />
            </Box>
            <Box sx={{ maxWidth: '20rem', width: '100%' }}>
              <MuiAutocomplete
                id='check-type'
                options={scanDetailsChecksConst.CheckTypeOptions}
                label={'Type'}
                autoComplete
                autoHighlight
                value={checkType}
                onChange={(_, newValue: globalTypes.UIOptionItem<CheckTypeEnum>) => setCheckType(newValue || null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='All types'
                  />
                )}
                getOptionKey={(option: globalTypes.UIOptionItem<CheckTypeEnum>) => option.label}
                getOptionLabel={(option: globalTypes.UIOptionItem<CheckTypeEnum>) => option.label}
              />
            </Box>
            <Box sx={{ maxWidth: '20rem', width: '100%' }}>
              <MuiAutocomplete
                id='check-severity'
                options={scanDetailsChecksConst.SeverityOptions}
                label={'Severity'}
                autoComplete
                autoHighlight
                value={severity}
                onChange={(_, newValue: globalTypes.UIOptionItem<SeverityEnum>) => setSeverity(newValue || null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='All severities'
                  />
                )}
                getOptionKey={(option: globalTypes.UIOptionItem<SeverityEnum>) => option.label}
                getOptionLabel={(option: globalTypes.UIOptionItem<SeverityEnum>) => option.label}
              />
            </Box>
          </Stack>
        </Stack>
        <ScanChecksTable
          scan={scan}
          checks={checks}
          checksHasNextPage={checksHasNextPage}
          fetchNextChecksPage={fetchNextChecksPage}
          isLoading={isScanLoading || !scanEnded || isChecksLoading}
        />
      </StyledFlex>
    </Stack>
  );
};

export default ScanDetailsChecks;
