import { Spinner } from '@contentful/f36-components';
import React from 'react';
import { useFormikContext } from 'formik';
import { isUndefined } from 'lodash';
import { globalEnums, globalQueries, globalTypes } from '@shared/duck';
import { EndIcon, UITextInput, UnableToRunScanModal } from '@shared/components';
import baseTokens from '@contentful/f36-tokens';
import { ApiTargetsContentValues } from '../../duck';
import { useDebounce } from '@shared/hooks';
import { CanceledError } from 'axios';


const UrlCellRender: React.FC<globalTypes.UITextInputProps> = ({
  isInvalid: invalid,
  ...innerTextInputProps
}: globalTypes.UITextInputProps) => {
  const { values, setFieldValue } = useFormikContext<ApiTargetsContentValues>();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [enabled, setEnabled] = React.useState<boolean>(true);
  const [isInvalid, setInvalid] = React.useState<boolean>(invalid ?? false);
  const { debouncedValue = values.newTarget.location, onChange: onDebounceChange } = useDebounce<string>('', 1000);
  const [abortController, setAbortController] = React.useState(new AbortController());

  React.useEffect(() => {
    testUrl();
    setEnabled(true);
  }, []);

  React.useEffect(() => {
    if (enabled && !invalid)
      isUndefined(values.newTarget.isAccessible) ?
        setInvalid(false) :
        setInvalid(!values.newTarget.isAccessible);
  }, [values.newTarget.isAccessTested]);

  const { checkPublicUrl } = globalQueries.useCheckPublicUrl(abortController.signal);
  const testUrl = async () => {
    const onTestNewWebTarget = async (
      url: string,
    ) => {
      try {
        const { data } = await checkPublicUrl(url);

        setFieldValue('newTarget.location', data.requested_url);
        setFieldValue('newTarget.isAccessible', data.is_accessible);
        setFieldValue('newTarget.isAccessTested', true);
      }
      catch (e) {
        if (e instanceof CanceledError) {
          // canceled prevoius request
        }
      }
    };

    setEnabled(true);
    if (values.newTarget.location && !invalid) {
      setInvalid(false);
      onTestNewWebTarget(values.newTarget.location);
    }
  };

  React.useEffect(() => {
    if (!values.newTarget.isAccessTested && !enabled)
      testUrl();
  }, [debouncedValue]);

  return (
    <>
      <UITextInput
        {...innerTextInputProps}
        isInvalid={isInvalid || invalid}
        noBorders={globalEnums.EmotionBoolean.False}
        onChange={(e) => {
          abortController.abort();
          setAbortController(new AbortController());
          setEnabled(false);
          setFieldValue('newTarget.location', e.target.value);
          setFieldValue('newTarget.isAccessTested', false);
          setFieldValue('newTarget.isAccessible', undefined);
          innerTextInputProps.onChange?.(e);
          onDebounceChange(e.target.value);
        }}
        endIcon={enabled
          ? (innerTextInputProps.endIcon ?
            innerTextInputProps.endIcon
            : (values.newTarget.isAccessTested
              ? (
                <EndIcon
                  isAccessible={values.newTarget.isAccessible}
                  location={values.newTarget.location}
                  id={values.newTarget.name || values.newTarget.location}
                  setOpenModal={() => setOpenModal(true)}
                  targetExists={false}
                  smallIcon
                />
              ) : (values.newTarget.location && !invalid ? <Spinner size='small' /> : <></>)))
          : <></>}
        style={{ color: baseTokens.gray500 }}
      />
      <UnableToRunScanModal
        type={globalEnums.ETargetTypes.OPEN_API}
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        targetName={values.newTarget?.name || values.newTarget?.location}
        projectId={values.newTarget?.project}
      />
    </>
  );
};

export default UrlCellRender;

