import { Box, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const ExplainWithAIBox = styled(Box)`
  position: relative;
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 0.375rem;
  border: 1px solid ${muiPalette.grey?.[200]};
  background: ${muiPalette.grey?.[100]};
  padding: 0.75rem;
  
  pre {
    white-space: pre-wrap;
  }

  .copy {
    position: absolute;
    right: 0;
    top: 0;

    button {
      height: 1.5rem;
    }
  }
`;

export { ExplainWithAIBox };
