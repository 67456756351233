import { tableTypes, MuiTable } from '@shared/components';
import { allRoutesEnum } from '@shared/duck';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  applicDetailsTableConsts,
  applicDetailsTableHooks,
  applicDetailsTableTypes,
} from './duck';
import { TargetGray } from '@assets';

interface ApplicationDetailsTableProps {
  applicationId: string;
  filter?: string;
}

const ApplicationDetailsTable: React.FC<ApplicationDetailsTableProps> = ({
  applicationId,
  filter,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [filter, applicationId]);

  const {
    targets,
    totalItemsCount,
    isTargetsListLoading,
    targetsHasNextPage,
    fetchNextTargetsPage,
  } = applicDetailsTableHooks.useApplicDetailsTableApi({
    id: applicationId,
    filter: filter,
    page: currentPage,
    pageSize: rowsPerPage,
  });

  const tableConfig: tableTypes.MuiTableProps<applicDetailsTableTypes.ApplicDetailsTargetViewModel> = {
    data: targets,
    columns: applicDetailsTableConsts.targetsColumns(
      allRoutesEnum.ApplicationScanDetails
        .replace(':applicationId', applicationId)),
    loading: isTargetsListLoading,
    isLoadMoreAvailable: targetsHasNextPage,
    onLoadMoreClick: fetchNextTargetsPage,
    onRowClick: (item) => (
      navigate(allRoutesEnum.ApplicationScanDetails
        .replace(':applicationId', applicationId)
        .replace(':targetId', item.id))
    ),
    noContentMessage: 'No Targets found',
    noContentIcon: TargetGray,
    pagination: {
      count: totalItemsCount,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: (e, newPage) => handleChangePage(newPage),
      onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
    },
  };

  return (
    <MuiTable
      {...tableConfig}
    />
  );
};

export default ApplicationDetailsTable;