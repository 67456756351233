import { Checkbox, TableCell } from '@mui/material';
import { tableTypes, UIMenu } from '@shared/components';
import { MuiTableCell } from '@shared/components/table/components';
import { StyledMuiBodyRow } from './basic-body-row.styled';
import { globalEnums, globalTypes } from '@shared/duck';
import { memoize } from 'lodash';
import React from 'react';

interface BodyRowProps<DataItem> extends Partial<tableTypes.BasicMuiTableProps<DataItem>> {
  rowKey: globalTypes.UID,
  dataItem: DataItem,
  columns: tableTypes.ColumnProps<DataItem>[];
  index: number,
}

/** Note: bordered - add separators between cells */
const BasicBodyRow = <DataItem extends globalTypes.UIDItem>({
  rowKey,
  dataItem,
  columns,
  index,
  itemActions,
  onRowClick,
  bordered = false,
  itemSelection,
  selectedRowId,
  disableRow,
}: BodyRowProps<DataItem>) => {
  const itemSelectionApi = itemSelection?.itemSelectionApi;
  const selectable = Boolean(itemSelectionApi);
  const isSelected = !!itemSelectionApi?.selectedItems.has(dataItem.id) || selectedRowId === dataItem.id;
  const withClickableRows = React.useMemo(() => {
    return !!onRowClick;
  }, [!!onRowClick]);
  const isRowDisabled = React.useMemo(() => {
    return disableRow ? disableRow(dataItem) : false;
  }, [dataItem]);

  const getOnRowClick = React.useCallback(
    memoize((dataItem: DataItem) => () => {
      dataItem && !isRowDisabled && onRowClick && onRowClick(dataItem);
    }), [onRowClick],
  );

  const getOnToggleCheckRow = React.useCallback(
    memoize((item: DataItem) => () => itemSelectionApi?.onToggleItem(item)),
    [itemSelectionApi],
  );

  const isComplexMenu = React.useMemo(() => {
    if (itemActions?.length) {
      return itemActions(dataItem).footerItems?.length ||
             itemActions(dataItem).headerText?.length ||
             itemActions(dataItem).headerSubText?.length ||
             itemActions(dataItem).sx;
    } else {
      return false;
    }
  }, [itemActions]);

  return (
    <StyledMuiBodyRow
      key={rowKey}
      hover={withClickableRows && !isRowDisabled}
      selected={isSelected}
      bordered={bordered}
      disabled={isRowDisabled}
      onClick={getOnRowClick(dataItem)}
    >
      {selectable ? (
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            checked={itemSelectionApi?.selectedItems.has(dataItem.id)}
            inputProps={{
              'aria-label': `select_item_${dataItem.id}`,
            }}
            disabled={itemSelection?.disabled || isRowDisabled}
            onChange={getOnToggleCheckRow(dataItem)}
            onClick={(e) => e.stopPropagation()}
          />
        </TableCell>
      ) : null}

      {columns.map((col, colIndex) => (
        <MuiTableCell
          key={`${index}${colIndex}`}
          dataItem={dataItem}
          column={col}
          index={index}
        />
      ))}

      {itemActions?.length && (
        <TableCell
          onClick={(e: any) => e.stopPropagation()}
          sx={{ textAlign: 'right' }}
        >
          <UIMenu
            type={isComplexMenu
              ? globalEnums.EMenuType.COMPLEX
              : globalEnums.EMenuType.BASIC}
            itemList={itemActions(dataItem).itemList}
            headerText={itemActions(dataItem).headerText}
            headerSubText={itemActions(dataItem).headerSubText}
            footerItems={itemActions(dataItem).footerItems}
            size='small'
            iconVariant={globalEnums.EMuiIconButtonVariants.DEFAULT}
            menuIconC={itemActions(dataItem).menuIconC}
            sx={itemActions(dataItem).sx}
          />
        </TableCell>
      )}
    </StyledMuiBodyRow>
  );
};

export default BasicBodyRow;