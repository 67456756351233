import React from 'react';
import { Dialog } from '@mui/material';
import { ImportCsvFileFormikValues, createPathTargetUrlHandler } from './components/import-csv-modal/duck';
import { FormikHelpers } from 'formik';
import { globalQueries } from '@shared/duck';
import { ImportCsvModal, UrlPathModal, UrlPathProps } from './components';
import { enqueueSnackbar } from 'notistack';
export interface ModalWrapperProps {
  openImportModal: boolean;
  onClose: () => void;
  webTargetId: string;
  onImportCsvClick: () => void;
  onAddPathClick: () => void;
  pathToEdit: UrlPathProps | undefined;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  openImportModal,
  onClose,
  webTargetId,
  onImportCsvClick,
  onAddPathClick,
  pathToEdit,
}: ModalWrapperProps) => {
  // used to control current modal state
  const [isOpen, setIsOpen] = React.useState(true);

  const onCloseModal = () => setIsOpen(false);
  const { createNewUserPaths } = createPathTargetUrlHandler();

  const onImportCsvSubmit = async (values: ImportCsvFileFormikValues) => {
    try {
      await createNewUserPaths(values.data || [], webTargetId);
      enqueueSnackbar(`${values.data?.length} url(s) have been added successfully`, { variant: 'success' });
    }
    catch (e) {
      enqueueSnackbar(e as string, { variant: 'error' });
      return;
    }
    onClose();
  };

  const { createPathTargetUrl } = globalQueries.useCreatePathTargetUrl();
  const { updatePathTargetUrl } = globalQueries.useUpdatePathTargetUrl();

  const onAddPathSubmit = async (values: UrlPathProps, formikHelpers: FormikHelpers<UrlPathProps>) => {
    const newPath = values.path || '';
    const inEdit = !!values.id;
    try {
      if (inEdit) {
        await updatePathTargetUrl({
          id: webTargetId,
          pathId: values.id!,
          urlsAdditionalPathsItemUpdateRequest: {
            path: values.path,
          },
        });
      }
      else {
        await createPathTargetUrl({
          id: webTargetId,
          urlsAdditionalPathsItemRequest: [{
            path: newPath.trim(),
          }],
        });
      }
      onClose();
    }
    catch (error: any) {
      const response = error?.response;
      if (response?.status === 400) {
        const errorDetail = response.data?.errors?.[0]?.detail;
        formikHelpers.setFieldError('path', errorDetail || 'Error');
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      // used internal onCloseModal method to don't lose closing animation
      onClose={onCloseModal}
      maxWidth={openImportModal ? 'md' : 'xs'}
      sx={{
        '& .MuiPaper-root': {
          maxHeight: '40rem'
        }
      }}
      fullWidth
      TransitionProps={{
        // used external onClose method to close modal after closing animation is ended
        onExited: () => {
          onClose();
        }
      }}
    >
      {!openImportModal ?
        <UrlPathModal
          onClose={onCloseModal}
          onSubmit={onAddPathSubmit}
          onImportCsvClick={onImportCsvClick}
          pathToEdit={pathToEdit}
        />
        :
        <ImportCsvModal
          onClose={onCloseModal}
          onSubmit={onImportCsvSubmit}
          onAddPathClick={onAddPathClick}
        />
      }
    </Dialog>
  );
};

export default ModalWrapper;
