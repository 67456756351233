import React from 'react';
import { FormikControlMui, MuiTextInput, UIDialogTitle } from '@shared/components';
import { Button, CircularProgress, DialogActions, DialogContent, Stack } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export interface UrlPathModalProps {
  onClose: () => void;
  onSubmit: (values: UrlPathProps, helpers: FormikHelpers<UrlPathProps>) => void;
  onImportCsvClick?: () => void;
  pathToEdit: UrlPathProps | undefined;
}

export const UrlPathValidationSchema = Yup.object().shape({
  path: Yup.string().required('New Path is required').trim()
});

export interface UrlPathProps {
  id?: number;
  path?: string;
}

const UrlPathModal: React.FC<UrlPathModalProps> = ({
  onClose,
  onSubmit,
  onImportCsvClick,
  pathToEdit,
}: UrlPathModalProps) => {

  return (
    <Formik<UrlPathProps>
      initialValues={{ id: pathToEdit?.id, path: pathToEdit?.path || '' }}
      onSubmit={onSubmit}
      validateOnMount
      validateOnChange
      validationSchema={UrlPathValidationSchema}
    >
      {({ isSubmitting, isValid, values }) => {
        const isInitialPathValue = values.path === pathToEdit?.path;
        return (
          <Form>
            <UIDialogTitle
              title={pathToEdit ? 'Update Path' : 'Add new Path'}
              onCloseBtnAction={onClose}
            />
            <DialogContent dividers sx={{ borderBottom: 'none', pb: '2.25rem' }}>
              <FormikControlMui name='path' fullWidth label='New Path' absoluteError>
                <MuiTextInput placeholder='updatecore/javaspringvulny' />
              </FormikControlMui>
            </DialogContent>
            <DialogActions sx={{ pt: '0', justifyContent: 'space-between' }}>
              <Button
                variant='text'
                onClick={onImportCsvClick}
              >
                {'Import from CSV'}
              </Button>
              <Stack direction='row'>
                <Button onClick={onClose} size='small'>
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  size='small'
                  type='submit'
                  endIcon={isSubmitting && (
                    <CircularProgress color='inherit' size={20} />
                  )}
                  disabled={!isValid || isSubmitting || isInitialPathValue}
                >
                  {pathToEdit ? 'Update' : 'Add'}
                </Button>
              </Stack>
            </DialogActions>
          </Form>
        );
      }
      }
    </Formik>
  );
};

export default UrlPathModal;
