import {
  InfiniteQueryObserverOptions,
  QueryObserverOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import * as apiClient from 'api-client';
import { useApp } from '@shared/hooks';
import { ApplicationsQueryKeys } from './queriesKeys';
import { AxiosError, AxiosResponse } from 'axios';
import { getNextPageParam, useFlatCountFromResponse, useFlatResults } from './utils';


const useCreateApplication = () => {
  const { applicationsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.ApplicationsApiApplicationsCreateRequest) => applicationsApi.applicationsCreate(
      request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [ApplicationsQueryKeys.applicationsList] }),
  });

  return {
    createApplication: mutateAsync,
  };
};

const useGetDefaultApplication = () => {
  const { applications = [], isApplicationsListLoading } = useGetApplicationsList({
    isDefault: true,
    ownApplications: true,
  }, {
    // Do not invalidate Default Application query when List Applications query is invalidated.
    queryKey: [ApplicationsQueryKeys.applicationsDefault],
  });
  const [defaultApplication = null] = applications;
  return {
    defaultApplication,
    isDefaultApplicationLoading: isApplicationsListLoading,
  };
};

const useGetApplicationsList = (
  request: apiClient.ApplicationsApiApplicationsListRequest,
  config?: Partial<InfiniteQueryObserverOptions<AxiosResponse<apiClient.PaginatedApplicationList>, AxiosError>>,
) => {
  const { applicationsApi } = useApp();
  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    fetchStatus,
  } = useInfiniteQuery({
    queryKey: [ApplicationsQueryKeys.applicationsList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => applicationsApi.applicationsList({ ...request, page: pageParam }),
    getNextPageParam: getNextPageParam,
    ...config,
  });

  const applications = useFlatResults(data);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    applications,
    totalItemsCount,
    // Problem  -> Disabled query has isLoading=true property
    // Solution -> https://github.com/TanStack/query/issues/3584#issuecomment-1256986636
    isApplicationsListLoading: (isLoading && fetchStatus !== 'idle') || isFetching,
    isApplicationsFetching: isFetching,
    applicationsHasNextPage: hasNextPage,
    fetchNextApplicationsPage: fetchNextPage,
  };
};

const useGetApplicationTargetScansList = (
  request: apiClient.ApplicationsApiApplicationsTargetScansListRequest,
  config?: Partial<InfiniteQueryObserverOptions<AxiosResponse<apiClient.PaginatedScanList>, AxiosError>>,
) => {
  const { applicationsApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [ApplicationsQueryKeys.applicationByIdTargetScansList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => applicationsApi.applicationsTargetScansList({ ...request, page: pageParam }),
    getNextPageParam: getNextPageParam,
    ...config,
  });

  const applicationTargetScans = useFlatResults(data);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    applicationTargetScans: applicationTargetScans,
    totalItemsCount,
    isApplicationTargetScansListLoading: isLoading,
    applicationTargetScansHasNextPage: hasNextPage,
    fetchNextApplicationTargetScansPage: fetchNextPage,
  };
};

const useDeleteApplication = () => {
  const queryClient = useQueryClient();
  const { applicationsApi } = useApp();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.ApplicationsApiApplicationsDestroyRequest) => applicationsApi.applicationsDestroy(
      request),
    onSuccess: () => queryClient.resetQueries({ queryKey: [ApplicationsQueryKeys.applicationsList] }),
  });

  return {
    deleteApplication: mutateAsync,
  };
};

const useGetApplication = (
  request: apiClient.ApplicationsApiApplicationsRetrieveRequest,
  config?: Partial<QueryObserverOptions<AxiosResponse<apiClient.Application>, AxiosError>>,
) => {
  const { applicationsApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [ApplicationsQueryKeys.applicationById, ...Object.values(request)],
    queryFn: () => applicationsApi.applicationsRetrieve(request),
    ...config,
  });

  return {
    application: data?.data,
    isApplicationLoading: isLoading,
  };
};

const useGetApplicationMutation = () => {
  const { applicationsApi } = useApp();
  const {
    mutateAsync
  } = useMutation({
    mutationFn: (request: apiClient.ApplicationsApiApplicationsRetrieveRequest) => applicationsApi.applicationsRetrieve(request),
  });

  return {
    getApplication: mutateAsync,
  };
};

const useUpdateApplication = () => {
  const { applicationsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.ApplicationsApiApplicationsUpdateRequest) => applicationsApi.applicationsUpdate(
      request),
    onSuccess: () => [
      queryClient.resetQueries({ queryKey: [ApplicationsQueryKeys.applicationsList] }),
      queryClient.resetQueries({ queryKey: [ApplicationsQueryKeys.applicationById] }),
      queryClient.resetQueries({ queryKey: [ApplicationsQueryKeys.applicationScanByTargetId] }),
    ]
  });

  return {
    updateApplication: mutateAsync,
  };
};

const useGetScanByApplication = (
  request: apiClient.ApplicationsApiApplicationsTargetScansRetrieveRequest,
  config?: Partial<QueryObserverOptions<AxiosResponse<apiClient.Scan>, AxiosError>>,
) => {
  const { applicationsApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [ApplicationsQueryKeys.applicationScanByTargetId, ...Object.values(request)],
    queryFn: () => applicationsApi.applicationsTargetScansRetrieve(request),
    ...config,
  });

  return {
    scan: data?.data,
    isScanLoading: isLoading,
  };
};

export {
  useCreateApplication,
  useGetDefaultApplication,
  useGetApplicationsList,
  useGetApplicationTargetScansList,
  useDeleteApplication,
  useGetApplication,
  useGetApplicationMutation,
  useUpdateApplication,
  useGetScanByApplication,
};
