import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';
import { StyledToolCard } from './tool-card.styled';
import { UIAsset } from '@shared/components';

interface ToolCardProps extends BoxProps {
  title: string;
  description: string;
  asset: string;
  action: () => void;
  isActive: boolean;
}

const ToolCard: React.FC<ToolCardProps> = ({
  title,
  description,
  asset,
  action,
  isActive,
}: ToolCardProps) => {
  return (
    <StyledToolCard onClick={action} isActive={isActive}>
      <Box className='icon'>
        <UIAsset src={asset} />
      </Box>
      <Stack
        sx={{ width: '100%' }}
      >
        <Typography
          variant='subtitle2'
          fontSize='1rem'
          fontWeight='600'
        >
          {title}
        </Typography>

        <Typography
          variant='subtitle2'
          sx={{
            fontSize: '0.875rem',
            fontWeight: '400',
            lineHeight: '1.25rem',
            color: `${muiPalette.grey?.[500]}`,
          }}
        >
          {description}
        </Typography>
      </Stack>
    </StyledToolCard>
  );
};

export default ToolCard;