import { ChipProps, Interpolation, Theme, ChipClasses } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const ChipDefaultProps: Partial<ChipProps> | undefined =
{
  deleteIcon: <X size={14} style={{ flexShrink: 0 }} />,
  size: 'small',
};

export const ChipStyles: Partial<OverridesStyleRules<keyof ChipClasses, 'MuiChip', Omit<Theme, 'components'>>> | undefined =
{
  root: {
    borderRadius: '0.25rem',
  },
  label: {
    padding: '0 0.5rem',
    fontWeight: '600',
  },
  deleteIcon: {
    color: 'inherit',

    ':hover': {
      color: 'inherit',
    },
  },
};

export const ChipVariants:
  {
    props: Partial<ChipProps>;
    style: Interpolation<{
      theme: Omit<Theme, 'components'>;
    }>;
  }[] | undefined = [
    {
      props: { color: 'default' },
      style: {
        color: '#5A657C',
        background: '#E7EBEE',
      }
    },
    {
      props: { color: 'error' },
      style: {
        color: '#BD002A',
        background: '#FFE0E0',
      },
    },
    {
      props: { color: 'success' },
      style: {
        color: '#006D23',
        background: '#CDF3C6',
      },
    },
    {
      props: { color: 'secondary' },
      style: {
        color: '#6C3ECF',
        background: '#EDE3FF',
      },
    },
    {
      props: { color: 'primary' },
      style: {
        color: '#0059C8',
        background: '#CEECFF',
      },
    },
    {
      props: { size: 'small' },
      style: {
        maxHeight: '1.25rem',
      }
    }
  ];