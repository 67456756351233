import React from 'react';
import { AssetProps } from '@contentful/f36-components';
import { StyledAsset } from './asset.styled';

interface UIAssetProps extends AssetProps {
  id?: string;
}

const UIAsset: React.FC<UIAssetProps> = (props) => {
  return <StyledAsset {...props} />;
};

export default UIAsset;