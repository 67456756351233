import { Stack, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const StyledDivContainer = styled(Stack)`
    flex-direction: row;
    font-weight: 500;
    color: ${muiPalette.grey?.[800]};
    gap: 0.5rem;
    align-items: center;
`;

export { StyledDivContainer };
