import { InitiatedWithEnum, ScanRequest } from '@api-client';
import { RescanPopup } from '@shared/components';
import { allRoutesEnum, globalQueries } from '@shared/duck';
import { useGlobalModalContext } from '@shared/duck/contexts';
import { ScanTarget } from '@shared/duck/types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface RescanPopupWrapperProps {
  scanId: string;
}

export const RescanPopupWrapper: React.FC<RescanPopupWrapperProps> = ({ scanId }: RescanPopupWrapperProps) => {
  const { createScans } = globalQueries.useCreateScans();
  const { hideModal } = useGlobalModalContext();
  const navigate = useNavigate();

  const onRescanClick = async (values: ScanTarget) => {
    const scanRequest = Array.from(values.credentials.size > 0 ? values.credentials.keys() : [null])
      .map<ScanRequest>(credentials_id => (
        {
          credentials_id: credentials_id as string | null,
          target_id: values.id,
          application_id: values.application,
          initiated_with: InitiatedWithEnum.Web,
        }
      ));
    const res = await createScans({ scanRequest });
    navigate(allRoutesEnum.ScanDetails.replace(':scanId', res.data[0].id));
  };

  return <>
    <RescanPopup
      scanId={scanId}
      onRescanClick={onRescanClick}
      closeModal={() => hideModal()}
    />
  </>;
};
