import React from 'react';
import { globalTypes } from '@shared/duck';
import { StyledTextarea, TextareaContainer } from './textarea.styled';

const UITextarea = React.forwardRef<HTMLTextAreaElement, globalTypes.UITextareaProps>((props, ref) => {
  const { endIcon, ...otherProps } = props;

  return endIcon ? (
    <TextareaContainer tabIndex={0} endIcon={endIcon}>
      <StyledTextarea {...otherProps} ref={ref} />
      <div className='icon-container'>{endIcon}</div>
    </TextareaContainer>
  ) : (
    <StyledTextarea {...otherProps} ref={ref} />
  );
});

UITextarea.displayName = 'Textarea';

export default UITextarea;
