/*
 Mobile devices – 320px — 480px.
 iPads, Tablets – 481px — 768px.
 Small screens, laptops – 769px — 1024px.
 Desktops, large screens – 1025px — 1200px.
 Extra large screens, TV – 1201px, and more.

 Name(s)  Resolution in pixels
 High Definition (HD)  1280 x 720
 Full HD, FHD  1920 x 1080
 2K, Quad HD, QHD  2560 x 1440
 4K, Ultra HD  3840 x 2160
 */

const BreakpointX = {
  Mobile: {
    min: 320,
    max: 480,
  },
  Tablet: {
    min: 481,
    max: 768,
  },
  Laptop: {
    min: 769,
    max: 1024,
  },
  Desktop: {
    min: 1025,
    max: 1280,
  },
  DesktopM: {
    min: 1281,
    max: 1366,
  },
  DesktopL: {
    min: 1367,
    max: 1536,
  },
  DesktopHD: {
    min: 1537,
    max: 1920,
  },
  DesktopFHD: {
    min: 1921,
    max: 2560,
  },
  Desktop2K: {
    min: 2561,
    max: 3840,
  },
  Desktop4K: {
    min: 3841,
    max: 9999,
  },
};

export { BreakpointX };
