import React from 'react';
import { UIDialogTitle, MuiContentLoader } from '@shared/components';
import { AdvancedTab, GeneralTab } from './components';
import { Formik, FormikHelpers } from 'formik';
import { Tabs } from '@contentful/f36-components';
import { CreateApplicationValuesSchema, emptyCriteria } from './duck';
import { globalEnums, globalQueries } from '@shared/duck';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext } from '@shared/duck/contexts';
import { Dialog, DialogActions, DialogContent, Stack, Button, CircularProgress } from '@mui/material';
import { ApplicationConfigurationExcludedIssuesInner, Project } from '@api-client';

export interface CreateApplicationModalProps {
  onClose: () => void;
  onSubmit: (values: ApplicationFormValues, formikHelpers: FormikHelpers<ApplicationFormValues>) => Promise<boolean>;
  applicationId?: string;
  enableProjectSelect?: boolean;
  initialProject?: string;
  initialTab?: 'general' | 'advanced';
}

export interface ApplicationFormValues {
  name: string;
  project?: Project;
  labels: string[];
  url_patterns: string[];
  xpath_patterns: string[];
  criteria: ApplicationConfigurationExcludedIssuesInner[];
}

const ApplicationModal: React.FC<CreateApplicationModalProps> = ({
  onSubmit,
  onClose,
  applicationId = undefined,
  enableProjectSelect = true,
  initialProject,
  initialTab = 'general',
}) => {
  const isGetApplicationEnabled = !!applicationId;
  const { setError } = React.useContext(ErrorContext);

  const { application = null, isApplicationLoading = false } = globalQueries.useGetApplication(
    {
      id: applicationId || ''
    }, {
      enabled: isGetApplicationEnabled,
      onError: err => errorResponseHandler(err, 'application', setError),
    });

  const { project = null, isProjectLoading = false } = globalQueries.useGetProject(
    { id: application?.project || initialProject || '' }, { enabled: (isGetApplicationEnabled && !!application?.project) || !!initialProject });
  const { defaultProject = null, isDefaultProjectLoading } = globalQueries.useGetDefaultProject();

  const createApplicationInitialValues: ApplicationFormValues = React.useMemo(() => {
    return {
      name: application?.name || '',
      project: project || (defaultProject ? defaultProject : undefined),
      labels: application?.labels || [],
      url_patterns: application?.configuration?.excluded_url_patterns || [],
      xpath_patterns: application?.configuration?.excluded_x_paths || [],
      criteria: application?.configuration?.excluded_issues || [emptyCriteria],
    };
  }, [defaultProject, isDefaultProjectLoading, application, isApplicationLoading, project, isProjectLoading]);

  const [isShown, setIsShown] = React.useState(true);
  const handleClose = () => setIsShown(false);

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={isShown}
      onClose={handleClose}
      onTransitionExited={onClose}
      sx={{
        '& .MuiPaper-root': {
          height: '100%',
          borderRadius: '0.375rem',
          maxWidth: 'unset',
          width: '100%',
        }
      }}
    >
      <>
        <UIDialogTitle
          title={isGetApplicationEnabled ? 'Update Application' : 'Create new Application'}
          onCloseBtnAction={handleClose}
        />
        <MuiContentLoader
          isLoading={isDefaultProjectLoading || (isGetApplicationEnabled && (isApplicationLoading || isProjectLoading)) || (!!initialProject && isProjectLoading)}
        >
          <Formik<ApplicationFormValues>
            initialValues={createApplicationInitialValues}
            validateOnChange
            onSubmit={async (values, helpers) => {
              const shouldClose = await onSubmit(values, helpers);
              shouldClose && handleClose();
            }}
            validationSchema={CreateApplicationValuesSchema}
            enableReinitialize
          >
            {({ isSubmitting, isValid, handleSubmit, values }) => {
              return (
                <>
                  <DialogContent dividers sx={{ borderBottom: 'none' }}>
                    <Tabs defaultTab={initialTab}>
                      <Tabs.List variant='horizontal-divider'>
                        <Tabs.Tab panelId='general'>General</Tabs.Tab>
                        <Tabs.Tab panelId='advanced'>Advanced Configuration</Tabs.Tab>
                      </Tabs.List>
                      <Tabs.Panel id='general'>
                        <GeneralTab initialProject={values.project} enableProjectSelect={enableProjectSelect} />
                      </Tabs.Panel>
                      <Tabs.Panel id='advanced'>
                        <AdvancedTab />
                      </Tabs.Panel>
                    </Tabs>
                  </DialogContent>
                  <DialogActions sx={{ gap: '0.5rem' }}>
                    <Stack direction='row' justifyContent='flex-end' gap='1.5rem' pb='0.5rem' pr='0.5rem'>
                      <Button
                        size='small'
                        sx={{
                          border: 'none !important',
                        }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        size='small'
                        color={globalEnums.EMuiButtonColors.PRIMARY}
                        variant={globalEnums.EMuiButtonVariants.CONTAINED}
                        endIcon={isSubmitting && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        disabled={!isValid || isSubmitting}
                        onClick={() => handleSubmit()}
                      >
                        {isGetApplicationEnabled ? 'Update' : 'Create Application'}
                      </Button>
                    </Stack>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </MuiContentLoader>
      </>
    </Dialog>
  );
};

export default ApplicationModal;