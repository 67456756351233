import React from 'react';
import { Modal } from '@shared/components';
import { JsonModal, TextModal } from 'pages/workspace-router/scans/scan-details/components/scan-details-checks/components/table/components/column-type';
import { Check } from '@api-client';
import { globalQueries } from '@shared/duck';

interface LogsModalProps {
  scanId: string;
  checkName: string;
  setCheckName: React.Dispatch<React.SetStateAction<string>>;
}

export const LogsModal: React.FC<LogsModalProps> = ({
  scanId,
  checkName,
  setCheckName,
}) => {

  const [isLogsModalOpen, setLogsModalOpen] = React.useState(false);
  const [logsItem, setLogsItem] = React.useState<Check | null>(null);
  const [useTextModal, setUseTextModal] = React.useState(false);

  const onModalClose = () => {
    setCheckName('');
    setLogsItem(null);
  };

  const { getChecksList, isChecksListLoading } = globalQueries.useGetScanChecksListMutation();
  const getCheck = async (id: string, name: string) => {
    const res = await getChecksList({ id: id, name: name });
    setLogsItem(res.data.results[0]);
  };

  React.useEffect(() => {
    setUseTextModal(false);

    if (checkName) {
      setLogsModalOpen(true);
      getCheck(scanId, checkName);
    }
    else {
      setLogsModalOpen(false);
    }
  }, [checkName]);

  return (
    <Modal
      onClose={onModalClose}
      style={{
        height: '34.75rem',
      }}
      isShown={isLogsModalOpen}
      size='fullWidth'
    >
      {() => (
        !useTextModal ? (
          <JsonModal
            item={logsItem ?? undefined}
            setModal={onModalClose}
            onUseTextModal={() => setUseTextModal(true)}
            isCheckLoading={isChecksListLoading || logsItem === null}
            title={`ZAP Scanner's Logs: ${checkName}`}
          />
        ) : (
          <TextModal
            item={logsItem ?? undefined}
            setModal={onModalClose}
            isCheckLoading={isChecksListLoading || logsItem === null}
            title={`ZAP Scanner's Logs: ${checkName}`}
          />
        )
      )}
    </Modal>
  );
};