import {
  Application,
  CliToken,
  Credentials,
  IssueKindStatistics,
  Project,
  Scan,
  ScanRef,
  Target,
  User,
} from '@api-client';
import { globalConstants, globalEnums, globalModels, globalQueries, globalUtils } from '@shared/duck';
import dayjs from 'dayjs';
import { ScanStatusEnum } from 'models/Enums';
import { TechnologyName } from '@shared/duck/utils/technologies';
import { FULL_MONTH_DATE_TIME } from '../constants';

export const getTargetTypeFromDto = (dto: Scan | ScanRef): TechnologyName => {
  return dto.target_type.toLowerCase() as TechnologyName;
};

export const mapScansDtoToViewModelNew = (dto: Scan): globalModels.ScansViewModelNew => {
  const targetType: TechnologyName = getTargetTypeFromDto(dto);
  const location = dto.location ? dto.location.replace(/http(s?):\/\//, '') : '';
  const isInProgress = dto.status === ScanStatusEnum.IN_PROGRESS;
  const isDisrupted = Boolean(
    dto.status && [ScanStatusEnum.ABORTED, ScanStatusEnum.FAILED, ScanStatusEnum.TIMED_OUT].includes(dto.status)
  );

  return {
    id: dto.id,
    target: dto.target,
    targetName: dto.target_name || location || '',
    application: dto.application,
    applicationId: dto.application_id,
    applicationName: dto.application_name,
    internetAccessible: dto.target.internet_accessible,
    targetType,
    issuesStat: [
      { severityIndex: 1, count: dto.issues_statistics?.Critical || 0, severity: 'Critical' },
      { severityIndex: 2, count: dto.issues_statistics?.High || 0, severity: 'High' },
      { severityIndex: 3, count: dto.issues_statistics?.Medium || 0, severity: 'Medium' },
      { severityIndex: 4, count: dto.issues_statistics?.Low || 0, severity: 'Low' },
      { severityIndex: 5, count: dto.issues_statistics?.Informational || 0, severity: 'Info' },
    ],
    uniqueIssuesStat: [
      { severityIndex: 1, count: dto.unique_issues_statistics?.Critical || 0, severity: 'Critical' },
      { severityIndex: 2, count: dto.unique_issues_statistics?.High || 0, severity: 'High' },
      { severityIndex: 3, count: dto.unique_issues_statistics?.Medium || 0, severity: 'Medium' },
      { severityIndex: 4, count: dto.unique_issues_statistics?.Low || 0, severity: 'Low' },
      { severityIndex: 5, count: dto.unique_issues_statistics?.Informational || 0, severity: 'Info' },
    ],
    vulnPathsStat: [
      { severityIndex: 1, count: dto.vulnerable_paths_statistics?.Critical || 0, severity: 'Critical' },
      { severityIndex: 2, count: dto.vulnerable_paths_statistics?.High || 0, severity: 'High' },
      { severityIndex: 3, count: dto.vulnerable_paths_statistics?.Medium || 0, severity: 'Medium' },
      { severityIndex: 4, count: dto.vulnerable_paths_statistics?.Low || 0, severity: 'Low' },
      { severityIndex: 5, count: dto.vulnerable_paths_statistics?.Informational || 0, severity: 'Info' },
    ],
    createdBy: dto.created_by,
    createdAt: dto.created_at,
    credentials: dto.credentials,
    startedAt: dto.started_at || dto.created_at || '',
    endedAt: dto.ended_at || '',
    deletedAt: dto.deleted_at || '',
    status: dto.status,
    isInProgress: isInProgress,
    isDisrupted: isDisrupted,
    completedIn: dto.completed_in,
    projectName: dto.project_name || '',
    percentage: dto.percent_complete,
    archived: dto.archived || false,
  };
};

export const mapScansDtoToViewModel = (dto: Scan, statsProp: globalQueries.StatisticsProp = 'issues_statistics'): globalModels.ScanViewModel => {
  const isInProgress = dto.status === ScanStatusEnum.IN_PROGRESS;
  const isDisrupted = Boolean(
    dto.status && [ScanStatusEnum.ABORTED, ScanStatusEnum.FAILED, ScanStatusEnum.TIMED_OUT].includes(dto.status),
  );

  const targetType: TechnologyName = getTargetTypeFromDto(dto);
  const location = dto.location ? dto.location.replace(/http(s?):\/\//, '') : '';

  return {
    id: dto.id,
    location: location || dto.target_name || '',
    targetType,
    issues: [
      { severityIndex: 1, count: dto[statsProp]?.Critical || 0, severity: 'Critical' },
      { severityIndex: 2, count: dto[statsProp]?.High || 0, severity: 'High' },
      { severityIndex: 3, count: dto[statsProp]?.Medium || 0, severity: 'Medium' },
      { severityIndex: 4, count: dto[statsProp]?.Low || 0, severity: 'Low' },
      { severityIndex: 5, count: dto[statsProp]?.Informational || 0, severity: 'Info' },
    ],
    frameworks: dto.target?.development_frameworks_ids || [],
    languages: dto.target?.programming_languages_ids || [],
    initiatedBy: dto.created_by,
    isInProgress,
    isDisrupted,
    credentials: dto.credentials,
    startedAt: dto.started_at || dto.created_at || '',
    endedAt: dto.ended_at || '',
    createdAt: dto.created_at || '',
    percentage: `${dto.percent_complete}%`,
  };
};

export const mapAuthenticationsDtoToViewModel = (dto: Credentials): globalModels.AuthenticationsViewModel => {
  return {
    id: dto.id,
    name: dto.name,
    description: dto.description || '',
    lastSynced: dayjs(dto.last_updated_at).format(globalConstants.FULL_MONTH_DATE_TIME),
    authenticationType: globalUtils.getAuthenticationTypeName(dto.type) || '',
    projectName: dto.project_name,
    noOfTargets: dto.targets_count,
  };
};

export const mapScanDtoToTargetViewModel = (dto: Scan): globalModels.TargetViewModel => {
  return {
    id: dto.target_id,
    name: dto.target_name || 'N/A',
    hasApiDetails: dto.target_type?.toString() === globalEnums.ETargetType.OpenAPI,
    description: '', /** TODO: remove after moving to a new table */
    location: dto.location || '',
    type: dto.target_type?.toString() as globalEnums.ETargetType,
    projectName: dto.project_name || 'N/A',
    projectId: dto.target.project,
    createdAt: dayjs(dto.created_at).format(FULL_MONTH_DATE_TIME),
    updatedAt: dayjs(dto.last_updated_at).format(FULL_MONTH_DATE_TIME),
  };
};

export const mapTargetsDtoToViewModel = (dto: Target): globalModels.TargetViewModel => {
  return {
    id: dto.id,
    name: dto.name || 'N/A',
    hasApiDetails: dto.type?.toString() === globalEnums.ETargetType.OpenAPI,
    description: '', /** TODO: remove after moving to a new table */
    location: dto.location || '',
    type: dto.type?.toString() as globalEnums.ETargetType,
    createdBy: dto.own_user,
    projectName: dto.project_name || 'N/A',
    projectId: dto.project || '',
    createdAt: dayjs(dto.created_at).format(FULL_MONTH_DATE_TIME),
    updatedAt: dayjs(dto.last_updated_at).format(FULL_MONTH_DATE_TIME),
    credentials: dto.credentials,
    internetAccessible: dto.internet_accessible,
  };
};

export const mapApplicationsDtoToViewModel = (dto: Application): globalModels.ApplicationsViewModel => {
  return {
    id: dto.id,
    name: dto.name,
    labels: dto.labels,
    lastUpdatedAt: dayjs(dto.last_updated_at).format(globalConstants.FULL_MONTH_DATE_TIME),
    lastScanEndedAt: dto.last_scan_ended_at || '',
    project: dto.project,
    projectName: dto.project_name,
    noOfTargets: dto.targets_count || 0,
    configuration: dto.configuration,
    vulnerablePathsStat: [
      { severityIndex: 1, count: dto.vulnerable_paths_statistics?.Critical || 0, severity: 'Critical' },
      { severityIndex: 2, count: dto.vulnerable_paths_statistics?.High || 0, severity: 'High' },
      { severityIndex: 3, count: dto.vulnerable_paths_statistics?.Medium || 0, severity: 'Medium' },
      { severityIndex: 4, count: dto.vulnerable_paths_statistics?.Low || 0, severity: 'Low' },
      { severityIndex: 5, count: dto.vulnerable_paths_statistics?.Informational || 0, severity: 'Info' },
    ],
  };
};

export const mapProjectsDtoToViewModel = (dto: Project, me?: User): globalModels.ProjectViewModel => (
  {
    id: dto.id,
    name: dto.name,
    description: dto.own_user.id === me?.id
      ? dto.is_default
        ? 'Your first project' : 'Own project'
      : 'Shared project',
    targets_count: dto.targets_count,
    teamSize: dto.shared_with_users.length,
    collaborators: dto.shared_with_users_preview,
    isMyDefault: dto.is_default && dto.own_user.id === me?.id,
    isFree: dto.is_free,
    isOwned: dto.own_user.id === me?.id,
    owner: dto.own_user,
  }
);

export const mapTargetDtoToViewModelOld = (target: Target): globalModels.TargetViewModel => (
  {
    id: target.id,
    name: target.name || 'N/A',
    hasApiDetails: target.type?.toString() === globalEnums.ETargetType.OpenAPI,
    description: target.description || 'N/A',
    location: target.location || 'N/A',
    type: target.type?.toString() as globalEnums.ETargetType,
    createdBy: target.own_user,
    projectName: target.project_name || 'N/A',
    projectId: target.project || '',
    createdAt: dayjs(target.created_at).format(FULL_MONTH_DATE_TIME),
    updatedAt: dayjs(target.last_updated_at).format(FULL_MONTH_DATE_TIME),
    credentials: target.credentials,
    internetAccessible: target.internet_accessible,
  }
);

export const mapIssueKindsDtoToViewModel = (dto: IssueKindStatistics): globalModels.IssueKindStatisticsViewModel => {
  return {
    id: dto.id,
    kindId: dto.kind_id,
    name: dto.kind_name,
    type: dto.type,
    labels: [dto.severity],
    openIssues: dto.open,
    vulnPaths: dto.vulnerable_paths_count,
    resolved: dto.resolved,
    falsePositives: dto.false_positive,
  };
};

export const mapUserDtoToViewModel = (user: User, me?: User): globalModels.UserViewModel => (
  {
    id: user.id,
    username: user.username,
    firstName: user.first_name,
    lastName: user.last_name,
    fullName: `${user.first_name} ${user.last_name}`,
    email: user.email,
    isMe: user.id === me?.id,
    avatarUrl: user.avatar_url,
    phoneNumber: user.phone_number,
    jobTitle: user.job_title,
    isStaff: user.is_staff,
  }
);

export const mapTokensDtoToViewModel = (dto: CliToken): globalModels.TokenViewModel => (
  {
    id: dto.token_key,
    digest: dto.digest,
    tokenKey: dto.token_key,
    createdAt: dayjs(dto.created).format(globalConstants.FULL_MONTH_DATE_TIME),
    expiresAt: dto.expiry ? dayjs(dto.expiry).format(globalConstants.FULL_MONTH_DATE_TIME) : 'No expiration date',
    isTokenExpired: dayjs() > dayjs(dto.expiry),
  }
);