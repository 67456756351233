import { Grid } from '@contentful/f36-components';
import { styled, Stack, Box } from '@mui/material';
import { BreakpointX } from 'views/utils/design';
import { muiPalette } from '@shared/general-mui-theme';

const ContentGrid = styled(Grid)`
  word-break: break-word;

  .grid-item-info {
    overflow-x: scroll;
  }; 

  @media (max-width: ${BreakpointX.Laptop.max}px) {
    .grid-item-info {
      overflow-x: scroll;
      grid-area: auto / span 2;
      margin-bottom: 1.5rem;
    }
  }
`;

const FilteredStackPanel = styled(Stack)`
  background-color: #FFFFFF;
  border-radius: 0.375rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid ${muiPalette.grey?.[200]};
  padding: 0 0.75rem;
`;

const StyledCodeContainer = styled(Box)`
  min-width: 13rem;
  background: #FFFFFF;
  border: 1px solid ${muiPalette.grey?.[200]};
  border-radius: 0.375rem;

  pre {
    pointer-events: auto;
    margin: 0;
  }
`;

export { ContentGrid, FilteredStackPanel, StyledCodeContainer };
