import { ButtomRoundArrowC, ExploreTheAppHereC, ScanExamplesC, TopRoundArrowC } from '@assets';
import * as assets from '@assets';
import { muiPalette } from '@shared/general-mui-theme';
import { ScanTargetDialog } from './components';
import React from 'react';
import {
  ContainerLayout,
} from './new-scan-page.styled';
import { Wave } from 'views/utils/emoji';
import { globalConstants } from '@shared/duck';
import { ScanTypeCard } from './components/scan-type-card';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useApp, useMedia } from '@shared/hooks';
import { newScanPageEnums } from './duck';

const NewScanPage = () => {
  const app = useApp();

  const isScanLimitReached = app.checkIfScanLimitReached();

  const [openScanTargetDialog, setOpenScanTargetDialog] = React.useState(false);
  const [currentScanType, setCurrentScanType] = React.useState<newScanPageEnums.EScanType | undefined>();

  const handleCardAction = (type: newScanPageEnums.EScanType) => {
    setOpenScanTargetDialog(true);
    setCurrentScanType(type);
  };

  const handleDialogClose = () => {
    setOpenScanTargetDialog(false);
    setCurrentScanType(undefined);
  };

  const { isSxScreen, isSmallScreen, isLargeScreen } = useMedia();

  return (
    <ContainerLayout
      style={{
        marginTop: '-4.5rem',
        padding: isSmallScreen ? '4.5rem 1rem 1rem' : '4.5rem 8rem 5rem',
      }}
    >
      <Typography variant='h4' lineHeight='3rem' fontWeight='600'>
        {Wave} Hello there!
      </Typography>
      <Typography
        variant='subtitle2'
        sx={{
          fontSize: '0.875rem',
          fontWeight: '400',
          lineHeight: '1.25rem',
          letterSpacing: '-0.00963rem',
          color: '#5A657C',
          margin: '0.5rem 0 3rem 0',
        }}
      >
        We’re excited you’re here! Let’s find some exploitable security risks.
      </Typography>

      <Box display='flex' width='100%' justifyContent='center'>
        <Grid
          container
          spacing={3}
        >
          {isLargeScreen && (
            <Grid item lg={4} maxWidth={isLargeScreen ? '16.5rem !important' : 'inherit'}>
              <Stack gap={0}>
                <ExploreTheAppHereC sx={{ cursor: 'default !important' }} />
                <Stack alignItems='flex-end'>
                  <ButtomRoundArrowC sx={{ cursor: 'default !important' }} />
                </Stack>
              </Stack>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            maxWidth={isLargeScreen ? '19.5rem !important' : 'inherit'}
          >
            <ScanTypeCard
              action={() => handleCardAction(newScanPageEnums.EScanType.WEB)}
              asset={assets.WebSvg}
              title='Scan Web Applications'
              description='Identify and assess potential security vulnerabilities within Websites & Web Apps'
              disabled={isScanLimitReached}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            maxWidth={isLargeScreen ? '19.5rem !important' : 'inherit'}
          >
            <ScanTypeCard
              action={() => handleCardAction(newScanPageEnums.EScanType.OPEN_API)}
              asset={assets.ApiSvg}
              title='Scan APIs'
              description='Secure your APIs to prevent vulnerabilities like unauthorized access, data breaches, and more'
              disabled={isScanLimitReached}
            />
          </Grid>

          {!isSxScreen && (
            <Grid item sm={12}>
              <Divider sx={{ borderColor: muiPalette.grey?.[200] }} />
            </Grid>
          )}

          {isLargeScreen && (
            <Grid item lg={4} maxWidth={isLargeScreen ? '16.5rem !important' : 'inherit'}>
              <Stack gap={0}>
                <Stack alignItems='flex-end'>
                  <TopRoundArrowC sx={{ cursor: 'default !important' }} />
                </Stack>

                <ScanExamplesC sx={{ cursor: 'default !important' }} />
              </Stack>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            maxWidth={isLargeScreen ? '19.5rem !important' : 'inherit'}
          >
            <ScanTypeCard
              action={() => app.openReadme(globalConstants.DOCS_PATHS.GITHUB_ACTIONS)}
              asset={assets.PurpleCircleTerminalSvg}
              title='Try a CI/CD example'
              description='Integrate NightVision DAST scans into your CI/CD pipelines to automatically find vulnerabilities within minutes'
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            maxWidth={isLargeScreen ? '19.5rem !important' : 'inherit'}
          >
            <ScanTypeCard
              action={() => app.openReadme(globalConstants.DOCS_PATHS.SCANNING_WEB_APPS)}
              asset={assets.LinkSvg}
              title='Try a Web App example'
              description='We have set up an example scan of a purposefully vulnerable Web App for you to test'
            />
          </Grid>
        </Grid>
      </Box>

      {currentScanType && (
        <ScanTargetDialog
          open={openScanTargetDialog}
          scanType={currentScanType}
          onClose={handleDialogClose}
        />
      )}
    </ContainerLayout>
  );
};

export default NewScanPage;
