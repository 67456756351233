import { DialogTitle, IconButton } from '@mui/material';
import { DialogTitleContainer } from './ui-dialog.styled';
import React from 'react';
import { X } from '@phosphor-icons/react';

/** Note: this file contains components from the Dialog
 * that need more than just styles
 * */

interface UIDialogTitleProps {
  title: string;
  onCloseBtnAction?: () => void;
}

export const UIDialogTitle: React.FC<UIDialogTitleProps> = ({
  title,
  onCloseBtnAction,
}) => {
  return (
    <DialogTitleContainer>
      <DialogTitle>
        {title}
      </DialogTitle>
      {onCloseBtnAction && (
        <IconButton
          size='small'
          onClick={onCloseBtnAction}
        >
          <X color='black' size={16} />
        </IconButton>
      )}
    </DialogTitleContainer>
  );
};