import { Line } from 'react-chartjs-2';
import { ChartBox } from './time-chart.styled';
import 'chartjs-adapter-date-fns';

interface AxisProps {
  min?: number | string;
  max?: number | string;
  renderTickLabel?: (value: string | number, index: number, ticks: any[]) => string;
  displayTicks?: boolean;
}

interface DatasetProps {
  label: string;
  data: ({ x: Date; y: number } | number)[];
  borderColor?: string;
  backgroundColor?: string;
}

export interface TimeChartProps {
  axisX?: AxisProps;
  axisY?: AxisProps;
  labels?: string[];
  verticalLineOnHover?: true;
  perMonth?: boolean;
  datasets: DatasetProps[];
}

const TimeChart: React.FC<TimeChartProps> = ({
  axisX, axisY, labels,
  verticalLineOnHover,
  datasets,
  perMonth
}: TimeChartProps) => {
  return (
    <ChartBox>
      <Line
        options={{
          animations: {
            x: { duration: 0 },
            y: { duration: 200, easing: 'easeOutSine' },
          },
          transitions: {
            show: { animation: { duration: 200 } },
            hide: { animation: { duration: 200 } },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              ticks: {
                display: axisY?.displayTicks,
                callback: axisY?.renderTickLabel,
              },
              min: axisY?.min,
              max: axisY?.max,
              grid: {
                drawTicks: false,
              },
            },
            x: {
              type: 'time',
              time: {
                unit: perMonth ? 'month' : 'day',
                tooltipFormat: perMonth ? 'MMM yyyy' : 'MMM dd, yyyy',
              },
              grid: {
                display: false,
              },
              ticks: {
                display: axisX?.displayTicks,
                callback: axisX?.renderTickLabel,
                maxRotation: 0,
                minRotation: 0,
              },
              min: axisX?.min,
              max: axisX?.max,
            },
          },
          plugins: {
            legend: {
              position: 'top',
              align: 'end',
              labels: {
                boxWidth: 5,
                boxHeight: 5,
                usePointStyle: true,
              },
            },
            tooltip: {
              xAlign: 'center',
              yAlign: 'bottom',
              displayColors: false,
            },
          },
          /** @ts-expect-error: ChartJS natively supports this config */
          pointRadius: 0,
          spanGaps: true,
          showLine: true,
          borderWidth: 3,
          hoverBorderWidth: 3,
          hoverRadius: 4,
          hoverBackgroundColor: 'white',
          interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x',
          },
        }}
        data={{
          labels,
          datasets,
        }}
        plugins={
          verticalLineOnHover && [
            {
              id: 'vertical-line-active-tooltip',
              beforeDraw: chart => {
                const activeElements = chart.tooltip?.getActiveElements();
                if (activeElements?.length) {
                  const x = activeElements[0].element.x;
                  const yAxis = chart.scales.y;
                  const ctx = chart.ctx;
                  ctx.save();
                  ctx.beginPath();
                  ctx.moveTo(x, yAxis.top);
                  ctx.lineTo(x, yAxis.bottom);
                  ctx.lineWidth = 1;
                  ctx.setLineDash([5, 5]);
                  ctx.strokeStyle = '#cccccc';
                  ctx.stroke();
                  ctx.restore();
                }
              },
            },
          ]
        }
      />
    </ChartBox>
  );
};

export default TimeChart;
