import { globalEnums, globalTypes } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';
import { Menu, IconButton, Box, ListItemIcon, Tooltip, Stack, SxProps, Theme } from '@mui/material';
import { DotsThreeVertical } from '@phosphor-icons/react';
import { StyledMenuItem, StyledMenuItemText } from '../menu.styled';

interface BasicMenuProps {
  itemList: globalTypes.UIMenuItemsList[];
  disabled?: boolean;
  iconVariant?: globalEnums.EMuiIconButtonVariants;
  iconSize?: 'small' | 'medium' | 'large';
  iconColor?: string;
  menuIconC?: JSX.Element;
  sx?: SxProps<Theme>;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const BasicMenu: React.FC<BasicMenuProps> = ({
  itemList,
  disabled,
  iconVariant = globalEnums.EMuiIconButtonVariants.OUTLINED,
  iconSize = 'medium',
  iconColor = muiPalette.grey?.[900],
  menuIconC,
  sx,
  setIsOpen,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    setIsOpen && setIsOpen(open);
  }, [open]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (disabled) {
      event.preventDefault();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {menuIconC ? (
        <Box
          display='flex'
          justifyContent='center'
          onClick={handleClick}
          aria-label='toggle_menu'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          {menuIconC}
        </Box>
      ) : (
        <IconButton
          color={iconVariant}
          aria-label='toggle_menu'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          size={iconSize}
        >
          <DotsThreeVertical color={iconColor} weight='bold' size={22} />
        </IconButton>
      )}

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={sx}
      >
        {itemList.map((item, index) => {
          const iconPosition = item.titleIconPosition || 'left';

          return item.isHidden ? null : (
            <Tooltip
              key={`basic-menu-tooltip-${item.title}-${index}`}
              arrow
              placement={item.tooltipPlacement || 'top'}
              title={item.disabled ? item.tooltipText || '' : ''}
            >
              <span>
                <StyledMenuItem
                  onClick={() => {
                    item.itemAction();
                    handleClose();
                  }}
                  disabled={item.disabled}
                >
                  <Stack flexDirection={'row'} gap={item.titleStackGap || 0.25}>
                    {item.titleIcon && iconPosition === 'left' && (
                      <ListItemIcon sx={{ alignItems: 'center' }}>
                        {item.titleIcon}
                      </ListItemIcon>
                    )}

                    <StyledMenuItemText
                      isRedText={item.isRedText ? globalEnums.EmotionBoolean.True : globalEnums.EmotionBoolean.False}
                    >
                      {item.title}
                    </StyledMenuItemText>

                    {item.titleIcon && iconPosition === 'right' && (
                      <ListItemIcon sx={{ alignItems: 'center' }}>
                        {item.titleIcon}
                      </ListItemIcon>
                    )}
                  </Stack>
                </StyledMenuItem>
              </span>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
};

export default BasicMenu;