import { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(
      done => {
        done.getCLS(onPerfEntry);
        done.getFID(onPerfEntry);
        done.getFCP(onPerfEntry);
        done.getLCP(onPerfEntry);
        done.getTTFB(onPerfEntry);
      },
      error => {
        console.log(error);
      },
    );
  }
};

export default reportWebVitals;
