import { muiPalette } from '@shared/general-mui-theme';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const NightvisionLogoLink = styled(Link)(
  () => ({
    'svg': {
      'path': {
        fill: 'white',
      }
    },

    '& :hover:': {
      'svg': {
        'path': {
          fill: muiPalette.grey?.[200],
        }
      }
    }
  }),
);

export { NightvisionLogoLink };
