import React from 'react';
import { useDebounce } from '@shared/hooks';
import { MuiSearchInput, MuiTable, tableTypes } from '@shared/components';
import { Stack } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { useParams } from 'react-router-dom';
import { collaboratorsColumns, useCollaboratorsTableApi } from './duck';
import { globalModels, globalUtils } from '@shared/duck';

const ProjectCollaborators: React.FC = () => {
  const { debouncedValue: debouncedSearch, onChange: onSearchChange } = useDebounce<string>();
  const { projectId = '' } = useParams();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [debouncedSearch, projectId]);

  const {
    collaborators,
    totalItemsCount,
    isCollaboratorsListLoading,
    collaboratorsHasNextPage,
    fetchNextCollaboratorsPage,
    onUnshareCollaboratorClick,
    isProjectOwned,
    projectOwnerId,
  } = useCollaboratorsTableApi({
    page: currentPage,
    search: debouncedSearch,
    projectId: projectId,
    pageSize: rowsPerPage,
  });

  const tableConfig: tableTypes.MuiTableProps<globalModels.UserViewModel> = {
    data: collaborators,
    columns: collaboratorsColumns(projectOwnerId),
    loading: isCollaboratorsListLoading,
    isLoadMoreAvailable: collaboratorsHasNextPage,
    onLoadMoreClick: fetchNextCollaboratorsPage,
    withExternalBorders: false,
    pagination: {
      count: totalItemsCount,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      onPageChange: (e, newPage) => handleChangePage(newPage),
      onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
    },
  };

  tableConfig.itemActions = isProjectOwned ? item => (
    {
      itemList: [
        {
          title: 'Unshare',
          itemAction: () => onUnshareCollaboratorClick?.(item),
          isRedText: true,
          disabled: item.isMe,
          tooltipText: globalUtils.getUnShareProjectTooltipText(isProjectOwned),
        },
      ],
    }
  ) : undefined;

  return (
    <>
      <Stack p='1rem' borderBottom={`1px solid ${muiPalette.grey?.[200]}`}>
        <MuiSearchInput
          width='25rem'
          onChange={e => onSearchChange(e.target.value)}
        />
      </Stack>

      <MuiTable
        {...tableConfig}
      />
    </>
  );
};

export default ProjectCollaborators;