import { tableTypes, UIBadge } from '@shared/components';
import { StyledTableCell } from '../../table-cell.styled';
import { globalEnums } from '@shared/duck';
import { Stack } from '@mui/material';

interface CellLabelProps<DataItem> {
  labels: Array<string>;
  column: tableTypes.ColumnProps<DataItem>;
  badgeVariant?: globalEnums.EBadgeVariants;
}

type alignProps = 'center' | 'left' | 'right';

const CellLabels = <DataItem,>({
  labels,
  column,
  badgeVariant = globalEnums.EBadgeVariants.SECONDARY,
}: CellLabelProps<DataItem>) => {
  return (
    <StyledTableCell
      width={column.width}
      minWidth={column.minWidth}
      align={column.cellInfo?.alignItems as alignProps || 'left'}
    >
      <Stack direction='row' gap='0.25rem' flexWrap='wrap' justifyContent={column.cellInfo?.alignItems || 'left'}>
        {
          labels.map((label, index) => {
            return (
              <UIBadge
                key={label + index}
                variant={badgeVariant}
                text={label}
                sx={{
                  maxWidth: column.minWidth || '6rem'
                }}
              />
            );
          })
        }
      </Stack>
    </StyledTableCell>
  );
};

export default CellLabels;