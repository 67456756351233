import React from 'react';
import { Credentials, CredentialsUpdate } from '@api-client';
import { MuiAutocomplete } from '@shared/components';
import { globalQueries, globalTypes } from '@shared/duck';
import { selectorsUtils } from '../duck';
import { CustomInput, CustomOption, CustomPaperComponent } from '../components';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

interface MuiAuthenticationSelectProps extends globalTypes.MUISelectProps<Credentials> {
  label?: string;
  currentValue?: Credentials;
  withoutBorders?: boolean;
  projectId?: string;
  withCreate?: boolean;
}

const MuiAuthenticationSelect: React.FC<MuiAuthenticationSelectProps> = ({
  label = 'Authentication',
  currentValue,
  projectId = '',
  withCreate = true,
  onFormChange,
  ...props
}) => {
  const [value, setValue] = React.useState<Credentials | CredentialsUpdate | null>(currentValue ?? null);

  React.useEffect(() => {
    setValue(currentValue ?? null);
  }, [currentValue]);

  const {
    credentials = [],
    isCredentialsListLoading,
    credentialsHasNextPage,
    fetchNextCredentialsPage,

  } = globalQueries.useGetCredentialsList({
    project: projectId ? [projectId] : [],
    order: ['name'],
  });

  const { showModal } = useGlobalModalContext();

  const onAddNewCredential = () => showModal({
    modalType: MODAL_TYPE.AuthenticationModal,
    modalProps: {
      initialProjectId: projectId,
      enableProjectSelect: false,
      onResult(data) {
        data && setValue(data);
      },
    },
  });

  React.useEffect(() => {
    if (credentialsHasNextPage && !isCredentialsListLoading) {
      fetchNextCredentialsPage();
    }
  }, [credentialsHasNextPage, fetchNextCredentialsPage, isCredentialsListLoading]);

  React.useEffect(() => {
    selectorsUtils.handleOnFormChange(value, onFormChange);
  }, [value]);

  return (
    <MuiAutocomplete
      id='authentication-autocomplete'
      options={credentials}
      label={label}
      required={props.required}
      error={props.error}
      autoComplete
      autoHighlight
      loadingText='Loading...'
      loading={isCredentialsListLoading}
      value={value}
      // filterSelectedOptions
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      onChange={(_, newValue: Credentials) => setValue(newValue)}
      renderOption={(props, option) => {
        return (
          <CustomOption {...props} option={option} />
        );
      }}
      renderInput={(params) => {
        return (
          <CustomInput
            {...params}
            onBlur={props.onBlur}
            isError={props.error}
            name={props.name}
            isLoading={isCredentialsListLoading}
            placeholder='Select'
          />
        );
      }}
      PaperComponent={(props) => {
        return (
          <CustomPaperComponent
            {...props}
            onCreateClick={onAddNewCredential}
            withCreate={withCreate}
            content='+ Create new Authentication'
          />
        );
      }}
      getOptionKey={(option: Credentials) => option.id}
      getOptionLabel={(option: Credentials) => option.name}
      noOptionsText='No Authentications found'
    />
  );
};

export default MuiAuthenticationSelect;