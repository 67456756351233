import { Typography } from '@mui/material';
import { DocsLink, Modal, ModalContent, ModalHeader } from '@shared/components';
import { globalConstants } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';

interface ModalPopup {
  isOpen: boolean;
  closeModal: () => void;
}

const LearnMorePopup: React.FC<ModalPopup> = ({
  isOpen,
  closeModal,
}) => {
  return (
    <Modal
      isShown={isOpen}
      onClose={closeModal}
      size={'large'}
    >
      {() => (
        <>
          <ModalHeader
            title={'Learn more about Playwright'}
            onClose={closeModal}
          />
          <ModalContent
            style={{ display: 'flex', flexDirection: 'column', maxHeight: '43.75rem' }}
          >
            <h2
              style={{
                fontSize: '1.25rem',
                lineHeight: '2rem',
                letterSpacing: '0.02375rem',
                marginTop: 'initial',
              }}
            >
              {'What is Playwright?'}
            </h2>

            <Typography
              variant='default' color={muiPalette.grey?.[700]}
              mb='1rem'
            >
              {'Playwright is a browser automation library developed by Microsoft, ' +
                'offering multi-browser support and the ability to record authentication' +
                ' flows. It supports headless browser operation, ' +
                'which means it can run browser automation in the background without ' +
                'a visible browser window.'}
            </Typography>

            <Typography
              variant='default' color={muiPalette.grey?.[700]}
              mb='0.5rem'
            >
              {'See it in action in the video below:'}
            </Typography>

            <div style={{ position: 'relative', height: '20rem', width: '40.75rem' }}>
              <iframe
                src={globalConstants.VIDEOS_URL.WHAT_IS_PLAYWRIGHT}
                allowFullScreen
                style={{ width: '100%', height: '100%', borderRadius: '0.5rem' }}
              />
            </div>

            <Typography
              variant='default' color={muiPalette.grey?.[700]}
              mt='1.5rem'
            >

              {'Learn more about '}

              <DocsLink
                to={globalConstants.DOCS_PATHS.RECORD_AUTH}
              >
                {'Recording Authentication'}
              </DocsLink>

              {' using Playwright'}
            </Typography>
          </ModalContent>
        </>
      )}
    </Modal>
  );
};

export default LearnMorePopup;