import { Scan } from '@api-client';
import { CredentialsLink, CredentialsBasicInfo } from '../components';
import { DataItem } from '../components/creds-basic-info/credentials-basic-info';

interface HeaderBasedCredentialsProps {
  scan?: Scan;
}

const HeaderBasedCredentials: React.FC<HeaderBasedCredentialsProps> = ({
  scan
}: HeaderBasedCredentialsProps) => {
  const { credentials } = scan || {};

  const items: DataItem[] = [
    {
      title: 'Authentication type',
      value: 'Header Authentication',
    },
    {
      title: 'Authentication credential',
      value: <CredentialsLink credentials={credentials} targetProjectId={scan?.target.project || ''} />,
    },
  ];
  return <CredentialsBasicInfo items={items} />;
};

export default HeaderBasedCredentials;
