import { Box, RadioGroup, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const CliRemovedFileWrapper = styled(Box)`
  padding: 1rem;
  border: 1px dashed ${muiPalette.grey?.[300]};
  border-radius: 0.375rem;
  color: ${muiPalette.grey?.[500]};

  & > i {
    font-weight: 400;
    color: ${muiPalette.grey?.[500]};

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const WebRadioGroup = styled(RadioGroup)`
  & label { 
    margin-left: 0;
    
    & > .MuiRadio-root {
      margin-right: 0.25rem;
      padding: 0;
    }

    & > .MuiTypography-root {
      line-height: 1.25rem;
      color: #111B2B;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
`;

export { CliRemovedFileWrapper, WebRadioGroup };