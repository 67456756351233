import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { muiPalette } from '@shared/general-mui-theme';

export const StyledGroupRow = styled(TableRow)`
  td {
    color: ${muiPalette.grey?.['500']};
    padding: 0.5rem 1rem;

    &:hover {
      cursor: default !important;
      background-color: transparent;
    }
  }
`;