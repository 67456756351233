import { IconContainer } from '@shared/components';
import React from 'react';
import { StyledIconFrame } from './icon-frame.styled';
import { iconFrameTypes } from './duck';

const UIIconFrame: React.FC<iconFrameTypes.IconFrameProps> = (props) => {
  const Icon = props.Icon;

  return (
    <IconContainer>
      <StyledIconFrame {...props}>
        <Icon {...props.iconProps} />
      </StyledIconFrame>
    </IconContainer>
  );
};

export default UIIconFrame;
