import { AssetProps } from '@contentful/f36-asset';
import { IconProps } from '@mui/material/Icon/Icon';
import React from 'react';
import { UIAsset } from '@shared/components';
import {
  FlashIcon,
  FileTextIcon,
  ExternalLinkIcon,
  Copy,
  Link,
  DownloadGray,
  CopyBlack,
  NightvisionFullLogo,
  NightvisionSmallLogo,
  DeleteOutline,
  Refresh,
  CopyBlue,
  WorldIcon,
  GithubIcon,
  SwaggerIcon,
  VueIcon,
  PythonIcon,
  FlaskIcon,
  LaravelIcon,
  PhpIcon,
  JSIcon,
  Cloud,
  FileIcon,
  ExploreTheAppHereSvg,
  ButtomRoundArrowSvg,
  ScanExamplesSvg,
  TopRoundArrowSvg,
} from '@assets';
import { globalEnums, globalUtils } from '@shared/duck';

interface IconComponentProps extends AssetProps {
  id?: string;
}

interface CopyIconProps extends IconProps {
  variant?: globalEnums.CopyIconVariantsEnum;
}

export const ExternalLinkIconC: React.FC<IconComponentProps> = ({
  id,
}) => {
  return (
    <UIAsset id={id} type='image' src={ExternalLinkIcon} />
  );
};

export const LinkIconC: React.FC<IconComponentProps> = ({
  id,
}) => {
  return (
    <UIAsset id={id} type='image' src={Link} />
  );
};

export const CopyIconC: React.FC<CopyIconProps> = ({
  variant = globalEnums.CopyIconVariantsEnum.DEFAULT,
  ...props
}) => {
  /** Note: use 'sx' for <Icon> & 'style' for <img> */

  switch (variant) {
    case globalEnums.CopyIconVariantsEnum.DEFAULT:
      return globalUtils.createCustomIcon(Copy, props);
    case globalEnums.CopyIconVariantsEnum.BLACK:
      return globalUtils.createCustomIcon(CopyBlack, props);
    case globalEnums.CopyIconVariantsEnum.BLUE:
      return globalUtils.createCustomIcon(CopyBlue, props);
    default:
      return globalUtils.createCustomIcon(Copy, props);
  }
};

export const DownloadIconC: React.FC<IconProps> = ({
  ...props
}) => {
  /** Note: use 'sx' for <Icon> & 'style' for <img> */
  return globalUtils.createCustomIcon(DownloadGray, props);
};

export const WorldIconC: React.FC<IconProps> = ({
  ...props
}) => {
  /** Note: use 'sx' for <Icon> & 'style' for <img> */
  return globalUtils.createCustomIcon(WorldIcon, props);
};

export const FileTextIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(FileTextIcon, props);
};

export const GithubIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(GithubIcon, props);
};

export const SwaggerIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(SwaggerIcon, props);
};

export const VueIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(VueIcon, props);
};

export const PythonIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(PythonIcon, props);
};

export const FlaskIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(FlaskIcon, props);
};

export const LaravelIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(LaravelIcon, props);
};

export const PhpIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(PhpIcon, props);
};

export const JSIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(JSIcon, props);
};

export const CloudIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(Cloud, props);
};

export const FileIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(FileIcon, props);
};

export const FlashIconC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(FlashIcon, props);
};

export const ExploreTheAppHereC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(ExploreTheAppHereSvg, props);
};

export const ButtomRoundArrowC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(ButtomRoundArrowSvg, props);
};

export const ScanExamplesC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(ScanExamplesSvg, props);
};

export const TopRoundArrowC: React.FC<IconProps> = ({
  ...props
}) => {
  return globalUtils.createCustomIcon(TopRoundArrowSvg, props);
};

export const NightvisionFullIconC: React.FC<IconComponentProps> = ({
  id,
  ...props
}) => {
  return (
    <UIAsset id={id} type='image' src={NightvisionFullLogo} {...props} />
  );
};

export const NightvisionSmallIconC: React.FC<IconComponentProps> = ({
  id,
}) => {
  return (
    <UIAsset id={id} type='image' src={NightvisionSmallLogo} />
  );
};

export const DeleteOutlineIconC: React.FC<IconComponentProps> = ({
  id,
}) => {
  return (
    <UIAsset id={id} type='image' src={DeleteOutline} />
  );
};

export const RefreshIconC: React.FC<IconComponentProps> = ({
  id,
}) => {
  return (
    <UIAsset id={id} type='image' src={Refresh} />
  );
};