import { TextLinkProps } from '@contentful/f36-components';
import React from 'react';
import { StyledCustomIconLink } from './custom-icon-link.styled';

interface BaseLinkProps extends TextLinkProps {
  text: string;
  icon: React.ReactElement;
}

const CustomIconLink: React.FC<BaseLinkProps> = ({
  text,
  icon,
  ...props
}) => {
  return (
    <StyledCustomIconLink
      icon={icon}
      alignIcon='end'
      href={props.href}
      target={props.target || '_blank'}
      rel='noopener noreferrer'
      onClick={(e: any) => e.stopPropagation()}
      {...props}
    >
      {text}
    </StyledCustomIconLink>
  );
};

export default CustomIconLink;