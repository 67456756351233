import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';
import { BreakpointX } from 'views/utils/design';
import { globalTypes } from '@shared/duck';

export const StyledContentContainer = styled.div<{ hasTarget?: globalTypes.EmotionBooleanType }>`
  & .issue-occurrences-table {
    position: relative;
    padding: 0.25rem;
    background-color: ${baseTokens.gray200} !important;
    // border-collapse: unset;
    //border-spacing: 0.25rem;
    

    th {
      background: white;
    }

    tr td {
      background-color: white !important;
      vertical-align: middle !important;
    }

    tr:hover:not(.selected-row) td:not(.expandFlex):not(.hidden-substrate td) {
      background-color: ${baseTokens.gray100} !important;
    }

    tr.selected-row td {
      background-color: ${baseTokens.blue100} !important;
    }
    
    td {
      border-bottom: 1px solid ${baseTokens.gray200};
      background-color: white;

      tr:hover {
        & td:nth-last-of-type(-n + 2) {
          background: ${baseTokens.blue100};
          cursor: pointer;
        }
      }

      tr.selected-row {
        td:nth-last-of-type(-n + 2) {
          background: ${baseTokens.blue100};
        }
      }

      @media (min-width: ${BreakpointX.DesktopHD.min}px) {
        .parameter_name {
          width: ${({ hasTarget }) => (
    hasTarget ? '10rem' : '6.25rem'
  )};
        }
      }

      @media (min-width: ${BreakpointX.DesktopFHD.min}px) {
        .parameter_name {
          width: ${({ hasTarget }) => (
    hasTarget ? '12.5rem' : '6.25rem'
  )};
        }
      }
    }

    .expandRow {
      position: relative;
    }

    .expandFlex {
      background-color: ${baseTokens.gray200} !important;
      filter: drop-shadow(0rem 0.375rem 0.75rem rgba(25, 37, 50, 0.10)) drop-shadow(0rem 0.1875rem 0.4375rem rgba(25, 37, 50, 0.10)) drop-shadow(0rem 0rem 0rem rgba(25, 37, 50, 0.10));
      padding: 0.3rem 0.75rem 0.4rem 2.75rem;
      position: relative;
      border-radius: 0 0 0.375rem 0.375rem;
      z-index: 2;
    }

    .icon {
      width: 1rem;
      height: 1rem;
    }

    .hidden-substrate {
      td {
        background-color: transparent !important;
        position: sticky;
        padding: 0;
        padding-bottom: 0.2rem;
        }
    }

    .substrate {
      position: absolute;
      height: 0.4rem;
      width: 99%;
      left: 0.25rem;
      bottom: 0;
      background-color: ${baseTokens.gray300} !important;
      border-radius: 0 0 0.375rem 0.375rem;
      z-index: 1;
    }
  }
`;