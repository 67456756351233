import { QueryObserverOptions, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as apiClient from 'api-client';
import { useApp } from '@shared/hooks';
import { TargetsQueries } from './queriesKeys';
import { getNextPageParam, useFlatResults } from './utils';
import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';

const useGetUrlTarget = (
  request: apiClient.TargetsApiTargetsUrlRetrieveRequest,
  config?: Partial<QueryObserverOptions<AxiosResponse<apiClient.UrlsTarget>, AxiosError>>,
) => {
  const { targetsApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [TargetsQueries.urlTargetById, ...Object.values(request)],
    queryFn: () => targetsApi.targetsUrlRetrieve({ ...request }),
    enabled: !!request.id,
    ...config,
  });

  return {
    target: data?.data,
    isTargetLoading: request.id ? isLoading : false,
  };
};

const useCreateTargetUrl = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.TargetsApiTargetsUrlCreateRequest) => targetsApi.targetsUrlCreate(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [TargetsQueries.targetsList] });
      queryClient.resetQueries({ queryKey: [TargetsQueries.urlTargetsList] });
    }
  });

  return { createUrlTargets: mutateAsync };
};

const useUpdateTargetUrl = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.TargetsApiTargetsUrlUpdateRequest) => targetsApi.targetsUrlUpdate(request),
    onSuccess: (_, variables) => {
      queryClient.resetQueries({ queryKey: [TargetsQueries.urlTargetsList] });
      queryClient.resetQueries({ queryKey: [TargetsQueries.targetsList] });
      queryClient.resetQueries({ queryKey: [TargetsQueries.targetById] });
      queryClient.resetQueries({ queryKey: [TargetsQueries.targetsAuthList] });
      queryClient.resetQueries({ queryKey: [TargetsQueries.targetsAppList] });
      queryClient.resetQueries({ queryKey: [TargetsQueries.urlTargetById, variables.id] });
    }
  });

  return { updateUrlTargets: mutateAsync };
};

const useGetUrlTargetsList = (request: apiClient.TargetsApiTargetsUrlListRequest) => {
  const { targetsApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [TargetsQueries.urlTargetsList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => targetsApi.targetsUrlList({ ...request, page: pageParam }),

    getNextPageParam: getNextPageParam,
  });

  const urlTargets = useFlatResults(data);

  return {
    urlTargets,
    isUrlTargetsListLoading: isLoading || isFetchingNextPage,
    urlTargetsHasNextPage: hasNextPage,
    fetchNextUrlTargetsPage: fetchNextPage,
  };
};

// Additional paths

const useListPathsTargetUrl = (request: apiClient.TargetsApiTargetsUrlAdditionalPathsListRequest) => {
  const { targetsApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [TargetsQueries.urlTargetPathsList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => targetsApi.targetsUrlAdditionalPathsList({ ...request, page: pageParam }),

    getNextPageParam: getNextPageParam,
  });

  // fetch all pages of paths
  React.useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, data?.pages.length, isFetchingNextPage]);

  const additionalPaths = useFlatResults(data);

  return {
    additionalPaths,
    isUrlTargetPathsListLoading: isLoading || isFetchingNextPage || hasNextPage,
    urlTargetsPathsHasNextPage: hasNextPage,
    fetchNextUrlTargetPathsPage: fetchNextPage,
  };
};

const useCreatePathTargetUrl = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.TargetsApiTargetsUrlAdditionalPathsCreateRequest) => targetsApi.targetsUrlAdditionalPathsCreate(request),
    onSuccess: (_, values) => {
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.urlTargetPathsList, values.id] });
    }
  });

  return { createPathTargetUrl: mutateAsync };
};

const useUpdatePathTargetUrl = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.TargetsApiTargetsUrlAdditionalPathsUpdateRequest) => targetsApi.targetsUrlAdditionalPathsUpdate(request),
    onSuccess: (_, values) => {
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.urlTargetPathsList, values.id] });
    }
  });

  return { updatePathTargetUrl: mutateAsync };
};

const useDeletePathsTargetUrl = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: apiClient.TargetsApiTargetsUrlAdditionalPathsDeleteCreateRequest) => targetsApi.targetsUrlAdditionalPathsDeleteCreate(request),
    onSuccess: (_, values) => {
      queryClient.resetQueries({ queryKey: [TargetsQueries.urlTargetPathsList, values.id] });
    }
  });

  return { deletePathsTargetUrl: mutateAsync };
};

export {
  useGetUrlTarget,
  useCreateTargetUrl,
  useGetUrlTargetsList,
  useUpdateTargetUrl,
  // Additional paths
  useListPathsTargetUrl,
  useCreatePathTargetUrl,
  useUpdatePathTargetUrl,
  useDeletePathsTargetUrl,
};
