import { Box, styled } from '@mui/material';

const StyledMarkdownContainer = styled(Box)`
  p {
    margin: 0;
    word-break: break-word;
  }

  code {
    background: whitesmoke;
    border-radius: 0.1875rem;
    padding: 0.125rem 0.25rem;
  }
`;

export { StyledMarkdownContainer };
