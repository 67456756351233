import { Stack } from '@mui/material';
import { globalModels, globalTypes } from '@shared/duck';
import { ScansTable, OtherFilters, MainFilters, NewScanButtonWrapper, SelectedFiltersPanel, PlannerModal } from './components';
import React from 'react';
import { useMedia } from '@shared/hooks';
import { ItemSelectionApi } from '@shared/hooks/use-item-selection';

interface ScanPageProps {
  scanSelectionApi: ItemSelectionApi<globalModels.ScansViewModelNew>
  itemList: globalTypes.UIMenuItemsList[];
  footerItems: globalTypes.UIMenuItemsList[];
  setOpenPlanner: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPlannerScans: React.Dispatch<React.SetStateAction<globalModels.ScansViewModelNew[] | undefined>>;
  isOpenPlanner: boolean;
  selectedPlannerScans?: globalModels.ScansViewModelNew[];
}

const ScansPage: React.FC<ScanPageProps> = ({
  scanSelectionApi,
  itemList,
  footerItems,
  setOpenPlanner,
  setSelectedPlannerScans,
  isOpenPlanner,
  selectedPlannerScans,
}) => {
  const { isSxScreen } = useMedia();

  return (
    <Stack gap={1.5} overflow='hidden'>
      {isSxScreen && (
        <NewScanButtonWrapper
          fullWidth
          itemList={itemList}
          footerItems={footerItems}
        />
      )}

      <Stack flexDirection='row' justifyContent='space-between' gap='1rem' alignItems='flex-start'>
        <MainFilters />

        {!isSxScreen && (
          <NewScanButtonWrapper
            itemList={itemList}
            footerItems={footerItems}
          />
        )}
      </Stack>

      <OtherFilters />

      <SelectedFiltersPanel />

      <ScansTable
        itemSelectionApi={scanSelectionApi}
        setOpenPlanner={setOpenPlanner}
        setSelectedPlannerScans={setSelectedPlannerScans}
      />

      {selectedPlannerScans && (
        <PlannerModal
          isOpen={isOpenPlanner}
          onClose={() => setOpenPlanner(false)}
          scans={selectedPlannerScans}
        />
      )}
    </Stack >
  );
};

export default ScansPage;
