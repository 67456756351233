import React from 'react';
import { InputWithBadge } from './components';
import { HorizontalDivider } from '@shared/components';
import { Stack } from '@mui/material';

const AdvancedTab: React.FC = () => {
  return (
    <Stack width='100%' gap='1.5rem' mt='1.5rem'>
      <InputWithBadge
        name='url_patterns'
        tooltip='Provide URL patterns you want to exclude from the scan'
        placeholder='Enter regex or the URLs directly'
        label='Exclude URL patterns'
      />
      <HorizontalDivider />

      <InputWithBadge
        name='xpath_patterns'
        tooltip='Provide clicks based on XPath you want to exclude from the scan'
        placeholder='Enter XPath'
        label='Exclude clicks based on XPath'
      />
    </Stack>
  );
};

export default AdvancedTab;