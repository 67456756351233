import { Spinner } from '@contentful/f36-components';
import React from 'react';
import { useFormikContext } from 'formik';
import { isUndefined } from 'lodash';
import { globalEnums, globalQueries, globalTypes, globalUtils } from '@shared/duck';
import { WebTargetsContentValues } from '../../duck';
import baseTokens from '@contentful/f36-tokens';
import { EndIcon, UITextInput, UnableToRunScanModal } from '@shared/components';
import { CanceledError } from 'axios';

export interface UrlCellRenderProps
  extends Pick<globalTypes.UITextInputProps, 'onBlur' | 'name' | 'id' | 'isInvalid' | 'isDisabled'> {
  index: number;
  projectId?: string;
}

const UrlCellRender: React.FC<UrlCellRenderProps> = ({
  isInvalid: invalid,
  index,
  projectId,
  ...innerTextInputProps
}: UrlCellRenderProps) => {
  const { values, setFieldValue } = useFormikContext<WebTargetsContentValues>();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [changes] = React.useState({ count: 0 });
  const [isInvalid, setInvalid] = React.useState<boolean>(invalid ?? false);
  const [abortController] = React.useState(new AbortController());

  React.useEffect(() => {
    isUndefined(values.targets[index].isAccessible) ?
      setInvalid(false) :
      setInvalid(!values.targets[index].isAccessible);
  }, [values.targets[index].isAccessible]);

  const { checkPublicUrl } = globalQueries.useCheckPublicUrl(abortController.signal);

  const componentWillUnmount = React.useRef(false);

  const cleanup = React.useCallback(() => {
    abortController.abort();
    componentWillUnmount.current = true;
  }, [abortController]);

  globalUtils.useComponentWillUnmount(cleanup);

  React.useEffect(() => {
    const onTestNewWebTarget = async (
      url: string,
    ) => {
      const currCounter = changes.count;
      try {
        const { data } = await checkPublicUrl(url);
        if (changes.count === currCounter && !componentWillUnmount.current) {
          setFieldValue(`targets[${index}].url`, data.requested_url);
          setFieldValue(`targets[${index}].isAccessible`, data.is_accessible);
          setFieldValue(`targets[${index}].isAccessTested`, true);
        }
      }
      catch (e) {
        if (e instanceof CanceledError) {
          // canceled previous request
        }
      }
    };

    changes.count++;
    if (values.targets[index] && !values.targets[index].isAccessTested && values.targets[index].url) {
      onTestNewWebTarget(values.targets[index].url);
    }
  }, [values.targets[index].isNameTested]);

  return (
    <>
      <UITextInput
        {...innerTextInputProps}
        isReadOnly={true}
        isInvalid={isInvalid}
        noBorders={isInvalid && values.targets[index].isAccessTested ? globalEnums.EmotionBoolean.False : globalEnums.EmotionBoolean.True}
        endIcon={values.targets[index].isAccessTested ?
          <EndIcon
            isAccessible={values.targets[index].isAccessible}
            location={values.targets[index].url}
            id={values.targets[index].name || values.targets[index].url}
            setOpenModal={() => setOpenModal(true)}
            targetExists={false}
            smallIcon
          /> :
          <Spinner size='small' />}
        style={{ color: baseTokens.gray500 }}
      />
      <UnableToRunScanModal
        type={globalEnums.ETargetTypes.WEB}
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        targetName={values.targets[index]?.name || values.targets[index]?.url}
        projectId={projectId}
      />
    </>
  );
};

UrlCellRender.displayName = 'UrlCellRender';

export default UrlCellRender;

