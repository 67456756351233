import { CircularProgress, InputAdornment, InputBaseComponentProps } from '@mui/material';
import { MuiTextInput } from '@shared/components';
import { globalQueries, globalTypes } from '@shared/duck';
import { useDebounce } from '@shared/hooks';
import { CanceledError } from 'axios';
import { useFormikContext } from 'formik';
import React from 'react';

const Username: React.FC<InputBaseComponentProps> = ({
  ...props
}) => {
  const { values, initialValues, setFieldValue } = useFormikContext<globalTypes.ProfileFormValues>();

  const { debouncedValue = '', onChange: onDebounceChange } = useDebounce<string>(values.username);
  const [abortController, setAbortController] = React.useState(new AbortController());

  const { getUserByUsername, isLoading } = globalQueries.useGetUserByUsername(abortController.signal);
  const testUsername = async () => {
    if (values.username) {
      if (values.username.trim() === initialValues.username && !values.usernameExists) {
        setFieldValue('usernameExists', false);
        return;
      }
      try {
        await getUserByUsername({ username: debouncedValue });
        setFieldValue('usernameExists', true);
      }
      catch (e) {
        if (e instanceof CanceledError) {
          setFieldValue('usernameExists', undefined);
        }
        else {
          setFieldValue('usernameExists', false);
        }
      }
    }
  };

  React.useLayoutEffect(() => {
    testUsername();
  }, [debouncedValue]);

  return (
    <MuiTextInput
      id='username'
      placeholder='Enter Username'
      onBlur={props.onBlur}
      name={props.name}
      error={props.error}
      inputProps={props}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {isLoading
              ? <CircularProgress size={20} color='inherit' />
              : <></>}
          </InputAdornment>
        )
      }}
      onChange={(e) => {
        setFieldValue('usernameExists', undefined);
        abortController.abort();
        setAbortController(new AbortController());
        onDebounceChange(e.target.value);
        props.onChange?.(e);
      }}
    />
  );
};

export default Username;