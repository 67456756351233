import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { muiPalette } from '@shared/general-mui-theme';

export const ContainerStyled = styled(Box)`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.375rem;
    border: 1px solid ${muiPalette.grey?.['200']};
    padding: 1.5rem;
    height: 100%;
    justify-content: space-between;
`;