import { IssueResolutionEnum, IssueResolutionName } from 'models/Enums';

export const IssueResolutions = [
  {
    label: IssueResolutionName.get(IssueResolutionEnum.open) as string,
    value: IssueResolutionEnum.open,
  },
  {
    label: IssueResolutionName.get(IssueResolutionEnum.falsePositive) as string,
    value: IssueResolutionEnum.falsePositive,
  },
  {
    label: IssueResolutionName.get(IssueResolutionEnum.resolved) as string,
    value: IssueResolutionEnum.resolved,
  },
];
