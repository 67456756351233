import React from 'react';
import { useFormikContext } from 'formik';
import { globalQueries } from '@shared/duck';
import { useDebounce } from '@shared/hooks';
import { MuiTextInput } from '@shared/components';
import { CircularProgress, InputAdornment, InputBaseComponentProps } from '@mui/material';
import { AuthenticationFormValues } from '@shared/components/authentication-modal/authentication-modal';
import { CanceledError } from 'axios';

export interface NameInputProps extends InputBaseComponentProps {
  projectId: string;
  isInEdit?: boolean;
}

const NameInput: React.FC<NameInputProps> = ({
  projectId,
  isInEdit = false,
  ...innerTextInputProps
}: NameInputProps) => {
  const { values, setFieldValue, initialValues } = useFormikContext<AuthenticationFormValues>();
  const { debouncedValue = '', onChange: onDebounceChange } = useDebounce<string>(values.name);
  const [abortController, setAbortController] = React.useState(new AbortController());

  const { getAuthByName, isLoading } = globalQueries.useGetAuthByName(abortController.signal);
  const testName = async () => {
    if (values.name) {
      if (isInEdit && values.name.trim() === initialValues.name && !values.nameExists) {
        setFieldValue('nameExists', false);
        return;
      }
      try {
        await getAuthByName({ credentialsName: debouncedValue, id: projectId });
        setFieldValue('nameExists', true);
      }
      catch (e) {
        if (e instanceof CanceledError) {
          setFieldValue('nameExists', undefined);
        }
        else {
          setFieldValue('nameExists', false);
        }
      }
    }
  };

  React.useEffect(() => {
    testName();
  }, [debouncedValue]);

  React.useEffect(() => {
    setFieldValue('nameExists', undefined);
    testName();
  }, [projectId]);

  return (
    <MuiTextInput
      inputProps={innerTextInputProps}
      error={innerTextInputProps.error}
      size='small'
      placeholder='Enter name'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {isLoading
              ? <CircularProgress size={20} color='inherit' />
              : <></>}
          </InputAdornment>
        )
      }}
      onChange={(e) => {
        setFieldValue('nameExists', undefined);
        abortController.abort();
        setAbortController(new AbortController());
        onDebounceChange(e.target.value);
        innerTextInputProps.onChange?.(e);
      }} />
  );
};

export default NameInput;