import { TextFieldProps } from '@mui/material';
import { MuiTextInput } from '@shared/components';
import { useFormikContext } from 'formik';
import React from 'react';
import { fpModalTypes } from '../../duck';

const ParamName: React.FC<TextFieldProps> = ({
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext<fpModalTypes.FpModalFormValues>();
  const [value, setValue] = React.useState<string>(values.paramName || '');

  React.useEffect(() => {
    if (values.paramName !== value) {
      setFieldValue('paramName', value);
    }
  }, [value]);

  const handleOnChange = (value: string) => {
    setValue(value);
  };

  return (
    <MuiTextInput
      id='paramName'
      placeholder='Enter Parameter Name...'
      value={value}
      onBlur={props.onBlur}
      name={props.name}
      error={props.error}
      disabled={props.disabled}
      onChange={(e) => {
        handleOnChange(e.target.value);
        props.onChange?.(e);
      }}
    />
  );
};

export default ParamName;