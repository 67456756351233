import { globalModels, globalQueries, globalUtils } from '@shared/duck';
import { ErrorContext } from '@shared/duck/contexts';
import React from 'react';
import { errorResponseHandler } from 'views/utils/errorHandlers';

interface FindingsTableApiResult {
  issueKinds: globalModels.IssueKindStatisticsViewModel[],
  isIssueKindsLoading: boolean,
  issueKindListHasNextPage?: boolean,
  fetchIssueKindListNextPage: () => void
}

export const useFindingsTableApi = ({
  scanId,
  projectId,
  refetchInterval,
  filter,
}: {
  scanId: string;
  refetchInterval?: number;
  projectId?: string;
  filter?: string
}): FindingsTableApiResult => {
  const { setError } = React.useContext(ErrorContext);
  const {
    issueKinds: dtoIssueKinds = [],
    fetchIssueKindListNextPage,
    isIssueKindsLoading,
    issueKindListHasNextPage,
  } = globalQueries.useGetIssueKindList(
    {
      scan: scanId,
      project: projectId ? [projectId] : undefined,
      filter: filter,
    },
    {
      refetchInterval,
      enabled: !!scanId,
      onError: err => errorResponseHandler(err, 'scan', setError),
    },
  );

  const issueKinds = React.useMemo(
    () =>
      dtoIssueKinds?.map(
        (issueKind) => globalUtils.mapIssueKindsDtoToViewModel(issueKind)) || [],
    [dtoIssueKinds],
  );

  return {
    issueKinds,
    isIssueKindsLoading,
    issueKindListHasNextPage,
    fetchIssueKindListNextPage,
  };
};