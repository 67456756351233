import { IconProps } from './BaseIcon';
import { IconSizeStyles, pickCustomSvgProps } from './icons.utils';

const CustomSVGIcon = (props: IconProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...pickCustomSvgProps(props)}
  >
    <path
      d='M7.99967 14.6669C4.31967 14.6629 1.33701 11.6803 1.33301 8.00026V7.86692C1.40634 4.20359 4.42301 1.28559 8.08634 1.33359C11.751 1.38292 14.689 4.37959 14.665 8.04359C14.641 11.7083 11.6643 14.6669 7.99967 14.6669ZM5.41013 8.19556C5.15016 7.93659 4.7296 7.937 4.47013 8.19647C4.2103 8.4563 4.2103 8.87755 4.47013 9.13738L5.95923 10.6265C6.34976 11.017 6.98292 11.017 7.37345 10.6265L11.5297 6.47026C11.7895 6.21042 11.7903 5.78936 11.5313 5.5286C11.2711 5.26653 10.8475 5.26578 10.5863 5.52693L6.66634 9.44692L5.41013 8.19556Z'
      fill='#006D23'
    />
  </svg>
);

function ResolutionResolvedIcon(props: IconProps) {
  const sizeProps = IconSizeStyles.get(props.size || 'medium');
  return <CustomSVGIcon {...sizeProps} {...props} />;
}

export default ResolutionResolvedIcon;
