import React from 'react';
import { Project } from '@api-client';
import { MuiAutocomplete } from '@shared/components';
import { globalQueries, globalTypes, globalUtils } from '@shared/duck';
import { CustomInput, CustomOption, CustomPaperComponent } from '../components';
import { selectorsUtils } from '../duck';
import { useDebounce } from '@shared/hooks';

interface MuiProjectSelectProps extends globalTypes.MUISelectProps<Project> {
  label?: string;
  defaultProject?: Project | null;
  disabled?: boolean;
  withCreate?: boolean;
}

const MuiProjectSelect: React.FC<MuiProjectSelectProps> = ({
  label = 'Project',
  defaultProject,
  disabled = false,
  withCreate = true,
  onFormChange,
  ...props
}) => {
  const [value, setValue] = React.useState<Project | undefined | null>(defaultProject);
  const [newProjectName] = React.useState<string>('');
  const { debouncedValue, onChange: onInputChange } = useDebounce<string>();

  const {
    projects = [],
    fetchNextProjectsPage,
    projectsHasNextPage,
    isProjectsListLoading,
    isProjectsListFetching,
  } = globalQueries.useGetProjectsList({
    order: ['name'],
    pageSize: 25,
    filter: debouncedValue !== value?.name ? debouncedValue : undefined,
  });

  const isProjectsLoading = isProjectsListLoading || isProjectsListFetching;

  React.useEffect(() => {
    selectorsUtils.handleOnFormChange(value, onFormChange);
  }, [value]);

  const { createOrEditProjectHandler: createProjectHandler } = globalUtils.useCreateOrEditProjectHandler();
  const createProject = async () => {
    const res = await createProjectHandler({ initialName: newProjectName });
    res && setValue(res);
  };

  return (
    <MuiAutocomplete
      id='project-autocomplete'
      options={projects}
      label={label}
      required={props.required}
      error={props.error}
      autoComplete
      autoHighlight
      // filterSelectedOptions
      disabled={disabled}
      loadingText='Loading...'
      loading={isProjectsLoading}
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_, newValue: Project) => setValue(newValue)}
      filterOptions={() => {
        return projects;
      }}
      onInputChange={(_, val) => onInputChange(val)}
      renderOption={(props, option) => {
        return (
          <CustomOption {...props} option={option} />
        );
      }}
      renderInput={(params) => {
        return (
          <CustomInput
            {...params}
            onBlur={props.onBlur}
            isError={props.error}
            name={props.name}
            isLoading={isProjectsLoading}
            placeholder='Project Name'
          />
        );
      }}
      getOptionKey={(option: Project) => option.id}
      getOptionLabel={(option: Project) => option.name}
      noOptionsText={'No Projects found'}
      PaperComponent={CustomPaperComponent}
      slotProps={{
        paper: {
          onCreateClick: createProject,
          withCreate: withCreate,
          content: '+ Create new project',
        } as any,
      }}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 5) {
            !isProjectsLoading && projectsHasNextPage && fetchNextProjectsPage();
          }
        },
      }}
    />
  );
};

export default MuiProjectSelect;