import { allSubroutesEnum } from 'pages/all-subroutes-enum';
import { AuthenticationDetails } from './components';
import AuthenticationsPage from './authentications-page';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFoundErrorWrapper } from '@pages';

const AuthenticationsRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={allSubroutesEnum.Root} element={<AuthenticationsPage />} />
      <Route path={allSubroutesEnum.AuthenticationsDetails} element={<AuthenticationDetails />} />
      <Route path='*' element={<NotFoundErrorWrapper />} />
    </Routes>
  );
};

export default AuthenticationsRouter;