import { styled } from '@mui/material/styles';

export const ContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
`;

export const AvatarContainer = styled('div')`
    display: flex;
    margin-top: 1rem;
`;