import { tableTypes } from '@shared/components';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { StyledTableCell } from '../../table-cell.styled';
import { globalUtils } from '@shared/duck';
import { Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

interface CustomCellProp<DataItem> {
  dataItem: DataItem;
  column: tableTypes.ColumnProps<DataItem>;
  index: number;
  cellValue: any;
  description?: string;
  Icon?: React.ReactNode;
  withoutPadding?: boolean;
}

type alignProps = 'center' | 'left' | 'right';

export const ToLinkOrNotToLink: React.FC<React.PropsWithChildren & { href: string }> = ({ href, children }) => {
  return (
    href ? (
      <Link to={href} onClick={e => (e.stopPropagation(), false)}>
        {children}
      </Link >
    ) : (
      <>
        {children}
      </>
    )
  );
};

const CustomCell = <DataItem,>({
  dataItem,
  column,
  index,
  cellValue,
  description,
  Icon,
  withoutPadding = false,
}: CustomCellProp<DataItem>) => {
  return (
    <StyledTableCell
      width={column.width}
      withoutPadding={withoutPadding}
      minWidth={column.minWidth}
      align={column.cellInfo?.alignItems as alignProps || 'left'}
    >
      <ToLinkOrNotToLink href={column.cellInfo?.href?.(dataItem) || ''}>
        {column.cellInfo?.cellTooltipText && <Tooltip
          place={column.cellInfo?.cellTooltipPlace || 'bottom'}
          id={'custom-cell-tooltip' + column.columnKey.toString() + index}
          style={{ borderRadius: '0.375rem' }}
        >
          {column.cellInfo?.cellTooltipText || ''}
        </Tooltip>}

        <Stack
          direction='row'
          alignItems='center'
          justifyContent={column.cellInfo?.alignItems || 'left'}
          data-tooltip-id={'custom-cell-tooltip' + column.columnKey.toString() + index}
        >
          {Icon}
          <Stack width={column.cellInfo?.fullWidth ? '100%' : 'initial'} gap='0'>
            {column.customCellRenderer && column.customCellRenderer(cellValue, dataItem, index)}

            {description && (
              <Typography variant='default' noWrap sx={{ opacity: 0.5 }}>
                {globalUtils.sliceTextByLimit(description, true)}
              </Typography>
            )}
          </Stack>

        </Stack>
      </ToLinkOrNotToLink>
    </StyledTableCell>
  );
};

export default CustomCell;