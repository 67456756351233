import React from 'react';
import { UIDialogTitle } from '@shared/components';
import { Button, CircularProgress, DialogActions, DialogContent, Stack } from '@mui/material';
import { ImportCsvFileFormikValues, importCsvValidationSchema } from './duck';
import { Form, Formik } from 'formik';
import { ImportCsvContent } from './components';

export interface ImportCSVModalProps {
  onClose: () => void;
  onSubmit: (values: ImportCsvFileFormikValues) => void;
  onAddPathClick: () => void;
}

const ImportCsvModal: React.FC<ImportCSVModalProps> = ({
  onClose,
  onSubmit,
  onAddPathClick,
}: ImportCSVModalProps) => {

  return (
    <Formik<ImportCsvFileFormikValues>
      initialValues={{ file: undefined, data: null }}
      validationSchema={importCsvValidationSchema}
      onSubmit={onSubmit}
      validateOnMount
      validateOnChange
      validateOnBlur
    >
      {({ isValid, isSubmitting, values }) => (
        <Form>
          <UIDialogTitle
            title={'Import paths from CSV'}
            onCloseBtnAction={onClose}
          />
          <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', gap: '0.5rem' }}>
            <ImportCsvContent />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              variant={'text'}
              onClick={onAddPathClick}
            >
              {'Add paths manually'}
            </Button>
            <Stack direction='row'>
              <Button
                variant={'outlined'}
                onClick={onClose}
              >
                {'Cancel'}
              </Button>
              <Button
                type='submit'
                variant='contained'
                disabled={!isValid}
                endIcon={isSubmitting && (
                  <CircularProgress color='inherit' size={20} />
                )}
              >
                {values.data?.length ? `Add (${values.data.length})` : 'Add'}
              </Button>
            </Stack>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default ImportCsvModal;
