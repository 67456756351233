import { Issue, IssuesOccurrencesResponse, ResolutionEnum } from '@api-client';
import { Table as BaseTable, Checkbox } from '@contentful/f36-components';
import useItemSelection, { ItemSelectionApi } from '@shared/hooks/use-item-selection';
import { IssueResolutionEnum } from 'models/Enums';
import React from 'react';
import {
  StyledContentContainer,
} from './occurences-table.styled';
import _ from 'lodash';
import { ChevronDownIcon, ChevronUpIcon } from 'views/components/v1/Icons';
import { occurencesTableUtils } from './duck';
import { globalEnums, globalQueries } from '@shared/duck';
import { MuiContentLoader, UIIssueResolutionSelectOld } from '@shared/components';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext } from '@shared/duck/contexts';
import { Box, Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

interface IssueOccurrencesTableProps {
  kindId: number;
  scanId?: string;
  targetId: string;
  urlPath: string;
  httpMethod: string;
  resolution?: ResolutionEnum[];
  currentIssue?: Issue;
  setCurrentIssueId: (issueId?: string) => void;
  firstScrollToIssue: boolean;
  setFirstScrollToIssue: (value: boolean) => void;
  parameterSelectionApi?: ItemSelectionApi<Issue>;
  setClosedGroupIssues?: React.Dispatch<React.SetStateAction<Issue[]>>;
}

const IssueOccurrencesTable: React.FC<IssueOccurrencesTableProps> = ({
  kindId,
  scanId,
  targetId,
  urlPath,
  httpMethod,
  resolution,
  currentIssue,
  setCurrentIssueId,
  parameterSelectionApi,
  firstScrollToIssue,
  setFirstScrollToIssue,
}) => {
  const initialized = React.useRef(false);
  const isUnmount = React.useRef(false);
  const { updateIssue } = globalQueries.useUpdateIssue();
  const [expandedIssues, setExpandedIssues] = React.useState<Set<string>>(new Set());
  const [loading, setLoading] = React.useState(true);
  const [groupedIssues, setGroupedIssues] = React.useState<_.Dictionary<IssuesOccurrencesResponse[]>>();
  const [allGroupedIssues, setAllGroupedIssues] = React.useState<Issue[]>([]);
  const { setError } = React.useContext(ErrorContext);

  const {
    issues = [], isIssuesFetchedAfterMount, isIssuesLoading,
    issuesListHasNextPage, fetchIssuesListNextPage, isFetchingNextPage,
  } = globalQueries.useGetIssueOccurrencesList({
    issuesOccurrencesFiltersRequest: {
      kind_id: kindId,
      scan_id: scanId ? scanId : undefined,
      target_id: targetId,
      url_path: urlPath,
      http_method: httpMethod,
      resolution: resolution,
    },
  }, {
    onError: err => errorResponseHandler(err, 'scan', setError),
  });

  const localSelectionApi = useItemSelection<Issue>({
    data: [],
    keyBy: item => item.id,
    labelBy: item => item.id,
  });

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      if (localSelectionApi.selectedItems.size) {
        parameterSelectionApi?.reinitialize(localSelectionApi.selectedItems);
      } else if (parameterSelectionApi?.selectedItems.size && resolution) {
        parameterSelectionApi.onUnToggleAll();
      }
    }

    return () => {
      if (initialized.current) {
        isUnmount.current = true;
        initialized.current = false;
      }
    };
  }, []);

  React.useEffect(() => {
    if (localSelectionApi.isAllSelected) {
      const concat = occurencesTableUtils.concatSelectedItems(
        localSelectionApi.selectedItems,
        parameterSelectionApi?.selectedItems,
      );
      parameterSelectionApi?.reinitialize(concat);
    }
  }, [localSelectionApi.isAllSelected]);

  React.useEffect(() => {
    const grouped = _.groupBy(issues, 'parameter_name');

    setGroupedIssues(grouped);
    occurencesTableUtils.sortSelectedItemsByResolution(localSelectionApi, parameterSelectionApi, resolution);
  }, [issues]);

  React.useEffect(() => {
    if (isIssuesFetchedAfterMount && !currentIssue) {
      const grouped = _.groupBy(issues, 'parameter_name');
      const [first] = Object.keys(grouped);
      setCurrentIssueId(grouped[first]?.[0].id);
    }
  }, [issues, isIssuesFetchedAfterMount]);

  // Expand parameters list if current issue is not visible
  React.useEffect(() => {
    if (currentIssue && currentIssue.url_path === urlPath) {
      const paramName = currentIssue?.parameter_name ?? '';
      const paramsList = groupedIssues?.[paramName] || [];
      if (paramsList.length && paramsList[0].id !== currentIssue.id) {
        occurencesTableUtils.setParameterIssuesOpen(
          paramName,
          true,
          expandedIssues,
          setExpandedIssues,
        );
      }
    }
    // resolution dependency is crucial
  }, [currentIssue, resolution, groupedIssues]);

  React.useEffect(() => {
    occurencesTableUtils.getAllGroupedIssues(
      setAllGroupedIssues,
      groupedIssues,
    );
  }, [groupedIssues]);

  React.useEffect(() => {
    return () => {
      if (isUnmount.current) {
        const issues = groupedIssues && occurencesTableUtils.convertResponseToIssues(groupedIssues);
        const items = new Map(parameterSelectionApi?.selectedItems);

        if (issues?.length) {
          issues.forEach((issue) => {
            items.delete(issue.id);
          });

          parameterSelectionApi?.reinitialize(items);
        } else {
          localSelectionApi.onUnToggleAll();
          parameterSelectionApi?.onUnToggleAll();
        }

        isUnmount.current = false;
      }
    };
  }, [groupedIssues, parameterSelectionApi?.selectedItems]);

  React.useEffect(() => {
    if (allGroupedIssues) {
      const withLength = _.toArray(allGroupedIssues).map((value) => {
        return value;
      });
      localSelectionApi.reinitializeData(withLength);

      if (localSelectionApi.selectedItems.size) {
        withLength.forEach((newValue) => {
          if (localSelectionApi.selectedItems.has(newValue.id)) {
            localSelectionApi.selectedItems.set(newValue.id, newValue);
          }
          if (parameterSelectionApi?.selectedItems.has(newValue.id)) {
            parameterSelectionApi.selectedItems.set(newValue.id, newValue);
          }
        });

        localSelectionApi.selectedItems.forEach((item) => {
          if (!withLength.find(newValue => newValue.id === item.id)) {
            localSelectionApi.selectedItems.delete(item.id);
            parameterSelectionApi?.selectedItems.delete(item.id);
          }
        });
      }
    }
  }, [allGroupedIssues]);

  const onToggleAll = () => {
    occurencesTableUtils.onToggleAll(localSelectionApi, parameterSelectionApi);
  };

  const toggleIssue = (parameterName: string) => {
    const open = !expandedIssues.has(parameterName);
    occurencesTableUtils.setParameterIssuesOpen(
      parameterName,
      open,
      expandedIssues,
      setExpandedIssues,
    );
  };

  const getOnToggleCheckRow = React.useCallback(
    _.memoize((issue: Issue) => () => {
      localSelectionApi?.onToggleItem(issue);
      parameterSelectionApi?.onToggleItem(issue);
    }),
    [parameterSelectionApi],
  );

  occurencesTableUtils.fetchNextPages({
    setLoading,
    issuesListHasNextPage,
    fetchIssuesListNextPage,
    isIssuesLoading: isIssuesLoading || isFetchingNextPage,
  });

  return (
    <StyledContentContainer hasTarget={!scanId ? globalEnums.EmotionBoolean.True : globalEnums.EmotionBoolean.False}>
      <BaseTable className='issue-occurrences-table'>
        <BaseTable.Head>
          <BaseTable.Row>
            <BaseTable.Cell width={'2.3125rem'}>
              <Checkbox
                onChange={onToggleAll}
                isIndeterminate={localSelectionApi?.isPartiallySelected}
                isChecked={localSelectionApi?.isAllSelected || localSelectionApi?.isPartiallySelected}
              />
            </BaseTable.Cell>
            <BaseTable.Cell style={{ minWidth: '5rem' }}>Parameter</BaseTable.Cell>
            <BaseTable.Cell style={{ minWidth: '5rem' }}>Payload</BaseTable.Cell>
            <BaseTable.Cell width='158px'>Status</BaseTable.Cell>
          </BaseTable.Row>
        </BaseTable.Head>
        <BaseTable.Body>
          <>
            <MuiContentLoader
              tableSpanCols={5}
              isLoading={issues.length ? loading || isIssuesLoading || isFetchingNextPage : true}
            >
              {groupedIssues && Object.keys(groupedIssues).map(key => {
                const issues = (
                  expandedIssues.has(key)
                    ? groupedIssues[key]
                    : _.first(groupedIssues[key])
                      ? [_.first(groupedIssues[key])]
                      : []
                ) as Issue[];

                return issues.map((issue, i) => {
                  const isIssueSelected = issue.id === currentIssue?.id;
                  const isIssueExpanded = expandedIssues.has(key);

                  return (
                    <React.Fragment key={issue.id}>
                      <BaseTable.Row
                        ref={(ref) => {
                          if (isIssueSelected && firstScrollToIssue) {
                            ref?.scrollIntoView({ block: 'center', behavior: 'smooth' });
                            setFirstScrollToIssue(false);
                          }
                        }}
                        key={issue.id}
                        onClick={() => setCurrentIssueId(issue.id)}
                        isSelected={isIssueSelected}
                        className={isIssueSelected ? 'selected-row' : ''}
                      // className={!isIssueExpanded && i === 0 ? 'first' : isIssueSelected ? 'selected-row' : ''}
                      >
                        <BaseTable.Cell>
                          <Box
                            p='0.75rem'
                            pl='0'
                            onClick={(e: { stopPropagation: () => void }) => e.stopPropagation()}
                          >
                            <Checkbox
                              onChange={getOnToggleCheckRow(issue)}
                              isChecked={localSelectionApi?.selectedItems.has(issue.id)}
                              onClick={e => e.stopPropagation()}
                            />
                          </Box>
                        </BaseTable.Cell>

                        <BaseTable.Cell>
                          <Typography variant='default'>{issue.parameter_name || 'N/A'}</Typography>
                        </BaseTable.Cell>

                        <BaseTable.Cell>
                          <Typography variant='default' color={muiPalette.grey?.[500]}>
                            {issue.payload || 'N/A'}
                          </Typography>
                        </BaseTable.Cell>
                        <BaseTable.Cell>
                          <UIIssueResolutionSelectOld
                            name='issue-resolution'
                            key={issue.id + '-resolution-select'}
                            onChange={(e) => {
                              occurencesTableUtils.onResolutionChange(
                                updateIssue,
                                issue,
                                e.target.value as IssueResolutionEnum,
                              );
                            }}
                            resolution={issue.resolution}
                            withBorders={false}
                            withPaddings={false}
                            bgColor={'transparent'}
                          />
                        </BaseTable.Cell>
                      </BaseTable.Row>

                      {groupedIssues[key].length > 1 && i === issues.length - 1 && (
                        <>
                          <BaseTable.Row
                            key={'expand' + issue.id + 'row'}
                            className={'expandRow'}
                            onClick={() => toggleIssue(key)}
                          >

                            <BaseTable.Cell
                              colSpan={4}
                              className={'expandFlex'}
                            >
                              <Stack direction='row' gap='0.25rem'>
                                {isIssueExpanded ?
                                  <ChevronUpIcon size={'tiny'} variant={globalEnums.EIconVariants.SECONDARY} /> :
                                  <ChevronDownIcon size={'tiny'} variant={globalEnums.EIconVariants.SECONDARY} />
                                }
                                <Typography variant='body2'>
                                  {isIssueExpanded ? 'Hide entries' :
                                    `Expand ${groupedIssues[key].length - 1} more entries`}
                                </Typography>
                              </Stack>
                            </BaseTable.Cell>
                          </BaseTable.Row>
                          <BaseTable.Row
                            key={'hidden-' + issue.id}
                            className='hidden-substrate'
                          >
                            <BaseTable.Cell colSpan={4}>
                              <Box className={'substrate'} />
                            </BaseTable.Cell>
                          </BaseTable.Row>
                        </>
                      )}
                    </React.Fragment>
                  );
                });
              })}
            </MuiContentLoader>
          </>
        </BaseTable.Body>
      </BaseTable>
    </StyledContentContainer >
  );
};

export default IssueOccurrencesTable;
