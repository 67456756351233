import Link, { LinkProps } from '@mui/material/Link';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter: React.FC<LinkRouterProps> = (props: LinkRouterProps) => {
  return <Link {...props} component={RouterLink as any} />;
};

export default LinkRouter;