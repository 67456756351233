import { CommonProps } from '@contentful/f36-components';
import { WarningIcon } from 'views/components/v1/Icons';
import { Tooltip as CredTooltip } from 'react-tooltip';
import React from 'react';
import { getVariantOfLastLimitScans, LoginStatus, Result } from '@shared/components/warning-alert/duck';
import { globalQueries } from '@shared/duck';
import { Stack } from '@mui/material';

const LIMIT = 3;

interface MyComponentProps extends CommonProps {
  id: string;
  name: string;
}

interface AuthWarningAlertFCProps {
  name: string,
  result?: Result
}

const AuthWarningAlertFC: React.FC<AuthWarningAlertFCProps> = ({
  result,
  name,
}) => {
  return (
    result ? (
      <Stack direction='row'>
        <WarningIcon variant={result.variant} size='small' />

        <CredTooltip
          place='bottom'
          id={'auth-tooltip' + name}
          style={{ borderRadius: '0.375rem' }}
        >
          {`Authentication set ${result.status} for last 3 scans`}
        </CredTooltip>
      </Stack>
    ) : <></>
  );
};

export const getAuthWarningAlert = ({
  id,
  name,
}: MyComponentProps): React.ReactNode => {
  const { data } = globalQueries.useGetCredentialsLoginStatus({ id: id, limit: LIMIT });

  const result = getVariantOfLastLimitScans(data as LoginStatus, LIMIT);

  return <AuthWarningAlertFC name={name} result={result} />;
};