import React from 'react';
import { AxiosError } from 'axios';
import { allRoutesEnum } from '@shared/duck';
import { ErrorContextTypes } from '@shared/duck/contexts';

const errorResponseHandler = (error: AxiosError, name: string, setError?: React.Dispatch<ErrorContextTypes.ErrorDetails>) => {
  if (error.response?.status === 404) {
    if (setError) {
      setError({
        type: ErrorContextTypes.ErrorType.NOT_FOUND,
        objectName: name,
      });
    }
    else {
      window.location.replace(allRoutesEnum.NotFound);
    }
  }
  if (error.response?.status === 403) {
    if (setError) {
      setError({
        type: ErrorContextTypes.ErrorType.NO_ACCESS,
        objectName: name,
      });
    }
    else {
      window.location.replace(allRoutesEnum.NoAccess + '?name=' + name);
    }
  }
};

export { errorResponseHandler };
