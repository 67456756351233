import { User } from '@api-client';
import { UIUserAvatar } from '@shared/components';
import React from 'react';

interface ListOptionProps {
  user: User;
}

const UserCard: React.FC<ListOptionProps> = ({
  user,
  ...props
}) => {
  return (
    <UIUserAvatar
      {...props}
      fullName={`${user.first_name} ${user.last_name}`}
      src={user.avatar_url}
      description={user.username}
      titleFontSize='0.75rem'
      titleLineHeight='1rem'
      titleFontWeight='600'
    />
  );
};

export default UserCard;