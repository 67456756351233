import { Note } from '@contentful/f36-components';
import { Typography } from '@mui/material';
import { Modal, ModalContent, ModalHeader, UICodeFragment } from '@shared/components';
import { globalEnums, globalConstants, globalQueries } from '@shared/duck';
import { ErrorContext } from '@shared/duck/contexts';
import { ScanTarget, UID } from '@shared/duck/types';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { errorResponseHandler } from 'views/utils/errorHandlers';

interface ModalPopup {
  scanId: string | '';
  closeModal: () => void;
  onRescanClick: (values: ScanTarget) => void;
}

const RescanPopup: React.FC<ModalPopup> = ({
  scanId,
  closeModal,
  onRescanClick,
}) => {
  const { setError } = React.useContext(ErrorContext);
  const { scan = undefined, isScanLoading } = globalQueries.useGetScan({ id: scanId }, {
    enabled: !!scanId,
    onError: err => errorResponseHandler(err, 'scan', setError),
  });
  const applicationId = scan?.application_id;
  const isAuthDeleted = scan?.credentials?.is_deleted;
  const isGetCredentialsEnabled = !!scan?.credentials_id;
  const {
    credentials = null,
    isCredentialsLoading = false,
  } = globalQueries.useGetCredentials({ id: scan?.credentials_id || '' }, { enabled: isGetCredentialsEnabled });

  const [code, setCode] = React.useState('');
  React.useEffect(() => {
    const onRescan = async () => {
      if (scan?.relay_id) {
        if (applicationId && !isAuthDeleted) {
          setCode(`nightvision scan ${scan?.target.name} \\\n` +
            `-a ${scan.application?.name} \\\n` +
            `-P ${scan?.target.project} ` +
            (
              scan?.credentials ? `\\\n--auth ${scan?.credentials?.name}` : ''
            )
          );
        }
        else {
          enqueueSnackbar(isAuthDeleted ? globalConstants.RESCAN_IS_NOT_AVAILABLE_AUTH : globalConstants.RESCAN_IS_NOT_AVAILABLE_APP, { variant: 'error' });
          closeModal();
        }
      }
    };

    onRescan();
  }, [scan]);

  React.useEffect(() => {
    const onRescan = async () => {
      if (!(isGetCredentialsEnabled && isCredentialsLoading) && scan && !scan.relay_id && !isScanLoading) {
        if (applicationId && !isAuthDeleted) {
          const values: ScanTarget | undefined = {
            credentials: new Map(),
            application: '',
            name: '',
            id: '',
          };

          const key = scan.credentials_id;
          if (key) {
            values.credentials = new Map().set(key as UID, credentials ?? null);
          } else {
            values.credentials = new Map();
          }

          values.project = scan.target.project;
          values.id = scan.target.id;
          values.application = applicationId;
          onRescanClick(values);
        }
        else {
          enqueueSnackbar(isAuthDeleted ? globalConstants.RESCAN_IS_NOT_AVAILABLE_AUTH : globalConstants.RESCAN_IS_NOT_AVAILABLE_APP, { variant: 'error' });
        }
        closeModal();
      }
    };

    onRescan();
  }, [scan, isCredentialsLoading]);

  return (
    scan?.relay_id && applicationId && !isAuthDeleted ?
      <Modal
        isShown={!!scanId || !isScanLoading}
        onClose={closeModal}
        size='large'
      >
        {() => (
          <>
            <ModalHeader onClose={closeModal} title='Unable to run scan directly...' />
            <ModalContent style={{ display: 'flex', flexDirection: 'column', maxHeight: '40rem' }}>
              <Typography variant='default' mb='1rem'>
                {'This looks like a private target to which NightVision does not have direct access. To re-run the scan, try the following command from your CLI'}
              </Typography>
              <UICodeFragment
                code={code}
                language={globalEnums.SupportedLangs.evidence_markup}
                useTabsForMultiline
              />

              <Note
                variant={globalEnums.ENoteVariants.PRIMARY}
                style={{ marginTop: '1rem' }}
              >
                {'Make sure your environment has network access to the application.'}
              </Note>
            </ModalContent>
          </>
        )}
      </Modal> : <></>
  );
};

export default RescanPopup;