import { Stack, styled } from '@mui/material';
import { globalEnums } from '@shared/duck';
import { BreakpointX } from 'views/utils/design';

const FullScreenPageLayout = styled(Stack)`
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  background: white;
  gap: 0;
`;

const FullScreenContainer = styled('div')`
  display: flex;
  position: relative; // to position back button
  padding: 0 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;

const FullScreenTopBar = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 1rem 2rem;
  padding-bottom: 0.5rem;
`;

const FullScreenLogoutButton = styled('div')`
  text-align: right;
  margin-left: 1rem;
`;

function getStretched({ stretch }: { stretch?: globalEnums.EmotionBoolean }) {
  return stretch ? 'padding: 0rem 4rem; align-self: stretch;' : '';
}

const FullScreenContent = styled(Stack) <{ stretch?: globalEnums.EmotionBoolean }>`
  display: flex;
  ${getStretched};
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @media (max-width: ${BreakpointX.Laptop.max}px) {
    padding: 0 1rem;
  }
`;

export { FullScreenPageLayout, FullScreenContainer, FullScreenContent, FullScreenLogoutButton, FullScreenTopBar };
