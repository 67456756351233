import React from 'react';
import * as apiClient from 'api-client';
import { DeleteEmptyIcon, PlusIcon } from 'views/components/v1/Icons';
import { useDebounce } from '@shared/hooks';
import { StyledGridItem } from './custom-paths-tab.styled';
import { globalEnums, globalQueries, globalTypes } from '@shared/duck';
import { NoContentPlaceholder, MuiSearchInput, UIMenu, MuiContentLoader } from '@shared/components';
import { Button, Checkbox, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { ScansGray } from '@assets';
import { UrlPathProps, ModalWrapper } from './components';
import { PencilSimple } from '@phosphor-icons/react';
import saveAs from 'file-saver';
import useItemSelection from '@shared/hooks/use-item-selection';
import { endpointsToCsv, useDeletePaths } from './duck';

export interface CustomPathsTabProps {
  webTargetId: string;
}

// type AdditionalPathItem = apiClient.UrlsAdditionalPathsItem & { path: string }

const CustomPathsTab: React.FC<CustomPathsTabProps> = ({
  webTargetId
}: CustomPathsTabProps) => {
  const { debouncedValue, onChange: onSearchChange } = useDebounce<string>();

  const { additionalPaths, isUrlTargetPathsListLoading } = globalQueries.useListPathsTargetUrl({
    id: webTargetId,
    filter: debouncedValue,
  });
  const hasAdditionalPaths = additionalPaths.length > 0;

  const { deletePathsHandler } = useDeletePaths();

  // const changePathStatus = React.useCallback(async (item: AdditionalPathItem) => {
  //   await updatePathTargetUrl({
  //     id: webTargetId,
  //     pathId: item.id,
  //     urlsAdditionalPathsItemUpdateRequest: {
  //       disabled: !item.disabled,
  //     },
  //   });
  // }, [webTargetId]);

  const message = (
    <Stack gap='0.25rem'>
      Unable to see some paths in your scans? <Link onClick={() => setOpenAddPathModal(true)}>Add&nbsp;them&nbsp;here</Link>
    </Stack>
  );

  const onExportClick = React.useCallback(() => {
    const text = endpointsToCsv(additionalPaths);
    const value = new Blob([text], { type: 'text/plain;charset=utf-8' });
    saveAs(value, `user-defined-paths-${webTargetId}.csv`);
  }, [additionalPaths]);

  const [openImportModal, setOpenImportModal] = React.useState(false);
  const [openAddPathModal, setOpenAddPathModal] = React.useState(false);

  const [pathToEdit, setPathToEdit] = React.useState<UrlPathProps | undefined>();

  const localSelectionApi = useItemSelection<apiClient.UrlsAdditionalPathsItem>({
    data: additionalPaths,
    keyBy: item => item.id,
    labelBy: item => item.path,
  });

  React.useEffect(() => {
    localSelectionApi.reinitializeData(additionalPaths);

    const selectedItems = new Map(localSelectionApi.selectedItems);
    localSelectionApi.selectedItems.forEach((item) => {
      if (selectedItems.has(item.id) && !additionalPaths.find((path) => path.id === item.id)) {
        selectedItems.delete(item.id);
      }
    });
    localSelectionApi.reinitialize(selectedItems);
  }, [JSON.stringify(additionalPaths)]);

  const itemList: globalTypes.UIMenuItemsList[] = [
    {
      title: 'Export CSV',
      itemAction: () => onExportClick(),
      disabled: !hasAdditionalPaths,
    },
    {
      title: 'Import CSV',
      itemAction: () => setOpenImportModal(true),
    }
  ];

  const footerItems: globalTypes.UIMenuItemsList[] = [
    {
      title: 'Delete Selected',
      itemAction: () => deletePathsHandler(webTargetId, Array.from(localSelectionApi.selectedItems.values())),
      isRedText: true,
      disabled: !localSelectionApi.selectedItems.size,
      tooltipText: 'Please select Paths',
    }
  ];

  return (
    <Stack width={'100%'} height={'100%'} gap={1}>
      <Stack
        justifyContent='space-between'
        flexDirection='row'
        sx={{
          textWrap: 'nowrap',
          mt: '1rem',
          px: '0 0.5rem',
        }}
      >
        <Checkbox
          color='primary'
          indeterminate={localSelectionApi?.isPartiallySelected}
          checked={localSelectionApi?.isAllSelected || localSelectionApi?.isPartiallySelected}
          onChange={() => localSelectionApi.onToggleAll()}
          disabled={isUrlTargetPathsListLoading || !hasAdditionalPaths}
          sx={{ p: 0.5 }}
        />

        <MuiSearchInput
          width='100%'
          placeholder='Search'
          onChange={e => onSearchChange(e.target.value)}
        />
        <Button variant='outlined' style={{ minWidth: 'unset', width: '40px', padding: 0, flexShrink: 0 }} onClick={() => setOpenAddPathModal(true)}>
          <PlusIcon variant='muted' />
        </Button>
        <UIMenu
          type={globalEnums.EMenuType.COMPLEX}
          itemList={itemList}
          footerItems={footerItems}
        />
      </Stack>

      <MuiContentLoader
        isLoading={isUrlTargetPathsListLoading}
        color='primary'
        variant='linear'
      >
        {!hasAdditionalPaths ? (
          <NoContentPlaceholder padded height='100%' message={message} iconSrc={ScansGray} />
        ) : (
          hasAdditionalPaths && (
            <div style={{ overflow: 'auto', marginBottom: '0.5rem' }}>
              <Stack
                direction='column'
                gap='0'
                overflow='auto hidden'
              >
                {additionalPaths.map(item => (
                  <StyledGridItem key={item.id} style={{ display: 'flex', width: '100%', boxSizing: 'border-box', padding: '0' }}>
                    <Checkbox
                      color='primary'
                      checked={localSelectionApi?.selectedItems.has(item.id)}
                      onChange={() => localSelectionApi?.onToggleItem(item)}
                      sx={{ p: 0.5 }}
                    />
                    <Stack
                      justifyContent='space-between'
                      flexDirection='row'
                      width='100%'
                      overflow='hidden'
                    >
                      <Tooltip
                        title={item.path}
                        placement='top'
                        arrow
                        disableInteractive
                        enterDelay={500}
                        enterNextDelay={500}
                      >
                        <Typography
                          variant='subtitle2'
                          fontWeight={400}
                          noWrap
                          p={0.75}
                        >
                          {item.path}
                        </Typography>
                      </Tooltip>

                      <Stack direction='row' gap='0.25rem' alignItems='center'>
                        <Tooltip
                          title='Edit'
                          placement='top'
                          arrow
                          disableInteractive
                        >
                          <span>
                            <IconButton
                              size='small'
                              onClick={() => {
                                setPathToEdit({ id: item.id, path: item.path });
                                setOpenAddPathModal(true);
                              }}
                            >
                              <PencilSimple
                                color='black'
                                size={20}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip
                          title='Delete'
                          placement='top'
                          arrow
                          disableInteractive
                        >
                          <span>
                            <IconButton
                              size='small'
                              onClick={() => deletePathsHandler(webTargetId, [item])}
                            >
                              <DeleteEmptyIcon size='small' color='#BD002A' />
                            </IconButton>
                          </span>
                        </Tooltip>
                        {/* <Tooltip
                          arrow
                          title={item.disabled ? 'Enable' : 'Disable'}
                          placement='left'
                        >
                          <span>
                            <IconButton
                              size='small'
                              onClick={() => changePathStatus(item)}
                            >
                              <Prohibit
                                color={item.disabled ? '#BD002A' : '#CFD9E0'}
                                size={20}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <UIMenu
                          itemList={[
                            {
                              title: 'Edit',
                              itemAction: () => openUrlModal({ id: item.id, path: item.path }),
                            },
                            {
                              title: 'Delete',
                              itemAction: () => deletePathHandler(item),
                            },
                          ]}
                          size={'small'}
                          iconVariant={globalEnums.EButtonVariants.TRANSPARENT}
                        /> */}
                      </Stack>
                    </Stack>
                  </StyledGridItem>
                ))}
              </Stack>
            </div>
          )
        )}
      </MuiContentLoader>
      {(openImportModal || openAddPathModal) && (
        <ModalWrapper
          openImportModal={openImportModal}
          onClose={() => {
            openImportModal
              ? setOpenImportModal(false)
              : setOpenAddPathModal(false);
            setPathToEdit(undefined);
          }}
          webTargetId={webTargetId}
          onImportCsvClick={() => {
            setOpenAddPathModal(false);
            setOpenImportModal(true);
          }}
          onAddPathClick={() => {
            setOpenImportModal(false);
            setOpenAddPathModal(true);
          }}
          pathToEdit={pathToEdit}
        />
      )}
    </Stack>
  );
};

export default CustomPathsTab;
