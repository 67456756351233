import { Issue, IssueVulnerablePathResponse } from '@api-client';
import { globalEnums, globalModels } from '@shared/duck';

export function getVariantFromMethod(text: string): globalEnums.EBadgeVariants {
  switch (text.toLowerCase()) {
    case 'get':
      return globalEnums.EBadgeVariants.FEATURED;
    case 'post':
    case 'put':
      return globalEnums.EBadgeVariants.PRIMARY;
    case 'patch':
      return globalEnums.EBadgeVariants.WARNING;
    case 'purge':
    case 'delete':
    default:
      return globalEnums.EBadgeVariants.NEGATIVE;
  }
}

export const getVulnerablePathId = (dto: Issue | IssueVulnerablePathResponse): string => {
  return (
    (
      dto.url_path || ''
    ) + dto.http_method
  );
};

export const mapDtoToViewModel = (dto: IssueVulnerablePathResponse): globalModels.VulnerablePathsViewModel => {
  return {
    id: getVulnerablePathId(dto),
    targetId: dto.target_id,
    targetName: dto.target_name,
    urlPath: dto.url_path,
    httpMethod: dto.http_method,
    kindId: dto.kind_id,
    issues: dto.issues,
  };
};