import styled from '@emotion/styled';
import baseTokens from '@contentful/f36-tokens';
import { GridItem } from '@contentful/f36-components';

const StyledGridItem = styled(GridItem)`
  padding: 0.75rem;

  border-top: 1px solid ${baseTokens.gray300};
`;

export { StyledGridItem };
