import { globalQueries } from '@shared/duck';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { ApplicationsViewModel } from '@shared/duck/models';
import { enqueueSnackbar } from 'notistack';

export const useDeleteApplicationHandler = () => {
  const { deleteApplication } = globalQueries.useDeleteApplication();
  const onDeleteApplication = async (id: string, name?: string) => {
    try {
      await deleteApplication({ id });
      enqueueSnackbar(<><b>{name}</b> has been deleted successfully</>, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to delete ${name} Application`, { variant: 'error' });
    }
  };

  const { showModal } = useGlobalModalContext();

  const deleteApplicationHandler = async (application: ApplicationsViewModel) => {
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete Application?',
        onConfirm: () => onDeleteApplication(application.id, application.name),
        name: application.name,
      },
    });
  };

  return { deleteApplicationHandler };
};